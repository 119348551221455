import React, {useContext, useEffect, useRef} from "react";
import {SessionContext} from "../../contexts/SessionContext";
import api from "../api";
import {useNavigate} from "react-router-dom-v5-compat";
import {message, notification} from 'antd'
import {AbilityContext} from "./Can";
import TimeAgo from "./TimeAgo";
import moment from "moment";
import HelpPopover from "../HelpPopover";
import {useOrgPath} from "./OrgNavLink";

const useCurrentOrg = ()=>{
    const {state: sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const navigate = useNavigate();

    const ability = useContext(AbilityContext);

    const getPath = useOrgPath()

    if(currentOrg?.locked && !location.pathname.match(/update-billing/)) {
        if(ability.can('manage','Organization')) {
            // message.error('You must update your billing.')
            // navigate(getPath(`/update-billing`))
        } else {
            // message.error('This organizations is currently Locked.')
            sessionDispatch({type: 'set_org', org: null, abilities: null});
            navigate(`/organizations`)
        }
    }

    return currentOrg
}

const useRefreshOrg = ()=>{
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const currentOrg = useCurrentOrg()

    const navigate = useNavigate();

    return ()=>{
        api.get(`/api/organizations/find?slug=${currentOrg.slug}&login=true`).then(res => {
            sessionDispatch({type: 'set_org', org: res.data.organization || res.data, abilities: res.data.abilities});
            checkReindexing(res.data.organization)

        }).catch(()=> {
            navigate('/login')
        })
    }
}

const checkReindexing = org => {
    if(org?.reindexing) {
        notification.error({
            message: 'Search Re-Indexing',
            description: (
                <>
                    Your Assets are currently reindexing into the search system, so you may not see all of them when browsing and searching.
                    <br/>
                    <br/>
                    The estimated completion time is: <br/> <TimeAgo date={moment(org.reindexing_eta)}/>
                    {/*<HelpPopover code={'reindexing'}/>*/}
                </>
            ),
            duration: 0
        })
    }
}

const useAfterOrgLoad = ()=>{
    const callbacks = useRef([])

    const org = useCurrentOrg()

    useEffect(()=>{
        if(org) {
            callbacks.current.map(cb => cb(org))
            callbacks.current = []
        }
    },[org?.slug])

    return cb => {
        callbacks.current.push(cb)
    }
}

export default useCurrentOrg
export {useRefreshOrg, checkReindexing, useAfterOrgLoad}