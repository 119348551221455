import {Button, List, message, Modal, notification, Popover, Progress, Space} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import SparkMD5 from "spark-md5";
import sha256 from "js-sha256";
import Evaporate from "evaporatejs";
import {AppContext} from "../../contexts/AppContext";
import {useUploadsDispatch, useUploadsState} from "../../contexts/UploadsContext";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import api from "../api";
import {useAggsDispatch} from "../../contexts/AggsContext";
import useConsumer from "../../channels/consumer";
import {SessionContext} from "../../contexts/SessionContext";
import uuid from "../helpers/uuid";
import mapLimit from 'async/mapLimit';
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import PauseOutlined from "@ant-design/icons/lib/icons/PauseOutlined";
import PlayCircleOutlined from "@ant-design/icons/lib/icons/PlayCircleOutlined";
import pluralize from "pluralize";
import {useEditAssetsDispatch, useEditAssetsState} from "../../contexts/EditAssetsContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useTranslation} from "react-i18next";
import {FileFilled, OrderedListOutlined} from "@ant-design/icons";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import ListItem from "antd/es/upload/UploadList/ListItem";

export default ({quickUpload})=> {
    const {state} = useContext(AppContext);
    const {jwt} = state

    const {state: sessionState} = useContext(SessionContext);
    const {sessionGuid, currentOrg} = sessionState;

    const {uploads, uploadingAssets, fileAdds, disconnected} = useUploadsState();
    const uploadsDispatch = useUploadsDispatch()

    const assetsDispatch = useAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch()

    const aggsDispatch = useAggsDispatch();

    const editAssetDispatch = useEditAssetsDispatch()

    const onProgress = (asset, percent, stats) => {
        uploadsDispatch({type:'uploadProgress', asset, percent, stats})
    }

    const consumer = useConsumer();
    const sub = useRef();

    const uploadAsset = (asset, cb) => {
        console.log('adding to evaporate:', asset)
        const file = _.find(FileQueue[asset.upload.guid], {upload_guid: asset.upload_guid})

        if(!file) return console.log('No matching file in queue', FileQueue);

        uploader.current.then((evaporate) => {
            evaporate.add({
                file: file,
                name: asset.s3_upload_key,
                progress: (percent, stats) => onProgress(asset, percent, stats),
                complete: (xhr, awsObjectKey) => {
                    console.log('Complete!', awsObjectKey)
                    cb && cb()
                },
                error: (msg) => {
                    // FIXME: 404 error on part PUT
                    console.log('Evaporate Error:', msg)
                    evaporate.resume().then(()=> console.log('Resumed.'))
                },
                paused: () => console.log('Paused'),
                pausing: () => console.log('Pausing'),
                resumed: () => console.log('Resumed'),
                cancelled: () => console.log('Cancelled'),
                started: key => {
                    api.post(`/api/assets/started_upload`, {key})
                },
                uploadInitiated: () => assetsDispatch({type:'updateAsset', asset: {...asset, aasm_state:'uploading'}}),
                warn: (msg) => {
                    console.log('Evaporate Warning:', msg)
                }
            }).then(
                () => {onFinish(asset)},
                (reason) => console.log('File did not upload successfully:', reason)
            );
        });
    }
    // TODO: useEffect for new version uploads to pass to `uploadAsset`

    //--------------------------------
    // Setup ActionCable Subscription:
    //--------------------------------

    const {t} = useTranslation();
    const onReady = (data, source)=> {
        // onReady {type: "ready", id: 1305, total: 0} cable
        console.log('onReady', data, source)
        clearInterval(preparingInterval.current)
        preparingInterval.current = null

        if(data.existing > 0) {
            notification.warning({
                message: t('existing-assets-skipped-message', 'Skipping existing Assets'),
                description: (
                    <VerticalSpace>
                        <div>{`${data.existing} ${t('existing-assets-notice', 'Assets already present in Upload, skipping.')}`}</div>
                        <ExistingAssetsModal id={data.id}/>
                    </VerticalSpace>
                ),
                duration: 0
            })
        }

        const per_page = 50

        let assets = []
        const getPage = (page)=> {
            api(`/api/bulk_uploads/${data.id}?page=${page}&per_page=${per_page}`).then(res => {
                if(res.data.status === 'processing') return setTimeout(() => getPage(1), 500)

                assets = assets.concat(res.data)
                totalUploads.current += res.data.length

                assetsDispatch({type: 'assetsAdded', assets: res.data});
                loadedAssetsDispatch({type: 'assetsAdded', assets: res.data}); // IDs only

                uploadsDispatch({type:'addUploadingAssets', assets: res.data})

                if(res.headers['total-pages'] > page) {
                    getPage(page + 1)

                } else {
                    preparingRef.current && preparingRef.current()
                    mapLimit(assets, 5, uploadAsset)
                }
            })
        }

        aggsDispatch({type:'setTotal', total: data.total})

        getPage(1)
    }

    const totalUploads = useRef(0)

    useEffect(()=>{
        if(!sub.current && sessionGuid && currentOrg) {
            console.log('connecting to BulkUploadsChannel: ', sessionGuid, currentOrg.id, sub.current)

            sub.current = consumer.subscriptions.create({channel: "BulkUploadsChannel", guid: sessionGuid}, {
                connected: ()=> {
                    console.log('BulkUploadsChannel connected')
                },
                disconnected: ()=> {
                    console.log('BulkUploadsChannel disconnected!')
                },
                received: (data) => {
                    switch(data.type) {
                        case 'ready':
                            console.log('BulkUploadsChannel: ready')
                            // if the polling check has not been cleared:
                            if(preparingInterval.current) onReady(data, 'cable')
                            return

                        case 'retryUpload':
                            console.log('RETRYING UPLOAD:', data.asset)
                            uploadAsset(data.asset)
                            return

                        case 'progress':
                            preparingRef.current = message.open({
                                key: `preparing-${data.id}`,
                                type: 'loading',
                                content: <PreparingUploadMessage count={data.total} progress={data.progress}/>,
                                duration: 0
                            })
                            return
                    }
                }
            });
        }

        return ()=> {
            console.log('UploadManager unloaded')
            consumer.subscriptions.remove(sub.current)
        }
    }, [currentOrg?.id])

    //---------------------
    // Prepare Bulk Upload:
    //---------------------

    const preparingRef = useRef()
    const preparingInterval = useRef()

    useEffect(()=>{
        if(disconnected) {
            message.error('Please wait until reconnected before uploading');
            return
        }

        // POST entire file list to api w/ upload_guid, queue background job, send asset records to front end via cable:
        Object.keys(uploads).map((guid)=> {
            if(guid == 'new') return;

            const files = uploads[guid]

            uploadsDispatch({type:'filesProcessed', files, guid})

            const assets = _.compact(files.map(file => {
                // .filename can be manually set to override; e.g. <DokaButton/>

                // Replace narrow-space character for S3 / CloudFront compatibility:
                let filename = (file.filename || file.name).replace(/\u202F/g, ' ')
                let path = file.webkitRelativePath

                if(filename.match(/\//)) {
                    filename = _.last(file.name.split(/\//));
                    path = file.name
                }

                if(file.upload_guid || file.size == 0 || filename.match(/(\/|^)\./) || path.match(/(\/|^)\./)) return;

                file.upload_guid = uuid()

                return {
                    filename, path, file_size: file.size, upload_guid: file.upload_guid,
                    new_version: file.new_version, asset_id: file.asset_id
                };
            }))

            if(assets.length) {
                const blob = new Blob([JSON.stringify(assets)], {
                    type: 'application/json'
                });
                const formData = new FormData()
                formData.append('assets', blob)

                api.post(`/api/uploads/${guid}/assets?session_guid=${sessionGuid}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'}
                }).then(res => {
                    const id = res.data.bulk_upload_id

                    preparingRef.current = message.open({
                        key: `preparing-${id}`,
                        type: 'loading',
                        content: <PreparingUploadMessage count={res.data.count} progress={0}/>,
                        duration: 0
                    })

                    // Failsafe interval in case `ready` message missed
                    preparingInterval.current = setInterval(()=> {
                        api(`/api/bulk_uploads/${id}/status`).then(res => {

                            console.log(`Checking Bulk Upload ${id} status: `, res.data.status)

                            if(res.data.status == 'ready') onReady(res.data, 'poll')
                        })
                    }, 3_000)
                })
            }
        })
    }, [fileAdds])

    const {currentUpload} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch();

    const onFinish = (asset)=>{
        api.get(`/api/assets/${asset.guid}/set_uploaded`).then(res => {
            if(currentUpload?.id === res.data.upload_id) {
                delete res.data.aasm_state // prevent race condition with Sidekiq
                assetsDispatch({type:'updateAsset', asset: res.data})
                editAssetDispatch({type:'increment'})
                totalUploads.current--;

                if(currentUpload) {
                    api(`/api/uploads/${currentUpload?.id}`).then(res => {
                        assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});
                    })
                }
            }
        })
    }

    //--------------------------------------
    // Create Evaporate.js Instance For Org:
    //--------------------------------------

    const uploader = useRef();
    window.uploader = uploader;

    useEffect(()=> {
        if(!currentOrg) return;

        const config = {
            logging: false,
            progressIntervalMS: 250,
            signerUrl: `${Config.apiHost}/api/assets/sign`,
            aws_key: Config.awsKey,
            bucket: Config.uploadBucket,
            s3Acceleration: true,
            computeContentMd5: true,
            cryptoMd5Method: (data)=> { btoa(SparkMD5.ArrayBuffer.hash(data, true)) },
            cryptoHexEncodedHash256: sha256,
            signHeaders: {
                'Authorization': `Bearer ${jwt}`,
                'OrganizationId': currentOrg.id
            }
        }

        uploader.current = Evaporate.create(config);

        const handleBeforeunload = (e)=> {
            console.log('totalUploads:')

            if(totalUploads.current == 0) {
                delete e['returnValue'];
                return true;
            }

            e.preventDefault();
            const msg = `${totalUploads.current} ${pluralize('file', totalUploads.current)} remaining to upload, leaving now will lose them.`
            e.returnValue = msg

            return msg
        }

        window.addEventListener('beforeunload', handleBeforeunload);

        return ()=>{
            window.removeEventListener('beforeunload', handleBeforeunload)
        }
    }, [currentOrg?.id])

    const totalPercent = _.sumBy(uploadingAssets, a => a.stats?.totalUploaded || 0) / _.sumBy(uploadingAssets, 'file_size')

    const [paused, setPaused] = useState(false)

    const pause = ()=> {
        uploader.current.then(ev => ev.pause(undefined))
        setPaused(true)
    }

    const resume = ()=> {
        uploader.current.then(ev => ev.resume())
        setPaused(false)
    }

    if(!totalPercent || totalPercent == 0 || totalPercent == 1) return '';

    if(isMobile() || quickUpload) return <></>

    return (
        <Popover
            title={'Current Uploads'}
            overlayStyle={{maxWidth:400}}
            content={
                <>
                    {!paused && (
                        <Button onClick={pause} icon={<PauseOutlined/>}>Pause {totalUploads.current} {pluralize('Upload', totalUploads.current)}</Button>
                    ) || (
                        <Button onClick={resume} icon={<PlayCircleOutlined/>}>Resume {totalUploads.current} {pluralize('Upload', totalUploads.current)}</Button>
                    )}
                    {/*{uploadingAssets.map(asset => (*/}
                    {/*    <div key={asset.id}>*/}
                    {/*        <small>{asset.filename}</small>*/}
                    {/*        <Progress percent={(asset.percent * 100).toFixed()} size="small" status="active" />*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </>
            }
        >
            <Button type={'text'}>
                <Progress
                    type="circle"
                    status={'active'}
                    percent={totalPercent * 100}
                    width={40}
                    format={p => <span style={{color:'white'}}>{p.toFixed()}%</span>}
                />
            </Button>
        </Popover>
    )
}

const PreparingUploadMessage = ({count, progress})=>{
    const {t} = useTranslation();
    return (
        <>
            {count === 1 ? t('preparing-upload-file','Preparing upload of the file') : t('preparing-upload-files','Preparing upload of {{count}} files', {count})}
            {progress < 1 && (
                <>
                    <br/>
                    <Progress percent={Math.round(progress * 100)} status={'active'}/>
                </>
            )}
        </>
    )
}

const ExistingAssetsModal = ({id})=>{
    const {t} = useTranslation();

    const [open, setOpen] = useState()
    const [loading, setLoading] = useState()
    const [filenames, setFilenames] = useState()

    useEffect(() => {
        if(!open) return;

        setLoading(true)
        api(`/api/bulk_uploads/${id}/existing_assets`).then(res => {
            setLoading(false)
            setFilenames(res.data)
        })
    }, [open, id]);

    return (
        <>
            <Button icon={<OrderedListOutlined/>} block onClick={()=> setOpen(true)}>{t('view', 'View')}</Button>
            <Modal
                open={open}
                onCancel={()=> setOpen(false)}
                footer={null}
                title={t('existing-assets','Existing Assets')}
            >
                <List
                    dataSource={filenames}
                    loading={loading}
                    bordered
                    size={'small'}
                    renderItem={filename => <List.Item><FileFilled/> {filename}</List.Item>}
                />
            </Modal>
        </>
    )

}