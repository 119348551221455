import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Descriptions,
    message,
    Popconfirm,
    Popover,
    Select,
    Tag,
    Tooltip,
    Typography,
    Space,
    Button,
    Switch, Badge, Divider, Radio, DatePicker, Collapse, Avatar, Modal, ConfigProvider
} from "antd";
import TagOutlined from "@ant-design/icons/lib/icons/TagOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import ApartmentOutlined from "@ant-design/icons/lib/icons/ApartmentOutlined";
import api from "../api";
import User from "../helpers/User";
import TimeAgo from "../helpers/TimeAgo";
import {AbilityContext, Can} from "../helpers/Can";
import {
    CalendarOutlined, CheckOutlined,
    CloseOutlined, CloudServerOutlined,
    EyeInvisibleOutlined, FormOutlined, LoadingOutlined, ProfileOutlined, SettingOutlined, ShoppingCartOutlined,
    UnorderedListOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";
import {Formik, useFormikContext} from "formik";
import Links from "./Links";
import {MergeIcon, SynonymIcon, TaxonomyIcon} from "../helpers/icons";
import {TagFilter} from "./TaxonomyTree";
import {useFilters} from "../helpers/useFilters";
import {SessionContext} from "../../contexts/SessionContext";
import {useAggsState} from "../../contexts/AggsContext";
import moment from 'moment'
import day from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
day.extend(utc)
day.extend(timezone)
import {Checkbox, Form, FormItem, Input, Select as FormikSelect} from "formik-antd";
import TagSynonymManager from "./TagSynonymManager";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import {getLocale} from "./DateRangePicker";
import {EditableTextArea} from "@/components/widgets/AssetModal";
import FloatLabel from "@/components/helpers/FloatLabel";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import useConsumer from "@/channels/consumer";
import HelpPopover from "@/components/HelpPopover";
import IsolatedDiv from "@/components/helpers/IsolatedDiv";

export default ({asset, bulk, upload, subType, popoverPlacement, placeholder, bordered=false}) => {
    const {t} = useTranslation();
    const dispatch = useAssetsDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const getTags = ()=> {
        if(!asset) return;
        return subType ? asset.tags.filter(t => t.sub_type == subType) : asset.tags
    }

    const [tags, setTags] = useState(getTags() || []);

    const names = tags.map(tag => tag.name)
    const [tagNames, setTagNames] = useState(names)

    useEffect(()=>{
        if(bulk) return;
        // When tags come in from ActionCable or AssetModal edit:
        setTagNames(names)
        setTags(getTags())
    }, [asset?.tags])

    // HACK: prevent Enter key from Typography editable from removing the Tag
    let enterPressed = false;

    const { setFieldValue, values } = bulk ? useFormikContext() : {};

    const handleChange = (value) => {
        if(!bulk) {
            const remove_tag_names = value.length < tagNames.length && _.difference(tagNames, value);
            const add_tag_names = value.length > tagNames.length && _.difference(value, tagNames);

            if(remove_tag_names.length && enterPressed) return;

            // Preemptively remove tags from state before API call
            if(remove_tag_names.length) {
                setTags([..._.filter(tags, (t) => remove_tag_names.indexOf(t?.name) == -1)])
            }

            api.put(`/api/assets/${asset.id}/tag`, {asset: {remove_tag_names, add_tag_names, tag_sub_type: subType}}).then(res => {
                setTags(res.data.tags);
                setTagNames(_.map(res.data.tags, 'name'))
                dispatch({type:'updateAsset', asset: {...asset, ...res.data}});
                editAssetDispatch({type:'increment'})
                message.success(t('message-tags-updated','Tags updated.'))
            })

        } else if(bulk) {
            setFieldValue('tag_names', (values.tag_names || []).concat(value))

            api.post(`/api/tags/bulk_find`, {tag_names: value}).then(res => {
                setTags(res.data);
                setTagNames(_.map(res.data, 'name'))
            })
        }
    }

    const removeTag = (name)=> {
        const newTagNames = _.without(tagNames, name)
        handleChange(newTagNames);
    }

    const tagRender = ({value}) => {
        value = value?.trim()

        const tag = _.find(tags, {name: value}) || {name: value}

        const onClose = () => {
            removeTag(value)
        }

        return <EditableTag editable tag={tag} onClose={onClose} bulk={bulk} popoverPlacement={popoverPlacement} asset={asset}/>
    }

    const [searchResults, setSearchResults] = useState([]);

    const lastSearchValue = useRef();
    const onSearch = (value)=> {
        lastSearchValue.current = value;
        if(value !== '') {
            api('/api/tags', {params: {list: 'visible', q: value}}).then(res => {
                if(value !== lastSearchValue.current || res.data.error) return;
                setSearchResults(res.data)
            })
        } else {
            setSearchResults([])
        }
    }

    const [tagSuggesters, setTagSuggesters] = useState(upload?.tag_suggesters || []);

    const onFocus = ()=>{
        if(upload || !asset) return;

        api(`/api/assets/${asset.id}/tag_suggesters`).then(res => {
            setTagSuggesters(res.data)
        })
    }

    return asset?.editable || asset?.taggable || bulk ? (
        <Select
            className={'editable-tag-select'}
            aria-label={t('tag-selection-input', 'Tag Selection Input')}
            mode="tags"
            bordered={bordered}
            style={{width: '100%'}}
            placeholder={placeholder || t('placeholder-add-typed-tags','Add {{type}} Tags...', {type: t(subType,subType)})}
            onChange={handleChange}
            tagRender={tagRender}
            value={names}
            tokenSeparators={[',',';']}
            onInputKeyDown={e => enterPressed = e.key == 'Enter'}
            onSearch={onSearch}
            onFocus={onFocus}
        >
            {!!searchResults.length && (
                <Select.OptGroup label={t('existing-tags','Existing Tags')} key={'existing'}>
                    {_.compact(searchResults).map(tag => (
                        <Select.Option value={tag.name} key={tag.id}>
                            {tag.sub_type == 'person' && <UserOutlined style={{marginRight:'.5em'}}/>}
                            {tag.sub_type == 'event' && <CalendarOutlined style={{marginRight:'.5em'}}/>}
                            {tag.sub_type == 'product' && <ShoppingCartOutlined style={{marginRight:'.5em'}}/>}
                            {tag.name}

                            {tag.sub_type == 'event' && tag.date_start && (
                                <span style={{marginLeft:'.5em'}}>
                                    {moment(tag.date_start).format('YYYY-MM-DD')}
                                    {tag.date_end != tag.date_start && (
                                        <> - {moment(tag.date_end).format('YYYY-MM-DD')}</>
                                    )}
                                </span>
                            )}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )}

            {tagSuggesters?.map(ts => (
                <Select.OptGroup label={`${ts.name} ${ts.required && `(${t('required', 'required')})` || ''}`} key={ts.id}>
                    {_.compact(ts.tag_names).map(name => (
                        <Select.Option value={name} key={`${ts.id}-${name}`}>{name}</Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>
    ) : getTags()?.map(tag => <EditableTag key={tag.id} tag={tag} asset={asset}/>)
    ;
}

const EditableTag = ({tag: editTag, editable, onClose, bulk, filter, applied, hidden, badgeCount, onClick, hideIcon,
                         onUpdate, popoverPlacement, auto, face, disableTypeSelect=false, disableRemove=false, large,
                         asset, afterMerge, children})=>{
    const {t} = useTranslation();
    const dispatch = useAssetsDispatch();
    const {tags} = useAssetsState();

    const findTag = () => _.find(tags, {tag_id: editTag.tag_id}) || {}

    const currentTag = (bulk || filter) ? editTag : findTag()

    const [tag, setTag] = useState(currentTag || editTag || {})

    useEffect(()=> {
        if(bulk || filter) return;

        setTag(findTag())
    }, [tags])

    useEffect(()=> {
        if(!bulk) return;

        setTag(editTag)
        setName(editTag.name)
    }, [editTag?.id])

    const [name, setName] = useState(tag.name || '');

    const onNameChange = (newValue)=>{
        setName(newValue);
        api.put(`/api/tags/${tag.tag_id}`, {tag:{name: newValue}}).then(res => {
            tag.name = newValue
            dispatch({type:'updateTag', tag})
            message.success(t('message-name-updated','Name updated.'))
            onUpdate && onUpdate(tag)
        })
    }

    const [attrs, setAttrs] = useState(tag)
    const onChange = field => {
        return value =>{
            attrs[field] = value
            setAttrs({...attrs})

            api.put(`/api/tags/${tag.tag_id}`, {tag:{description: newValue}}).then(res => {
                message.success(t(`message-${field}-updated`,`${field.toProperCase()} updated.`))
                dispatch({type:'updateTag', attrs})
                onUpdate && onUpdate(attrs)
            })
        }
    }

    const [description, setDescription] = useState(tag.description || '');

    const onDescriptionChange = (newValue)=>{
        setDescription(newValue);
        api.put(`/api/tags/${tag.tag_id}`, {tag:{description: newValue}}).then(res => {
            tag.description = newValue
            dispatch({type:'updateTag', tag})
            message.success(t('message-description-updated','Description updated.'))
            onUpdate && onUpdate(tag)
        })
    }

    const [subType, setSubType] = useState(tag.sub_type);

    const onSubTypeChange = (newValue)=>{
        setSubType(newValue);
        api.put(`/api/tags/${tag.tag_id}`, {tag:{sub_type: newValue}}).then(res => {
            tag.sub_type = newValue
            dispatch({type:'updateTag', tag})
            message.success(t('message-type-setting-updated','Type setting updated.'))
            onUpdate && onUpdate(tag)
        })
    }

    const [dateStart, setDateStart] = useState(tag.date_start);
    const [dateEnd, setDateEnd] = useState(tag.date_end);

    const onDateStartChange = (newValues)=>{
        if(!newValues) return;

        setDateStart(newValues[0]);
        setDateEnd(newValues[1]);
        api.put(`/api/tags/${tag.tag_id}`, {tag:{date_start: newValues[0], date_end: newValues[1]}}).then(res => {
            tag.date_start = newValues[0]
            tag.date_end = newValues[1]
            dispatch({type:'updateTag', tag})
            message.success(t('message-date-updated','Date updated.'))
            onUpdate && onUpdate(tag)
        })
    }

    const [list, setList] = useState(tag.list);

    // Handle edits from elsewhere:
    useEffect(()=>{
        if(!tag) return;
        setList(tag.list)
        setName(tag.name)
        setDescription(tag.description || '')
        setDateStart(tag.date_start)
        setDateEnd(tag.date_end)
        setSubType(tag.sub_type)
        setInTaxonomy(tag.in_taxonomy);
        setTaxonomyPathNames(tag.taxonomy_path_names);
    }, [tag.list, tag.name, tag.description, tag.in_taxonomy, tag.taxonomy_path_names, tag.sub_type, tag.date_start, tag.date_end])

    const getColor = (value)=>{
        return {
            visible: 'success',
            searchable: 'warning',
            blocked: 'error'
        }[value] || 'default';
    }

    const getIcon = (value, select=false)=> {
        if(!select && inTaxonomy) return <TaxonomyIcon aria-label={t('in-taxonomy', 'In Taxonomy')}/>

        return {
            visible: <EyeOutlined aria-label={t('visible-tag', 'Visible Tag')}/>,
            searchable: <SearchOutlined aria-label={t('searchable-tag', 'Searchable Tag')}/>,
            blocked: <CloseCircleOutlined aria-label={t('blocked-tag', 'Blocked Tag')}/>
        }[value];
    }

    const onListChange = (newValue)=>{
        setList(newValue);
        api.put(`/api/tags/${tag.tag_id}`, {tag:{list: newValue}}).then(res => {
            tag.list = newValue
            dispatch({type:'updateTag', tag})
            message.success(t('message-list-updated','List updated.'))
        })
    }

    const onClickList = (e)=>{
        console.log(e)
        e.stopPropagation()
    }

    // HACK: https://github.com/ant-design/ant-design/issues/15392
    const onStart = ()=>{
        setTimeout(()=> {
            const list = document.querySelectorAll("div.ant-typography-edit-content > textarea.ant-input")
            for(let input of list) {
                input.addEventListener("keydown", (e) => {
                    if (e.keyCode == 8){
                        e.stopPropagation()
                    }
                })
            }
        },100)
    }

    const [visible, setVisible] = useState();
    const onOpenChange = (value)=>{
        setVisible(value)
    }

    useEffect(()=>{
        if(!visible) return;

        api(`/api/taggings/${tag.id}`).then(res => {
            setTag({...tag, ...res.data})
        })
    }, [visible])

    const ability = useContext(AbilityContext);

    const [inTaxonomy, setInTaxonomy] = useState(tag.in_taxonomy);
    const [taxonomyPathNames, setTaxonomyPathNames] = useState(tag.taxonomy_path_names);

    const addTaxonomy = ()=>{
        api.put(`/api/tags/${tag.tag_id}/add_taxonomy`).then(res => {
            dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            setInTaxonomy(true);
            setTaxonomyPathNames(res.data.taxonomy_path_names)
            setList(res.data.list)
            message.success(`${tag.name} ${t('message-added-to-taxonomy','Added to Taxonomy.')}`)
        })
    }

    const color = filter ? (applied ? 'blue' : null) : getColor(list)

    const icon =
        !hideIcon &&
        ((filter && (auto ? <></> : <TagOutlined aria-label={t('keyword-tag', 'Keyword Tag')}/>)) || getIcon(list));

    let style = {}
    if(large) style.fontSize = '1em'
    if(filter) style = {...style, cursor:'pointer'}
    else style = {...style, cursor: 'help', margin:'0 .5em .5em 0'}

    const [modalOpen, setModalOpen] = useState()

    const onKeyDown = e => {
        if(e.keyCode === 13) onClick()
        else if(e.key === 'i') setModalOpen(true)
    }

    const renderedTag = (
        <Tooltip title={t('i-to-view-details','Press "i" to view details')} trigger={'focus'}>
            <Tag
                color={color}
                icon={icon}
                onClick={onClick}
                style={style}
                tabIndex={0}
                onKeyDown={onKeyDown}
            >
                {!hideIcon && subType === 'person' && (tag.thumb_url ?
                        <Avatar src={tag.thumb_url} size={20} style={{marginRight:'.5em'}}/>
                        : <UserOutlined style={{marginRight:'.5em'}}/>
                )}
                {!hideIcon && subType === 'event' && <CalendarOutlined style={{marginRight:'.5em'}}/>}
                {!hideIcon && subType === 'product' && <ShoppingCartOutlined style={{marginRight:'.5em'}}/>}

                {!hideIcon && (tag.is_lead_tag || tag.lead_tag_id) && <SynonymIcon style={{marginRight:'.5em'}}/>}

                {inTaxonomy ? (
                    <strong>{name}</strong>
                ) : name}

                {!filter && editable && !disableRemove && (
                    <Popconfirm title={`Remove ${name}?`} onConfirm={onClose} zIndex={1033}>
                        <small><CloseOutlined style={{color: 'grey', marginLeft:'.5em', cursor:'pointer'}}/></small>
                    </Popconfirm>
                )}

                {hidden && <EyeInvisibleOutlined/>}

                {filter && (
                    <Badge count={badgeCount} style={{backgroundColor:'#fff', color:'#999'}} overflowCount={100000}/>
                )}
            </Tag>
        </Tooltip>
    )

    const [links, setLinks] = useState(tag.links || [])
    const getInfo = (visible)=>{
        if(visible && !auto) {
            api(`/api/tags/${tag.tag_id}?asset_id=${asset?.id}`).then(res => {
                setTag({...tag, ...res.data})
                setLinks(res.data.links)

                // Prevent bulk input from clearing out:
                if(!bulk) dispatch({type:'updateTag', tag: {...tag, ...res.data}});
            })
        }
    }
    
    const locale = getLocale();

    if(!tag.id) return renderedTag

    const title = (
        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <div>
                {auto && <><CloudServerOutlined/> {t('auto-tag-info', 'Auto Tag Info')}</>}
                {subType == 'keyword' && <><TagOutlined/> {t('keyword-tag-info', 'Keyword Tag Info')}</>}
                {subType == 'person' && <>{tag.thumb_url ?
                    <Popover content={<Avatar src={tag.thumb_url} size={200}/>} placement={'left'} zIndex={9000}>
                        <Avatar src={tag.thumb_url} size={30}/>
                    </Popover>
                    : <UserOutlined/>
                } {t('person-tag-info', 'Person Tag Info')}</>}
                {subType == 'event' && <><CalendarOutlined/> {t('event-tag-info', 'Event Tag Info')}</>}
                {subType == 'product' && <><ShoppingCartOutlined/> {t('product-info', 'Product Info')}</>}
            </div>

            {editable && (
                <div style={{marginLeft: '1em'}}>
                    <MergeTagButton tag={tag} afterMerge={afterMerge}/>
                </div>
            )}
        </div>
    )

    const content = (
        <IsolatedDiv>
            <Descriptions bordered size='small' column={1}>
                <Descriptions.Item label={t('name', 'Name')}>
                    <Typography.Text editable={editable && {onChange: onNameChange, onStart}}>
                        {name}
                    </Typography.Text>
                </Descriptions.Item>
                {(editable || description) && (
                    <Descriptions.Item label={t('description', 'Description')}>
                        <EditableTextArea
                            name={'Description'}
                            value={description}
                            onChange={onDescriptionChange}
                            editable={editable}
                        />
                    </Descriptions.Item>
                )}

                {editable && !disableTypeSelect && (
                    <Descriptions.Item label={t('type','Type')}>
                        <Radio.Group
                            options={[{label:t('keyword','Keyword'), value:'keyword'}, {label:t('person','Person'), value:'person'}, {label:t('event','Event'), value:'event'}, {label: t('product','Product'), value: 'product'}]}
                            onChange={e => onSubTypeChange(e.target.value)}
                            size={'small'}
                            optionType="button"
                            value={subType}
                        />
                    </Descriptions.Item>
                )}

                {subType == 'event' && (
                    <Descriptions.Item label={t('date','Date')}>
                        {editable ? (
                            <div onClick={onClickList}>
                                <DatePicker.RangePicker
                                    value={dateStart ? [day(dateStart), day(dateEnd)] : null}
                                    onChange={onDateStartChange}
                                    getPopupContainer={trigger => trigger.parentElement}
                                    locale={locale}
                                />
                            </div>
                        ) : (
                            <>
                                {moment(dateStart).format('YYYY-MM-DD')}
                                {dateStart != dateEnd && (
                                    <>- {moment(dateEnd).format('YYYY-MM-DD')}</>
                                )}
                            </>
                        )}
                    </Descriptions.Item>
                )}

                {subType == 'product' && (
                    <>
                        <Descriptions.Item label={'GTIN'}>
                            <Typography.Text editable={editable && {onChange: onChange('gtin'), onStart}}>
                                {attrs?.gtin}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={'SKU'}>
                            <Typography.Text editable={editable && {onChange: onChange('sku'), onStart}}>
                                {attrs?.sku}
                            </Typography.Text>
                        </Descriptions.Item>
                    </>
                )}

                {(editable || !!links.length) && (
                    <Descriptions.Item label={t('links','Links')}>
                        <Links what={{...tag, editable, id: tag.tag_id, links}} type={'Tag'}/>
                    </Descriptions.Item>
                )}

                {ability.can('show', 'Tag') && inTaxonomy && (
                    <Descriptions.Item label={t('taxonomies','Taxonomies')}>
                        {taxonomyPathNames?.map((taxonomy_path,i) => (
                            <div key={i}>
                                <ApartmentOutlined/> {taxonomy_path.join(' / ')}
                            </div>
                        ))}
                    </Descriptions.Item>
                ) || (ability.can('set_list', 'Tag') && !auto && (
                    <Descriptions.Item label={t('taxonomies','Taxonomies')}>
                        <Button ghost size='small' type={'primary'} icon={<UnorderedListOutlined />} onClick={addTaxonomy}>
                            {t('button-add-to-taxonomy','Add To Taxonomy')}
                        </Button>
                    </Descriptions.Item>
                ))}

                {ability.can('show', 'Tag') && !auto && (
                    <Descriptions.Item label={t('list','List')}>
                        <Can I={'set_list'} a={'Tag'}>
                            <Select onChange={onListChange} style={{width:150}} value={list} onClick={onClickList}>
                                {['visible', 'searchable', 'blocked'].map(level => {
                                    return <Select.Option key={level} value={level}>{getIcon(level, true)} {t(level,level)}</Select.Option>
                                })}
                            </Select>
                        </Can>

                        <Can not I={'set_list'} a={'Tag'}>
                            {getIcon(list)} {t(list,list)}
                        </Can>
                    </Descriptions.Item>
                )}

                {(editable || tag.lead_tag || tag.is_lead_tag) && (
                    <Descriptions.Item label={t('synonym','Synonym')}>
                        <TagSynonymManager tag={tag} onUpdate={onUpdate}/>
                    </Descriptions.Item>
                )}
                {tag.tagged_at && (
                    <Descriptions.Item label={t('tagged','Tagged')}>
                        <TimeAgo date={tag.tagged_at}/> {t('by', 'by')} {tag.source === 'rekognition' ? t('facial-recognition','Facial Recognition') : (tag.source === 'manual' && tag.tagged_by ? <User user={tag.tagged_by}/> : t(tag.source || 'system', tag.source || 'System'))}
                    </Descriptions.Item>
                )}
            </Descriptions>

            {subType == 'event' && (
                <EventTagDescriptions tag={tag} onUpdate={onUpdate} onStart={onStart} editable={editable}/>
            )}

            <TagFilterWrapper tag={tag} auto={auto} face={face}/>
        </IsolatedDiv>
    )

    return (
        <>
            <Popover
                trigger={children ? 'click' : 'hover'}
                mouseEnterDelay={0.5}
                zIndex={1032}
                overlayStyle={{width: 500}}
                title={title}
                placement={popoverPlacement || (filter && 'right')}
                onOpenChange={getInfo}
                content={content}
            >
                {children || renderedTag}
            </Popover>

            <Modal
                title={title}
                open={modalOpen}
                onCancel={e => setModalOpen(false) && e.stopPropagation()}
                footer={null}
                afterOpenChange={getInfo}
            >
                {content}
            </Modal>
        </>
    )
}

const TagFilterWrapper = ({tag, auto, face})=> {
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const name = tag.name.toLowerCase()

    const {filters, setFilters, replaceFilters} = useFilters();

    let field;
    if(auto) field = 'auto_tags'
    else if(face) field = 'face_tags'
    else field = 'tags'

    const filtered = filters[field].indexOf(name) !== -1
    const hideFiltered = filters[field].indexOf(name) !== -1

    const {aggs} = useAggsState();

    const toggleFilter = ()=> {
        const obj = {}
        obj[field] = name
        setFilters(filtered, obj)
    }

    const toggleHideFilter = ()=> {
        const obj = {}
        obj['hide_' + field] = name

        setFilters(hideFiltered, obj )
    }

    const addFilter = (e)=> {
        e.stopPropagation();
        if(hideFiltered) toggleHideFilter();
        toggleFilter();
    }

    const hideFilter = (e)=> {
        e.stopPropagation();
        if(filtered) toggleFilter();
        toggleHideFilter();
    }

    const getPath = useOrgPath()

    const showAll = (e)=> {
        e.stopPropagation();

        const obj = {}
        obj[field] = [name]
        replaceFilters(obj, getPath(`/explore`));
    }

    let aggField
    if(auto) aggField = 'auto_tag_names' //auto ? aggs.auto_tag_names : aggs.visible_tag_names;
    else if(face) aggField = 'face_names'
    else aggField  = 'visible_tag_names'

    const aggValue = aggs[aggField]

    const count = aggs && _.find(aggValue?.buckets, agg => agg.key === name)?.doc_count || 0
    const total = tag.visible_assets_count

    return (
        <>
            <Divider/>
            <TagFilter {...{filtered, hideFiltered, addFilter, hideFilter, showAll, count, total}}/>
        </>
    )
}

const EventTagDescriptions = ({tag, tagId, onUpdate, onStart, editable})=>{
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();
    const [attrs, setAttrs] = useState(tag)

    if(tag.editable) editable = true

    const onChange = (field)=>{
        return (value)=> {
            attrs[field] = value
            setAttrs({...attrs});

            const values = {}
            values[field] = value

            api.put(`/api/tags/${tagId || tag.tag_id}`, {tag: values}).then(res => {
                assetsDispatch({type: 'updateTag', tag})
                message.success(t('message-event-updated','Event updated.'))
                onUpdate && onUpdate(field)
            })
        }
    }

    return (
        <Collapse style={{marginTop:'1em'}}>
            <Collapse.Panel header={<><CalendarOutlined/> {t('event-details','Event Details')}</>}>
                <Space direction={'vertical'} style={{width:'100%'}}>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item label={t('organization','Organization')} labelStyle={{width:50}}>
                            <Typography.Text editable={editable && {onChange: onChange('featured_organization_name'), onStart}}>
                                {attrs.featured_organization_name || ''}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('country-code','Country Code')}>
                            <Typography.Text editable={editable && {onChange: onChange('location_country_code'), onStart}}>
                                {attrs.location_country_code || ''}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('country','Country')}>
                            <Typography.Text editable={editable && {onChange: onChange('location_country'), onStart}}>
                                {attrs.location_country || ''}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('state','State')}>
                            <Typography.Text editable={editable && {onChange: onChange('location_state'), onStart}}>
                                {attrs.location_state || ''}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('city','City')}>
                            <Typography.Text editable={editable && {onChange: onChange('location_city'), onStart}}>
                                {attrs.location_city || ''}
                            </Typography.Text>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('location','Location')}>
                            <Typography.Text editable={editable && {onChange: onChange('location_name'), onStart}}>
                                {attrs.location_name || ''}
                            </Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('related-tags','Related Tags')}>
                            {editable ? (
                                <Select name={'related_tag_names'} mode='tags' style={{width:'100%'}} value={attrs.related_tag_names} onChange={onChange('related_tag_names')}/>
                            ) : (
                                <>{attrs.related_tag_names?.map(t => <Tag key={t}>{t}</Tag>)}</>
                            )}
                        </Descriptions.Item>
                    </Descriptions>

                    <Typography.Text type={'secondary'}>
                        <em>
                            {t('added','Added')} <TimeAgo date={tag.created_at}/> {t('by','by')} <User user={tag.user}/>
                        </em>
                    </Typography.Text>
                </Space>
            </Collapse.Panel>
        </Collapse>
    )
}

export {EditableTag, TagFilterWrapper, EventTagDescriptions}

const MergeTagButton = ({tag, afterMerge, iconOnly}) => {
    const {t} = useTranslation();
    const consumer = useConsumer();
    const userSub = useRef();

    const [merging, setMerging] = useState()
    const [visible, setVisible] = useState()

    return (
        (<IsolatedDiv>
            <Tooltip title={iconOnly && t('merge-into-button','Merge Into')}>
                <Button
                    size={'small'}
                    icon={!iconOnly && <MergeIcon/>}
                    type={iconOnly ? 'default' : 'primary'}
                    ghost={!iconOnly}
                    onClick={() => setVisible(true)}
                    loading={merging}
                >
                    {iconOnly ? <MergeIcon/> : t('merge-into-button','Merge Into') + '...'}
                </Button>
            </Tooltip>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setMerging(true)
                    const key = `merge-tag-${tag.id}`
                    message.loading({content: t('message-merging-tag','Merging Tag...'), key})

                    if(!userSub.current) {
                        userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                            received: (data) => {
                                switch(data.type) {
                                    case 'tagMergeJobDone':
                                        if(data.tag_1_id === tag.id) {
                                            message.destroy(key)
                                            message.success(t('message-tag-merged','Tag Merged!'))
                                            setMerging(false)

                                            afterMerge && afterMerge()
                                        }
                                        return
                                }
                            }
                        });
                    }

                    api.post( `/api/tags/${tag.id}/merge_into`, values).then(res => {
                        actions.resetForm();
                        actions.setSubmitting(false);

                        message.success(t('tags-merging-please-wait','Tags merging, please wait...'))
                        setVisible(false)

                    }).catch((err)=>{
                        console.log(err)
                        actions.setSubmitting(false);
                    })
                }}
            >
                {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                    const autoFocusInput = useRef(null);

                    const afterOpenChange = (open)=> {
                        setTimeout(()=>{
                            autoFocusInput.current?.focus();
                        }, 100)
                    }

                    const [data, setData] = useState()
                    const [fetching, setFetching] = useState()

                    const handleSearch = _.debounce(val => {
                        if (val) {
                            // setValue(val)
                            setFetching(true)

                            api(`/api/tags?q=${val}&list_not=blocked&per_page=100`).then(res => {
                                setFetching(false)
                                setData(res.data.filter(t => t.tag_id !== tag.tag_id));
                            })
                        } else {
                            setData()
                        }
                    }, 250);

                    const onKey = (e)=> {
                        if (e.key === 'Backspace' || e.key === 'Enter') {
                            e.stopPropagation()
                        }
                    }

                    return (
                        (<Modal
                            zIndex={2001}
                            onCancel={()=> setVisible(false)}
                            open={visible}
                            title={<><MergeIcon/> {t('merging', 'Merging')} "{tag.name}" {t('into','Into')}:</>}
                            afterOpenChange={afterOpenChange}
                            footer={
                                <Space>
                                    <HelpPopover code={'merge-tags'}/>

                                    <Button
                                        loading={isSubmitting}
                                        onClick={submitForm}
                                        icon={<CheckOutlined/>}
                                        disabled={!values.tag_2_id}
                                    >
                                        {t('button-merge-tags','Merge Tags')}
                                    </Button>
                                </Space>
                            }
                        >
                            <Form onSubmit={handleSubmit} layout="vertical">
                                <VerticalSpace>
                                    <FormikSelect
                                        autoFocus
                                        ref={autoFocusInput}
                                        disabled={isSubmitting}
                                        showSearch
                                        allowClear
                                        style={{width:'100%'}}
                                        notFoundContent={fetching ? <LoadingOutlined /> : null}
                                        placeholder={<>{t('search', 'Search')}...</>}
                                        defaultActiveFirstOption
                                        showArrow
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        onSelect={val => {
                                            setFieldValue('tag_2_id', val);
                                        }}
                                        value={values.tag_2_id}
                                        className={'add-person-select'}
                                        onKeyDown={onKey}
                                        onKeyUp={onKey}
                                        onKeyPress={onKey}
                                    >
                                        {data?.map(t => <Select.Option key={t.tag_id} tag_2_id={t.tag_id}>{t.name} <Tag>{t.sub_type.toProperCase()}</Tag></Select.Option>)}
                                    </FormikSelect>

                                    <Checkbox name={'set_synonym'}> {t('set-as-synonym','Set as Synonym')} </Checkbox>
                                </VerticalSpace>
                            </Form>
                        </Modal>)
                    );
                }}
            </Formik>
        </IsolatedDiv>)
    );
}

export {MergeTagButton}