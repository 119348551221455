import React, {useEffect, useState} from "react";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import {Tooltip, Card, Divider, Row, Col, Button, Typography, message, Avatar} from "antd";
import Links from "../widgets/Links";
import Collection from "./Collection";
import {Parser} from "html-to-react";
import {useViewMode} from "./ViewMenu";
import {TagCloud} from "./ExploreFilters";
import {LinkOutlined, TagOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default ()=>{
    const {t} = useTranslation();
    const {currentAssetGroup, currentTag} = useAssetGroupState()

    const {tocHidden} = useViewMode()

    const {gridColor} = useViewMode()

    let style = {textAlign:'center', padding: '1em'}
    if(gridColor == '#000000' || gridColor == '#4a4a4a') style.color = '#ddd'

    const dividerStyle = {backgroundColor: gridColor, color: style.color, margin:0, padding: '16 0', borderTopColor: style.color}

    const customSort = currentAssetGroup?.children_sort_order === 'custom'

    const sortCollections = ()=> {
        const assetGroups = currentAssetGroup.collections || currentAssetGroup.folders
        return customSort ? _.sortBy(assetGroups,'position') : assetGroups
    }

    const [sortedCollections, setSortedCollections] = useState(currentAssetGroup && sortCollections())

    useEffect(()=>{
        setSortedCollections(currentAssetGroup && sortCollections())
    }, [currentAssetGroup?.children_sort_order, currentAssetGroup?.id, currentAssetGroup?.folder?.id, currentAssetGroup?.collections?.length])

    const onReorder = (collection, dragCollection)=> {
        const index = _.findIndex(sortedCollections, {id: dragCollection.id})
        const insertIndex = _.findIndex(sortedCollections, {id: collection?.id})
        sortedCollections.splice(index, 1)
        sortedCollections.splice(insertIndex, 0, dragCollection)

        setSortedCollections([...sortedCollections])
    }

    if(tocHidden) return <></>
    if(['person', 'event', 'product','keyword'].includes(currentTag?.sub_type)) return <TagToc/>
    if(!currentAssetGroup?.enable_toc) return <></>

    return (
        <div style={{backgroundColor: gridColor}}>
            {currentAssetGroup.enable_poster_image && currentAssetGroup.poster_image && (
                <img src={currentAssetGroup.poster_image.cover_url} style={{objectFit:'cover', width:'100%', height:300}} alt={currentAssetGroup.poster_image.alt_text || currentAssetGroup.poster_image.description}/>
            )}
            <div style={style}>
                <h1 style={{color: style.color}}>{currentAssetGroup.name}</h1>

                {currentAssetGroup.toc_copy && (
                    <div style={{whiteSpace: 'pre-wrap', marginBottom:'1em'}}>{(new Parser).parse(currentAssetGroup.toc_copy)}</div>
                )}

                {currentAssetGroup.toc_contact && (
                    <>
                        <Divider style={dividerStyle}>Contact</Divider>
                        <div style={{margin:'1em'}}>
                            {currentAssetGroup.toc_contact.split(/,|;/).map((email, i) => (
                                <span key={i}><a href={`mailto:${email}`} key={email}><MailOutlined/> {email}</a><br/></span>
                            ))}
                        </div>
                    </>
                )}

                {!!currentAssetGroup.links?.length && (
                    <>
                        <Divider style={dividerStyle}>Links</Divider>
                        <div style={{marginLeft:'auto', marginRight:'auto'}}>
                            <Links centered what={currentAssetGroup} type={'AssetGroup'} readOnly/>
                        </div>
                    </>
                )}
            </div>

            {sortedCollections?.length > 0 && (
                <>
                    <Divider style={dividerStyle}>{currentAssetGroup.type === 'Collection' ? t('sub-collections','Sub-Collections') : t('bins','Bins')}</Divider>
                    <Row style={{marginTop: '2em', marginBottom:'1em', marginLeft:'.5em'}} gutter={[16,8]}>
                        {sortedCollections.map((c,i) => {
                            return (
                                <Col key={i} lg={6} xs={24}>
                                    <Collection c={c} draggable={customSort} onReorder={onReorder}/>
                                </Col>
                            );
                        })}
                        <div style={{clear:'both', float:'none'}}/>
                    </Row>
                </>
            )}

            <Divider style={{...dividerStyle, padding:'1em'}}>{currentAssetGroup?.folder?.name} {t('assets','Assets')}</Divider>
        </div>
    )
}

const TagToc = ()=>{
    const {t} = useTranslation();
    const {currentTag} = useAssetGroupState()

    const {gridColor} = useViewMode()

    let style = {textAlign:'center', paddingTop: '1em'}
    if(gridColor == '#000000' || gridColor == '#4a4a4a') style.color = '#ddd'
    const dividerStyle = {backgroundColor: gridColor, color: style.color, margin:0, padding: '1em 0', borderTopColor: style.color}

    const [links, setLinks] = useState()
    useEffect(()=>{
        if(!currentTag) return;

        api(`/api/tags/${currentTag.tag.id}`).then(res => {
            setLinks(res.data.links)
        })

    }, [currentTag?.id])

    return (
        <div style={{backgroundColor: gridColor}}>
            {currentTag.poster_image && (
                <img src={currentTag.poster_image.cover_url} style={{objectFit:'cover', width:'100%', height:300}} alt={currentTag.poster_image.alt_text || currentTag.poster_image.description}/>
            )}
            <div style={style}>

                <h1 style={{color: style.color}}>
                    {currentTag.thumb_url && (
                        <div><Avatar src={currentTag.thumb_url} size={100}/></div>
                    )}

                    {currentTag.name}
                </h1>

                {currentTag.description && (
                    <div style={{whiteSpace: 'pre-wrap', marginBottom:'1em'}}>{(new Parser).parse(currentTag.description)}</div>
                )}

                {!!links?.length && (
                    <>
                        <Divider style={dividerStyle}><LinkOutlined/> Links</Divider>
                        <div style={{marginLeft:'auto', marginRight:'auto'}}>
                            <Links centered what={{links}} type={'AssetGroup'} readOnly/>
                        </div>
                    </>
                )}

                <Divider style={dividerStyle}><TagOutlined/> {t('related-tags','Related Tags')}</Divider>
                <div style={{padding:'1em',whiteSpace: 'nowrap', overflow:'auto'}}>
                    <TagCloud />
                </div>
            </div>

            <Divider style={{...dividerStyle, padding:'1em'}}>{t('assets','Assets')}</Divider>
        </div>
    )
}