import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import WorkflowsTable from "./WorkflowsTable";
import WorkflowForm from "./WorkflowForm";
import RightsTable from "./RightsTable";
import RightsForm from "./RightsForm";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import WebhookForm from "./WebhookForm";
import WebhooksTable from "./WebhooksTable";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('integrations','Integrations'));

    const [drawerVisible, setDrawerVisible] = useState();

    const onClick = ()=> {
        setDrawerVisible(true)
        window.location.hash = 'new'
    }

    const onClose = ()=> {
        history.replaceState(null, null, ' ');
        setDrawerVisible(false)
    }

    const [newWebhook, setNewWebhook] = useState();

    const onSave = (workflow)=> {
        history.replaceState(null, null, ' ');
        onClose()
        setNewWebhook(workflow);
    }

    if(window.location.hash == '#new' && !drawerVisible) {
        setDrawerVisible(true);
    }

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('webhooks');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('webhooks');

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search','Search...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'Webhook'}>
                    <Button type='primary' onClick={onClick}>
                        <PlusOutlined/>
                        {t('button-add-new','Add New')}
                    </Button>

                    <WebhookForm
                        onClose={onClose}
                        onSave={onSave}
                        visible={drawerVisible}
                    />
                </Can>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}

            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-integrations'}/>
                </TabPane>
                <TabPane tab={t("webhooks","Webhooks")} key="webhooks">
                    <WebhooksTable newWebhook={newWebhook} q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
            </Tabs>
        </>
    );
}
