import React, {useContext, useState} from "react";

import { message, Button, Row, Col, Card } from "antd"
import setTitle from "../helpers/setTitle";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";
import useOrgSlug from "../helpers/useOrgSlug";
import OrgBackground from "../session/OrgBackground";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input, SubmitButton} from "formik-antd";
import {LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom-v5-compat";
import * as PropTypes from "prop-types";
import OrgLogo from "../widgets/OrgLogo";
import {isMobile} from "device-detect";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";


export default () => {
    const org = useOrgSlug()
    setTitle('Request Access');
    const navigate = useNavigate();

    const [showAutoApproveNote, setShowAutoApproveNote] = useState()

    if(!org) return <></>

    if(!org.enable_membership_requests) {
        navigate('/')
    }

    const onSave = data => {
        if(data.auto_approved) {
            setShowAutoApproveNote(true)
        } else {
            navigate('login')
        }
    }

    return (
        <div style={{paddingTop:'2em'}}>
            <OrgBackground/>
            <Card title={<><UserAddOutlined/> {org.title} Access Request</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                <OrgLogo org={org}/>

                {showAutoApproveNote ? (
                    <>
                        Thanks for requesting access. We have sent an email to the address you provided. Click the link in that email to finish the request process.
                    </>
                ) : (
                    <MembershipRequestForm onSave={onSave} />
                )}
            </Card>
        </div>
    );
}

const MembershipRequestForm = ({onSave, shareLink})=> {
    const {t} = useTranslation();
    const org = useOrgSlug()

    const currentUser = useCurrentUser();

    return (
        <Formik
            initialValues={{email: currentUser?.guest_email, first_name: currentUser?.first_name, last_name: currentUser?.last_name}}
            onSubmit={(values, actions) => {
                values.share_link_code = shareLink?.code

                api({
                    method:'post',
                    url: `/api/organizations/${org.id}/request_access`,
                    data: { membership_request: values }
                }).then((res)=>{
                    actions.setSubmitting(false)
                    if(res.data.errors) {
                        message.warning(`Error: ${formatError(res.data.errors)}`)
                        return
                    }
                    actions.resetForm()
                    message.success(`Access Request to ${org.title} sent!`);
                    onSave && onSave(res.data)
                }).catch(err => {
                    console.warn(err)
                    message.warning(`Error: ${formatError(err.response.data?.errors)}`)
                    actions.setSubmitting(false)
                })
            }}
        >
            {({isSubmitting}) => (
                <Form id={'request-access-form'}>
                    <FormItem name="email" required showValidateSuccess>
                        <Input
                            autoFocus required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            type='email'
                            autoComplete='email' name="email" placeholder={t('email', 'Email')} />
                    </FormItem>

                    <FormItem name="first_name" required showValidateSuccess>
                        <Input
                            required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            name="first_name" placeholder={t('First-name','First Name')} />
                    </FormItem>

                    <FormItem name="last_name" required showValidateSuccess>
                        <Input
                            required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            name="last_name" placeholder={t('last-name', 'Last Name')} />
                    </FormItem>

                    <FormItem name='note'>
                        <Input.TextArea name='note' autoComplete='off' placeholder={t('add-a-note','Add a Note (optional)')}/>
                    </FormItem>

                    <FormItem name="submit" style={{marginBottom:0}}>
                        {isSubmitting ? (
                            <Button block type="primary" icon={<PoweroffOutlined />} loading>{t('submitting','Submitting')}...</Button>
                        ) : (
                            <SubmitButton block>{t('send-request','Send Request')}</SubmitButton>
                        )}
                    </FormItem>
                </Form>
            )}
        </Formik>
   )
}

export {MembershipRequestForm}