import React, {useContext, useEffect, useRef, useState} from "react";

import _ from 'lodash';

import {Alert, Card, Button, message, Upload, Divider, Select, notification} from 'antd';

import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {Formik} from "formik";
import * as Yup from "yup";
import {Form, FormItem, Input, SubmitButton} from "formik-antd";
import {
    PoweroffOutlined,
    LoadingOutlined,
    PlusOutlined,
    LockOutlined,
    ApiOutlined,
    MailOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import HelpPopover from "../HelpPopover";
import {SessionContext} from "../../contexts/SessionContext";
import {isMobile} from "device-detect";
import setTitle from "../helpers/setTitle";
import useCurrentUser from "../helpers/useCurrentUser";
import {OrgLink} from "../helpers/OrgNavLink";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {LanguageSelect} from "../AppHeader";
import VerticalSpace from "../helpers/VerticalSpace";


export default () => {
    const {t} = useTranslation();
    setTitle(t('account','Account'))

    const {state, dispatch} = useContext(AppContext);
    const {currentUser} = state;

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const lastEmailCheck = useRef();

    const [notice, setNotice] = useState()

    return (
        <main role={'main'} tabIndex={0}>
            <Card title={<><UserOutlined/> {t('your-mediagraph-account','Your Mediagraph Account')}</>} style={{width: isMobile() ? '100%' : 450, margin:'1em auto', backgroundColor:'white'}}>
                <UnconfirmedAccount/>

                <Formik
                    initialValues={_.pick(currentUser,['email', 'default_username'])}
                    onSubmit={(values, actions) => {
                        api({
                            method:'patch',
                            url: `/api/update_with_password`,
                            data: { user: values }
                        }).then((res)=>{
                            dispatch({
                                type:'user_updated',
                                user: res.data,
                            });

                            if(values.email != currentUser.email) setNotice(true)

                            actions.resetForm()
                            actions.setSubmitting(false)

                            message.success(t('message-your-settings-updated','Your settings have been updated!'))

                        }).catch((error)=>{
                            console.error(error.response.data)
                            message.error(`Error: ${JSON.stringify(error.response.data)}`)
                            actions.setSubmitting(false)
                        })
                    }}
                    validationSchema={
                        Yup.object({
                            email: Yup.string()
                                .email().required('Required')
                                .test('checkUniqueEmail',t('email-already-exists','Email already exists'), function(value){
                                    if(value == lastEmailCheck.current) return true;
                                    return new Promise((resolve, reject) => {
                                        api.post('/api/check_email', {email: value}).then((res)=>{
                                            if(res.data.ok) lastEmailCheck.current = value;
                                            resolve(res.data.ok)
                                        }).catch(() => resolve(false));
                                    })
                                }),
                            current_password: Yup.string().required(t('required','Required')),
                            default_username: Yup.string().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9_-]+$/, t('username-validation-message','Default Username must be text and numbers only (no special characters or spaces), e.g. "JohnnyQPublic"')).required(t('required','Required')),
                        })
                    }
                >
                    {({isSubmitting}) => (
                        <Form layout='vertical' autoComplete="off">
                            <FormItem label={t('email','Email')} name="email" required showValidateSuccess>
                                <Input required name="email" type='email' placeholder={t('placeholder-your-email',"Your email")} />
                            </FormItem>

                            <FormItem
                                label={<>{t('default-username','Default Username')} <HelpPopover code='default-username' /></>}
                                name="default_username"
                                showValidateSuccess
                                extra={<></>}
                            >
                                <Input
                                    id={'default-username-input'}
                                    autoComplete="tel"
                                    required
                                    prefix={<UserOutlined style={{opacity:0.5}} />}
                                    name="default_username" placeholder={t('placeholder-default-username',"Default Username (text and numbers only)")} />
                            </FormItem>

                            <FormItem name="current_password" required showValidateSuccess label={t('current-password','Current Password')}>
                                <Input.Password
                                    prefix={<LockOutlined style={{opacity:0.5}}/>}
                                    name="current_password"
                                    placeholder={t('placeholder-current-password',"Current Password")}
                                />
                            </FormItem>

                            <FormItem name="password" showValidateSuccess label={t('new-password','New Password')}>
                                <Input.Password
                                    prefix={<LockOutlined style={{opacity:0.5}}/>}
                                    name="password"
                                    placeholder={t('placeholder-password-with-blank',"Password (Leave blank to not change)")}
                                />
                            </FormItem>

                            <FormItem name="password_confirmation" showValidateSuccess label={t('confirm-new-password','Confirm New Password')}>
                                <Input.Password
                                    prefix={<LockOutlined style={{opacity:0.5}}/>}
                                    name="password_confirmation"
                                    placeholder={t('placeholder-password-confirmation',"Password Confirmation")}
                                />
                            </FormItem>

                            <FormItem name="submit" style={{marginBottom:0}}>
                                {isSubmitting ? (
                                    <Button block type="primary" icon={<PoweroffOutlined />} loading>{t('saving','Saving...')}</Button>
                                ) : (
                                    <SubmitButton block>{t('button-save','Save')}</SubmitButton>
                                )}
                            </FormItem>
                        </Form>
                    )}
                </Formik>

                {notice && (
                    <Alert style={{marginTop:'1em'}} type={'info'} showIcon message={t('alert-check-email-for-confirmation-link','Please check your email for a link to confirm your new email address.')}/>
                )}

                <VerticalSpace>
                    <TimezonePicker/>

                    <LanguageSelect selectTag/>
                </VerticalSpace>

                {currentOrg && (
                    <>
                        <Divider style={{margin:'2em 0'}}/>
                        <p>
                            <OrgLink to={'/settings'}>
                                <EditOutlined/> {t('change-your-profile-name-username-avatar','Change your Profile name / username / avatar.')}
                            </OrgLink>
                        </p>
                    </>
                )}

                <Divider style={{margin:'2em 0'}}/>

                <p>
                    <Link to={'/account/security'}>
                        <LockOutlined/> {t('securtiy-settings-2fa','Security Settings (2FA)')}
                    </Link>
                </p>

                <Divider style={{margin:'2em 0'}}>{t('api-settings','API Settings')}</Divider>

                <p>
                    <Link to={'/account/personal-access-tokens'}>
                        <ApiOutlined/> {t('manage-personal-access-tokens-and-oauth','Manage Personal Access Tokens and OAuth Authorizations')}
                    </Link>
                </p>
            </Card>
        </main>
    );
}

const UnconfirmedAccount = ()=>{
    const {t} = useTranslation();
    const currentUser = useCurrentUser()

    const [resending, setResending] = useState()
    const resendConfirmation = ()=>{
        setResending(true)
        api.post('/api/resend_confirm_instructions', {email: currentUser.email}).then(res => {
            setResending(false)
            message.success(t('message-confirmation-instructions-sent','Confirmation instructions sent.'))
        })
    }

    if(!currentUser || currentUser.confirmed_at) return <></>

    return (
        <Alert
            type={'error'} showIcon
            message={t('account-not-active','Account Not Active')}
            description={t('alert-you-must-confirm-email','You must confirm your email address.')}
            action={
                <>
                    <Button onClick={resendConfirmation} loading={resending} icon={<MailOutlined/>}>
                        {t('button-resend','Resend')}
                    </Button>
                </>
            }
            style={{marginBottom:'2em'}}
        />
    )
}

export {UnconfirmedAccount}

const TimezonePicker = ()=>{
    const {t} = useTranslation();
    const currentUser = useCurrentUser()
    const {dispatch} = useContext(AppContext)

    const [updating, setUpdating] = useState()

    const update = timezone => {
        setUpdating(true)
        api.put(`/api/sign_up`, {user: {timezone}}).then(res => {
            setUpdating(false)
            dispatch({type:'user_updated', user: res.data})
            message.success(t('message-timezone-updated','Timezone Updated.'))
        })
    }

    return (
        <>
            <Divider/>
            <strong>{t('timezone','Timezone')}:</strong>
            <br/>
            <TimezoneSelect onChange={update} disabled={updating} defaultValue={currentUser?.timezone}/>
        </>
    )
}

const TimezoneSelect = ({SelectComponent=Select, onChange, defaultValue, disabled, name}) => {
    const {t} = useTranslation();
    const guess = moment.tz.guess()

    return (
        <SelectComponent
            name={name}
            onChange={onChange}
            disabled={disabled}
            style={{width:'100%'}}
            placeholder={t('placeholder-select-timezeon','Select your timezone...')}
            filterOption={(input, option) =>
                option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children?.toLowerCase()?.localeCompare(optionB.children?.toLowerCase())
            }
            showSearch
            defaultValue={defaultValue}
        >
            <SelectComponent.OptGroup label={t('suggested','Suggested')}>
                <SelectComponent.Option value={guess}>{guess}</SelectComponent.Option>
            </SelectComponent.OptGroup>
            <SelectComponent.OptGroup label={t('all','All')}>
                {moment.tz.names().map(name => (
                    <SelectComponent.Option value={name} key={name}>{name}</SelectComponent.Option>
                ))}
            </SelectComponent.OptGroup>
        </SelectComponent>
    )
}

export {TimezoneSelect}