import React, {useContext, useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import * as Yup from "yup";

import {Badge, Collapse, Divider, Drawer, message, Modal, Space, Tag, Tooltip, Upload} from 'antd';
const { Panel } = Collapse;

import HelpPopover from "../HelpPopover";

import {
    SaveOutlined,
    FolderFilled,
    ArrowDownOutlined,
    DeploymentUnitOutlined,
    PlusOutlined,
    ClockCircleOutlined,
    FileProtectOutlined,
    TagOutlined,
    UserOutlined,
    UploadOutlined, FileOutlined, LinkOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import AssetGroupChooser from "./AssetGroupChooser";
import FloatLabel from "../helpers/FloatLabel";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import UserSelect from "../widgets/UserSelect";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import {BlockDownload, BlockView, RightsIcon} from "../helpers/icons";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import {SessionContext} from "../../contexts/SessionContext";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {FileIcon} from "react-file-icon";
import AssetLink from "../widgets/AssetLink";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";
import {getLocale} from "../widgets/DateRangePicker";

export default ({id, onSave, visible, onClose}) => {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);

    const navigate = useNavigate();

    if(!id) {
        const { id } = useParams();
    }

    const [rightsPackage, setRightsPackage] = useState({name:''});
    const apiPath = '/api/rights_packages';

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`${apiPath}/${path}`).then((res) => {
            setRightsPackage(res.data);
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    const onCloseDirty = useOnCloseDirty(onClose)

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    // Avoid null validation error:
    if(rightsPackage && !rightsPackage.name) rightsPackage.name = ''
    if(rightsPackage && !rightsPackage.rights_class) rightsPackage.rights_class = ''

    const getPath = useOrgPath()

    return (
        (<Formik
            initialValues={rightsPackage}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                api({
                    method: id ? 'put' : 'post',
                    url: id ? `${apiPath}/${id}` : apiPath,
                    data: {rights_package: values}
                }).then((res)=>{
                    console.log('id',id);
                    if(!id) navigate(getPath(`/manage/rights`))
                    actions.setSubmitting(false)
                    message.success(`${values.name} has been ${id ? 'updated' : 'created'}!`)

                    setRightsPackage(res.data);

                    actions.resetForm({});

                    onSave && onSave(res.data);

                }).catch((error)=>{
                    if(error) {
                        console.error(error.response?.data)
                        message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                        actions.setSubmitting(false)
                    }
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(true),
                    rights_class: Yup.string().required(t('required','Required')),
                })
            }
        >
            {({setFieldValue, values, submitForm, dirty}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = ''; // agreements

                const locale = getLocale();

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <RightsIcon/> {id ? t('editing','Editing') : t('add-new','Add New')} {t('rights-package','Rights Package')}

                                    <HelpPopover code='rights-package-form-header'/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <SaveOutlined/>
                                            {id ? 'Update' : 'Save'} {t('rights-package','Rights Package')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical'>
                                <FormItem name='enabled'>
                                    <Switch name='enabled' checkedChildren={t('enabled','Enabled')} unCheckedChildren={t('disabled','Disabled')}/>
                                </FormItem>

                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-rights-package-name','e.g. Owned By Org')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('administrative-description','Administrative Description')} name={'description'} value={values.description || ''} description={t('placeholder-rights-package-description', 'Describe the purpose of the Rights Package. Optional.')}>
                                        <Input.TextArea rows={2} name='description'/>
                                    </FloatLabel>
                                </FormItem>

                                {/*<Divider/>*/}

                                {/*<FormItem label={<>Usage <HelpPopover code={'rights-form-reusable'}/></>} name='reusable' style={{marginTop:'.5em'}}>*/}
                                {/*    <Radio.Group*/}
                                {/*        className={'vertical-checkbox-group'}*/}
                                {/*        name={`reusable`}*/}
                                {/*    >*/}
                                {/*        <Radio value={false}>One-time use</Radio>*/}
                                {/*        <Radio value={true}>Re-use</Radio>*/}
                                {/*    </Radio.Group>*/}
                                {/*</FormItem>*/}

                                <FormItem label={<>{t('filterable-rights-status','Filterable Rights Status')} <HelpPopover code={'rights-form-status'}/></>} name='rights_class' style={{marginTop:'.5em'}}>
                                    <Select name={'rights_class'} required placeholder={'Select One...'}>
                                        {rightsPackage?.class_options && Object.keys(rightsPackage.class_options).map((key, i) => {
                                            const opt = rightsPackage.class_options[key];
                                            return (
                                                <Select.Option value={key} key={key}>
                                                    {t(opt.name,opt.name)} | <small>{t(opt.description,opt.description)}</small>
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </FormItem>

                                <FormItem label={<>{t('restricted-access','Restricted Access')} <HelpPopover code={'rights-package-block-flag'}/></>} name='block_level' style={{marginTop:'.5em'}}>
                                    <Select name={'block_level'}>
                                        <Select.Option value={0}>{t('none','None')}</Select.Option>
                                        <Select.Option value={1}><BlockDownload/> {t('block-download','Block Download')}</Select.Option>
                                        <Select.Option value={2}><BlockView/> {t('block-view-and-download','Block View and Download')}</Select.Option>
                                    </Select>
                                </FormItem>

                                <FormItem name='default' label={<>{t('default','Default')} <HelpPopover code={'rights-form-default'}/></>}>
                                    <Switch name='default' checkedChildren={t('yes','Yes')} unCheckedChildren={t('no','No')}/>
                                </FormItem>

                                <Collapse defaultActiveKey={defaultActiveKey}>
                                    <Collapse.Panel key='personalization' header={<><UserOutlined/> {t('personalization','Personalization')} <EnabledDisabled enabled={values.personalize}/></>}>
                                        <FormItem name='personal'>
                                            <Switch name='personalize' checkedChildren={t('personalize','Personalize')} unCheckedChildren={t('personalize','Personalize')}/>
                                            <HelpPopover code={'rights-form-personalize'}/>
                                            {values.personalize && (
                                                <>
                                                    <br/>
                                                    <br/>
                                                    <FloatLabel label={t('name-of-second-party','Name of Second Party')} name={'other_party_name'} value={values.other_party_name} description={t('placeholder-rights-package-other-party-name','e.g. Joe Contractor')}>
                                                        <Input size={'large'} name='other_party_name' autoComplete='off'/>
                                                    </FloatLabel>
                                                </>
                                            )}
                                        </FormItem>
                                    </Collapse.Panel>

                                    <Collapse.Panel key='expiration' header={<><ClockCircleOutlined/> {t('expiration','Expiration')} <EnabledDisabled enabled={values.expires}/></>}>
                                        <FormItem name='expiration' style={{marginTop:'.5em'}}>
                                            <FormItem name='expires'>
                                                <Switch checkedChildren={t('expires','Expires')} unCheckedChildren={t('no-expiration','No Expiration')} name={'expires'}/>
                                            </FormItem>

                                            <FormItem name='expires_at' label={t('expiration-date','Expiration Date')}>
                                                <DatePicker name={'expires_at'} showTime locale={locale}/>
                                            </FormItem>

                                            <FormItem name='expiration_behaviour' label={t('upon-expiration','Upon Expiration')}>
                                                <Checkbox name={'watermark'}>{t('watermark','Watermark')}</Checkbox>
                                                <br/>
                                                <Checkbox name={'prevent_download'}>{t('prevent-download','Prevent Download')}</Checkbox>
                                            </FormItem>

                                        </FormItem>
                                    </Collapse.Panel>

                                    <Collapse.Panel key={'agreements'} header={<><FileProtectOutlined/> {t('agreements','Agreements')}</>}>
                                        <FormItem label={<>{t('upload-agreement','Upload Agreement')} <HelpPopover code={'rights-form-permission-agreement'}/></>} name='summary' extra={t('rights-package-agreements-note','This is what the person uploading will see and agree to.')}>
                                            <Input.TextArea rows={2} name='summary' placeholder={t('placeholder-rights-package-upload-agreement','Enter Upload Agreement here...')}/>
                                        </FormItem>

                                        <FormItem label={<>{t('external-contract-identifier','External Contract Identifier')} <HelpPopover code={'rights-form-external-contract'}/></>} name='external'>
                                            <FloatLabel label={t('contract-id','Contract ID')} name={'external_contract_id'} value={values.external_contract_id} style={{marginTop:'1em'}}>
                                                <Input size={'large'} name='external_contract_id' autoComplete='off'/>
                                            </FloatLabel>

                                            <FloatLabel label={t('contract-location','Contract Location')} name={'external_contract_location'} value={values.external_contract_location} style={{marginTop:'1em'}}>
                                                <Input size={'large'} name='external_contract_location' autoComplete='off'/>
                                            </FloatLabel>
                                        </FormItem>

                                        <FormItem label={<>{t('license-attachments','License Attachments')} <HelpPopover code={'rights-form-license-attachments'}/></>} name='attachments' style={{marginTop:'.5em'}}>
                                            <Radio.Group
                                                rootClassName={'vertical-checkbox-group'}
                                                name={`long_form_type`}
                                            >
                                                <Radio value={'none'}>None</Radio>
                                                {/*<Radio value={'text'}>Paste in long-form license text</Radio>*/}
                                                {/*<Radio value={'allow_contract_upload'}>Allow Member to upload a contract</Radio>*/}
                                                <Radio value={'attachment'}>{t('attach-our-contract','Attach our contract')}</Radio>
                                            </Radio.Group>

                                            {values.long_form_type == 'attachment' && (
                                                <div style={{margin:'1em 0'}}>
                                                    {rightsPackage?.contract_asset && (
                                                        <p>
                                                            <AssetLink asset={rightsPackage?.contract_asset}/>
                                                        </p>
                                                    )}

                                                    <FloatLabel label={t('contract-asset-guid','Contract Asset GUID')} name={'contract_asset_guid'} style={{marginTop:'1em'}}>
                                                        <Input size={'large'} name='contract_asset_guid' autoComplete='off'/>
                                                    </FloatLabel>
                                                </div>
                                            )}

                                            {/*<br/>*/}
                                            {/*<br/>*/}
                                            {/*<FloatLabel label={'Long Form License Text'} name={'long_form'} value={values.long_form} description={'Long Form License Text.'} style={{marginTop:'.5em'}}>*/}
                                            {/*    <Input.TextArea rows={2} name='long_form'/>*/}
                                            {/*</FloatLabel>*/}
                                        </FormItem>

                                        <FormItem label={<>{t('releases','Releases')} <HelpPopover code={'rights-form-license-attachments'}/></>} name='attachments' style={{marginTop:'.5em'}}>
                                            <Checkbox name={'allow_model_release_upload'}>{t('allow-member-to-upload-model-property release','Allow Member to upload a model and/or property release')}</Checkbox>
                                        </FormItem>
                                    </Collapse.Panel>

                                    <Collapse.Panel key={'iptc'} header={<><TagOutlined/> IPTC <EnabledDisabled enabled={values.replace_meta_field}/></>}>
                                        <FormItem name='meta' style={{marginTop:'.5em'}}>
                                            <Checkbox name={'replace_meta_field'}>{t('replace-text-in-iptc-rights-usage-field','Replace text in IPTC Rights Usage field')}</Checkbox>

                                            <br/>
                                            <br/>
                                            <FloatLabel label={t('usage-field-text','Usage Field Text')} name={'meta_field_text'} value={values.meta_field_text} description={t('placeholder-rights-package-text-to-write-to-iptc','Text to write to IPTC.')} style={{marginTop:'.5em'}}>
                                                <Input.TextArea rows={2} name='meta_field_text'/>
                                            </FloatLabel>

                                            <br/>
                                            <Checkbox name={'replace_empty_meta_field'}>{t('only-replace-if-empty','Only replace if empty')}</Checkbox>
                                        </FormItem>
                                    </Collapse.Panel>
                                </Collapse>

                                {/*For Enter btn submit:*/}
                                <SubmitButton style={{display:'none'}}/>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

