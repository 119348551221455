import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput, Tooltip} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {RefreshIcon} from "../helpers/icons";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('taxonomy','Taxonomy'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const [newTag, setNewTag] = useState()
    const onSave = tag =>{
       setNewTag(tag)
    }

    const [refresh, setRefresh] = useState(0)

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-tags','Search Tags...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'Tag'}>
                    <NewTagButton onSave={onSave}/>
                </Can>
                <Tooltip title={t('tooltip.reload-tags','Reload Tags')}>
                    <Button icon={<RefreshIcon/>} type='text' onClick={()=> setRefresh(refresh + 1)}/>
                </Tooltip>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}

            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-taxonomy'}/>
                </TabPane>
                <TabPane tab={t("browse-tags","Browse Tags")} key="browse">
                    <TaxonomyTable q={searchValue} onLoaded={onLoaded} newTag={newTag} refresh={refresh}/>
                </TabPane>
            </Tabs>
        </>
    );
}

const NewTagButton = ({onSave}) => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState()
    const onClick = ()=> {
        setModalVisible(true)
        setTimeout(()=> autoFocusInput.current?.focus(), 100)
    }

    const autoFocusInput = useRef(null);

    return (<>
        <Button type='primary' onClick={onClick}>
            <PlusOutlined/>
            {t('button-add-new-tag','Add New Tag')}
        </Button>
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                const data = {tag: values}

                api.post(`/api/tags/`, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    setModalVisible(false);
                    message.success(t('message-tag-created','Tag created.'))

                    onSave && onSave(res.data)
                }).catch((err)=>{
                    message.error(JSON.stringify(err.response.data))
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit}) => {

                // This is needed since an Enter key press will submit the outer form also
                const onKeyDownCapture = (e) => {
                    if(e.keyCode == 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        submitForm();
                    }
                }

                return (
                    (<Modal
                        title={t('new-tag','New Tag')}
                        open={modalVisible}
                        destroyOnClose
                        onCancel={() => setModalVisible(false)}
                        centered
                        confirmLoading
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm}>
                                    <SaveOutlined/>
                                    {t('button-create','Create')}
                                </Button>
                                <Button onClick={() => setModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                            </Space>
                        }
                    >
                        <form onSubmit={handleSubmit} onKeyDownCapture={onKeyDownCapture}>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name','Name')} name={'name'} value={values?.name} description={t('placeholder-tag-name','e.g. Marketing')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>
                            <FormItem name='description' style={{marginTop:'.5em'}}>
                                <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('optional','Optional.')}>
                                    <Input.TextArea rows={2} name='description'/>
                                </FloatLabel>
                            </FormItem>
                        </form>
                    </Modal>)
                );
            }}
        </Formik>
    </>);
}