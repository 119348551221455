import React from "react";
import {Button} from "antd";
import useCurrentOrg from "../helpers/useCurrentOrg";

export default props => {
    const currentOrg = useCurrentOrg()

    const color = currentOrg?.header_highlight_color || Colors.blue;

    const style = {
        borderColor: color,
        backgroundColor: color,
        color: currentOrg?.header_text_color || 'fff'
    }

    return (
        <Button {...props} style={{...style, ...(props.style || {})}}/>
    )
}