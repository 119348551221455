import React, {useContext, useEffect, useRef, useState} from "react";

import {Alert, Card, Button, message, Upload, Divider, Table, Space, Popconfirm, Modal, Typography} from 'antd';

import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {Formik} from "formik";
import {Form, FormItem, Input, SubmitButton} from "formik-antd";
import {
    PoweroffOutlined,
    LoadingOutlined,
    PlusOutlined,
    LockOutlined,
    ApiOutlined,
    DeleteOutlined, SaveOutlined
} from "@ant-design/icons";
import {isMobile} from "device-detect";
import useCurrentUser from "../helpers/useCurrentUser";
import TimeAgo from "../helpers/TimeAgo";
import FloatLabel from "../helpers/FloatLabel";
import setTitle from "../helpers/setTitle";
import HelpPopover from "../HelpPopover";


export default () => {
    setTitle('Personal Access Tokens')

    const currentUser = useCurrentUser()

    const [loadingPats, setLoadingPats] = useState()
    const [pats, setPats] = useState()

    const load = ()=>{
        setLoadingPats(true)
        api('/api/personal_access_tokens').then(res => {
            setLoadingPats(false)
            setPats(res.data)
        })

        setLoadingAccessGrants(true)
        api('/api/access_grants').then(res => {
            setLoadingAccessGrants(false)
            setAccessGrants(res.data)
        })
    }
    useEffect(()=>{
        load()
    }, [currentUser?.id])

    const columns = [
        {
            title: 'Created',
            dataIndex: 'created_at',
            width: 150,
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: 'Note',
            dataIndex: 'note'
        },
        {
            title: 'Organization',
            render: (pat) => {
                return <>{pat.organization?.title}</>
            }
        },
        {
            title: 'Storage Folder',
            render: (pat) => {
                return <>{pat.asset_group?.name}</>
            }
        },
        {
            title: 'Last Used',
            dataIndex: 'last_used_at',
            width: 150,
            render: (last_used_at) => {
                return <TimeAgo date={last_used_at}/>
            }
        },
        {
            key: 'actions',
            width: 100,
            render: (pat)=> {
                const destroy = ()=>{
                    api.delete(`/api/personal_access_tokens/${pat.id}`).then(res => {
                        message.success('Personal Access Token revoked.')
                        load()
                    })
                }

                return (
                    <Popconfirm onConfirm={destroy} title={'Revoke?'}>
                        <Button icon={<DeleteOutlined/>} danger ghost>Revoke</Button>
                    </Popconfirm>
                )
            }
        },
    ]

    const [visible, setVisible] = useState()
    const autoFocusInput = useRef(null);

    const [newPat, setNewPat] = useState()

    const [accessGrants, setAccessGrants] = useState()
    const [loadingAccessGrants, setLoadingAccessGrants] = useState(false)
    const accessGrantColumns = [
        {
            title: 'Authorized',
            dataIndex: 'created_at',
            width: 150,
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: 'Application',
            dataIndex: 'application_name'
        },
        {
            title: 'Organization',
            render: ag => {
                return <>{ag.organization?.title}</>
            }
        },
        {
            key: 'actions',
            width: 100,
            render: (ag)=> {
                const destroy = ()=>{
                    api.delete(`/api/access_grants/${ag.id}`).then(res => {
                        message.success('OAuth Authorization revoked.')
                        load()
                    })
                }

                return (
                    <Popconfirm onConfirm={destroy} title={'Revoke?'}>
                        <Button icon={<DeleteOutlined/>} danger ghost>Revoke</Button>
                    </Popconfirm>
                )
            }
        },
    ]

    return (
        (<Card
            title={
                <>
                    <ApiOutlined/> Personal Access Tokens
                    <Button icon={<PlusOutlined/>} type={'primary'} ghost onClick={()=> setVisible(true)} style={{float:'right'}}>Create New</Button>
                </>
            }
            style={{width: isMobile() ? '100%' : 800, margin:'0 auto', backgroundColor:'white'}}
        >
            {newPat && (
                <Alert
                    showIcon type={'success'}
                    message={
                        <>
                            Personal Access Token created
                        </>
                    }
                    description={
                        <>
                            <div style={{margin: '1em 0'}}>
                                <Typography.Text copyable={{tooltips: 'Copy Token', text: newPat.token}} code>{newPat.token}</Typography.Text>
                            </div>
                            Copy the token and keep it safe like your password; you will only be able to see it once. <HelpPopover code={'personal-access-token'}/>
                        </>
                    }
                    style={{marginBottom:'1em'}}
                />
            )}
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    api.post('/api/personal_access_tokens', {personal_access_token: values}).then(res => {
                        setVisible(false)
                        setNewPat(res.data)
                        load()
                        actions.resetForm()
                    })
                }}
            >
                {({values, submitForm, dirty, isSubmitting}) => (
                    <Modal
                        open={visible}
                        onCancel={()=>setVisible(false)}
                        title={'New Personal Access Token'}
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm}>
                                    <SaveOutlined/>
                                    Create
                                </Button>
                                <Button onClick={() => setVisible(false)}>Cancel</Button>
                            </Space>
                        }
                    >
                        <FormItem name='note'>
                            <FloatLabel label={'Note'} name={'note'} value={values.note} description={'Optional'}>
                                <Input size={'large'} required name='note' ref={autoFocusInput} autoFocus autoComplete='off'/>
                            </FloatLabel>
                        </FormItem>
                    </Modal>
                )}
            </Formik>
            <Table
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={pats}
                loading={loadingPats}
                pagination={false}
            />
            <Divider>Authorized OAuth Applications</Divider>
            <Table
                scroll={{x: true}}
                size={'small'}
                columns={accessGrantColumns}
                rowKey={record => record.id}
                dataSource={accessGrants}
                loading={loadingAccessGrants}
                pagination={false}
            />
            <p style={{marginTop:'1em'}}>
                API documentation here: <a href={'https://docs.mediagraph.io'} target={'_blank'}>docs.mediagraph.io</a>
            </p>
        </Card>)
    );
}
