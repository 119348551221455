import React, {useContext, useEffect, useRef, useState} from "react";
import {SessionContext} from "../../contexts/SessionContext";
import {
    AutoComplete,
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Drawer,
    List,
    Menu,
    message,
    Row,
    Space,
    Tag,
    Typography,
    Input as AntInput
} from "antd";
import {
    DeleteOutlined,
    FolderOutlined,
    PlusOutlined,
    SaveOutlined,
    SettingOutlined,
    TagOutlined
} from "@ant-design/icons";
import {Checkbox, Form, FormItem, Input, Select, SubmitButton, DatePicker, Radio} from "formik-antd";
import {FieldArray, Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import User from "../helpers/User";
import FloatLabel from "../helpers/FloatLabel";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import moment from "moment";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import HelpPopover from "../HelpPopover";

import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {RightsIcon} from "../helpers/icons";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import Descriptions from "antd/lib/descriptions";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";

export default ()=> {
    const [upload, setUpload] = useState()
    const {id} = upload || {};

    const {currentUpload} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const location = useLocation()
    const navigate = useNavigate();

    useEffect(()=>{
        const [x,id] = (location.hash?.match(/^#\/uploads\/(\d+?)\/edit$/) || [])
        if(id) {
            setUpload({id})
        }
    }, [location.hash])

    // Only load when Drawer opened:
    useEffect(() => {
        if(!upload) return;

        api.get(`/api/uploads/${upload.id}`).then((res) => {
            setUpload(res.data);
        });

    }, [upload?.id]);

    const [width, setWidth] = useState(isMobile() ? '100%' : '50%');

    const closeDrawer = ()=>{
        location.hash = null
        navigate(location)
        setUpload(null);
    }

    if(!upload) return '';

    console.log('currentUpload', currentUpload)
    return (
        (<Formik
            initialValues={upload || {}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {

                const data = {upload: values}

                api({
                    method: 'put',
                    url: `/api/uploads/${id}`,
                    data: data
                }).then((res)=>{
                    actions.setSubmitting(false)
                    message.success(`${values.name} has been updated!`)
                    setUpload(res.data);
                    console.log('currentUpload', currentUpload)

                    if(currentUpload?.id == id) assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});

                    editAssetDispatch({type:'increment'})

                    actions.resetForm({});

                }).catch((error)=>{
                    console.error(error)
                    message.error(`Error: ${JSON.stringify(error.response.data)}`)
                    actions.setSubmitting(false)
                })
            }}
        >
            {({setFieldValue, values, submitForm, dirty}) => {
                return (
                    (<Drawer
                        width={width}
                        placement={'right'}
                        open={!!upload}
                        onClose={closeDrawer}
                        title={<strong><SettingOutlined/> Manage Upload</strong>}
                        footer={
                            <div style={{textAlign: 'right',}}>
                                <Space direction={'horizontal'}>
                                    <SubmitButton type={'primary'} htmlType={'submit'} onClick={submitForm}>
                                        <SaveOutlined/>
                                        {id ? 'Update' : 'Save'}
                                    </SubmitButton>
                                </Space>
                            </div>
                        }
                    >
                        <Form onSubmit={submitForm} layout='vertical'>
                            {upload.contribution && (
                                <Card title={'Contribution Request Details'} size={'small'} style={{marginBottom:'1em'}}>
                                    <Descriptions bordered size={'small'} column={1}>
                                        <Descriptions.Item label={'Name'}>
                                            {upload.contribution.name}
                                        </Descriptions.Item>

                                        {upload.contribution.user_group && (
                                            <Descriptions.Item label={'User Group'}>
                                                {upload.contribution.user_group.name}
                                            </Descriptions.Item>
                                        )}

                                        {upload.contribution.asset_group && (
                                            <Descriptions.Item label={upload.contribution.asset_group.type}>
                                                {upload.contribution.asset_group.name}
                                            </Descriptions.Item>
                                        )}

                                        {upload.contribution.text && upload.contribution.text != '' && (
                                            <Descriptions.Item label={'Request'}>
                                                {upload.contribution.text}
                                            </Descriptions.Item>
                                        )}

                                        {!!upload.contribution.tag_names.length && (
                                            <Descriptions.Item label={'Auto Added Tags'}>
                                                {upload.contribution.tag_names.map(name => <Tag key={name}><TagOutlined/> {name}</Tag>)}
                                            </Descriptions.Item>
                                        )}

                                        {upload.tag_suggesters.map(ts => (
                                            <Descriptions.Item key={ts.id} label={'Tag Suggester'}>
                                                <h5>{ts.name}{ts.required ? ' (required)' : ''}:</h5>
                                                {ts.tag_names.map(name => <Tag key={name}><TagOutlined/> {name}</Tag>)}
                                            </Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </Card>
                            )}

                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={'Name'} name={'name'} value={values?.name}
                                            description={'e.g. Offsite Event'}>
                                    <Input size={'large'} required name='name' autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='note' style={{marginTop:'.5em'}}>
                                <FloatLabel label={'Note'} name={'note'} value={values?.note} description={'Optional.'}>
                                    <Input.TextArea rows={2} name='note'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='tag_names'>
                                <FieldArray
                                    name={'tag_names'}
                                    render={() => (
                                        <FloatLabel label={'Tags'} name={'tag_names'} value={values.tag_names} description={'e.g. Event, Product'}>
                                            <Select size={'large'} name='tag_names' mode='tags'>
                                                {values.tag_names?.map((name) => (
                                                    <Select.Option value={name} key={name}>{name}</Select.Option>
                                                ))}
                                            </Select>
                                        </FloatLabel>
                                    )}
                                />
                            </FormItem>

                            <FormItem name='default_rights_package' label={'Default Rights Package'}>
                                <Select name={'default_rights_package_id'} placeholder={'Select one...'}>
                                    {upload.contribution?.rights_packages.map(rp => (
                                        <Select.Option key={rp.id} value={rp.id}>
                                            <RightsIcon/> {rp.name}
                                            <h5>{rp.summary}</h5>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Form>
                    </Drawer>)
                );
            }}
        </Formik>)
    );
}
