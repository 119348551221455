import React, {useContext, useEffect, useState} from "react";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Typography, Skeleton, Dropdown, Menu, Select, notification
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    CloseOutlined,
    DeleteOutlined, DeploymentUnitOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, LoadingOutlined, UserSwitchOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {SessionContext} from "../../contexts/SessionContext";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import useSwitchToUser from "../helpers/useSwitchToUser";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import useCurrentOrg from "@/components/helpers/useCurrentOrg";

export default ({q, onLoaded}) => {
    const {t} = useTranslation();
    const {state} = useContext(SessionContext);

    const switchToUser = useSwitchToUser()

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/memberships'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-membership-removed','Membership Removed.'));
            handleTableChange();
        });
    }

    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()

    const StatusCell = ({aasm_state, membership})=>{
        const [status, setStatus] = useState(aasm_state)
        const [loading, setLoading] = useState()

        const onChange = (newStatus)=>{
            setLoading(true)
            api.put(`/api/memberships/${membership.id}/update_status`, {status: newStatus}).then(res => {
                message.success(t('message-member-status-changed',`Member status changed to {{status}}`, {status: newStatus}))
                setLoading(false)
                setStatus(newStatus)
            })
        }

        return (
            <Can I={'manage'} a={'Membership'} passThrough>
                {allowed => {
                    return allowed && !membership.is_owner ? (
                        <Select onChange={onChange} style={{width: 150}} value={status} loading={loading} getPopupContainer={() => document.getElementById('membership-table')}>
                            <Select.Option key={'active'} value={'active'}><CheckOutlined/> {t('active','Active').toProperCase()}</Select.Option>
                            <Select.Option key={'deactivated'} value={'deactivated'}><CloseOutlined/> {t('deactivated','Deactivated').toProperCase()}</Select.Option>
                        </Select>
                    ) : status.toProperCase()
                }}
            </Can>
        );
    }

    const RoleCell = ({rl, membership})=>{
        const [role_level, setRoleLevel] = useState(membership.role_level);

        const onChange = value => {
            setRoleLevel(value);
            api.put(`/api/memberships/${membership.id}`, {membership: {role_level: value}}).then(res => {
                message.success(t('message-membership-updated','Membership updated'))
            })
        }

        const levelLabels = {
            restricted: t('restricted','Restricted'),
            general: t('general','General'),
            global_tagger: t('global-tagger','Global Tagger'),
            global_library: t('global-library','Global Library'),
            global_content: t('global-content','Global Content'),
            admin: t('admin','Admin')
        }

        const title = membership.is_owner ? t('owner','Owner') : t(membership.role_level_name,membership.role_level_name)

        const levels = ['restricted', 'general', 'global_tagger', 'global_library', 'global_content', 'admin']
        const i = levels.indexOf(state.currentOrg?.role_level) || 0;

        if (membership.user?.id === currentUser?.id) return <>{title}</>
        return (
            <Can I={'manage'} a={'Membership'} passThrough>
                {allowed => {
                    return state.currentOrg?.is_owner || (allowed && !membership.is_owner && levels.indexOf(membership.role_level) < i) ? (
                        <Select onChange={onChange} style={{width:150}} value={role_level} getPopupContainer={() => document.getElementById('membership-table')}>
                            {levels.slice(0, i + 1).map(level =>
                                <Select.Option key={level} value={level}>{levelLabels[level]}</Select.Option>
                            )}
                        </Select>
                    ) : title
                }}
            </Can>
        )
    }

    const columns = [
        {
            title: t('avatar','Avatar'),
            render: membership => (
                <Popover content={<img src={membership.user.avatar_url} style={{maxWidth:300}}/>} trigger={membership.user.avatar_url ? 'hover' : 'none' }>
                    <Avatar src={membership.user.avatar_url}>{membership.initials}</Avatar>
                </Popover>
            ),
        },
        {
            title: t('first-name','First Name'),
            render: (_, membership) => membership.user.first_name,
            sorter: true,
            dataIndex: 'users.first_name',
        },
        {
            title: t('last-name','Last Name'),
            render: (_, membership) => membership.user.last_name,
            sorter: true,
            dataIndex: 'users.last_name',
        },
        {
            title: t('email','Email'),
            render: (_,membership) => <Typography.Text copyable>{membership.user.email}</Typography.Text>,
            sorter: true,
            dataIndex: 'users.email',
        },
        {
            title: t('title','Title'),
            dataIndex: 'title',
            sorter: true
        },
        {
            title: t('role','Role'),
            sorter: true,
            filterMultiple: true,
            dataIndex: 'role_level',
            filters: [
                {text: t('restricted','Restricted'), value: 'restricted'},
                {text: t('general','General'), value: 'general'},
                {text: t('global-tagger','Global Tagger'), value: 'global_tagger'},
                {text: t('global-library','Global Library'), value: 'global_library'},
                {text: t('global-content','Global Content'), value: 'global_content'},
                {text: t('admin','Admin'), value: 'admin'},
            ],
            render: (rl, membership)=> <RoleCell rl={rl} membership={membership}/>
        },
        {
            title: t('tester','Tester'),
            render: tester => tester ? <CheckOutlined/> : '',
            dataIndex: 'tester',
            filterMultiple: false,
            filters: [
                {text: t('tester','Tester'), value: 'true'},
                {text: t('normal','Normal'), value: 'false'},
            ],
            defaultFilteredValue: 'false'
        },
        {
            title: t('joined','Joined'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => <TimeAgo date={created_at}/>
        },
        {
            title: t('last-login','Last Login'),
            dataIndex: 'last_login_at',
            sorter: true,
            render: (last_login_at) => last_login_at && <TimeAgo date={last_login_at}/>
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            filters: [
                {text: t('active','Active').toProperCase(), value: 'active'},
                {text: t('deactivated','Deactivated').toProperCase(), value: 'deactivated'},
            ],
            defaultFilteredValue: 'active',
            render: (aasm_state, membership)=> <StatusCell aasm_state={aasm_state} membership={membership}/>
        },
        {
            title: t('user-groups','User Groups'),
            render: (membership) => (
                <Popover title={t('user-groups','User Groups')} content={
                    membership.user_groups.map(group => (<div key={group.id}>{group.name} - {t(group.role_level_name, group.role_level_name)}</div>))
                }><Tag>{membership.user_groups_count}</Tag></Popover>
            )
        },
        {
            title: t('2fa','2FA'),
            filterMultiple: false,
            dataIndex: 'otp_required_for_login',
            filters: [
                {text: t('enabled','Enabled'), value: 'true'},
                {text: t('disabled','Disabled'), value: 'false'},
            ],
            render: enabled => (
                <>{enabled && <CheckOutlined/>}</>
            )
        },
        {
            title: t('actions','Actions'),
            key: 'action',
            render: (membership) => (
                <>
                    {membership.switchable &&
                        <Button icon={<UserSwitchOutlined/>} onClick={()=> switchToUser(membership.user)} id={`switch-to-${membership.user.id}`}>{t('button-switch-to','Switch To')}</Button>
                    }
                </>
            )
        }
    ];

    const defaultFilters = {tester: 'false', aasm_state:'active'}
    const [settings, setSettings] = useState({filters: defaultFilters})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {

        for(let f in defaultFilters) {
            if(!filters[f]) filters[f] = defaultFilters[f]
        }

        setSettings({pagination, filters, sorter, q})
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(() => {
        if(!currentUser || !currentOrg) return;

        handleTableChange({}, settings.filters);
    }, [q, currentUser?.id, currentOrg?.id]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    if(!currentUser || !currentOrg) return <LoadingOutlined/>

    return (
        <>
            <Table
                getPopupContainer={() => document.querySelector?.('#root div.ant-table-container > div.ant-table-header') || document.body}
                id={'membership-table'}
                scroll={{x: true}}
                bordered
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, showSizeChanger: true}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton settings={settings} apiPath={apiPath}/>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Member{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}