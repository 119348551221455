import {Button, List, message, Popconfirm, Popover, Space, Tooltip} from "antd";
import User from "../helpers/User";
import TimeAgo from "../helpers/TimeAgo";
import React, {useContext, useState} from "react";

import {
    ArrowRightOutlined, CheckOutlined, CloseOutlined,
    FolderFilled,CheckCircleTwoTone,CloseCircleTwoTone, HeartTwoTone,
    UndoOutlined, HeartOutlined
} from "@ant-design/icons";
import {SessionContext} from "../../contexts/SessionContext";
import {WorkflowIcon} from "../helpers/icons";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useTranslation} from "react-i18next";


const useApprovalState = (step, asset) => {
    const approval = _.find(step?.approvals, {asset_id: asset?.id});
    const approval_state = approval?.aasm_state || 'pending';

    return {approval, approval_state}
}

export {useApprovalState}

export default ({assetGroup, asset, onUpdate, step, buttonsOnly=false, iconOnly=false, largeIcon=false})=>{
    const {t} = useTranslation();
    if(!assetGroup || !assetGroup.workflow_steps?.length) return <></>;

    const {currentWorkflowStep} = useAssetGroupState();

    const isAccessRequest = assetGroup.access_request_id;

    let steps;
    if(asset && step) {
        steps = [step]
    } else if(step && !isAccessRequest) {
        // get group and step from Asset data since it includes the Approvals
        const groupsField = {
            Collection: 'collections',
            Lightbox: 'lightboxes'
        }[assetGroup.type]

        const group = groupsField ? _.find(asset[groupsField], {asset_group_id: assetGroup.asset_group_id}) : asset.storage_folder;

        steps = group ? [_.find(group.workflow_steps, {id: step.id})] : []
    } else if(isAccessRequest) {
        steps = [currentWorkflowStep]
    } else {
        steps = assetGroup.workflow_steps
    }

    const {loading, approve, reject, unReject, pick} = useWorkflowStep(step, asset, onUpdate)

    const {approval, approval_state} = useApprovalState(step, asset)

    const canApprove = (step?.destination || isAccessRequest) && (approval_state == 'pending' || approval_state == 'picked')
    const canReject = approval_state == 'pending'
    const canPick = approval_state == 'pending'
    const canSetPending = approval_state == 'rejected' || approval_state == 'picked'

    if(iconOnly) {
        const style = {fontSize: largeIcon ? 30 : null, filter: 'drop-shadow(1px 1px 1px rgb(0 0 0))'}
        const icon = {
            'approved': <CheckCircleTwoTone twoToneColor={Colors.green} style={style}/>,
            'rejected': <CloseCircleTwoTone twoToneColor={Colors.red} style={style}/>,
            'picked': <HeartTwoTone twoToneColor={Colors.blue} style={style}/>
        }[approval?.aasm_state] || <UndoOutlined style={{...style, color: 'white'}}/>;

        // TODO: return icon if can't approve

        return (
            <Popover
                content={
                    <>
                        {approval && (
                            <div style={{marginBottom:'.5em'}}>
                                <small>
                                    {approval_state == 'approved' && (
                                        <>
                                            <CheckOutlined/> {t('approved','Approved').toProperCase()}
                                        </>
                                    )}
                                    {approval_state == 'picked' && (
                                        <>
                                            <HeartOutlined/> {t('picked','Picked').toProperCase()}
                                        </>
                                    )}
                                    {approval_state == 'rejected' && (
                                        <>
                                            <CloseOutlined/> {t('rejected','Rejected').toProperCase()}
                                        </>
                                    )}

                                    {approval_state == 'pending' && (
                                        <>
                                            <UndoOutlined/> {t('set-pending','Set pending').toProperCase()}
                                        </>
                                    )}
                                    &nbsp;
                                    {t('by','by')}
                                    &nbsp;
                                    {approval?.user ? <User user={approval.user}/> : 'workflow'}
                                    &nbsp;
                                    <TimeAgo date={approval?.created_at}/>
                                </small>
                                <br/>
                            </div>
                        )}
                        <Space>
                            {canReject && (
                                <Button
                                    onClick={() => reject(step)}
                                    danger
                                    ghost
                                    icon={<CloseOutlined/>}
                                    size={'small'}
                                    loading={loading}
                                >
                                    {t('button-reject','Reject')}
                                </Button>
                            )}

                            {canPick && (
                                <Button
                                    onClick={() => pick(step)}
                                    type={'primary'}
                                    ghost
                                    icon={<HeartOutlined/>}
                                    size={'small'}
                                    loading={loading}
                                >
                                    {t('button-pick','Pick')}
                                </Button>
                            )}

                            {canApprove && (
                                <Popconfirm
                                    title={'Approve?'}
                                    onConfirm={() => approve(step)}
                                >
                                    <Button
                                        type={'primary'}
                                        icon={<CheckOutlined/>}
                                        size={'small'}
                                        loading={loading}
                                    >
                                        {t('button-approve','Approve')}
                                    </Button>
                                </Popconfirm>
                            )}

                            {canSetPending && (
                                <Button
                                    onClick={() => unReject(step)}
                                    icon={<UndoOutlined/>}
                                    size={'small'}
                                    loading={loading}
                                >
                                    {t('button-set-pending','Set Pending')}
                                </Button>
                            )}
                        </Space>
                    </>
                }
            >
                {icon}
            </Popover>
        )
    }

    if(buttonsOnly) {
        return (
            <>
                {approval && (
                    <>
                        <small>
                            {approval_state == 'picked' && (
                                <>
                                    <HeartOutlined/> {t('picked','Picked').toProperCase()}
                                </>
                            )}
                            {approval_state == 'approved' && (
                                <>
                                    <CheckOutlined/> {t('approved','Approved').toProperCase()}
                                </>
                            )}
                            {approval_state == 'rejected' && (
                                <>
                                    <CloseOutlined/> {t('rejected','Rejected').toProperCase()}
                                </>
                            )}

                            {approval_state == 'pending' && (
                                <>
                                    <UndoOutlined/> {t('set-pending','Set pending').toProperCase()}
                                </>
                            )}
                            &nbsp;
                            {t('by','by')}
                            &nbsp;
                            {approval?.user ? <User user={approval.user}/> : 'workflow'}
                            &nbsp;
                            <TimeAgo date={approval?.created_at}/>
                        </small>
                        <br/>
                    </>
                )}

                <Space style={{marginTop:'.5em'}}>
                    {canReject && (
                        <Button
                            onClick={() => reject(step)}
                            danger
                            ghost
                            icon={<CloseOutlined/>}
                            size={'small'}
                            loading={loading}
                        >
                            {t('button-reject','Reject')}
                        </Button>
                    )}

                    {canPick && (
                        <Button
                            onClick={() => pick(step)}
                            type={'primary'}
                            ghost
                            icon={<HeartOutlined/>}
                            size={'small'}
                            loading={loading}
                        >
                            {t('button-pick','Pick')}
                        </Button>
                    )}

                    {canSetPending && (
                        <Button
                            onClick={() => unReject(step)}
                            icon={<UndoOutlined/>}
                            size={'small'}
                            loading={loading}
                        >
                            {t('button-set-pending','Set Pending')}
                        </Button>
                    )}

                    {canApprove && (
                        <Popconfirm
                            title={t('confirm-approve','Approve?')}
                            onConfirm={() => approve(step)}
                        >
                            <Button
                                type={'primary'}
                                icon={<CheckOutlined/>}
                                size={'small'}
                                loading={loading}
                            >
                                {t('button-approve','Approve')}
                            </Button>
                        </Popconfirm>
                    )}
                </Space>
            </>
        )
    }

    const s = steps.length > 1 ? 's' : '';
    return (
        <List
            bordered
            header={<>{<WorkflowIcon/>} {isAccessRequest ? t(`access-request${s}`,`Access Request${s}`) : t(`workflow${s}`,`Workflow${s}`)}{}</>}
            style={{marginTop:'1em', width:'100%'}}
            size={'small'}
            dataSource={[..._.compact(steps)]}
            renderItem={step => {
                // const approval = step.approvals[0]
                const approval = _.find(step.approvals, {asset_id: asset.id});

                const approval_state = approval?.aasm_state || 'pending';
                const canApprove = (step.destination || isAccessRequest) && (approval_state == 'pending' || approval_state == 'picked')
                const canReject = approval_state == 'pending'
                const canPick = approval_state == 'pending'
                const canSetPending = approval_state == 'rejected' || approval_state == 'picked'

                return (
                    <List.Item style={{width:'100%'}}>
                        <List.Item.Meta
                            title={
                                <Popover
                                    title={step.workflow?.name}
                                    content={
                                        <>
                                            {step.workflow.description}
                                            {step.destination && (
                                                <>
                                                    <br/>
                                                    {t('step','Step')}: {step.name}
                                                    <br/>
                                                    {t('description','Description')}: {step.description}
                                                </>
                                            )}
                                        </>
                                    }
                                >
                                    {step.workflow?.name}{step.destination && `: ${step.name}`}
                                </Popover>
                            }
                            description={
                                <>
                                    {step.destination && (
                                        <h5>
                                            <FolderFilled/> {step.asset_group.name}
                                            &nbsp;
                                            <ArrowRightOutlined/>
                                            &nbsp;
                                            <FolderFilled/> {step.destination?.path_names.join(' / ')}
                                        </h5>
                                    )}

                                    {approval && (
                                        <small>
                                            {approval_state == 'picked' && (
                                                <>
                                                    <HeartOutlined/> {t('picked','Picked').toProperCase()}
                                                </>
                                            )}
                                            {approval_state == 'approved' && (
                                                <>
                                                    <CheckOutlined/> {t('approved','Approved').toProperCase()}
                                                </>
                                            )}
                                            {approval_state == 'rejected' && (
                                                <>
                                                    <CloseOutlined/> {t('rejected','Rejected').toProperCase()}
                                                </>
                                            )}

                                            {approval_state == 'pending' && (
                                                <>
                                                    <UndoOutlined/> {t('set-pending','Set pending').toProperCase()}
                                                </>
                                            )}
                                            &nbsp;
                                            {t('by','by')}
                                            &nbsp;
                                            {approval.user ? <User user={approval.user}/> : t('workflow','workflow')}
                                            &nbsp;
                                            <TimeAgo date={approval.created_at}/>
                                            <br/>
                                        </small>
                                    )}

                                    <Space style={{marginTop:'.5em'}}>
                                        {canReject && (
                                            <Button
                                                onClick={()=> reject(step)}
                                                danger
                                                ghost
                                                icon={<CloseOutlined/>}
                                                size={'small'}
                                                loading={loading}
                                            >
                                                {t('button-reject','Reject')}
                                            </Button>
                                        )}

                                        {canPick && (
                                            <Button
                                                onClick={() => pick(step)}
                                                type={'primary'}
                                                ghost
                                                icon={<HeartOutlined/>}
                                                size={'small'}
                                                loading={loading}
                                            >
                                                {t('button-pick','Pick')}
                                            </Button>
                                        )}

                                        {canSetPending && (
                                            <Button
                                                onClick={() => unReject(step)}
                                                icon={<UndoOutlined/>}
                                                size={'small'}
                                                loading={loading}
                                            >
                                                {t('button-set-pending','Set Pending')}
                                            </Button>
                                        )}

                                        {canApprove && (
                                            <Popconfirm
                                                title={t('confirm-approve','Approve?')}
                                                onConfirm={()=> approve(step)}
                                            >
                                                <Button
                                                    type={'primary'}
                                                    icon={<CheckOutlined/>}
                                                    size={'small'}
                                                    loading={loading}
                                                >
                                                    {t('button-approve','Approve')}
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </Space>
                                </>
                            }
                        />
                    </List.Item>
                )
            }}
        />
    )
}

const useWorkflowStep = (step, asset, onUpdate) => {
    const [loading, setLoading] = useState()
    const {currentWorkflowStep} = useAssetGroupState();
    const assetGroupDispatch = useAssetGroupDispatch();
    const {t} = useTranslation();

    const {approval_state} = useApprovalState(step, asset)

    const post = (step, decision, verb) => {
        if(!step) return;

        const messageKey = `wf-update-${step.id}-${asset.id}`

        message.open({
            key: messageKey,
            type: 'loading',
            content: t('updating','Updating...'),
            duration: 0
        })

        setLoading(true)

        api.post(`/api/workflow_steps/${step.id}/approve`, {
            asset_ids: [asset.id],
            decision: decision
        }).then(res => {
            setLoading(false)

            if(step.id == currentWorkflowStep?.id) {
                // Reload Workflow:
                api(`/api/workflow_steps/${step.id}`).then(res => {
                    assetGroupDispatch({type:'setCurrentWorkflowStep', step: res.data})

                    message.success({
                        key: messageKey,
                        content: `${t('message-workflow-updated-colon','Workflow Updated:')} ${t(verb, verb.toProperCase())}`,
                        duration: 2
                    })
                })
            }

            onUpdate && onUpdate()
        }).catch(()=>{
            setLoading(false)
        })
    }

    const reject = (step) => {
        post(step, 'reject', 'rejected');
    }

    const pick = (step) => {
        approval_state === 'rejected' ? unReject(step) : post(step, 'pick', 'picked');
    }

    const approve = (step) => {
        post(step, 'approve', 'approved');
    }

    const unReject = (step) => {
        approval_state === 'pending' ? reject(step) : post(step, 'un_reject', 'set pending');
    }

    return {
        loading,
        reject,
        pick,
        approve,
        unReject
    }
}

export {useWorkflowStep}