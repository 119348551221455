import {Button, message, Popconfirm, Tooltip, Typography} from "antd";
import {DeleteFilled, DeleteOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import api from "../api";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {DeleteIcon, RefreshIcon} from "../helpers/icons";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import useRefreshAssets from "../helpers/useRefreshAssets";
import {useTranslation} from "react-i18next";

export default ({asset, enabled, iconOnly = false, cb, block})=>{
    const {t} = useTranslation();

    const {onDestroy, onRestore, restoring} = useDeleteAsset(asset,cb)

    const shown = enabled || (asset.manageable && !(asset.contract || asset.release))

    if(!shown) return <></>;

    return asset.trashed_at ? (
        <Popconfirm title={t('confirm-restore-asset','Restore Asset?')} onConfirm={onRestore}>
            {iconOnly ? (
                <Tooltip title={isBrowser() && t('tooltip-restore-asset','Restore Asset?')}><Button type={'text'} icon={<RefreshIcon/>}/></Tooltip>
            ) : (
                <Button ghost type={'primary'} icon={<DeleteOutlined/>} loading={restoring}>
                    {isBrowser() && t('button-restore','Restore')}
                </Button>
            )}
        </Popconfirm>
    ) : (
        <Popconfirm title={t('confirm-trash-asset','Trash Asset?')} onConfirm={onDestroy}>
            {block ? (
                <Button type={'text'} size={'small'}>
                    <Typography.Text type={'danger'}><DeleteOutlined/> {t('button-send-file-to-trash','Send File To Trash')}</Typography.Text>
                </Button>
            ) : (
                <>
                    {iconOnly ? (
                        <Tooltip title={isBrowser() && t('tooltip-trash-asset','Trash Asset?')}><Button type={'text'} icon={<DeleteIcon/>} block={block}/></Tooltip>
                    ) : (
                        <Button ghost danger icon={<DeleteOutlined/>} block={block}>
                            {isBrowser() && t('button-trash','Trash')}
                        </Button>
                    )}
                </>
            )}
        </Popconfirm>
    )
}

const useDeleteAsset = (asset, cb) => {
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch()

    const onDestroy = ()=>{
        console.log('delete', asset)
        api.delete(`/api/assets/${asset.id}`).then(res => {
            cb && cb(asset);

            assetsDispatch({type:'removeAsset', id: asset.id})
            loadedAssetsDispatch({type: 'removeAsset', asset})

            message.success(t('message-asset-moved-to-trash','Asset Moved to Trash'))
        })
    }

    const refresh = useRefreshAssets()

    const [restoring, setRestoring] = useState()
    const onRestore = ()=>{
        setRestoring(true)
        api.post(`/api/assets/${asset.id}/restore`).then(res => {
            setRestoring(false)
            message.success(t('message-asset-restored','Asset Restored'))
            refresh()
            assetsDispatch({type:'updateAsset', asset: res.data});
        })
    }

    return {onDestroy, onRestore, restoring}
}

export {useDeleteAsset}