import { DndProvider, createDndContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {getFilesFromDragEvent} from "html-dir-content";
import React, { useRef } from "react";

// https://github.com/react-dnd/react-dnd-html5-backend/issues/8

const dndBackendWithFolderSupport = (manager)=>{
    const backend = HTML5Backend(manager),
        orgTopDropCapture = backend.handleTopDropCapture;

    backend.handleTopDropCapture = (e) => {
        orgTopDropCapture.call(backend, e);
        if(backend.currentNativeSource)
            backend.currentNativeSource.item.dirContent = getFilesFromDragEvent(e, {recursive: true, withFullPath: true}); //returns a promise
    };

    return backend;
}

const RNDContext = createDndContext(dndBackendWithFolderSupport);

function useDNDProviderElement(props) {
    const manager = useRef(RNDContext);

    if (!props.children) return null;

    return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>;
}

export default function DragAndDrop(props) {
    const DNDElement = useDNDProviderElement(props);
    return <>{DNDElement}</>;
}