import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import VerticalSpace from "@/components/helpers/VerticalSpace";

export default ({error})=>{
    console.log('ERROR', error)

    const {t} = useTranslation();
    const modal = Modal.error({
        title: t('sorry','Sorry!'),
        content: (
            <VerticalSpace>
                {t('crash-message', 'Something has crashed Mediagraph\'s Front End Application, please try refreshing. \r\rWe\'ve been notified and if this error persists after refreshing the page, click the button below.')}
            </VerticalSpace>
        ),
        okText: t('button-clear-local-session','Clear Local Session'),
        onOk: () => {
            clearSession()
            location.reload()
        },
    });

    return <></>;
}
