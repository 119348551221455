export default {
    resizeLabel: 'Redimensionner',
    resizeIcon:
        '<g stroke-width=".125em" stroke="currentColor" fill="none"><rect x="2" y="12" width="10" height="10" rx="2"/><path d="M4 11.5V4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5"/><path d="M14 10l3.365-3.365M14 6h4v4"/></g>',

    resizeLabelFormCaption: `Taille de sortie d'image`,

    resizeLabelInputWidth: 'l',
    resizeTitleInputWidth: 'Largeur',
    resizeLabelInputHeight: 'h',
    resizeTitleInputHeight: 'Hauteur',

    resizeTitleButtonMaintainAspectRatio: `Maintenir le rapport d'apparence`,

    resizeIconButtonMaintainAspectRatio: (active, activeFraction) => `
        <defs>
            <mask id="mask1" x="0" y="0" width="24" height="24" >
                <rect x="0" y="0" width="24" height="10" fill="#fff" stroke="none"/>
            </mask>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g  mask="url(#mask1)">
                <path transform="translate(0 ${
                    (activeFraction - 1) * 3
                })" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" d="M9.401 10.205v-.804a2.599 2.599 0 0 1 5.198 0V17"/>
            </g>
            <rect fill="currentColor" x="7" y="10" width="10" height="7" rx="1.5"/>
        </g>
    `,
};
