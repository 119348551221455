import React, {useContext, useEffect, useRef, useState} from "react";
import {Badge, Button, Card, Checkbox, Divider, Flex, message, Popover, Radio, Select, Space, Tooltip, Typography} from "antd";
import TableOutlined from "@ant-design/icons/lib/icons/TableOutlined";
import UnorderedListOutlined from "@ant-design/icons/lib/icons/UnorderedListOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import {isMobile, isBrowser} from "device-detect";
import {GlobalHotKeys} from "react-hotkeys";
import {GlobalOutlined, MacCommandFilled, OrderedListOutlined, PicCenterOutlined} from "@ant-design/icons";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {TwitterPicker} from "react-color";
import api from "../api";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import {ViewSettingsContext} from "../../contexts/ViewSettingsContext";
import {GraphIcon} from "../helpers/icons";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import useCurrentOrg from "@/components/helpers/useCurrentOrg";

export default ({})=> {
    const {t} = useTranslation();
    const currentUser = useCurrentUser();

    const {
        defaults, viewMode, gridSize, gridColor,
        changeGridSize, changeGridColor, changeViewMode, tocHidden, toggleToc,
        showFilenames, changeShowFilenames, autoPlay, changeAutoPlay, showIcons, changeShowIcons,
        showRating, changeShowRating, showHeadline, changeShowHeadline,
    } = useViewMode()

    const setCurrentAsset = useSetCurrentAsset()

    const {currentAssetGroup, currentTag} = useAssetGroupState()

    const gridSizes = ['xs', 's', 'm', 'l', 'xl']

    const ref = useRef();
    const keyMap = {
        G: 'g',
        T: 't',
        M: 'm',
        P: 'p', // Disabled for now
        PLUS: '=',
        MINUS: '-',
    }

    const handlers = {
        G: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            changeViewMode('grid')
            setCurrentAsset()
        },
        T: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            changeViewMode('table')
            setCurrentAsset()
        },
        M: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            changeViewMode('map')
            setCurrentAsset()
        },
        P: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            changeViewMode('posts')
            setCurrentAsset()
        },
        PLUS: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            const size = gridSizes[gridSizes.indexOf(gridSize) + 1]
            if(size) changeGridSize(size)

        },
        MINUS: (e) => {
            if(e.getModifierState("Meta")) return;
            e.preventDefault()
            const size = gridSizes[gridSizes.indexOf(gridSize) - 1]
            if(size) changeGridSize(size)
        }
    }

    // ------------
    // Saving
    // ------------
    const assetGroupDispatch = useAssetGroupDispatch()

    const value = { gridSize, gridColor, viewMode, }

    const notDefault = currentAssetGroup &&
        ((currentAssetGroup.view_settings && !_.isEqual(currentAssetGroup.view_settings, value)) ||
            (!currentAssetGroup.view_settings && !_.isEqual(value, defaults)))

    const [saving, setSaving] = useState()
    const save = ()=> {
        setSaving(true)

        const route = {
            'Collection': 'collections',
            'StorageFolder': 'storage_folders',
            'Lightbox': 'lightboxes'
        }[currentAssetGroup.type];

        const param = {
            'Collection': 'collection',
            'StorageFolder': 'storage_folder',
            'Lightbox': 'lightbox'
        }[currentAssetGroup.type];

        const data = {}
        data[param] = {view_settings: value}

        api.put(`/api/${route}/${currentAssetGroup.id}`, data).then(res => {
            setSaving(false)
            assetGroupDispatch({type:'updateCurrentAssetGroup', assetGroup: {view_settings: value}})
            message.success(t('message-view-settings-updated','View settings saved!'))
        })
    }

    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref} allowChanges>
            <Popover
                getPopupContainer={e => e.parentElement}
                trigger={['click','hover']}
                overlayStyle={{width:275}}
                title={
                    <>
                        <EyeOutlined/> {t('view-settings','View Settings')}

                        {currentAssetGroup?.editable && notDefault && (
                            <div style={{float:'right'}}>
                                <Tooltip title={t('tooltip-set-container-default-view-settings',`Set default view settings for this {{type}}`, {type: currentAssetGroup.type})}>
                                    <Button size='small' icon={<SaveOutlined/>} onClick={save} type={'primary'} ghost loading={saving}>{t('button-save','Save')}</Button>
                                </Tooltip>
                            </div>
                        )}
                    </>
                }
                id={'view-settings-popover'}
                content={
                    <VerticalSpace>
                        {(currentAssetGroup?.enable_toc || currentTag) && (
                            <>
                                <Checkbox onChange={toggleToc}
                                          checked={!tocHidden}>{t('show-table-of-contents', 'Show Table of Contents')}</Checkbox>
                                <Divider style={{margin: '.5em 0'}}/>
                            </>
                        )}

                        <div>
                            <strong>View Mode:</strong>
                            <Radio.Group
                                size={'small'}
                                onChange={changeViewMode}
                                value={viewMode}
                                tabIndex={0}
                                id={'view-mode-radios'}
                            >
                                <Radio.Button value={'grid'} id={'grid-view-btn'}>
                                    <Tooltip title={isBrowser() && <>{t('tooltip-grid-view', 'Grid View')}: g</>}>
                                        <TableOutlined/> {t('grid', 'Grid')}
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value={'table'} id={'table-view-btn'}>
                                    <Tooltip title={isBrowser() && <>{t('tooltip-table-view', 'Table View')}: t</>}>
                                        <UnorderedListOutlined/> {t('table', 'Table')}
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value={'map'} id={'map-view-btn'}>
                                    <Tooltip title={isBrowser() && <>{t('tooltip-map-view', 'Map View')}: m</>}>
                                        <GlobalOutlined/> {t('map', 'Map')}
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value={'posts'} id={'posts-view-btn'}>
                                    <Tooltip title={isBrowser() && <>{t('tooltip-posts-view', 'Posts View')}: p</>}>
                                        <PicCenterOutlined /> {t('posts', 'Posts')}
                                    </Tooltip>
                                </Radio.Button>
                                {/*{currentUser?.super_admin && (*/}
                                {/*    <Radio.Button value={'graph'} id={'graph-view-btn'}>*/}
                                {/*        <Tooltip title={isBrowser() && <>Graph View: p</>}>*/}
                                {/*            <GraphIcon/>  Graph*/}
                                {/*        </Tooltip>*/}
                                {/*    </Radio.Button>*/}
                                {/*)}*/}
                            </Radio.Group>
                        </div>

                        <Divider style={{margin: '.5em 0'}}/>

                        <VerticalSpace size={2}>
                            <Checkbox checked={showHeadline} onChange={changeShowHeadline}>{t('show-headline', 'Show Headline')}</Checkbox>
                            <Checkbox checked={showRating} onChange={changeShowRating}>{t('show-rating', 'Show Rating')}</Checkbox>
                            <Checkbox checked={showFilenames} onChange={changeShowFilenames}>{t('show-filenames', 'Show Filenames')}</Checkbox>
                            <Checkbox checked={showIcons} onChange={changeShowIcons}>{t('show-icons', 'Show Grid Icons')}</Checkbox>
                            <Checkbox checked={autoPlay} onChange={changeAutoPlay}>{t('auto-play-videos', 'Auto-Play Videos')}</Checkbox>
                        </VerticalSpace>

                        <Divider style={{margin: '.5em 0'}}/>

                        <VerticalSpace>
                            <div>
                                <strong>{t('grid-size', 'Grid Size')}:</strong>
                                <br/>
                                <Radio.Group
                                    size={'small'}
                                    onChange={changeGridSize}
                                    value={gridSize}
                                    disabled={viewMode != 'grid'}
                                    style={{marginBottom: '1em'}}
                                    tabIndex={0}
                                    id={'grid-size-radios'}
                                >
                                    <Radio.Button value={'xs'}>
                                        {t('grid-size-xs', 'XS')}
                                    </Radio.Button>
                                    <Radio.Button value={'s'}>
                                        {t('grid-size-s', 'S')}
                                    </Radio.Button>
                                    <Radio.Button value={'m'}>
                                        {t('grid-size-m', 'M')}
                                    </Radio.Button>
                                    <Radio.Button value={'l'}>
                                        {t('grid-size-L', 'L')}
                                    </Radio.Button>
                                    <Radio.Button value={'xl'}>
                                        {t('grid-size-xl', 'XL')}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>

                            <div style={{marginTop:'.5em'}}>
                                <strong>{t('grid-background-color', 'Grid Background Color')}:</strong>
                                <TwitterPicker
                                    aria-label={t('grid-background-color', 'Grid Background Color')}
                                    color={gridColor}
                                    onChangeComplete={changeGridColor}
                                    colors={['#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
                                    width={150}
                                    triangle={'hide'}
                                />
                            </div>
                        </VerticalSpace>

                        <Divider style={{margin: '.5em 0'}}/>

                        {currentUser && (
                            <VerticalSpace>
                                <strong>{t('color-labels','Color Labels')}:</strong>
                                <ColorLabelSelect/>
                            </VerticalSpace>
                        )}
                    </VerticalSpace>
                }
                placement={'bottomLeft'}
            >
                <Button size={'small'} type={'text'} id={'view-menu-btn'} aria-label={t('view-menu', 'View Menu')}>
                    <EyeOutlined/>
                </Button>
            </Popover>
        </GlobalHotKeys>
    )
}

const ColorLabelSelect = ({onSelect, hideKey})=>{
    const {t} = useTranslation();
    const {colorLabel, changeColorLabel} = useViewMode()

    const [customMetaFields, setCustomMetaFields] = useState([])

    const [loading, setLoading] = useState()

    useEffect(()=>{
        if(loading) return;

        setLoading(true)

        api(`/api/custom_meta_fields?with_color_labels=true`).then(res => {
            setLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [])

    const options = [
        {
            label: t('custom-meta-fields', 'Custom Meta Fields'),
            options: customMetaFields.map(cmf => { return {label: cmf.name, title: cmf.name, value: cmf.id}})
        },
        // {
        //     label: t('asset-attributes', 'Asset Attributes'),
        //     options: [
        //         {label: t('file-type', 'File Type'), value: 'file_type'},
        //         {label: t('download-access', 'Download Access'), value: 'download_access'},
        //     ]
        // }
    ]

    const onSelectColorLabel = val => {
        changeColorLabel(val)
        onSelect && onSelect(val)
    }

    const cmf = _.find(customMetaFields, {id: colorLabel})

    if(!customMetaFields.length) return <em>{t('none-yet', 'None yet...')}</em>

    return (
        <VerticalSpace>
            <Select
                placeholder={t('select-source','Select Source...')}
                allowClear
                style={{width:'100%'}}
                loading={loading}
                onChange={onSelectColorLabel}
                defaultValue={colorLabel}
                options={options}
            />

            {colorLabel && !hideKey && (
                <VerticalSpace size={2}>
                    <strong>{t('key', 'Key')}:</strong>
                    {cmf?.custom_meta_values_attributes?.map(cmv => (
                        <Flex gap={6} key={cmv.id}>
                            {cmv.color_label ? <Badge color={cmv.color_label}/> : <div style={{width:15, height:15}}/>}
                            {cmv.text}
                        </Flex>
                    ))}
                </VerticalSpace>
            )}
        </VerticalSpace>
    )
}

const useViewMode = () => {
    return useContext(ViewSettingsContext);
}

export {useViewMode, ColorLabelSelect}
