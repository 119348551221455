import React, {useState} from "react";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import api from "../api";
import {Button, Tooltip} from "antd";
import {FileTextOutlined} from "@ant-design/icons";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";

function useLoadAssetFromGuid() {
    const assetsDispatch = useAssetsDispatch();
    const setCurrentAsset = useSetCurrentAsset()

    const {assets} = useAssetsState()

    const [loadingGuid, setLoadingGuid] = useState()

    const loadAssetFromGuid = (guid)=> {
        if(loadingGuid == guid) return

        const existing = assets && (assets[guid] || _.find(Object.values(assets), {guid}))
        if(existing) return setCurrentAsset(existing)

        setLoadingGuid(guid)

        api(`/api/assets/${guid}?view=false`).then(res => {
            assetsDispatch({type:'updateAsset', asset: res.data});
            setCurrentAsset(res.data)
            setLoadingGuid(null)
        })
    }

    return loadAssetFromGuid
}

export {useLoadAssetFromGuid}

export default ({asset})=> {
    const loadAssetFromGuid = useLoadAssetFromGuid()

    return (
        <Tooltip title={`View ${asset.filename}`}>
            <Button type='text' onClick={() => loadAssetFromGuid(asset.guid) }>
                <FileTextOutlined/> {asset.filename}
            </Button>
        </Tooltip>
    )
}
