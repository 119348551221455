import React, {useContext, useState} from "react";
import {Alert, message, Button, Row, Col, Card } from "antd"
import setTitle from "../helpers/setTitle";
import {Checkbox, Form, FormItem, Input, SubmitButton} from "formik-antd";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import queryString from "query-string";
import {isMobile} from "device-detect";

export default () => {
    setTitle('Update Password');

    const location = useLocation()
    const {reset_password_token} = queryString.parse(location.search)

    const navigate = useNavigate();

    return (
        <Card title='Update Password' style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
            <Formik
                initialValues={{
                    password: "",
                    password_confirmation: "",
                }}
                onSubmit={(values, actions) => {
                    values.reset_password_token = reset_password_token
                    console.log(values)
                    api({
                        method:'post',
                        url: '/api/update_password',
                        data: values
                    }).then((res)=>{
                        console.log(res)
                        actions.setSubmitting(false)
                        message.success('Password updated!')
                        navigate('/login')
                    }).catch((res)=>{
                        message.info(JSON.stringify(res))
                        console.log(res)
                        actions.setSubmitting(false)
                    })
                }}
                validationSchema={
                    Yup.object({
                        password: Yup.string().min(8).required('Required'),
                        password_confirmation: Yup.string()
                            .required('Required')
                            .oneOf([Yup.ref('password')], 'Password does not match'),
                    })
                }
                render={({isSubmitting}) => (
                    <Form id={'update-password-form'}>
                        <FormItem name="password" required showValidateSuccess>
                            <Input.Password
                                prefix={<LockOutlined style={{opacity:0.5}}/>}
                                name="password"
                                placeholder="Password"
                            />
                        </FormItem>

                        <FormItem name="password_confirmation" required showValidateSuccess>
                            <Input.Password
                                prefix={<LockOutlined style={{opacity:0.5}}/>}
                                name="password_confirmation"
                                placeholder="Password Confirmation"
                            />
                        </FormItem>

                        <FormItem name="submit" style={{marginBottom:0}}>
                            {isSubmitting ? (
                                <Button block type="primary" icon={<PoweroffOutlined />} loading>Updating...</Button>
                            ) : (
                                <SubmitButton block>Update</SubmitButton>
                            )}
                        </FormItem>
                    </Form>
                )}
            />
        </Card>
    );
}
