import {
    Alert,
    Badge,
    Button,
    Collapse,
    Divider,
    message,
    Popconfirm,
    Select,
    Switch,
    Tag,
    Tooltip,
    Typography
} from "antd";
import React, {useEffect, useState} from "react";
import CommentThread from "../widgets/CommentThread";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import CommentOutlined from "@ant-design/icons/lib/icons/CommentOutlined";
import DoubleRightOutlined from "@ant-design/icons/lib/icons/DoubleRightOutlined";
import DoubleLeftOutlined from "@ant-design/icons/lib/icons/DoubleLeftOutlined";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {GreyBadge, LightroomIcon, WorkflowIcon} from "../helpers/icons";
import {
    ArrowRightOutlined,
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    FolderFilled,
    HeartOutlined,
    UndoOutlined
} from "@ant-design/icons";
import {useFilters} from "../helpers/useFilters";
import api from "../api";
import queryString from "query-string";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useStorageState} from "react-storage-hooks";

import {isBrowser} from "device-detect";
import HelpPopover from "../HelpPopover";
import VerticalSpace from "../helpers/VerticalSpace";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import AssetGroupLink from "../widgets/AssetGroupLink";
import {useTranslation} from "react-i18next";
import useCurrentOrg from "~/components/helpers/useCurrentOrg";
import {CheckoutFilter} from "@/components/explore/ExploreFilters";
import EnabledDisabled from "@/components/helpers/EnabledDisabled";
import {AssetGroupLightroomConnectionButton} from "@/components/widgets/AssetGroupLightroomConnectionButton";
import useCurrentUser from "@/components/helpers/useCurrentUser";
import {useBulkAction} from "~/components/explore/BulkEditButton";
import useRefreshAssets from "@/components/helpers/useRefreshAssets";

export default ({collapsed, toggleSidebar})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const {currentAssetGroup, currentWorkflowStep} = useAssetGroupState()

    const location = useLocation()
    const {refreshing} = queryString.parse(location.search, {arrayFormat: 'bracket'})

    const [activeKeys, setActiveKeys] =
        useStorageState(sessionStorage, `activeRightSidebarKeys`, [currentWorkflowStep && 'workflows']);

    useEffect(()=>{
        if(collapsed && location.hash == '#comments') {
            toggleSidebar(true)
        }
    },[location.hash])

    const [commentCount, setCommentCount] = useState(currentAssetGroup.comments_count);

    useEffect(()=>{
        setCommentCount(currentAssetGroup?.comments_count)
    }, [currentAssetGroup?.id])

    const toggleStyle = collapsed ? {position: 'fixed', right: 0, top: 0} : {position: 'absolute', left: 0, top: 0}
    const headerHeight = document.getElementById('header')?.clientHeight

    useEffect(()=>{
        if(refreshing) setActiveKeys([...activeKeys, 'lightroom'])
    }, [refreshing])

    const {filters} = useFilters()

    return (
        <div style={{padding:'1em 1em'}}>
            {isBrowser() && (
                <div style={toggleStyle}>
                    <Tooltip
                        trigger={['hover', 'click']}
                        placement={'right'}
                        title={<>{collapsed ? t('tooltip-show-sidebar','Show Sidebar') : t('tooltip-hide-sidebar','Hide Sidebar')} <Typography.Text keyboard style={{backgroundColor:'grey'}}>]</Typography.Text></>}
                    >
                        <Button
                            size='small'
                            onClick={toggleSidebar}
                            id={'right-sidebar-btn'}
                            type={'text'}
                            style={{height: `calc(100vh - ${headerHeight}px)`, paddingTop:'auto', paddingBottom:'auto', paddingLeft:0, paddingRight:0}}
                        >
                            <Typography.Text type={'secondary'}>
                                {collapsed ? (
                                    <>
                                        <DoubleLeftOutlined />
                                    </>
                                ) : (
                                    <DoubleRightOutlined />
                                )}
                            </Typography.Text>
                        </Button>
                    </Tooltip>
                </div>
            )}

            {!collapsed && (
                <Collapse
                    activeKey={activeKeys}
                    onChange={keys => setActiveKeys(keys)}
                >
                    {currentOrg?.lightroom_account && currentAssetGroup?.user?.id === currentUser?.id && (
                        <Collapse.Panel
                            id={'lightroom-sync'}
                            key={'lightroom'}
                            header={
                                <>
                                    <LightroomIcon style={{marginRight:'.5em'}}/> Lightroom Connection
                                    <EnabledDisabled
                                        enabled={currentAssetGroup.asset_group_lightroom_connection} onOff
                                        labels={{on: <><CheckOutlined/> Active</>, off: 'None'}}
                                    />
                                </>
                            }
                        >
                            <AssetGroupLightroomConnectionButton assetGroup={currentAssetGroup}/>

                            {/*<SyncLightroomButton/>*/}

                            {/*<Divider/>*/}
                            {/*<strong>Filter by Checkout Status:</strong>*/}
                            {/*<br/>*/}
                            {/*<CheckoutFilter/>*/}
                        </Collapse.Panel>
                    )}

                    {!!currentAssetGroup?.workflow_steps?.filter(s => !s.auto_approval )?.length && (
                        <Collapse.Panel
                            key='workflows'
                            id={'right-sidebar-workflows-panel'}
                            header={
                                <>
                                    {!currentAssetGroup?.access_request_id ? (
                                        <>
                                            <WorkflowIcon/> {t('workflows','Workflows')}
                                            <HelpPopover code={'right-sidebar-workflows'}/>
                                        </>
                                    ) : (
                                        <>
                                            <WorkflowIcon/> {t('approve-or-reject','Approve or Reject')}
                                        </>
                                    )}

                                    <div style={{marginLeft:'auto'}}>
                                        {!!filters.workflow_state?.length && (
                                            <Tag color={'red'}>{t('filtered','Filtered')}</Tag>
                                        )}
                                    </div>
                                </>
                            }
                        >
                            <WorkflowControls/>
                        </Collapse.Panel>
                    )}

                    {currentAssetGroup?.commentable && (
                        <Collapse.Panel id='comment-collapse-panel' key='comments' header={<><CommentOutlined/> <span>{t('comments','Comments')}</span> <GreyBadge count={commentCount}/> <HelpPopover code={'right-sidebar-comments'}/></>}>
                            <CommentThread what={currentAssetGroup} visible={true} setCount={setCommentCount}/>
                        </Collapse.Panel>
                    )}
                </Collapse>
            )}
        </div>
    )
}

const WorkflowControls = ()=> {
    const {t} = useTranslation();
    let location = useLocation()
    const navigate = useNavigate();
    
    const {filters, setFilters, removeFilter} = useFilters()

    const {currentAssetGroup, currentWorkflowStep} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch();

    const {selectedAssetIds} = useSelectedAssetsState();
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const {reloads} = useLoadedAssetsState()
    const refreshAssets = useRefreshAssets()

    const refreshWorkflow = () => {
        selectedAssetDispatch({type:'selectNone'})

        loadWorkflowStep(currentWorkflowStep.id, refreshAssets)
    }

    useEffect(()=>{
        loadWorkflowStep(currentWorkflowStep.id)
    }, [reloads])

    const loadWorkflowStep = (id, cb)=>{
        api(`/api/workflow_steps/${id}`).then(res => {
            assetGroupDispatch({type:'setCurrentWorkflowStep', step: res.data})
            cb && setTimeout(cb, 100) // The callback may need to have the WorkflowStep loaded.
        })
    }

    const query = queryString.parse(location.search, {arrayFormat: 'bracket'})

    const unsetWorkflow = ()=>{
        assetGroupDispatch({type:'setCurrentWorkflowStep', step: null})

        // Remove from query string:
        delete query.workflow_step_id
        location.search = queryString.stringify(query, {arrayFormat: 'bracket'})
        navigate(location)

        removeFilter('workflow_state')
    }

    const getWorkflowStep = (id)=>{
        if(!id) return unsetWorkflow()

        const queryWorkflowStepId = query.workflow_step_id

        if(queryWorkflowStepId != id?.toString()) {
            // Trigger the workflow step to be detected and loaded from the query string:
            query.workflow_step_id = id
            location.search = queryString.stringify(query, {arrayFormat: 'bracket'})
            navigate(location)
        }
    }

    useEffect(()=>{
        if(query.workflow_step_id && query.workflow_step_id != currentWorkflowStep?.id) {

            if(!query.workflow_step_id) {
                unsetWorkflow()
            } else {
                loadWorkflowStep(query.workflow_step_id)
            }
        }
    }, [query.workflow_step_id])

    const bulkAction = useBulkAction()

    const post = (decision, cb) => {
        bulkAction({
            workflow_step_id: currentWorkflowStep.id,
            workflow_decision: decision
        }, {
            noConfirm: true,
            noRefresh: false,
            cb: ()=> {
                message.success(t('message-workflow-udated','Workflow Updated.'))
                refreshWorkflow()
                cb && cb()
            }
        })
    }

    const [picking, setPicking] = useState()
    const pick = () => {
        setPicking(true)
        post('pick', ()=> setPicking(false));
    }

    const [approving, setApproving] = useState()
    const approve = () => {
        setApproving(true)
        post('approve', ()=> setApproving(false));
    }

    const [rejecting, setRejecting] = useState()
    const reject = () => {
        setRejecting(true)
        post('reject', ()=> setRejecting(false));
    }

    const [settingPending, setSettingPending] = useState()
    const unReject = () => {
        setSettingPending(true)
        post('un_reject', ()=> setSettingPending(false));
    }

    const [approvingPicks, setApprovingPicks] = useState()

    const approvePicks = ()=>{
        setApprovingPicks(true)

        bulkAction({
            workflow_step_id: currentWorkflowStep.id,
            workflow_decision: 'approve_picks'
        }, {
            noAssetIds: true,
            noConfirm: true,
            noRefresh: true,
            cb: ()=> {
                setApprovingPicks(false)
                message.success(t('message-picks-approved','Picks Approved!'))
                refreshWorkflow()
            }
        })
    }

    const filter = val => {
        return ()=> {
            setFilters(filters.workflow_state.indexOf(val) != -1, {workflow_state: val})
        }
    }

    // Auto-start w/ pending filter applied:
    useEffect(()=>{
        if(currentWorkflowStep && filters.workflow_state.indexOf('pending') == -1) {
            setFilters(false, {workflow_state: 'pending'})
        }
    }, [currentWorkflowStep?.id])

    const isAccessRequest = !!currentWorkflowStep?.asset_group?.access_request_id

    const canApprove = currentWorkflowStep?.destination || isAccessRequest

    return (
        <>
            {!isAccessRequest && (
                <Tooltip title={'Workflow Selection'} placement={'left'}>
                    <Select
                        allowClear={{clearIcon: <Tooltip title={'Hide Workflow'}><CloseCircleFilled/></Tooltip>}}
                        value={currentWorkflowStep?.id}
                        placeholder={t('placeholder-select-a-workflow','Select a Workflow')}
                        style={{width:'100%'}}
                        onChange={(id, option) => getWorkflowStep(id)}
                        optionLabelProp={'title'}
                        options={
                            currentAssetGroup.workflow_steps.map(step => { return {
                                value: step.id,
                                step: step,
                                title: `${step.workflow.name}: ${step.name}`,
                                label: (
                                    <>
                                        <strong><WorkflowIcon/> {step.workflow.name}</strong>
                                        {step.destination && (
                                            <>
                                                <br/>
                                                <small>{step.workflow.description}</small>
                                                <br/>
                                                <small>{t('step','Step')}: {step.name}</small>
                                                <br/>
                                                <small>{step.description}</small>
                                                <h5>
                                                    <FolderFilled/> {step.asset_group.name}
                                                    &nbsp;
                                                    <ArrowRightOutlined/>
                                                    &nbsp;
                                                    <FolderFilled/> {step.destination?.path_names.join(' / ')}
                                                    <div style={{float:'right'}}>
                                                        <em>{step.auto_approval ? t('auto','Auto') : t('manual','Manual')}</em>
                                                    </div>

                                                </h5>
                                            </>
                                        )}
                                    </>
                                )
                            }})
                        }
                    />
                </Tooltip>
            )}

            {isAccessRequest && (
                <Alert
                    type={'info'}
                    showIcon
                    description={t('alert-access-request-info','Use the pick and reject flags to sort through the requested files. Note, only Approved files will be made available to Requester')}
                />
            )}

            {currentWorkflowStep && (
                <VerticalSpace>
                    {currentWorkflowStep.destination && !isAccessRequest && (
                        <VerticalSpace>
                            <div style={{margin:'.5em 0'}}>
                                {currentWorkflowStep.workflow.description}
                            </div>
                            <div>
                                <strong>{t('step','Step')}:</strong>
                                <br/>
                                {currentWorkflowStep.name}
                            </div>
                            <div>
                                <strong>{t('instructions','Instructions')}:</strong>
                                <br/>
                                {currentWorkflowStep.description}
                            </div>
                            <div>
                                <strong>{t('destination','Destination')}: <HelpPopover code={'workflow-step-destination'}/></strong>
                                <br/>
                                <AssetGroupLink assetGroup={currentWorkflowStep.destination}/>
                                {/*<FolderFilled/> {currentWorkflowStep.destination?.path_names.join(' / ')}*/}
                                <div style={{float:'right'}}>
                                    <em>{currentWorkflowStep.auto_approval ? t('auto','Auto') : t('manual','Manual')}</em>
                                </div>
                            </div>
                        </VerticalSpace>
                    )}

                    <Divider style={{margin:'0 0 .5em 0'}}><small>{t('filter','Filter')}:</small></Divider>

                    <div>
                        <Switch onChange={filter('pending')} checked={filters.workflow_state.indexOf('pending') != -1}/> <UndoOutlined/> {t('pending','Pending').toProperCase()} <Badge count={currentWorkflowStep.pending_count} style={{backgroundColor:'#bbb'}}/>
                    </div>
                    <div>
                        <Switch onChange={filter('picked')} checked={filters.workflow_state.indexOf('picked') != -1}/> <HeartOutlined/> {t('picked','Picked').toProperCase()} <Badge count={currentWorkflowStep.picked_count} style={{backgroundColor:'#bbb'}}/>
                    </div>
                    <div>
                        <Switch onChange={filter('rejected')} checked={filters.workflow_state.indexOf('rejected') != -1}/> <CloseOutlined/> {t('rejected','Rejected').toProperCase()} <Badge count={currentWorkflowStep.rejected_count} style={{backgroundColor:'#bbb'}}/>
                    </div>
                    {(currentWorkflowStep.destination || isAccessRequest) && (
                        <div>
                            <Divider style={{margin:'.5em 0'}}/>
                            <Switch onChange={filter('approved')} checked={filters.workflow_state.indexOf('approved') != -1}/> <CheckOutlined/> {t('approved','Approved').toProperCase()} <Badge count={currentWorkflowStep.approved_count} style={{backgroundColor:'#bbb'}}/>
                        </div>
                    )}
                </VerticalSpace>
            )}
            {currentWorkflowStep && !!selectedAssetIds.length && (
                <>
                    <Divider><small>{t('actions','Actions')}:</small></Divider>
                    {t('set-flag-for-selected-files','Set flag for selected files')}:
                    <br/>
                    <VerticalSpace style={{marginTop:'1em'}}>
                        <div style={{display:'flex', marginTop:'.5em'}}>
                            <Tooltip title={t('tooltip-pick-selected','Pick Selected')}>
                                <Button
                                    ghost
                                    type={'primary'}
                                    onClick={pick}
                                    loading={picking}
                                    icon={<HeartOutlined/>}
                                    size={'small'}
                                    block
                                >
                                    {t('button-pick','Pick')}
                                </Button>
                            </Tooltip>

                            <Tooltip title={t('tooltip-reject-selected','Reject Selected')}>
                                <Button
                                    onClick={reject}
                                    danger
                                    ghost
                                    icon={<CloseOutlined/>}
                                    size={'small'}
                                    loading={rejecting}
                                    block
                                    style={{margin:'0 .5em'}}
                                >
                                    {t('button-reject','Reject')}
                                </Button>
                            </Tooltip>
                            <Tooltip title={t('tooltip-set-selected-pending','Set Selected Pending')}>
                                <Button
                                    onClick={unReject}
                                    icon={<UndoOutlined/>}
                                    size={'small'}
                                    loading={settingPending}
                                    block
                                >
                                    {t('button-reset','Reset')}
                                </Button>
                            </Tooltip>

                        </div>

                        {canApprove && (
                            <>
                                <Divider style={{margin:'.5em 0'}}/>
                                {/*Approve selected files:*/}

                                <Popconfirm
                                    title={
                                        <>
                                            <h3>
                                                {selectedAssetIds.length === 1 ? t('approve-1-file', 'Approve 1 File') : t('approve-files', 'Approve {{count}} Files', {count: selectedAssetIds.length})}
                                            </h3>

                                            {!isAccessRequest && (
                                                <>
                                                    {t('workflow-all-approved-files-note','All approved files will be immediately added to the')}
                                                    <br/>
                                                    "{currentWorkflowStep.destination.name}" &nbsp;
                                                    {t(currentWorkflowStep.destination.type, currentWorkflowStep.destination.type)}
                                                    <br/>
                                                    <small>{t('workflow-note-cannot-unapprove','Note: you cannot un-approve files.')}</small>
                                                </>
                                            )}
                                        </>
                                    }
                                    onConfirm={approve}
                                >
                                    <Button
                                        type={'primary'}
                                        icon={<CheckOutlined/>}
                                        size={'small'}
                                        loading={approving}
                                        block
                                    >
                                        {t('button-set-as-approved','Set as Approved')}...
                                    </Button>
                                </Popconfirm>
                            </>
                        )}

                    </VerticalSpace>
                </>
            )}

            {!!currentWorkflowStep?.picked_count && canApprove && (
                <>
                    <Divider/>
                    {t('approve-all-picked-files','Approve all picked files')}:
                    <br/>
                    <Popconfirm
                        title={
                            <>
                                <h3>
                                    {currentWorkflowStep.picked_count === 1 ? t('approve-1-file', 'Approve 1 File') : t('approve-files', 'Approve {{count}} Files', {count: currentWorkflowStep.picked_count})}
                                </h3>

                                {!isAccessRequest && (
                                    <>
                                        {t('workflow-all-picked-files-note','All picked files will be immediately added to the')}
                                        <br/>
                                        "{currentWorkflowStep.destination.name}" &nbsp;
                                        {currentWorkflowStep.destination.type}
                                        <br/>
                                        <small>{t('workflow-note-cannot-unapprove','Note: you cannot un-approve files.')}</small>
                                    </>
                                )}
                            </>
                        }

                        onConfirm={approvePicks}
                    >
                        <Button
                            loading={approvingPicks}
                            type={'primary'}
                            ghost
                            icon={<CheckOutlined/>}
                            style={{marginTop:'1em'}}
                        >
                            {t('button-approve-picked','Approve {{count}} Picked', {count: currentWorkflowStep.picked_count})}
                        </Button>
                    </Popconfirm>
                </>
            )}
        </>
    )
}

