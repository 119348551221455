import React, {useEffect, useRef, useState} from "react";

import moment from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
moment.extend(isBetween)

import {message, Button, Calendar, Col, Divider, Modal, Row, Popover, Tooltip, Typography, Space} from "antd";
import {CalendarOutlined, CloseOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {Formik} from "formik";
import * as Yup from "yup";
import {Form, FormItem, Input, DatePicker, Select} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import {EditableTag} from "./TagSelect";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import Tag from "antd/lib/tag";
import {
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";

export default ({mini, onUpdate, refresh})=>{
    const {t} = useTranslation();
     const assetsDispatch = useAssetsDispatch();

    const [selectedDate, setSelectedDate] = useState(moment())
    const onSelect = date =>{
        setSelectedDate(date)
    }

    const [createModalVisible, setCreateModalVisible] = useState()
    const clickCreate = () => {
        setCreateModalVisible(true)
        autoFocusInput.current?.focus()
    }

    const autoFocusInput = useRef(null);

    const [events, setEvents] = useState([])

    const loadEvents = (month, year)=>{
        api('/api/tags/events', {params: {month, year}}).then(res => {
            console.log(res.data)
            assetsDispatch({type:'addTags', tags: res.data});
            setEvents(res.data);
        })
    }

    useEffect(()=>{
        if(!selectedDate) return;

        loadEvents(selectedDate.month() + 1, selectedDate.year())
    }, [refresh])

    const [month, setMonth] = useState(moment().month() + 1)
    const [year, setYear] = useState(moment().year())

    const onCalendarChange = date => {
        console.log('onCalendarChange', date, selectedDate)
    }

    const onCalendarPanelChange = (date, mode)=>{
        console.log('onCalendarPanelChange', date, mode, selectedDate)
        const newMonth = date.month() + 1
        const newYear = date.year()
        if(newMonth != month || newYear != year) {
            setMonth(newMonth)
            setYear(newYear)
            loadEvents(newMonth, newYear)
        }
    }

    const onTagUpdate = field => {
        loadEvents(month, year);
        onUpdate && onUpdate()
    }

    const getEvents = date => {
        return events.filter(event => date.isBetween(moment(event.date_start).startOf('day'), moment(event.date_end).endOf('day')))
    }

    const getContent = date => {
        return getEvents(date).map(tag => <EditableTag tag={tag} editable={tag.editable} filter hideIcon key={tag.id} onUpdate={onTagUpdate}/>)
    }

    const dateFullCellRender = date => {
        return <DateCell date={date} getContent={getContent} selectedDate={selectedDate}/>
    }

    const dateCellRender = date => {
        return getContent(date)
    }

    return (<>
        <Row>
            <Col span={24}>
                <Button icon={<PlusOutlined/>} onClick={clickCreate} type={'primary'} ghost block={mini}>{t('button-create-event','Create Event')}</Button>
                <Formik
                    initialValues={{date_start: selectedDate}}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                        values.sub_type = 'event'

                        api.post(`/api/tags`, {tag: values}).then(res => {
                            actions.resetForm()
                            actions.setSubmitting(false)
                            message.success(t('message-event-created!','Event Created!'))
                            setCreateModalVisible(false)
                            loadEvents(month,year)
                        }).catch(err => {
                            actions.setSubmitting(false)
                            message.error(JSON.stringify(err.data))
                        })
                    }}
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required(''), // TODO: check uniqueness
                            date_start: Yup.date().required(''), // TODO: check uniqueness
                        })
                    }
                >
                    {({values, submitForm, handleSubmit, setFieldValue, isSubmitting}) => {
                        const onKeyDownCapture = (e) => {
                            if(e.keyCode == 13) {
                                e.stopPropagation();
                                e.preventDefault();
                                submitForm();
                            }
                        }
                        return (
                            (<Modal
                                width={isMobile() ? '100%' : '33%'}
                                open={createModalVisible}
                                onCancel={()=> setCreateModalVisible(false)}
                                title={<><CalendarOutlined/> {t('new-event','New Event')}</>}
                                onOk={submitForm}
                                footer={
                                    <Space direction={'horizontal'}>
                                        <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                            <SaveOutlined/>
                                            {t('button-create','Create')}
                                        </Button>
                                        <Button onClick={() => setCreateModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                                    </Space>
                                }
                            >
                                <Form
                                    onSubmit={handleSubmit}
                                    onKeyDownCapture={onKeyDownCapture}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <DatePicker.RangePicker
                                        onChange={dates => {
                                            setFieldValue('date_start', dates[0])
                                            setFieldValue('date_end', dates[1])
                                        }}
                                        required
                                        name={'dates'}
                                    />

                                    <FormItem required name='name' showValidateSuccess style={{marginTop:'1em'}}>
                                        <FloatLabel label={t('name','Name')} name={'name'} value={values?.name}>
                                            <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                        </FloatLabel>
                                    </FormItem>

                                    <FormItem name='description' style={{marginTop:'.5em'}}>
                                        <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('placeholder-event-description','Describe the Event. Optional.')}>
                                            <Input.TextArea rows={2} name='description'/>
                                        </FloatLabel>
                                    </FormItem>

                                    <FormItem name='featured_organization_name' style={{marginTop:'.5em'}}>
                                        <FloatLabel label={t('featured-organization-name','Featured Organization Name')} name={'featured_organization_name'} value={values.featured_organization_name} description={t('placehlder-optional','Optional')}>
                                            <Input size={'large'} name='featured_organization_name' autoComplete='off'/>
                                        </FloatLabel>
                                    </FormItem>

                                    <Divider>{t('location','Location')}</Divider>

                                    <FormItem name='location_country_code' label={t('country-code','Country Code')}>
                                        <Input name='location_country_code' autoComplete='off'/>
                                    </FormItem>

                                    <FormItem name='location_country' label={t('country','Country')}>
                                        <Input name='location_country' autoComplete='off'/>
                                    </FormItem>

                                    <FormItem name='location_state' label={t('state','State')}>
                                        <Input name='location_state' autoComplete='off'/>
                                    </FormItem>

                                    <FormItem name='location_city' label={t('city','City')}>
                                        <Input name='location_city' autoComplete='off'/>
                                    </FormItem>

                                    <FormItem name='location_name' label={t('location-name','Location Name')}>
                                        <Input name='location_name' autoComplete='off'/>
                                    </FormItem>

                                    <Divider>{t('related','Related')}</Divider>

                                    <FormItem name='related_tag_names' label={t('related-tags','Related Tags')}>
                                        <Select name={'related_tag_names'} mode='tags' style={{width:'100%'}} />
                                    </FormItem>

                                    {/*<Links what={{editable:true}} type={'Tag'}/>*/}
                                </Form>
                            </Modal>)
                        );
                    }}
                </Formik>
            </Col>
        </Row>
        <Calendar
            dateCellRender={dateCellRender}
            dateFullCellRender={mini && dateFullCellRender}
            onSelect={onSelect}
            value={selectedDate}
            onChange={onCalendarChange}
            onPanelChange={onCalendarPanelChange}
            fullscreen={!mini}
        />
        {mini && (
            <>
                <Divider style={{margin: '0.5em 0'}}>{selectedDate?.format('YYYY-MM-DD')} {t('events','Events')}</Divider>
                {!getEvents(selectedDate).length && <em>{t('none-yet','None yet.')}</em>}
                {getContent(selectedDate)}
            </>
        )}
    </>);
}

const DateCell = ({date, selectedDate, getContent})=>{
    const [miniVisible, setMiniVisible] = useState()

    const clickDate = ()=>{
        console.log('clickDate')
        setMiniVisible(!miniVisible)
    }

    const content = getContent(date)

    const selected = date.isSame(selectedDate, 'day')

    const clickClose = e => {
        e.stopPropagation()
        setMiniVisible(false)
    }

    if(content.length) {
        return (
            <>
                {/* TODO: dropdown tab nav? */}
                <Popover
                    title={
                        <>
                            Events
                            <div style={{float:'right'}}>
                                <Button icon={<CloseOutlined/>} onClick={clickClose} type={'text'} size={'small'}/>
                            </div>
                        </>
                    }
                    content={content}
                    visible={miniVisible}
                >
                    <Tag color={'success'} onClick={clickDate}><Typography.Text underline={selected} tabIndex={0}>{date.date()}</Typography.Text></Tag>
                </Popover>
            </>
        )
    } else if(selected) {
        return <Typography.Text underline onClick={clickDate}>{date.date()}</Typography.Text>

    } else if(moment().isSame(date,'day')) {
        return <Tag onClick={clickDate} tabIndex={0}>{date.date()}</Tag>

    } else {
        return <span onClick={clickDate} tabIndex={0}>{date.date()}</span>
    }
}