import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom-v5-compat";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    ArrowRightOutlined,
    DeleteOutlined, DownloadOutlined, SettingOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import {MetaImportSetup} from "./MetaImports";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import useShowAsset from "../helpers/useShowAsset";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newMetaImport, q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/meta_imports'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-meta-import-canceled.','Meta Import Canceled.'));
            handleTableChange();
        });
    }

    const Assets = ({metaImport}) => {
        const [visible, setVisible] = useState()
        return (<>
            <Button type={'link'} onClick={()=> setVisible(true)}>{metaImport.assets_count}</Button>
            <Modal
                title={<>{t('meta-import-assets','Meta Import Assets')}: {metaImport.filename}</>}
                open={visible}
                footer={null}
                onCancel={()=> setVisible(false)}
                overlayStyle={{width:'66vw'}}
                width={'66vw'}
            >
                <MetaImportAssetsTable metaImport={metaImport}/>
            </Modal>
        </>);
    }

    const Actions = ({metaImport})=>{
        const [visible, setVisible] = useState()

        return (<>
            <Space size={5}>
                <Can I={'edit'} a={'MetaImport'}>
                    <Space direction={'horizontal'}>
                        {metaImport.aasm_state == 'analyzed' && (
                            <>
                                <Button icon={<SettingOutlined/>} onClick={()=> setVisible(true)}>{t('button-process','Process')}... </Button>
                                <Modal
                                    title={t('tooltip-process-meta-import','Process Meta Import')}
                                    open={visible}
                                    onCancel={()=> setVisible(false)}
                                    footer={null}
                                >
                                    <MetaImportSetup
                                        metaImport={metaImport}
                                        onDone={()=> {
                                            loadTableData()
                                            setVisible(false);
                                        }}
                                    />
                                </Modal>
                            </>
                        )}

                        {(metaImport.aasm_state != 'processing' && metaImport.aasm_state != 'processed') && (
                            <Popconfirm
                                title={t('confirm-cancel-meta-import','Cancel Meta Import?')}
                                onConfirm={() => destroy(metaImport.id)}
                            >
                                <Button size={'middle'}>
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>
                        )}
                    </Space>
                </Can>
            </Space>
        </>);
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            render: date => <TimeAgo date={date}/>
        },
        {
            title: t('user','User'),
            render: (m) => {
                return <User user={m.user}/>
            }
        },
        {
            title: t('filename','Filename'),
            dataIndex: 'filename',
            sorter: true,
            render: (f, m)=> (
                <>
                    {f}
                    <a style={{marginLeft:'1em'}} href={m.download_url} download={m.filename} target={'_blank'}><DownloadOutlined/></a>
                </>
            )
        },
        {
            title: t('note','Note'),
            dataIndex: 'note',
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            // filters: [
            //     {text: 'Analyzed', value: 'analyzed'},
            //     {text: 'Processed', value: 'processed'},
            // ],
            // filterMultiple: true,
            render: (state) => (
                <Tag>{t(state, state.toProperCase())}</Tag>
            )
        },
        {
            title: t('assets','Assets'),
            render: (metaImport) => <Assets metaImport={metaImport}/>
        },
        {
            key: 'action',
            width: 100,
            render: (metaImport) => <Actions metaImport={metaImport}/>
        }
    ];

    const params = useRef({})

    const handleTableChange = (pagination, filters, sorter) => {
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newMetaImport, q]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}


const MetaImportAssetsTable = ({metaImport})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = `/api/meta_imports/${metaImport.id}/assets`
    const showAsset = useShowAsset()

    const click = mia => {
        showAsset(mia.asset_id)
    }

    const columns = [
        {
            title: t('thumb','Thumb'),
            render: mia => {
                return (
                    <a onClick={()=> click(mia)}>
                        <AssetBrowseImage asset={mia.asset} assetSize={40} thumbType={'thumb'} padding={'0.5em'}/>
                    </a>
                );
            }
        },
        {
            title: t('filename','Filename'),
            dataIndex: 'filename',
            sorter: true,
            render: (filename, mia) => {
                return (
                    <>
                        <Button type={'link'} onClick={()=> click(mia)}>{filename}</Button>
                    </>
                );
            }
        },
        {
            title: 'GUID',
            render: mia => (
                <Typography.Text copyable={{text: mia.guid}} type={'code'}>{mia.guid.slice(0,8)}</Typography.Text>
            )
        },
        {
            title: t('meta-mapped-updates','Meta Mapped Updates'),
            render: mia => {
                return (
                    <>
                        {metaImport.columns.map(col => {
                            if(!col.mapping || !mia.meta[col.name]) return;

                            const metaName = MetaFields[col.mapping] ? MetaFields[col.mapping].name : _.find(customMetaFields, {id: parseInt(col.custom_meta_field_id)})?.name

                            return (
                                <div key={col.name}><strong>{col.name} <ArrowRightOutlined/> {metaName}:</strong> "{mia.meta[col.name]}"</div>
                            )
                        })}
                    </>
                );
            }
        },
    ];

    const params = useRef({})

    const handleTableChange = (pagination, filters, sorter) => {
        params.current = {
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadTableData()
    }, [metaImport.id])

    const [fieldsLoading, setFieldsLoading] = useState(true)
    const [customMetaFields, setCustomMetaFields] = useState([])

    useEffect(()=>{
        setFieldsLoading(true)
        api(`/api/custom_meta_fields`).then(res => {
            setFieldsLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [currentOrg?.id])

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}