import React, {useState} from "react";

const FloatLabel = ({ children, label, value, description, style }) => {
    const [focus, setFocus] = useState(false);

    const floated = focus || (value && value.length !== 0);
    const labelClass = floated ? "label label-float" : "label";

    let text = floated ? label : label;
    if(description) text += ` (${description})`

    return (
        <div
            className={`float-label ${focus && 'focused'}`}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            style={style}
        >
            {children}
            <label className={labelClass}>{text}</label>
        </div>
    );
};

export default FloatLabel;
