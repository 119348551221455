import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    spideredLinks: []
}

const LinksStateContext = createContext(initialState);
const LinksDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    // console.log('LinkDispatch', action)

    switch (action.type) {
        case 'spidered':
            return {...state, spideredLinks: state.spideredLinks.concat(action.id)};

        default:
            return state
    }
}

const LinksProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <LinksStateContext.Provider value={state}>
            <LinksDispatchContext.Provider value={dispatch}>
                {children}
            </LinksDispatchContext.Provider>
        </LinksStateContext.Provider>
    )
}

function useLinksState() {
    const context = useContext(LinksStateContext)
    if (context === undefined) {
        throw new Error('useLinksState must be used within a Provider')
    }
    return context
}

function useLinksDispatch() {
    const context = useContext(LinksDispatchContext)
    if (context === undefined) {
        throw new Error('useLinksDispatch must be used within a Provider')
    }
    return context
}

export {
    LinksProvider,
    useLinksState,
    useLinksDispatch
};
