import React, {useEffect, useState} from "react";

import {FormItem, Input, Checkbox, Select} from "formik-antd";
import {Row, Col, Card, Badge, Collapse, Divider, Typography, Tooltip} from "antd";
const {Panel} = Collapse;

import EnabledDisabled from "../helpers/EnabledDisabled";

import {useFormikContext, FieldArray} from 'formik';
import TagSuggesterForm from "./TagSuggesterForm";
import RightsTree from "./RightsTree";
import HelpPopover from "../HelpPopover";
import {RightsIcon, WorkflowIcon} from "../helpers/icons";
import TagsOutlined from "@ant-design/icons/lib/icons/TagsOutlined";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {NotificationOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import VerticalSpace from "../helpers/VerticalSpace";
import UserChooser from "./UserChooser";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";

export default ({fieldName, disabled=false, includeWorkflowPanel=false, noFeatured, onSandboxChange}) => {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const {values} = useFormikContext()

    const getInputName = (name)=>{
        return fieldName ? `${fieldName}.${name}` : name
    }

    const getValue = (name)=> {
        if(!values) return;
        return fieldName ? values[fieldName] && values[fieldName][name] : values[name]
    }

    const [rightsPackageOptions, setRightsPackageOptions] = useState([]);

    useEffect(()=>{
        api('/api/rights_packages?tree=true').then(res => {
            setRightsPackageOptions(res.data)
        })
    }, [!rightsPackageOptions.length])

    const linkPrepend = `https://${window.location.hostname}/${customDomainOrganizationSlug ? '' : `${currentOrg.slug}/`}contribute/`
    const linkUrl = `${linkPrepend}${values[fieldName] ? values[fieldName].slug : values.slug}`

    const quickLinkPrepend = `https://${window.location.hostname}/${customDomainOrganizationSlug ? '' : `${currentOrg.slug}/`}quick-upload/`
    const quickLink = `${quickLinkPrepend}${values[fieldName] ? values[fieldName].slug : values.slug}`

    return (
        <Collapse defaultActiveKey={[]}>
            <Panel key={'basic'} disabled={disabled} header={<><SettingOutlined/> {t('contribution-request-settings','Contribution Request Settings')}</>} id={'contribution-settings-panel'}>
                <VerticalSpace>
                    <Input name={getInputName('id')} style={{display:'none'}}/>

                    <FormItem name={getInputName('text')} label={t('write-a-request',"Write a request")}>
                        <Input.TextArea rows={2} placeholder={t('placeholder-contribution-request-text','Tell Group Members what you want them to contribute.')} name={getInputName('text')}/>
                    </FormItem>

                    {!noFeatured && (
                        <FormItem name={getInputName('featured')}>
                            <Checkbox name={getInputName('featured')}>{t('feature-on-the-upload-page-for-all-group-members','Feature on the Upload Page for all Group Members')}</Checkbox>
                        </FormItem>
                    )}

                    <FormItem name={getInputName('slug')} label={t('direct-contribution-link',"Direct Contribution Link")}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Input addonBefore={linkPrepend} name={getInputName('slug')} style={{flex:'auto'}}/>
                            <Typography.Text copyable={{tooltips: t('tooltip-copy-url','Copy URL'), text: linkUrl}} style={{flex:'none', marginLeft:'.5em'}}/>
                        </div>
                    </FormItem>

                    <FormItem name={getInputName('enable_quick_upload')}>
                        <Tooltip title={!getValue('slug')?.length && t('slug-must-be-set','Slug must be set first.')}>
                            <Checkbox name={getInputName('enable_quick_upload')} disabled={!getValue('slug')?.length}>{t('enable-quick-upload',"Enable Quick Upload")} <HelpPopover code={'quick-upload'}/></Checkbox>
                        </Tooltip>
                    </FormItem>

                    {getValue('enable_quick_upload') && (
                        <>
                            <QRCode
                                value={quickLink}
                                icon={ImageUrls.blackCircleLogo}
                                // errorLevel={'H'}
                            />
                            <Typography.Text copyable={{tooltips: t('tooltip-copy-url','Copy URL'), text: quickLink}} style={{flex:'none', marginLeft:'.5em'}}>{quickLink}</Typography.Text>
                        </>
                    )}

                </VerticalSpace>
            </Panel>

            <Panel key={'tagging'} header={<><TagsOutlined/> {t('tagging','Tagging')}</>} disabled={disabled} id={'contribution-tagging-panel'}>
                {/*<Checkbox name={'contribution_attributes.enable_comprehensive_tagging'}>Enable Comprehensive tagging on computer-based uploads</Checkbox>*/}

                <FormItem name={getInputName('tag_names')} label={t('add-these-tags-to-all-group-uploads-',"Add these tags to all Group uploads")} style={{margin:'1em 0'}}>
                    <FieldArray
                        name={getInputName('tag_names')}
                        render={() => (
                            <Select name={getInputName('tag_names')} mode='tags' placeholder={t('placeholder-enter-tags','Enter Tags')}>
                                {getValue('tag_names')?.map((name) => (
                                    <Select.Option value={name} key={name}>{name}</Select.Option>
                                ))}
                            </Select>
                        )}/>
                </FormItem>
                <br/>

                <Checkbox name={getInputName('require_creator_tag')}>{t('require-creator-tag','Require Creator Tag')}</Checkbox>
                {/*<Checkbox name={getInputName('request_people_tags')}>Request People Tags (coming soon)</Checkbox>*/}
                {/*<br/>*/}
                {/*<Checkbox name={getInputName('create_place_name_tags')}>Create place name tags from GPS when present (coming soon)</Checkbox>*/}
                <Divider/>
                <Checkbox name={getInputName('enable_tag_suggester')}>{t('enable-tag-suggesters','Enable Tag Suggesters')}</Checkbox>

                {getValue('enable_tag_suggester') && (
                    <div style={{marginTop:'1em'}}>
                        <TagSuggesterForm fieldName={fieldName}/>
                    </div>
                )}

            </Panel>

            <Panel
                key={'rights'}
                header={
                    <>
                        <RightsIcon/> {t('rights-and-releases','Rights and Releases')}
                        <Badge count={getValue('rights_package_ids')?.length} style={{marginLeft:'1em', backgroundColor:'#bbb'}}/>
                    </>
                }
                disabled={disabled}
                id={'contribution-rights-panel'}
            >
                <Row gutter={[16,16]}>
                    <Col lg={12} xs={24}>
                        <Card size={'small'} title={<><RightsIcon/> {t('allowable-rights-packages','Allowable Rights Packages')}</>}>
                            <RightsTree opts={rightsPackageOptions} fieldName={fieldName} isNew={!values?.id}/>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Checkbox name={getInputName('require_rights_package')}>{t('require-selection','Require Selection')}</Checkbox>
                    </Col>
                </Row>
            </Panel>

            {includeWorkflowPanel && (
                <Panel id={'contribution-workflow-panel'} key={'auto'} disabled={disabled} header={
                    <>
                        <WorkflowIcon/> {t('sandbox-workflow','Sandbox Workflow')}
                        <HelpPopover code='contribution-form-auto-approve'/>
                    </>
                }>
                    <Checkbox name={'auto_approve'} onChange={onSandboxChange || (()=>true)}>{t('add-all-files','Add all files')}</Checkbox>
                </Panel>
            )}

            <Panel
                header={<><UsergroupAddOutlined/> {t('invite-users','Invite Users')}</>}
                key='memberships'
                disabled={disabled}
            >
                <UserChooser />
            </Panel>

            <Panel
                key={'notifications'}
                header={
                    <>
                        <NotificationOutlined/> {t('notifications','Notifications')}
                        <Badge count={getValue('notify_emails')?.length} style={{marginLeft:'1em', backgroundColor:'#bbb'}}/>
                    </>
                }
                disabled={disabled}
                id={'contribution-notifications-panel'}
            >
                <FormItem name={getInputName('notify_emails')} label={t('notify-emails-label',"Notify Emails when upload starts and new Assets Are submitted")} style={{margin:'1em 0'}}>
                    <FieldArray
                        name={getInputName('notify_emails')}
                        render={() => (
                            <Select name={getInputName('notify_emails')} mode='tags' placeholder={t('placeholder-enter-emails','Enter Emails...')}>
                                {getValue('notify_emails')?.map((email) => (
                                    <Select.Option value={email} key={email}>{email}</Select.Option>
                                ))}
                            </Select>
                        )}/>
                </FormItem>

                <FormItem name={getInputName('notify_copy')} label={t('notify-copy-label',"Text to add to Email notification")} style={{margin:'1em 0'}}>
                    <Input.TextArea rows={2} placeholder={t('placeholder-notify-copy','This text will be added to the Email')} name={getInputName('notify_copy')}/>
                </FormItem>
            </Panel>
        </Collapse>
    )
}