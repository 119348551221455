import {useAssetsDispatch} from "../../contexts/AssetsContext";
import React, {useEffect, useState} from "react";
import api from "../api";
import {Button, message, Tooltip} from "antd";
import {ThumbsDownIcon, ThumbsUpIcon} from "../helpers/icons";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";

export default ({asset, style={}})=>{
    const {t} = useTranslation();
    const currentUser = useCurrentUser()

    const assetsDispatch = useAssetsDispatch();

    const [vote, setVote] = useState(asset.vote)

    useEffect(()=>{
        setVote(asset.vote)
    }, [asset.id, asset.vote?.value])

    const updateVote = value => {
        setVote({value})

        api.post(`/api/assets/${asset.id}/vote`, {value}).then(res => {
            setVote(res.data)
            assetsDispatch({type:'updateAsset', asset: {...asset, vote}})
            message.success(value === 1 ? t('liked-ex', 'Liked!') : t('disliked-ex','Disliked!'))
            // TODO: update Filter counts
        })
    }

    const upVote = ()=> updateVote(1)
    const downVote = ()=> updateVote(-1)

    const getColor = value => {
        if(vote?.value == value) {
            return 'red'
        } else {
            return 'grey'
        }
    }

    if(!currentUser) return <></>

    return (
        <span style={style} onClick={e => e.stopPropagation()} className={'asset-vote-buttons'}>
            <Tooltip title={t('like','Like')}>
                <Button type='text' shape='circle' size='small' onClick={upVote} style={{color: getColor(1)}} tabIndex={0} aria-label={t('like-button', 'Like Button')}><ThumbsUpIcon/></Button>
            </Tooltip>

            <Tooltip title={t('dislike','Dislike')}>
                <Button type='text' shape='circle' size='small' onClick={downVote} style={{color: getColor(-1)}} tabIndex={0} aria-label={t('dislike-button', 'Dislike Button')}><ThumbsDownIcon/></Button>
            </Tooltip>
        </span>
    )
}

