import React, {useContext, useEffect, useRef, useState} from "react";

import { SessionContext } from '../../contexts/SessionContext'
import { useStorageState } from 'react-storage-hooks';
import { useDrop } from 'react-dnd';

import {
    Tree,
    Tag,
    Card,
    Tooltip,
    Popover,
    Button,
    message,
    Typography,
    Modal,
    Space,
    Descriptions,
    Popconfirm,
    Input as AntInput,
    Skeleton,
    Menu,
    Upload,
    Checkbox,
    Switch,
    Divider,
    DatePicker as AntDatePicker,
    Radio as AntRadio,
    notification, Avatar, Alert
} from 'antd';
const { Text } = Typography;

import {
    TagOutlined,
    PlusOutlined,
    BlockOutlined,
    AppstoreOutlined,
    UserOutlined,
    EditOutlined,
    EditFilled,
    FolderOpenOutlined,
    FolderOutlined,
    FolderOpenFilled,
    TagsFilled,
    TagsOutlined,
    SearchOutlined,
    InfoCircleOutlined,
    SaveOutlined,
    DeleteOutlined,
    ApartmentOutlined,
    CheckOutlined,
    UploadOutlined,
    FileAddFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    FilterOutlined, LoadingOutlined, CalendarOutlined, ShoppingCartOutlined, DownloadOutlined
} from '@ant-design/icons';
import api from "../api";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormItem, Input, Switch as FormikSwitch, Radio, DatePicker} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";

import { useAbility } from '@casl/react';
import {Can, AbilityContext} from "../helpers/Can";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import {useFilters} from "../helpers/useFilters";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {useBulkJobsDispatch} from "../../contexts/BulkJobsContext";
import BulkJobProgress from "../explore/BulkJobProgress";
import HelpPopover from "../HelpPopover";
import Links from "./Links";
import {useNavigate} from "react-router-dom-v5-compat";
import {EventTagDescriptions, MergeTagButton} from "./TagSelect";
import moment from "moment";
import day from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
day.extend(utc)
day.extend(timezone)
import TagSynonymManager from "./TagSynonymManager";
import useCurrentOrg from "../helpers/useCurrentOrg";
import useConsumer from "../../channels/consumer";
import {AssetGroupBulkJobProgress} from "../manage/AssetGroupChooser";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {AssetMention} from "./CommentThread";
import {useTranslation} from "react-i18next";
import {getLocale} from "./DateRangePicker";
import {TaxonomyForm} from "~/components/explore/ExploreTaxonomies";
import {UserGroupIcon} from "~/components/helpers/icons";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import {AppContext} from "@/contexts/AppContext";

const TreeContext = React.createContext(null);

export default ({ taxonomy, reset, ...props }) => {
    const {t} = useTranslation();

    const ability = useAbility(AbilityContext);

    const [attrs, setAttrs] = useState(taxonomy)

    const taxonomyName = attrs.name;
    const taxonomyId = attrs.id;
    const initialData = attrs.taxonomy_tag_roots;

    const useStateWithLocalStorage = (localStorageKey, initial) => {
        return useStorageState(sessionStorage, `${localStorageKey}-${taxonomyId}`, initial);
    };

    const {selectedAssetAggs} = useSelectedAggsState();

    const {dispatch} = useContext(SessionContext);

    const assetsDispatch = useAssetsDispatch()
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    // ------------------------------------------------------------------------------------------------------
    // Mode
    // ------------------------------------------------------------------------------------------------------

    // start in Edit mode for empty Taxonomy:
    const [mode, setModeState] = useStateWithLocalStorage('taxonomyTreeMode',
        initialData.length ? null : 'Edit');

    const editMode = mode === 'Edit' && (taxonomy.editable || ability.can('manage', 'Taxonomy'));
    const tagMode = mode === 'Tag' && (taxonomy.editable || ability.can('create', 'Tag'));
    const viewMode = !editMode && !tagMode;

    const setMode = (newMode) => {
        if (newMode !== '') message.info(`${taxonomyName} ${t(`message.taxonomy-${mode}-activated`, `${newMode} Mode Activated.`)}`);
        setModeState(newMode);
    };

    const [intersectionMode, setIntersectionMode] = useStateWithLocalStorage(`taxonomyTreeIntersectionMode-${taxonomy.id}`, true);

    const toggleIntersectionMode = ()=>{
        if(intersectionMode) {
            setIntersectionMode(false)
            dispatch({type:'setTaxonomyFilterMode', id: taxonomy.id, mode: 'union'})
        } else {
            setIntersectionMode(true);
            dispatch({type:'setTaxonomyFilterMode', id: taxonomy.id, mode: 'intersection'})
        }
    }

    // ------------------------------------------------------------------------------------------------------
    // Expand / Collapse
    // ------------------------------------------------------------------------------------------------------

    const [expandedKeys, setExpandedKeys] = useStateWithLocalStorage('expandedKeys', []);
    const [searchExpandedKeys, setSearchExpandedKeys] = useState([]);

    const expand = (keys, {expanded, node})=> {
        keys = keys || (searching ? searchExpandedKeys : expandedKeys);
        const func = searching ? setSearchExpandedKeys : setExpandedKeys;
        func(_.uniq(expanded ? (keys || []).concat(node.name) : _.without((keys || []), node.name)));
    }

    const expandAll = () => {
        setExpandedKeys([...keys]);
    };

    const collapseAll = () => {
        setExpandedKeys([]);
    };

    // ------------------------------------------------------------------------------------------------------
    // Selection
    // ------------------------------------------------------------------------------------------------------

    const {currentTag} = useAssetGroupState()
    const [selected, setSelected] = useState([currentTag?.id]);

    useEffect(()=>{
        if(!currentTag) {
            setSelected([])
            return
        }
        if(selected[0] === currentTag.id)  return

        setSelected([currentTag.id])
    }, [currentTag?.id])

    const assetGroupDispatch = useAssetGroupDispatch()
    const getPath = useOrgPath()
    const navigate = useNavigate()

    const onSelect = (selectedKeys, info) => {
        console.log('onSelect',info)
        setSelected(selectedKeys);
        assetGroupDispatch({type:'setCurrentTag', tag: info.node})
        navigate(getPath(`/explore/tags/${info.node.id}-${info.node.name.replace(' ', '-')}`));
    };

    // ------------------------------------------------------------------------------------------------------
    // Check/Uncheck
    // ------------------------------------------------------------------------------------------------------
    const {selectedAssetIds, dragging} = useSelectedAssetsState()

    const [tagModeCheckedKeys, setTagModeCheckedKeys] = useState({});

    const [loadingNodeId, setLoadingNodeId] = useState();

    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const onBulkAddFinish = bj =>{
        console.log('onBulkAddFinish', bj)

        message.destroy(`bulkJob-${bj.guid}`)
        message.success(t('message-done','Done'))

        refreshCounts(()=> {
            setLoadingNodeId(null)

            assetsDispatch({type:'reload'});
            selectedAssetsDispatch({type:'reload'});
        })
    }

    const onCheck = (newCheckedKeys, {checked, node}) => {
        console.log('onCheck')
        if(!tagMode) return message.error(t('error-must-be-in-tag-mode','Must be in tag mode.'));

        const n = selectedAssetIds.length;

        if(n == 0) return message.error(t('error-must-select-assets-first','Please select some assets first.'));

        setLoadingNodeId(node.id)

        if(checked) {
            tagModeCheckedKeys.checked.push(node.key);

        } else {
            tagModeCheckedKeys.checked.splice(tagModeCheckedKeys.checked.indexOf(node.key), 1);
            tagModeCheckedKeys.halfChecked.splice(tagModeCheckedKeys.halfChecked.indexOf(node.key), 1);
        }

        const data = {
            bulk_job: {
                asset_ids: selectedAssetIds,
                tag_names: [node.name],
                tag_mode: checked ? 'add' : 'remove',
            }
        }

        api.post(`/api/bulk_jobs`, data).then(res => {
            // Create BulkJob and listen:
            message.info({content: t('message-starting-bulk-job','Starting Bulk Job...'), key:`bulkJob-${res.data.guid}`})

            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})

        }).catch((err)=>{
            console.log(err)
        })

        setTagModeCheckedKeys({...tagModeCheckedKeys});
    };

    useEffect(()=>{
        const keys = {checked:[], halfChecked:[]};

        selectedAssetAggs?.taxonomy_paths?.buckets.forEach(agg => {
            const id = parseInt(agg.key.split('/').pop())
            const key = tagsById[id]

            if(agg.doc_count === selectedAssetIds.length) {
                keys.checked = [...keys.checked, key];
            } else if(agg.doc_count) {
                keys.halfChecked = [...keys.halfChecked, key];
            }
        })

        setTagModeCheckedKeys(keys)

    }, [selectedAssetAggs])

    // ------------------------------------------------------------------------------------------------------
    // Filters
    // ------------------------------------------------------------------------------------------------------

    const {filters, setFilters, clearFilters} = useFilters()

    const toggleFilter = ({ancestor_ids, id}) => {
        const path = (ancestorHash[id] || ancestor_ids).concat(id).join('/');
        const applied = filters.taxonomy.indexOf(path) != -1;
        setFilters(applied, {taxonomy: path})
    };

    const toggleHideFilter = ({ancestor_ids, id}) => {
        const path = (ancestorHash[id] || ancestor_ids).concat(id).join('/');
        const applied = filters.hide_taxonomy.indexOf(path) != -1;
        setFilters(applied, {hide_taxonomy: path})
    };

    let keys;

    // ------------------------------------------------------------------------------------------------------
    // Search Tags
    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState()

    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const searchValue = useRef();

    const apiSearch = _.debounce((value)=>{
        searchValue.current = value

        api(`/api/taxonomies/${taxonomyId}/taxonomy_tags`, { params: { q: value } }).then(res => {
            if(searchValue.current != value) return;

            setLoading(false);
            setSearchResults(res.data);
        });
    }, 250);

    const search = (e)=> {
        const { value } = e.target;

        if(value && value != '') {
            setSearching(true);
            setLoading(true);
            apiSearch(value)
        } else {
            setSearching(false);
        }
    };

    // ------------------------------------------------------------------------------------------------------
    // Data Handling
    // ------------------------------------------------------------------------------------------------------

    const [rawTreeData, setRawTreeData] = useState([]);

    const [ancestorHash, setAncestorHash] = useState({})

    const updateTreeData = (list, id, children) => {
        if(!id) return children;

        return list.map(node => {
            if (node.id === id) {
                return { ...node, children };
            }
            if (node.children) {
                return { ...node, children: updateTreeData(node.children, id, children) };
            }

            return node;
        });
    }

    const updateTaxonomyTag = (id, data, cb, errCb) => {
        api.put(`/api/taxonomies/${taxonomyId}/taxonomy_tags/${id}`, { taxonomy_tag: data })
            .then(cb)
            .catch(err => {
                console.log(err)
                message.error(JSON.stringify(err.data))
                errCb && errCb()
            })
    }

    const updateTreeDataFromRaw = (data, key=null)=> {
        setLoading(false);
        if(searching) {
            setSearchResults(origin => updateTreeData(origin, key, data));
        } else {
            setRawTreeData(origin => updateTreeData(origin, key, data));
        }
    }

    const setupInitial = ()=> {
        updateTreeDataFromRaw(initialData);
        setExpandedKeys([...expandedKeys]);
    }

    useEffect(()=>{
        setupInitial()
    },[initialData])

    // --- Extract:
    const loadTreeData = ({id, has_children}, cb) => {
        return new Promise(resolve => {
            if (!has_children) {
                resolve();
                return;
            }

            setLoading(true);
            api(`/api/taxonomies/${taxonomyId}/taxonomy_tags/tree`, { params: { parent_id: id} }).then(res => {
                // console.log('updateTreeDataFromRaw', res.data, id)
                updateTreeDataFromRaw(res.data, id)

                res.data.map(tt => setAncestorHash(ancestorHash => {
                    ancestorHash[tt.id] = tt.ancestor_ids
                    return ancestorHash
                }))

                cb && cb();
                resolve();
            })
        });
    }

    const getTreeData = (data) => {
        keys = [];

        const walk = (datum, children) => {
            let newNode = getNodeFromDatum(datum);
            if(newNode) {
                children.push(newNode);
                datum.children?.forEach(child => walk(child, newNode.children));
            }
        };

        let tree = [];
        data.forEach(datum => walk(datum, tree));
        return tree;
    };

    const tagsById = {}

    const getNodeFromDatum = (taxTag) => {
        const {id, name, has_children, organizer, slug} = taxTag;
        keys.push(name);

        if(viewMode && !taxTag.visible_assets_count) return false;

        tagsById[id] = slug;

        return {
            ...taxTag,
            key: searching ? `search-${slug}` : slug, // to prevent Ant from caching `loaded` state on Node
            slug: slug,
            id: id,
            name: name,
            'aria-label': name,
            isLeaf: !has_children,
            title: <NodeTitle taxTag={taxTag}/>,
            checkable: !organizer,
            disableCheckbox: !tagMode || organizer,
            children: []
        };
    }

    // ----------------------
    // Index refresh Listener
    // ----------------------

    const consumer = useConsumer();
    const currentOrg = useCurrentOrg()
    const assetIndexSub = useRef();

    useEffect(()=>{
        if(!assetIndexSub.current) {
            assetIndexSub.current = consumer.subscriptions.create({channel: "AssetIndexChannel", organization_id: currentOrg?.id}, {
                received: (data) => {
                    console.log('AssetIndexChannel received', data)
                    if(data.klass === 'Tag') {
                        refreshCounts()
                    }
                }
            });
        }

        return ()=> {
            assetIndexSub.current?.unsubscribe()
        }
    }, [currentOrg?.id])

    const [refreshingCounts, setRefreshingCounts] = useState()

    const refreshCounts = cb =>{
        console.log('TaxonomyTree refreshCounts')
        const ids = []
        const traverse = (nodes) => {
            nodes?.map(node => {
                // if expanded:
                ids.push(node.id)
                traverse(node.children)
            })
        }

        // Get correct state:
        setRawTreeData(rawTreeData => {
            traverse(rawTreeData)
            return rawTreeData
        })

        setTimeout(()=> {
            api.post(`/api/taxonomies/${taxonomyId}/taxonomy_tags/visible_asset_counts`, {ids}).then(res => {
                setRefreshingCounts(false)

                setRawTreeData(rawTreeData => {
                    const findNode = (tag) => {
                        const traverse = (nodes) => {
                            if(!nodes) return;

                            let found;
                            for(let node of nodes) {
                                if(tag.id === node.id) return node;

                                found = traverse(node.children)
                                if(found) return found;
                            }
                        }

                        return traverse(rawTreeData)
                    };

                    res.data.map(tag => {
                        const node = findNode(tag)
                        if(node) {
                            node.visible_assets_count = tag.visible_assets_count
                        }
                    })

                    return [...rawTreeData]
                })

                cb && cb()
            })
        }, 1000)
    }


    // --------------------------------------------------------------------------------
    // Drag/Drop
    // --------------------------------------------------------------------------------

    // --- Extract:
    const onDrop = (info) => {
        if(!info.dragNode) {
            return message.error(t('error-edit-mode-drag-drop-disabled','Currently in Edit Mode, Asset drag/drop disabled.'))
        }

        // only allow drop on another node, since alpha ordering
        if(info.dropToGap && info.node.parent_id === info.dragNode.parent_id) return;

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        let parent;
        // Finds node in data with id == key
        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.slug === key) {
                    return callback(item, index, arr, parent);
                }
                if (item.children) {
                    parent = item;
                    return loop(item.children, key, callback);
                }
            });
        };

        const newRawData = [...rawTreeData];

        // Find dragObject
        let dragObj;
        loop(newRawData, dragKey, (item, index, arr, parent) => {
            arr.splice(index, 1);
            dragObj = item;
            if(parent) parent.isLeaf = !arr.length;
        });

        if (!info.dropToGap) {
            // Drop on node to add as child
            loop(newRawData, dropKey, item => {
                item.children = item.children || [];
                // where to insert
                item.children.push(dragObj);
                item.has_children = true;
                dragObj.parent_id = item.id;
            });
        } else if (
            (info.node.children || []).length > 0 && // Has children
            info.node.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(newRawData, dropKey, item => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else {
            let ar;
            let i;
            let dropItem;
            loop(newRawData, dropKey, (item, index, arr) => {
                dropItem = item;
                ar = arr;
                i = index;
            });

            dragObj.parent_id = dropItem?.id;

            if (dropPosition === -1) {
                ar?.splice(i, 0, dragObj);
            } else {
                ar?.splice(i + 1, 0, dragObj);
            }
        }

        setRawTreeData(newRawData)

        const newParentId = info.dropToGap ? info.node.parent_id : info.node.id;

        setRefreshingCounts([info.node.id, info.dragNode.id])

        updateTaxonomyTag(info.dragNode.id, {parent_id: newParentId}, (res) => {
            setAncestorHash(ancestorHash => {
                ancestorHash[res.data.id] = res.data.ancestor_ids
                return ancestorHash
            })

            message.success(`${info.dragNode.name} ${t('moved', 'moved')}.`);

            assetsDispatch({type:'reload'});
            selectedAssetsDispatch({type:'reload'});
        })
    };

    const [archived, setArchived] = useState(false);

    const editable = taxonomy.editable || ability.can('manage', 'Taxonomy');

    const treeRef = useRef()

    const domId = `taxonomy-tree-${taxonomy.id}`;
    const [openInfoId, setOpenInfoId] = useState()

    const resetFocus = key => {
        setOpenInfoId(null)
        setTimeout(()=>{
            document.querySelector(`#${domId} input`).focus()
            const state = treeRef.current
            state.activeKey = key
            treeRef.current.setState({...state})
        }, 500)
    }

    const onKeyDown = e => {
        const id = treeRef.current.getActiveItem()?.id;
        if(e.key === 'i' && id) {
            setOpenInfoId(parseInt(id))
        }
    }

    if(archived) return '';

    return (
        <TreeContext.Provider
            value={{taxonomy, taxonomyId, editMode, updateTaxonomyTag, updateTreeDataFromRaw, loadTreeData,
                rawTreeData, setRawTreeData, toggleFilter, toggleHideFilter, clearFilters, filters, expand, setArchived, ability,
                refreshCounts, refreshingCounts, loadingNodeId, setMode, mode, attrs, setAttrs, editable, openInfoId, resetFocus
            }}
        >
            <Card {...props} size={'small'} className='vault-taxonomy-tree'
                  title={
                      <Space size={2}>
                          <TaxonomyTitle/>
                          <Tooltip title={t('tooltip-expand-all','Expand All')}>
                              <Button type={'text'} size={'small'} shape={'circle'} icon={<FolderOpenOutlined/>} onClick={expandAll}/>
                          </Tooltip>
                          <Tooltip title={t('tooltip-collapse-all','Collapse All')}>
                              <Button type={'text'} size={'small'} shape={'circle'} icon={<FolderOutlined/>} onClick={collapseAll}/>
                          </Tooltip>
                      </Space>
                  }
                  extra={
                      <Space size={2}>
                          <Tooltip title={intersectionMode ? t('tooltip.set-union-mode','Set Union Mode') : t('tooltip.set-intersection-mode','Set Intersection Mode') }>
                              <Button type={'text'} size={'small'} shape={'circle'} icon={intersectionMode ? <BlockOutlined/> : <AppstoreOutlined/>} onClick={toggleIntersectionMode}/>
                          </Tooltip>

                          {editable && (
                              <Tooltip title={tagMode ? t('tooltip.leave-tag-mode','Leave Tag Mode') : t('tooltip.enter-tag-mode','Enter Tag Mode')}>
                                  <Button type={'text'} size={'small'} shape={'circle'} icon={tagMode ? <TagsFilled/> : <TagsOutlined/>} onClick={()=> setMode(tagMode ? '' : 'Tag')}/>
                              </Tooltip>
                          )}

                          {editable && (
                              <span className='edit-btn'>
                                  <Tooltip title={editMode ? t('tooltip.leave-edit-mode','Leave Edit Mode') : t('tooltip.enter-edit-mode','Enter Edit Mode')}>
                                      <Button type={'text'} size={'small'} shape={'circle'} icon={editMode ? <EditFilled/> : <EditOutlined/>} onClick={()=> setMode(editMode ? '' : 'Edit')}/>
                                  </Tooltip>
                              </span>
                          )}
                      </Space>
                  }
            >
                <AntInput
                    style={{ marginBottom: 8 }}
                    placeholder={t('search', 'Search...')}
                    allowClear
                    onChange={search}
                    prefix={<SearchOutlined style={{opacity:0.5}}/>}
                />

                {editMode && selected.length > 1 && (
                    <Button type='primary' block style={{marginBottom:'1em'}}>
                        <BlockOutlined/>
                        {t('button-consolidate','Consolidate')}
                    </Button>
                )}
                {editMode && selected.length <= 1 && (
                    <AddTaxonomyTag organizer block style={{marginBottom:'1em'}} type={'primary'}/>
                )}
                <div id={domId}>
                    <Tree
                        ref={treeRef}
                        onKeyDown={onKeyDown}
                        checkedKeys={tagModeCheckedKeys}
                        onCheck={onCheck}
                        expandedKeys={searching ? searchExpandedKeys : expandedKeys}
                        onExpand={expand}
                        blockNode
                        checkStrictly={!editMode}
                        checkable={editMode || tagMode}
                        selectable
                        onSelect={onSelect}
                        selectedKeys={selected || []}
                        // multiple={editMode}
                        draggable={!dragging && editMode}
                        treeData={getTreeData(searching ? searchResults : rawTreeData)}
                        loadData={loadTreeData}
                        onDrop={onDrop}
                    />
                </div>

                {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onBulkAddFinish}/>}
            </Card>
        </TreeContext.Provider>
    )
}

const NodeTitle = ({taxTag}) => {
    const {editMode, taxonomy, updateTaxonomyTag, rawTreeData, setRawTreeData,
        toggleFilter, toggleHideFilter, ability, refreshCounts, refreshingCounts, loadingNodeId, editable, openInfoId, resetFocus} = useContext(TreeContext);

    const locale = getLocale()
    const {t, i18n} = useTranslation();
    moment.locale(i18n.language)

    // For Event Descriptions form:
    taxTag.editable = editable

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const {filters, replaceFilters} = useFilters()

    const {aggs, total: totalAssets} = useAggsState();

    const {selectedAssetAggs} = useSelectedAggsState();
    const {selectedAssetIds} = useSelectedAssetsState()

    const {id, organizer, synonyms, user, created_at, path_names, ancestor_ids} = taxTag;
    const path = ancestor_ids.concat(id).join('/');

    const ancestry = taxTag.ancestor_ids.concat(taxTag.id).join('/')
    const [count, setCount] = useState(0);

    useEffect(()=>{
        setCount(aggs && _.find(aggs.taxonomy_paths?.buckets, agg => agg.key === ancestry)?.doc_count || 0);
    },[aggs])

    const [total, setTotal] = useState(taxTag.visible_assets_count || 0);

    useEffect(()=>{
        if(!taxTag.visible_assets_count) return

        setTotal(taxTag.visible_assets_count)
    }, [taxTag.visible_assets_count])


    const inSelection = selectedAssetAggs && _.find(selectedAssetAggs.taxonomy_paths?.buckets, agg => agg.key === ancestry)?.doc_count || 0;

    const [name, setName] = useState(taxTag.name);
    const [description, setDescription] = useState(taxTag.description);
    const [dateStart, setDateStart] = useState(taxTag.date_start);
    const [dateEnd, setDateEnd] = useState(taxTag.date_end);
    const [gtin, setGtin] = useState(taxTag.gtin);
    const [sku, setSku] = useState(taxTag.sku);
    const [posterImageGuid, setPosterImageGuid] = useState(taxTag.poster_image_guid);
    const [link, setLink] = useState(taxTag.link);
    const [subType, setSubType] = useState(taxTag.tag.sub_type);
    const [tag, setTag] = useState(taxTag.tag)

    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const onBulkAddFinish = bj =>{
        console.log('onBulkAddFinish', bj)
        refreshCounts()
        setTotal(total + bj.added)

        message.destroy('bulkJob')

        if(bj.added) {
            message.success(`${bj.added} assets added to ${name}`)
        } else {
            notification.error({message:'Tagging Error', description: `Sorry, you don’t have permission to tag files that are not in your Group Library`, duration: 5})
        }
    }

    //--------------------
    // Asset Drop Handling
    //--------------------
    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'asset',
        drop: ({asset}) => {
            const asset_ids = [...new Set([asset.id, ...selectedAssetIds])];

            const data = {
                bulk_job: {
                    asset_ids,
                    tag_names: [name],
                    tag_mode: 'add',
                }
            }

            // Create BulkJob and listen:
            message.info({content: t('message-starting-bulk-job','Starting Bulk Job...'), key:'bulkJob'})

            api.post(`/api/bulk_jobs`, data).then(res => {
                setBulkJobId(res.data.guid)
                bulkJobDispatch({type:'add', bulkJob: res.data})
            }).catch((err)=>{
                console.log(err)
            })
            return taxTag
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        // hover: (item) => {
        //     console.log('hovering', item.id);
        // },
        canDrop: ()=>  !organizer
    });

    const isActive = canDrop && isOver;

    const style = isActive ? {
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed'
    } : {};

    const [links, setLinks] = useState(taxTag.links || [])
    const getInfo = (visible)=>{
        if(visible) {
            api(`/api/tags/${taxTag.tag.id}`).then(res => {
                setTag(res.data)
                setLinks(res.data.links)
            })
        }
    }

    const getPath = useOrgPath()
    const {currentTag} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch()

    const [open, setOpen] = useState()

    useEffect(() => {
        if(!open && openInfoId === taxTag.id) setOpen(true)
    }, [openInfoId]);

    const onChange = (field, original) => {
        return (value) => {
            const set = {
                name: setName,
                description: setDescription,
                link: setLink,
                sub_type: setSubType,
                gtin: setGtin,
                sku: setSku,
                poster_image_guid: setPosterImageGuid,
                date_start: values=> {
                    setDateStart(values[0])
                    setDateEnd(values[1])
                }
            }[field]

            let data = {};

            if(field === 'date_start') {
                data['date_start'] = value[0]
                data['date_end'] = value[1]

            } else {
                data[field] = value;
            }

            updateTaxonomyTag(id, data, (res)=>{
                set(value);
                message.success(t('message-taxonomy-tag-updated','Taxonomy Tag updated.')) ;

                if(currentTag?.id === id) assetGroupDispatch({type:'setCurrentTag', tag: res.data})
            }, ()=>{
                set(original);
            })
        }
    }

    const onDestroy = ()=> {
        api.delete(`/api/taxonomies/${taxonomy.id}/taxonomy_tags/${id}`).then(() => {
            message.success(`${name} ${t('archived','Archived')}.`)
            const newRawData = [...rawTreeData];

            // Remove Node from tree:
            const loop = (data, key, callback) => {
                data.forEach((item, index, arr) => {
                    if (item.slug === key) {
                        return callback(item, index, arr);
                    }
                    if (item.children) {
                        return loop(item.children, key, callback);
                    }
                });
            };

            loop(newRawData, id, (item, index, arr) => {
                arr.splice(index, 1)
            });

            setRawTreeData(newRawData);
        });
    }

    const removePosterImage = ()=> {
        setPosterImageGuid(null)

        updateTaxonomyTag(id, {poster_image_guid: 0}, (res)=>{
            message.success(t('poster-image-removed','Poster Image removed.')) ;

            if(currentTag?.id === id) assetGroupDispatch({type:'setCurrentTag', tag: res.data})
        })
    }

    //--------------------

    return (
        <div style={{...style, display:'flex', justifyContent: 'flex-start', verticalAlign:'center', minWidth:0}} ref={drop}>
            <div style={{flexShrink:0}}>
                {organizer && (
                    <>
                        <FolderOpenOutlined/>
                        &nbsp;
                    </>
                )}

                {subType == 'person' && (
                    <>
                        {tag.thumb_url ? <Avatar src={tag.thumb_url} size={20}/> : <UserOutlined/>}
                        &nbsp;
                    </>
                )}

                {subType == 'event' && (
                    <>
                        <CalendarOutlined/>
                        &nbsp;
                    </>
                )}

                {subType == 'product' && (
                    <>
                        <ShoppingCartOutlined/>
                        &nbsp;
                    </>
                )}
            </div>

            <div className='node-name' style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', minWidth:0, flex: '0 1 auto'}}>
                {name}
                {editMode && synonyms && ` (${synonyms})`}
            </div>

            <div style={{flexShrink:0}} onClick={e => e.stopPropagation()}>
                <Tooltip title={t('details', 'Details')}>
                    <Button
                        onClick={e => setOpen(true)}
                        icon={<InfoCircleOutlined style={{color: Colors.lightGrey}}/>}
                        style={{marginLeft: '0.5em',}}
                        tabIndex={-1}
                        type={'text'}
                        shape={'circle'}
                        size={'small'}
                    />
                </Tooltip>

                <Modal
                    afterOpenChange={getInfo}
                    open={open}
                    onCancel={() => {
                        setOpen(false);
                        resetFocus && resetFocus(taxTag.id)
                    }}
                    footer={null}
                    style={{maxWidth: '100vw'}}
                    title={
                        <div onClick={e => e.stopPropagation()} style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                {organizer ? <FolderOpenOutlined/> : <TagOutlined/>}&nbsp;
                                {path_names.join(' / ')}
                            </div>

                            {editable && (
                                <div style={{marginLeft: '1em'}}>
                                    <MergeTagButton tag={tag}/>
                                </div>
                            )}
                        </div>
                    }
                >
                    <div onClick={e => e.stopPropagation()}>
                        <Space direction={'vertical'}>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('name', 'Name')}>
                                    <Typography.Paragraph
                                        style={{display: 'inline'}}
                                        editable={editable ? {onChange: onChange('name', name)} : false}
                                    >
                                        {name}
                                    </Typography.Paragraph>

                                </Descriptions.Item>
                                <Descriptions.Item label={t('description', 'Description')} style={{maxWidth: 500}}>
                                    <Typography.Paragraph
                                        style={{display: 'inline'}}
                                        editable={editable ? {onChange: onChange('description')} : false}
                                    >
                                        {description || ''}
                                    </Typography.Paragraph>
                                </Descriptions.Item>
                                {!taxTag.organizer && (
                                    <Descriptions.Item label={t('type', 'Type')}>
                                        {editable ? (
                                            <AntRadio.Group
                                                options={[{
                                                    label: t('keyword', 'Keyword'),
                                                    value: 'keyword'
                                                }, {
                                                    label: t('person', 'Person'),
                                                    value: 'person'
                                                }, {
                                                    label: t('event', 'Event'),
                                                    value: 'event'
                                                }, {label: t('product', 'Product'), value: 'product'}]}
                                                onChange={e => {
                                                    onChange('sub_type')(e.target.value);
                                                }}
                                                value={subType}
                                                optionType="button"
                                                size="small"
                                            />
                                        ) : subType.toProperCase()}
                                    </Descriptions.Item>
                                )}


                                <Descriptions.Item label={t('poster-image', 'Poster Image')}>
                                    {posterImageGuid &&
                                        <AssetMention guid={posterImageGuid} style={{marginBottom: '1em'}}/>}

                                    <Typography.Text copyable={!!posterImageGuid} code
                                                     editable={editable && {onChange: onChange('poster_image_guid')}}>
                                        {posterImageGuid}
                                    </Typography.Text>

                                    {editable && posterImageGuid && (
                                        <Popconfirm
                                            title={t('remove-poster-image', 'Remove Poster Image?')}
                                            onConfirm={removePosterImage}
                                        >
                                            <Button size={'small'} ghost danger><DeleteOutlined/></Button>
                                        </Popconfirm>
                                    )}
                                </Descriptions.Item>


                                {subType == 'event' && (
                                    <Descriptions.Item label={t('date', 'Date')}>
                                        {editable ? (
                                            <AntDatePicker.RangePicker
                                                name={'date_start'}
                                                value={dateStart ? [day(dateStart), day(dateEnd)] : null}
                                                onChange={onChange('date_start')}
                                                getPopupContainer={trigger => trigger.parentElement}
                                                locale={locale}
                                            />
                                        ) : (
                                            <>
                                                {moment(dateStart).format('YYYY-MM-DD')}
                                                {dateStart != dateEnd && (
                                                    <>- {moment(dateEnd).format('YYYY-MM-DD')}</>
                                                )}
                                            </>
                                        )}
                                    </Descriptions.Item>
                                )}

                                {subType == 'product' && (
                                    <>
                                        <Descriptions.Item label={'GTIN'}>
                                            <Typography.Text editable={editable && {onChange: onChange('gtin')}}>
                                                {gtin}
                                            </Typography.Text>
                                        </Descriptions.Item>

                                        <Descriptions.Item label={'SKU'}>
                                            <Typography.Text editable={editable && {onChange: onChange('sku')}}>
                                                {sku}
                                            </Typography.Text>
                                        </Descriptions.Item>
                                    </>
                                )}

                                <Descriptions.Item label={t('links', 'Links')}>
                                    <Links what={{...taxTag.tag, links: links, editable}} type={'Tag'}/>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('created', 'Created')}>
                                    <TimeAgo date={created_at}/> {t('by', 'by')} <User user={user}/>
                                </Descriptions.Item>

                                <Descriptions.Item label={t('synonyms', 'Synonyms')}>
                                    <TagSynonymManager tag={tag} onUpdate={updatedTag => setTag(updatedTag)}/>
                                </Descriptions.Item>
                            </Descriptions>

                            {editable && (
                                <Space direction={'horizontal'}>
                                    <AddTaxonomyTag parent={taxTag}/>

                                    <Popconfirm
                                        title={t('confirm-remove', 'Remove?')}
                                        onConfirm={onDestroy}
                                    >
                                        <Button danger size={'small'}>
                                            <DeleteOutlined/> {t('button-remove-from-taxonomy', 'Remove From Taxonomy')}...
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            )}

                            {subType == 'event' && (
                                <EventTagDescriptions tag={taxTag} tagId={taxTag.tag.id}/>
                            )}
                        </Space>
                    </div>
                </Modal>
            </div>

            <div style={{flexShrink: '0', marginLeft: 'auto'}}>
                {(() => {
                    const filtered = filters.taxonomy.indexOf(path) !== -1
                    const hideFiltered = filters.hide_taxonomy.indexOf(path) !== -1

                    const addFilter = (e) => {
                        e.stopPropagation();
                        if (hideFiltered) toggleHideFilter(taxTag);
                        toggleFilter(taxTag);
                    }

                    const hideFilter = (e) => {
                        e.stopPropagation();
                        if (filtered) toggleFilter(taxTag);
                        toggleHideFilter(taxTag);
                    }

                    const showAll = (e) => {
                        e.stopPropagation();
                        replaceFilters({taxonomy: [path]}, getPath(`/explore`));
                    }

                    return (
                        <Popover
                            title={<>
                                <FilterOutlined/> {`${filtered ? t('remove-filter', 'Remove Filter') : t('add-filter', 'Add Filter')} ${name}`}</>}
                            placement='right'
                            content={<TagFilter {...{
                                filtered,
                                hideFiltered,
                                addFilter,
                                hideFilter,
                                showAll,
                                count,
                                total
                            }}/>}
                        >
                            <Tag
                                style={{margin: 0, cursor: 'pointer'}}
                                color={filtered || hideFiltered ? 'grey' : (count > 0 ? 'blue' : 'default')}
                                onClick={addFilter}
                            >
                                {(loadingNodeId === taxTag.id || (refreshingCounts && refreshingCounts.indexOf(taxTag.id) !== -1)) &&
                                    <LoadingOutlined/> || (
                                        <>
                                            {!!inSelection && <CheckOutlined/>} {n(total)} {hideFiltered &&
                                            <EyeInvisibleOutlined/>}
                                        </>
                                    )}
                            </Tag>
                        </Popover>
                    )
                })()}
            </div>

            {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onBulkAddFinish}/>}
        </div>
    );
}

const TagFilter = ({filtered, hideFiltered, addFilter, hideFilter, showAll, count, total}) => {
    const {t} = useTranslation();
    const {total: totalAssets} = useAggsState();

    return (
        <>
            <div>
                <h4><FilterOutlined/> {t('filter-shown-files', 'Filter Shown Files')}:</h4>
                <Checkbox checked={filtered} onClick={addFilter}>{t('has-tag', 'Has tag')} <Tag
                    style={{marginLeft: '1em'}}>{n(count)}</Tag></Checkbox>
                <br/>
                <Checkbox checked={hideFiltered} onClick={hideFilter}>{t('does-not-have-tag', 'Does not have tag')} <Tag
                    style={{marginLeft: '1em'}}>{n(totalAssets - count)}</Tag></Checkbox>
            </div>
            <p style={{marginTop: '.5em'}}>
                <Button icon={<EyeOutlined/>} onClick={showAll}>
                    {t('show-every-file-with-this-tag', 'Show every file with this tag')} <Tag
                    style={{marginLeft: '1em'}}>{n(total || 0)}</Tag>
                </Button>
            </p>
        </>
    )
}

export {TagFilter}

// ------------------------------------------------------------------------------------------------------
// AddTaxonomyTag
// ------------------------------------------------------------------------------------------------------

const AddTaxonomyTag = ({organizer, parent, ...buttonProps}) => {
    const {
        taxonomyId,
        loadTreeData,
        expand,
        setRawTreeData,
        rawTreeData,
        setMode,
        mode,
        editable
    } = useContext(TreeContext);

    const {t} = useTranslation();
    const locale = getLocale()

    const [popoverVisible, setPopoverVisible] = useState(false);

    const autoFocusInput = useRef(null);

    const showPopover = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setPopoverVisible(true)
        setTimeout(() => {
            autoFocusInput.current?.focus()
        }, 100)
    }

    const title = organizer ? t('new-top-level-taxonomy-organizer', 'New Top Level Organizer') : t('new-taxonomy-tag', `New Taxonomy Tag`);

    return (<>
        <Formik
            initialValues={{sub_type: 'keyword'}}
            enableReinitialize
            onSubmit={(values, actions) => {
                values.parent_id = parent?.id;
                values.organizer = organizer

                const data = {taxonomy_tag: values}

                api.post(`/api/taxonomies/${taxonomyId}/taxonomy_tags`, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    if (parent) {
                        parent.has_children = true;
                        loadTreeData({id: parent.id, has_children: true}, () => {
                            expand(null, {expanded: true, node: parent});
                        });
                    } else {
                        setRawTreeData([res.data, ...rawTreeData])
                    }

                    setPopoverVisible(false);
                    message.success(t('message-tag-created', 'Tag created.'))
                    if (mode !== 'Tag') setMode('Edit');

                }).catch((err) => {
                    console.log(err)
                    actions.setSubmitting(false);
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {

                // This is needed since an Enter key press will submit the outer form also
                const onKeyDownCapture = (e) => {
                    if (e.keyCode == 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        submitForm();
                    }
                }

                return (
                    (<Modal
                        zIndex={9001}
                        title={title}
                        open={popoverVisible}
                        destroyOnClose
                        onCancel={() => setPopoverVisible(false)}
                        centered
                        confirmLoading
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                    <SaveOutlined/>
                                    {t('button-create', 'Create')}
                                </Button>
                                <Button onClick={() => setPopoverVisible(false)}>{t('button-cancel', 'Cancel')}</Button>
                            </Space>
                        }
                    >
                        <form onSubmit={handleSubmit} onKeyDownCapture={onKeyDownCapture}>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name', 'Name')} name={'name'} value={values?.name}
                                            description={t('name-description', 'e.g. Marketing')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus
                                           autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='description' style={{marginTop:'.5em'}}>
                                <FloatLabel label={t('description','Description')} name={'description'} value={values?.description} description={t('optional','Optional.')}>
                                    <Input.TextArea rows={2} name='description'/>
                                </FloatLabel>
                            </FormItem>

                            {!organizer && (
                                <FormItem name='sub_type' label={t('type','Type')}>
                                    <Radio.Group
                                        name={'sub_type'}
                                        options={[{label:t('keyword','Keyword'), value:'keyword'}, {label:t('person','Person'), value:'person'}, {label:t('event','Event'), value:'event'}, {label: t('product','Product'), value:'product'}]}
                                        optionType="button"
                                        size="small"
                                    />
                                </FormItem>
                            )}

                            {values.sub_type === 'event' && (
                                <FormItem name='date_start' label={t('date','Date')}>
                                    <DatePicker.RangePicker
                                        value={[day(values.date_start), day(values.date_end)]}
                                        onChange={(values) => {
                                            setFieldValue('date_start', values[0]);
                                            setFieldValue('date_end', values[1]);
                                        }}
                                        popupStyle={{zIndex:9999}}
                                        locale={locale}
                                    />
                                </FormItem>
                            )}
                        </form>
                    </Modal>)
                );
            }}
        </Formik>
        {editable && (
            <Button size='small' type={'primary'} ghost onClick={showPopover} {...buttonProps}>
                <PlusOutlined/>
                {organizer ? t('button-add-organizer', `Add Organizer`) : t('button-add-sub-tag', `Add Sub-Tag`)}
            </Button>
        )}
    </>);
}

const TaxonomyTitle = ()=> {
    const {taxonomy, attrs, setAttrs, setArchived, ability, editable} = useContext(TreeContext);
    const {id, created_at, user, name, description} = attrs;

    const org = useCurrentOrg()
    const {state} = useContext(AppContext);

    const {t} = useTranslation();

    const onChange = (field) => {
        return (value) => {
            attrs[field] = value
            setAttrs({...attrs});

            let data = {};
            data[field] = value;
            api.put(`/api/taxonomies/${id}`, {taxonomy: data}).then((res) => {
                message.success(t('message-taxonomy-updated', 'Taxonomy updated.'));
            });
        }
    }

    const onDestroy = () => {
        api.delete(`/api/taxonomies/${id}`).then(() => {
            message.success(`${name} ${t('message-archived', 'Archived.')}`)
            setArchived(true);
        });
    }

    const [editing, setEditing ] = useState()
    const clickEdit = ()=> setEditing(true)

    const [updates, setUpdates] = useState(0)
    const onUpdate = data => {
        setAttrs(data)
        setUpdates(updates + 1)
    }

    const [userGroups, setUserGroups] = useState()

    useEffect(()=>{
        api(`/api/taxonomies/${taxonomy.id}`).then(res => {
            setUserGroups(res.data.user_groups)
        })
    }, [taxonomy.id, updates])

    const [downloading, setDownloading] = useState()
    const downloadExport = ()=>{
        setDownloading(true)
        const a = document.createElement("a")
        const url = `/api/download_taxonomy/${taxonomy.id}?organization_id=${org?.id}&jwt=${state.jwt}`
        a.setAttribute('href', url)
        a.setAttribute('_target', 'blank')
        a.setAttribute('download', `${taxonomy.name}.txt`)

        a.style.display = 'none';
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a);
        setTimeout(()=> setDownloading(false), 1000)
    }

    const [open, setOpen] = useState()

    return (
        <Space size={2}>
            {name}

            <Button icon={<InfoCircleOutlined/>} type={'text'} size={'small'} shape={'circle'} style={{color: 'rgb(176, 176, 176)'}} onClick={()=> setOpen(true)}/>

            <Modal
                open={open}
                onCancel={()=> setOpen(false)}
                footer={null}
                title={
                    <div style={{display:'flex'}}>
                        <div>
                            <ApartmentOutlined/> {name}
                        </div>

                        <Can I={'manage'} a={'Taxonomy'}>
                            <div style={{marginLeft:'1em'}}>
                                <Button icon={<EditOutlined/>} onClick={clickEdit} size={'small'} type={'primary'} ghost>Edit</Button>

                                <TaxonomyForm taxonomy={attrs} visible={editing} onCancel={() => setEditing(false)} onUpdate={onUpdate}/>
                            </div>
                        </Can>
                    </div>
                }
            >
                <Space direction={'vertical'}>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item label={t('name', 'Name')}>
                            <Typography.Paragraph
                                style={{display: 'inline'}}
                                editable={editable ? {onChange: onChange('name')} : false}
                            >
                                {name}
                            </Typography.Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('description', 'Description')}>
                            <Typography.Paragraph
                                style={{display: 'inline'}}
                                editable={editable ? {onChange: onChange('description')} : false}
                            >
                                {description || ''}
                            </Typography.Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('created', 'Created')}>
                            <TimeAgo date={created_at}/> {t('by', 'by')} <User user={user}/>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('user_groups', 'User Groups')}>
                            <VerticalSpace>
                                {userGroups?.map(group => (
                                    <div key={group.id}><UserGroupIcon/> {group.name}</div>
                                ))}
                            </VerticalSpace>
                        </Descriptions.Item>
                    </Descriptions>

                    {editable && (
                        <Space direction={'horizontal'}>
                            <ImportButton taxonomy={taxonomy}/>

                            <Button size={'small'} type={'primary'} ghost icon={<DownloadOutlined/>} onClick={downloadExport} loading={downloading}>
                                {t('export','Export')}
                            </Button>

                            <Can I={'manage'} a={'Taxonomy'}>
                                {org.plan_has_multiple_tag_trees && (
                                    <Popconfirm
                                        title={t('confirm-archive', 'Archive?')}
                                        onConfirm={onDestroy}
                                    >
                                        <Button danger size={'small'}>
                                            <DeleteOutlined/> {t('button-archive', 'Archive...')}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Can>
                        </Space>
                    )}
                </Space>
            </Modal>
        </Space>
    );
}

const ImportButton = ({taxonomy})=>{
    const {t} = useTranslation();
    const {loadTreeData} = useContext(TreeContext);

    const bulkJobDispatch = useBulkJobsDispatch()

    const [showImportModal, setShowImportModal] = useState()

    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState()

    const [bulkJobId, setBulkJobId] = useState()

    const clickImport = (e)=> {
        setShowImportModal(true)
    }

    const onAddFile = (file)=> {
        setUploading(true)
        setError(false)

        if (file.type !== 'text/plain') {
            message.error(`Error: ${file.name} ${t('error-is-not-text-file', 'is not a txt file')}`);
            setUploading(false)
            return false
        }

        const data = new FormData()
        data.append('taxonomy_import[file]', file)

        api.post(`/api/taxonomies/${taxonomy.id}/taxonomy_imports`, data).then(res => {
            setUploading(false)
            setFileList([])
            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})
        }).catch(err => {
            setUploading(false)
            message.error(`${t('error','Error')}: ${err}`)
        })

        return false
    }

    const onFinish = (bj)=> {
        message.success(t('message-done','Done!'))
        setShowImportModal(false)
        setBulkJobId(null)
        loadTreeData({has_children: true})
    }

    const [error, setError] = useState()

    const onError = (bj)=> {
        setError(bj.error)
        setBulkJobId(null)
    }

    return (<>
        <Button icon={<UploadOutlined/>} size={'small'} onClick={clickImport}>{t('button-import','Import...')}</Button>
        <Modal
            open={showImportModal}
            title={<>{t('import-taxonomy-from-file','Import Taxonomy From File')} <HelpPopover code={'taxonomy-import'}/></>}
            onCancel={() => setShowImportModal(false)}
            footer={null}
        >
            <VerticalSpace>
                {bulkJobId && (
                    <>
                        {t('processing-file','Processing File')}:
                        <BulkJobProgress id={bulkJobId} onFinish={onFinish} onError={onError}/>
                    </>
                ) || (
                    <Upload
                        beforeUpload={onAddFile}
                        fileList={fileList}
                        showUploadList={false}
                    >
                        <Button icon={<UploadOutlined />} loading={uploading}>{uploading ? t('uploading','Uploading...') : t('select-txt-file','Select .txt File')}</Button>
                    </Upload>
                )}

                {error && (
                    <Alert
                        showIcon
                        type={'error'}
                        description={error}
                    />
                )}
            </VerticalSpace>
        </Modal>
    </>);
}
