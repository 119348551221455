import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    Modal,
    Table,
    Typography,
    Flex, message, Popconfirm, Button, Divider
} from "antd";

import filesize from 'filesize'
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import AutoSizer from "react-virtualized-auto-sizer";
import UploadProgress from "../uploads/UploadProgress";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAssetsPageDispatch, useAssetsPageState} from "../../contexts/AssetsPageContext";
import {useUploadsState} from "../../contexts/UploadsContext";
import {useAssetLoader} from "../explore/AssetsManager";
import {useTranslation} from "react-i18next";
import {EmptyAssetGrid} from "~/components/explore/AssetsList";
import {DeleteOutlined, FileOutlined, LoadingOutlined} from "@ant-design/icons";
import {AssetViewer, EditableTextArea} from "~/components/widgets/AssetModal";
import {AssetModalContext} from "~/components/widgets/AssetModal";
import IsolatedDiv from "~/components/helpers/IsolatedDiv";
import AutoLinker from "~/components/helpers/AutoLinker";
import api from "~/components/api";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import {value} from "lodash/seq";
import {useDeleteAsset} from "~/components/widgets/DeleteAssetButton";
import {isMobile} from "~/plugins/device-detect";

export default ({})=> {
    const {t} = useTranslation();

    const {assetIds, assetsLoading, moreAssetsLoading} = useLoadedAssetsState()
    const total = assetIds.length

    const assetsPageDispatch = useAssetsPageDispatch()

    const {page} = useAssetsPageState()
    const tableAssetIds = _.compact(assetIds.slice((page - 1) * 25, (page - 1) * 25 + 25))

    const columns = [
        {
            key: 'created_at',
            render: ({id}) => <UploadedCol id={id}/>,
            width: 100,
            details: true
        },
        {
            key: 'info',
            render: ({id}) => <InfoCol id={id}/>,
        },
    ];

    const {assetLoader} = useAssetLoader()
    const [loadingPage, setLoadingPage] = useState()

    const [settings, setSettings] = useState({})
    const handleTableChange = (pagination=settings.pagination, filter, sorter)=>{
        setSettings({pagination, filters, sorter})
        setLoadingPage(true)
        assetLoader({
            page: pagination.current,
            cb: ()=>{
                assetsPageDispatch({type:'setPage', page: pagination.current})
                setLoadingPage(false)
            }
        })
    }

    const dataSource = tableAssetIds.reverse().map(id => { return {id} })

    return (
        <AutoSizer>
            {({height, width}) => (
                <div style={{width:width, height:'100%', marginTop:'1em'}}>
                    <Table
                        showHeader={false}
                        loading={loadingPage || assetsLoading || moreAssetsLoading}
                        size={'small'}
                        rowKey={'id'}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{defaultPageSize: 100, total: total, current: page, showSizeChanger: false}}
                        onChange={handleTableChange}
                        scroll={{y: height, scrollToFirstRowOnChange: true, x: true}}
                        locale={{
                            emptyText: <EmptyAssetGrid/>,
                        }}
                    />
                </div>
            )}
        </AutoSizer>
    );
}

const assetSize = isMobile() ? 100 : 250;

const UploadedCol = ({id})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    const {uploadingAssets} = useUploadsState();
    const {percent, stats} = _.find(uploadingAssets, {id}) || {};

    return useMemo(()=> {
        return (asset.aasm_state === 'uploading' || asset.aasm_state === 'processing') ?
            <UploadProgress asset={asset} width={assetSize}/>
            : (
                <AssetPreviewModal asset={asset}/>
            )
    }, [
        percent, stats,
        asset?.aasm_state,
        asset?.thumb_url, asset?.meta_extracted, asset?.grid_url, asset?.permalink_url, asset?.final_storage
    ])
}

const AssetPreviewModal = ({asset})=>{
    const [open, setOpen] = useState()

    const assetsDispatch = useAssetsDispatch();

    useEffect(()=>{
        if(!open) return;
        api(`/api/assets/${asset.id}`).then(res => {
            // check to see if current asset has changed:
            if(asset.id !== res.data.id) return;

            assetsDispatch({type:'updateAsset', asset: res.data});
        })
    },[open]);

    return (
        <>
            <a onClick={()=> setOpen(true)}>
                <AssetBrowseImage asset={asset} assetSize={assetSize} thumbType={'small'} padding={'0.5em'}/>
            </a>

            <Modal
                open={open}
                footer={null}
                onCancel={()=> setOpen(false)}
                width={'75vw'}
                title={asset.filename}
            >
                <AssetModalContext.Provider value={{asset, attrs: asset}} >
                    <AssetViewer disableContextMenu/>
                </AssetModalContext.Provider>
            </Modal>
        </>
    )
}

const InfoCol = ({id})=>{
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    const [description, setDescription] = useState(asset.description)

    useEffect(() => {
        setDescription(asset.description);
    },[asset.description])

    const updateDescription = value => {
        setDescription(value)
        api.put(`/api/assets/${asset.id}/update_description`, {asset: { description: value}}).then(res => {
            message.success(t('message-description-updated','Description Updated!'))
        })
    }

    const assetsDispatch = useAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch()
    const [destroying, setDestroying] = useState()

    const destroy = ()=>{
        setDestroying(true)

        api.delete(`/api/assets/${asset.id}?destroy=true` ).then(res => {
            assetsDispatch({type:'removeAsset', id: asset.id})
            loadedAssetsDispatch({type: 'removeAsset', asset})

            setDestroying(false)
            message.success(t('message-asset-removed','Asset Removed!'))
        })
    }

    return (
        <Flex align={'center'} justify={'space-between'}>
            <VerticalSpace size={'large'}>
                <div>
                    <strong>{t('description', 'Description')}:</strong>
                    <br/>
                    {('description' in asset) ? (
                        <EditableTextArea
                            tooltip={t('edit-description', 'Edit Description')}
                            id={asset.id}
                            value={description}
                            displayValue={<AutoLinker text={description}/>}
                            onChange={updateDescription}
                            editable
                            style={{marginBottom: 0}}
                            bottomButtons={isMobile()}
                        />
                    ) : (
                        <Typography.Text type={'secondary'}><LoadingOutlined/></Typography.Text>
                    )}
                </div>

                <Typography.Text type={'secondary'}>
                    <small id={`asset-${id}`} className={`state-${asset.aasm_state}`}><FileOutlined/> {asset.filename}</small>
                    <br/>
                    <small>{filesize(asset.file_size, {round: true})}</small>
                </Typography.Text>
            </VerticalSpace>

            <Popconfirm
                title={t('remove-asset', 'Remove Asset?')}
                onConfirm={destroy}
            >
                <Button icon={<DeleteOutlined/>} danger ghost loading={destroying} id={`destroy-btn-${asset.id}`}/>
            </Popconfirm>
        </Flex>
    )
}