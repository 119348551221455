import React, {createContext, memo, useContext, useEffect, useState} from "react";
import {Divider, message} from "antd";

import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'

import BrowseHeader from "./BrowseHeader";
import AssetsList from "./AssetsList";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import {useUploadsDispatch} from "../../contexts/UploadsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import api from "../api";
import {useNavigate} from "react-router-dom-v5-compat";
import {AppContext} from "../../contexts/AppContext";
import Toc from "./Toc";
import {SessionContext} from "../../contexts/SessionContext";
import {useFilters} from "../helpers/useFilters";
import uuid from "../helpers/uuid";
import {useOrgPath} from "../helpers/OrgNavLink";
import {HotKeys} from "react-hotkeys";
import {useCurrentAssetCursor} from "~/components/helpers/useSetCurrentAsset";
import {useTranslation} from "react-i18next";
import {useViewMode} from "@/components/explore/ViewMenu";

export default ({quickUpload})=> {
    // console.log('rendering AssetGrid');
    const {state: appState} = useContext(AppContext);
    const {currentUser} = appState;

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const assetGroupDispatch = useAssetGroupDispatch();
    const uploadsDispatch = useUploadsDispatch()

    const {currentUpload, currentAssetGroup, currentTag} = useAssetGroupState();

    const navigate = useNavigate();

    const {setFilters} = useFilters()
    useEffect(()=>{
        setFilters(true, {gps: null})
    }, [])

    const processFiles = (files, upload)=> {
        uploadsDispatch({type:'addFiles', files, upload})
    }

    const handleDropItem = (item, upload=currentUpload)=>{
        item.dirContent.then((files) => {
            if (files.length){
                // handle dragged folder(s)
                processFiles(files, upload)
            }
            else if (item.files && item.files.length){
                //handle dragged files
                processFiles(item.files, upload)
            }
        });
    }

    const getPath = useOrgPath()

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {

            // If no currentUpload, create one based on currentGroup and redirect to it:
            if(currentUser && currentUpload?.user_id != currentUser.id) {
                const container = currentUpload || currentAssetGroup
                if(!container || !container.contribution_id) {
                    handleDropItem(item)
                    message.info('Please select an upload destination.')
                    return
                }

                api.post(`/api/contributions/${container.contribution_id}/uploads`,{
                    asset_group_folder_id: currentAssetGroup?.folder?.id
                }).then(res => {
                    message.success('Starting new Upload!')

                    const upload = res.data;
                    navigate(getPath(`/upload/${upload.contribution.slug}/${upload.guid}`));
                    assetGroupDispatch({type: 'setCurrentUpload', upload});

                    // HACK: should queue up for processing once the Upload is set in BrowseHeader
                    setTimeout(()=> {
                        handleDropItem(item, upload)
                    }, 2000)
                })
            } else {
                handleDropItem(item)
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const onPaste = e =>{
        const item = e.clipboardData.items[0]

        if(!item) return;

        if(item.type.match('^image/')) {
            const file = item.getAsFile()
            file.filename = `pasted-image-${uuid().slice(0,8)}.png`
            processFiles([file], currentUpload)
        } else if(item.type == 'text/plain') {
           console.log(e.clipboardData.getData('Text'))
        }
    }

    const isActive = canDrop && isOver

    return <Grid isActive={isActive} drop={drop} onPaste={onPaste} quickUpload={quickUpload}/>;
}

const Grid = memo(({isActive, drop, onPaste, quickUpload})=> {
    const {t} = useTranslation();
    const headerHeight = document.getElementById('header')?.clientHeight
    const {viewMode} = useViewMode()

    return (
        <main id='main' role='main' tabIndex={0} aria-label={t('asset-contents', 'Asset Contents')} className={`view-mode-${viewMode}`}>

            <div id={'main-focus-indicator'}/>

            <AssetNavKeys>
                <div className='drop-target' ref={drop} style={{height: `calc(100vh - ${headerHeight}px + 32px)`}} onPaste={onPaste}>
                    <BrowseHeader quickUpload={quickUpload}/>

                    {!quickUpload && (
                        <>
                            <Divider style={{margin: 0}}/>
                            <Toc/>
                        </>
                    )}

                    <AssetsList quickUpload={quickUpload}/>

                    {isActive && (
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            opacity: 0.5,
                            backgroundColor: '#000',
                            zIndex: 1000
                        }}>
                            <h1 style={{opacity: 1, color: 'white', fontSize: '3em'}} className={'fixed-center'}>
                                <CloudUploadOutlined/> Drop Files or Folders to Upload...
                            </h1>
                        </div>
                    )}
                </div>
            </AssetNavKeys>
        </main>
    )
})

const AssetNavKeys = ({children}) => {
    const currentAssetCursor = useCurrentAssetCursor()

    const keyMap = {
        LEFT: ['left', 'option+left'], RIGHT: ['right', 'option+right'], UP: 'up', DOWN: 'down', SPACE: 'space', S: 's', SHIFT_S: 'shift+s',
        SHIFT_LEFT: 'shift+left', SHIFT_RIGHT: 'shift+right',
        SHIFT_UP: 'shift+up', SHIFT_DOWN: 'shift+down',
    }

    const handlers = {
        LEFT: ()=> {currentAssetCursor('prev')},
        SHIFT_LEFT: ()=> {currentAssetCursor('prev', true)},
        RIGHT: ()=> {currentAssetCursor('next')},
        SHIFT_RIGHT: ()=> {currentAssetCursor('next', true)},
        UP: ()=> {currentAssetCursor('up')},
        SHIFT_UP: ()=> {currentAssetCursor('up', true)},
        DOWN: ()=> {currentAssetCursor('down')},
        SHIFT_DOWN: ()=> {currentAssetCursor('down',true)},
        S: ()=> {currentAssetCursor('toggle')},
        SHIFT_S: ()=> {currentAssetCursor('shift-toggle')},
        SPACE: (e)=> {
            e.preventDefault()
            currentAssetCursor('open')
        },
    }

    return (
        <HotKeys keyMap={keyMap} handlers={handlers} allowChanges>
            {children}
        </HotKeys>
    )
}

export {AssetNavKeys}