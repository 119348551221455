import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom-v5-compat";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    DeleteOutlined, DeploymentUnitOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, LinkOutlined, MailOutlined, UserSwitchOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import GroupForm from "./GroupForm";
import WorkflowForm from "./WorkflowForm";
import RightsForm from "./RightsForm";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import EnabledDisabled from "../helpers/EnabledDisabled";
import moment from "moment";
import InviteForm from "./InviteForm";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import StateIcon from "../widgets/StateIcon";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newInvite, q, onLoaded, reloads}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/invites'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-invite-removed','Invite Removed.'));
            handleTableChange();
        });
    }

    const Actions = ({invite}) => {
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${invite.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        return invite.editable && (
            <Space direction={'horizontal'}>
                {/*<Button onClick={edit} size={'middle'}>*/}
                {/*    <EditOutlined/>*/}
                {/*    Edit*/}

                {/*    <InviteForm*/}
                {/*        id={invite.id}*/}
                {/*        visible={formVisible}*/}
                {/*        onSave={onSave}*/}
                {/*        onClose={onClose}*/}
                {/*    />*/}
                {/*</Button>*/}

                <Popover
                    title={<><LinkOutlined/> {t('join-link','Join Link')}</>}
                    content={
                        <>
                            <Typography.Text copyable={{tooltips:t('tooltip-copy-paste-invite-link','Copy/Paste Invite Link'), text: invite.join_link}} code>{invite.join_link}</Typography.Text>
                            <br/>
                            <em>{t('the-recipient-can-use-link-to-join','The recipient can use this link to join.')}</em>
                        </>
                    }
                >
                    <Typography.Link>
                        <LinkOutlined/> {t('link','Link')}
                    </Typography.Link>
                </Popover>

                <ResendInviteBtn invite={invite}/>

                <Popconfirm
                    title={t('confirm-remove-invite','Remove Invite?')}
                    onConfirm={() => destroy(invite.id)}
                >
                    <Button size={'middle'}>
                        <DeleteOutlined/>
                    </Button>
                </Popconfirm>

            </Space>
        )
    }

    const columns = [
        {
            title: t('sent-to','Sent To'),
            dataIndex: 'email',
            sorter: true,
        },
        {
            title: t('role','Role'),
            dataIndex: 'role_level',
            sorter: true,
            render: (role,i) => t(role,i.role_level_name)
        },
        {
            title: t('user-groups','User Groups'),
            render: (invite =>
                <Popover
                    title={t('user-groups','User Groups')}
                    content={invite.user_groups?.map(g => <div key={g.id}>{g.name}</div>)}
                >
                    <Tag>{invite.user_groups_count}</Tag>
                </Popover>)
        },
        {
            title: t('sent-by','Sent By'),
            sorter: true,
            render: invite => <User user={invite.user}/>
        },
        {
            title: t('sent','Sent'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => {
                return <Tooltip title={moment(created_at).format('LLLL')}><span>{moment(created_at).fromNow()}</span></Tooltip>;
            }
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            filters: [
                {text: t('pending','Pending'), value: 'pending'},
                {text: t('accepted','Accepted'), value: 'accepted'},
            ],
            filterMultiple: true,
            render: (state) => (
                <StateIcon state={state}/>
            )
        },
        {
            title: t('signed-up-as','Signed Up As'),
            sorter: true,
            render: invite => <User user={invite.membership}/>
        },
        {
            title: t('actions', 'Actions'),
            key: 'action',
            render: invite => <Actions invite={invite}/>
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newInvite, q, reloads]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Tag{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}

const ResendInviteBtn = ({invite})=> {
    const {t} = useTranslation();
    const [sending, setSending] = useState()

    const resend = ()=> {
        setSending(true)
        api.post(`/api/invites/${invite.id}/resend`).then(res => {
            setSending(false)
            message.success(t('message-invite-email-re-sent','Invite email re-sent!'))
        })
    }

    return (
        <Popconfirm
            title={t('confirm-resend-invite','Resend Invite?')}
            onConfirm={resend}
        >
            <Button loading={sending} icon={<MailOutlined/>}>
                {t('button-resend','Resend')}
            </Button>
        </Popconfirm>
    )
}
