import React from "react";
import api from "../api";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import useSetCurrentAsset from "./useSetCurrentAsset";

export default ()=> {
    const assetsDispatch = useAssetsDispatch();
    const setCurrentAsset = useSetCurrentAsset()

    return id => {
        api(`/api/assets/${id}`).then(res => {
            assetsDispatch({type:'assetsAdded', assets: [res.data]});
            setCurrentAsset(res.data);
        })
    }
}