import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    comments: {},
}

const CommentsStateContext = createContext(initialState);
const CommentsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('CommentsContext reducer:', action.type, action);

    switch (action.type) {
        case 'newComment':
            return {...state, comments: [action.comment, ...state.comments]};

        default:
            return state;
    }
}

function CommentsProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <CommentsStateContext.Provider value={state}>
            <CommentsDispatchContext.Provider value={dispatch}>
                {children}
            </CommentsDispatchContext.Provider>
        </CommentsStateContext.Provider>
    )
}

function useCommentsState() {
    const context = useContext(CommentsStateContext)
    if (context === undefined) {
        throw new Error('useCommentsState must be used within a CommentsProvider')
    }
    return context
}

function useCommentsDispatch() {
    const context = useContext(CommentsDispatchContext)
    if (context === undefined) {
        throw new Error('useCommentsDispatch must be used within a CommentsProvider')
    }
    return context
}

export {
    CommentsProvider,
    useCommentsState,
    useCommentsDispatch
};
