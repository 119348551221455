import React, {createContext, useContext} from "react";
import { useStorageReducer } from 'react-storage-hooks';
import {AppContext} from "./AppContext";
import uuid from "../components/helpers/uuid";

const reducer = (state, action) => {
    console.log('session reducer:', action.type, action);

    switch(action.type) {
        case 'set_org':
            clearSession()
            return {...state, currentOrg: action.org, abilities: action.abilities };

        case 'org_updated':
            return {...state, currentOrg: action.org };

        case 'setCanUpload':
            return {...state, canUpload: action.canUpload };

        case 'setTaxonomyFilterMode':
            if(!state.taxonomyFilterModes) state.taxonomyFilterModes = {}
            state.taxonomyFilterModes[action.id] = action.mode;
            return {...state, taxonomyFilterModes: {...state.taxonomyFilterModes}}

        case 'setViewMode':
            return {...state, viewMode: action.viewMode };

        case 'setGridSize':
            return {...state, gridSize: action.gridSize };

        case 'setGridColor':
            return {...state, gridColor: action.gridColor };

        case 'setShowFilenames':
            return {...state, showFilenames: action.showFilenames };

        case 'setAutoPlay':
            return {...state, autoPlay: action.autoPlay };

        case 'setShowIcons':
            return {...state, showIcons: action.showIcons };

        case 'setShowRating':
            return {...state, showRating: action.showRating };

        case 'setShowHeadline':
            return {...state, showHeadline: action.showHeadline };

        case 'setColorLabel':
            return {...state, colorLabel: action.colorLabel };

        case 'setTocHidden':
            return {...state, tocHidden: action.tocHidden };

        case 'setConvertSpacesToOr':
            return {...state, convertSpacesToOr: action.convertSpacesToOr };

        case 'setConvertCommasToOr':
            return {...state, convertCommasToOr: action.convertCommasToOr };

        case 'logout':
            clearSession()
            return {...state, currentOrg: null, abilities: {}, canUpload: false };

        default:
            return state;
    }
};

const initialState = {
    taxonomyFilterModes: {},
    editingAssetGroup: null,
    tocHidden: false,
    sessionGuid: uuid(),
    canUpload: false,
    currentOrg: null,
    abilities: {},
};

const SessionContext = createContext(initialState);

function SessionProvider(props) {
    const key = `session-reducer`;
    const [state, dispatch, writeError] = useStorageReducer(sessionStorage, key, reducer, initialState);

    window.clearSession = ()=> {
        sessionStorage.clear()
    }

    // TODO: return error display if writeError
    return <SessionContext.Provider value={{state, dispatch}} {...props}/>;
}

export { SessionContext, SessionProvider };
