import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    page: 1,
    loadedPages: []
}

const AssetsPageStateContext = createContext(initialState);
const AssetsPageDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('AssetsPageContext reducer:', action.type, action);

    switch (action.type) {
        case 'setPage':
            if(action.page == 1) state.loadedPages = [];
            if(state.loadedPages.indexOf(action.page) != -1) return state;

            return {...state, page: action.page, loadedPages: [...state.loadedPages, action.page]}

        case 'reload':
            return {...state, page: 1, loadedPages: []}

        default:
            return state;
    }
}

const AssetsPageProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AssetsPageStateContext.Provider value={state}>
            <AssetsPageDispatchContext.Provider value={dispatch}>
                {children}
            </AssetsPageDispatchContext.Provider>
        </AssetsPageStateContext.Provider>
    )
}

function useAssetsPageState() {
    const context = useContext(AssetsPageStateContext)
    if (context === undefined) {
        throw new Error('useAssetsPagetate must be used within a AssetProvider')
    }
    return context
}

function useAssetsPageDispatch() {
    const context = useContext(AssetsPageDispatchContext)
    if (context === undefined) {
        throw new Error('useAssetDispatch must be used within a AssetProvider')
    }
    return context
}

export {
    AssetsPageProvider,
    useAssetsPageState,
    useAssetsPageDispatch
};
