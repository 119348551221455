import {useContext} from "react";

import _ from 'lodash'
import {SessionContext} from "../../contexts/SessionContext";

export default (title) => {
    const {state} = useContext(SessionContext);
    const {currentOrg} = state;

    document.title = _.compact([title, currentOrg?.title, 'Mediagraph', Config.releaseStage != 'production' && Config.releaseStage]).join(' | ');
};

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

String.prototype.toBasename = function() {
    let base = new String(this).substring(this.lastIndexOf('/') + 1);
    if(base.lastIndexOf(".") != -1) base = base.substring(0, base.lastIndexOf("."));
    return base;
}