import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    List,
    Modal,
    message,
    Skeleton,
    Space,
    Popconfirm,
    Alert,
    Badge,
    Tooltip,
    Input,
    Typography,
    notification
} from "antd";

import api from "../api"
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";

import { AntdConfig, Query, Builder, Utils as QbUtils } from '@react-awesome-query-builder/antd';
import '@react-awesome-query-builder/ui/css/styles.css';
import {useStorageState} from "react-storage-hooks";
import {BulbOutlined, SaveOutlined} from "@ant-design/icons";
import ClearOutlined from "@ant-design/icons/lib/icons/ClearOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import {useFilters} from "../helpers/useFilters";
import HelpPopover from "../HelpPopover";
import {useTranslation} from "react-i18next";
const InitialConfig = AntdConfig; // or BasicConfig

// Docs:
// https://github.com/ukrbublik/react-awesome-query-builder/blob/master/CONFIG.adoc#configoperators

const initQuery = {"id": QbUtils.uuid(), "type": "group"};

export default ({style, children, block})=>{
    const {t} = useTranslation();

    const initConfig = {
        ...InitialConfig,
        fields: {
            // _all: {
            //     label: 'Any Field',
            //     type: 'text',
            //     defaultOperator: 'like',
            // },
            // type: {
            //     label: 'Asset Type',
            //     type: 'select',
            //     fieldSettings: {
            //         listValues: {
            //             Image: 'Image',
            //             Video: 'Video',
            //             Audio: 'Audio',
            //             Document: 'Document',
            //             Font: 'Font',
            //         },
            //     },
            // },
            // creator: {
            //     label: 'Creator',
            //     type: 'text',
            //     defaultOperator: 'like',
            // },
            // captured_at: {
            //     label: 'Date Captured',
            //     type: 'datetime',
            //     defaultOperator: 'between',
            // },
            created_at: {
                label: t('date-uploaded','Date Uploaded'),
                type: 'datetime',
                defaultOperator: 'between',
            },
            updated_at: {
                label: t('last-update-date','Last Update Date'),
                type: 'datetime',
                defaultOperator: 'between',
            },
            description: {
                label: t('description','Description'),
                type: 'text',
                defaultOperator: 'like',
            },
            ext: {
                label: t('file-extension','File Extension'),
                type: 'multiselect',
                fieldSettings: {
                    listValues: {},
                },
                allowCustomValues: true
            },
            filename: {
                label: t('filename','Filename'),
                type: 'text',
            },
            file_size_mb: {
                label: t('file-size-mb','File Size (MB)'),
                type: 'number',
                defaultOperator: 'between',
                fieldSettings: {min: 0, step: 0.1},
            },
            // location: {
            //     label: 'Location',
            //     type: 'text',
            //     defaultOperator: 'like',
            // },
            tag_names: {
                label: t('tags','Tags'),
                type: 'multiselect',
                fieldSettings: {
                    listValues: {},
                },
                allowCustomValues: true
            },
            rating: {
                label: t('rating','Rating'),
                type: 'number',
                defaultOperator: 'between',
                fieldSettings: {min: 0, max:5},
                // preferWidgets: ['slider', 'rangeslider'],
            },
            width: {
                label: t('width','Width'),
                type: 'number',
                defaultOperator: 'between',
                fieldSettings: {min: 0},
            },
            height: {
                label: t('height','Height'),
                type: 'number',
                defaultOperator: 'between',
                fieldSettings: {min: 0},
            },
            // duration: {
            //     label: 'Duration (s)',
            //     type: 'number',
            //     defaultOperator: 'between',
            //     fieldSettings: {min: 0},
            // },
        }
    };

    initConfig.settings.groupActionsPosition = 'bottomLeft'
    initConfig.settings.addRuleLabel = 'Add Search Term'
    initConfig.settings.addGroupLabel = 'Add Group of Search Terms'

    const {filters, setFilters} = useFilters();

    const [visible, setVisible] = useState(false);

    useEffect(()=> {
       if(window.location.hash == '#search') setVisible(true)
    }, []);

    const onClick = () => {
        setVisible(true)
        window.location.hash = 'search'
    }

    const onCancel = () => {
        setVisible(false)
        window.location.hash = ''
    }

    // ------------- Query Builder ----------------

    const [queryValue, setQueryValue] = useStorageState(sessionStorage, `query-builder`, initQuery);

    Object.keys(MetaFields).map(f =>{
        const meta = MetaFields[f]

        const defaultOperators = ['is_null', 'is_not_null']

        let operators = [];
        let  type = '';
        let mode = null;
        let subfields = null;
        switch(meta.index_type) {
            case 'boolean':
                type = 'boolean';
                break;
            case 'date':
                type = 'date'
                operators = ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between']
                break;
            case 'integer':
                operators = ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between']
                type = 'number'
                break;
            // case 'geo_point':
            case 'keyword':
            case 'text':
            default:
                type = 'text'
                operators = ['equal', 'not_equal', 'like', 'not_like', 'starts_with', 'ends_with']
        }


        initConfig.fields[meta.index_name || f] = {
            label: meta.name,
            type,
            mode,
            subfields,
            operators: [...defaultOperators, ...operators]
        }

        if(!initConfig.fields[meta.index_name || f].defaultOperator) initConfig.fields[meta.index_name || f].defaultOperator = 'like'

    })

    const [config, setConfig] = useState(initConfig);
    const [tree, setTree] = useState(QbUtils.sanitizeTree(QbUtils.loadTree(queryValue), config).fixedTree);

    const renderResult = () => (
        <div className="query-builder-result">
            <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(tree, config))}</pre></div>
            {/*<div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(tree, config))}</pre></div>*/}
            <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(tree, config))}</pre></div>
            <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(tree, config))}</pre></div>
        </div>
    )

    const [result, setResult] = useState();

    const onChange = _.throttle((immutableTree, config) => {
        setConfig(config);
        setTree(immutableTree);

        const jsonTree = QbUtils.getTree(immutableTree);
        setQueryValue(jsonTree);

        const sql = QbUtils.sqlFormat(immutableTree, config);
        if(sql && sql != '') {
            api.get('/api/assets/search', {params:{sql: sql}}).then(res => {
                if(res.data.error) {
                    notification.error({message:'Search Error', description: res.data.error, duration: 0})

                } else {
                    setResult(res.data)
                }
            })
        }
    }, 100)

    const searchQueryNameInput = useRef();

    const [savedCount, setSavedCount] = useState(0);

    const save = () => {
        const sql = QbUtils.sqlFormat(tree, config);
        const json_logic = QbUtils.getTree(tree, config);
        const name = searchQueryNameInput.current.input.value;

        api.post('/api/search_queries', {search_query: { json_logic, sql, name }}).then(res => {
            message.success('Search Query saved!')
            setSavedCount(savedCount + 1);
        })
    }

    const run = (sq) => {
        runSql(QbUtils.sqlFormat(tree, config));
    }

    const runSql = (sql) => {
        setFilters(false, {sql: sql});
        onCancel();
    }

    const edit = (sq) => {
        // setQueryValue(sq.json_logic)
        setTree(QbUtils.checkTree(QbUtils.loadTree(sq.json_logic), config));
        // onChange(tree, config)
    }

    useEffect(()=>{
        onChange(tree,config)
    }, [])

    const clear = () => {
        setTree(QbUtils.checkTree(QbUtils.loadTree(initQuery), config));
        setResult(null);
    }

    const [searchQueries, setSearchQueries] = useState();
    useEffect(()=> {
        if(!visible) return;

        api('/api/search_queries').then(res => {
           setSearchQueries(res.data);
        })
    }, [visible, savedCount])

    const renderBuilder = (props) => (
        <div className="query-builder-container">
            <div className="query-builder" style={{margin: '0 0 1rem' }}>
                <Builder {...props} />

                <Space direction={'horizontal'}>
                    <Button disabled={!result} type={'primary'} onClick={run} icon={<SearchOutlined/>}>{t('button-search','Search')}</Button>
                    <Popconfirm
                        title={t('confirm-clear-query','Clear Query?')}
                        onConfirm={clear}
                    >
                        <Button disabled={!result} icon={<ClearOutlined/>}>{t('button-reset','Reset')}</Button>
                    </Popconfirm>
                    <Input ref={searchQueryNameInput} type={'text'} placeholder={t('placeholder-set-query-name...','Set Query Name...')}/>
                    <Button disabled={!result} type={'primary'} ghost onClick={save} icon={<SaveOutlined/>}>{t('save','Save')}</Button>
                </Space>

                {/*{renderResult()}*/}

                {result && <Alert
                    type={result.total_entries > 0 ? 'success' : 'warning'}
                    style={{marginTop:'1em', paddingBottom: result.total_entries > 0 ? 0 : null}}
                    showIcon
                    message={`${t('test-results', 'Test Results')}: ${result.total_entries}`}
                    description={
                        <>
                            {result.assets.length ?
                                <List
                                    size="small"
                                    style={{margin:0}}
                                    grid={{}}
                                    dataSource={result.assets.slice(0,10)}
                                    renderItem={asset => (
                                        <List.Item style={{marginLeft:0, padding:'0 0.5em 0 0'}}>
                                            <img src={asset.thumb_url} style={{ width: 50, height: 50, objectFit: 'scale-down'}}/>
                                        </List.Item>
                                    )}
                                />
                                : 'None...'}
                        </>
                    }
                />}

                {searchQueries && (
                    <List
                        header={<strong>{t('saved-searches','Saved Searches')}</strong>}
                        style={{marginTop:'1em'}}
                        size="small"
                        bordered
                        dataSource={searchQueries}
                        renderItem={sq => {
                            const destroy = () => {
                                api.delete(`/api/search_queries/${sq.id}`).then(res => {
                                    setSavedCount(savedCount + 1);
                                    message.success(t('message-search-query-deleted.','Search Query Deleted.'))
                                })
                            }

                            return (
                                <List.Item id={sq.id}>
                                    <Space direction={'horizontal'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => runSql(sq.sql)}>{t('button-run','Run')}</Button>
                                        {sq.name}
                                        <Typography.Text code>{sq.sql}</Typography.Text>
                                    </Space>
                                    <Space direction={'horizontal'}>
                                        <Button icon={<EditOutlined/>} onClick={() => edit(sq)}>{t('button-edit','Edit')}</Button>
                                        <Popconfirm
                                            title={t('confirm-delete-query','Delete Query?')}
                                            onConfirm={destroy}
                                        >
                                            <Button icon={<DeleteOutlined/>} danger/>
                                        </Popconfirm>
                                    </Space>
                                </List.Item>
                            )
                        }}
                    />
                )}
            </div>
        </div>
    )

    return (<>
        {children ? (
            <Button
                onClick={onClick}
                block={block}
                ghost
                type={'primary'}
            >
                {children}
                {filters.sql && <Badge count={1}/>}
            </Button>
        ) : (
            <Tooltip title={t('nav-advanced-search', 'Advanced Search')}>
                <Button
                    ghost
                    onClick={onClick}
                    style={style || {}}
                >
                    <BulbOutlined/>
                    {filters.sql && <Badge count={1}/>}
                </Button>
            </Tooltip>
        )}
        <Modal
            title={
                <>
                    <BulbOutlined/> {t('search-query-builder','Search Query Builder')}
                    <HelpPopover code={'search-query-builder-header'}/>
                </>
            }
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={1000}
        >
            <Query
                {...config}
                value={tree}
                onChange={onChange}
                renderBuilder={renderBuilder}
            />

        </Modal>
    </>);
}