import React, {useContext} from "react";
import {useParams, NavLink, Link} from "react-router-dom";
import {SessionContext} from "../../contexts/SessionContext";

const useOrgPath = ()=> {
    const {state} = useContext(SessionContext);
    const {currentOrg} = state || {};
    const {org: orgSlug} = useParams()

    return (to, forceOrg=null)=> {
        const slug = !window.customDomainOrganizationSlug && (forceOrg?.slug || currentOrg?.slug || orgSlug)
        return slug ? `/${slug}${to !== '/' ? to : ''}` : to
    }
}

export default ({to, children, ...rest}) => {
    const getPath = useOrgPath()

    return (
        <NavLink to={getPath(to)} {...rest}>{children}</NavLink>
    )
}

const OrgLink = ({to, children, ...rest}) => {
    const getPath = useOrgPath()

    return (
        <Link to={getPath(to)} {...rest}>{children}</Link>
    )
}


export { OrgLink, useOrgPath }