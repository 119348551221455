import React, {useState} from "react";
import {FormItem, Select} from "formik-antd";
import {useFormikContext} from "formik";
import User from "../helpers/User";

const getOption = user => (
    <Select.Option value={user.id} key={user.id} onClick={(e)=> e.preventDefault() && e.stopPropagation()}>
        <User user={user}/> ({user.email})
    </Select.Option>
)

export default ({selectedUser, fieldName, size, style, onChange, allowClear=false})=>{
    const { values, setFieldValue } = useFormikContext();

    const [options, setOptions] = useState(selectedUser ? [getOption(selectedUser)] : []);

    const [value, setValue] = useState(selectedUser?.id);

    const handleChange = val =>{
        if(fieldName) setFieldValue(fieldName, val);
        setValue(val)

        onChange && onChange(val)
    }

    const handleSearch = val =>{
        api('/api/memberships', {params:{q: val, tester: false}}).then(res => {
            const newOptions = res.data.map(m => getOption(m.user))

            setOptions(newOptions)
        })
    }

    return (
        <Select
            style={style}
            size={size || 'large'}
            showSearch
            value={value}
            placeholder={'Search Users...'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            allowClear={allowClear}
        >
            {options}
        </Select>
    )
}