import api from "../api";
import {message} from "antd";
import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import {SessionContext} from "../../contexts/SessionContext";
import {useNavigate} from "react-router-dom-v5-compat";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";
import {useSetCursorAsset} from "./useSetCurrentAsset";
import {useOrgPath} from "./OrgNavLink";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {state: appState, dispatch: appDispatch} = useContext(AppContext);

    const {dispatch, state: sessionState} = useContext(SessionContext);
    const assetGroupDispatch = useAssetGroupDispatch();
    const setCursorAsset = useSetCursorAsset()

    return (org, cb) => {
        console.log(org)

        if(appState.switchedFromUser){
            return alert('Cannot switch orgs when switched from user')
        }

        api.get(`/api/organizations/find?slug=${org.slug}&login=true`).then(res => {
            const newOrg = res.data.organization

            let landingPage;

            switch(newOrg.default_landing_page) {
                case 'explore':
                    landingPage = '/explore';
                    break;
                case 'homepage':
                    landingPage = ''
                    break;
            }

            if(newOrg.domain) {
                return window.location = `https://${newOrg.domain}${landingPage}`
            } else if(customDomainOrganizationSlug) {
                return window.location = `${Config.host}/${newOrg.slug}`
            } else {
                navigate(`/${newOrg.slug}${landingPage}`)
            }

            dispatch({type: 'set_org', org: newOrg, abilities: res.data.abilities});
            assetGroupDispatch({type:'reloadAll'})
            setCursorAsset(null)
            appDispatch({type:'setLastOrganization', org: newOrg})

            message.success(t(`message-welcome-back-to-org`,`Welcome back to {{title}}!`, {title: org.title}));

            cb && cb()
        })
    }
}