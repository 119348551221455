import {Alert, Card, message} from "antd";
import React, {useContext, useEffect, useState} from "react";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import setTitle from "../helpers/setTitle";
import {useLocation, useNavigate} from "react-router-dom-v5-compat";
import queryString from "query-string";
import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {SessionContext} from "../../contexts/SessionContext";
import {isMobile} from "device-detect";
import useAfterLogin, {setAfterLogin} from "../helpers/useAfterLogin";
import {useOrgPath} from "../helpers/OrgNavLink";

export default ({})=>{
    setTitle('Account Confirmation');

    const [confirming, setConfirming] = useState(true)
    const [error, setError] = useState(false)

    const {state, dispatch} = useContext(AppContext);
    const {currentUser} = state;

    const {state: sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const location = useLocation()
    const {confirmation_token} = queryString.parse(location.search)

    const navigate = useNavigate();

    const getPath = useOrgPath()

    useEffect(()=>{
        api.post(`/api/confirm_account`, {confirmation_token}).then(res => {
            setConfirming(false)
            console.log(res)

            message.success(`Email confirmed!`);

            if(!currentUser) {
                setAfterLogin('/organizations/new')

                navigate('/login')
                return
            }

            if(res.data.user) {
                dispatch({
                    type:'user_updated',
                    user: res.data.user,
                });
            }

            const org = res.data.organization

            if(org) {
                if(org.id != currentOrg?.id) {
                    api.get(`/api/organizations/${org.id}/abilities`).then(res => {
                        sessionDispatch({type: 'set_org', org: org, abilities: res.data});
                        message.success(`Welcome to ${org.title}!`);
                        navigate(getPath(`/explore#tour`))
                    });
                } else {
                    // From email change confirmations:
                    navigate(getPath(`/explore`))
                }

            } else {
                navigate('/organizations/new')
            }
        }).catch(err => {
            setConfirming(false)
            setError(err)
        })

    }, [confirmation_token])

    return (
        <Card style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
            {confirming && (
                <>
                    <LoadingOutlined/> Confirming your account...
                </>
            )}

            {error && (
                <Alert type={'error'} message={'This confirmation link is no longer valid'} showIcon action={<><a href={'/'}>Back</a></>}/>
            )}
        </Card>
    )
}