import React, {useContext, useEffect, useRef, useState} from "react";

import _ from 'lodash';

import {Alert, Card, Button, message, Upload, Divider, Popconfirm, Modal, Space, Typography} from 'antd';

import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {Formik} from "formik";
import * as Yup from "yup";
import {Form, FormItem, Input, SubmitButton} from "formik-antd";
import {
    PoweroffOutlined,
    LoadingOutlined,
    PlusOutlined,
    LockOutlined,
    ApiOutlined,
    CheckOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import HelpPopover from "../HelpPopover";
import {SessionContext} from "../../contexts/SessionContext";
import {isMobile} from "device-detect";
import setTitle from "../helpers/setTitle";
import useCurrentUser from "../helpers/useCurrentUser";

import QRCode from 'qrcode.react'
import {useTranslation} from "react-i18next";
import VerticalSpace from "~/components/helpers/VerticalSpace";

export default () => {
    const {t} = useTranslation();
    setTitle(t('security','Security'))

    const {dispatch} = useContext(AppContext)

    const currentUser = useCurrentUser()

    const [modalVisible, setModalVisible] = useState()
    const [secretModalVisible, setSecretModalVisible] = useState()
    const [disableModalVisible, setDisableModalVisible] = useState()

    const [otpUri, setOtpUri] = useState()
    const [otpSecret, setOtpSecret] = useState()
    const [backupCodes, setBackupCodes] = useState()

    useEffect(()=>{
        if(!modalVisible) return;

        api('/api/profile/otp_uri').then(res => {
            setOtpUri(res.data.uri)
            setOtpSecret(res.data.secret)
        })
    }, [modalVisible])

    if(!currentUser) return <></>

    return (
        (<Card title={<><LockOutlined/> {t('security-settings','Security Settings')}</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto', backgroundColor:'white'}}>
            {currentUser.otp_required_for_login ? (
                <Space direction={'vertical'} style={{width:'100%'}}>
                    <Alert showIcon type={'success'} message={t('2fa-enabled','2FA Enabled')}/>

                    <Button onClick={()=> setDisableModalVisible(true)}>{t('button-disable-2fa','Disable 2FA')}...</Button>
                </Space>
            ) : (
                <Button onClick={()=> setModalVisible(true)} block type={'primary'} ghost>{t('button-enable-2fa','Enable 2FA')}...</Button>
            )}
            {backupCodes && (
                <Card title={<>{t('recovery-codes','Recovery Codes')}</>} style={{margin:'1em 0'}}>
                    <p>
                        <strong>{t('recovery-code-note1','Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.')}</strong>
                        <br/>
                        {t('recover-code-note2','Copy and save your recovery codes now -- they will not be shown again.')}
                    </p>

                    <Typography.Paragraph copyable={{text: backupCodes.join('\n')}} code>{backupCodes.join(' ')}</Typography.Paragraph>
                </Card>
            )}
            <Modal
                title={<>{t('enable-2fa','Enable 2FA')}</>}
                open={modalVisible}
                onCancel={()=> setModalVisible(false)}
                footer={null}
            >
                <Formik
                    initialValues={{}}
                    onSubmit={(values, actions)=>{
                        api.post('/api/profile/enable_otp', values).then(res => {
                            setBackupCodes(res.data.backup_codes)
                            message.success(t('message-2fa-enabled','2FA Enabled'))
                            setModalVisible(false)
                            dispatch({type:'user_updated', user: res.data})
                            actions.setSubmitting(false)

                        }).catch(e => {
                            message.error(t('error-code-or-password-incorrect','Code or password incorrect.'))
                            actions.resetForm()
                            actions.setSubmitting(false)
                        })
                    }}
                >
                    {({submitForm, isSubmitting})=> (
                        <Form>
                            <VerticalSpace>
                                {otpUri && (
                                    <QRCode value={otpUri}/>
                                )}

                                <p>
                                    <strong>{t('otp-enter-code', 'Enter the six-digit code from the application')}</strong>
                                    <br/>
                                    {t('otp-after-scanning', 'After scanning the barcode image, the app will display a six-digit code that you can enter below.')}
                                    <br/>
                                    {t('otp-enter-code-instead', 'If you can’t use a barcode, enter this text code instead:')} <a onClick={() => setSecretModalVisible(true)}>{t('view-code', 'View Code')}</a>.
                                </p>

                                <Modal open={secretModalVisible} onCancel={() => setSecretModalVisible(false)} footer={null}>
                                    <Typography.Text copyable code>{otpSecret}</Typography.Text>
                                </Modal>

                                <label>{t('code', 'Code')}:</label>
                                <Input name={'otp_attempt'} placeholder={'123456'} autoComplete={'one-time-code'}/>

                                <label>{t('current-password', 'Current Password')}:</label>
                                <Input type='password' name={'password'} placeholder={t('enter-current-password', 'Enter Your Current Password')}/>

                                <Button onClick={submitForm} block type={'primary'} ghost icon={<CheckOutlined/>} loading={isSubmitting}>{t('button-enable', 'Enable')}</Button>
                            </VerticalSpace>
                        </Form>
                    )}
                </Formik>

            </Modal>
            <Modal
                title={<>Disable 2FA</>}
                open={disableModalVisible}
                onCancel={()=> setDisableModalVisible(false)}
                footer={null}
            >
                <Formik
                    initialValues={{}}
                    onSubmit={(values, actions)=>{
                        api.post('/api/profile/disable_otp', values).then(res => {
                            message.success(t('message-2fa-disabled','2FA Disabled'))
                            setDisableModalVisible(false)
                            dispatch({type:'user_updated', user: res.data})
                            actions.setSubmitting(false)

                        }).catch(e => {
                            message.error(t('error-code-or-password-incorrect','Code or password incorrect.'))
                            actions.resetForm()
                            actions.setSubmitting(false)
                        })
                    }}
                >
                    {({submitForm, isSubmitting})=> (
                        <Form layout={'vertical'}>
                            <Space direction={'vertical'} style={{width:'100%'}}>
                                <p>
                                    <strong>{t('enter-code-to-disable-2fa','Enter the six-digit code from the application and your current password to disable 2FA.')}</strong>
                                </p>

                                <FormItem name={'otp_attempt'} required>
                                    <Input name={'otp_attempt'} placeholder={'123456'} autoComplete={'one-time-code'} required/>
                                </FormItem>

                                <FormItem name={'password'} required>
                                    <Input type='password' name={'password'} placeholder={t('placeholder-current-password','Current Password')} required/>
                                </FormItem>

                                <Button onClick={submitForm} block type={'primary'} ghost loading={isSubmitting}>{t('button-disable-2fa','Disable 2FA')}</Button>
                            </Space>
                        </Form>
                    )}
                </Formik>

            </Modal>
        </Card>)
    );
}
