import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom-v5-compat";

import {Tooltip, Table, Button, message, Card, Space, Alert, Tag} from 'antd';
import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import Invites from './Invites'
import setTitle from "../helpers/setTitle";
import {BuildOutlined, CheckOutlined, PlusOutlined, UserSwitchOutlined} from "@ant-design/icons";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import {SessionContext} from "../../contexts/SessionContext";
import Search from "antd/lib/input/Search";
import useOrgSwitcher from "../helpers/useOrgSwitcher";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import LegalFooter from "../widgets/LegalFooter";
import {UnconfirmedAccount} from "./Account";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('your-organizations','Your Organizations'));

    const {state: appState} = useContext(AppContext);
    const {currentUser} = appState;

    const {dispatch, state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const switchOrgs = useOrgSwitcher()

    const Switcher = ({org}) => {
        const [loading, setLoading] = useState()
        return (
            (currentOrg?.id === org.id ?
                    <div>
                        <CheckOutlined/> {t('current','Current')}
                    </div> :
                    <Button
                        loading={loading}
                        id={`switch-to-organization-${org.id}`}
                        icon={<UserSwitchOutlined/>}
                        onClick={() => {
                            setLoading(true)
                            switchOrgs(org, ()=> setLoading(false))
                        }}
                        tabIndex={0}
                    >
                        {t('button-go','Go')}
                    </Button>
            )
        );
    }

    const columns = [
        {
            title: t('switch','Switch'),
            key: 'action',
            width: 100,
            render: org => <Switcher org={org}/>
        },
        {
            title: t('title','Title'),
            dataIndex: 'title',
            sorter: true,
            render: (title, org)=> (
                <>
                    {org.logo_url && <img src={org.logo_url} style={{height:30, backgroundColor:'#000'}}/>} <Tooltip title={`ID: ${org.id}`}>{title}</Tooltip>
                </>
            )
        },
        {
            title: t('Created','Created'),
            sorter: (a,b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: org => <TimeAgo date={org.created_at}/>,
            shouldCellUpdate: () => false,
        },
        // {
        //     title: 'By',
        //     render: org => <User user={org.user}/>,
        // },
        // {
        //     title: 'Assets',
        //     dataIndex: 'assets_count',
        //     render: count => n(count),
        //     sorter: true,
        // },
        {
            title: t('your-role','Your Role'),
            dataIndex: 'current_role_level_name',
            render: role => t(role.toLowerCase().replace(/ /, '-'), role)
        },
        {
            title: t('last-login','Last Login'),
            render: org => org.last_login_at && <TimeAgo date={org.last_login_at}/>,
        },
        {
            title: t('plan','Plan'),
            render: org => (
                <>
                    {t(org.stripe_product_code, org.stripe_product_code_name?.toProperCase())}
                </>
            )
        },
        {
            title: t('status','Status'),
            render: org => (
                <>
                    {org.free ? (
                        <Tag color={'green'}>{t('free','Free')}</Tag>
                    ) : (
                        <Tag color={org.stripe_status == 'active' || org.stripe_status == 'trialing' ? 'green' : 'red'}>
                            {t(org.stripe_status,org.stripe_status?.toProperCase())}
                        </Tag>
                    )}
                </>
            )
        },
    ];

    const [q, setQ] = useState()
    const onSearch = _.debounce((value) => {
        setQ(value);
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);

        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get('/api/profile/organizations', { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(handleTableChange, [q]);

    if(!currentUser) return <></>

    return (
        <main role={'main'} tabIndex={0}>
            <Space direction={'vertical'} style={{width: '100%', padding: '1em'}} size={'middle'}>
                <UnconfirmedAccount/>

                <Card
                    bodyStyle={{padding: 0}}
                    title={
                        <>
                            <BuildOutlined/> {t('organization-memberships', 'Organization Memberships')}:
                            <div style={{float: 'right'}}>
                                <Search
                                    autoFocus={isBrowser}
                                    placeholder={t('placeholder-search', 'Search...')}
                                    onSearch={onSearch}
                                    style={{width: 200, marginRight: '1em'}}
                                    size={'medium'}
                                    allowClear
                                    loading={loading}
                                    onChange={onChange}
                                />

                                {isMobile() && <p/>}

                                {(currentUser.can_create_orgs || currentUser.super_admin) && (
                                    <Link to={'/organizations/new'}>
                                        <Button
                                            type={'primary'}
                                            icon={<PlusOutlined/>}
                                            id={'create-organization-btn'}
                                        >
                                            {t('button-create-new-organization', 'Create New Organization')}
                                        </Button>
                                    </Link>
                                ) || (
                                    <Link to={'/trial-sign-up'}>
                                        <Button
                                            type={'primary'}
                                            icon={<PlusOutlined/>}
                                            id={'create-trial-organization-btn'}
                                        >
                                            {t('button-request-new-trial-organization', 'Request New Trial Organization')}
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </>
                    }
                >
                    <Table
                        size={'small'}
                        scroll={{x: true}}
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </Card>
                <Invites reloadOrgs={handleTableChange}/>
            </Space>
        </main>
    );
}
