import {useEffect, useRef} from "react";
import ViewerJS from "viewerjs";

export default ({asset, ref})=> {
    const viewer = useRef()

    const openViewer = ()=> {
        console.log('openViewer', asset, viewer.current)
        if(!viewer.current) {
            const el = document.createElement('img')
            el.src = asset.full_url || asset.permalink_url || asset.small_url

            viewer.current = new ViewerJS(el, {
                title: false,
                navbar: false,
                rotatable: false,
                transition: false,
                toolbar: {
                    flip: false,
                    next: false,
                    prev: false,
                    zoomIn: 4,
                    zoomOut: 4,
                    oneToOne: 4,
                    play: {
                        show: 4,
                        size: 'large',
                    },
                },
                viewed({target: {viewer}}) {
                    // find ratio of screen size to image size
                    viewer.zoomTo(window.outerHeight / asset.height);
                    console.log(viewer)
                },
                hidden() {
                    ref?.current?.focus();
                }
            })
        }
        viewer.current?.show()
    }

    useEffect(()=>{
        if(asset?.id) viewer.current = null
    }, [asset?.id])

    return openViewer
}