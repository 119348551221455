import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";

import {Table, Button, message, Tooltip, Popconfirm, Card} from 'antd';
import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import moment from "moment";
import {CheckOutlined, UserAddOutlined} from "@ant-design/icons";
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";

export default ({reloadOrgs}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const Actions = ({invite})=>{
        const [accepting, setAccepting] = useState()

        return (
            <Popconfirm
                title={t(`confirm-accept-invite`,`Accept the invite to the {{title}} Organization?`, {title: invite.organization.title})}
                onConfirm={() => {
                    setAccepting(true)
                    api.post(`/api/profile/invites/${invite.id}/accept`).then(() => {
                        setAccepting(false)
                        message.success(t(`message-accepted-invite`,`Accepted {{title}} invite.`, {title: invite.organization.title}));
                        handleTableChange();
                        reloadOrgs();
                    }).catch((err) => message.error(t('error-server-error','Server Error')))
                }}
            >
                <Button icon={<CheckOutlined/>} loading={accepting}>{t('button-accept','Accept')}</Button>
            </Popconfirm>
        );
    }

    const columns = [
        {
            title: t('organization','Organization'),
            key: 'organization',
            sorter: true,
            render: invite => invite.organization.title
        },
        {
            title: t('sent-by','Sent By'),
            sorter: true,
            render: invite => invite.user.email
        },
        {
            title: t('sent','Sent'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => {
                return <Tooltip title={moment(created_at).format('LLLL')}><span>{moment(created_at).fromNow()}</span></Tooltip>;
            }
        },
        {
            title: t('note','Note'),
            dataIndex: 'note',
        },
        {
            title: t('actions','Actions'),
            key: 'actions',
            render: invite => <Actions invite={invite}/>
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);
        
        const params = {
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get('/api/profile/invites', { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(handleTableChange, []);

    return (
        <Card
            bodyStyle={{padding:0}}
            title={<><UserAddOutlined/> {t('pending-invites','Pending Invites')}:</>}
        >
            <Table
                scroll={{x:true}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                locale={{emptyText:t('none-yet','None yet.')}}
            />
        </Card>
    );
}
