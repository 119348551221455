import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import ApartmentOutlined from "@ant-design/icons/lib/icons/ApartmentOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newTagSuggester, q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/tag_suggesters'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-tag-suggester-deleted','Tag Suggester Deleted.'));
            handleTableChange();
        });
    }

    const Name = ({name, tagSuggester}) => {
        const [value, setValue] = useState(tagSuggester.name || '');

        const onChange = (newValue)=>{
            setValue(newValue);
            api.put(`${apiPath}/${tagSuggester.id}`, {tag_suggester:{name: newValue}}).then(res => {
                message.success(t('message-name-updated','Name updated.'))
            })
        }

        return (
            <Typography.Paragraph
                // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                editable={tagSuggester.editable && {onChange}}
            >
                {value}
            </Typography.Paragraph>
        )
    }

    const Tags = ({name, tagSuggester})=> {
        const [tagNames, setTagNames] = useState(tagSuggester.tag_names)

        const onUpdateExisting = (value)=>{
            setTagNames(value)
            api.put(`${apiPath}/${tagSuggester.id}`, {tag_suggester:{tag_names: value}}).then(res => {
                message.success(t('message-tags-updated','Tags updated.'))
            })
        }

        return (
            <Select
                mode='tags'
                bordered={false}
                onChange={onUpdateExisting}
                value={tagNames}
                style={{width:'100%'}}
                placeholder={t('placeholder-add-tagsAdd Tags...')}
            />
        )
    }

    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: (name, tagSuggester) => <Name name={name} tagSuggester={tagSuggester}/>
        },
        {
            title: t('tags','Tags'),
            dataIndex: 'tag_names',
            sorter: true,
            render: (name, tagSuggester) => <Tags name={name} tagSuggester={tagSuggester}/>
        },
        {
            key: 'action',
            width: 100,
            render: (tagSuggester) => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'TagSuggester'}>
                                <Space direction={'horizontal'}>
                                    <Popconfirm
                                        title={t('confirm-archive-tag-suggester','Archive Tag Suggester?')}
                                        onConfirm={() => destroy(tagSuggester.id)}
                                    >
                                        <Button size={'middle'}>
                                            <DeleteOutlined/>
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination, filters, sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newTagSuggester, q]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        console.log(item);
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    const getIcon = (value)=> {
        return {
            visible: <EyeOutlined/>,
            searchable: <SearchOutlined/>,
            blocked: <CloseCircleOutlined/>
        }[value];
    }

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
