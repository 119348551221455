import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    assetEdits: 0
}

const EditAssetsStateContext = createContext(initialState);
const EditAssetsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('EditAssetsContext reducer:', action.type, action);

    switch (action.type) {
        case 'increment':
            state.assetEdits++
            return {...state};

        default:
            return state;
    }
}

const EditAssetsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <EditAssetsStateContext.Provider value={state}>
            <EditAssetsDispatchContext.Provider value={dispatch}>
                {children}
            </EditAssetsDispatchContext.Provider>
        </EditAssetsStateContext.Provider>
    )
}

function useEditAssetsState() {
    const context = useContext(EditAssetsStateContext)
    if (context === undefined) {
        throw new Error('useAssetsState must be used within a AssetsProvider')
    }
    return context
}

function useEditAssetsDispatch() {
    const context = useContext(EditAssetsDispatchContext)
    if (context === undefined) {
        throw new Error('useAssetsDispatch must be used within a AssetsProvider')
    }
    return context
}

export {
    EditAssetsProvider,
    useEditAssetsState,
    useEditAssetsDispatch
};
