import React, {useContext, useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";

import {Card, Carousel, Col, Divider, message, Result, Row, Tabs, Tooltip, Typography, Button, Skeleton} from 'antd';
import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import setTitle from "../helpers/setTitle";
import Content from "../Content";
import useOrgSlug from "../helpers/useOrgSlug";
import {SessionContext} from "../../contexts/SessionContext";
import OrgLogo from "../widgets/OrgLogo";
import Collection from "../explore/Collection";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import LoginForm from "../session/LoginForm";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {BulbOutlined, LoadingOutlined, LoginOutlined, UserAddOutlined, QuestionOutlined} from "@ant-design/icons";
import VerticalSpace from "../helpers/VerticalSpace";
import DragAndDrop from "../helpers/DragAndDrop";
import {useTranslation} from "react-i18next";
import {Parser} from "html-to-react";
import {markdown} from "markdown";

export default () => {
    const {t} = useTranslation();
    setTitle(t('home','Home'));

    const org = useCurrentOrg();

    const {state: appState} = useContext(AppContext);
    const {currentUser} = appState;

    const {org: orgSlug} = useParams()
    const navigate = useNavigate();

    const [featuredCollectionsLoading, setFeaturedCollectionsLoading] = useState()
    const [featuredCollections, setFeaturedCollections] = useState()
    useEffect(()=>{
        if(!org?.role_level) return;

        setFeaturedCollectionsLoading(true)
        api(`/api/organizations/${org?.id}/featured_collections`).then(res => {
            setFeaturedCollectionsLoading(false)
            setFeaturedCollections(res.data)
        }).catch(()=>{
            setFeaturedCollectionsLoading(false)
        })
    }, [org?.id]);

    if(!customDomainOrganizationSlug && !orgSlug && currentUser?.last_organization) {
        navigate(`/${currentUser.last_organization.slug}`)
        return <></>
    }
    const headerHeight = document.getElementById('header')?.clientHeight

    const floatHeadline = !isMobile() && org?.banner_assets?.length > 0;
    const headlineStyle = floatHeadline ? {position: 'absolute', top: headerHeight, left: 0, zIndex: 10, width:'100vw'} : { minHeight: '1em'}
    const headlineTextStyle = floatHeadline || isMobile() ? {color: 'white', textShadow: '0px 4px 3px rgba(0,0,0,0.75), 0px 8px 13px rgba(0,0,0,0.5), 0px 18px 23px rgba(0,0,0,0.5)'} : {}

    if(isMobile()) headlineTextStyle.fontSize = 25;

    const Logo = (
        <div style={{maxWidth: isMobile() ? 200 : 400, margin: '0 auto'}}>
            <OrgLogo org={org}/>
        </div>
    )

    const Slides = (
        <>
            {org?.banner_assets?.length > 0 && (
                <div style={{width: '100%'}}>
                    <Carousel autoplay style={{width: '100%', backgroundColor: 'black'}}>
                        {org.banner_assets.map((asset, i) => (
                            <div key={i} style={{textAlign: 'center'}}>
                                <img src={asset.permalink_url} style={{margin: 'auto auto', width: '100%'}} alt={asset.alt_text || asset.description}/>
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}
        </>
    )

    const Copy = (
        <>
            {!!org?.public_headline?.length && (
                <Typography.Title style={{textAlign: 'center', ...headlineTextStyle}}>{org.public_headline}</Typography.Title>
            )}

            {!!org?.public_text?.length && (
                <Typography.Title level={isMobile() ? 2 : 5} style={{margin: '1em auto', textAlign: 'center', maxWidth: 800, ...headlineTextStyle}} id={'public-text'}>
                    {(new Parser).parse(markdown.toHTML(org.public_text))}
                </Typography.Title>
            )}
        </>
    )

    return (
        <DragAndDrop>
            <main role={'main'} aria-label={t('organization-home-label', 'Organization homepage content')} style={{backgroundColor: org?.header_background_color || '464545', paddingBottom: '2em'}} tabIndex={0}>
                {isMobile() ? (
                    <>
                        {Logo}
                        {Slides}
                        <div style={headlineStyle} id={'headline'}>
                            {Copy}
                        </div>
                    </>
                ) : (
                    <>
                        {Slides}
                        <div style={{paddingTop: '2em', ...headlineStyle}} id={'headline'}>
                            {Logo}
                            {Copy}
                        </div>
                    </>
                )}

                {currentUser && (
                    <Skeleton active loading={featuredCollectionsLoading}>
                        {featuredCollections?.length > 0 && (
                            <>
                                <BrandedDivider>{t('featured-content', 'Featured Content')}</BrandedDivider>

                                <Row style={{marginBottom: '1em', padding: 1, textAlign: 'center', marginLeft: '1em'}} gutter={[8, 8]}>
                                    {featuredCollections.map((c, i) => {
                                        return (
                                            <Col key={i} lg={6} xs={24}>
                                                <Collection c={c}/>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </>
                        )}
                    </Skeleton>
                )}

                {!org?.role_level && (
                    <>
                        <Divider style={{color: 'white'}}>{t('public-library', 'Public Library')}</Divider>
                        <PublicLibrary/>
                    </>
                )}

                {!org && !currentUser && !window.customDomainOrganizationSlug && (
                    <VerticalSpace>
                        <Row gutter={16} justify="center" style={{padding: '0 1em'}}>
                            <Col style={{textAlign: 'center'}}>
                                <img alt='large-logo' src={ImageUrls.stackedLogo} style={{width: isMobile() ? '100%' : 450, marginBottom: '1em'}}/>
                                {/*<Typography.Title level={1}>*/}
                                {/*    {t('welcome-to-mediagraph','Welcome to Mediagraph')}*/}
                                {/*</Typography.Title>*/}
                                <Typography.Title level={5} type="secondary">
                                    {t('welcome-tag-line', 'A scalable B2B, cloud-based digital asset management platform that allows organizations to acquire, organize, store and share media files.')}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Divider style={{marginTop: 0}}/>
                        <Row gutter={[16, 16]} justify="center" style={{padding: '0 1em'}}>
                            <Col lg={8} xs={24}>
                                <Button
                                    type={'link'}
                                    onClick={() => window.location = 'https://www.mediagraph.io'}
                                    onKeyPress={e => {
                                        if (e.nativeEvent.keyCode === 13) window.location = 'https://www.mediagraph.io'
                                    }}
                                    tabIndex={0}
                                    className={'home-card-link'}
                                    block
                                    style={{height: 180}}
                                >
                                    <Card hoverable bodyStyle={{padding: '4em', textAlign: 'center'}}>
                                        <Typography.Title level={3}>
                                            <BulbOutlined/> {t('learn-about-mediagraph', 'Learn about Mediagraph')}
                                        </Typography.Title>
                                    </Card>
                                </Button>
                            </Col>

                            <Col lg={8} xs={24}>
                                <Button
                                    type='link'
                                    onClick={() => navigate('/trial-sign-up')}
                                    tabIndex={0}
                                    className={'home-card-link'}
                                    block
                                    style={{height: 180}}
                                >
                                    <Card hoverable bodyStyle={{padding: '4em', textAlign: 'center'}}>
                                        <Typography.Title level={3}>
                                            <UserAddOutlined/> {t('start-a-trial-account', 'Start a Trial Account')}
                                        </Typography.Title>
                                    </Card>
                                </Button>
                            </Col>
                        </Row>

                        <Row justify="center" style={{marginTop: '1em', padding: '0 1em'}}>
                            <Col lg={8} xs={24}>
                                <Card
                                    title={<strong><LoginOutlined/> {t('sign-into-my-existing-account', 'Sign into my Existing Account')}</strong>}
                                >
                                    <LoginForm autoFocus={false}/>
                                </Card>
                            </Col>
                        </Row>
                    </VerticalSpace>
                )}
            </main>
        </DragAndDrop>
    );
}

const PublicLibrary = () => {
    const org = useCurrentOrg();
    const [publicCollectionsLoading, setPublicCollectionsLoading] = useState()
    const [publicCollections, setPublicCollections] = useState()

    useEffect(()=>{
        if(!org) return;
        setPublicCollectionsLoading(true)

        api(`/api/organizations/${org.id}/public_collections`).then(res => {
            setPublicCollectionsLoading(false)
            setPublicCollections(res.data)
        })
    },[org?.id]);

    return (
        <Skeleton active loading={publicCollectionsLoading}>
            <Row style={{marginBottom: '1em', textAlign: 'center', marginLeft: '1em'}} gutter={[8, 8]}>
                {publicCollections?.map((c, i) => {
                    return (
                        <Col key={i} lg={6} xs={24}>
                            <Collection c={c}/>
                        </Col>
                    );
                })}
            </Row>
        </Skeleton>
    )
}

const PublicLibraryHome = () => {
    const {t} = useTranslation();
    setTitle(t('public-library', 'Public Library'))

    return (
        <div style={{marginTop: '2em'}}>
            <DragAndDrop>
                <PublicLibrary/>
            </DragAndDrop>
            <Divider/>
        </div>
    )
}

export {PublicLibrary, PublicLibraryHome}

const NoMatch = ()=> {
    const {t} = useTranslation();
    setTitle(t('404-not-found-title','404 Not Found'));

    const navigate = useNavigate()

    return (
        <>
            <Result
                title="404"
                subTitle={t('404-not-found-note','Sorry, we could not find anything for this URL.')}
                extra={<Button type="primary" onClick={()=> navigate('/')}>Back Home</Button>}
                icon={<QuestionOutlined/>}
            />
        </>
    );
}

export {NoMatch}

const BrandedDivider = ({children}) => {
    const currentOrg = useCurrentOrg()
    const color = currentOrg?.header_text_color

    return (
        <Divider style={{color, borderColor: color}}>{children}</Divider>
    )

}