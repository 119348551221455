import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import api from "../api";
import {useParams} from "react-router-dom";

export default () => {

    const {dispatch} = useContext(AppContext);

    const {guestCode} = useParams()

    return  cb =>{
        api.post(`/api/login_as_guest?guest_code=${guestCode || ''}`).then(res => {
            dispatch({
                type:'login',
                user: res.data,
                jwt: res.headers.authorization.replace('Bearer ','')
            });

            setTimeout(()=> cb && cb(), 100)
        })
    }

}