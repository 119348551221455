import React, {useEffect, useRef, useState} from "react";

import {
    Table,
    Button,
    message,
    Space,
    Popconfirm,
    Popover,
    Modal,
    Card,
    Typography, Skeleton, Dropdown, Menu, Tooltip, Flex
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    CloudUploadOutlined,
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, ScanOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import ContributionsForm from "./ContributionsForm";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {NavLink} from "react-router-dom";
import {StorageFolderIcon} from "../helpers/icons";
import OrgNavLink, {useOrgPath} from "../helpers/OrgNavLink";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import QRCode from "qrcode.react";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import Search from "antd/lib/input/Search";

export default ({newContribution, q, onLoaded}) => {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/contributions'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-contribution-deleted','Contribution Deleted.'));
            handleTableChange();
        });
    }

    const getPath = useOrgPath()

    const Actions = ({contribution}) => {
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${contribution.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${contribution.id}`;

        const [currentContribution, setCurrentContribution] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`${apiPath}/${id}`).then(res => {
                setCurrentContribution(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(contribution.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        return (
            <>
                <Space size={5}>
                    {contribution.editable && (
                        <Space direction={'horizontal'}>
                            <Button onClick={edit} size={'middle'}>
                                <EditOutlined/>
                                {t('button-edit','Edit')}

                                <ContributionsForm
                                    id={contribution.id}
                                    visible={formVisible}
                                    onSave={onSave}
                                    onClose={onClose}
                                />
                            </Button>

                            <Popconfirm
                                title={t('confirm-archive-contribution','Archive Contribution?')}
                                onConfirm={() => destroy(contribution.id)}
                            >
                                <Button size={'middle'}>
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>

                        </Space>
                    )}
                </Space>
            </>
        );
    }

    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: (name, contribution)=> (
                <Tooltip title={`ID: ${contribution.id}`}>{name}</Tooltip>
            )
        },
        {
            title: t('enabled','Enabled').toProperCase(),
            dataIndex: 'enabled',
            width: 1,
            render: enabled => <EnabledDisabled enabled={enabled}/>,
            filters: [
                {text: t('enabled','Enabled'), value: true},
                {text: t('disabled','Disabled'), value: false},
            ],
        },
        {
            title: t('featured','Featured'),
            dataIndex: 'featured',
            width: 1,
            render: featured => <EnabledDisabled enabled={featured}/>,
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
        },
        {
            title: t('public','Public'),
            dataIndex: 'enable_public',
            width: 1,
            render: tf => <EnabledDisabled enabled={tf}/>,
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
        },
        {
            title: t('user-group','User Group'),
            dataIndex: 'user_groups.name',
            sorter: true,
            render: (name, contribution) => contribution.user_group?.name
        },
        {
            title: t('storage-folder','Storage Folder'),
            dataIndex: 'asset_groups.name',
            sorter: true,
            render: (name, contribution) => (
                <OrgNavLink
                    to={`/explore/folders/${contribution.storage_folder?.slug}`}
                    style={{color:'black'}}
                >
                    <StorageFolderIcon/> {contribution.storage_folder?.path_names?.join(' / ')}
                </OrgNavLink>
            )
        },
        {
            title: t('direct-link','Direct Link'),
            dataIndex: 'slug',
            sorter: true,
            render: (slug) => {
                const url = `https://${window.location.hostname}${getPath(`/contribute/${slug}`)}`
                return !!slug?.length && <Typography.Link copyable={{text:url}}><Tooltip title={url}>{slug}</Tooltip></Typography.Link>
            }
        },
        {
            title: t('quick-upload','Quick Upload'),
            dataIndex: 'enable_quick_upload',
            sorter: true,
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (_,c) => <QuickUploadCell contribution={c}/>
        },
        {
            title: t('upload-sessions','Upload Sessions'),
            dataIndex: 'uploads_count',
            sorter: true,
            render: (_, contribution) => <UploadsModal contribution={contribution}/>
        },
        {
            title: t('invited-users','Invited Users'),
            dataIndex: 'contribution_memberships_count',
            sorter: true,
        },
        {
            title: t('assets','Assets'),
            dataIndex: 'assets_count',
            sorter: true,
            render: (count, contribution) => (
                <OrgNavLink to={`/explore/contributions/${contribution.to_param}`} id={`contribution-assets-${contribution.id}`}>{count}</OrgNavLink>
            )
        },
        {
            key: 'action',
            width: 100,
            render: (contribution) => <Actions contribution={contribution}/>
        }
    ];

    const [settings, setSettings] = useState({})

    const handleTableChange = (newPagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newContribution, q]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Tag{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}

const QuickUploadCell = ({contribution}) => {
    const {t} = useTranslation();
    const getPath = useOrgPath()
    const url = `https://${window.location.hostname}${getPath(`/quick-upload/${contribution.slug}`)}`

    const [open, setOpen] = useState()

    if(!contribution.enable_quick_upload) return <></>

    return (
        <>
            <Button icon={<ScanOutlined/>} size='small' onClick={()=> setOpen(true)}>{t('view-qr','View QR')}</Button>

            <Modal
                size={'small'}
                open={open}
                onCancel={()=> setOpen(false)}
                footer={null}
                title={<h2>{t('quick-upload','Quick Upload')}: {contribution.name}</h2>}
                width={400}
            >
                <VerticalSpace>
                    <QRCode
                        value={url}
                        icon={ImageUrls.blackCircleLogo}
                        size={350}
                    />

                    <Typography.Text copyable={{tooltips: t('tooltip-copy-url','Copy URL'), text: url}}>{url}</Typography.Text>
                </VerticalSpace>
            </Modal>
        </>
    )
}

const UploadsModal = ({contribution})=>{
    const {t} = useTranslation();
    const [open, setOpen] = useState()

    const [loading, setLoading] = useState(false)

    const apiPath = `/api/contributions/${contribution.id}/uploads`

    const params = useRef({})
    const [q, setQ] = useState();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: {...params.current} }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(()=>{
        if(!open) return;

        handleTableChange({...settings.pagination, current:1} )
    }, [open, q, refresh])

    const columns = [
        {
            title: t('created','Created').toProperCase(),
            dataIndex: 'created_at',
            sorter: true,
            render: date => <TimeAgo date={date}/>,
        },
        {
            title: t('user','User').toProperCase(),
            render: upload => <User user={upload.user}/>,
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: (name, upload)=> (
                <Tooltip title={`ID: ${upload.id}`}>{name}</Tooltip>
            )
        },
        {
            title: t('description','Description'),
            render: (upload)=> (
                <Typography.Paragraph ellipsis={{expandable: 'collapsible', tooltip:t('view-full','View Full'), rows: 1}} style={{whiteSpace: 'pre-wrap'}}>{upload.note}</Typography.Paragraph>
            )
        },
        {
            title: t('assets','Assets'),
            dataIndex: 'current_assets_count',
            sorter: true,
            render: (count, upload) => (
                <OrgNavLink to={`/upload/${upload.guid}`} id={`upload-assets-${upload.id}`}>{count}</OrgNavLink>
            )
        },
    ]

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setQ(value);
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    return (
        <>
            <Button type={'link'} onClick={()=> setOpen(true)} id={`uploads-${contribution.id}`}>{contribution.uploads_count}</Button>

            <Modal
                open={open}
                onCancel={()=> setOpen(false)}
                footer={null}
                title={<><CloudUploadOutlined/> {contribution.name} {t('upload-sessions', 'Upload Sessions')}</>}
                width={'75vw'}
            >
                <VerticalSpace>
                    <Search
                        placeholder={t('search','Search...')}
                        onSearch={onSearch}
                        style={{width:'100%'}}
                        size={'medium'}
                        allowClear
                        loading={searchLoading}
                        autoFocus
                        onChange={onChange}
                    />

                    <Table
                        bordered
                        scroll={{x: true}}
                        size={'small'}
                        columns={columns}
                        rowKey={record => record.id}
                        dataSource={data}
                        pagination={{...pagination, showSizeChanger: true}}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </VerticalSpace>
            </Modal>
        </>
    )
}