import {Alert, Button, Popover, message, Popconfirm, Progress} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import React, {useState, useEffect, useRef, useContext} from "react";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import api from "../api";
import {useEditAssetsState} from "../../contexts/EditAssetsContext";
import useConsumer from "../../channels/consumer";
import mapLimit from "async/mapLimit";
import pluralize from "pluralize";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import {SessionContext} from "../../contexts/SessionContext";
import {useNavigate} from "react-router-dom-v5-compat";
import {isMobile} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useUploadsDispatch, useUploadsState} from "~/contexts/UploadsContext";

export default ({})=> {
    const navigate = useNavigate();

    const {currentUpload} = useAssetGroupState()

    const assetGroupDispatch = useAssetGroupDispatch();

    const [uploadAttrs, setUploadAttrs] = useState(currentUpload)

    useEffect(()=>{
      setUploadAttrs(currentUpload);
    }, [currentUpload?.id]);

    const {assetEdits}  = useEditAssetsState()

    const lastCheck = useRef()

    useEffect(()=> {
        if(!currentUpload || currentUpload.done || currentUpload.contribution.asset_group?.type === 'StorageFolder') return

        console.log('Upload Check Requirements')

        api(`/api/uploads/${currentUpload.id}/check_requirements`).then(res => {
            if(_.isEqual(lastCheck.current, res.data)) return;
            lastCheck.current = res.data;

            setUploadAttrs(res.data)
        })
    }, [assetEdits])

    const [loading, setLoading] = useState()

    const messageKey = `upload-done-progress-${currentUpload.id}`

    const onClick = ()=> {
        setLoading(true)
        api.put(`/api/uploads/${currentUpload.id}/set_done`).then(res => {
            message.open({
                key: messageKey,
                type: 'loading',
                content: <ProgressMessage count={res.data.total} progress={0}/>,
                duration: 0
            })

        })
    }

    const consumer = useConsumer();
    const sub = useRef();

    const cancelingUploadMessage = useRef()

    const getPath = useOrgPath()

    useEffect(()=> {
        if(!sub.current && currentUpload) {
            sub.current = consumer.subscriptions.create({channel: "UploadChannel", id: currentUpload.id}, {
                connected: ()=> {
                    console.log('UploadChannel connected')
                },
                disconnected: ()=> {
                    console.log('UploadChannel disconnected')
                },
                received: (data) => {
                    console.log('UploadChannel received:', data)

                    switch(data.type) {
                        case 'progress':
                            message.open({
                                key: messageKey,
                                type: 'loading',
                                content: <ProgressMessage count={data.total} progress={data.progress}/>,
                                duration: 0
                            })
                            return
                        case 'done':
                            setLoading(false)
                            navigate(getPath(`/explore`))

                            // HACK to avoid race condition in specs, since we're using Sidekiq inline
                            setTimeout(()=>{
                                message.success({
                                    key: messageKey,
                                    content: 'Your upload has been submitted!'
                                })
                            }, 100)

                            return

                        case 'cancelUploadStart':
                            cancelingUploadMessage.current = `canceling-upload-${data.id}`
                            return message.open( {type:'loading', content: 'Cancelling Upload...', duration: 0, key: cancelingUploadMessage.current})

                        case 'cancelUploadProgress':
                            // TODO: implement progress:
                            console.log('cancelUploadProgress', data)
                            return;

                        case 'cancelUploadDone':
                            message.success('Upload Canceled.');
                            assetGroupDispatch({type:'reloadContributionId', id: data.contribution_id})
                            navigate(getPath(`/explore`))
                            message.destroy(cancelingUploadMessage.current)
                            return

                    }
                }
            });
        }

        return ()=> sub.current?.unsubscribe()

    },[currentUpload?.id])

    if(currentUpload.done || currentUpload.contribution.asset_group?.type === 'StorageFolder') return <></>;

    const enableSetDone = uploadAttrs.all_requirements_met

    return (
        <Popover
            title={'Requirements'}
            placement={'bottom'}
            content={
                <>
                    {uploadAttrs.all_requirements_met && (
                        <Alert message={'All requirements met.'} showIcon type={'success'}/>
                    ) || (
                        <Alert
                            message={'Unmet requirements:'}
                            description={
                                <ul>
                                    {/*{!currentUpload.name && (*/}
                                    {/*    <li key={'name'}>Upload name blank</li>*/}
                                    {/*)}*/}

                                    {uploadAttrs.assets_count == 0 && (
                                        <li key={'assets'}>No Assets</li>
                                    )}

                                    {!uploadAttrs.tag_suggesters_met && (
                                        <li key={'tags'}>Tag Suggesters</li>
                                    )}

                                    {!uploadAttrs.rights_packages_met && (
                                        <li key={'rights'}>Rights Package Selection</li>
                                    )}

                                    {!uploadAttrs.creator_tags_met && (
                                        <li key={'creators'}>Creator Tags</li>
                                    )}
                                </ul>
                            }
                            type={'error'}
                            showIcon
                        />
                    )}
                </>
            }
        >
            <Popconfirm title={'Submit Uploaded Files?'} onConfirm={onClick} disabled={!enableSetDone}>
                <Button
                    disabled={!enableSetDone}
                    style={{marginLeft:'1em'}}
                    size={'small'}
                    ghost
                    type={'primary'}
                    loading={loading}
                    id={'set-upload-done-btn'}
                    block={isMobile()}
                >
                    <CheckOutlined /> Submit
                </Button>
            </Popconfirm>
        </Popover>
    )
}

const ProgressMessage = ({count, progress})=>{
    return (
        <>
            Submitting {count} {pluralize('file', count)}...
            {progress < 1 && (
                <>
                    <br/>
                    <Progress percent={Math.round(progress * 100)} status={'active'}/>
                </>
            )}
        </>
    )
}
