import React, {useContext, useState} from "react";
import {Button, Divider, Popover, Radio, message} from "antd";
import {SortIcon} from "../helpers/icons";
import {useFilters} from "../helpers/useFilters";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import CameraOutlined from "@ant-design/icons/lib/icons/CameraOutlined";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import StarOutlined from "@ant-design/icons/lib/icons/StarOutlined";
import BulbOutlined from "@ant-design/icons/lib/icons/BulbOutlined";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import api from "../api";

import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import useCurrentOrg from "@/components/helpers/useCurrentOrg";

const useSortOrder = ()=>{
    const {currentAssetGroup} = useAssetGroupState();
    const {filters} = useFilters();
    const {sort, order} = filters

    const org = useCurrentOrg();

    const defaultSortOrder = org?.default_sort_order || 'created_at-desc'

    let value;
    if(sort == 'custom') {
        value = currentAssetGroup?.sortable ? 'custom' : defaultSortOrder
    } else if(!sort) {
        value = currentAssetGroup?.sort_order || defaultSortOrder
    } else {
        value = `${sort}-${order}`
    }

    return value
}

export {useSortOrder};

export default ({})=> {
    const {t} = useTranslation();
    const {currentAssetGroup} = useAssetGroupState();
    const assetGroupDispatch = useAssetGroupDispatch()

    const {setFilters} = useFilters();

    const value = useSortOrder()

    const org = useCurrentOrg()
    const defaultSortOrder = org?.default_sort_order || 'created_at-desc'

    const notDefault = currentAssetGroup &&
        ((currentAssetGroup.sort_order && currentAssetGroup.sort_order != value) ||
        (!currentAssetGroup.sort_order && value != defaultSortOrder))

    const changeSortMode = (e)=>{
        const [sort, order] = e.target.value.split('-')
        setFilters(false, {sort, order})
    }

    const [saving, setSaving] = useState()
    const save = ()=> {
        setSaving(true)

        const route = {
            'Collection': 'collections',
            'StorageFolder': 'storage_folders',
            'Lightbox': 'lightboxes'
        }[currentAssetGroup.type];

        const param = {
            'Collection': 'collection',
            'StorageFolder': 'storage_folder',
            'Lightbox': 'lightbox'
        }[currentAssetGroup.type];

        const data = {}
        data[param] = {sort_order: value}

        api.put(`/api/${route}/${currentAssetGroup.id}`, data).then(res => {
            setSaving(false)
            assetGroupDispatch({type:'updateCurrentAssetGroup', assetGroup: {sort_order: value}})
            message.success(t('message-default-sort-order-updated','Default sort order updated!'))
        })
    }

    return (
        <Popover
            id={'sort-order-popover'}
            trigger={['click', 'hover']}
            getPopupContainer={e => e.parentElement}
            overlayStyle={{minWidth:325}}
            title={
                <>
                    <SortIcon/> {t('sort-order','Sort Order')}
                    {currentAssetGroup?.editable && notDefault && (
                        <div style={{float:'right'}}>
                            <Button size='small' icon={<SaveOutlined/>} onClick={save} type={'primary'} ghost loading={saving}>{t('button-save','Save')}</Button>
                        </div>
                    )}
                </>
            }
            content={
                <>
                    <h4><CloudUploadOutlined/> {t('sort-uploaded-time','Uploaded Time')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'created_at-desc'}>
                            {t('newest-first','Newest First')}
                        </Radio>
                        <Radio value={'created_at-asc'}>
                            {t('oldest-first','Oldest First')}
                        </Radio>
                    </Radio.Group>
                    <Divider/>
                    <h4><EditOutlined/> {t('sort-edited','Edited')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'updated_at-desc'}>
                            {t('recent-edits-first','Recent Edits First')}
                        </Radio>
                        <Radio value={'updated_at-asc'}>
                            {t('oldest-edits-first','Oldest Edits First')}
                        </Radio>
                    </Radio.Group>
                    <Divider/>
                    <h4><CameraOutlined/> {t('sort-creation-date','Creation Date')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'captured_at-desc'}>
                            {t('newest-first','Newest First')}
                        </Radio>
                        <Radio value={'captured_at-asc'}>
                            {t('oldest-first','Oldest First')}
                        </Radio>
                    </Radio.Group>
                    <Divider/>
                    <h4><FileOutlined/> {t('sort-name-of-file','Name of File')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'filename-asc'}>
                            {t('a-z','A-Z')}
                        </Radio>
                        <Radio value={'filename-desc'}>
                            {t('z-a','Z-A')}
                        </Radio>
                    </Radio.Group>
                    <Divider/>
                    <h4><SaveOutlined/> {t('sort-size-of-file','Size of File')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'file_size-desc'}>
                            {t('largest-first','Largest First')}
                        </Radio>
                        <Radio value={'file_size-asc'}>
                            {t('smallest-first','Smallest First')}
                        </Radio>
                    </Radio.Group>
                    <Divider/>
                    <h4><StarOutlined/> {t('sort-rating','Rating')}:</h4>
                    <Radio.Group onChange={changeSortMode} value={value}>
                        <Radio value={'rating-desc'}>
                            {t('highest-first','Highest First')}
                        </Radio>
                        <Radio value={'rating-asc'}>
                            {t('lowest-first','Lowest First')}
                        </Radio>
                    </Radio.Group>

                    {currentAssetGroup?.sortable && (
                        <>
                            <Divider/>
                            <Radio.Group onChange={changeSortMode} value={value}>
                                <Radio value={'custom'}>
                                    <BulbOutlined/> {t('sort-custom','Custom')}
                                </Radio>
                            </Radio.Group>
                        </>
                    )}
                </>
            }
            placement={'bottomLeft'}
        >
            <Button size={'small'} type={'text'} id={'sort-order-btn'} aria-label={t('sort-menu', 'Sort Menu')}>
                <SortIcon/>
            </Button>
        </Popover>
    )
}