import React from 'react';

import { NavLink, withRouter } from "react-router-dom";

import { Layout } from 'antd';
import LegalFooter from "./widgets/LegalFooter";
import {css, Global as GlobalStyles} from "@emotion/react";
const { Content } = Layout;

const NakedLayout = withRouter(props => {
    return (
        <Layout style={{height:'100vh'}}>
            <GlobalStyles
                styles={css`
                    body { background-color: #f0f2f5; }
                `}
            />

            <Content
                style={{
                    padding: 24,
                    margin: 0,
                }}
            >
                {props.children}

                <LegalFooter/>
            </Content>
        </Layout>
    );
});

export default NakedLayout;
