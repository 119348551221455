import {Alert, Button, Empty, Modal, Skeleton, Tag, Tooltip} from "antd";
import AssetsTable from "../widgets/AssetsTable";
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import Asset from "./Asset";
import {SessionContext} from "../../contexts/SessionContext";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {areEqual, FixedSizeList, VariableSizeList} from "react-window";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {CloseCircleOutlined, LoadingOutlined, VerticalAlignBottomOutlined} from "@ant-design/icons";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import HelpPopover from "../HelpPopover";
import {useAssetsState} from "../../contexts/AssetsContext";
import {useUploadsState} from "../../contexts/UploadsContext";
import {useAssetLoader} from "./AssetsManager";

import {
    isBrowser,
    isMobile
} from "device-detect";
import EditAssetTable from "../widgets/EditAssetTable";
import AssetsMap from "./AssetsMap";
import {useFilters} from "../helpers/useFilters";
import {useSelectedAssetsDispatch} from "../../contexts/SelectedAssetsContext";
import {useViewMode} from "./ViewMenu";
import AssetsGraph from "./AssetsGraph";
import {useTranslation} from "react-i18next";
import QuickUploadList from "~/components/widgets/QuickUploadList";

export default ({quickUpload})=>{
    // console.log('Rendering <AssetsList/>')

    const {viewMode} = useViewMode()

    const {currentUpload, gridInitializes} = useAssetGroupState();

    const Inner = useMemo(()=>(
        <>
            {(viewMode === 'grid' || viewMode === 'posts') && (
                <ScrollWrapper>
                    {({onScroll, onLoading}) => (
                        <InfiniteGrid onScroll={onScroll} onLoading={onLoading}/>
                    )}
                </ScrollWrapper>
            )}

            {viewMode == 'table' && (
                <div style={{height: '100%'}}>
                    {currentUpload && !currentUpload.done ? <EditAssetTable/> : <AssetsTable/>}
                </div>
            )}

            {viewMode == 'map' && (
                <div style={{height: '100%'}}>
                    <AssetsMap/>
                </div>
            )}

            {viewMode == 'graph' && (
                <div style={{height: '100%'}}>
                    <AssetsGraph/>
                </div>
            )}
        </>
    ), [viewMode, currentUpload, gridInitializes])

    if(quickUpload) return <div style={{height:800}}><QuickUploadList/></div>

    return Inner
}

const EmptyAssetGrid = ({gridColor})=>{
    const {t} = useTranslation();
   const {assetsLoading} = useLoadedAssetsState();
   const {currentUpload, loadingCurrentAssetGroup} = useAssetGroupState();

   const {filterCount, clearFilters} = useFilters()

   let description
   if(assetsLoading || loadingCurrentAssetGroup)  {
       description = <><LoadingOutlined/>&nbsp;&nbsp;{t('loading-assets','Loading Assets')}...</>
   } else if(currentUpload){
       description = <>{t('placeholder-upload=empty-asset-grid','Drag and Drop Files / Folders Here, or Paste From Clipboard...')} <span style={{zIndex:1000}}><HelpPopover code={'drag-drop-files'}/></span></>
   } else {
       description = filterCount > 0 ? <>{t('no-filtered-assets','No Filtered Assets')}...<br/><br/><Button onClick={clearFilters} icon={<CloseCircleOutlined/>} danger ghost>{t('button-clear-filters','Clear Filters')}</Button> </> :
           t('no-assets','No Assets...')
   }

   const fontColor = gridColor != '#ffffff' ? '#fff' : '#888';

   return (
       <Empty
           image={Empty.PRESENTED_IMAGE_SIMPLE}
           description={description}
           style={{backgroundColor: gridColor, color: fontColor, height:'100vh', marginTop:0, paddingTop:'2em'}}
       />
   )
}

export {EmptyAssetGrid};

const ScrollWrapper = ({children})=>{
    const [scrollTop, setScrollTop] = useState()

    const onScroll = useCallback((offset)=> {
        setScrollTop(offset)
    }, [])

    const [loading, setLoading] = useState()

    const onLoading = useCallback((val)=> {
        setLoading(val)
    }, [])

    return (
        <>
            {children({onScroll, onLoading})}

            <div style={{position:'fixed', bottom:'.5em', right:'.5em', backgroundColor: 'transparent'}}>
                <Tooltip title={'Scroll Position'} placement={'left'}>
                    <Tag
                        color='blue'
                        icon={loading && <LoadingOutlined/>}
                    >
                        {Math.min(scrollTop, 100) || 0}%
                    </Tag>
                </Tooltip>
            </div>
        </>
    )
}

const InfiniteGrid = memo(({onScroll, onLoading}) => {
    const {t} = useTranslation();

    const {gridSize, viewMode, showRating, showFilenames, showHeadline} = useViewMode()

    const defaultSize = isMobile() ? 'xl' : 'm';

    const gridSizeToAssetSize = {
        xs: 100,
        s: 150,
        m: 200,
        l: 350,
        xl: 500
    }[gridSize || defaultSize]

    const mobileAssetsPerRow = {
        xs: 5,
        s: 4,
        m: 3,
        l: 2,
        xl: 1
    }[gridSize || defaultSize]

    const viewWidth = (document.getElementById('workspace')?.clientWidth || window.innerWidth)

    const leftMargin = isBrowser() ? 15 : 0

    const assetMargin = isMobile() ? (5 - mobileAssetsPerRow) : leftMargin;

    let assetSize;
    if(viewMode === 'posts') {
        assetSize = isMobile() ? window.innerWidth : Config.defaultPostWidth; // TODO: setting: S, M, L, XL -- vw %?
    } else {
        assetSize = isMobile() ?
            (viewWidth / mobileAssetsPerRow) - assetMargin :
            gridSizeToAssetSize;
    }

    // console.log('assetSize', viewWidth, assetMargin, assetSize)

    const {assetIds, assetsLoading} = useLoadedAssetsState()

    const loadedPages = useRef([])
    if(assetsLoading) loadedPages.current = []

    // console.log('InfiniteGrid render, loadedPages:', loadedPages.current)

    const total = assetIds.length;

    const {assetLoader} = useAssetLoader()

    const requestCount = useRef(0)

    const [loading, setLoading] = useState(false)

    const selectedAssetsDispatch = useSelectedAssetsDispatch()
    const gridClick = ()=> {
        selectedAssetsDispatch({type:'selectNone'})
    }

    const {gridColor} = useViewMode()

    const {loadingCurrentAssetGroup} = useAssetGroupState();

    const rowHeights = useRef({});
    const listRef = useRef({});

    window.listRef = listRef

    function setRowHeight(index, size) {
        rowHeights.current = { ...rowHeights.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }

    function getRowHeight(index) {
        return rowHeights.current[index] || 400;
    }

    if(total === 0 || loadingCurrentAssetGroup) return <EmptyAssetGrid gridColor={gridColor}/>;

    return (
        <AutoSizer className={'infinite-grid-auto-sizer'} onClick={gridClick}>
            {({height, width}) => {
                setTimeout(()=>{
                    const el = document.querySelector('.infinite-grid-auto-sizer div')
                    if(el) el.onclick = (e) => {
                        try {
                            const classes = _.flatten(e.path.map(el => el.className?.split(/ /).map(s => s.trim())))
                            if(classes.indexOf('asset') == -1) gridClick();
                        } catch(e) { }
                    }
                }, 100)

                const toolbarHeight = (document.getElementById('browse-grid-header')?.clientHeight || 0) + 32

                let assetsPerRow
                if(viewMode === 'posts') assetsPerRow = 1
                else assetsPerRow = isMobile() ? Math.floor(width / assetSize) || 1 : Math.floor((width - leftMargin) / (assetSize + assetMargin));

                window.assetsPerRow = assetsPerRow

                const loadMoreItems = (startIndex, stopIndex)=>{
                    if(loading) return;

                    onLoading(true)
                    setLoading(true)

                    const assetsPerPage = 25
                    const startPage = Math.floor((startIndex * assetsPerRow) / assetsPerPage) + 1
                    const endPage = Math.floor((stopIndex * assetsPerRow) / assetsPerPage) + 1

                    console.log('loadMoreItems', startIndex, stopIndex, startPage, endPage)

                    const promises = []
                    for(let i = 0; i <= endPage - startPage; i++) {
                        const page = startPage + i

                        if(loadedPages.current.indexOf(page) == -1) {
                            loadedPages.current.push(page)

                            requestCount.current++

                            promises.push(new Promise(resolve => {
                                assetLoader({page,
                                    cb: ()=> {
                                        requestCount.current--
                                        resolve()
                                    }
                                })
                            }))
                        }
                    }

                    return Promise.all(promises).then(()=> {
                        if(requestCount.current == 0) {
                            onLoading(false)
                            setLoading(false)
                        }
                    })
                }

                const isItemLoaded = rowIndex => {
                    // To account for `last` spacer at end:
                    if(rowIndex === total / assetsPerRow) return true
                    return !!assetIds[rowIndex * assetsPerRow]
                }

                const assetsPerPage = 25
                const maxResults = 10_000
                const maxRows = Math.ceil(maxResults / assetsPerRow)

                // NOTE: total + 1 to account for `last` spacer asset:
                const itemCount = Math.min(maxRows + 1, Math.ceil((total + 1)/ assetsPerRow))

                let itemSize = assetSize + assetMargin;

                // Add height for info displays:
                if(showRating) itemSize += 35;
                if(showFilenames) itemSize += 35;
                if(showHeadline) itemSize += 35;

                console.log('defining ItemRenderer')

                const ItemRenderer = ({ index, style }) => {
                    const rowRef = useRef({});

                    useEffect(() => {
                        if (viewMode === 'posts' && rowRef.current) {
                            setRowHeight(index, rowRef.current.clientHeight);
                        }
                    }, [rowRef]);

                    const assetIdsRow = _.compact([...assetIds,'last'].slice(index * assetsPerRow, (index * assetsPerRow) + assetsPerRow))

                    const row = useMemo(()=>{
                        return assetIdsRow.map(id => <AssetWrapper assetSize={assetSize} assetMargin={assetMargin} id={id} key={id}/>)
                    }, [index])

                    return (
                        <div
                            style={{...style, marginTop: assetMargin}}
                            className={'asset-row'}
                            onClick={gridClick}
                        >
                            <div ref={rowRef}>
                                {index >= maxRows && (
                                    <Alert type={'info'} showIcon message={t('results-limit-reached','Limit of 10K results reached, please refine your search to view more.')} style={{margin:'1em'}}/>
                                ) || row}

                                {/*<div style={{position:'absolute', top:0, left:0, color:'red'}}>{index}, P:{Math.ceil(index * assetsPerRow / 25)}, max: {maxRows}</div>*/}
                            </div>
                        </div>
                    )
                }

                return (
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount + (total > assetsPerPage ? 1 : 0)}
                        loadMoreItems={loadMoreItems}
                        threshold={10}
                    >
                        {({ onItemsRendered, ref }) => {
                            if(viewMode === 'posts') {
                                return (
                                    <VariableSizeList
                                        itemCount={itemCount}
                                        itemSize={getRowHeight}
                                        onItemsRendered={onItemsRendered}
                                        ref={node => {
                                            ref.current = node;
                                            listRef.current = node;
                                        }}
                                        width={width}
                                        height={height}
                                        onScroll={({scrollOffset}) => {
                                            return onScroll((((scrollOffset + height) / (itemSize * itemCount)) * 100)?.toFixed())
                                        }}
                                        onClick={gridClick}
                                        style={{backgroundColor: gridColor}}
                                    >
                                        {ItemRenderer}
                                    </VariableSizeList>
                                )
                            }

                            return (
                                <FixedSizeList
                                    itemSize={itemSize}
                                    itemCount={itemCount + (total > assetsPerPage ? 1 : 0)}
                                    onItemsRendered={onItemsRendered}
                                    ref={node => {
                                        ref.current = node;
                                        listRef.current = node;
                                    }}
                                    width={width}
                                    height={height - toolbarHeight}
                                    onScroll={({scrollOffset}) => {
                                        return onScroll((((scrollOffset + height) / (itemSize * itemCount)) * 100)?.toFixed())
                                    }}
                                    onClick={gridClick}
                                    style={{backgroundColor: gridColor}}
                                >
                                    {ItemRenderer}
                                </FixedSizeList>
                            );
                        }}
                    </InfiniteLoader>
                )
            }}
        </AutoSizer>
    )
})

const AssetWrapper = ({assetSize, assetMargin, id})=> {
    const {assets} = useAssetsState()
    const asset = id === 'last' ? {} : assets[id]

    const {uploadingAssets} = useUploadsState();
    const {percent, stats} = _.find(uploadingAssets, {id}) || {};

    const {viewMode} = useViewMode()

    return useMemo(()=>(
        <Asset
            key={asset?.id || id}
            assetSize={assetSize}
            assetMargin={assetMargin}
            asset={asset}
        />
    ),[
        percent, stats,
        asset?.aasm_state, asset?.description,
        asset?.thumb_url, asset?.meta_extracted, asset?.grid_url, asset?.permalink_url, asset?.final_storage,
        asset?.custom_meta_values, asset?.rating,
        viewMode,
    ]
    ); // TODO: add more deps here
}