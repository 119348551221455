import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom-v5-compat";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, notification
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    CloseCircleOutlined,
    CommentOutlined,
    DeleteOutlined, DeploymentUnitOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, FormOutlined, UserSwitchOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import GroupForm from "./GroupForm";
import WorkflowForm from "./WorkflowForm";
import RightsForm from "./RightsForm";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import EnabledDisabled from "../helpers/EnabledDisabled";
import moment from "moment";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {SessionContext} from "../../contexts/SessionContext";
import InviteForm from "./InviteForm";
import MembershipRequestForm from "./MembershipRequestForm";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import useCurrentUser from "@/components/helpers/useCurrentUser";
import useCurrentOrg from "@/components/helpers/useCurrentOrg";

export default ({q, onLoaded, onUpdate}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/membership_requests'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-membership-request-removed','Membership Request Removed.'));
            handleTableChange();
        });
    }

    const Actions = ({membershipRequest}) => {
        const [drawerVisible, setDrawerVisible] = useState()
        const onSave = ()=> {
            setDrawerVisible(false)
            onUpdate && onUpdate()
            handleTableChange()
        }

        if(membershipRequest.aasm_state == 'accepted') return <></>

        return (
            <Can I={'manage'} a={'Membership'}>
                <Button onClick={()=> setDrawerVisible(true)} icon={<FormOutlined/>}>{t('button-respond','Respond')}</Button>
                <MembershipRequestForm
                    onClose={()=> setDrawerVisible(false)}
                    onSave={onSave}
                    visible={drawerVisible}
                    membershipRequest={membershipRequest}
                />
            </Can>
        );
    }

    const columns = [
        {
            title: t('first-name','First Name'),
            dataIndex: 'first_name',
            sorter: true,
        },
        {
            title: t('last-name','Last Name'),
            dataIndex: 'last_name',
            sorter: true,
        },
        {
            title: t('email','Email'),
            dataIndex: 'email',
            sorter: true,
        },
        {
            title: t('note','Note'),
            key: 'note',
            render: (membershipRequest) => (
                <>
                    {!!membershipRequest.note?.length && (
                        <Popover title={t('note','Note')} content={membershipRequest.note}>
                            <CommentOutlined/>
                        </Popover>
                    )}
                </>

            )
        },
        {
            title: t('requested','Requested'),
            dataIndex: 'created_at',
            sorter: true,
            render: (date) => <TimeAgo date={date}/>
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            filters: [
                {text: t('pending','Pending'), value: 'pending'},
                {text: t('accepted','Accepted'), value: 'accepted'},
                {text: t('rejected','Rejected'), value: 'rejected'},
            ],
            filterMultiple: true,
            render: (state, membershipRequest) => {
                const colorIcons = {
                    pending: ['warning', <ClockCircleOutlined/>],
                    accepted: ['success', <CheckOutlined/>],
                    rejected: ['error', <CloseCircleOutlined/>],
                }

                const [color, icon] = colorIcons[state]

                const Wrapper = ({children}) => {
                    return membershipRequest.processed_by ?
                        <Tooltip title={<><TimeAgo date={membershipRequest.processed_at}/> by <User user={membershipRequest.processed_by}/> </>}>{children}</Tooltip>
                        : children
                }

                return (
                    <Wrapper>
                        <Tag color={color} icon={icon}>{state.toProperCase()}</Tag>
                    </Wrapper>
                );
            }
        },
        {
            title: t('Actions','Actions'),
            key: 'action',
            render: (membershipRequest) => <Actions membershipRequest={membershipRequest}/>
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()

    useEffect(()=> {
        if(!currentOrg || !currentUser) return;

        handleTableChange()
    }, [q, currentOrg?.id, currentUser?.id]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    return (
        <>
            <Table
                bordered
                size={'small'}
                scroll={{x: true}}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Tag{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
