import React, {useContext} from "react";

import {message, Button, Row, Col, Card, Alert} from "antd"
import setTitle from "../helpers/setTitle";
import SignUpForm from "./SignUpForm";
import {AppContext} from "../../contexts/AppContext";
import {Link, useNavigate} from "react-router-dom-v5-compat";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";
import useOrgSlug from "../helpers/useOrgSlug";
import OrgNavLink from "../helpers/OrgNavLink";
import OrgBackground from "../session/OrgBackground";
import OrgLogo from "../widgets/OrgLogo";
import {isMobile} from "device-detect";
import Content from "../Content";
import useCurrentUser from "../helpers/useCurrentUser";
import VerticalSpace from "../helpers/VerticalSpace";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    const org = useOrgSlug()

    setTitle(t('sign-up-title','Sign Up'));

    const currentUser = useCurrentUser()

    const navigate = useNavigate();

    if(currentUser?.confirmed_at && !currentUser.super_admin) {
        navigate('/organizations/new')
        return <></>
    }

    return (
        <div style={{paddingTop:'2em'}}>
            <OrgBackground/>
            <Card title={<><UserAddOutlined/> {org?.title || 'Mediagraph'} {t('sign-up','Sign Up')}</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                <OrgLogo org={org}/>

                <VerticalSpace size={'large'}>
                    <Alert message={<Content code={'sign-up'}/>}></Alert>

                    <SignUpForm org={org}/>

                    <div>
                        <OrgNavLink to={'/login'}>{t('login','Login')}</OrgNavLink>
                        <br/>
                        <Link to={'/forgot-password'}>{t('forgot-password','Forgot Password')}</Link>
                    </div>
                </VerticalSpace>
            </Card>
        </div>
    );
}
