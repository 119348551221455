// Wrapper for app-specific customizations

import React from 'react';

import {
    BrowserView as WrappedBrowserView,
    MobileView as WrappedMobileView,
    isMobileOnly,
    isTablet
} from "react-device-detect";

const isBrowser = ()=>{
    return !isMobile();
}

const isMobile = ()=>{
    return isMobileOnly || isTablet;
}

const BrowserView = ({children})=>{
    return isBrowser() ? children : <></>
}

const MobileView = ({children})=>{
    return isMobile() ? children : <></>
}

export {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
}