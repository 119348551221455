import React, {useContext, useEffect, useRef, useState} from "react";
import useConsumer from "../channels/consumer";
import {useAssetsDispatch} from "../contexts/AssetsContext";
import {useNotificationsDispatch} from "../contexts/NotificationsContext";
import {useBulkJobsDispatch} from "../contexts/BulkJobsContext";
import {notification, message} from "antd";
import User from "./helpers/User";
import {useLinksDispatch} from "../contexts/LinksContext";
import {AppContext} from "../contexts/AppContext";
import {useEditAssetsDispatch} from "../contexts/EditAssetsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../contexts/AssetGroupContext";
import {SessionContext} from "../contexts/SessionContext";
import {useLoadedAssetsDispatch} from "../contexts/LoadedAssetsContext";
import {useUploadsDispatch} from "../contexts/UploadsContext";
import {useAggsDispatch} from "../contexts/AggsContext";
import api from "@/components/api";

export default ({}) => {
    const {state, dispatch} = useContext(AppContext);
    const {currentUser} = state;

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const assetsDispatch = useAssetsDispatch();
    const notificationsDispatch = useNotificationsDispatch();
    const bulkJobDispatch = useBulkJobsDispatch()
    const linksDispatch = useLinksDispatch()
    const editAssetsDispatch = useEditAssetsDispatch()
    const loadedAssetsDispatch = useLoadedAssetsDispatch()
    const uploadsDispatch = useUploadsDispatch()
    const aggsDispatch = useAggsDispatch()

    const {currentAssetGroup, currentUpload} = useAssetGroupState();

    // Use a ref here to ensure it's current in the ActionCable context::
    const currentAssetGroupId = useRef()
    currentAssetGroupId.current = currentAssetGroup?.asset_group_id  || currentAssetGroup?.id

    const currentUploadId = useRef()
    currentUploadId.current = currentUpload?.id

    const consumer = useConsumer();
    const userSub = useRef();
    const appSub = useRef();
    const orgSub = useRef();

    const disconnectErrorNotification = useRef()

    useEffect(()=> {
        if(!currentUser) return;

        if(!userSub.current) {
            userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                connected: ()=> {
                    console.log('UserChannel connected');
                },

                disconnected: ()=> {
                    console.log('UserChannel disconnected')
                },

                rejected: ()=> {
                    console.log('UserChannel rejected, logged out!')
                },

                received: (data) => {
                    console.log('UserChannel received', data)

                    switch(data.type) {
                        case 'processingProgress':
                            if(data.attrs.upload_id === currentUploadId.current || data.attrs.storage_folder_id === currentAssetGroupId.current) {
                                if(data.attrs.finished) editAssetsDispatch({type:'increment'})

                                if(data.attrs.processing_error) {
                                    const description = (
                                        <>
                                            {data.attrs.filename} was not able to process, please check the file and upload again.
                                            <br/>
                                            {data.attrs.lightroom_publish_service_id && (
                                                <>
                                                    <a href={`lightroom://${Config.lightroomProtocol}/?action=uploadvalidation&organization_id=${currentOrg.id}&storage_folder_id=${data.attrs.storage_folder_id}&lightroom_publish_service_id=${data.attrs.lightroom_publish_service_id}`}>
                                                        Click Here
                                                    </a>
                                                    &nbsp;to retry upload in Lightroom.
                                                </>
                                            )}
                                        </>
                                    )

                                    notification.error({
                                        description,
                                        message: 'Asset Processing Error',
                                        duration: 0
                                    })
                                }

                                return assetsDispatch({type:'updateAsset', asset: {id: data.asset_id, ...data.attrs}});
                            }

                        case 'transcribeProgress':
                            return assetsDispatch({type:'updateAsset', asset: {id: data.asset_id, ...data.attrs}});

                        case 'addTags':
                            return assetsDispatch({type:'addTags', tags: data.tags});

                        case 'notification':
                            return notificationsDispatch({type: 'newNotification', notification: data})

                        case 'error':
                            notification.error({
                                description: data.description,
                                message: `Error: ${data.message}`,
                                duration: 0
                            })
                            return

                        case 'bulkJobProgress':
                            return bulkJobDispatch({...data, type:'progress'})

                        case 'linkSpidered':
                            return linksDispatch({type:'spidered', id: data.id})

                        case 'dataDownload':
                            return bulkJobDispatch({data, type:'dataDownload'})

                        case 'lightroomConnected':
                            message.success('Lightroom Connected!')
                            notification.close('lightroomConnectionNotification')
                            return

                        case 'ingestionAssetAdded':
                            const {asset} = data;
                            if(asset.upload_id === currentUploadId.current || asset.storage_folder_id === currentAssetGroupId.current){
                                assetsDispatch({type: 'assetsAdded', assets: [asset]});
                                loadedAssetsDispatch({type: 'assetsAdded', assets: [asset]});
                                uploadsDispatch({type:'addUploadingAssets', assets: {...asset, aasm_state: 'pending'}})
                                aggsDispatch({type:'incrementTotal'})
                            }
                            return

                        case 'assetGroupAssetAdded':
                            console.log('assetGroupAssetAdded', data.asset_group_id, currentAssetGroupId.current)
                            if (data.asset_group_id === currentAssetGroupId.current) {
                                api(`/api/assets/${data.asset_id}`).then(res => {
                                    const asset = res.data
                                    assetsDispatch({type: 'assetsAdded', assets: [asset]});
                                    loadedAssetsDispatch({type: 'assetsAdded', assets: [asset]});
                                    uploadsDispatch({type:'addUploadingAssets', assets: {...asset, aasm_state: 'pending'}})
                                    aggsDispatch({type:'incrementTotal'})
                                })
                            }
                            return
                    }
                }
            });
        }

        if(!appSub.current) {
            appSub.current = consumer.subscriptions.create({channel: "AppChannel"}, {
                connected: ()=> {
                    console.log(`AppChannel connected @ ${new Date()}`);
                    if(disconnectErrorNotification.current) {
                        disconnectErrorNotification.current = false
                        uploadsDispatch({type:'reconnected'})
                    }
                },

                disconnected: ()=> {
                    console.log(`AppChannel disconnected @ ${new Date()}`)
                    disconnectErrorNotification.current = true
                    uploadsDispatch({type:'disconnected'})
                },

                rejected: ()=> {
                    console.log('AppChannel rejected, logged out!')
                },

                received: (data) => {
                    console.log('AppChannel received', data)

                    switch(data.type) {
                        case 'reindexing':
                            return notification.warn({
                                message: 'Search Re-Indexing starting...',
                                description: 'Your Assets will begin reindexing into the search system shortly, so you may not see all of them when browsing and searching. Refresh the page to see your estimated completion time.',
                                duration: 0
                            })
                        case 'newVersion':
                            return notification.warn({
                                message: 'New App Version Released',
                                description: 'Please refresh the page to load the new app version.',
                                duration: 0
                            })
                        case 'forceReload':
                            setTimeout(()=>{
                                window.location.reload()
                            }, 30 * 1000)

                            return notification.warn({
                                message: 'New Major App Version Released',
                                description: 'Your browser will be reloaded in 30 seconds to update the application.',
                                duration: 30
                            })
                    }
                }
            });
        }

        if(!orgSub.current) {
            orgSub.current = consumer.subscriptions.create({channel: "OrganizationChannel"}, {
                connected: ()=> {
                    console.log(`OrganizationChannel connected @ ${new Date()}`);
                },

                disconnected: ()=> {
                    console.log(`OrganizationChannel disconnected @ ${new Date()}`)
                },

                rejected: ()=> {
                    console.log('OrganizationChannel rejected, logged out!')
                },

                received: (data) => {
                    console.log('OrganizationChannel received', data)

                    switch(data.type) {
                        case 'treeChanged':
                            // if data.user_id !== currentUser.id, set dirty tree indicator
                            console.log('treeChanged', data)
                    }
                }
            });
        }

        return ()=> {
            userSub.current?.unsubscribe()
            appSub.current?.unsubscribe()
            orgSub.current?.unsubscribe()
        }

    }, [currentUser?.id])

    return <></>;
}
