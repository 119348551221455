import React from "react";
import {Divider} from "antd";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useTranslation} from "react-i18next";

export default ({noDivider, orgColors})=> {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const A = props => {
        return <a {...props} style={{color: orgColors && currentOrg?.header_text_color}} tabIndex={0} className={'legal-footer-anchor'}>{props.children}</a>
    }

    return (
        <footer style={{backgroundColor: orgColors && currentOrg?.header_background_color, padding:'2em 0'}}>
            {!noDivider && <Divider/>}

            <div style={{margin:'0 auto', padding: '1em', textAlign: 'center', maxWidth: 800, fontSize:15, color: orgColors && currentOrg?.header_text_color}}>
                {t('powered-by','Powered By')} <A href={'//www.mediagraph.io'}>Mediagraph.io</A>
                <br/>
                <A href={'https://www.mediagraph.io/terms-of-service'} target={'_blank'}>{t('terms-of-service','Terms of Service')}</A>
                &nbsp;|&nbsp;
                <A href={'https://www.mediagraph.io/privacy-policy'} target={'_blank'}>{t('privacy-policy','Privacy Policy')}</A>
                {/*&nbsp;|&nbsp;*/}
                {/*<A href={'https://www.mediagraph.io/cookies-policy'} target={'_blank'}>{t('cookie-policy','Cookie Policy')}</A>*/}
                &nbsp;|&nbsp;
                <A href={'https://docs.mediagraph.io'} target={'_blank'}>{t('api','API')}</A>
                <br/>
                {t('copyright','Copyright')} {new Date().getFullYear()} The DAM Software Company, Inc.
            </div>
        </footer>
    )
}