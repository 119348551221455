import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {AutoComplete, Checkbox, ConfigProvider, Input, Popover, Space, Tag, Button, Menu, Divider} from "antd";
import api from "../api";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import {
    BulbOutlined,
    CaretDownOutlined, DownOutlined,
    FolderOutlined,
    LinkOutlined,
    LoadingOutlined,
    SettingOutlined,
    TagOutlined
} from "@ant-design/icons";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import {useFilters} from "../helpers/useFilters";
import queryString from "query-string";
import {SessionContext} from "../../contexts/SessionContext";
import {
    BrowserView,
    MobileView,
    isBrowser,
} from "device-detect";
import {GlobalHotKeys} from "react-hotkeys";
import {useNavigate} from "react-router-dom-v5-compat";
import useShowAsset from "../helpers/useShowAsset";
import {isMobile} from "../../plugins/device-detect";
import SearchModalButton from "./SearchModalButton";
import {AbilityContext} from "../helpers/Can";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import {useViewMode} from "@/components/explore/ViewMenu";

export default ({inputRef: externalInputRef})=> {
    const { t } = useTranslation();
    const {state: sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const [searchLoading, setSearchLoading] = useState()

    const [searchOptions, setSearchOptions] = useState([])

    const lastSearchValue = useRef();
    const search = useCallback(_.debounce((value)=>{
        console.log('searching:', value)
        lastSearchValue.current = value;

        if(value === '') return setSearchOptions([]);

        setSearchLoading(true);

        const q = processedSearchValue?.length ? processedSearchValue : value
        api('/api/search', {params: {q}}).then(res => {
            setSearchLoading(false);
            // console.log(value, res.data)
            if(value != lastSearchValue.current || res.data.error) return;
            console.log('searched for: ', q)

            const newOptions = [
                {
                    label: <><SearchOutlined/> {t('search-all','Search All')}...</>, value: 'all'
                },
                {
                    label: t('tags','Tags'),
                    options: res.data.tags.map(tag => {
                        return {
                            value: `tag-${tag.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    <Tag icon={<TagOutlined/>}>
                                        {tag.name}
                                    </Tag>
                                </Space>
                            )
                        }
                    })
                },
                {
                    label: t('collections','Collections'),
                    options: res.data.collections.map(col => {
                        return {
                            value: `collection-${col.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    <FolderOutlined/>
                                    {col.path_names.join(' / ')}
                                </Space>
                            )
                        }
                    })
                },
                {
                    label: t('lightboxes','Lightboxes'),
                    options: res.data.lightboxes.map(col => {
                        return {
                            value: `lightbox-${col.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    <FolderOutlined/>
                                    {col.path_names.join(' / ')}
                                </Space>
                            )
                        }
                    })
                },
                {
                    label: t('storage-folders','Storage Folders'),
                    options: res.data.storage_folders.map(sf => {
                        return {
                            value: `storage_folder-${sf.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    <FolderOutlined/>
                                    {sf.path_names.join(' / ')}
                                </Space>
                            )
                        }
                    })
                },
                {
                    label: t('links','Links'),
                    options: res.data.links.map(link => {
                        return {
                            value: `link-${link.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    <LinkOutlined/> {link.url}
                                </Space>
                            )
                        }
                    })
                },
                {
                    label: t('assets','Assets'),
                    options: res.data.assets.map(asset => {
                        return {
                            value: `asset-${asset.id}`,
                            label: (
                                <Space direction={'horizontal'}>
                                    {!!asset.thumb_url?.length && (
                                        <img src={asset.preview_image_url || asset.thumb_url} style={{ width: 50, height: 50, objectFit: 'scale-down'}}/>
                                    )}
                                    {asset.filename}
                                    |
                                    {asset.description}
                                </Space>
                            )
                        }
                    }),
                },
            ];

            setSearchOptions(newOptions.filter(opt => opt.value === 'all' || (opt.options && opt.options.length)))
        });
    }, 250), [])

    const navigate = useNavigate();

    const {setFilters} = useFilters();

    const showAsset = useShowAsset()

    const getPath = useOrgPath()

    const gotoExplore = ()=>{
        setTimeout(()=>{
            if(!location.pathname.match(/explore/)) {
                location.pathname = getPath(`/explore`)
                navigate(location);
            }
        }, 100)
    }

    const selectSearchOption = (value, option)=> {
        console.log(value, option, searchValue)

        if(value === 'all') {
            setFilters(false, {q: processedSearchValue})
            setSearchValue(searchValue);
            gotoExplore()
            return;
        }

        const id = parseInt(value.match(/-(.+?)$/)[1])

        if(value.match(/^asset/)) {
            showAsset(id)
        } else if(value.match(/^storage_folder/)) {
            api(`/api/storage_folders/${id}`).then(res => {
                navigate(getPath(`/explore/folders/${res.data.slug}`));
            })

        } else if(value.match(/^collection/)) {
            api(`/api/collections/${id}`).then(res => {
                navigate(getPath(`/explore/collections/${res.data.slug}`));
            })

        } else if(value.match(/^lightbox/)) {
            api(`/api/lightboxes/${id}`).then(res => {
                navigate(getPath(`/explore/projects/${res.data.slug}`));
            })
        } else if(value.match(/^tag/)) {
            api(`/api/tags/${id}`).then(res => {
                setFilters(false, {tags: res.data.name})
                gotoExplore()
            })
        } else if(value.match(/^link/)) {
            api(`/api/links/${id}`).then(res => {
                setFilters(false, {link: res.data.url})
                gotoExplore()
            })
        }

        setSearchValue('');
    }

    const {
        convertCommasToOr,
        changeConvertCommasToOr,
        changeConvertSpacesToOr,
        convertSpacesToOr
    } = useViewMode()

    const q = queryString.parse(location.search, {arrayFormat: 'bracket'}).q;
    const [searchValue, setSearchValue] = useState(q);

    let processedSearchValue = searchValue;

    if((convertSpacesToOr || convertCommasToOr) && processedSearchValue?.length) {
        if(convertSpacesToOr) {
            const myRegexp = /[^\s"]+|("[^"]*")/gi;
            const arr = [];
            let match;

            do {
                match = myRegexp.exec(processedSearchValue);
                if (match != null)  arr.push(match[1] ? match[1] : match[0]);
            } while (match != null);

            processedSearchValue = arr.join(' OR ')
        }

        if(convertCommasToOr) {
            processedSearchValue = processedSearchValue.replaceAll(/,/g, ' OR ')
        }
    }

    useEffect(()=>{
        if(!q) setSearchValue('')
    }, [q])

    const inputRef = externalInputRef || useRef();
    const ref = useRef();
    const keyMap = { FWD_SLASH: '/' }

    const handlers = {
        FWD_SLASH: (e) => {
            e.preventDefault()
            inputRef.current.focus()
        }
    }

    const ability = useContext(AbilityContext);

    return (
        (<GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref} allowChanges>
            <search role='search' id={'site-search'} style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%', gap: '.5em'}} aria-label={t('sitewide-search', 'Sitewide Search')}>
                <ConfigProvider
                    theme={
                        {
                            components: {
                                Input: { addonBg: '#fff'} ,
                                Menu: {
                                    horizontalItemHoverBg: 'transparent',
                                    horizontalItemSelectedBg: 'transparent',
                                    itemBg: currentOrg?.header_background_color
                                },
                            }
                        }
                    }
                >
                    <AutoComplete
                        popupClassName="certain-category-search-dropdown"
                        popupMatchSelectWidth={800}
                        defaultActiveFirstOption
                        style={{
                            width: '100%',
                            flex: 'auto',
                            borderColor: currentOrg?.header_text_color,
                            textAlign:'left',
                        }}
                        onSearch={search}
                        options={searchOptions}
                        onSelect={selectSearchOption}
                        onChange={setSearchValue}
                        value={searchValue}
                        listHeight={'80vh'}
                    >
                        <Input
                            aria-label={t('sitewide-search', 'Sitewide Search')}
                            placeholder={t('nav-search','Search...')}
                            onKeyPress={e => { if(e.key == 'Enter') selectSearchOption('all') }}
                            allowClear
                            ref={inputRef}
                            prefix={searchLoading ? <LoadingOutlined/> : <SearchOutlined style={{color: 'grey'}}/>}
                        />
                    </AutoComplete>

                    <Popover
                        title={<><SettingOutlined/> Search Options</>}
                        getPopupContainer={() => document.getElementById('site-search-wrapper')}
                        content={
                            <VerticalSpace>
                                <Checkbox checked={convertSpacesToOr} onChange={changeConvertSpacesToOr} tabIndex={0}>Convert Spaces to 'OR'?</Checkbox>
                                <Checkbox checked={convertCommasToOr} onChange={changeConvertCommasToOr} tabIndex={0}>Convert Commas to 'OR'?</Checkbox>

                                <SearchModalButton block><BulbOutlined/> {t('advanced-search', 'Advanced Search')}</SearchModalButton>
                            </VerticalSpace>
                        }
                        trigger={['hover', 'click']}
                        overlayStyle={{width:300}}
                    >
                        <Button
                            type='text'
                            icon={<SettingOutlined style={{color: currentOrg?.header_text_color}}/>}
                            shape={'circle'}
                            aria-label={t('search-settings', 'Search Settings')}
                            id={'search-settings-btn'}
                        />
                    </Popover>
                </ConfigProvider>
            </search>
        </GlobalHotKeys>)
    );
}