import {useTranslation} from "react-i18next";
import useCurrentOrg from "~/components/helpers/useCurrentOrg";
import React, {useEffect, useRef, useState} from "react";
import {message, Modal, Skeleton, Space, Button, Card, Popconfirm, Divider, Checkbox, Switch} from "antd";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import {Formik} from "formik";
import {FormItem, Input} from "formik-antd";
import FloatLabel from "~/components/helpers/FloatLabel";
import {MenuOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {DeleteIcon} from "~/components/helpers/icons";
import {arrayMoveImmutable} from "array-move";
import api from "~/components/api";
import {useFilterTitles} from "@/components/explore/ExploreFilters";
import HelpPopover from "@/components/HelpPopover";

export default ({})=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()
    const [loading, setLoading] = useState(true);

    const [filterGroups, setFilterGroups] = useState()
    const [filters, setFilters] = useState()
    const [unusedFilters, setUnusedFilters] = useState()

    const [includeTaggersInAdmin, setIncludeTaggersInAdmin] = useState(org.include_taggers_in_admin)

    const load = ()=>{
        setLoading(true)
        api('/api/filter_groups?with_filters=true').then(res => {
            setLoading(false)
            setFilterGroups(res.data.filter_groups)
            setFilters(res.data.filters)
        });
    }

    useEffect(()=>{
        if(!filterGroups || !filters) return;

        const usedFilters = _.flatten(filterGroups.map(fg => fg.filters?.map(f => f.name) || []))

        setUnusedFilters(filters.filter(f => usedFilters.indexOf(f) === -1).map(name => { return {name, general: true, public: false, admin: true}}))
    }, [filterGroups, filters])

    useEffect(()=>{
        load()
    }, [org?.id]);

    const autoFocusInput = useRef(null);
    const [modalVisible, setModalVisible] = useState()

    const clickNew = ()=>{
        setModalVisible(true)
        setTimeout(()=>{
            autoFocusInput.current.focus()
        }, 100)
    }

    const SortableFilterGroupItem = SortableElement((props) => <EditableFilterGroup {...props} />);
    const SortableFilterGroupsBody = SortableContainer((props) => <div {...props} />);

    const onSortEnd = ({ oldIndex, newIndex, collection, ...props}, e) => {
        if (collection === 'filterGroups') {
            if( oldIndex !== newIndex) {
                const newData = arrayMoveImmutable(filterGroups, oldIndex - 1, newIndex - 1);

                api.put('/api/filter_groups/update_position', {oldIndex, newIndex}).then(res => {
                    message.success(t('message-sort-order-updated','Sort order updated!'))
                })

                setFilterGroups(newData);
            }
        } else {
            // Dropped from unused:
            const filter = unusedFilters[oldIndex]
            console.log(`Dropped ${filter} from unused into ${hoveringFilterGroup.current?.name}`)

            const fg = hoveringFilterGroup.current;
            if(fg) {
                const newFilters= [...fg.filters, filter];

                setFilterGroups(filterGroups => {
                    _.find(filterGroups, {id: fg.id}).filters = newFilters
                    return [...filterGroups]
                })

                console.log(newFilters, newFilters.map(f => f.name))
                api.put(`/api/filter_groups/${fg.id}`, {filter_group: {filter_order: newFilters.map(f => f.name)}}).then(res => {
                    message.success(t('message-filter-added','Filter added!'))
                })
            }
        }
    };

    const onSortStart = (props, e)=> {
        console.log(props,e)
    }

    const hoveringFilterGroup = useRef()

    const changeIncludeTaggers = () => {
        setIncludeTaggersInAdmin(!includeTaggersInAdmin)
        api.put(`/api/organizations/${org.id}`, {organization: {include_taggers_in_admin: !includeTaggersInAdmin}}).then(res => {
            message.success(t('setting-updated', 'Setting updated.'))
        })
    }

    return (
        (<Skeleton active loading={loading}>
            <SortableFilterGroupsBody
                useDragHandle
                onSortStart={onSortStart}
                onSortEnd={onSortEnd}
            >
                <VerticalSpace>
                    <Checkbox
                        checked={includeTaggersInAdmin}
                        onChange={changeIncludeTaggers}
                    >
                        {t('include-taggers-in-admin', 'Include Taggers in Admin?')}
                        <HelpPopover code={'include-taggers-in-admin'}/>
                    </Checkbox>

                    <Divider style={{margin:'.5em 0 0 0'}}/>

                    <VerticalSpace>
                        {filterGroups?.map((fg, i) => (
                            <div
                                onMouseOver={()=> hoveringFilterGroup.current = fg}
                                onMouseOut={()=> hoveringFilterGroup.current = null}
                                key={fg.id}
                            >
                                <SortableFilterGroupItem
                                    filterGroup={fg}
                                    load={load}
                                    index={i + 1}
                                    collection={'filterGroups'}
                                    hoveringFilterGroup={hoveringFilterGroup}
                                    filterGroups={filterGroups}
                                    setFilterGroups={setFilterGroups}
                                />
                            </div>
                        ))}
                    </VerticalSpace>

                    <Button onClick={clickNew} icon={<PlusOutlined/>}>{t('add-new-group', 'Add New Group')}</Button>

                </VerticalSpace>

                <Divider>{t('available-filters','Available Filters')}</Divider>

                <VerticalSpace>
                    <SortableFilterBody
                        useDragHandle
                        onSortEnd={onSortEnd}
                    >
                        {unusedFilters?.map((filter,i) => {
                            return (
                                <SortableFilterItem
                                    key={i}
                                    filter={filter}
                                    index={i}
                                    collection={'filters'}
                                />
                            );
                        })}
                    </SortableFilterBody>
                </VerticalSpace>
            </SortableFilterGroupsBody>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    api.post(`/api/filter_groups`, {filter_group: values}).then(res => {
                        actions.setSubmitting(false)
                        setFilterGroups([...filterGroups, res.data])
                        message.success(t('message-filter-group-added','Filter Group added.'))
                        setModalVisible(false)
                    })
                }}
            >
                {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                    return (
                        (<Modal
                            title={<>{t('add-new-filter-group','Add New Filter Group')}</>}
                            open={modalVisible}
                            onCancel={setModalVisible}
                            footer={
                                <Space>
                                    <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                        <SaveOutlined/>
                                        {t('button-add','Add')}
                                    </Button>
                                    <Button onClick={() => setModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                                </Space>
                            }
                        >
                            <form onSubmit={submitForm}>
                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-workflow-name','e.g. Marketing Approval Pipeline')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>
                            </form>
                        </Modal>)
                    );
                }}
            </Formik>
        </Skeleton>)
    );
}

const SortableFilterItem = SortableElement((props) => <Filter {...props} />);
const SortableFilterBody = SortableContainer((props) => <div {...props} />);

const Filter = ({filterGroup, filter}) => {
    const {t} = useTranslation();
    const titles = useFilterTitles()

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const [attrs, setAttrs] = useState(filter)

    return (
        <div style={{display:'flex', margin:'.5em 0'}} id={`filter-${filter.name}`}>
            <Space>
                <DragHandle/>
                {titles[filter.name] || filter.name}
            </Space>

            <div style={{marginLeft:'auto'}}>
                {filterGroup && ['admin', 'general', 'public'].map(type => {
                    const onChange = ({target}) =>{
                        attrs[type] = target.checked
                        setAttrs({...attrs})
                        console.log(target.checked, filterGroup.name, filter.name)
                        api.put(`/api/filter_groups/${filterGroup.id}/update_visibility?name=${filter.name}&type=${type}&visible=${target.checked}`).then(res => {
                            message.success(t('message-filter-visibility-updated','Filter visibility updated.'))
                        })
                    }

                    return (
                        <Checkbox onChange={onChange} key={type} checked={attrs[type]}>{t(type, type.toProperCase())}</Checkbox>
                    )
                })}
            </div>
        </div>
    )
}

const EditableFilterGroup = ({filterGroup, load, hoveringFilterGroup, filterGroups, setFilterGroups})=>{
    const {t} = useTranslation();

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const [filters, setFilters] = useState(filterGroup.filters)

    useEffect(()=>{
        setFilters(filterGroup.filters)
    }, [filterGroup.filters])

    const [deleting, setDeleting] = useState()
    const destroy = ()=>{
        setDeleting(true)
        api.delete(`/api/filter_groups/${filterGroup.id}`).then(res => {
            setDeleting(false)
            load()
        })
    }

    const onSortEnd = ({oldIndex, newIndex, collection}, e)=>{
        const droppingFilterGroup = hoveringFilterGroup.current
        const sourceId = parseInt(collection.replace('filters-',''))

        let newFilters;

        if(!droppingFilterGroup) {
            const filter = filters[oldIndex]
            console.log('removed', filterGroup.name, filter.name)

            newFilters = [...filterGroup.filters.filter(f => f.name !== filter.name)]

            api.put(`/api/filter_groups/${filterGroup.id}`, {filter_group:{filter_order: newFilters.map(f => f.name)}}).then(res => {
                message.success(t('message-filter-removed','Filter removed!'))
            })

            // Update so it's added back to Available filters:
            setFilterGroups(filterGroups => {
                const existing = _.find(filterGroups, {id: filterGroup.id})
                existing.filters = newFilters
                return [...filterGroups]
            })

        } else if(droppingFilterGroup?.id === sourceId) {
            // Dropped in same FG, update position:
            console.log('Dropped in same FG, update position:', oldIndex, newIndex)

            newFilters = arrayMoveImmutable(filters, oldIndex, newIndex);

            api.put(`/api/filter_groups/${droppingFilterGroup.id}`, {filter_group:{filter_order: newFilters.map(f => f.name)}}).then(res => {
                message.success(t('message-filter-updated','Filter updated!'))
            })

        } else if(collection) {
            // Different FG, add to end, remove from other:

            const filter = filters[oldIndex]

            // Remove from this FG:
            newFilters = [...filterGroup.filters.filter(f => f.name !== filter.name)]

            // Add to other FG:
            setFilterGroups(filterGroups => {
                const existing = _.find(filterGroups, {id: droppingFilterGroup.id})
                existing.filters = [...existing.filters, filter]

                api.put(`/api/filter_groups/${droppingFilterGroup.id}`, {filter_group:{filter_order: existing.filters.map(f => f.name)}}).then(res => {
                    message.success(t('message-filter-updated','Filter updated!'))
                })

                const current = _.find(filterGroups, {id: filterGroup.id})
                current.filters = newFilters

                console.log(`Different FG, add ${filter.name} from ${filterGroup.name} to ${existing.name}:`)

                return [...filterGroups]
            })
        }

        setFilters(newFilters)
    }

    return (
        <Card
            size={'small'}
            title={
                <div style={{display:'flex'}}>
                    <Space style={{width:'100%'}}>
                        <DragHandle/>
                        <span>{filterGroup.name}</span>
                    </Space>

                    <div style={{marginLeft:'auto'}}>
                        <Popconfirm
                            onConfirm={destroy}
                            title={t('confirm-remove-filter-group','Remove Filter Group?')}
                        >
                            <Button ghost danger loading={deleting} size={'small'}><DeleteIcon/></Button>
                        </Popconfirm>
                    </div>
                </div>
            }
        >
            <SortableFilterBody
                useDragHandle
                // disableAutoscroll
                // helperClass="row-dragging"
                onSortEnd={onSortEnd}
            >
                {filters?.map((filter,i) => {
                    return (
                        <SortableFilterItem
                            key={filter.name}
                            filter={filter}
                            filterGroup={filterGroup}
                            index={i}
                            collection={`filters-${filterGroup.id}`}
                        />
                    );
                })}
            </SortableFilterBody>
        </Card>
    )
}