import React, {useContext, useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import * as Yup from "yup";

import {Alert, Badge, Collapse, Divider, Drawer, message, Modal, Space, Tag, Tooltip} from 'antd';
const { Panel } = Collapse;

import HelpPopover from "../HelpPopover";

import {
    SaveOutlined,
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";

import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import FloatLabel from "../helpers/FloatLabel";
import {ContributionIcon, RightsIcon, StorageFolderIcon, UserGroupIcon} from "../helpers/icons";
import ContributionDetailsForm from "./ContributionDetailsForm";
import AssetGroupChooser from "./AssetGroupChooser";
import {SessionContext} from "../../contexts/SessionContext";
import EnabledDisabled from "../helpers/EnabledDisabled";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";

export default ({id, onSave, visible, onClose}) => {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const navigate = useNavigate();

    if(!id) {
        const { id } = useParams();
    }

    const [contribution, setContribution] = useState({});
    const apiPath = '/api/contributions';

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`${apiPath}/${path}`).then((res) => {
            setContribution(res.data);
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    const onCloseDirty = useOnCloseDirty(onClose)

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    const getPath = useOrgPath()

    return (
        (<Formik
            initialValues={contribution}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                api({
                    method: id ? 'put' : 'post',
                    url: id ? `${apiPath}/${id}` : apiPath,
                    data: { contribution: values }
                }).then((res)=>{
                    console.log('id',id);
                    if(!id) navigate(getPath(`/manage/uploads`))
                    actions.setSubmitting(false)
                    message.success(`${values.name} has been ${id ? 'updated' : 'created'}!`)
                    setContribution(res.data);

                    actions.resetForm({});

                    onSave && onSave(res.data);

                }).catch((error)=>{
                    console.error(error.data)
                    message.error(`Error: ${JSON.stringify(error.data)}`)
                    actions.setSubmitting(false)
                })
            }}
        >
            {({setFieldValue, values, submitForm, dirty}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = '';

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <ContributionIcon/> {id ? t('editing','Editing') : t('add-new','Add New')} {t('contribution-request','Contribution Request')}
                                    <HelpPopover code='contribution-form-header'/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <SaveOutlined/>
                                            {id ? t('update','Update') : t('save','Save')} {t('contribution-request','Contribution Request')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical'>
                                {contribution.user_group && (
                                    <Alert
                                        message={
                                            <><UserGroupIcon/> {t('user-group','User Group')}: {contribution.user_group.name}</>
                                        }
                                        type={'info'}
                                    />
                                )}

                                <FormItem name='enabled'>
                                    <Switch checkedChildren={t('enabled','Enabled').toProperCase()} unCheckedChildren={t('disabled','Disabled').toProperCase()} name={'enabled'}/>
                                </FormItem>

                                <FormItem name='enable_public'>
                                    <Switch checkedChildren={t('public','Public').toProperCase()} unCheckedChildren={t('private','Private').toProperCase()} name={'enable_public'}/>
                                    <HelpPopover code='public-contributions'/>
                                </FormItem>

                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-contribution-request-name','e.g. Owned By Org')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='text' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('description','Description')} name={'text'} value={values.text} description={t('placeholder-contribution-request-description','Describe the purpose of the Contribution Request. Optional.')}>
                                        <Input.TextArea rows={2} name='text'/>
                                    </FloatLabel>
                                </FormItem>

                                <Collapse>
                                    <Collapse.Panel header={<><StorageFolderIcon/> {t('storage-folder','Storage Folder')} {contribution.id && `: ${contribution.storage_folder?.name}`} {<EnabledDisabled enabled={values.storage_folder_id}/> }</>}>
                                        {contribution && (
                                            <AssetGroupChooser
                                                type={'storage_folders'}
                                                fieldName={'storage_folder_id'}
                                                current={contribution.storage_folder}
                                            />
                                        )}
                                    </Collapse.Panel>
                                </Collapse>

                                <Divider>{t('details','Details')}</Divider>

                                <ContributionDetailsForm/>

                                {/*For Enter btn submit:*/}
                                <SubmitButton style={{display:'none'}}/>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

