import React from 'react';

export default ({children, ...props}) => {
    const capture = e => e.stopPropagation()

    return (
        <div onClick={capture} onMouseDown={capture} onMouseUp={capture} onKeyPress={capture} onKeyUp={capture} onKeyDown={capture} {...props}>
            {children}
        </div>
    )
}