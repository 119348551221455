import {FieldArray, useFormikContext} from "formik";
import {Button, Card, List, Popover, Tag, Typography, message} from "antd";
import {Checkbox, Form, FormItem, Input, Select} from "formik-antd";
import {PlusOutlined, TagOutlined} from "@ant-design/icons";
import React, {useEffect, useState, useRef} from "react";
import api from "../api";
import FloatLabel from "../helpers/FloatLabel";
import Collapse from "antd/lib/collapse";
import {useTranslation} from "react-i18next";

export default ({fieldName})=> {
    const {t} = useTranslation();
    const { values, setFieldValue } = useFormikContext();

    const getInputName = (name)=>{
        return fieldName ? `${fieldName}.${name}` : name
    }

    const getValue = (name)=> {
        if(!values) return;
        return fieldName ? values[fieldName][name] : values[name]
    }

    const [tagSuggesters, setTagSuggesters] = useState([]);

    useEffect(()=>{
        api.get(`/api/tag_suggesters?per_page=1000`).then((res) => {
            updateApplicableTagSuggesters(res.data);
        });
    },[])

    const createNewTagSuggester = (arrayHelpers)=> {
        return () => {
            if(!values.new_tag_suggester_name) {
                return message.error('Please enter a Tag Suggester Name');
            }

            const names = _.map(getValue('contribution_tag_suggesters_attributes'),
                ctsa => ctsa.tag_suggester_attributes.name);

            if(names.indexOf(values.new_tag_suggester_name) != -1) {
                return message.error(t('already-tag-suggester-with-name', 'There is already a Tag Suggester with that name.'));
            }

            arrayHelpers.push({
                tag_suggester_attributes: {
                    name: values.new_tag_suggester_name,
                    tag_names: values.new_tag_suggester_tag_names
                }
            });
            setFieldValue('new_tag_suggester_name', null);
            setFieldValue('new_tag_suggester_tag_names', null);
        }
    }

    const addExistingTagSuggester = (arrayHelpers, ts)=> {
        arrayHelpers.push({tag_suggester_id: ts.id, tag_suggester_attributes: ts});
        setFieldValue(getInputName('current_tag_suggester'), '') ;
    }

    const updateApplicableTagSuggesters = (existing= tagSuggesters)=>{
        const ids = _.map(getValue('contribution_tag_suggesters_attributes'),
            ctsa => ctsa.tag_suggester_attributes.id);

        const filtered = _.filter(existing, ts => ids.indexOf(ts.id) == -1);
        setTagSuggesters([...filtered]);
    }

    useEffect(
        updateApplicableTagSuggesters,
        [getValue('contribution_tag_suggesters_attributes')?.length]
    );

    return (
        <FieldArray
            name={getInputName('contribution_tag_suggesters_attributes')}
            render={arrayHelpers => (
                <>
                    <List
                        bordered
                        size={'small'}
                        header={<strong><TagOutlined/> {t('tag-suggesters', 'Tag Suggesters')}</strong>}
                    >
                        {!getValue('contribution_tag_suggesters_attributes') && (
                            <List.Item><em>{t('no-tag-suggesters-yet', 'None yet, please create one using the form below.')}</em></List.Item>
                        )}

                        {getValue('contribution_tag_suggesters_attributes')?.map((item, i) => (
                            <TagSuggesterItem item={item} i={i} key={i} fieldName={fieldName}/>
                        ))}

                        {!!tagSuggesters.length && (
                            <List.Item key={'new'}>
                                <Select
                                    showSearch
                                    name={getInputName('current_tag_suggester')}
                                    placeholder='Select Existing'
                                    optionFilterProp='name'
                                    style={{ width: 300 }}
                                    allowClear
                                    onSelect={(value, option) => {
                                        addExistingTagSuggester(arrayHelpers, {
                                            id: option.id,
                                            name: option.name,
                                            tag_names: option.tag_names
                                        })
                                    }}
                                >
                                    {tagSuggesters.map(({name, id, tag_names}) => (
                                        <Select.Option key={id} id={id} name={name} tag_names={tag_names}>{name}</Select.Option>
                                    ))}
                                </Select>
                            </List.Item>
                        )}
                    </List>
                    <br/>

                    <Collapse>
                        <Collapse.Panel header={'Create New Tag Suggester'} id={'new-tag-suggester-panel'}>
                            <Form.Item name={'new_tag_suggester_name'}>
                                <FloatLabel label={t('name', 'Name')} name={'new_tag_suggester_name'} value={values.new_tag_suggester_name} description={t('e-g-people', 'e.g. People')}>
                                    <Input size={'large'} name={'new_tag_suggester_name'}/>
                                </FloatLabel>
                            </Form.Item>

                            <FormItem name='new_tag_suggester_tag_names'>
                                <FieldArray
                                    name={'new_tag_suggester_tag_names'}
                                    render={() => (
                                        <FloatLabel label={t('tag','Tags')} name={'new_tag_suggester_tag_names'} value={values.new_tag_suggester_tag_names} description={t('e-g-tags', 'e.g. "George, Jill". Press enter to add.')}>
                                            <Select size={'large'} name='new_tag_suggester_tag_names' mode='tags' id={'new_tag_suggester_tag_names'}>
                                                {values.new_tag_suggester_tag_names?.map((name) => (
                                                    <Select.Option value={name} key={name}>{name}</Select.Option>
                                                ))}
                                            </Select>
                                        </FloatLabel>
                                    )}/>
                            </FormItem>
                            <Button onClick={createNewTagSuggester(arrayHelpers)}><PlusOutlined /> {t('create-and-add', 'Create and Add')}</Button>
                        </Collapse.Panel>
                    </Collapse>
                </>
            )}
        />

    );
}

const TagSuggesterItem = ({item, i, fieldName})=>{
    const {t} = useTranslation();
    const { setFieldValue } = useFormikContext();

    const getInputName = (name)=>{
        return fieldName ? `${fieldName}.${name}` : name
    }

    const [tagNames, setTagNames] = useState(item.tag_suggester_attributes.tag_names)

    const onUpdateExisting = (value)=>{
        setTagNames(value)
        setFieldValue(getInputName(`contribution_tag_suggesters_attributes[${i}].tag_suggester_attributes.tag_names`), value)
    }

    const [name, setName] = useState(item.tag_suggester_attributes.name)

    const onChange = (value) => {
        setName(value)
        setFieldValue(getInputName(`contribution_tag_suggesters_attributes[${i}].tag_suggester_attributes.name`), value)
    }

    return (
        <List.Item key={i} extra={
            <div style={{float:'right'}}>
                <Input name={getInputName(`contribution_tag_suggesters_attributes[${i}].id`)} style={{display:'none'}}/>
                <Checkbox name={getInputName(`contribution_tag_suggesters_attributes[${i}].required`)}>{t('required', 'Required')}</Checkbox>
                <Checkbox name={getInputName(`contribution_tag_suggesters_attributes[${i}]._destroy`)}>
                    <Typography.Text type="danger">
                        {t('remove', 'Remove')}?
                    </Typography.Text>
                </Checkbox>
            </div>
        }>
            {/* Keep extra div wrapper here to ensure tabIndex goes into Popover: */}
            <div>
                <Popover
                    trigger={['hover','click']}
                    getPopupContainer={e => e.parentElement}
                    zIndex={1033}
                    title={ <Typography.Text editable={{onChange}} tabIndex={0}>{name}</Typography.Text> }
                    content={
                        <Select
                            tabIndex={0}
                            mode='tags'
                            onChange={onUpdateExisting}
                            value={tagNames}
                            style={{width:'100%'}}
                        />
                    }
                >
                    <Button type={'link'} className={'tag-suggester-form-name'}>
                        {name}
                        <Tag style={{marginLeft: '1em'}}>{tagNames?.length}</Tag>
                    </Button>
                </Popover>
            </div>
        </List.Item>
    )
}