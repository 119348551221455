import React, {useEffect, useRef, useState} from "react";
import useCurrentOrg from "../helpers/useCurrentOrg";
import api from "../api";
import {
    Button,
    Col,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Space,
    Switch,
    Table,
    Tag,
    Tooltip,
    Typography
} from "antd";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {CollectionIcon, ShareIcon, LightboxIcon, ProjectIcon, StorageFolderIcon} from "../helpers/icons";
import OrgNavLink from "../helpers/OrgNavLink";
import {
    ArrowRightOutlined,
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    LinkOutlined
} from "@ant-design/icons";
import WebhookForm from "./WebhookForm";
import Text from "antd/lib/typography/Text";
import StateIcon from "../widgets/StateIcon";
import useShowAsset from "../helpers/useShowAsset";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import useAssetViewer from "../helpers/useAssetViewer";
import VerticalSpace from "../helpers/VerticalSpace";
import ShareButton, {ShareDetails} from "../explore/ShareButton";
import {useTranslation} from "react-i18next";

export default ({asset, assetGroup, user})=>{
    const {t} = useTranslation();
    const [q, setQ] = useState()

    const search = e => {
        setQ(e.currentTarget.value);
    }

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 1000});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/shares'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-share-deleted','Share Deleted.'));
            handleTableChange();
        });
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            width: 200,
            render: (date, e) => <><TimeAgo date={date}/> by <User user={e.user}/></>
        },
        // {
        //     title: 'Enabled',
        //     dataIndex: 'enabled',
        //     width: 1,
        //     sorter: true,
        //     render: enabled => <EnabledDisabled enabled={enabled}/>,
        //     filters: [
        //         {text: 'Yes', value: 'true'},
        //         {text: 'No', value: 'false'},
        //     ]
        // },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            render: state => {
                return <StateIcon state={state}/>
            }
        },
    ]

    if(!assetGroup) {
        columns.push({
            title: t('lightbox','Lightbox'),
            render: (share) => {
                if(!share.asset_group_id) return <></>

                return (
                    <><LightboxIcon/> {share.asset_group_name}</>
                )
            }
        })
    }

    const ShareName = ({share})=>{
        const [name, setName] = useState(share.name)
        const onChange = value => {
            setName(value)
            api.put(`/api/shares/${share.id}`, {share: {name: value}}).then(res => {
                message.success(t('message-name-updated','Name updated!'))
            })
        }

        return (
            <Typography.Text editable={{onChange}}>{name}</Typography.Text>
        )
    }

    const ShareAssetsCount = ({assets_count, share})=> {
        const [visible, setVisible] = useState()

        const click = ()=>{
            setVisible(true)
        }

        return (<>
            <Tooltip title={t('tooltip-view-assets','View Assets')}>
                <Tag onClick={click} style={{cursor:'pointer'}}>{assets_count}</Tag>
            </Tooltip>
            <Modal
                title={<>{t('shared-assets','Shared Assets')}</>}
                open={visible}
                onCancel={()=> setVisible(false)}
                footer={null}
                overlayStyle={{width:'66vw'}}
                width={'66vw'}
                zIndex={9999}
            >
                <AssetSharesTable share={share} onClose={()=> setVisible(false)}/>
            </Modal>
        </>);
    }

    const ShareEmail = ({share})=> {
        const [showEmail, setShowEmail] = useState(share.show_email)

        const change = val => {
            setShowEmail(val)
            api.put(`/api/shares/${share.id}`, {share: {show_email: val}}).then(res => {
                message.success(t('updated', 'Updated'))
            })
        }

        return (
            <Switch checkedChildren={t('on','On')} unCheckedChildren={t('off','Off')} checked={showEmail} onChange={change}/>
        )
    }

    const ShareBanner = ({share})=> {
        const [showBanner, setShowBanner] = useState(share.show_banner)

        const change = val => {
            setShowBanner(val)
            api.put(`/api/shares/${share.id}`, {share: {show_banner: val}}).then(res => {
                message.success(t('updated', 'Updated'))
            })
        }

        return (
            <Switch checkedChildren={t('on','On')} unCheckedChildren={t('off','Off')} checked={showBanner} onChange={change}/>
        )
    }

    const ShareActions = ({share})=>{
        const [visible, setVisible] = useState()

        return (<>
            <Space size={5}>
                <Tooltip title={t('tooltip-view-share-details','View Share Details')}>
                    <Button onClick={()=> setVisible(true)} id={`view-share-${share.id}`}><ShareIcon/></Button>
                </Tooltip>

                <Modal
                    zIndex={2000}
                    open={visible}
                    onCancel={()=> setVisible(false)}
                    footer={null}
                    title={<><ShareIcon/> {t('share-details','Share Details')}</>}
                >
                    <ShareDetails share={share}/>
                </Modal>

                <Popconfirm
                    zIndex={1032}
                    title={t('confirm-delete-share','Delete Share?')}
                    onConfirm={() => destroy(share.id)}
                >
                    <Button size={'middle'}>
                        <DeleteOutlined/>
                    </Button>
                </Popconfirm>
            </Space>
        </>);
    }

    columns.push(
        {
            title: t('name','Name'),
            render: share => <ShareName share={share}/>
        },
        {
            title: t('image-video-size','Image/Video Size'),
            dataIndex: 'size',
            sorter: true,
            render: size => size.toProperCase()
        },
        {
            title: t('watermarked','Watermarked'),
            dataIndex: 'watermarked',
            sorter: true,
            render: wm => wm ? 'Yes' : 'No'
        },
        {
            title: t('assets','Assets'),
            dataIndex: 'assets_count',
            sorter: true,
            render: (assets_count, share) => <ShareAssetsCount assets_count={assets_count} share={share}/>
        },
        {
            title: t('show-email', 'Show Email?'),
            width:100,
            render: share => <ShareEmail share={share}/>
        },
        {
            title: t('show-banner', 'Show Banner?'),
            width:100,
            render: share => <ShareBanner share={share}/>
        },
        {
            key: 'action',
            width: 100,
            render: share => <ShareActions share={share}/>
        }
    );

    const [settings, setSettings] = useState({})

    const handleTableChange = (newPagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setPagination({...pagination, ...newPagination})
        setSettings({pagination, filters, sorter, q})
        setLoading(true);
        const params = {
            q,
            asset_id: asset?.id,
            asset_group_id: assetGroup?.asset_group_id,
            user_id: user?.id,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setPagination({ ...pagination, total: res.headers['total-entries'] })
            setData(res.data)
            setLoading(false)
        })
    }

    useEffect(handleTableChange, [q, asset?.id, user?.id, assetGroup?.id]);

    return (
        <VerticalSpace>
            <Row>
                <Col span={12}>
                    <Input onChange={search} value={q} style={{width:'100%'}} placeholder={t('placeholder-search-share','Search by Asset GUID, Filename, User email...')} allowClear/>
                </Col>

                {(assetGroup || asset) && (
                    <Col span={12} style={{textAlign: 'right'}}>
                        <ShareButton assetGroup={assetGroup} asset={asset} afterCreate={handleTableChange}/>
                    </Col>
                )}
            </Row>

            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, pageSize:100, position: ['none', 'none']}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        {pagination.total &&
                        <Text type={'secondary'} style={{marginRight:'.5em', marginLeft: 'auto'}}>
                            <em>
                                {pagination.total} Total Result{pagination.total != 1 && 's'}
                            </em>
                        </Text>
                        }
                    </div>
                )}
            />

        </VerticalSpace>
    );
}

const AssetSharesTable = ({share, onClose})=>{
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = `/api/shares/${share.id}/assets`
    const showAsset = useShowAsset()

    const click = mia => {
        onClose && onClose()
        showAsset(mia.asset_id)
    }

    const columns = [
        {
            title: t('thumb','Thumb'),
            render: mia => {
                return (
                    <a onClick={()=> click(mia)}>
                        <AssetBrowseImage asset={mia.asset} assetSize={40} thumbType={'thumb'} padding={'0.5em'}/>
                    </a>
                );
            }
        },
        {
            title: t('filename','Filename'),
            render: mia => {
                return (
                    <>
                        <Button type={'link'} onClick={()=> click(mia)}>{mia.asset.filename}</Button>
                    </>
                );
            }
        },
        {
            title: 'GUID',
            render: mia => (
                <Typography.Text copyable={{text: mia.asset.guid}} code>{mia.asset.guid.slice(0,8)}</Typography.Text>
            )
        },
        {
            title: t('status','Status'),
            render: mia => (
                <StateIcon obj={mia}/>
            )
        },
        {
            title: t('size', 'Size'),
            dataIndex: 'size',
        },
        {
            title: t('watermarked','Watermarked'),
            dataIndex: 'watermarked',
            sorter: true,
            render: wm => wm ? 'Yes' : 'No'
        },
    ];

    const params = useRef({})

    const handleTableChange = (pagination, filters, sorter) => {
        params.current = {
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadTableData()
    }, [share?.id])

    return (
        <VerticalSpace>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        {pagination.total &&
                        <Text type={'secondary'} style={{marginRight:'.5em', marginLeft: 'auto'}}>
                            <em>
                                {pagination.total} Total Result{pagination.total != 1 && 's'}
                            </em>
                        </Text>
                        }
                    </div>
                )}
            />

        </VerticalSpace>
    );
}
