//* `SelectedAssetsContext` (sessionStorage)
// 	* `LeftSideBar`
// 	* `BrowseGrid`; re-render of whole grid needed for every selection?
// 		* NO -- down to `Asset` level: useMemo that checks if selected
// 	* next: `AssetModal`; show selection control?
// * dispatch from `Asset`

import React, {createContext, useContext} from "react";
import { useStorageReducer } from 'react-storage-hooks';
import {SessionContext} from "./SessionContext";

const initialState = { selectedAssetIds: [], reloads: 0, selectedAssetGuids: [], dragging: false,
    previouslySelectedAssetIds: [], previouslySelectedAssetGuids: [] }

const SelectedAssetsStateContext = createContext(initialState);
const SelectedAssetsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('SelectedAssetsContext reducer:', action.type, action);

    switch (action.type) {
        case 'selectAsset':
            if (!state.selectedAssetIds) {
                state.selectedAssetIds = [];
            }

            if (state.selectedAssetIds.indexOf(action.id) == -1) {
                state.selectedAssetIds.push(action.id);
            }

            return {...state, selectedAssetIds: [...state.selectedAssetIds], selectedAssetGuids: [...state.selectedAssetGuids]};

        case 'deselectAsset':
            if (state.selectedAssetIds.indexOf(action.id) != -1) {
                state.selectedAssetIds.splice(state.selectedAssetIds.indexOf(action.id), 1);
            }

            return {...state, selectedAssetIds: [...state.selectedAssetIds], selectedAssetGuids: [...state.selectedAssetGuids]};

        case 'toggleAsset':
            let newIds, newGuids;
            if(state.selectedAssetIds.indexOf(action.id) == -1) {
                newIds = [...state.selectedAssetIds, action.id]
            } else {
                newIds = [..._.without(state.selectedAssetIds, action.id)]
            }

            return {...state, selectedAssetIds: newIds, selectedAssetGuids: newGuids || []};

        case 'toggleAssets':
            return {...state, selectedAssetIds: _.uniq([...state.selectedAssetIds, ...action.ids])};

        case 'setSelection':
            return {...state, selectedAssetIds: action.ids};

        case 'selectNone':
            if(!state.selectedAssetIds.length) return {...state}

            return {...state, selectedAssetIds: [], selectedAssetGuids: [],
                previouslySelectedAssetIds: state.selectedAssetIds, previouslySelectedAssetGuids: state.selectedAssetGuids};

        case 'undoSelectNone':
            return {...state, selectedAssetIds: state.previouslySelectedAssetIds, selectedAssetGuids: state.previouslySelectedAssetGuids,
                previouslySelectedAssetIds: [], previouslySelectedAssetGuids: []};

        case 'selectAll':
            // Note: don't use select-all for guid actions e.g. comment mentions
            return {...state, selectedAssetIds: action.ids, selectedAssetGuids: []};

        case 'reload':
            return {...state, reloads: state.reloads += 1};

        case 'draggingOn':
            return {...state, dragging: true};

        case 'draggingOff':
            return {...state, dragging: false};

        default:
            return state;
    }
}

function SelectedAssetsProvider({children}) {
    const {state: sessionState} = useContext(SessionContext);
    const key = `selected-assets-reducer-${sessionState?.currentOrg?.id || 'all'}`;
    const [state, dispatch, writeError] = useStorageReducer(sessionStorage, key, reducer, initialState);

    return (
        <SelectedAssetsStateContext.Provider value={state}>
            <SelectedAssetsDispatchContext.Provider value={dispatch}>
                {children}
            </SelectedAssetsDispatchContext.Provider>
        </SelectedAssetsStateContext.Provider>
    )
}

function useSelectedAssetsState() {
    const context = useContext(SelectedAssetsStateContext)
    if (context === undefined) {
        throw new Error('useSelectedAssetsState must be used within a SelectedAssetsProvider')
    }
    return context
}

function useSelectedAssetsDispatch() {
    const context = React.useContext(SelectedAssetsDispatchContext)
    if (context === undefined) {
        throw new Error('useSelectedAssetsDispatch must be used within a SelectedAssetsProvider')
    }
    return context
}

export {
    SelectedAssetsProvider,
    useSelectedAssetsState,
    useSelectedAssetsDispatch
};
