import {useState} from "react";
import api from "../api";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useCurrentAssetsState} from "@/contexts/CurrentAssetContext";

export default ()=> {
    const selectedAssetDispatch = useSelectedAssetsDispatch();
    const {assetCursorId} = useCurrentAssetsState()

    const {params} = useAggsState()

    const [selectingAll, setSelectingAll] = useState(false);

    const getAllIds = cb => {
        if(params) params.all_ids = true
        api.post('/api/assets/search', params).then(res => cb(res.data.ids))
    }

    const selectAll = (cb)=>{
        setSelectingAll(true)
        getAllIds(ids => {
            setSelectingAll(false)
            selectedAssetDispatch({type:'selectAll', ids})
            typeof(cb) === 'function' && cb(ids)
        })
    }

    const selectNone = ()=> {
        selectedAssetDispatch({type:'selectNone'})
    }

    const {selectedAssetIds} = useSelectedAssetsState();
    const toggleSelect = ()=>{
        selectedAssetIds.length ? selectNone() : selectAll()
    }

    return {selectingAll, selectAll, selectNone, getAllIds, toggleSelect}
}