import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import api from "../api";
import {Breadcrumb, Button, Col, message, Popconfirm, Popover, Row, Space, Table, Typography, DatePicker} from "antd";
import {OrgLink} from "../helpers/OrgNavLink";
import {Can} from "../helpers/Can";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import User from "../helpers/User";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import Search from "antd/lib/input/Search";
import queryString from "query-string";
import useShowAsset from "../helpers/useShowAsset";
import EnabledDisabled from "../helpers/EnabledDisabled";
import TimeAgo from "../helpers/TimeAgo";
import moment from 'moment'
import DateRangePicker from "../widgets/DateRangePicker";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ()=> {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/assets/popular'

    const showAsset = useShowAsset()

    const clickAsset = (asset)=>{
        showAsset(asset?.id)
    }

    const columns = [
        {
            title: t('uploaded','Uploaded'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: t('thumb','Thumb'),
            render: asset => (
                <a onClick={()=> clickAsset(asset)}>
                    <Popover
                        content={<img src={asset.grid_url}/>}
                        placement={'right'}
                    >
                        <img src={asset.thumb_url} style={{width:30}}/>
                    </Popover>
                </a>
            )
        },
        {
            title: t('filename','Filename'),
            render: asset => (
                <a onClick={()=> clickAsset(asset)}>{asset.filename}</a>
            )
        },
        {
            title: 'GUID',
            render: asset => (
                <Typography.Text code copyable>{asset.guid.slice(0,8)}</Typography.Text>
            )
        },
        {
            title: t('user','User'),
            render: asset => {
                return <User user={asset.user}/>
            }
        },
        {
            title: t('type','Type'),
            dataIndex: 'asset_type',
            filters: [
                {text: t('image','Image'), value: 'Image'},
                {text: t('video','Video'), value: 'Video'},
                {text: t('document','Document'), value: 'Document'},
                {text: t('audio','Audio'), value: 'Audio'},
                {text: t('font','Font'), value: 'Font'},
            ],
        },
        {
            title: t('views','Views'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'views_count'
        },
        {
            title: t('downloads','Downloads'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'asset_downloads_count'
        },
        {
            title: t('share-links','Share Links'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'share_links_count'
        },
    ];

    let location = useLocation()
    const navigate = useNavigate()

    const {search: searchQuery} = queryString.parse(location.search)

    const params = useRef({})
    const [q, setQ] = useState(searchQuery)
    const [dates, setDates] = useState()

    useEffect(()=>{
        if(searchQuery != q) setQ(searchQuery)
    }, [searchQuery])

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination, filters, sorter) => {
        setSettings({q, dates, pagination, filters, sorter})
        params.current = {
            q,
            dates,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(handleTableChange, [q, dates]);

    const onSearch = _.debounce((value) => {
        setQ(value)
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <Row gutter={8}>
                <Col lg={4} xs={24}>
                    <DateRangePicker onChange={dates => setDates(dates) } />
                </Col>
                <Col lg={8} xs={24}>
                    <Search
                        placeholder={t('placeholder-search-popularity','Search By Asset GUID, Filename, User...')}
                        onSearch={onSearch}
                        onChange={onChange}
                        style={{width:'100%'}}
                        size={'medium'}
                        allowClear
                        loading={loading}
                        value={q}
                    />
                </Col>
            </Row>

            <Table
                scroll={{x: true}}
                bordered
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </Space>
    );
}