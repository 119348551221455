import React, {createContext, useContext, useReducer} from "react";
import useSetCurrentAsset from "../components/helpers/useSetCurrentAsset";

const initialState = {
    page: 1,
    assetIds: [],
    assetsLoading: false,
    loadingMore: false,
    reloads: 0,
    tags: [],
    assetsPerRow: 0,
}

const LoadedAssetsStateContext = createContext(initialState);
const LoadedAssetsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('LoadedAssetsContext reducer:', action.type, action);

    let i, assetIds, newAssetId, newAssetIds, tags, newTags;

    switch (action.type) {
        // New Assets Loading:
        case 'assetsLoading':
            return {...state, assetsLoading: true, assetIds: []};

        // More Assets Loading (Table):
        case 'moreAssetsLoading':
            return {...state, assetsLoading: true};

        case 'assetsLoaded':
            if(state.assetIds.length != action.total) newAssetIds = new Array(action.total);
            else newAssetIds = state.assetIds;

            assetIds = action.assets.map(a => a.id)
            _.uniq(newAssetIds.splice((action.page - 1) * action.per_page, action.assets.length, ...assetIds));

            return {...state, assetsLoading: false, assetIds: newAssetIds};

        case 'assetsAdded':
            newTags = _.uniq(_.flatten(action.assets.map(asset => asset.tags)));
            tags = _.uniqBy([...state.tags, ...newTags], 'tag_id')

            return {...state, assetsLoading: false, assetIds: _.uniq([...state.assetIds, ...action.assets.map(a => a.id)]), tags};

        case 'reorderAssets':
            i = action.beforeAssetId ? state.assetIds.indexOf(action.beforeAssetId) : state.assetIds.length - 1
            state.assetIds = state.assetIds.filter(id => action.movingAssetIds.indexOf(id) == -1)
            state.assetIds.splice(i, 0, ...action.movingAssetIds)
            return {...state, assetIds: [...state.assetIds]};

        case 'reload':
            return {...state, reloads: state.reloads + 1 };

        case 'removeSelectedAssets':
            assetIds = _.filter(state.assetIds, id => action.selectedAssetIds.indexOf(id) == -1)
            return {...state, assetIds: [...assetIds], reloads: state.reloads + 1};

        case 'removeAsset':
            assetIds = _.filter(state.assetIds, id => id != action.id)
            return {...state, assetIds: [...assetIds], reloads: state.reloads + 1};

        case 'setAssetsPerRow':
            return {...state, assetsPerRow: action.assetsPerRow};

        default:
            return state;
    }
}

const LoadedAssetsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <LoadedAssetsStateContext.Provider value={state}>
            <LoadedAssetsDispatchContext.Provider value={dispatch}>
                {children}
            </LoadedAssetsDispatchContext.Provider>
        </LoadedAssetsStateContext.Provider>
    )
}

function useLoadedAssetsState() {
    const context = useContext(LoadedAssetsStateContext)
    if (context === undefined) {
        throw new Error('useAssetsState must be used within a LoadedAssetsProvider')
    }
    return context
}

function useLoadedAssetsDispatch() {
    const context = useContext(LoadedAssetsDispatchContext)
    if (context === undefined) {
        throw new Error('useAssetsDispatch must be used within a LoadedAssetsProvider')
    }
    return context
}

export {
    LoadedAssetsProvider,
    useLoadedAssetsState,
    useLoadedAssetsDispatch
};
