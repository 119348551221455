import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    bulkJobs: {},
    dataDownloads: {},
    finishedBulkJobIds: [],
}

const BulkJobsStateContext = createContext(initialState);
const BulkJobsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            state.bulkJobs[action.bulkJob.guid] = action.bulkJob
            return {...state, bulkJobs: {...state.bulkJobs}};

        // Received from ActionCable.jsx subscription:
        case 'progress':
            const bj = state.bulkJobs[action.guid] || {guid: action.guid}
            bj.progress = action.progress;
            bj.total_assets = action.total_assets
            bj.added = action.added;
            bj.removed = action.removed;
            bj.done = action.done;
            bj.error = action.error;
            bj.warnings = action.warnings;
            bj.canceled = action.canceled;
            state.bulkJobs[action.guid] = bj

            if(bj.progress === 100) {
                state.finishedBulkJobIds.push(action.guid)
            }

            return {...state, bulkJobs: {...state.bulkJobs}};

        case 'dataDownload':
            state.dataDownloads[action.data.guid] = action.data
            return {...state, dataDownloads: {...state.dataDownloads}}

        default:
            return state
    }
}

const BulkJobsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <BulkJobsStateContext.Provider value={state}>
            <BulkJobsDispatchContext.Provider value={dispatch}>
                {children}
            </BulkJobsDispatchContext.Provider>
        </BulkJobsStateContext.Provider>
    )
}

function useBulkJobsState() {
    const context = useContext(BulkJobsStateContext)
    if (context === undefined) {
        throw new Error('useBulkJobsState must be used within a Provider')
    }
    return context
}

function useBulkJobsDispatch() {
    const context = useContext(BulkJobsDispatchContext)
    if (context === undefined) {
        throw new Error('useBulkJobsDispatch must be used within a Provider')
    }
    return context
}

export {
    BulkJobsProvider,
    useBulkJobsState,
    useBulkJobsDispatch
};
