import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import setTitle from "../helpers/setTitle";
import Content from "../Content";
import CollectionsTable from "./CollectionsTable";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('collections','Collections'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-collections','Search Collections...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}
            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}
            >
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-collections'}/>
                </TabPane>
                <TabPane tab={t("browse-collections","Browse Collections")} key="browse">
                    <CollectionsTable q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
            </Tabs>

        </>
    );
}

