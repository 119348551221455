import useOrgSlug from "../helpers/useOrgSlug";
import React from "react";

export default ({})=> {
    const org = useOrgSlug()

    console.log(org?.login_background_asset)

    const style = org?.login_background_asset ? {
        background: `url(${org.login_background_asset.permalink_url}) no-repeat center center fixed`,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        height:'100vh',
        width:'100vw',
        position: 'fixed',
        top: 0,
        left: 0
    } : {}

    return <div style={style}></div>
}