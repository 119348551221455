import useCurrentOrg from "../helpers/useCurrentOrg";
import api from "../api";
import React, {useEffect, useState} from "react";
import {useFilters} from "../helpers/useFilters";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import useCurrentUser from "../helpers/useCurrentUser";
import {CheckOutlined, CloseCircleOutlined, CloudDownloadOutlined, EditOutlined} from "@ant-design/icons";
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Descriptions,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Tag,
    Typography, message, Space, Tooltip, Card, Divider
} from "antd";
import VerticalSpace from "../helpers/VerticalSpace";
import StateIcon from "../widgets/StateIcon";
import {EditableTextArea} from "../widgets/AssetModal";
import User from "../helpers/User";
import TimeAgo from "../helpers/TimeAgo";
import moment from "moment-timezone";
import day from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
day.extend(utc)
day.extend(timezone)
import {useViewMode} from "./ViewMenu";
import {useNavigate} from "react-router-dom-v5-compat";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import {getLocale} from "../widgets/DateRangePicker";

export default ({accessRequest})=>{
    const {t} = useTranslation();
    const locale = getLocale()
    const currentOrg = useCurrentOrg()

    const [attrs, setAttrs] = useState(accessRequest)

    useEffect(()=>{
        setAttrs(accessRequest)
    }, [accessRequest?.id])

    const editable = attrs.aasm_state === 'pending'
    const managable = attrs.managable && attrs.aasm_state === 'submitted'

    const onChange = field => {
        return value =>{
            const data = {access_request: {}}
            data.access_request[field] = value
            attrs[field] = value
            setAttrs({...attrs})
            api.put(`/api/access_requests/${accessRequest.id}`, data).then(res => {
                message.success(t('message-access-request-updated','Access Request Updated!'))
            })
        }
    }

    const [submitting, setSubmitting] = useState()

    const {clearFilters} = useFilters()
    const assetGroupDispatch = useAssetGroupDispatch();
    const {currentWorkflowStep} = useAssetGroupState()

    const submit = ()=> {
        // TODO: check fields all set
        setSubmitting(true)

        const [action, verb] = accessRequest.aasm_state === 'pending' ? ['submit', 'Submitted'] : ['finalize', 'Finalized']
        api.post(`/api/access_requests/${accessRequest.id}/${action}`).then(res => {
            setSubmitting(false)
            message.success(t(`message-access-request-${verb}`,`Access Request ${verb}!`))
            setAttrs(res.data)

            if(action === 'finalize') {
                clearFilters()
                assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: res.data.asset_group});
            }
        })
    }

    const navigate = useNavigate();
    const getPath = useOrgPath()

    const cancel = ()=>{
        setSubmitting(true)

        api.delete(`/api/access_requests/${accessRequest.id}`).then(res => {
            setSubmitting(false)
            message.success(t('message-access-request-canceled',`Access Request Canceled!`))
            navigate(getPath('/explore'))
        })
    }

    const revoke = ()=>{
        setSubmitting(true)

        api.post(`/api/access_requests/${accessRequest.id}/revoke`).then(res => {
            setSubmitting(false)
            message.success(t('message-access-request-revoked',`Access Request Revoked!`))
            setAttrs(res.data)
        })
    }

    const currentUser = useCurrentUser()
    const finalized = attrs?.aasm_state === 'finalized'
    const submitted = attrs?.aasm_state === 'submitted'

    // ---------------------
    // Custom Fields:
    // ---------------------

    const [valuesLoading, setValuesLoading] = useState(true)
    const [customMetaValues, setCustomMetaValues] = useState(accessRequest.custom_meta_values)

    useEffect(()=>{
        if(accessRequest.custom_meta_values) {
            setCustomMetaValues(accessRequest.custom_meta_values)
            setValuesLoading(false)
        }
        else setValuesLoading(true)
    }, [!!accessRequest.custom_meta_values, accessRequest.id])

    const [fieldsLoading, setFieldsLoading] = useState(true)
    const [customMetaFields, setCustomMetaFields] = useState([])

    useEffect(()=>{
        setFieldsLoading(true)
        api(`/api/custom_meta_fields/access_requests`).then(res => {
            setFieldsLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [currentOrg?.id])

    const customMetaValuesGrouped = {}
    if(!(editable || managable)) {
        customMetaValues.map(cmv => {
            if(!customMetaValuesGrouped[cmv.custom_meta_field_id]) customMetaValuesGrouped[cmv.custom_meta_field_id] = []
            customMetaValuesGrouped[cmv.custom_meta_field_id].push(cmv);
        })
    }

    const onCmfChange =  cmf =>{
        return value => {
            const data = {custom_meta_field_id: cmf.id}

            // Either text value for freeform, or id(s) of value:
            if(cmf.free) data.text = value
            else data.custom_meta_value_id = value

            api.put(`/api/access_requests/${accessRequest.id}/update_custom_meta`, data).then(res => {
                setCustomMetaValues(res.data.custom_meta_values)
                message.success(`${cmf.name} ${t('updated','Updated')}!`)
            })
        }
    }

    const collapseChange = ()=>{
        setTimeout(()=>{
            assetGroupDispatch({type:'reinitializeGrid'})
        }, 250)
    }

    const pendingCount = currentWorkflowStep?.pending_count + currentWorkflowStep?.picked_count

    const isSubmitted = attrs?.submitted_at

    const downloadAll = ()=>{
        setSubmitting(true)

        api.post( '/api/downloads', {download: {access_request_id: accessRequest.id, via: 'web', size: 'original', watermark: false }}).then(res => {
            setSubmitting(false)

            if(res.data.token) {
                const url = `/api/downloads/${res.data.token}`
                const a = document.createElement("a")
                a.setAttribute('href', url)
                a.setAttribute('download', res.data.filename)
                a.setAttribute('_target', 'blank')
                a.style.display = 'none';
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a);
            } else {
                message.error(t('error-download-error','There an error preparing your download.'))
            }

        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <VerticalSpace>
            <Collapse defaultActiveKey={'details'} onChange={collapseChange} style={{margin: '1em 1em 0 0'}}>
                <Collapse.Panel key={'details'} header={<><EditOutlined/> Details</>}>
                    <Row style={{marginTop: '1em'}} gutter={[16, 16]}>
                        <Col span={12}>
                            <VerticalSpace>
                                <div>
                                    <strong>{t('status','Status')}:</strong> <StateIcon state={t(attrs?.aasm_state,attrs?.aasm_state)}/>
                                </div>

                                {accessRequest.user.guest ? (
                                    <VerticalSpace>
                                        <div>
                                            <strong>{t('requester-name','Requester Name')}:</strong>
                                            <br/>
                                            <Typography.Text editable={editable && {onChange: onChange('requester_name')}}>{attrs.requester_name}</Typography.Text>
                                        </div>

                                        <div>
                                            <strong>{t('requester-email','Requester Email')}:</strong>
                                            <br/>
                                            <Typography.Text editable={editable && {onChange: onChange('requester_email')}}>{attrs.requester_email}</Typography.Text>
                                        </div>
                                    </VerticalSpace>
                                ) : accessRequest.submitted_at && (
                                    <div>
                                        <strong>{t('requested-by','Requested By')}:</strong> <User user={accessRequest.user}/> <TimeAgo date={accessRequest.submitted_at}/>
                                    </div>
                                )}

                                <div>
                                    <strong>{t('name-of-request','Name of Request')}:</strong>
                                    <br/>
                                    <Typography.Text editable={editable && {onChange: onChange('name')}}>{attrs.name}</Typography.Text>
                                </div>

                                <div>
                                    <strong>{t('request-deadline','Request Deadline')}:</strong>
                                    <br/>
                                    <DatePicker defaultValue={attrs?.request_deadline ? day.tz(attrs?.request_deadline, 'UTC') : null}
                                                onChange={onChange('request_deadline')} disabled={!editable} locale={locale}/>
                                </div>

                                <div>
                                    <strong>{t('description-of-request','Description of Request')}:</strong>
                                    <br/>
                                    <EditableTextArea
                                        value={attrs[isSubmitted ? 'modified_description' : 'description']}
                                        onChange={onChange(isSubmitted ? 'modified_description' : 'description')}
                                        editable={editable || managable}
                                    />
                                </div>

                                {isSubmitted && (
                                    <VerticalSpace>
                                        <div>
                                            <strong>{t('approval-notes','Approval Notes')}:</strong>
                                            <br/>
                                            <EditableTextArea value={attrs.note} onChange={onChange('note')} editable={managable}/>
                                        </div>
                                    </VerticalSpace>
                                )}
                            </VerticalSpace>
                        </Col>

                        <Col span={12}>
                            <VerticalSpace>
                                <Skeleton active loading={valuesLoading || fieldsLoading}>
                                    {(editable || managable) ? (
                                        <Descriptions bordered size='small' column={1}>
                                            {customMetaFields.map(cmf => {
                                                const cmvs = customMetaValues.filter(cmv => cmv.custom_meta_field_id === cmf.id)

                                                return (
                                                    <Descriptions.Item
                                                        label={<>{cmf.name}<br/>{cmf.description && <small><em>{cmf.description}</em></small>}</>}
                                                        key={cmf.name}
                                                        style={{width: '33%'}}
                                                    >
                                                        {cmf.free ? (
                                                            <Typography.Text editable={{onChange: onCmfChange(cmf)}}>{cmvs[0]?.value}</Typography.Text>
                                                        ) : (
                                                            <Select
                                                                name={cmf.name}
                                                                mode={cmf.multi && 'multiple'}
                                                                placeholder={t(`choose-${cmf.multi ? 'multiple' : 'one'}`, `Choose ${cmf.multi ? 'Multiple...' : 'One...'}`)}
                                                                style={{width: '100%'}}
                                                                allowClear
                                                                onChange={onCmfChange(cmf)}
                                                                value={cmf.multi ? cmvs.map(cmv => cmv.id) : cmvs[0]?.id}
                                                            >
                                                                {cmf.custom_meta_values_attributes.map(cmv => (
                                                                    <Select.Option key={cmv.id} value={cmv.id}>{cmv.text}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}

                                                    </Descriptions.Item>
                                                );
                                            })}
                                        </Descriptions>
                                    ): (
                                        <Descriptions bordered size='small' column={1}>
                                            {Object.keys(customMetaValuesGrouped).map(id => {
                                                const values = customMetaValuesGrouped[id]
                                                const {name, description} = values[0]
                                                return (
                                                    <Descriptions.Item
                                                        label={
                                                            <>{name}<br/>{description && <small><em>{description}</em></small>}</>
                                                        }
                                                        key={name}
                                                        style={{width: '33%'}}
                                                    >
                                                        {values.map(v => v.value).join(', ')}
                                                    </Descriptions.Item>
                                                );
                                            })}
                                        </Descriptions>
                                    )}
                                </Skeleton>
                            </VerticalSpace>
                        </Col>
                    </Row>
                    <Divider>
                        <CloudDownloadOutlined/> {t('requested-file-access','Requested File Access')}
                    </Divider>
                    <Row>
                        <Col span={24}>
                            <VerticalSpace>
                                <div>
                                    <strong>{t('image-and-video-download-size','Image and Video Download Size')}:</strong>
                                    <br/>
                                    {attrs?.submitted_at ? (
                                        <>
                                            {t('requested','Requested')}: <Tag>{t(accessRequest.requested_image_and_video_permission, accessRequest.requested_image_and_video_permission)}</Tag>

                                            {submitted && managable ? (
                                                <Select value={attrs.granted_image_and_video_permission} style={{width:'100%'}} placeholder={`${t('select','Select')}...`} onChange={onChange('granted_image_and_video_permission')}>
                                                    <Select.Option value={'original'}>{t('images-and-video','Images and Video')} - {t('download-original-file','Download Original File')}</Select.Option>
                                                    <Select.Option value={'full'}>{t('images-and-video','Images and Video')} - {t('download-full-resolution','Download Full Resolution')}</Select.Option>
                                                    <Select.Option value={'medium'}>{t('images-and-video','Images and Video')} - {t('download-medium-resolution','Download Medium Resolution')}</Select.Option>
                                                    <Select.Option value={'small'}>{t('images-and-video','Images and Video')} - {t('download-low-resolution','Download Low Resolution')}</Select.Option>
                                                    <Select.Option value={'none'}>{t('no-download','No Download')}</Select.Option>
                                                </Select>
                                            ) : finalized && (
                                                <>{t('granted','Granted')}: <Tag>{t(attrs.granted_image_and_video_permission, attrs.granted_image_and_video_permission)}</Tag></>
                                            )}
                                        </>
                                    ) : (
                                        <Select value={attrs.requested_image_and_video_permission} style={{width:'100%'}} placeholder={`${t('select','Select')}...`} onChange={onChange('requested_image_and_video_permission')}>
                                            <Select.Option value={'original'}>{t('images-and-video','Images and Video')} - {t('download-original-file','Download Original File')}</Select.Option>
                                            <Select.Option value={'full'}>{t('images-and-video','Images and Video')} - {t('download-full-resolution','Download Full Resolution')}</Select.Option>
                                            <Select.Option value={'medium'}>{t('images-and-video','Images and Video')} - {t('download-medium-resolution','Download Medium Resolution')}</Select.Option>
                                            <Select.Option value={'small'}>{t('images-and-video','Images and Video')} - {t('download-low-resolution','Download Low Resolution')}</Select.Option>
                                            <Select.Option value={'none'}>{t('no-download','No Download')}</Select.Option>
                                        </Select>
                                    )}
                                </div>

                                <div>
                                    <strong>{t('other-file-types','Other File Types')}:</strong>
                                    <br/>
                                    {attrs?.submitted_at ? (
                                        <>
                                            {t('requested','Requested')}: <Tag>{t(accessRequest.requested_other_permission,accessRequest.requested_other_permission)}</Tag>

                                            {attrs.aasm_state === 'submitted' && managable ? (
                                                <Select value={attrs.granted_other_permission} style={{width:'100%'}} placeholder={`${t('select','Select')}...`} onChange={onChange('granted_other_permission')}>
                                                    <Select.Option value={'original'}>{t('download-original-file','Download Original File')}</Select.Option>
                                                    <Select.Option value={'none'}>{t('no-download','No Download')}</Select.Option>
                                                </Select>
                                            ) : finalized && (
                                                <>{t('granted','Granted')}: <Tag>{t(attrs.granted_other_permission,attrs.granted_other_permission)}</Tag></>
                                            )}
                                        </>
                                    ) : (
                                        <Select value={attrs.requested_other_permission} style={{width:'100%'}} placeholder={`${t('select','Select')}...`} onChange={onChange('requested_other_permission')}>
                                            <Select.Option value={'original'}>{t('download-original-file','Download Original File')}</Select.Option>
                                            <Select.Option value={'none'}>{t('no-download','No Download')}</Select.Option>
                                        </Select>
                                    )}
                                </div>

                            </VerticalSpace>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>

            <div style={{float:'right', marginRight:'1em'}}>
                {attrs.aasm_state === 'pending' && (
                    <Space>
                        <Tooltip title={t('tooltip-submit-access-request','You can modify any part of your request, including adding or removing files, until you submit.')} placement={'left'}>
                            <Popconfirm onConfirm={submit} title={t('confirm-submit-access-request','Submit Request?')}>
                                <Button icon={<CheckOutlined/>} type={'primary'} loading={submitting}>{t('button-submit-access-request','Submit Access Request')}</Button>
                            </Popconfirm>
                        </Tooltip>

                        <Popconfirm onConfirm={cancel} title={'Cancel Request?'} okText={'Yes'} cancelText={'No'}>
                            <Button icon={<CloseCircleOutlined/>} danger loading={submitting}>{t('button-cancel','Cancel')}</Button>
                        </Popconfirm>
                    </Space>
                )}

                {attrs.aasm_state === 'submitted' && managable && (
                    <Tooltip title={pendingCount > 0 && <>{t('tooltip-access-request-not-done','You are not quite done yet. Please set the remaining {{count}} Pending or Picked assets to Approved or Rejected.', {count: pendingCount})}</>}>
                        <Popconfirm onConfirm={submit} title={t('tooltip-finalize-access-request','Finalize? Only Approved Assets will be granted access.')}>
                            <Button icon={<CheckOutlined/>} type={'primary'} loading={submitting} disabled={pendingCount > 0}>{t('button-finalize-access-request','Finalize Access Request')}</Button>
                        </Popconfirm>
                    </Tooltip>
                )}

                {finalized && managable && (
                    <Popconfirm onConfirm={revoke} title={t('confirm-revoke-access-request','Revoke? This cannot be undone.')}>
                        <Button icon={<CloseCircleOutlined/>} danger loading={submitting}>{t('button-revoke-access-request','Revoke Access')}</Button>
                    </Popconfirm>
                )}

                {finalized && !managable && (
                    <Button icon={<CloudDownloadOutlined/>} type={'primary'} ghost onClick={downloadAll}>{t('button-download-all','Download All')}</Button>
                )}
            </div>
        </VerticalSpace>
    )
}
