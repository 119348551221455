import {useBulkJobsState} from "../../contexts/BulkJobsContext";
import React, {useEffect, useState} from "react";
import {Alert, message, Popconfirm, Progress, Button} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import VerticalSpace from "@/components/helpers/VerticalSpace";

export default ({id, onFinish, onError, onCancel}) => {
    const {t} = useTranslation();

    const {bulkJobs} = useBulkJobsState()
    const bulkJob = bulkJobs[id]

    useEffect(()=>{
        if(bulkJob?.progress == 100) onFinish && onFinish(bulkJob);
    }, [bulkJob?.progress])

    useEffect(()=>{
        if(bulkJob?.error) onError && onError(bulkJob);
    }, [bulkJob?.error])

    const warnings = bulkJob?.warnings?.map((warning,i) => (
        <div key={i} style={{marginBottom:'1em'}}>
            {warning.asset && (
                <div>
                    <strong>{warning.asset.filename} <small>({warning.asset.guid})</small></strong>
                </div>
            )}
            <em>Warning: {warning.message}</em>
        </div>)
    )

    const cancel = ()=> {
        setCanceling(true)

        api.post(`/api/bulk_jobs/${id}/cancel`).then(res => {
            setCanceling(false)
            setCanceled(true)
            onCancel && onCancel()
        })
    }

    const [canceling, setCanceling] = useState()
    const [canceled, setCanceled] = useState()

    return (
        <VerticalSpace>
            {!canceled && bulkJob?.progress < 100 && (
                <VerticalSpace>
                    <Progress percent={bulkJob?.progress || 0} status={bulkJob?.error ? 'error' : 'active'}/>

                    <Popconfirm
                        title={t('cancel-job', 'Cancel Bulk Job?')}
                        onConfirm={cancel}
                        zIndex={9999}
                    >
                        <Button danger ghost icon={<CloseCircleOutlined/>} loading={canceling} size={'small'}>Cancel</Button>
                    </Popconfirm>
                </VerticalSpace>
            )}

            {bulkJob?.error && <Alert message={bulkJob.error} type="error" showIcon/>}

            {!!warnings?.length && <Alert message={warnings} type="warning" showIcon/>}

            {canceled && (
                <Alert message={t('message-bulk-job-canceled',`Bulk job canceled.`)} type="warning" showIcon/>
            )}

        </VerticalSpace>
    )
}
