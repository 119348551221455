import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    currentAssetId: null,
    cursorAssetId: null,
    zoomAsset: null
}

const CurrentAssetsStateContext = createContext(initialState);
const CurrentAssetsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    // console.log('CurrentAssetsContext reducer:', action.type, action);

    switch (action.type) {
        case 'setCurrentAsset':
            const {id} = action.asset || {}
            return {...state, currentAssetId: id };

        case 'setCursorAsset':
            window.cursorAssetId = action.asset?.id;

            return {...state, cursorAssetId: action.asset?.id };

        case 'setZoomAsset':
            return {...state, zoomAsset: action.asset };

        default:
            return state;
    }
}

const CurrentAssetsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <CurrentAssetsStateContext.Provider value={state}>
            <CurrentAssetsDispatchContext.Provider value={dispatch}>
                {children}
            </CurrentAssetsDispatchContext.Provider>
        </CurrentAssetsStateContext.Provider>
    )
}

function useCurrentAssetsState() {
    const context = useContext(CurrentAssetsStateContext)
    if (context === undefined) {
        throw new Error('useCurrentAssetsState must be used within a CurrentAssetsProvider')
    }
    return context
}

function useCurrentAssetsDispatch() {
    const context = useContext(CurrentAssetsDispatchContext)
    if (context === undefined) {
        throw new Error('useCurrentAssetsDispatch must be used within a CurrentAssetsProvider')
    }
    return context
}

export {
    CurrentAssetsProvider,
    useCurrentAssetsState,
    useCurrentAssetsDispatch
};
