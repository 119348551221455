import {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import useCurrentOrg from "./useCurrentOrg";

const useCurrentUser = ()=>{
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    return currentUser
}

const useTimezone = ()=>{
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()

    return currentUser?.timezone || currentOrg?.timezone || 'America/Los_Angeles'
}

export default useCurrentUser
export {useTimezone}