import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    Modal,
    Progress,
    Spin,
    Table,
    Tag,
    Timeline,
    Typography,
    message,
    Popconfirm, Rate, Skeleton, Select, Card, Tooltip, Checkbox, Space, Popover, Badge
} from "antd";

import filesize from 'filesize'
import TimeAgo from "../helpers/TimeAgo";

import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import {DragPreviewImage, useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import TagSelect from "./TagSelect";
import AssetGroupWorkflows from "./AssetGroupWorkflows";
import {RightsIcon} from "../helpers/icons";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import AutoSizer from "react-virtualized-auto-sizer";
import UploadProgress from "../uploads/UploadProgress";
import {useFilters} from "../helpers/useFilters";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";
import AssetRequirementsInfo from "./AssetRequirementsInfo";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAssetsPageDispatch, useAssetsPageState} from "../../contexts/AssetsPageContext";
import {useUploadsState} from "../../contexts/UploadsContext";
import {
    ClockCircleTwoTone,
    EyeInvisibleOutlined,
    EyeOutlined,
    FileTextFilled,
    FileTextOutlined,
    TagOutlined
} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import RightsPackage from "./RightsPackage";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../helpers/Can";
import {useAssetLoader} from "../explore/AssetsManager";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";
import {useTranslation} from "react-i18next";

const assetSize = 75;

export default ({})=> {
    const {t} = useTranslation();
    const ability = useAbility(AbilityContext);

    const {assetIds, assetsLoading, moreAssetsLoading} = useLoadedAssetsState()
    const total = assetIds.length

    const assetsPageDispatch = useAssetsPageDispatch()

    const {page} = useAssetsPageState()
    const tableAssetIds = _.compact(assetIds.slice((page - 1) * 25, (page - 1) * 25 + 25))

    const {currentWorkflowStep, currentUpload: upload} = useAssetGroupState()

    const [tagsExpanded, setTagsExpanded] = useState(!localStorage.getItem('tags-hidden'))
    const toggleTags = ()=>{
        if(tagsExpanded) {
            localStorage.setItem('tags-hidden', true)
        } else {
            localStorage.removeItem('tags-hidden', true)
        }

        setTagsExpanded(!tagsExpanded)
    }

    const [descriptionExpanded, setDescriptionExpanded] = useState(!localStorage.getItem('description-hidden'))
    const toggleDescription = ()=>{
        if(descriptionExpanded) {
            localStorage.setItem('description-hidden', true)
        } else {
            localStorage.removeItem('description-hidden', true)
        }

        setDescriptionExpanded(!descriptionExpanded)
    }

    const columnDefs = [
        {
            title: t('thumb','Thumb'),
            width: assetSize + 20,
            render: ({id}) => <ThumbCol id={id} key={id}/>
        },
        {
            title: t('uploaded','Uploaded'),
            key: 'created_at',
            sorter: true,
            render: ({id}) => <UploadedCol id={id} key={id}/>,
            shouldCellUpdate: () => false,
            width: 100,
            details: true
        },
        {
            title: t('filename','Filename'),
            key: 'filename',
            sorter: true,
            width: 150,
            render: ({id}) => <FilenameCol id={id} key={id}/>
        },
        {
            title: t('file-size','File Size'),
            key: 'file_size',
            sorter: true,
            render: ({id}) => <FilesizeCol id={id} key={id}/>,
            shouldCellUpdate: () => false,
            width: 100
        },
        {
            title: (
                <>
                    {t('description','Description')}
                    <Tooltip title={descriptionExpanded ? t('tooltip-hide-description','Hide Description') : t('tooltip-expand-description','Expand Description')}>
                        <Button type={'text'} icon={descriptionExpanded ? <EyeInvisibleOutlined/> : <EyeOutlined/>} onClick={toggleDescription} style={{color:'#8d8d8d'}}/>
                    </Tooltip>
                </>
            ),
            width: descriptionExpanded ? true : 100,
            render: ({id}) => <DescriptionCol id={id} key={id} descriptionExpanded={descriptionExpanded}/>
        },
        {
            title: (
                <>
                    {t('tags','Tags')}
                    <Tooltip title={tagsExpanded ? t('tooltip-hide-tags','Hide Tags') : t('tooltip-expand-tags','Expand Tags')}>
                        <Button type={'text'} icon={tagsExpanded ? <EyeInvisibleOutlined/> : <EyeOutlined/>} onClick={toggleTags} style={{color:'#8d8d8d'}}/>
                    </Tooltip>
                </>
            ),
            width: tagsExpanded ? 300 : 100,
            render: ({id}) => <TagsCol id={id} key={id} expanded={tagsExpanded}/>,
        },
        {
            title: t('rating','Rating'),
            width: 150,
            key: 'rating',
            sorter: true,
            render: ({id}) => <RatingCol id={id} key={id}/>,
            details: true
        },
    ];

    if(upload) {
        columnDefs.push({
            title: t('rights','Rights'),
            render: ({id}) => <RightsCol id={id} key={id}/>
        })

        columnDefs.push({
            title: t('status','Status'),
            width: 150,
            render: ({id}) => <StatusCol id={id} key={id}/>
        })
    }

    if(currentWorkflowStep) {
        columnDefs.push({
            title: t('workflow','Workflow'),
            // filters: [
            //     {text: 'Approved', value: 'approved'},
            //     {text: 'Rejected', value: 'rejected'},
            //     {text: 'Pending', value: 'pending'},
            // ],
            render: ({id}) => <WorkflowCol id={id}/>
        })
    }

    const columns = ability.can('view_details', 'Asset') ? columnDefs : _.filter(columnDefs, c => !c.details);

    const {selectedAssetIds: selectedRowKeys} = useSelectedAssetsState()
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const onChange = (newSelectedKeys, assets)=> {
        // TODO: need bulk selector, or calc diff
        selectedAssetsDispatch({type:'selectNone'});

        assets.map(({id}) => selectedAssetsDispatch({type:'selectAsset', id}))
    }

    const {setFilters} = useFilters();
    useEffect(()=>{
        setFilters(true, {gps: null})
    }, [])

    const {assetLoader} = useAssetLoader()
    const [loadingPage, setLoadingPage] = useState()

    const handleTableChange = (pagination, filter, sorter)=>{
        if(sorter.columnKey) setFilters(false, {sort: sorter.columnKey, order: sorter.order })

        setLoadingPage(true)
        assetLoader({
            page: pagination.current,
            cb: ()=>{
                assetsPageDispatch({type:'setPage', page: pagination.current})
                setLoadingPage(false)
            }
        })
    }

    const dataSource = tableAssetIds.map(id => { return {id} })

    return (
        <AutoSizer>
            {({height, width}) => (
                <div style={{width:width, height:'100%'}}>
                    <Table
                        rowSelection={{onChange, selectedRowKeys}}
                        loading={loadingPage || assetsLoading || moreAssetsLoading}
                        // bordered
                        size={'small'}
                        rowKey={'id'}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{defaultPageSize: 25, total: total, current: page, showSizeChanger: false }}
                        onChange={handleTableChange}
                        scroll={{y: height, scrollToFirstRowOnChange: true, x: true}}
                    />
                </div>
            )}
        </AutoSizer>
    );
}

//-------------------------------------------------------------------
// Renderers
//-------------------------------------------------------------------

const ThumbCol = ({id})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    const setCurrentAsset = useSetCurrentAsset()

    const [{ opacity }, drag, preview] = useDrag({
        item: { asset, type: 'asset' },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        })
    });

    return (
        <div id={`asset-${id}`} className={`state-${asset.aasm_state}`}>
            <div ref={drag}>
                <DragPreviewImage src={getEmptyImage().src} connect={preview}/>

                <a className={'asset-modal-btn'} onClick={()=> setCurrentAsset(asset)} id={`asset-show-link-${asset.id}`}>
                    <AssetBrowseImage asset={asset} assetSize={assetSize} thumbType={'thumb'} padding={'0.5em'}/>
                </a>

                <Typography.Text copyable={{tooltips: 'Copy GUID', text: asset.guid}}></Typography.Text>
            </div>
        </div>
    );
}

const UploadedCol = ({id})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    const {uploadingAssets} = useUploadsState();
    const {percent, stats} = _.find(uploadingAssets, {id}) || {};

    return useMemo(()=> {
        return (asset.aasm_state == 'uploading' || asset.aasm_state == 'processing') ?
            <UploadProgress asset={asset} width={90}/>
            : (
                <small>
                    <TimeAgo date={asset.created_at}/>
                    <AssetRequirementsInfo asset={asset}/>
                </small>
            )
    }, [
        percent, stats,
        asset?.aasm_state,
        asset?.submitted, asset?.submitted_at, asset?.all_requirements_met, asset?.tag_suggesters_met, asset?.rights_package_met,
        asset?.thumb_url, asset?.meta_extracted, asset?.grid_url, asset?.permalink_url, asset?.final_storage
    ])
}

const FilenameCol = ({id})=> {
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    return (
        <small>
            <Typography.Text code copyable>{asset.filename}</Typography.Text>
            {asset.expired && (
                <Tooltip title={<>Rights Expired <TimeAgo date={asset.expires_at}/></>}>
                    <ClockCircleTwoTone twoToneColor={'red'}/>
                </Tooltip>
            )}
            {asset.contract && (
                <Tooltip title={t('tooltip-this-asset-is-a-contract','This Asset is a Contract')}>
                    <FileTextOutlined style={{margin:'0 .5em'}}/>
                </Tooltip>
            )}
            {asset.release && (
                <Tooltip title={t('tooltip-this-asset-is-a-release','This Asset is a Release')}>
                    <FileTextOutlined style={{margin:'0 .5em'}}/>
                </Tooltip>
            )}
        </small>
    )
}

const FilesizeCol = ({id})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    return (
        <small>{filesize(asset.file_size)}</small>
    )
}

const DescriptionCol = ({id, descriptionExpanded})=> {
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    const dispatch = useAssetsDispatch()

    const [value, setValue] = useState(asset.description || '');

    useEffect(() => {
        setValue(asset.description || '')
    }, [asset.description])

    const onChange = (newValue)=>{
        setValue(newValue);
        api.put(`/api/assets/${asset.id}`, {asset:{description: newValue}}).then(res => {
            dispatch({type:'updateAsset', asset: res.data})
            message.success(t('message-description-updated','Description updated.'))
        })
    }

    const Description = ()=>(
        <small>
            <Typography.Paragraph
                editable={asset.editable && {onChange}}
            >
                {value}
            </Typography.Paragraph>
        </small>
    )

    return (
        <>
            {descriptionExpanded && <Description/> || (
                <Popover
                    title={t('description','Description')}
                    content={<Description/>}
                >
                    <EyeOutlined/>
                </Popover>
            )}
        </>
    )
}

const TagsCol = ({id, expanded})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    const {currentUpload: upload} = useAssetGroupState()

    if(expanded) {
        return (
            <TagSelect asset={asset} upload={upload} popoverPlacement={'left'}/>
        )
    } else {
        return (
            <Popover
                title={<><TagOutlined/> Tags</>}
                content={<TagSelect asset={asset} upload={upload}/>}
                overlayStyle={{width:400}}
                placement={'left'}
            >
                <Badge count={asset.tags.length} style={{ backgroundColor: '#52c41a' }} size={'small'}>
                    <Button icon={<TagOutlined/>} type={'text'}/>
                </Badge>
            </Popover>
        )

    }

}

const RatingCol = ({id})=> {
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    const dispatch = useAssetsDispatch()

    const [rating, setRating] = useState(asset.rating);

    useEffect(() => {
        setRating(asset.rating)
    }, [asset.rating])

    const onChange = (newValue)=>{
        console.log(newValue)
        setRating(newValue);
        api.put(`/api/assets/${asset.id}`, {asset:{rating: newValue}}).then(res => {
            dispatch({type:'updateAsset', asset: res.data})
            message.success(t('message-rating-updated','Rating updated.'))
        })
    }
    return <Rate value={rating} onChange={onChange} disabled={!asset.editable} style={{fontSize:14}}/>
}

const RightsCol = ({id})=> {
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    const dispatch = useAssetsDispatch()
    const editAssetDispatch = useEditAssetsDispatch()

    const {currentUpload: upload} = useAssetGroupState()

    const [rightsPackageId, setRightsPackageId] = useState(asset?.rights_package_id);
    const rightsPackageUpdated = (value)=>{
        setRightsPackageId(value);
        api.put(`/api/assets/${asset.id}`, {asset:{rights_package_id: value}}).then(res => {
            dispatch({type:'updateAsset', asset: res.data})
            editAssetDispatch({type:'increment'})
            message.success(t('message-rights-package-updated','Rights Package updated.'))
        })
    }

    const rightsPackage = _.find(upload.contribution.rights_packages, {id: rightsPackageId})

    const onChangeLegal = type => {
        return e => {
            const checked = e.target.checked

            const data = {}
            data[type == 'contract' ? 'contract' : 'release'] = checked

            api.put(`/api/assets/${asset.id}`, {asset: data}).then(res => {
                dispatch({type:'updateAsset', asset: res.data})
                editAssetDispatch({type:'increment'})
                message.success(`${t('asset','Asset')} ${t(type,type)} ${checked ? t('enabled','Enabled') : t('disabled','Disabled')}.`)
            })
        }
    }

    return (
        <>
            {asset.editable ? (
                <>
                    <Select onChange={rightsPackageUpdated} style={{width:'100%'}} value={rightsPackageId} placeholder={`${t('select','Select')}...`}>
                        {upload.contribution.rights_packages.map(rp => (
                            <Select.Option key={rp.id} value={rp.id}>
                                <RightsPackage rightsPackage={rp} placement={'left'}/>
                            </Select.Option>
                        ))}
                        <Select.Option key={'none'} value={null}>
                            <em>{t('none','None')}</em>
                        </Select.Option>
                    </Select>

                    {rightsPackage?.long_form_type == 'allow_contract_upload'  && (
                        <div>
                            <Checkbox
                                checked={asset.contract}
                                onChange={onChangeLegal('contract')}
                                style={{marginTop:'1em'}}
                            >
                                <small>
                                    {t('this-is-a-contract','This is a Contract.')} <HelpPopover code={'contract-asset'}/>
                                </small>
                            </Checkbox>
                        </div>
                    )}

                    {rightsPackage?.allow_model_release_upload && (
                        <div>
                            <Checkbox
                                checked={asset.release}
                                onChange={onChangeLegal('release')}
                                style={{marginTop:'1em'}}
                            >
                                <small>
                                    {t('this-is-a-release','This is a Release.')} <HelpPopover code={'release-asset'}/>
                                </small>
                            </Checkbox>
                        </div>
                    )}
                </>
            ) : (
                <>
                    Rights...
                </>
            )}
        </>
    )
}

const StatusCol = ({id})=> {
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const asset = assets[id]

    let color, icon;
    switch(asset.aasm_state) {
        case 'pending':
            color = 'default'
            icon = <ClockCircleOutlined/>
            break
        case 'uploading':
        case 'processing':
            color = 'processing'
            icon = <SyncOutlined spin/>
            break
        case 'processed':
            color = 'success'
            icon = <CheckCircleOutlined/>
    }
    return <Tag color={color} icon={icon}>{t(asset.aasm_state,asset.aasm_state)}</Tag>
}

const WorkflowCol = ({id})=> {
    const {assets} = useAssetsState()
    const asset = assets[id]

    const {currentWorkflowStep, currentAssetGroup} = useAssetGroupState()

    return (
        <AssetGroupWorkflows
            buttonsOnly
            step={currentWorkflowStep}
            asset={asset}
            assetGroup={currentAssetGroup}
        />
    )
}