import React, {useEffect, useState} from "react";

import {Form, Input} from "formik-antd";
import {Formik} from "formik";
import api from "../api";
import {Button, Space, message, List, Popover, Card, Divider, Popconfirm, Typography, Tooltip} from "antd";
import {DeleteOutlined, LinkOutlined, LoadingOutlined, SaveOutlined} from "@ant-design/icons";
import {useLinksState} from "../../contexts/LinksContext";
import {useTranslation} from "react-i18next";

export default ({id, what, type, readOnly, centered, style={}})=>{
    const {t} = useTranslation();

    if(!id) id = what.id

    // console.log(what)
    const [links, setLinks] = useState(what.links || [])

    useEffect(()=>{
        setLinks(what.links || [])
    },[what.links])

    const editable = !readOnly && what.editable

    return (
        <Space direction={'vertical'} style={{width: !centered && '100%'}}>
            {!!links.length && (
                <List
                    size={'small'}
                    dataSource={links}
                    renderItem={(link)=> {
                        let url = link.url
                        if(!url.match(/\/\//)) url = `//${url}`

                        const remove = ()=> {
                            api.post(`/api/links/${link.id}/remove`, {link: {what_id: id, what_type: type}}).then(res => {
                                message.success(t('message-link-removed','Link removed.'))
                                setLinks(_.without(links, link))
                            })
                        }

                        const click = (e)=>{
                            e.stopPropagation()
                            return true
                        }

                        return (
                            <List.Item
                                extra={editable &&
                                    <Popconfirm title={`${t('confirm-remove', 'Remove')} "${link.url}"?`} onConfirm={remove} zIndex={1033}>
                                        <Button size={'small'}><DeleteOutlined/></Button>
                                    </Popconfirm>
                                }
                            >
                                <Popover
                                    zIndex={1033}
                                    mouseEnterDelay={0.5}
                                    overlayStyle={{maxWidth:300}}
                                    style={{padding:0}}
                                    placement={'right'}
                                    content={()=> {
                                        const [linkData, setLinkData] = useState(link)

                                        const {spideredLinks} = useLinksState()
                                        const spidered = linkData.spidered_at || spideredLinks.indexOf(link.id) != -1

                                        useEffect(()=>{
                                            if(!spidered) return

                                            api(`/api/links/${link.id}`).then(res => {
                                                setLinkData(res.data)

                                                links.splice(links.indexOf(link), 1, {...link, ...res.data})
                                                setLinks([...links])
                                            })
                                        }, [spidered])

                                        if(!spidered) {
                                            return (
                                                <><LoadingOutlined/> {t('gathering-link-info','Gathering Link Info...')} </>
                                            )
                                        }

                                        if(linkData.meta_error || (!link.title && !link.description && !link.image)) {
                                            return ''
                                        }

                                        return (
                                            <Card
                                                bordered={false}
                                                cover={linkData.image && <img src={linkData.image} style={{maxWidth:300}}/>}
                                                size={'small'}
                                                style={{margin:0}}
                                            >
                                                <h3>{linkData.title}</h3>
                                                {linkData.description}
                                                {linkData.author && (
                                                    <>
                                                        <Divider/>
                                                        {t('author','Author')}: {linkData.author}
                                                    </>
                                                )}
                                                <Divider/>
                                                <Tooltip title={url}>
                                                    <a href={url} target={'_blank'} onClick={click} style={{display: 'block', overflow:'hidden', width:'100%'}}>{url}</a>
                                                </Tooltip>
                                            </Card>
                                        )

                                    }}
                                >
                                    <a href={url} target={'_blank'} onClick={click} style={style}>
                                        <div style={{display:'flex'}}>
                                            <div style={{flex:'none'}}>
                                                {!link.spidered_at && <LoadingOutlined style={{marginRight:'.5em'}}/>}

                                                {link.spidered_at && (
                                                    link.favicon ?
                                                        <img src={link.favicon} style={{height:'1em', marginRight:'.5em'}}/> :
                                                        <LinkOutlined style={{marginRight:'.5em'}}/>
                                                )}
                                            </div>

                                            <div style={{flex:'auto', textOverflow: 'ellipsis'}}>
                                                {link.title || link.url}
                                            </div>
                                        </div>

                                    </a>
                                </Popover>
                            </List.Item>
                        )
                    }}
                />
            )}

            {editable && (
                <Formik
                    initialValues={{}}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        values.what_id = id
                        values.what_type = type

                        api.post('/api/links', {link: values}).then(res => {
                            setLinks(links.concat(res.data))

                            actions.resetForm();
                            actions.setSubmitting(false);
                        }).catch(err => {
                            actions.setSubmitting(false);
                            message.error(err.data)
                        })
                    }}
                >
                    {({values, submitForm, handleSubmit, isSubmitting}) => {
                        const onKey = (e)=> {
                            if (e.key === 'Backspace' || e.key === 'Enter') {
                                e.stopPropagation()
                            }
                        }
                        const onClick = (e)=> {
                            e.preventDefault()
                            e.stopPropagation()
                        }

                        return (
                            <Form layout="inline">
                                <Input
                                    onClick={onClick}
                                    onKeyDown={onKey}
                                    onKeyUp={onKey}
                                    onKeyPress={onKey}
                                    prefix={<LinkOutlined/>}
                                    name={'url'}
                                    placeholder={t('url-placeholder','URL (e.g. example.com)')}
                                    autoComplete={'off'}
                                    addonAfter={
                                        <Button size='small' type='text' icon={<SaveOutlined/>} onClick={submitForm} loading={isSubmitting}>{t('button-add','Add')}</Button>
                                    }
                                />
                            </Form>
                        )
                    }}
                </Formik>
            )}
        </Space>
    )
}