import React, {useContext, useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select} from "formik-antd";
import * as Yup from "yup";

import {Alert, Badge, Collapse, Divider, Drawer, message, Modal, Skeleton, Space, Tag, Tooltip} from 'antd';
const { Panel } = Collapse;

import HelpPopover from "../HelpPopover";

import {
    Row,
    Col,
    Button,
    Card,
    Typography,
} from 'antd';

import {
    SaveOutlined,
    FolderFilled,
    ArrowDownOutlined,
    DeploymentUnitOutlined, PlusOutlined, LoadingOutlined, ApiOutlined, FastForwardOutlined, ToolOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import AssetGroupChooser from "./AssetGroupChooser";
import FloatLabel from "../helpers/FloatLabel";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import UserSelect from "../widgets/UserSelect";
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";
import User from "@/components/helpers/User";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import {CollectionIcon, FrameIcon, LightboxIcon, LightroomIcon, StorageFolderIcon} from "@/components/helpers/icons";

export default ({id, stepId, onSave, visible, onClose}) => {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const navigate = useNavigate();

    if(!id) {
        const { id } = useParams();
    }

    const [workflow, setWorkflow] = useState({});
    const apiPath = '/api/workflows';

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`${apiPath}/${path}`).then((res) => {
            setWorkflow(res.data);
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    const onCloseDirty = useOnCloseDirty(onClose)

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    const [currentGroups, setCurrentGroups] = useState([])

    const getPath = useOrgPath()

    return (
        (<Formik
            initialValues={workflow}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                api({
                    method: id ? 'put' : 'post',
                    url: id ? `${apiPath}/${id}` : apiPath,
                    data: { workflow: values }
                }).then((res)=>{
                    console.log('id',id);
                    if(!id) navigate(getPath(`/collaborate/workflows`))
                    actions.setSubmitting(false)
                    setCurrentGroups([]);
                    
                    message.success(`${values.name} has been ${id ? 'updated' : 'created'}!`)
                    setWorkflow(res.data);

                    actions.resetForm({});

                    onSave && onSave(res.data);

                }).catch((error)=>{
                    console.error(error.data)
                    message.error(`Error: ${JSON.stringify(error.data)}`)
                    actions.setSubmitting(false)
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(t('required','Required')), // TODO: check uniqueness
                    steps_attributes: Yup.array().of(Yup.object({asset_group_id: Yup.number().required(t('required','Required'))}))
                })
            }
        >
            {({setFieldValue, values, submitForm, dirty, isValid}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = '';

                useEffect(()=>{
                    if(!values.steps_attributes || currentGroups.length) return;

                    setCurrentGroups(values.steps_attributes?.map(step => step.asset_group))
                }, [values.steps_attributes])

                const setSelectedGroups = (index, group)=> {
                    currentGroups[index] = group
                    setCurrentGroups([...currentGroups])
                }

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <DeploymentUnitOutlined/> {id ? t('editing','Editing') : t('add-new','Add New')} {t('workflow','Workflow')}

                                    <HelpPopover text={
                                        <div>
                                            Create a Workflow to...
                                        </div>
                                    }/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'} disabled={!isValid}>
                                            <SaveOutlined/>
                                            {id ? t('update','Update') : t('save','Save')} {t('workflow','Workflow')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            {(id && !workflow?.id) && (
                                <><LoadingOutlined/> Loading details...</>
                            )}

                            <Form layout='vertical' id={'workflow-form'}>
                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-workflow-name','e.g. Marketing Approval Pipeline')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('placeholder-workflow-description','Describe the purpose of the Workflow. Optional.')}>
                                        <Input.TextArea rows={2} name='description'/>
                                    </FloatLabel>
                                </FormItem>

                                <Divider>{t('workflow-steps','Workflow Steps')}</Divider>
                                <FieldArray
                                    name={'steps_attributes'}
                                    render={({ move, swap, push, insert, unshift, pop, remove }) => {

                                        const removeStep = index => {
                                            return ()=> {
                                                remove(index)
                                                values.steps_attributes.map((step,i) => step.position = i)
                                                setFieldValue('step_attributes', values.step_attributes)
                                            }
                                        }
                                        return (<>
                                            <Collapse defaultActiveKey={stepId}>
                                                {values.steps_attributes?.map((step, index) => {
                                                    const destination = step.position == values.steps_attributes?.length;

                                                    const setSelectedGroup = group => setSelectedGroups(index, group)

                                                    const current = currentGroups[index];

                                                    const groupHeader = current ?
                                                        <h5 style={{display:'inline-block', margin:'0 .5em'}}><FolderFilled/> {[current.type, ...current.path_names].join(' / ')}</h5> :
                                                        <h5 style={{display:'inline-block', margin:'0 .5em'}}><em>{destination ? t('select-destination','Select a Destination') : t('select-source','Select a Source')}</em></h5>;

                                                    const id = `workflow-step-${step.id || index}`

                                                    return (
                                                        (<Collapse.Panel
                                                            key={step.id || index}
                                                            id={id}
                                                            header={
                                                                <>
                                                                    {destination ? (
                                                                        <>{t('destination','Destination')}</>
                                                                    ) : (
                                                                        <>{index + 1}) {step.name || <em>{t('add-a-name','Add a name')}...</em>}</>
                                                                    )}

                                                                    <br/>
                                                                    {groupHeader}
                                                                </>
                                                            }
                                                            extra={
                                                                <>
                                                                    {index + 1 != values.steps_attributes.length && <>{step.auto_approval ? t('auto-approval','Auto Approval') : t('manual-approval','Manual Approval')} <ArrowDownOutlined/></>}
                                                                </>
                                                            }
                                                        >
                                                            <Row gutter={16}>
                                                                <Col span={12}>
                                                                    <Collapse defaultActiveKey={[current?.type]}>
                                                                        {index == 0 && (
                                                                            <Collapse.Panel header={<><StorageFolderIcon/> {t('storage-folders','Storage Folders')}</>} key={'StorageFolder'} id={`${id}-storage-folders`}>
                                                                                <AssetGroupChooser
                                                                                    type={'storage_folders'}
                                                                                    organizerSelection
                                                                                    setSelectedGroup={setSelectedGroup}
                                                                                    fieldName={`steps_attributes.${index}.asset_group_id`}
                                                                                    current={current}
                                                                                    sessionKey={`workflow-${index}-storage_folders`}
                                                                                />
                                                                            </Collapse.Panel>
                                                                        )}

                                                                        <Collapse.Panel header={<><CollectionIcon/> {t('collections','Collections')}</>} key={'Collection'} id={`${id}-collections`}>
                                                                            <AssetGroupChooser
                                                                                type={'collections'}
                                                                                sandbox={'all'}
                                                                                setSelectedGroup={setSelectedGroup}
                                                                                fieldName={`steps_attributes.${index}.asset_group_id`}
                                                                                current={current}
                                                                                sessionKey={`workflow-${index}-collections`}
                                                                            />
                                                                        </Collapse.Panel>

                                                                        <Collapse.Panel header={<><LightboxIcon/> {t('lightboxes','Lightboxes')}</>} key={'Lightbox'} id={`${id}-lightboxes`}>
                                                                            <AssetGroupChooser
                                                                                type={'lightboxes'}
                                                                                setSelectedGroup={setSelectedGroup}
                                                                                fieldName={`steps_attributes.${index}.asset_group_id`}
                                                                                current={current}
                                                                                sessionKey={`workflow-${index}-lightboxes`}
                                                                            />
                                                                        </Collapse.Panel>
                                                                    </Collapse>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Card size={'small'} title={t('step-settings','Step Settings')}>
                                                                        {!destination ? (
                                                                            <>
                                                                                <FormItem required name='name'>
                                                                                    <FloatLabel label={t('step-name','Step Name')} name={`steps_attributes.${index}.name`} value={step.name} description={''}>
                                                                                        <Input size={'large'} required name={`steps_attributes.${index}.name`}/>
                                                                                    </FloatLabel>
                                                                                </FormItem>

                                                                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                                                                    <FloatLabel label={t('instructions','Instructions')} name={`steps_attributes.${index}.description`} value={step.description} description={t('placeholder-optional','Optional.')}>
                                                                                        <Input.TextArea rows={2} name={`steps_attributes.${index}.description`}/>
                                                                                    </FloatLabel>
                                                                                </FormItem>

                                                                                <Divider><FastForwardOutlined /> {t('forwarding','Forwarding')}</Divider>

                                                                                <Radio.Group
                                                                                    rootClassName={'vertical-checkbox-group'}
                                                                                    name={`steps_attributes.${index}.auto_approval`}
                                                                                >
                                                                                    <Radio value={true}>{t('auto-approval','Auto Approval')}</Radio>
                                                                                    <Radio value={false}>{t('manual-approval','Manual Approval')}</Radio>
                                                                                </Radio.Group>

                                                                                {step.auto_approval && (
                                                                                    <div style={{marginTop:'1em'}}>
                                                                                        <Checkbox name={`steps_attributes.${index}.enable_descendants`}>
                                                                                            {t('enable-forwarding-for-sub-folders', 'Enable forwarding for sub-folders?')}
                                                                                        </Checkbox>
                                                                                    </div>
                                                                                )}

                                                                                {!step.auto_approval && (
                                                                                    <div style={{paddingLeft:'2em'}}>
                                                                                        <Radio.Group
                                                                                            rootClassName={'vertical-checkbox-group'}
                                                                                            name={`steps_attributes.${index}.manual_approval_type`}
                                                                                        >
                                                                                            <Radio value={'owner'}>{t('by-me','By Me')}</Radio>
                                                                                            <Radio value={'any'}>
                                                                                                {t('by-any','By any')} {{StorageFolder: t('content-manager','Content Manager'), Collection: t('library-manager','Library Manager'), Lightbox: t('lightbox-member','Lightbox Member')}[current?.type]}
                                                                                            </Radio>
                                                                                            <Radio value={'user'}>
                                                                                                {t('by-specific','By Specific')} {{StorageFolder: t('content-manager','Content Manager'), Collection: t('library-manager','Library Manager'), Lightbox: t('lightbox-member','Lightbox Member')}[current?.type]}:
                                                                                            </Radio>
                                                                                        </Radio.Group>

                                                                                        {step.manual_approval_type === 'user' && (
                                                                                            <FormItem style={{marginTop:'1em'}} name={`steps_attributes.${index}.approval_user_id`}>
                                                                                                <UserSelect
                                                                                                    selectedUser={step.approval_user}
                                                                                                    fieldName={`steps_attributes.${index}.approval_user_id`}
                                                                                                />
                                                                                            </FormItem>
                                                                                        )}

                                                                                        {currentOrg?.frame_account && (
                                                                                            <div style={{marginBottom:'1em'}}>
                                                                                                <Divider/>
                                                                                                <Checkbox name={`steps_attributes.${index}.approve_on_frame`}>
                                                                                                    <FrameIcon/> {t('mark-assets-approved-on-frame', 'Also mark Assets approved on Frame.io?')}
                                                                                                </Checkbox>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                <div style={{marginTop:'1em'}}>
                                                                                    <Radio.Group
                                                                                        rootClassName={'vertical-checkbox-group'}
                                                                                        name={`steps_attributes.${index}.preserve_subfolders`}
                                                                                    >
                                                                                        <Radio value={true}>{t('preserve-destination-subfolders', 'Preserve subfolders in destination')}</Radio>
                                                                                        <Radio value={false}>{t('forward-into-one-container', 'Forward into one container')}</Radio>
                                                                                    </Radio.Group>
                                                                                </div>

                                                                                <Divider/>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {(currentOrg?.lightroom_account || step.lightroom_user) && (
                                                                                    <>
                                                                                        <Divider><ApiOutlined/> {t('integrations','Integrations')}</Divider>

                                                                                        <VerticalSpace>
                                                                                            {currentOrg?.lightroom_account && (
                                                                                                <>
                                                                                                    <Checkbox name={`steps_attributes.${index}.send_to_lightroom`}>
                                                                                                        <LightroomIcon/> {t('send-incoming-files-to-lightroom', 'Send Incoming Files to my Lightroom Cloud?')}
                                                                                                    </Checkbox>
                                                                                                </>
                                                                                            )}

                                                                                            {step.lightroom_user && (
                                                                                                <Alert
                                                                                                    message={
                                                                                                        <>{t('connected-to-lightroom', 'Connected to Lightroom')}: <User user={step.lightroom_user}/></>
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </VerticalSpace>
                                                                                    </>
                                                                                )}

                                                                                <Divider/>
                                                                            </>
                                                                        )}

                                                                        {step.id ? (
                                                                            <Checkbox
                                                                                name={`steps_attributes.${index}._destroy`}>
                                                                                <Typography.Text type="danger">
                                                                                    <DeleteOutlined/>
                                                                                    {t('remove-this-step','Remove this Step')}
                                                                                </Typography.Text>
                                                                            </Checkbox>
                                                                        ) : (
                                                                            <Button type={'text'} onClick={removeStep(index)}>
                                                                                <Typography.Text type="danger">
                                                                                    <DeleteOutlined/> {t('remove-this-step','Remove this Step')} {index + 1}
                                                                                </Typography.Text>
                                                                            </Button>
                                                                        )}
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Collapse.Panel>)
                                                    );
                                                })}
                                            </Collapse>
                                            <Button
                                                style={{marginTop:'1em'}}
                                                ghost
                                                type={'primary'}
                                                onClick={() => push({ name: "", description: "", position: values.steps_attributes.length + 1 })}
                                                icon={<PlusOutlined/>}
                                            >
                                                {t('button-add-workflow-step','Add Workflow Step')}
                                            </Button>
                                        </>);
                                    }}
                                />

                                {/*For Enter btn submit:*/}
                                <SubmitButton style={{display:'none'}}/>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

