import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput, Tooltip} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {RefreshIcon} from "../helpers/icons";
import {useTranslation} from "react-i18next";
import FacesTable from "@/components/manage/FacesTable";

export default () => {
    const {t} = useTranslation();
    setTitle(t('faces','Faces'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const [refresh, setRefresh] = useState(0)

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-faces','Search Faces...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Tooltip title={t('tooltip.reload-faces','Reload Faces')}>
                    <Button icon={<RefreshIcon/>} type='text' onClick={()=> setRefresh(refresh + 1)}/>
                </Tooltip>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}

            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-faces'}/>
                </TabPane>
                <TabPane tab={t("browse-faces","Browse Faces")} key="browse">
                    <FacesTable q={searchValue} onLoaded={onLoaded} refresh={refresh}/>
                </TabPane>
            </Tabs>
        </>
    );
}