import React, {useContext, useEffect, useState} from "react";
import {Button, Space, Col, Descriptions, Popover} from "antd";

import {
    InfoCircleOutlined,
    SettingOutlined
} from "@ant-design/icons";

import User from "../helpers/User";
import TimeAgo from "../helpers/TimeAgo";
import {SessionContext} from "../../contexts/SessionContext";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";

export default ({aga})=>{
    let location = useLocation()
    const navigate = useNavigate();

    const setCurrentAsset = useSetCurrentAsset()

    const clickManage = (e,c)=>{
        e.preventDefault()
        e.stopPropagation();

        const route = {
            'Collection': 'collections',
            'StorageFolder': 'storage_folders',
            'Lightbox': 'lightboxes'
        }[c.type];

        setCurrentAsset(null)
        location.hash = `/${route}/${c.id}/edit`
        navigate(location)
        return false;
    }

    return (
        <Popover
            placement={'right'}
            title={`${aga.type} Info`}
            content={
                <Space direction={'vertical'}>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item label={'Name'}>{aga.name}</Descriptions.Item>
                        {aga.description && aga.description != '' && (
                            <Descriptions.Item label={'Description'}>{aga.description}</Descriptions.Item>
                        )}
                        <Descriptions.Item label={'Created by'}><User user={aga.user}/> <TimeAgo date={aga.created_at}/></Descriptions.Item>
                        <Descriptions.Item label={'Added by'}>{aga.added_by ? <User user={aga.added_by}/> : 'Workflow'} <TimeAgo date={aga.added_at}/></Descriptions.Item>
                    </Descriptions>


                    {aga.editable && !aga.organizer && aga.type != 'StorageFolder' && (
                        <Button ghost type='primary' size={'small'} icon={<SettingOutlined/>} onClick={(e)=> clickManage(e, aga)}>
                            Manage
                        </Button>
                    )}
                </Space>
            }>
            <InfoCircleOutlined style={{marginLeft:'0.5em', color:'rgb(176, 176, 176)'}}/>
        </Popover>
    )

}