import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Flex
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    DeleteOutlined,
    DeploymentUnitOutlined,
    DownloadOutlined,
    DownOutlined,
    EditOutlined,
    EyeOutlined
} from "@ant-design/icons";
import GroupForm from "./GroupForm";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import {Can} from "../helpers/Can";
import EnabledDisabled from "../helpers/EnabledDisabled";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import {isMobile} from "../../plugins/device-detect";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import useCurrentOrg from "~/components/helpers/useCurrentOrg";

export default ({newGroup, q, onLoaded}) => {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const currentOrg = useCurrentOrg()

    const destroy = id => {
        console.log(id);
        api({
            url: `/api/user_groups/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-user-group-deleted','User Group Deleted.'));
            handleTableChange();
        });
    }

    const MembersCell = ({group})=>{
        const [currentGroup, setCurrentGroup] = useState();

        const onOpenChange = (visible) => {
            if(!visible) return;

            // TODO: use JSON cache
            api(`/api/user_groups/${group.id}`).then(res => {
                setCurrentGroup(res.data);
            })
        }

        return (
            <Popover
                title={t('members','Members')}
                onOpenChange={onOpenChange}
                placement={'rightTop'}
                content={() => {
                    if(currentGroup && !currentGroup.group_memberships.length)
                        return <em style={{textTransform:'capitalize'}}>{currentGroup.auto_group ? `${currentGroup.auto_group} ${t('users','Users')}.` : t('none-yet','None Yet.')}</em>;

                    return currentGroup ? currentGroup.group_memberships.map(m => (
                        <div key={m.id}>
                            <User user={m.membership}/> {m.role_level && <Tag>{t(m.role_level, m.role_level).toProperCase()}</Tag>}
                            <br/>
                        </div>
                    )) : <Skeleton active loading />
                }}>
                <Tag>{group.memberships_count}</Tag>
            </Popover>
        )
    }

    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: name => ( <>{['All Members', 'Public'].includes(name) ? <strong>{t(name,name)}</strong> : name}</>)
        },
        {
            title: t('members','Members'),
            render: (group) => <MembersCell group={group}/>
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            filters: [
                {text: 'active', value: 'active'},
                {text: 'archived', value: 'archived'},
            ],
            filterMultiple: true,
            render: (state) => (
                <Tag>{t(state,state)}</Tag>
            )
        },
        {
            title: t('contributions','Contributions'),
            dataIndex: 'enable_contribution',
            filters: [
                {text: t('enabled','enabled'), value: true},
                {text: t('disabled','disabled'), value: false},
            ],
            render: (enabled) => (
                <EnabledDisabled enabled={enabled}/>
            )
        },
        {
            title: t('domains','Domains'),
            dataIndex: 'invite_domain',
        },
    ]

    if(currentOrg?.enable_sso) {
        columns.push(
            {
                title: t('soo-group','SSO Group'),
                dataIndex: 'sso_name',
                render: (sso_name) => (
                    <Flex>
                        {sso_name?.map(name => name?.length ? <Tag title={name}><Typography.Text ellipsis={{expandable:true}} style={{maxWidth:100}}>{name}</Typography.Text></Tag>: <></>)}
                    </Flex>
                )
            },
        )
    }

    const Action = ({group}) => {
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${group.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${group.id}`;

        const [currentGroup, setCurrentGroup] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`/api/user_groups/${id}`).then(res => {
                setCurrentGroup(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(group.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        return (<>
            <Space size={5}>
                <Button onClick={() => view(group.id)} size={'middle'}>
                    <EyeOutlined/>
                    {t('button-view','View')}
                </Button>

                <Modal
                    width={isMobile() ? '100%' : 800}
                    title={<><DeploymentUnitOutlined/> {group.name}</>}
                    footer={(
                        <Can I={'edit'} a={'UserGroup'}>
                            <Button onClick={edit}>
                                <EditOutlined/>
                                {t('button-edit','Edit')}
                            </Button>
                        </Can>
                    )}
                    open={modalVisible}
                    onCancel={modalClose}
                >
                    {currentGroup ? (
                        <>
                            <p>
                                <Text type={'secondary'}>
                                    <em>
                                        {t('created','Created')} <TimeAgo date={currentGroup.created_at}/> {t('by','by')}
                                        &nbsp; <User user={currentGroup.user}/>
                                    </em>
                                </Text>
                            </p>

                            {currentGroup.description != '' && currentGroup.description &&
                                <Card style={{marginBottom:'1em', whiteSpace:'pre-wrap'}}>
                                    <Paragraph ellipsis={{rows: 3, expandable: true}}>
                                        {currentGroup.description}
                                    </Paragraph>
                                </Card>
                            }

                            {currentGroup.auto_group !== 'all' && (
                                <List
                                    header={<strong>{t('members','Members')}</strong>}
                                    size={'small'}
                                    dataSource={currentGroup.group_memberships}
                                    bordered
                                    locale={{emptyText:t('none-yet','None yet.')}}
                                    renderItem={m => (
                                        <List.Item>
                                            <User user={m.membership}/> {m.role_level && <Tag>{t(m.role_level,m.role_level)}</Tag>}
                                            <br/>
                                        </List.Item>
                                    )}
                                />
                            )}

                            {/*<Divider>Info</Divider>*/}
                            {/*<ul>*/}
                            {/*    <li>Collection:&nbsp;*/}
                            {/*        {currentGroup.collection ?*/}
                            {/*            (<Tooltip title={currentGroup.collection.path_names.join(' > ')}>{currentGroup.collection.name}</Tooltip>) :*/}
                            {/*            'None'*/}
                            {/*        }*/}
                            {/*    </li>*/}
                            {/*    <li>Sandbox Collection: {currentGroup.sandbox_collection ? currentGroup.sandbox_collection.name : 'None'}</li>*/}
                            {/*    <li>*/}
                            {/*        Storage Folder:&nbsp;*/}
                            {/*        {currentGroup.storage_folder ?*/}
                            {/*            (<Tooltip title={currentGroup.storage_folder.path_names.join(' > ')}>{currentGroup.storage_folder.name}</Tooltip>) :*/}
                            {/*            'None'*/}
                            {/*        }*/}
                            {/*    </li>*/}
                            {/*    <li># Collection Permissions</li>*/}
                            {/*    <li>Version History</li>*/}
                            {/*    <li>More details...</li>*/}
                            {/*</ul>*/}
                        </>
                    ) : (
                        <Skeleton active loading/>
                    )}
                </Modal>

                <Can I={'edit'} a={'UserGroup'}>
                    <Space direction={'horizontal'}>
                        <Button onClick={edit} size={'middle'} id={`edit-group-${group.id}`}>
                            <EditOutlined/>
                            {t('button-edit','Edit')}

                            <GroupForm
                                id={group.id}
                                visible={formVisible}
                                onSave={onSave}
                                onClose={onClose}
                            />
                        </Button>

                        {!group.auto_group && group.destroyable && (
                            <Popconfirm
                                title={t('confirm-archive-group','Archive Group?')}
                                onConfirm={() => destroy(group.id)}
                            >
                                <Button size={'middle'}>
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>
                        )}

                    </Space>
                </Can>
            </Space>
        </>);
    }

    columns.push(
        {
            key: 'action',
            width: 100,
            render: group => <Action group={group}/>
        }
    )

    const apiPath = '/api/user_groups'
    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})

        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newGroup, q]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    return (
        <>
            <Table
                scroll={{x: true}}
                bordered
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Group{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
