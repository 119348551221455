import React, {useEffect, useState} from "react";

import {
    Table,
    Button,
    message,
    Space,
    Popconfirm,
    Popover,
    Modal,
    Card,
    Typography, Skeleton, Dropdown, Menu, Tooltip
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, FileTextOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import RightsForm from "./RightsForm";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import EnabledDisabled from "../helpers/EnabledDisabled";
import AssetLink from "../widgets/AssetLink";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newRightsPackage, q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 1000});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/rights_packages'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-rights-package-deleted.','Rights Package Deleted.'));
            handleTableChange();
        });
    }

    const Actions = ({rightsPackage})=>{
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${rightsPackage.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${rightsPackage.id}`;

        const [currentRightsPackage, setCurrentRightsPackage] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`${apiPath}/${id}`).then(res => {
                setCurrentRightsPackage(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(rightsPackage.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        return (<>
            <Space size={5}>
                <Button onClick={() => view(rightsPackage.id)} size={'middle'}>
                    <EyeOutlined/>
                    {t('button-view','View')}
                </Button>

                <Modal
                    width={800}
                    title={<><ReconciliationOutlined/> {rightsPackage.name}</>}
                    footer={(
                        <Can I={'edit'} a={'Workflow'}>
                            <Button onClick={edit}>
                                <EditOutlined/>
                                {t('button-edit','Edit')}
                            </Button>
                        </Can>
                    )}
                    open={modalVisible}
                    onCancel={modalClose}
                >
                    {currentRightsPackage ? (
                        <>
                            <p>
                                <Text type={'secondary'}>
                                    <em>
                                        {t('created','Created')} <TimeAgo date={currentRightsPackage.created_at}/> {t('by','by')}
                                        &nbsp; <User user={currentRightsPackage.user}/>
                                    </em>
                                </Text>
                            </p>

                            {currentRightsPackage.description != '' && currentRightsPackage.description && (
                                <Card style={{marginBottom:'1em', whiteSpace:'pre-wrap'}}>
                                    <Paragraph ellipsis={{rows: 3, expandable: true}}>
                                        {currentRightsPackage.description}
                                    </Paragraph>
                                </Card>
                            )}

                        </>
                    ) : (
                        <Skeleton active loading/>
                    )}
                </Modal>

                {rightsPackage.editable && (
                    <Space direction={'horizontal'}>
                        <Button onClick={edit} size={'middle'}>
                            <EditOutlined/>
                            {t('button-edit','Edit')}

                            <RightsForm
                                id={rightsPackage.id}
                                visible={formVisible}
                                onSave={onSave}
                                onClose={onClose}
                            />
                        </Button>

                        <Popconfirm
                            title={t('confirm-delete-rights-package','Delete Rights Package?')}
                            onConfirm={() => destroy(rightsPackage.id)}
                        >
                            <Button size={'middle'}>
                                <DeleteOutlined/>
                            </Button>
                        </Popconfirm>

                    </Space>
                )}
            </Space>
        </>);
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            render: date => <TimeAgo date={date}/>
        },
        {
            title: t('enabled','Enabled').toProperCase(),
            dataIndex: 'enabled',
            width: 1,
            sorter: true,
            render: enabled => <EnabledDisabled enabled={enabled}/>,
            filters: [
                {text: t('yes','Yes'), value: 'true'},
                {text: t('no','No'), value: 'false'},
            ]
        },
        {
            title: t('default','Default'),
            dataIndex: 'default',
            width: 1,
            sorter: true,
            render: enabled => <EnabledDisabled enabled={enabled}/>,
            filters: [
                {text: t('yes','Yes'), value: 'true'},
                {text: t('no','No'), value: 'false'},
            ]
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('rights-status','Rights Status'),
            dataIndex: 'rights_class',
            sorter: true,
            render: (rights_class, rp) => <Popover content={rp.rights_class_description}>{t(rp.rights_class,rp.rights_class).replace(/_/g,' ').toProperCase()}</Popover>,
            filters: Object.keys(RightsStatusOptions).map(opt => { return {text: RightsStatusOptions[opt].name, value: opt }}),
        },
        {
            title: t('contract','Contract'),
            dataIndex: 'contract_asset',
            render: (contract_asset, rp) => (
                <>
                    {contract_asset && <AssetLink asset={contract_asset}/>}
                </>
            )
        },
        {
            title: t('personalized','Personalized'),
            dataIndex: 'personalize',
            sorter: true,
            render: (p, rp) => (
                <>
                    {rp.personalize && rp.other_party_name}
                </>
            ),
            filters: [
                {text: t('yes','Yes'), value: 'true'},
                {text: t('no','No'), value: 'false'},
            ]
        },
        {
            title: t('expires','Expires'),
            render: (rp) => (
                <>
                    {rp.expires && <TimeAgo date={rp.expires_at}/> || t('never','Never')}
                </>
            )
        },
        {
            title: t('assets','Assets'),
            sorter: true,
            dataIndex: 'assets_count',
            render: (n, rp) => (
                <>
                    <OrgLink to={`/explore?rights[]=${rp.id}`}>{n}</OrgLink>
                </>
            )
        },
        {
            key: 'action',
            width: 100,
            render: (rightsPackage) => <Actions rightsPackage={rightsPackage}/>
        }
    ];

    const [settings, setSettings] = useState({})

    const handleTableChange = (newPagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setPagination({...pagination, ...newPagination})
        setSettings({pagination, filters, sorter, q})
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setPagination({ ...pagination, total: res.headers['total-entries'] })
            setData(res.data)
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newRightsPackage, q]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, pageSize:100, position: ['none', 'none']}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>

                            {selectedKeys.length > 0 &&
                            <Button danger>
                                <DeleteOutlined/>
                                Archive {selectedKeys.length} Tag{selectedKeys.length != 1 && 's'}...
                            </Button>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
