import React, {useContext, useState} from "react";
import {Alert, message, Button, Row, Col, Card } from "antd"
import setTitle from "../helpers/setTitle";
import {Checkbox, Form, FormItem, Input, SubmitButton} from "formik-antd";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import OrgNavLink from "../helpers/OrgNavLink";
import {Link} from "react-router-dom";
import {isMobile} from "device-detect";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('password-reset','Password Reset'));

    const [sent, setSent]  = useState()

    return (
        <Card title={t('mediagraph-password-reset','Mediagraph Password Reset')} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
            {sent && (
                <Alert message={t('password-reset-link-sent-alert',"Thank you. If the email was found in our system, the password reset instructions were sent. Please check your email for a reset link.")} type="success" showIcon />
            ) || (
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={(values, actions) => {
                        api({
                            method:'post',
                            url: '/api/reset_password',
                            data: values
                        }).then((res)=>{
                            setSent(true)
                            console.log(res)
                            actions.setSubmitting(false)
                        }).catch(err =>{
                            if(err.response?.status == 404) {
                                message.warning(t('warning-could-not-find-email',"Sorry, we couldn't find that email."))
                            }
                            console.log(err)
                            actions.setSubmitting(false)
                        })
                    }}
                    validationSchema={
                        Yup.object({
                            email: Yup.string().email().required(t('required','Required')),
                        })
                    }
                >
                    {({isSubmitting}) => (
                        <Form id={'reset-password-form'}>
                            <FormItem name="email" required>
                                <Input
                                    autoFocus required
                                    prefix={<UserOutlined style={{opacity:0.5}} />}
                                    type='email'
                                    autoComplete='email' name="email" placeholder={t('placeholder-email',"Email")} />
                            </FormItem>

                            <FormItem name="submit" style={{marginBottom:0}}>
                                {isSubmitting ? (
                                    <Button block type="primary" icon={<PoweroffOutlined />} loading>{t('sending','Sending')}...</Button>
                                ) : (
                                    <SubmitButton block>{t('button-send-reset-instructions','Send Reset Instructions')}</SubmitButton>
                                )}
                            </FormItem>
                        </Form>
                    )}
                </Formik>
            )}
        </Card>
    );
}
