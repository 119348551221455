import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {SessionContext} from "../../contexts/SessionContext";
import useCurrentOrg, {checkReindexing} from "./useCurrentOrg";

window.lastOrgLoadaedSlug = null;
window.loadingOrg = false

export default ()=> {
    const {org: orgSlug} = useParams()
    const orgSlugOrDomain = window.customDomainOrganizationSlug || orgSlug

    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const currentOrg = useCurrentOrg()

    const [org, setOrg] = useState(currentOrg)

    useEffect(()=>{
        setOrg(currentOrg)

        if(
            loadingOrg || !orgSlugOrDomain ||
            (lastOrgLoadaedSlug && lastOrgLoadaedSlug == currentOrg?.slug) ||
            ['login','trial-sign-up', 'organizations'].indexOf(orgSlugOrDomain) !== -1
        ) return

        window.loadingOrg = true
        api(`/api/organizations/find?slug=${orgSlugOrDomain}`).then(res => {
            window.loadingOrg = false
            const orgData = res.data.organization || res.data;

            // always rehydrate org on full page load
            lastOrgLoadaedSlug = orgData.slug

            setOrg(orgData)
            sessionDispatch({type: 'set_org', org: orgData, abilities: res.data.abilities});

            checkReindexing(orgData)
        })

    }, [orgSlugOrDomain, currentOrg?.slug])

    return org
}