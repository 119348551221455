import React, {useState, useContext, useEffect, useMemo} from "react";

import {Col, Popover, Row, Tree, Card, Empty, Avatar} from 'antd';
import {Transfer} from "formik-antd";

const { TreeNode } = Tree;

import api from "../api";
// TODO: show (you) when seeing yourself in list
import {AppContext} from "../../contexts/AppContext";
import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import User from "../helpers/User";
import {useFormikContext} from "formik";
import {useTranslation} from "react-i18next";

const getInitialTreeData = t => {
    return [
        {
            key: 'filter',
            title: <b>{t('filter','Filter')}</b>,
            checkable: false,
            children: [
                {
                    key: 'groups',
                    title: t('existing-groups','Existing Groups'),
                    checkable: false,
                },
                {
                    key: 'staff',
                    title: t('staff-roles','Staff Roles'),
                    checkable: false,
                    children: [
                        {
                            key: 'global_content',
                            title: t('global-content-manager','Global Content Manager'),
                            isLeaf: true
                        },
                        {
                            key: 'library',
                            title: t('group-library-manager','Group Library Manager'),
                            isLeaf: true
                        },
                        {
                            key: 'global_library',
                            title: t('global-library-manager','Global Library Manager'),
                            isLeaf: true
                        },
                        {
                            key: 'global_tagger',
                            title: t('global-tagger','Global Tagger'),
                            isLeaf: true
                        },
                        {
                            key: 'tagger',
                            title: t('group-tagger','Group Tagger'),
                            isLeaf: true
                        },
                    ]
                },
                {
                    key: 'status',
                    title: t('status','Status'),
                    checkable: false,
                    children: [
                        {
                            key: 'Verified Members',
                            title: t('verified-members','Verified Members'),
                            isLeaf: true
                        },
                        {
                            key: 'Pending Members',
                            title: t('pending-members','Pending Members'),
                            isLeaf: true
                        },
                        {
                            key: 'Locked Users',
                            title: t('locked-users','Locked Users'),
                            isLeaf: true
                        },
                    ]
                },
                {
                    key: 'type',
                    title: t('type','Type'),
                    checkable: false,
                    children: [
                        {
                            key: 'normal',
                            title: t('normal-users','Normal Users'),
                            isLeaf: true
                        },
                        {
                            key: 'testers',
                            title: t('test-users','Test Users'),
                            isLeaf: true
                        },
                    ]
                }
            ]
        }
    ];
}

export default ({edit, fieldName='membership_ids'}) => {
    const {t} = useTranslation();
    const { values, setFieldValue } = useFormikContext();
    const membership_ids = values[fieldName];

    const [targetKeys, setTargetKeys] = useState([]);
    const [treeData, setTreeData] = useState(getInitialTreeData(t));
    const [checkedKeys, setCheckedKeys] = useState(['normal', 'testers']);

    const [data, setData] = useState([]);
    const [fullData, setFullData] = useState([]);

    // not used yet:
    const [loading, setLoading] = useState(false);

    useEffect(()=>{ setTargetKeys(membership_ids) },[membership_ids]);

    const filterOption = (inputValue, option) => {
        const val = inputValue.toLowerCase();
        return (option.name.toLowerCase().indexOf(val) > -1 || option.email.toLowerCase().indexOf(val) > -1)
    }

    const handleChange = newTargetKeys => {
        setTargetKeys(newTargetKeys);
        setFieldValue(fieldName, newTargetKeys);
    };

    const [searchData, setSearchData] = useState(false);
    const handleSearch = (dir, value) => {
        console.log('search:', dir, value);
        // if(!value) return setSearchData(null)
        //
        // // setLoading(true)
        // api('/api/memberships', {params: {q: value}}).then(res => {
        //     setLoading(false)
        //     setSearchData(res.data);
        // });
    };

    const loadNewData = ()=> {
        const user_group_ids = []
        const global_or_group_role_levels= []
        const types = []

        checkedKeys.map(key => {
           if(key.match(/^group-/)) user_group_ids.push(parseInt(key.replace('group-','')));
           else if(key == 'normal' || key == 'testers') types.push(key)
           else {
               global_or_group_role_levels.push(key)
           }
        })

        const params = {
            user_group_ids,
            global_or_group_role_levels,
            types,
            all: true
        }

        api.get('/api/memberships', {params}).then(res => {
            if(fullData.length) {
                const ids = _.map(res.data, 'id')
                setData(fullData.filter(d => ids.indexOf(d.id) > -1 || targetKeys?.indexOf(d.id) > -1))
            } else {
                setFullData(res.data)
                setData(res.data)
            }
        });
    }

    useEffect(() => {
        if(!edit || membership_ids) loadNewData();
    }, [membership_ids, checkedKeys]);

    // TransferItem render:
    const renderItem = item => {
        return {
            label: (
                <span key={item.id}>
                    <User user={item}/>
                </span>
            ),
            value: item.id
        }
    };

    function updateTreeData(list, key, children) {
        return list.map(node => {
            if (node.key === key) {
                return { ...node, children };
            }
            if (node.children) {
                return { ...node, children: updateTreeData(node.children, key, children) };
            }

            return node;
        });
    }

    const [allGroupKey, setAllGroupKey] = useState();

    const loadTreeData = ({key, children}) => {
        return new Promise(resolve => {
            if (children) {
                resolve();
                return;
            }

            if(key == 'groups') {
                api('/api/user_groups').then(res => {
                    const newNodes = _.map(res.data, group => {
                        const key = group.auto_group == 'all' ? 'all' : `group-${group.id}`

                        if(key == 'all') {
                            setAllGroupKey(key);
                            setCheckedKeys([key, ...checkedKeys]);
                        }

                        return {
                            key: key,
                            title: (
                                <Popover title={group.name} placement={'right'} content={(
                                    <>
                                        {group.memberships_count} {t('users','Users')}
                                    </>
                                )}><InfoCircleOutlined/> {group.name}</Popover>
                            ),
                            isLeaf: true
                        };
                    });

                    setTreeData(origin => updateTreeData(origin, key, newNodes));
                    resolve();
                })

            } else {
                resolve();
            }
        });
    }

    const onNodeCheck = (keys) => {
        console.log('onNodeCheck', keys)

        // if(_.last(keys) == allGroupKey || !keys.length) {
        //     // Toggle All:
        //     return setCheckedKeys([allGroupKey])
        // }
        //
        // let newKeys = _.filter(keys, key => key != allGroupKey);

        const lastKey = _.last(keys)
        if(lastKey && lastKey.match(/^group-/)) {
            keys = _.filter(keys, key => key == lastKey || !(key.match(/^group-/) || key == allGroupKey))
        }

        console.log('setCheckedKeys', keys)
        setCheckedKeys([...keys]);
    }

    return useMemo(()=> {
        return (
            <Row>
                <Col lg={12} xs={24}>
                    <Card style={{marginRight:'1em'}} size='small' title={t('choose-from','Choose From')}>
                        <Tree
                            checkable
                            selectable={false}
                            treeData={treeData}
                            loadData={loadTreeData}
                            onCheck={onNodeCheck}
                            defaultExpandedKeys={['filter','groups']}
                            checkedKeys={checkedKeys}
                        >
                        </Tree>
                    </Card>
                </Col>
                <Col lg={12} xs={24}>
                    <Transfer
                        titles={
                            [
                                <strong>{t('select','Select')}</strong>,
                                <strong>{t('add-to-group','Add to Group')}</strong>
                            ]
                        }
                        locale={
                            {
                                itemUnit:t('user','User'),
                                itemsUnit:t('users','Users'),
                                notFoundContent: loading ? <Empty image={<LoadingOutlined/>} description={''}/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no-users','No Users')}/>,
                                searchPlaceholder:t('search-users','Search Users')
                            }
                        }
                        // operations={['Add','Remove']}
                        listStyle={{flex:1, height:'100%', maxHeight:500}}
                        style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'stretch', height:'100%'}}
                        dataSource={searchData || data}
                        rowKey={record => record.id}
                        showSearch={!loading}
                        filterOption={filterOption}
                        targetKeys={targetKeys}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        render={renderItem}
                        oneWay
                    />
                </Col>
            </Row>
        )
    }, [membership_ids, loading, data, treeData, searchData]);
}
