import React, {useContext, useEffect, useState} from "react";

import {
    Card,
    Button,
    message,
    Upload,
    Divider,
    Popconfirm,
    Space,
    Typography,
    Alert,
    Tooltip, Skeleton
} from 'antd';

import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {Formik} from "formik";
import * as Yup from "yup";
import {Checkbox, Form, FormItem, Input, SubmitButton} from "formik-antd";
import {
    PoweroffOutlined,
    LoadingOutlined,
    PlusOutlined,
    EditOutlined,
    ApiOutlined, DropboxOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {isMobile} from "device-detect";
import {ConnectBoxButton, ConnectDropboxButton, ConnectGoogleDriveButton} from "../explore/UploadHeader";
import useCurrentOrg, {useRefreshOrg} from "../helpers/useCurrentOrg";
import {BoxIcon, DeleteIcon, FrameIcon, GoogleDriveIcon, LightroomIcon} from "../helpers/icons";
import {useTranslation} from "react-i18next";
import {SessionContext} from "~/contexts/SessionContext";


export default () => {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const {currentUser} = state;

    const currentOrg = useCurrentOrg()

    const [attrs, setAttrs] = useState(currentUser)

    const [loading, setLoading] = useState()
    useEffect(()=>{
        if(currentUser?.id) {
            setLoading(true)

            api(`/api/whoami`).then(res => {
                setLoading(false)
                setAttrs(res.data);
            })
        }
    }, [currentUser?.id])

    const [avatarLoading, setAvatarLoading] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar_url);

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('error-avatar-only-jpg-png','You can only upload JPG/PNG file!'));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(t('error-avatar-image-must-be-smaller','Image must smaller than 2MB!'));
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setAvatarLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            setAvatarLoading(false);
            setAvatarUrl(info.file.response.avatar_url);
            dispatch({
                type:'user_updated',
                user: info.file.response,
            });
        }
    };

    const uploadButton = (
        <div>
            {avatarLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">{t('upload','Upload')}</div>
        </div>
    );

    let lastUsernameCheck = null;

    const refreshOrg = useRefreshOrg()

    const disconnectFrame = ()=>{
        api.post('/api/integrations/disconnect_frame').then(res => {
            message.success(t('message-frame-disconnected','Frame disconnected!'))
            refreshOrg()
        })
    }

    const frameConnected = ()=>{
        message.success(t('message-frame-connected','Frame connected!'))
    }

    const disconnectLightroom = ()=>{
        api.post('/api/integrations/disconnect_lightroom').then(res => {
            message.success(t('message-lightroom-disconnected','Lightroom disconnected!'))
            refreshOrg()
        })
    }

    const lightroomConnected = ()=>{
        message.success(t('message-lightroom-connected','Lightroom connected!'))
    }

    const disconnectDropbox = ()=>{
        api.post('/api/integrations/disconnect_dropbox').then(res => {
            message.success(t('message-dropbox-disconnected','Dropbox disconnected!'))
            refreshOrg()
        })
    }

    const dropboxConnected = ()=>{
        message.success(t('message-dropbox-connected','Dropbox connected!'))
    }

    const googleDriveConnected = ()=> {
        message.success('Google Drive connected!')
    }

    const disconnectGoogleDrive = ()=>{
        api.post('/api/integrations/disconnect_google_drive').then(res => {
            message.success('Google Drive disconnected!')
            refreshOrg()
        })
    }

    const disconnectBox = ()=>{
        api.post('/api/integrations/disconnect_box').then(res => {
            message.success(t('message-box-disconnected','Box disconnected!'))
            refreshOrg()
        })
    }

    const boxConnected = ()=>{
        message.success(t('message-box-connected','Box connected!'))
    }

    return (
        <main role={'main'} tabIndex={0}>
            <Card title={<><EditOutlined/> {currentOrg?.title} {t('profile-settings','Profile Settings')}</>} style={{width: isMobile() ? '100%' : 450, margin:'1em auto', backgroundColor:'white'}}>
                <Skeleton active loading={loading} paragraph={{rows:10}}>
                    <label>{t('avatar', 'Avatar')}</label>
                    <Upload
                        name="user[avatar]"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="/api/sign_up"
                        method='put'
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        headers={{'Authorization': `Bearer ${state.jwt}`}}
                    >
                        {avatarUrl ? <img src={avatarUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                    </Upload>
                    <br/>

                    <Formik
                        initialValues={attrs}
                        onSubmit={(values, actions) => {
                            api({
                                method: 'patch',
                                url: `/api/sign_up`,
                                data: {user: values}
                            }).then((res) => {
                                dispatch({
                                    type: 'user_updated',
                                    user: res.data,
                                });
                                setAttrs(res.data)

                                actions.setSubmitting(false)

                                message.success(t('message-your-settings-updated', 'Your settings have been updated!'))

                            }).catch((error) => {
                                console.error(error.response.data)
                                message.error(`Error: ${JSON.stringify(error.response.data)}`)
                                actions.setSubmitting(false)
                            })
                        }}
                        validationSchema={
                            Yup.object({
                                first_name: Yup.string().required(t('required', 'Required')),
                                last_name: Yup.string().required(t('required', 'Required')),
                                username: Yup.string()
                                    .nullable().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9_-]+$/, t('username-validation-message', 'Username must be text and numbers only (no special characters or spaces), e.g. "JohnnyQPublic"')).required(t('required', 'Required'))
                                    .test('checkUniqueUsername', t('error-username-already-exists', 'Username already exists.'), function (value) {
                                        if (value === lastUsernameCheck) return true;
                                        return new Promise((resolve, reject) => {
                                            api.post('/api/check_username', {username: value}).then((res) => {
                                                if (res.data.ok) lastUsernameCheck = value;
                                                resolve(res.data.ok)
                                            }).catch(() => resolve(false));
                                        })
                                    })
                            })
                        }
                    >
                        {({isSubmitting}) => (
                            <Form layout='vertical'>
                                <FormItem label={t('first-name', 'First Name')} name="first_name" required showValidateSuccess>
                                    <Input
                                        required
                                        name="first_name" placeholder={t('placeholder-your-first-name', "Your First Name")}/>
                                </FormItem>

                                <FormItem label={t('last-name', 'Last Name')} name="last_name" required showValidateSuccess>
                                    <Input
                                        required
                                        name="last_name" placeholder={t('placeholder-your-last-name', "Your Last Name")}/>
                                </FormItem>

                                <FormItem label={t('username', 'Username')} name="username" required showValidateSuccess>
                                    <Input required name="username" placeholder={t('placeholder-username', "Username")}/>
                                    <em> <Typography.Text type={'secondary'}> {t('for-at-mentions', 'For @-mentions in comments.')} </Typography.Text></em>
                            </FormItem>

                                <FormItem label={t('title-within-org', 'Title')} name="title">
                                    <Input name="title" placeholder={t('placholder-title-within-org', "Title within organization")}/>
                                </FormItem>

                                {currentOrg?.role_level === 'admin' && (
                                    <FormItem name="notify_new_membership_requests">
                                        <Checkbox name={'notify_new_membership_requests'}>
                                            {t('notify-new-membership-requests', 'Notify New Membership Requests?')}
                                        </Checkbox>
                                    </FormItem>
                                )}

                                <FormItem name="submit" style={{marginBottom: 0}}>
                                    {isSubmitting ? (
                                        <Button block type="primary" icon={<PoweroffOutlined/>} loading>{t('saving', 'Saving')}...</Button>
                                    ) : (
                                        <SubmitButton block>{t('button-save', 'Save')}</SubmitButton>
                                    )}
                                </FormItem>
                            </Form>
                        )}
                    </Formik>
                    <Divider style={{margin: '2em 0'}}/>
                    <p>
                        <Link to={'/account'}>
                            <UserOutlined/> {t('change-your-account-email-password', 'Change your Account email / password.')}
                        </Link>
                    </p>

                    <Divider style={{margin: '2em 0'}}><ApiOutlined/> {t('integrations', 'Integrations')}</Divider>

                    <Space direction={'vertical'} style={{width: '100%'}} size={'large'}>
                        {!currentOrg?.box_token ? (
                            <ConnectBoxButton button cb={boxConnected}/>
                        ) : (
                            <Alert
                                type={'info'}
                                showIcon
                                icon={<BoxIcon/>}
                                message={
                                    <>
                                        Box.com {t('connection', 'Connection')}: <strong>{currentOrg.box_user?.login}</strong>
                                    </>
                                }
                                action={
                                    <Popconfirm onConfirm={disconnectBox} title={t('confirm-disconnect-box', 'Disconnect your Box.com Account?')}>
                                        <Button size={'small'} danger ghost style={{marginLeft: '.5em'}}><DeleteIcon/></Button>
                                    </Popconfirm>
                                }
                            />
                        )}

                        {!currentOrg?.dropbox_token ? (
                            <ConnectDropboxButton button cb={dropboxConnected}/>
                        ) : (
                            <Alert
                                type={'info'}
                                showIcon
                                icon={<DropboxOutlined/>}
                                message={
                                    <>
                                        Dropbox {t('connection', 'Connection')}: <strong>{currentOrg.dropbox_user?.email}</strong>
                                    </>
                                }
                                action={
                                    <Popconfirm onConfirm={disconnectDropbox} title={t('confirm-disconnect-dropbox', 'Disconnect your Dropbox Account?')}>
                                        <Button size={'small'} danger ghost style={{marginLeft: '.5em'}}><DeleteIcon/></Button>
                                    </Popconfirm>
                                }
                            />
                        )}

                        {!currentOrg?.google_drive_access_token ? (
                            <Tooltip title={t('google-drive-verification-note', 'PLEASE NOTE: You may see a "Google has not verified the app" warning. To continue with connecting, click "Advanced" and follow the steps.')}>
                                <div>
                                    <ConnectGoogleDriveButton cb={googleDriveConnected} button/>
                                </div>
                            </Tooltip>
                        ) : (
                            <Alert
                                type={'info'}
                                showIcon
                                icon={<GoogleDriveIcon style={{marginRight: 3}}/>}
                                message={
                                    <>
                                        Google Drive {t('connected', 'Connected')}: <strong>{currentOrg.google_drive_account?.email_address}</strong>
                                    </>
                                }
                                action={
                                    <Popconfirm onConfirm={disconnectGoogleDrive} title={t('confirm-disconnect-google-drive', 'Disconnect your Google Drive Account?')}>
                                        <Button size={'small'} danger ghost style={{marginLeft: '.5em'}}><DeleteIcon/></Button>
                                    </Popconfirm>
                                }
                            />
                        )}

                        {currentUser?.enable_lightroom && (
                            <>
                                {!currentOrg?.lightroom_account ? (
                                    <ConnectLightroomButton cb={lightroomConnected}/>
                                ) : (
                                    <Alert
                                        type={'info'}
                                        showIcon
                                        icon={<LightroomIcon/>}
                                        message={
                                            <>Lightroom {t('connection', 'Connection')}: <strong>{currentOrg.lightroom_account.email}</strong></>
                                        }
                                        action={
                                            <Popconfirm onConfirm={disconnectLightroom} title={t('confirm-disconnect-lightroom', 'Disconnect your Lightroom Account?')}>
                                                <Button size={'small'} danger ghost style={{marginLeft: '.5em'}}><DeleteIcon/></Button>
                                            </Popconfirm>
                                        }
                                    />
                                )}
                            </>
                        )}

                        {!currentOrg?.frame_account ? (
                            <ConnectFrameButton cb={frameConnected}/>
                        ) : (
                            <Alert
                                type={'info'}
                                showIcon
                                icon={<FrameIcon style={{margin: 0}}/>}
                                message={
                                    <>Frame.io {t('connection', 'Connection')}: <strong>{currentOrg.frame_account.email}</strong></>
                                }
                                action={
                                    <Popconfirm onConfirm={disconnectFrame} title={t('confirm-disconnect-frame', 'Disconnect your Frame.io Account?')}>
                                        <Button size={'small'} danger ghost style={{marginLeft: '.5em'}}><DeleteIcon/></Button>
                                    </Popconfirm>
                                }
                            />
                        )}

                        <Divider/>
                        <div>{t('mediagraph-api-organization-id', 'Mediagraph API Organization ID')}: <Typography.Text copyable>{currentOrg?.id}</Typography.Text></div>
                    </Space>
                </Skeleton>
            </Card>
        </main>
    );
}

const ConnectFrameButton = ({cb}) => {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const refreshOrg = useRefreshOrg()

    const {dispatch} = useContext(SessionContext);

    const click = () => {
        const jwt = JSON.parse(localStorage.getItem('app-reducer'))['jwt']
        window.open(`/api/integrations/connect_frame?jwt=${jwt}&org_id=${currentOrg.id}`)

        window.frameCallback = frame_token => {
            console.log('frameCallback', frame_token)
            dispatch({type: 'org_updated', org: {...currentOrg, frame_token, hasFrame: true}})
            refreshOrg()

            cb && cb()
        }
    }

    return (
        <Button onClick={click} icon={<FrameIcon/>} block ghost type={'primary'} style={{color: Colors.blue}}>{t('connect', 'Connect')} Frame.io</Button>
    )
}

const ConnectLightroomButton = ({cb, link}) => {
    const {t} = useTranslation();

    const click = useConnectLightroom(cb)

    if (link) {
        return (
            <a onClick={click}><LightroomIcon/> {t('connect', 'Connect')} Lightroom</a>
        )
    }

    return (
        <Button onClick={click} icon={<LightroomIcon/>} block ghost type={'primary'} style={{color: Colors.blue}}>&nbsp;&nbsp;{t('connect', 'Connect')} Lightroom</Button>
    )
}

const useConnectLightroom = (cb) => {
    const currentOrg = useCurrentOrg()
    const refreshOrg = useRefreshOrg()

    const {dispatch} = useContext(SessionContext);

    const click = () => {
        const jwt = JSON.parse(localStorage.getItem('app-reducer'))['jwt']
        window.open(`/api/integrations/connect_lightroom?jwt=${jwt}&org_id=${currentOrg.id}`)

        window.lightroomCallback = lightroom_account => {
            console.log('lightroomCallback', lightroom_account)
            dispatch({type: 'org_updated', org: {...currentOrg, lightroom_account, hasFrame: true}})
            refreshOrg()

            cb && cb()
        }
    }

    return click
}

export {useConnectLightroom, ConnectLightroomButton}