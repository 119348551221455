import React, {createContext, useContext, useEffect, useState} from "react";
import {SessionContext} from "./SessionContext";
import {useAssetGroupState} from "./AssetGroupContext";
import useCurrentOrg from "../components/helpers/useCurrentOrg";
import {isMobile} from "../plugins/device-detect";

const ViewSettingsContext = createContext(null);

const ViewSettingsProvider = ({children})=>{
    const {state, dispatch} = useContext(SessionContext);

    const currentOrg = useCurrentOrg()
    const {currentAssetGroup} = useAssetGroupState()

    const defaults = {
        gridSize: isMobile() ? 'xl' : 'm',
        gridColor: '#ffffff',
        viewMode: 'grid',
        showFilenames: false,
        showHeadline: false,
        autoPlay: false,
        convertSpacesToOr: false,
        convertCommasToOr: false,
        showIcons: true,
        showRating: false,
        colorLabel: null,
        ...(currentOrg?.view_settings || {}),
    }

    const [manual, setManual] = useState()
    useEffect(()=>{
        setManual(false)
    }, [currentAssetGroup?.id])

    // console.log('defaults: ', defaults, 'manual:', manual, 'view_settings:',  currentAssetGroup?.view_settings)

    const {viewMode, gridSize, gridColor, tocHidden, showFilenames, autoPlay, showIcons, showRating, showHeadline, convertCommasToOr, convertSpacesToOr, colorLabel} =
        {...defaults, ...state, ...(manual ? {} : currentAssetGroup?.view_settings) };

    const changeViewMode = (e)=>{
        const val = typeof e == 'string' ? e : e.target.value
        if(val != viewMode) dispatch({type:'setViewMode', viewMode: val})
        setManual(true)
    }

    const changeGridSize = (e)=>{
        const val = typeof e == 'string' ? e : e.target.value
        if(val != gridSize) dispatch({type:'setGridSize', gridSize: val})
        setManual(true)
    }

    const changeGridColor = color =>{
        const val = typeof color == 'string' ? color : color.hex
        if(val != gridColor) dispatch({type: 'setGridColor', gridColor: val})
        setManual(true)
    }

    const changeShowFilenames = e =>{
        const val = e.target.checked
        if(val != showFilenames) dispatch({type: 'setShowFilenames', showFilenames: val})
        setManual(true)
    }
    const changeShowHeadline = e =>{
        const val = e.target.checked
        if(val !== showHeadline) dispatch({type: 'setShowHeadline', showHeadline: val})
        setManual(true)
    }

    const changeAutoPlay = e =>{
        const val = e.target.checked
        if(val != autoPlay) dispatch({type: 'setAutoPlay', autoPlay: val})
        setManual(true)
    }

    const changeShowIcons = e =>{
        const val = e.target.checked
        if(val != showIcons) dispatch({type: 'setShowIcons', showIcons: val})
        setManual(true)
    }

    const changeShowRating = e =>{
        const val = e.target.checked
        if(val !== showRating) dispatch({type: 'setShowRating', showRating: val})
        setManual(true)
    }

    const changeColorLabel = val =>{
        dispatch({type: 'setColorLabel', colorLabel: val})
        setManual(true)
    }

    const toggleToc = ()=>{
        dispatch({type:'setTocHidden', tocHidden: !tocHidden})
    }

    const changeConvertCommasToOr = e => {
        const val = e.target.checked
        if(val != convertCommasToOr) dispatch({type:'setConvertCommasToOr', convertCommasToOr: val})
        setManual(true)
    }

    const changeConvertSpacesToOr = e => {
        const val = e.target.checked
        if(val != convertSpacesToOr) dispatch({type:'setConvertSpacesToOr', convertSpacesToOr: val})
        setManual(true)
    }

    return (
        <ViewSettingsContext.Provider
            value={
                {
                    defaults, viewMode, gridSize, gridColor, tocHidden,
                    changeGridSize, changeGridColor, changeViewMode, toggleToc,
                    showFilenames, changeShowFilenames, autoPlay, changeAutoPlay, showIcons, changeShowIcons,
                    showRating, changeShowRating,
                    showHeadline, changeShowHeadline,
                    convertCommasToOr,
                    changeConvertCommasToOr,
                    changeConvertSpacesToOr,
                    convertSpacesToOr,
                    colorLabel, changeColorLabel,
                }
            }
        >
            {children}
        </ViewSettingsContext.Provider>
    )
}

export {ViewSettingsContext, ViewSettingsProvider}
