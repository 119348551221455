import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    currentAssetGroup: null,
    viewAllType: null,
    currentWorkflowStep: null,
    loadingCurrentAssetGroup: false,
    currentUpload: null,
    currentUploadType: null,
    currentContribution: null,
    myCurrentContribution: null,
    currentTag: null,
    collectionReloads: 0,
    lightboxReloads: 0,
    storageFolderReloads: 0,
    fullReloads: 0,
    gridInitializes: 0,
}

const AssetGroupStateContext = createContext(initialState);
const AssetGroupDispatchContext = createContext(initialState);

// For lookup when clicking AssetGroup in trees:
window.assetGroupSlugs = {}
window.assetGroupIds = {}

const reducer = (state, action) => {
    console.log('AssetsGroupContext reducer:', action.type, action);

    switch (action.type) {

        case 'setCurrentAssetGroup':
            // Set from first:
            const firstManualWorkflowStep = _.first(action.assetGroup?.workflow_steps?.filter(s => !s.auto_approval ))

            const currentWorkflowStep =
                action.assetGroup &&
                state.currentWorkflowStep?.asset_group_id != action.assetGroup?.asset_group_id ?
                    firstManualWorkflowStep :
                    state.currentWorkflowStep;

            return {...state, currentWorkflowStep, currentAssetGroup: action.assetGroup, viewAllType: null, loadingCurrentAssetGroup: false, currentUpload: null, currentUploadType: null, currentContribution: null, myCurrentContribution: null, currentTag: null};

        case 'updateCurrentAssetGroup':
            window.assetGroupSlugs[action.assetGroup.asset_group_id] = action.assetGroup.slug;
            window.assetGroupIds[action.assetGroup.slug] = action.assetGroup.asset_group_id;

            return {...state, currentAssetGroup: {...state.currentAssetGroup, ...action.assetGroup}};

        case 'loadingCurrentAssetGroup':
            return {...state, loadingCurrentAssetGroup: true};

        case 'viewAllType':
            return {...state, currentAssetGroup: null, viewAllType: action.assetGroupType, currentUploadType: null, currentContribution: null, myCurrentContribution: null, currentWorkflowStep: null, currentTag: null};

        case 'setCurrentWorkflowStep':
            return {...state, currentWorkflowStep: action.step};

        case 'loadingCurrentUpload':
            return {...state, loadingCurrentUpload: true};

        case 'setCurrentUpload':
            return {...state, currentAssetGroup: null, loadingCurrentUpload: false, currentUpload: action.upload, viewAllType: null, currentUploadType: null, currentContribution: null, myCurrentContribution: null, currentTag: null};

        case 'updateCurrentUpload':
            return {...state, currentUpload: {...state.currentUpload, ...action.upload}};

        case 'reloadContributionId':
            return {...state, reloadContributionId: action.id};

        case 'incrementCurrentUploadAssets':
            state.currentUpload.assets_count++
            return {...state, currentUpload: {...state.currentUpload}};

        case 'setUploadType':
            return {...state, currentUploadType: action.uploadType, currentUpload: null, currentAssetGroup: null, viewAllType: null, currentContribution: null, myCurrentContribution: null, currentTag: null}

        case 'setMyCurrentContribution':
            return {...state, currentAssetGroup: null, loadingCurrentUpload: false, currentUpload: null, viewAllType: null, currentUploadType: null, currentContribution: null, myCurrentContribution: action.contribution, currentTag: null};

        case 'setCurrentContribution':
            return {...state, currentAssetGroup: null, loadingCurrentUpload: false, currentUpload: null, viewAllType: null, currentUploadType: null, currentContribution: action.contribution, myCurrentContribution: null, currentTag: null};

        case 'setCurrentTag':
            return {...state, currentAssetGroup: null, loadingCurrentUpload: false, currentUpload: null, viewAllType: null, currentUploadType: null, currentContribution: null, myCurrentContribution: null, currentTag: action.tag};

        case 'newAssetGroup':
            return {...state, newAssetGroup: action.assetGroup}

        case 'newAssetGroupAdded':
            return {...state, newAssetGroup: null}

        case 'reloadCollections':
            return {...state, collectionReloads: state.collectionReloads + 1}

        case 'reloadLightboxes':
            return {...state, lightboxReloads: state.lightboxReloads + 1}

        case 'reloadStorageFolders':
            return {...state, storageFolderReloads: state.storageFolderReloads + 1}

        case 'reloadAll':
            return {...state, fullReloads: state.fullReloads + 1}

        case 'reinitializeGrid':
            return {...state, gridInitializes: state.gridInitializes + 1}

        default:
            return state;
    }
}

const AssetGroupProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AssetGroupStateContext.Provider value={state}>
            <AssetGroupDispatchContext.Provider value={dispatch}>
                {children}
            </AssetGroupDispatchContext.Provider>
        </AssetGroupStateContext.Provider>
    )
}

function useAssetGroupState() {
    const context = useContext(AssetGroupStateContext)
    if (context === undefined) {
        throw new Error('useAssetsState must be used within a AssetsProvider')
    }
    return context
}

function useAssetGroupDispatch() {
    const context = useContext(AssetGroupDispatchContext)
    if (context === undefined) {
        throw new Error('useAssetsDispatch must be used within a AssetsProvider')
    }
    return context
}

export {
    AssetGroupProvider,
    useAssetGroupState,
    useAssetGroupDispatch
};
