import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Drawer,
    Menu,
    Tabs,
    Space,
    message,
    Modal,
    Input as AntInput,
    Skeleton,
    Statistic,
    Row,
    Col, Card, Tooltip
} from "antd";
import setTitle from "../helpers/setTitle";
import Content from "../Content";
import CollectionsTable from "./CollectionsTable";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import DownloadsReport from "./DownloadsReport";
import useCurrentOrg from "../helpers/useCurrentOrg";
import filesize from "filesize";
import {DownloadOutlined, FieldTimeOutlined, InfoCircleOutlined} from "@ant-design/icons";
import PopularityReport from "./PopularityReport";

export default () => {
    const {t} = useTranslation();
    setTitle(t('reports','Reports'));

    const ability = useAbility(AbilityContext);
    const viewAll = ability.can('view_all_reports', 'Organization')

    const getPath = useOrgPath()
    const { report } = useParams();

    const [tab, setTab] = useState(report || 'overview');

    useEffect(()=>{
        if(report?.length) setTab(report)
    }, [report])

    const navigate = useNavigate();

    const clickTab = key => {
        navigate(getPath(`/manage/reports/${key}`))
        setTab(key)
    }

    return (
        <>
            <Tabs
                activeKey={tab}
                onTabClick={clickTab}
                id={'report-tabs'}
            >
                <TabPane tab={t("overview","Overview")} key="overview">
                    <ReportsOverview/>
                </TabPane>
                {viewAll && (
                    <TabPane tab={t("storage","Storage")} key="storage">
                        <StorageReport/>
                    </TabPane>
                )}
                <TabPane tab={<div id={'popularity-tab'}>{t('popularity','Popularity')}</div>} key="popularity">
                    <PopularityReport/>
                </TabPane>
                <TabPane tab={t("downloads","Downloads")} key="downloads">
                    <DownloadsReport/>
                </TabPane>

                {viewAll && (
                    <TabPane tab={t("share-links","Share Links")} key="share-links">
                        <ShareLinkReport/>
                    </TabPane>
                )}
                {viewAll && (
                    <TabPane tab={t("quick-shares","Quick Shares")} key="shares">
                        <SharesTable/>
                    </TabPane>
                )}

                {ability.can('index', 'BulkJob') && (
                    <TabPane tab={t("bulk-jobs","Bulk Jobs")} key="bulk-jobs">
                        <BulkJobsTable/>
                    </TabPane>
                )}

                {ability.can('index', 'Ingestion') && (
                    <TabPane tab={t("ingestions","Ingestions")} key="ingestions">
                        <IngestionsTable/>
                    </TabPane>
                )}
            </Tabs>

        </>
    );
}

const ReportsOverview = ()=>{
    const {t} = useTranslation();
    const [stats, setStats] = useState()

    const currentOrg = useCurrentOrg()

    useEffect(()=>{
        if(!currentOrg) return;

        api(`/api/organizations/${currentOrg.id}/stats`).then(res =>{
            setStats(res.data)
        })
    }, [currentOrg?.id])

    const statDefs = {
        adv_count: {name: t('assets','Assets'), tooltip: t('tooltip-including-versions','Including Versions')},
        assets_size: {name: t('storage','Storage'), render: val => filesize(val)},
        users_count: {name: t('users','Users')},
        logins_this_week: {name: t('logins-this-week','Logins This Week')},
        uploading_users_count: {name: t('uploaders','Uploaders'), tooltip: t('tootlip-uploaders','Users that have uploaded assets, including public.')},
        pending_invites_count: {name: t('pending-invites','Pending Invites'), tooltip: t('tooltip-pending-invites','Invites that have not been accepted or rejected.')},
        pending_membership_requests_count: {name: t('pending-requests','Pending Requests'), tooltip: t('tootlip-pending-requests','Membership Requests that have not been accepted or rejected.')},
        collections_count: {name: t('collections','Collections')},
        lightboxes_count: {name: t('lightboxes','Lightboxes')},
        share_links_count: {name: t('share-links','Share Links')},
        downloads_count: {name: t('downloads','Downloads')},
        asset_views_count: {name: t('asset-views','Asset Views')},
        asset_searches_count: {name: t('asset-searches','Asset Searches')},
        tags_count: {name: t('tags','Tags')},
        taggings_count: {name: t('taggings','Taggings')},
    }

    return (
        <Skeleton loading={!stats}>
            <Row gutter={8}>
                {stats && Object.keys(statDefs).map((key,i) => (
                    <Col key={key}>
                        <Card style={{margin:'0 1em 1em 1em'}}>
                            <Statistic
                                key={key}
                                title={
                                    <>
                                        {statDefs[key]?.name || key}

                                        {statDefs[key]?.tooltip && (
                                            <Tooltip title={statDefs[key]?.tooltip}>
                                                <InfoCircleOutlined style={{marginLeft:'.5em'}}/>
                                            </Tooltip>
                                        )}
                                    </>
                                }
                                value={(statDefs[key]?.render && statDefs[key]?.render(stats[key])) || stats[key]}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </Skeleton>
    )
}

import {ColumnChart} from 'react-chartkick'
import 'chartkick/chart.js'
import ShareLinkReport from "./ShareLinkReport";
import {AbilityContext, Can} from "../helpers/Can";
import {useAbility} from "@casl/react";
import OrgNavLink, {useOrgPath} from "../helpers/OrgNavLink";
import BulkJobsTable from "./BulkJobsTable";
import SharesTable from "./SharesTable";
import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import IngestionsTable from "@/components/manage/IngestionsTable";
import api from "../api";

const StorageReport = ()=>{
    const {t} = useTranslation();
    const [data, setData] = useState()

    const currentOrg = useCurrentOrg()

    useEffect(()=>{
        if(!currentOrg) return;

        api(`/api/organizations/${currentOrg.id}/storage.json`).then(res =>{
            res.data.map(type => type.name = t(type.name.toLowerCase(), type.name))
            setData(res.data)
        })
    }, [currentOrg?.id])

    return (
        <Skeleton loading={!data}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                <Button 
                    type={'primary'}
                    icon={<DownloadOutlined/>}
                    onClick={()=>{
                        api(`/api/organizations/${currentOrg.id}/storage.csv`, {responseType: 'blob'}).then(res =>{
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${currentOrg.slug}-storage-report.csv`);
                            document.body.appendChild(link);
                            link.addEventListener('click', () => {
                                message.success(t('download-started', 'Download started'));
                            });
                            link.click();
                            document.body.removeChild(link);

                        })
                    }}
                >
                    {t('download-csv','Download CSV')}
                </Button>
            </div>

            <ColumnChart data={data} stacked ytitle={t("storage-size","Storage Size")} bytes download={'storage-report.png'} height={'75vh'}/>
        </Skeleton>
    )
}