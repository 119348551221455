import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Descriptions, message, Popover, Space, Tag, Tooltip, Tree} from "antd";
import {useFormikContext} from "formik";
import {
    CheckOutlined,
    FileTextOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {SessionContext} from "../../contexts/SessionContext";
import {RightsIcon} from "../helpers/icons";
import {Can} from "../helpers/Can";
import {NavLink} from "react-router-dom";
import {useDrop} from "react-dnd";
import api from "../api";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useFilters} from "../helpers/useFilters";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import OrgNavLink from "../helpers/OrgNavLink";
import RightsPackage from "../widgets/RightsPackage";
import {useTranslation} from "react-i18next";

export default ({opts, search, fieldName, refresh})=>{
    const {t} = useTranslation();
    let values, setFieldValue;

    if(!search) {
        const context = useFormikContext();

        values = context.values;
        setFieldValue = context.setFieldValue;
    }

    const optToNode = opt => {
        return {
            id: opt.id,
            key: opt.id,
            title: <RightsTreeTitle search={search} opt={opt} refresh={refresh}/>,
            isLeaf: true,
            checkable: true,
            visible_assets_count: opt.visible_assets_count,
        }
    }

    useEffect(()=>{
        // TODO: API call that sets `visible_assets_count` on each class

        const treeDataInit = _.map([
            {
                key: 'owned',
                title: t('company-ownership','Company Ownership')
            },
            {
                key: 'unlimited',
                title: t('unlimitted-use','Unlimited Use'),
            },
            {
                key: 'some',
                title: t('limited-use-by-organization','Limited use by Organization'),
            },
            {
                key: 'library',
                title: t('library-rights-only','Library Rights Only'),
            },
            {
                key: 'none',
                title: t('no-rights','No Rights'),
            },
            {
                key: 'unknown_no_search',
                title: t('unknown-no-search','Unknown (No Search)'),
            },
            {
                key: 'unknown_searched',
                title: t('unknown-searched','Unknown (Searched)'),
            },
        ], datum => {
            const total = _.find(opts, {id: datum.key})?.visible_assets_count || 0
            const packages = _.filter(opts, opt => opt.rights_class == datum.key)
            return {
                key: datum.key,
                title: <RightsClassTitle datum={datum} search={search} total={total}/>,
                checkable: false,
                children: _.map(packages, optToNode),
                type: 'rights_class'
            }
        })

        if(search){
            const noneOpt = _.find(opts, {id: 'missing'});
            if(noneOpt) {
                noneOpt.name = <em>{t('no-rights-package-selected','No Rights Package Selected')} &nbsp;&nbsp;</em>;

                treeDataInit.unshift({
                    key: 'missing',
                    isLeaf: true,
                    title: ()=>{
                        return <RightsTreeTitle search={search} opt={noneOpt} refresh={refresh}/>
                    }
                })
            }
        }

        setTreeData(treeDataInit)
    }, [opts])

    const [treeData, setTreeData] = useState([])

    const [checkedKeys, setCheckedKeys] = useState([]);

    const getInputName = (name)=>{
        return fieldName ? `${fieldName}.${name}` : name
    }

    const getValue = (name)=> {
        if(!values) return;
        return fieldName ? values[fieldName] && values[fieldName][name] : values[name]
    }

    useEffect(()=>{
        if(!search) {
            setCheckedKeys(getValue('rights_package_ids') || []);
        }
    }, [getValue('rights_package_ids')])

    const onCheck = keys => {
        if(search) return false;

        setFieldValue(getInputName('rights_package_ids'), keys)
        setCheckedKeys(keys)
    }

    const treeRef = useRef()

    const {filters, setFilters} = useFilters()
    const onKeyDown = e => {
        const {key,type} = treeRef.current.getActiveItem() || {};
        if(e.key === 'Enter' && key) {
            if(type === 'rights_class') {
                const applied = filters.rights_code.indexOf(key) !== -1
                setFilters(applied, {rights_code: key})
            } else {
                const applied = filters.rights.indexOf(key.toString()) !== -1
                setFilters(applied, {rights: key.toString()})
            }
        }
    }

    return (
        <>
            {!!treeData.length && (
                <Tree
                    ref={treeRef}
                    blockNode
                    checkable={!search}
                    selectable={false}
                    defaultExpandAll={!search}
                    treeData={treeData}
                    onCheck={onCheck}
                    onKeyDown={onKeyDown}
                    checkedKeys={checkedKeys}
                />
            )}
        </>
    )
}

const RightsClassTitle = ({search, datum, total})=> {
    const {aggs} = useAggsState();
    const {selectedAssetAggs} = useSelectedAggsState();

    const {filters, setFilters} = useFilters()

    const [count, setCount] = useState(0);

    useEffect(()=>{
        if(!aggs) return;

        const n = _.find(aggs.rights_code?.buckets, agg => agg.key == datum.key)?.doc_count

        setCount(n || 0);
    },[aggs])

    const inSelection = (selectedAssetAggs && _.find(selectedAssetAggs.rights_code?.buckets, agg => agg.key == datum.key)?.doc_count) || 0

    const toggleRightsFilter = () => {
        const applied = filters.rights_code.indexOf(datum.key) != -1
        setFilters(applied, {rights_code: datum.key})
    };

    return (
        <div>
            {datum.title}

            {search && (
                <div style={{float:'right'}}>
                    <Tag
                        style={{margin:0, cursor:'pointer'}}
                        color={filters.rights_code?.indexOf(datum.key) != -1 ? 'grey' : (count > 0 ? 'blue' : 'default')}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleRightsFilter();
                        }}
                    >
                        {!!inSelection && <CheckOutlined/>} {n(total)}
                    </Tag>
                </div>
            )}
        </div>
    )

}

const RightsTreeTitle = ({search, opt, refresh})=> {
    const {t} = useTranslation();
    const {aggs} = useAggsState();
    const {selectedAssetAggs} = useSelectedAggsState();
    const {selectedAssetIds} = useSelectedAssetsState();

    const assetsDispatch = useAssetsDispatch();
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const {filters, setFilters} = useFilters()

    const [count, setCount] = useState(0);

    useEffect(()=>{
        if(!aggs) return;

        const n = opt.id == 'missing' ?
            aggs.missing_rights_package_id?.doc_count
            : _.find(aggs.rights_package_id?.buckets, agg => agg.key == opt.id)?.doc_count

        setCount(n || 0);
    },[aggs])

    const [total, setTotal] = useState(opt.visible_assets_count || 0);

    useEffect(()=>{
        setTotal(opt.visible_assets_count || 0)
    },[opt.visible_assets_count])

    const inSelection = selectedAssetAggs &&
        (opt.id == 'missing' ?
            selectedAssetAggs.missing_rights_package_id?.doc_count
            : _.find(selectedAssetAggs.rights_package_id?.buckets, agg => agg.key == opt.id)?.doc_count || 0);

    const toggleRightsFilter = ({id}) => {
        console.log(filters.rights, id.toString())

        const applied = filters.rights.indexOf(id.toString()) != -1
        setFilters(applied, {rights: id.toString()})
    };

    //--------------------
    // Asset Drop Handling (enabled only on search)
    //--------------------
    const [{canDrop, isOver}, drop] = !search ? [{}] : useDrop({
        accept: 'asset',
        drop: ({asset}) => {
            const ids = [...new Set([asset.id, ...selectedAssetIds])];
            api.post('/api/assets/set_rights_package', {ids: ids, rights_package_id: opt.id }).then(res => {
                setTimeout(()=> {
                    assetsDispatch({type:'reload'});
                    selectedAssetsDispatch({type:'reload'});
                    refresh()
                }, 500)
                setCount(res.data.count)
                message.success(`${ids.length} asset${ids.length > 1 ? 's' : ''} set to ${opt.name}`)
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    const style = isActive ? {
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed'
    } : {};
    //--------------------

    return (
        <div style={style} ref={drop}>
            {opt.name}

            {!search && opt.default && <Tag style={{marginLeft:'0.5em'}}>{t('default','Default')}</Tag>}

            {opt.id != 'missing' && (
                <>
                    <RightsPackage rightsPackage={opt} placement={'right'} style={{display:'inline'}}>
                        <InfoCircleOutlined style={{marginLeft:'0.5em', color:'rgb(176, 176, 176)'}}/>
                    </RightsPackage>
                    {opt.contract_asset && (
                        <Tooltip title={`${t('tootlip-contract-attached','Contract Attached')}: ${opt.contract_asset.filename}`}>
                            <FileTextOutlined style={{marginLeft:'0.5em', color:'rgb(176, 176, 176)'}}/>
                        </Tooltip>
                    )}
                </>
            )}

            {search && (
                <div style={{float:'right'}}>
                    <Tag
                        style={{margin:0, cursor:'pointer'}}
                        color={filters.rights?.indexOf(opt.id.toString()) != -1 ? 'grey' : (count > 0 ? 'blue' : 'default')}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleRightsFilter(opt);
                        }}
                    >
                        {!!inSelection && <CheckOutlined/>} {n(total)}
                    </Tag>
                </div>
            )}
        </div>
    )
}