import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom-v5-compat";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions, Switch, Radio, notification, AutoComplete, Input
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    CheckOutlined, CloseOutlined,
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined,
    UnorderedListOutlined, UserOutlined
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import ApartmentOutlined from "@ant-design/icons/lib/icons/ApartmentOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import TagSynonymManager from "../widgets/TagSynonymManager";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import {ShareIcon} from "@/components/helpers/icons";
import SharesTable from "@/components/manage/SharesTable";
import {FaceAvatar} from "@/components/widgets/AssetModal";
import AssetLink, {useLoadAssetFromGuid} from "@/components/widgets/AssetLink";
import User from "@/components/helpers/User";
import Links from "@/components/widgets/Links";
import {EditableTag} from "@/components/widgets/TagSelect";
import {CreatorTag} from "@/components/explore/CreatorTagList";
import useConsumer from "@/channels/consumer";

export default ({q, onLoaded, refresh}) => {
    const {t} = useTranslation();
    const dispatch = useAssetsDispatch();

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/tags'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-face-deleted.','Face Deleted.'));
            handleTableChange();
        });
    }

    const [bulkDeleting, setBulkDeleting] = useState()

    const destroySelected = ()=>{
        const key = 'face-bulk-delete'
        message.loading({content: t('message-face-deleted','Face Deleted.'), key});

        setBulkDeleting(true)
        setSelectedKeys([])

        api({
            url: `/api/tags/bulk_destroy`,
            params: {ids: selectedKeys},
            method: 'delete'
        }).then(res => {
            message.destroy(key)
            message.success(t('message-faces-deleted','Faces Deleted.'));
            handleTableChange();
            setBulkDeleting(false)
        });
    }

    const NameCell = ({name, tag}) => {
        const [value, setValue] = useState(tag.name || '');

        const onChange = (newValue)=>{
            setValue(newValue);
            api.put(`/api/tags/${tag.id}`, {tag:{name: newValue}}).then(res => {
                if(res.data.errors) {
                    setValue(tag.name);
                    return message.error(`${t('error','Error')}: ` + JSON.stringify(res.data.errors))
                }

                message.success(t('message-name-updated','Name updated.'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        return (
            <Typography.Paragraph
                // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                editable={tag.editable && {onChange}}
            >
                {value}
            </Typography.Paragraph>
        )
    }

    const ListCell = ({value, tag})=>{
        const [list, setList] = useState(tag.list);

        const onChange = value => {
            setList(value);
            api.put(`/api/tags/${tag.id}`, {tag: {list: value}}).then(res => {
                message.success(t('message-face-Updated','Face updated'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        useEffect(()=>{
            setList(tag.list)
        }, [tag.list])

        return (
            <Can I={'manage'} a={'Tag'} passThrough>
                {allowed => {
                    return allowed ? (
                        <Select
                            onChange={onChange}
                            style={{width:150}}
                            value={list}
                            getPopupContainer={() => document.getElementById('faces-table')}
                        >
                            {['visible', 'searchable', 'blocked'].map(level =>
                                <Select.Option key={level} value={level}>
                                            <span style={{color: getColor(level)}}>
                                                {getIcon(level)}&nbsp;&nbsp;{t(level,level)}
                                            </span>
                                </Select.Option>
                            )}
                        </Select>
                    ) : list
                }}
            </Can>
        )
    }

    const TaxonomyCell = ({names, tag})=>{
        const [removing, setRemoving] = useState()
        const removeFromTaxonomy = ()=> {
            setRemoving(true)
            api.post(`/api/tags/${tag.id}/remove_taxonomies`).then(res => {
                setRemoving(false)
                message.success(t('message-tag-removed-from-taxonomies','Tag removed from Taxonomies.'))
                loadTableData()
            })
        }

        return (
            <>
                {names.join(', ')}
                {!!names.length && (
                    <div style={{float:'right'}}>
                        <Popconfirm onConfirm={removeFromTaxonomy} title={t('confirm-remove-from-taxonomies?','Remove From Taxonomies?')}>
                            <Tooltip title={t('tooltip-remove-from-taxonomies','Remove From Taxonomies...')} placement={'left'}>
                                <Button icon={<DeleteOutlined/>} loading={removing} size={'small'}/>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                )}
            </>
        )

    }

    const columns = [
        {
            title: t('face','Face'),
            width: 50,
            render: (tag) => (
                <>
                    {tag.thumb_url && (
                        <Popover content={<Avatar src={tag.thumb_url} size={200}/>} placement={'left'}>
                            <Avatar src={tag.thumb_url} size={50}/>
                        </Popover>
                    )}
                </>
            )
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: (name, tag) => <NameCell name={name} tag={tag}/>
        },
        // {
        //     title: t('description','Description'),
        //     dataIndex: 'description',
        //     sorter: true,
        //     render: (name, tag) => {
        //         const [value, setValue] = useState(tag.description || '');
        //
        //         const onChange = (newValue)=>{
        //             setValue(newValue);
        //             api.put(`/api/tags/${tag.id}`, {tag:{description: newValue}}).then(res => {
        //                 message.success(t('message-description-updated','Description updated.'))
        //                 dispatch({type:'updateTag', tag: {...tag, ...res.data}})
        //             })
        //         }
        //
        //         return (
        //             <Typography.Paragraph
        //                 // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
        //                 editable={tag.editable && {onChange}}
        //             >
        //                 {value}
        //             </Typography.Paragraph>
        //         )
        //     }
        // },
        {
            title: t('list','List'),
            sorter: true,
            dataIndex: 'list',
            filters: [
                {text: t('blocked','Blocked'), value: 'blocked'},
                {text: t('searchable','Searchable'), value: 'searchable'},
                {text: t('visible','Visible'), value: 'visible'},
            ],
            defaultFilteredValue: ['searchable', 'visible'],
            render: (value, tag) =>  <ListCell value={value} tag={tag}/>,
        },
        {
            title: t('taxonomies','Taxonomies'),
            dataIndex: 'taxonomy_names',
            filters: [
                {text: t('in-taxonomy','In Taxonomy'), value: true},
                {text: t('not-in-taxonomy','Not In Taxonomy'), value: false},
            ],
            render: (names, tag) => <TaxonomyCell names={names} tag={tag}/>
        },
        {
            title: t('taggings','Taggings'),
            dataIndex: 'face_taggings_count',
            sorter: true,
            render: (count, tag) => {
                return (
                    <OrgLink to={`/explore?face_tags[]=${tag.name}`}>{count}</OrgLink>
                )
            }
        },
        {
            title: t('user','User'),
            render: (tag) => (
                <SetFaceUser tag={tag} onUpdate={handleTableChange}/>
            )
        },
        {
            title: t('creator-tag','Creator Tag'),
            render: (tag) => (
                <SetFaceCreatorTag tag={tag} onUpdate={handleTableChange}/>
            )
        },
        {
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (tag) => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'Tag'}>
                                <Space direction={'horizontal'}>
                                    <AssociatedFacesButton tag={tag} onUpdate={handleTableChange}/>

                                    <EditableTag tag={tag} editable bulk disableRemove disableTypeSelect afterMerge={handleTableChange}>
                                        <Button icon={<EyeOutlined/>} size={'small'}>{t('details', 'Details')}</Button>
                                    </EditableTag>

                                    <ResetFaceButton tag={tag} onReset={handleTableChange}/>

                                    {/*<Popconfirm*/}
                                    {/*    title={t('confirm-delete-face','Delete Face?')}*/}
                                    {/*    onConfirm={() => destroy(tag.id)}*/}
                                    {/*>*/}
                                    {/*    <Button size={'small'}>*/}
                                    {/*        <DeleteOutlined/>*/}
                                    {/*    </Button>*/}
                                    {/*</Popconfirm>*/}
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: {...params.current, faces: true} }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(()=>{
        handleTableChange({...settings.pagination, current:1}, {list: settings.filters?.list || ['visible', 'searchable']})
    }, [q, refresh])

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        console.log(item);
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    const onListChange = (e)=>{
        const newValue = e.key;
        api.post(`/api/tags/bulk`, {list: newValue, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(t('message-lists-updated','List(s) updated.'))
        })
    }

    const addTaxonomy = ()=>{
        api.post(`/api/tags/bulk`, {add_taxonomy: true, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(`${selectedKeys.length} ${t('message-added-to-taxonomy','Added to Taxonomy.')}`)
        })
    }

    const removeTaxonomy = ()=>{
        api.post(`/api/tags/bulk`, {remove_taxonomy: true, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(`${selectedKeys.length} ${t('message-removed-from-taxonomy','Removed From Taxonomy.')}`)
        })
    }

    const getIcon = (value)=> {
        return {
            visible: <EyeOutlined/>,
            searchable: <SearchOutlined/>,
            blocked: <CloseCircleOutlined/>
        }[value];
    }

    const getColor = (value) => {
        return {
            visible: Colors.green,
            searchable: Colors.lightGrey,
            blocked: Colors.red,
        }[value];
    }

    return (
        <div id={'faces-table'}>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, showSizeChanger: true}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>

                            {selectedKeys.length > 0 &&
                                <Space>
                                    <Dropdown overlay={
                                        <Menu onClick={onListChange}>
                                                {['visible', 'searchable', 'blocked'].map(level => {
                                                    return <Menu.Item key={level} value={level}>{getIcon(level)} {t(level,level)}</Menu.Item>
                                                })}
                                        </Menu>
                                    }>
                                        <Button><EditOutlined/>  {t('bulk-update','Bulk Update')} {selectedKeys.length} {t('list','List')}... <DownOutlined/></Button>
                                    </Dropdown>

                                    <Popconfirm
                                        title={t('confirm-bulk-add-to-taxonomy','Bulk add to Taxonomy?')}
                                        onConfirm={addTaxonomy}
                                    >
                                        <Button ghost type={'primary'} icon={<UnorderedListOutlined />}>
                                            {t('bulk-add-to-taxonomy','Bulk Add {{count}} To Taxonomy', {count: selectedKeys.length})}
                                        </Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={t('confirm-bulk-remove-from-taxonomy','Bulk remove from Taxonomy?')}
                                        onConfirm={removeTaxonomy}
                                    >
                                        <Button ghost danger icon={<CloseCircleOutlined />}>
                                            {t('remove-from-taxonomy','Remove {{count}} From Taxonomy', {count: selectedKeys.length})}
                                        </Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={t('confirm-delete-selected','Delete Selected?')}
                                        onConfirm={destroySelected}
                                    >
                                        <Button ghost danger icon={<DeleteOutlined />} loading={bulkDeleting}>
                                            {t('delete','Delete')} {selectedKeys.length}
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />
        </div>
    );
}

const ResetFaceButton = ({tag, onReset})=> {
    const {t} = useTranslation();
    const consumer = useConsumer();
    const userSub = useRef();

    const [resetting, setResetting] = useState()
    const reset = () => {
        setResetting(true)
        const key = `face-${tag.id}`
        message.loading({content: t('message-resetting-face','Resetting Face...'), key})

        if(!userSub.current) {
            userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                received: (data) => {
                    switch(data.type) {
                        case 'faceResetJobDone':
                            if(data.tag_id === tag.id) {
                                message.destroy(key)
                                message.success(t('message-face-reset','Face Reset!'))
                                setResetting(false)
                                onReset();

                            }
                            return
                    }
                }
            });
        }

        api.post(`/api/tags/${tag.id}/reset_face`).then(res => { })

        return ()=> {
            userSub.current.unsubscribe()
        }
    }

    return (
        <Popconfirm
            title={t('confirm-reset-face','Reset Face and Start Over? Taggings and Trained Faces will be removed.')}
            onConfirm={reset}
            getPopupContainer={() => document.getElementById('faces-table')}
        >
            <Button size={'small'} icon={<CloseOutlined/>} loading={resetting}>{t('reset', 'Reset')}</Button>
        </Popconfirm>
    )
}

const AssociatedFacesButton = ({tag, onUpdate})=> {
    const {t} = useTranslation();
    
    const [visible, setVisible] = useState()

    const [loading, setLoading] = useState()
    const [faces, setFaces] = useState()

    useEffect(()=>{
        if(!visible) return

        setLoading(true)
        api(`/api/tags/${tag.id}/associated_faces`).then(res => {
            setLoading(false)
            setFaces(res.data)
        })
    }, [visible, tag?.id])

    const [count, setCount] = useState(tag.associated_faces_count)
    const onRemove = ()=>{
        setCount(count - 1)
    }

    useEffect(() => {
        setCount(tag.associated_faces_count)
    }, [tag.associated_faces_count]);

    return (<>
        <Button icon={<UserOutlined/>} size={'small'} onClick={()=> setVisible(true)}>
            {t('trained-faces', 'Trained Faces')}&nbsp;<Tag>{count}</Tag>
        </Button>
        <Modal
            open={visible}
            onCancel={()=> setVisible()}
            width={'50%'}
            title={<><UserOutlined/> {tag.name} {t('trained-faces', 'Trained Faces')}</>}
            footer={null}
            destroyOnClose
        >
            <Skeleton active loading={loading}>
                <List
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={faces}
                    renderItem={face => <FaceItem face={face} setFaces={setFaces} faces={faces} onRemove={onRemove} onUpdate={onUpdate}/>}
                />
            </Skeleton>
        </Modal>
    </>);
}

const FaceItem = ({face, faces, setFaces, onRemove, onUpdate})=> {
    const {t} = useTranslation();

    const imgWidth = 1200;
    const thumbnailSize = 100;

    const [associating, setAssociating] = useState()

    const disassociate = () => {
        setAssociating(true)

        api.post(`/api/taggings/${face.id}/disassociate_with_face`).then(res => {
            setAssociating(false)
            setFaces([..._.without(faces, face)])
            message.success(t('face-untrained', 'Face Successfully Un-Trained'))
            onRemove && onRemove()
        })
    }

    const loadAssetFromGuid = useLoadAssetFromGuid()

    const click  = ()=>{
        loadAssetFromGuid(face.asset_guid)
    }

    const [settingMainFace, setSettingMainFace] = useState()
    const setMainFace = ()=>{
        setSettingMainFace(true)
        api.post(`/api/taggings/${face.id}/set_main_face`).then(res => {
            setSettingMainFace(false)
            face.is_main_face = true
            setFaces([...faces])

            onUpdate && onUpdate()
        })
    }

    return (
        <List.Item>
            <Space>
                <Tooltip title={t('view-asset', 'View Asset')} zIndex={2000}>
                    <FaceAvatar
                        src={face.face_permalink_url}
                        faceId={face.face_id}
                        imgHeight={imgWidth / face.image_aspect}
                        imgWidth={imgWidth}
                        thumbnailSize={thumbnailSize}
                        boundingBox={face.bounding_box}
                        onClick={click}
                    />
                </Tooltip>

                {face.is_main_face ? (
                    <Tooltip title={t('main-face', 'Main Face')}>
                        <Tag color={'green'}><CheckOutlined/></Tag>
                    </Tooltip>
                ) : (
                    <Popconfirm
                        title={t('confirm-set-main-face', 'Set as main face?')}
                        onConfirm={setMainFace}
                    >
                        <Tooltip title={t('set-main-face', 'Set Main Face')} placement={'bottom'}>
                            <Button type={'primary'} icon={<CheckOutlined/>} ghost size={'small'} loading={settingMainFace}/>
                        </Tooltip>
                    </Popconfirm>
                )}

                <Popconfirm
                    title={t('disassociate-face-tagging', 'Remove face to person training set?')}
                    onConfirm={disassociate}
                >
                    <Button danger icon={<DeleteOutlined/>} ghost size={'small'} loading={associating}/>
                </Popconfirm>
            </Space>
        </List.Item>
    )
}

const SetFaceUser = ({tag, onUpdate}) => {
    const {t} = useTranslation();
    const [searchOptions, setSearchOptions] = useState([])

    const lastSearchValue = useRef();
    const search = _.debounce((value)=>{
        lastSearchValue.current = value;

        if(value === '') return setSearchOptions([]);

        api('/api/memberships/search', {params: {q: value}}).then(res => {
            if(value != lastSearchValue.current) return;

            const options = res.data.map(m => {
                return {
                    value: m.user.email,
                    label: <User user={m.user} showEmail/>
                }
            });

            setSearchOptions(options);
        });
    }, 250)

    const [searchValue, setSearchValue] = useState('');

    useEffect(()=>{
        setSearchValue('')
    }, [])

    const [selecting, setSelecting] = useState()
    const onSelect = email=> {
        setSelecting(true)
        api.post(`/api/tags/${tag.id}/set_face_membership`, {email: email}).then(res => {
            setSelecting(false)
            onUpdate && onUpdate()
        })
    }

    const [removing, setRemoving] = useState()
    const remove = ()=> {
        setRemoving(true)
        api.post(`/api/tags/${tag.id}/remove_face_membership`).then(res => {
            setRemoving(false)
            onUpdate && onUpdate()
        })
    }

    const [editing, setEditing ] = useState()

    if(tag.face_user && !editing) {
        return (
            <Space>
                <User user={tag.face_user}/>

                <Button icon={<EditOutlined/>} type={'primary'} onClick={()=> setEditing(true)} ghost size={'small'}/>

                <Popconfirm title={t('remove-face-user', 'Remove User?')} onConfirm={remove}>
                    <Button icon={<DeleteOutlined/>} danger ghost size={'small'} loading={removing}/>
                </Popconfirm>
            </Space>
        )
    }

    return (
        <Space>
            <AutoComplete
                popupMatchSelectWidth={500}
                defaultActiveFirstOption
                style={{width: '100%', margin: '.5em 0'}}
                onSearch={search}
                options={searchOptions}
                onSelect={onSelect}
                onChange={setSearchValue}
                value={searchValue}
                getPopupContainer={() => document.getElementById('faces-table')}
            >
                <Input.Search placeholder={t('search-users',"Search Users...")} autoComplete={'off'} disabled={selecting}/>
            </AutoComplete>

            {editing && (
                <Button size={'small'} ghost danger icon={<CloseCircleOutlined/>} onClick={()=> setEditing(false)}/>
            )}
        </Space>
    )
}

const SetFaceCreatorTag = ({tag, onUpdate}) => {
    const {t} = useTranslation();
    const [searchOptions, setSearchOptions] = useState([])

    const lastSearchValue = useRef();
    const [searching, setSearching] = useState()
    const search = _.debounce((value)=>{
        lastSearchValue.current = value;

        if(value === '') return setSearchOptions([]);

        setSearching(true)
        api('/api/creator_tags', {params: {q: value}}).then(res => {
            if(value != lastSearchValue.current) return;

            setSearching(false)

            const options = res.data.map(m => ({value: m.name,}));

            setSearchOptions(options);
        });
    }, 250)

    const [searchValue, setSearchValue] = useState('');

    useEffect(()=>{
        setSearchValue('')
    }, [])

    const [selecting, setSelecting] = useState()
    const onSelect = name => {
        setSelecting(true)
        api.post(`/api/tags/${tag.id}/set_face_creator_tag`, {name}).then(res => {
            setSelecting(false)
            onUpdate && onUpdate()
        })
        return false;
    }

    const [removing, setRemoving] = useState()
    const remove = ()=> {
        setRemoving(true)
        api.post(`/api/tags/${tag.id}/remove_face_creator_tag`).then(res => {
            setRemoving(false)
            onUpdate && onUpdate()
        })
    }

    const [editing, setEditing ] = useState()

    if(tag.face_creator_tag && !editing) {
        return (
            <Space>
                <CreatorTag cTag={tag.face_creator_tag}/>

                <Button icon={<EditOutlined/>} type={'primary'} onClick={()=> setEditing(true)} ghost size={'small'}/>

                <Popconfirm title={t('remove-face-creator-tag', 'Remove Creator Tag?')} onConfirm={remove}>
                    <Button icon={<DeleteOutlined/>} danger ghost size={'small'} loading={removing}/>
                </Popconfirm>
            </Space>
        )
    }

    return (
        <Space>
            <AutoComplete
                popupMatchSelectWidth={500}
                defaultActiveFirstOption
                style={{width: '100%', margin: '.5em 0'}}
                onSearch={search}
                options={searchOptions}
                onSelect={onSelect}
                onChange={setSearchValue}
                loading={searching}
                getPopupContainer={() => document.getElementById('faces-table')}
            >
                <Input.Search placeholder={t('search-creator-tags',"Search Creator Tags...")} autoComplete={'off'} disabled={selecting}/>
            </AutoComplete>

            {editing && (
                <Button size={'small'} ghost danger icon={<CloseCircleOutlined/>} onClick={()=> setEditing(false)}/>
            )}
        </Space>
    )
}
