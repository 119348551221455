import React, {useEffect, useState} from "react";
import api from "../api";
import {Select} from "antd";
import moment from "moment";
import {Select as FormikSelect} from "formik-antd/lib/select";
import {useFormikContext} from "formik";
import {DeleteOutlined} from "@ant-design/icons";

const EventTagSelect = ({style, includeRemove, placeholder})=>{
    const [loading, setLoading] = useState()

    const [eventTags, setEventTags] = useState([])

    useEffect(()=>{
        setLoading(true)
        api('/api/tags/recent_events').then(res => {
            setEventTags(res.data)
            setLoading(false)
        })
    }, [])

    const onSearch = val => {
        setLoading(true)
        api(`/api/tags/events?q=${val}`).then(res => {
            setEventTags(res.data)
            setLoading(false)
        })
    }

    const options = eventTags.map(t =>
        <Select.Option key={t.name}>
            {t.date_start && (
                <>
                    <strong>
                        {moment(t.date_start).format('YYYY-MM-DD')}
                        {t.date_end && t.date_start != t.date_end && <> - {moment(t.date_end).format('YYYY-MM-DD')}</>}
                    </strong>
                    &nbsp;-&nbsp;
                </>
            )}
            {t.name}
        </Select.Option>
    );

    if(includeRemove) options.push(<Select.Option key={'_remove'}><em><DeleteOutlined/> Remove Event Tag</em></Select.Option>)

    return (
        <div onClick={e => e.stopPropagation()}>
            <FormikSelect
                name={'event_tag_name'}
                showSearch
                loading={loading}
                defaultActiveFirstOption={false}
                placeholder={placeholder || 'Search...'}
                filterOption={false}
                onSearch={onSearch}
                notFoundContent={null}
                allowClear
                aria-label={'event_tag_name'}
                style={style || {}}
            >
                {options}
            </FormikSelect>
        </div>
    )
}

export default EventTagSelect;