import {useTranslation} from "react-i18next";
import {useAssetGroupDispatch, useAssetGroupState} from "@/contexts/AssetGroupContext";
import React, {useEffect, useRef, useState} from "react";
import {useSelectedAssetsState} from "@/contexts/SelectedAssetsContext";
import api from "@/components/api";
import {Button, Divider, message, Modal, Popconfirm, Progress, Space, Table, Tag, Tooltip, Typography} from "antd";
import useConsumer from "@/channels/consumer";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import EnabledDisabled from "@/components/helpers/EnabledDisabled";
import {
    ApiOutlined, ArrowRightOutlined, ClockCircleOutlined,
    CloudDownloadOutlined,
    CloudSyncOutlined,
    DownloadOutlined, EditOutlined, FileAddOutlined, InfoCircleOutlined,
    SaveOutlined,
    UploadOutlined
} from "@ant-design/icons";
import TimeAgo from "@/components/helpers/TimeAgo";
import {Formik} from "formik";
import {DeleteIcon, LightroomIcon} from "@/components/helpers/icons";
import {Checkbox, Form, FormItem, Input, Radio} from "formik-antd";
import FloatLabel from "@/components/helpers/FloatLabel";
import LoadingIcon from "antd/lib/button/LoadingIcon";
import HelpPopover from "@/components/HelpPopover";
import PaginationTotal from "@/components/widgets/PaginationTotal";
import AssetBrowseImage from "@/components/explore/AssetBrowseImage";
import StateIcon from "@/components/widgets/StateIcon";
import InfoOutlined from "@ant-design/icons/lib/icons/InfoOutlined";

export const AssetGroupLightroomConnectionButton = ({assetGroup}) => {
    const {t} = useTranslation();

    const {currentAssetGroup} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch();

    const [modalVisible, setModalVisible] = useState()

    const aglc = assetGroup.asset_group_lightroom_connection
    const creating = !aglc?.id;

    const [updating, setUpdating] = useState()

    const [syncing, setSyncing] = useState(aglc?.aasm_state === 'syncing')

    useEffect(()=>{
        setSyncing(aglc?.aasm_state === 'syncing')
    }, [aglc?.aasm_state])

    const {selectedAssetIds} = useSelectedAssetsState()

    const [lastSync, setLastSync] = useState(currentAssetGroup.last_lightroom_sync_at)

    useEffect(() => {
        setLastSync(currentAssetGroup.asset_group_lightroom_connection?.last_sync_at)
        sub.current = null
    }, [currentAssetGroup?.asset_group_id])

    const sync = (selected = false, direction = 'to') => {
        setSyncing(true)

        const data = {direction,}

        if (selected) data.asset_ids = selectedAssetIds;

        api.post(`/api/asset_groups/${currentAssetGroup.asset_group_id}/sync_lightroom`, data).then(res => {
            let str;
            if (selected) {
                str = 'Syncing Selected Files with Lightroom...';
            } else {
                str = direction === 'to' ? 'Syncing Files to Lightroom...' : 'Syncing Files with Lightroom...';
            }

            message.success(str)
        }).catch(() => {
            setSyncing(false)
        })
    }

    const syncTo = () => sync(true, 'to')
    const syncFrom = () => sync(true, 'from')
    const syncAll = () => sync(false, 'all')
    const importNew = () => sync(false, 'from')

    const [deleting, setDeleting ] = useState()
    const deleteConnection = () => {
        setSyncing(true)
        setDeleting(true)
        api.delete(`/api/asset_groups/${assetGroup.asset_group_id}/asset_group_lightroom_connections/${aglc.id}`).then(res => {
            if (assetGroup.asset_group_id === currentAssetGroup?.asset_group_id) {
                assetGroup.asset_group_lightroom_connection = null
                assetGroupDispatch({type: 'updateCurrentAssetGroup', assetGroup: {...assetGroup}})
            }
            message.success('Connection Removed!')
            setSyncing(false)
            setDeleting(false)
        })
    }

    const consumer = useConsumer();
    const sub = useRef();

    const [progress, setProgress] = useState((aglc?.aasm_state === 'syncing' && aglc?.progress) || 0)

    useEffect(() => {
        if (!sub.current) {
            sub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                connected: () => {
                    console.log('UserChannel listening for lightroomSyncProgress')
                },

                received: (data) => {
                    switch (data.type) {
                        case 'lightroomSyncState':
                            setSyncing(data.aasm_state === 'syncing')
                            return;

                        case 'lightroomSyncProgress':
                            if (data.asset_group_id === currentAssetGroup.asset_group_id) {
                                if (data.imports) message.success(`Importing ${data.imports} new files...`)

                                if (data.progress) setProgress(data.progress)

                                if (data.imports || data.progress === 100) {
                                    setSyncing(false)
                                    setProgress(0)
                                    message.success('Done Syncing!')
                                    setLastSync(new Date())
                                }
                            }
                    }
                }
            });
        }

        return () => sub.current.unsubscribe()
    }, [currentAssetGroup?.asset_group_id])

    return (
        (<VerticalSpace>
            {aglc && (
                <VerticalSpace>
                    <div style={{marginBottom: '1em'}}>
                        <div style={{marginBottom:'1em'}}>
                            <div style={{display:'flex'}}>
                                <strong>Connected Album:</strong>

                                <div style={{marginLeft:'auto'}}>
                                    <Space>
                                        <Button icon={<EditOutlined style={{color:Colors.blue}}/>} onClick={() => setModalVisible(true)} block ghost type={'text'}/>

                                        <Popconfirm onConfirm={deleteConnection} title={'Remove Lightroom Connection and Album?'}>
                                            <Button icon={deleting ? <LoadingIcon/> : <DeleteIcon style={{color:Colors.red}}/>} block ghost type={'text'} disabled={syncing}/>
                                        </Popconfirm>
                                    </Space>
                                </div>
                            </div>

                            {aglc.name}
                        </div>

                        <div>
                            <strong>Sending:</strong> {aglc.send_type?.toProperCase()} files
                            <br/>

                            {/*{aglc.send_type === 'all' && (*/}
                            {/*    <>*/}
                            {/*        <EnabledDisabled enabled={aglc.skip_checked_out_files}/> Skip checked out files*/}
                            {/*        <br/>*/}
                            {/*    </>*/}
                            {/*)}*/}
                            <br/>
                            <strong>Retrieving:</strong> As {aglc.retrieve_type === 'version' ? 'new versions' : 'new files'}
                            <br/>
                            {aglc.import_from_lightroom && (
                                <div style={{margin:'1em 0'}}>
                                    <strong> Importing files added in Lightroom</strong>
                                </div>
                            )}
                        </div>
                    </div>

                    {aglc.send_type === 'all' ? (
                        <Button loading={syncing} icon={<CloudSyncOutlined/>} onClick={syncAll} block>
                            Sync All
                        </Button>
                    ) : (
                        <>
                            <Divider>Selective Sync</Divider>
                            <Button block loading={syncing} icon={<UploadOutlined/>} onClick={syncTo}
                                    disabled={!selectedAssetIds.length}>Send {selectedAssetIds.length} Selected
                                Files</Button>
                            <Button block loading={syncing} icon={<DownloadOutlined/>} onClick={syncFrom}
                                    disabled={!selectedAssetIds.length}>Retrieve {selectedAssetIds.length} Selected
                                Files</Button>
                            {/*<Button block loading={syncing} icon={<CloudDownloadOutlined/>} onClick={importNew}>Import*/}
                            {/*    New</Button>*/}
                        </>
                    )}

                    {syncing && <Progress percent={progress}/>}

                    {lastSync && (
                        <small style={{display:'flex', alignItems:'center', gap: 4}}>
                            <span> Last synced <TimeAgo date={lastSync}/> </span>
                            <LightroomSyncLog aglc={aglc}/>
                        </small>
                    )}

                </VerticalSpace>
            )}
            {!aglc && (
                <Button icon={<ApiOutlined/>} onClick={() => setModalVisible(true)} block ghost type={'primary'}>
                    Create Lightroom Connection
                </Button>
            )}
            <Formik
                initialValues={
                    aglc || {
                        name: assetGroup.name,
                        send_type: 'all',
                        auto_send_new_files: true,
                        skip_checked_out_files: true,
                        retrieve_type: 'version',
                        import_from_lightroom: true,
                        checkout_files: true,
                        check_out_expiration_hours: 48
                    }
                }
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setUpdating(true)
                    const data = {asset_group_lightroom_connection: values}

                    const method = creating ? api.post : api.put
                    method(`/api/asset_groups/${assetGroup.asset_group_id}/asset_group_lightroom_connections${creating ? '' : `/${aglc.id}`}`, data).then(res => {
                        actions.resetForm();
                        actions.setSubmitting(false);

                        if (assetGroup.asset_group_id === currentAssetGroup?.asset_group_id) {
                            assetGroup.asset_group_lightroom_connection = res.data
                            assetGroupDispatch({type: 'updateCurrentAssetGroup', assetGroup: {...assetGroup}})
                        }

                        message.success(t(`asset-group-lightroom-connection-${creating ? 'created' : 'updated'}`, `Lightroom Connection ${creating ? 'Created' : 'Updated'}.`))

                        setModalVisible(false)
                        setUpdating(false)

                    }).catch((err) => {
                        actions.setSubmitting(false);
                        message.error(JSON.stringify(err.response.data))
                    })
                }}
            >
                {({values, submitForm, handleSubmit, dirty}) => {
                    return (
                        (<Modal
                            title={<><LightroomIcon/> Lightroom Connection</>}
                            open={modalVisible}
                            destroyOnClose
                            onCancel={() => setModalVisible(false)}
                            centered
                            footer={
                                <Space direction={'horizontal'}>
                                    <Button type={'primary'} onClick={submitForm} loading={updating}>
                                        <SaveOutlined/>
                                        {creating ? t('create', 'Create') : t('update', 'Update')}
                                    </Button>

                                    <Button onClick={() => setModalVisible(false)}>{t('cancel', 'Cancel')}</Button>
                                </Space>
                            }
                        >
                            <Form>
                                <FormItem required name='name' showValidateSuccess>
                                    <FloatLabel label={t('name-of-lightroom-album', 'Name of Lightroom Album')}
                                                name={'name'} value={values?.name}>
                                        <Input size={'large'} required name='name' autoFocus autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                {!aglc?.id && (
                                    <>
                                        <FormItem name={'send_type'}>
                                            <Radio.Group name={'send_type'} style={{width: '100%'}}>
                                                <VerticalSpace>
                                                    <Radio value={'all'}>Send all files to Lightroom</Radio>

                                                    {/*<VerticalSpace style={{paddingLeft: 30}}>*/}
                                                    {/*    <Checkbox disabled={values.send_type !== 'all'}*/}
                                                    {/*              name={'auto_send_new_files'}>Auto-send newly added*/}
                                                    {/*        files</Checkbox>*/}
                                                    {/*    <Checkbox disabled={values.send_type !== 'all'}*/}
                                                    {/*              name={'skip_checked_out_files'}>Skip files that are currently*/}
                                                    {/*        checked out elsewhere</Checkbox>*/}
                                                    {/*</VerticalSpace>*/}

                                                    <Radio value={'selected'}>Send selected files to Lightroom</Radio>
                                                </VerticalSpace>
                                            </Radio.Group>
                                        </FormItem>

                                        {/*<FormItem name={'check_out_files'}>*/}
                                        {/*    <div style={{display: 'flex', alignItems: 'center'}}>*/}
                                        {/*        <Checkbox name={'check_out_files'}>Checkout status will expire in</Checkbox>*/}
                                        {/*        <Input name={'check_out_expiration_hours'} type={'number'} style={{width: 60}}/>*/}
                                        {/*        <div style={{marginLeft: '1em'}}>hours</div>*/}
                                        {/*    </div>*/}
                                        {/*</FormItem>*/}

                                        <>
                                            <Divider><DownloadOutlined/> Retrieve files processed in Lightroom</Divider>

                                            <FormItem name={'retrieve_type'}>
                                                <Radio.Group name={'retrieve_type'} style={{width: '100%'}}>
                                                    <VerticalSpace>
                                                        <Radio value={'version'}>Retrieve as new versions</Radio>
                                                        <Radio value={'new'} disabled={!assetGroup?.enable_contribution}>Retrieve as new files <HelpPopover text='Uploads must be enabled on Lightbox'/></Radio>
                                                    </VerticalSpace>
                                                </Radio.Group>
                                            </FormItem>

                                            <Divider><FileAddOutlined/> Import New</Divider>

                                            <FormItem name={'import_from_lightroom'}>
                                                <Checkbox name={'import_from_lightroom'}  disabled={!assetGroup?.enable_contribution}>
                                                    Import new files added to the Lightroom album <HelpPopover text='Uploads must be enabled on Lightbox'/>
                                                </Checkbox>
                                            </FormItem>
                                        </>
                                    </>
                                )}
                            </Form>
                        </Modal>)
                    );
                }}
            </Formik>
        </VerticalSpace>)
    );
}

const LightroomSyncLog = ({aglc})=>{
    const {t} = useTranslation();

    const [visible, setVisible] = useState(false)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const columns = [
        {
            title: t('synced-at','When'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at, lrs) => (
               <>
                   <TimeAgo date={created_at}/>

                   <Tooltip title={`ID: ${lrs.id}`}>
                       <InfoCircleOutlined style={{marginLeft:'1em'}}/>
                   </Tooltip>
               </>
            )
        },
        {
            title: t('state','State'),
            render:  (lrs) => <StateIcon state={lrs.aasm_state}/>
        },
        {
            title: t('number-sent-assets','Assets Sent'),
            render: lrs => <><Tag>{lrs.sent_asset_ids?.length}</Tag></>
        },
        {
            title: t('number-retrieved-assets','Assets Retrieved'),
            render: lrs => <><Tag>{lrs.retrieved_asset_ids?.length}</Tag></>
        },
    ];

    useEffect(()=>{
        if(!visible) return

        setLoading(true);
        const url = `/api/asset_groups/${aglc.asset_group_id}/asset_group_lightroom_connections/${aglc.id}/lightroom_syncs`;
        api.get(url).then(res => {
            setData(res.data)
            setLoading(false)
        })
    }, [visible, aglc?.id, aglc?.last_sync_at])

    return (<>
        <Tooltip title={t('lightroom-sync-log-tooltip', 'View Sync Log')}>
            <Button type={'text'} size={'small'} icon={<ClockCircleOutlined/>} onClick={()=> setVisible(true)}/>
        </Tooltip>
        <Modal
            title={<><ClockCircleOutlined/> {t('lightroom-sync-log-title','Lightroom Sync Log')}</>}
            open={visible}
            footer={null}
            onCancel={()=> setVisible(false)}
            overlayStyle={{width:'66vw'}}
            width={'66vw'}
        >

            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                loading={loading}
            />
        </Modal>
    </>);
}