import React, {useEffect} from 'react';

import { withRouter } from "react-router-dom";

import {Layout} from 'antd';
const { Content } = Layout;

import AppHeader from '../AppHeader';
import AssetsManager from "./AssetsManager";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";

const ExploreLayout = withRouter(props => {

    const assetGroupDispatch = useAssetGroupDispatch()

    // Cleanup when browsing from Explore:
    useEffect(()=>{
        return ()=> assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: null});
    },[])

    return (
        <Layout>
            <AppHeader/>
            <AssetsManager/>
            <Content
                style={{
                    background: '#fff',
                    margin: 0,
                    minHeight: 280,
                }}
            >
                {props.children}
            </Content>
        </Layout>
    );
});

export default ExploreLayout;
