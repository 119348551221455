import React from "react";

import {Button, Cascader, Descriptions, Drawer, Popover, Select, Space, Tooltip, Typography} from "antd";
import {BlockDownload, BlockView, RightsIcon} from "../helpers/icons";
import TimeAgo from "../helpers/TimeAgo";
import {FileTextOutlined, InfoCircleOutlined, SettingOutlined} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import OrgNavLink from "../helpers/OrgNavLink";
import AssetLink from "./AssetLink";
import HelpPopover from "../HelpPopover";
import {isMobile} from "device-detect";
import {useTranslation} from "react-i18next";

const RightsPackage = ({rightsPackage, placement, children, style, showSummary=false})=> {
    const {t} = useTranslation();
    return (
        <>
            <div style={style}>
                {!children && <><RightsIcon/> {rightsPackage.name}</>}

                <Popover
                    zIndex={1099}
                    title={
                        <>
                            <RightsIcon/> {rightsPackage.name}
                            <div style={{float: 'right'}}>
                                <Can I={'manage'} a={'RightsPackage'}>
                                    <OrgNavLink to={`/manage/rights#edit-${rightsPackage.id}`}>
                                        <Button ghost type={'primary'} size={'small'}>
                                            <SettingOutlined/> {t('button-manage','Manage')}
                                        </Button>
                                    </OrgNavLink>
                                </Can>
                            </div>
                        </>
                    }
                    placement={placement}
                    content={
                        <>
                            <Descriptions bordered size='small' column={1} style={{maxWidth: 500}}>
                                {!!rightsPackage.description?.length && (
                                    <Descriptions.Item
                                        label={<>{t('description','Description')} <HelpPopover code={'rights-package-description'}/></>}>
                                        {rightsPackage.description}
                                    </Descriptions.Item>
                                )}

                                <Descriptions.Item label={t('rights-status','Rights Status')}>
                                    <RightsIcon/> {rightsPackage.rights_class_name}
                                    <br/>
                                    <small style={{
                                        whiteSpace: 'normal',
                                        height: 'auto'
                                    }}>{rightsPackage.rights_class_description}</small>
                                </Descriptions.Item>

                                {!!rightsPackage.meta_field_text?.length && (
                                    <Descriptions.Item
                                        labelStyle={{width: 150}}
                                        label={<>{t('iptc-rights-statement','IPTC Rights Statement')} <HelpPopover
                                            code={'rights-package-iptc-rights'}/></>}
                                    >
                                        {rightsPackage.meta_field_text}
                                    </Descriptions.Item>
                                )}

                                {!!rightsPackage.summary?.length && showSummary && (
                                    <Descriptions.Item label={<>{t('upload-agreement','Upload Agreement')} <HelpPopover
                                        code={'rights-package-upload-agreement'}/></>}>
                                        {rightsPackage.summary}
                                    </Descriptions.Item>
                                )}

                                {rightsPackage.personalize && (
                                    <Descriptions.Item label={<>{t('second-party','Second Party')} <HelpPopover
                                        code={'rights-package-second-party'}/></>}>
                                        {rightsPackage.other_party_name}
                                    </Descriptions.Item>
                                )}

                                {rightsPackage.expires && (
                                    <Descriptions.Item label={t('expiration','Expiration')}>
                                        {rightsPackage.expires_at && (
                                            <TimeAgo date={rightsPackage.expires_at}/>
                                        ) || (
                                            t('none','None')
                                        )}
                                    </Descriptions.Item>
                                )}

                                {rightsPackage.contract_asset && (
                                    <Descriptions.Item label={t('contract','Contract')}>
                                        <Tooltip title={`${t('view','View')} ${rightsPackage.contract_asset.filename}`}>
                                            <AssetLink asset={rightsPackage.contract_asset}/>
                                        </Tooltip>
                                    </Descriptions.Item>
                                )}

                                {rightsPackage.block_level && rightsPackage.block_level > 0 && (
                                    <Descriptions.Item label={t('restricted-access','Restricted Access')}>
                                        {rightsPackage.block_level == '1' && <><BlockDownload/> {t('blocked-download','Blocked Download')}</>}
                                        {rightsPackage.block_level == '2' && <><BlockView/> {t('blocked-view-and-download','Blocked View and Download')}</>}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </>
                    }
                >
                    {children || <InfoCircleOutlined style={{marginLeft:'.5em', color: Colors.lightGrey}}/>}
                </Popover>
            </div>
        </>

    )
}

const RightsPackageSelect = ({asset, bulk, onChange, options, popoverPlacement = 'right', disabled})=> {
    const {t} = useTranslation();
    if(!asset && !bulk) return <></>

    const optionsToCascade =
        Object.keys(RightsStatusOptions).map(opt => {
            return {
                label: RightsStatusOptions[opt].name,
                value: opt,
                children: (options || asset.available_rights_packages)?.filter(rp => rp.rights_class === opt).sort((a,b) => (a.name.trim().toProperCase() > b.name.trim().toProperCase()) ? 1 : ((b.name.trim().toProperCase() > a.name.trim().toProperCase()) ? -1 : 0)).map(rp => {
                    return {
                        label: <RightsPackage rightsPackage={rp} placement={popoverPlacement}/>,
                        value: rp.id,
                    }
                })
            }
        }).filter(node => node.children.length)

    if(bulk) optionsToCascade.push({label: 'None', value: 0})

    return (
        <Cascader
            disabled={disabled}
            onChange={value => value && onChange(value[1] || value[0] || 0)}
            placeholder={t('placeholder-select-one','Select One...')}
            style={{width:'100%'}}
            popupClassName={`right-package-cascader${bulk ? '-bulk' : ''}`}
            defaultValue={asset?.rights_package_id && [asset.rights_package?.rights_class, asset.rights_package_id]}
            allowClear
            displayRender={(labels, selectedOptions)=> {
                const id = selectedOptions[1]?.value
                if(selectedOptions[0] && selectedOptions[0].value == 0) return t('none','None')

                const rp = _.find(options || asset.available_rights_packages, {id})
                return rp && <RightsPackage rightsPackage={rp} placement={popoverPlacement}/>
            }}
            expandTrigger={isMobile() ? 'click' : 'hover'}
            options={optionsToCascade}
        />
    )
}

export default RightsPackage
export {RightsPackageSelect}