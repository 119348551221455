import autolinker from 'autolinker';
import React from "react";

export default ({text, ...props}) => {
    const opts = {newWindow: true, ...props}

    try {
        const linkedText = autolinker.link(text, opts)

        return <div dangerouslySetInnerHTML={{__html: linkedText}}/>
    } catch(e) {
        return <>{text}</>
    }
}
