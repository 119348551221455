import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    assets: {}, // hash by id
    tags: [],
    currentAsset: null,
}

const AssetsStateContext = createContext(initialState);
const AssetsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('AssetsContext reducer:', action.type, action);

    let i, tags, newTags, newAsset;

    switch (action.type) {
        case 'assetsLoaded':

            newTags = _.uniq(_.flatten(action.assets.map(asset => asset.tags)));
            tags = _.uniqBy([...state.tags, ...newTags], 'tag_id')

            action.assets.map(asset => {
                state.assets[asset.id] = {...(state.assets[asset.id] || {}), ...asset}
            })

            return {...state, assets: {...state.assets}, tags};

        case 'assetsAdded':

            newTags = _.uniq(_.flatten(action.assets.map(asset => asset.tags)));
            tags = _.uniqBy([...state.tags, ...newTags], 'tag_id')

            action.assets.map(asset => {
                state.assets[asset.id] = asset
            })

            return {...state, assets: {...state.assets}, tags};

        case 'updateAsset':
            newTags = action.asset.tags || []
            tags = _.uniqBy([...state.tags, ...newTags], 'tag_id')

            state.assets[action.asset.id] = {...(state.assets[action.asset.id] || {}), ...action.asset}

            return {...state, assets: {...state.assets}, tags};

        case 'addTags':
            tags = _.uniqBy([...state.tags, ...action.tags], 'tag_id');
            return {...state, tags}

        case 'updateTag':
            const tag = _.find(state.tags, {tag_id: action.tag.tag_id})

            i = _.indexOf(state.tags, tag)
            state.tags.splice(i, 1, action.tag);

            return {...state, tags: [...state.tags] };

        default:
            return state;
    }
}

const AssetsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AssetsStateContext.Provider value={state}>
            <AssetsDispatchContext.Provider value={dispatch}>
                {children}
            </AssetsDispatchContext.Provider>
        </AssetsStateContext.Provider>
    )
}

function useAssetsState() {
    const context = useContext(AssetsStateContext)
    if (context === undefined) {
        throw new Error('useAssetState must be used within a AssetProvider')
    }
    return context
}

function useAssetsDispatch() {
    const context = useContext(AssetsDispatchContext)
    if (context === undefined) {
        throw new Error('useAssetDispatch must be used within a AssetProvider')
    }
    return context
}

export {
    AssetsProvider,
    useAssetsState,
    useAssetsDispatch
};
