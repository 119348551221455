import TaxonomyTree from "../widgets/TaxonomyTree";
import {Button, Collapse, Divider, Empty, message, Modal, Skeleton, Space, Tooltip} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import api from "../api";
import {Formik, setNestedObjectValues} from "formik";
import * as Yup from "yup";
import {LoadingOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {Checkbox, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import {Can} from "../helpers/Can";
import {SessionContext} from "../../contexts/SessionContext";
import useCurrentOrg from "../helpers/useCurrentOrg";
import OrgNavLink from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import {UserGroupIcon} from "~/components/helpers/icons";
import Sk from "react-timeago/lib/language-strings/sk";

export default ({refresh})=>{
    const [taxonomies, setTaxonomies] = useState([]);

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const [loading, setLoading] = useState()

    useEffect(()=>{
        setLoading(true)
        setTaxonomies([])
        api('/api/taxonomies').then((res)=>{
            setLoading(false)
            setTaxonomies(res.data)
        })
    },[currentOrg?.slug, refresh])

    return (
        <>
            <Skeleton loading={loading} active>
                {taxonomies.length ?
                    taxonomies.map((tax)=>(
                        <TaxonomyTree
                            key={tax.id}
                            taxonomy={tax}
                            style={{marginBottom:'1em'}}
                        />
                    )) : <Empty/>
                }

            </Skeleton>

            <AddTaxonomy
                onUpdate={tax => setTaxonomies(taxonomies.concat(tax))}
                style={{marginTop:'1em'}}
            />
        </>

    )
}

const AddTaxonomy = ({onUpdate, style})=> {
    const {t} = useTranslation();
    const [popoverVisible, setPopoverVisible] = useState(false);

    const showPopover = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setPopoverVisible(true)
    }

    const org = useCurrentOrg()

    return (
        <div style={style}>
            <TaxonomyForm
                visible={popoverVisible}
                onCancel={() => setPopoverVisible(false)}
                onUpdate={onUpdate}
            />

            <Can I={'manage'} a={'Taxonomy'}>
                <Tooltip title={!org.plan_has_multiple_tag_trees && <><OrgNavLink to={'/manage/billing'}>{t('workgroup-or-enterprise-plans','Workgroup or Enterprise Plans')}</OrgNavLink> {t('only','only')}.</>}>
                    <Button size='small' type={'primary'} ghost block onClick={showPopover} disabled={!org.plan_has_multiple_tag_trees}>
                        <PlusOutlined/>
                        {t('button-add-new-tag-tree','Add New Tag Tree')}
                    </Button>
                </Tooltip>
            </Can>
        </div>
    )
}

const TaxonomyForm = ({visible, onCancel, onUpdate, taxonomy})=>{
    const {t} = useTranslation();
    const title = taxonomy ? t('edit-tag-tree','Edit Tag Tree') : t('new-tag-tree','New Tag Tree');

    const autoFocusInput = useRef(null);

    const org = useCurrentOrg()

    return (
        (<Formik
            initialValues={taxonomy || {}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                const data = {taxonomy: values}
                const url = taxonomy ? `/api/taxonomies/${taxonomy.id}` : '/api/taxonomies';
                const method = taxonomy ? api.put : api.post

                method(url, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    onUpdate && onUpdate(res.data);
                    onCancel && onCancel()

                    message.success(taxonomy ? t('message-tag-tree-updated','Tag Tree updated.') : t('message-tag-tree-created','Tag Tree created.'))
                }).catch((err)=>{
                    console.log(err)
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit, setFieldValue, isSubmitting}) => {

                // This is needed since an Enter key press will submit the outer form also
                const onKeyDownCapture = (e) => {
                    if(e.keyCode == 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        submitForm();
                    }
                }

                const [userGroupsLoading, setUserGroupsLoading] = useState()
                const [userGroups, setUserGroups ] = useState()


                useEffect(()=>{
                    if(!visible) return;

                    setUserGroupsLoading(true)
                    api(`/api/user_groups`).then(res => {
                        setUserGroupsLoading(false)

                        setUserGroups(res.data)

                        if(!taxonomy) {
                            const allMembersGroupId = _.filter(res.data,{auto_group:'all'})[0]?.id;
                            setFieldValue('user_group_ids', [allMembersGroupId])
                        }
                    })
                }, [org.id, visible])

                return (
                    (<Modal
                        zIndex={9001}
                        title={title}
                        open={visible}
                        destroyOnClose
                        onCancel={onCancel}
                        centered
                        confirmLoading
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                    <SaveOutlined/>
                                    {taxonomy ? t('button-update','Update') : t('button-create','Create')}
                                </Button>
                                <Button onClick={onCancel}>{t('button-cancel','Cancel')}</Button>
                            </Space>
                        }
                    >
                        <form onSubmit={handleSubmit} onKeyDownCapture={onKeyDownCapture}>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name','Name')} name={'name'} value={values?.name}
                                            description={t('name-description','e.g. Marketing')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='description' style={{marginTop:'.5em'}}>
                                <FloatLabel label={t('description','Description')} name={'description'} value={values?.description} description={t('optional','Optional.')}>
                                    <Input.TextArea rows={2} name='description'/>
                                </FloatLabel>
                            </FormItem>

                            <Divider><UserGroupIcon/> {t('user-groups','User Groups')}</Divider>

                            <Skeleton active loading={userGroupsLoading}>
                                <Checkbox.Group
                                    name={'user_group_ids'}
                                    options={userGroups?.map(group => { return {label: group.name, value: group.id}})}
                                    className={'vertical-checkbox-group-flex'}
                                />
                            </Skeleton>
                        </form>
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

export {TaxonomyForm}

