import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {
    Space,
    Badge,
    Tag,
    Col,
    Input as AntInput,
    Row,
    Tree,
    Tooltip,
    Popover,
    Descriptions,
    message,
    Popconfirm,
    Button, Alert, Skeleton
} from "antd";
import {
    DeleteOutlined,
    EyeOutlined, LinkOutlined, SearchOutlined
} from "@ant-design/icons";
import {GreyInfo, LightboxIcon, UserGroupIcon} from "../helpers/icons";
import api from "../api";
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import {useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useStorageState} from "react-storage-hooks";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import WarningTwoTone from "@ant-design/icons/lib/icons/WarningTwoTone";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import TimeAgo from "../helpers/TimeAgo";
import ContributionInfo from "../widgets/ContributionInfo";
import {SessionContext} from "../../contexts/SessionContext";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

export default ({})=>{
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    // For refreshing counts:
    const [refreshes, setRefreshes] = useState(0);

    const {currentUploadType, currentUpload, reloadContributionId} = useAssetGroupState()

    useEffect(()=>{
        if(reloadContributionId) {
            setRefreshes(refreshes + 1)
            loadData({key: reloadContributionId})
        }
    }, [reloadContributionId])

    const [selectedKeys, setSelectedKeys] = useState([currentUploadType]);

    useEffect(()=>{
        if(currentUploadType) setSelectedKeys([currentUploadType])
        else setSelectedKeys([..._.without(selectedKeys, ...['all', 'group', 'lightbox', 'link'])])
    }, [currentUploadType])

    useEffect(()=>{
        if(currentUpload) {
            setExpandedKeys([...expandedKeys, currentUpload.contribution.type, currentUpload.contribution.id])
            if(currentUpload.contribution.type == 'group' || currentUpload.contribution.type == 'link' || currentUpload.contribution.type == 'lightbox')
                setSelectedKeys([currentUpload.guid])
            else
                setSelectedKeys([currentUpload.contribution.id])
        } else {
            setSelectedKeys([])
        }
    }, [currentUpload])

    const [expandedKeys, setExpandedKeys] = useStorageState(sessionStorage, `uploadsTree`, []);

    const expand = (keys, {expanded, node})=> {
        setExpandedKeys(expandedKeys => _.uniq(expanded ? expandedKeys.concat(node.key) : _.without(expandedKeys, node.key)));
    }

    const [loadedKeys, setLoadedKeys] = useState([]);

    const PendingUploadBadge = ({count}) => <Tooltip title={'Pending Uploads'} placement={'right'}><Badge count={count}/></Tooltip>

    const viewAllTitle = (n) => topLevelTitle(n, <><EyeOutlined/> {t('view-all','View All')}</>)
    const groupTitle = (n, pending) => topLevelTitle(n, <><UserGroupIcon/> {t('groups','Groups')} <PendingUploadBadge count={pending}/></>)
    const lightboxTitle = (n, pending) => topLevelTitle(n, <><LightboxIcon/> {t('lightboxes','Lightboxes')} <PendingUploadBadge count={pending}/></>)
    const linkTitle = (n, pending) => topLevelTitle(n, <><LinkOutlined/> {t('link','Link')} <PendingUploadBadge count={pending}/></>)

    const [loading, setLoading] = useState(true)
    const [empty, setEmpty] = useState(true)

    useEffect(()=>{
        api('/api/upload_counts').then(res => {
            setLoading(false)
            if(res.data.all_assets_count == 0) return setEmpty(true)
            setEmpty(false)

            _.find(treeData, {key: 'all'}).title = viewAllTitle(res.data.all_assets_count)
            _.find(treeData, {key: 'group'}).title = groupTitle(res.data.group_assets_count, res.data.pending_group_uploads_count)
            _.find(treeData, {key: 'lightbox'}).title = lightboxTitle(res.data.lightbox_assets_count, res.data.pending_lightbox_uploads_count)
            _.find(treeData, {key: 'link'}).title = linkTitle(res.data.link_assets_count, res.data.pending_link_uploads_count)

            setTreeData([...treeData])
        })
    }, [currentUpload, refreshes, currentOrg?.slug])

    const topLevelTitle = (count, children)=>{
        return <em className={'node-name'}>{children} <div style={{float:'right'}}><Tag>{n(count)}</Tag></div></em>
    }

    const [treeData, setTreeData] = useState(
        [
            {
                key: 'all',
                title: viewAllTitle(),
                isLeaf: true
            },
            {
                key: 'group',
                title: groupTitle(),
                isLeaf: false
            },
            {
                key: 'lightbox',
                title: lightboxTitle(),
                isLeaf: false
            },
            {
                key: 'link',
                title: linkTitle(),
                isLeaf: false
            }
        ]
    );

    const nodeTitle = (c)=> {
        return (
            <div style={{display:'flex', justifyContent: 'flex-start', verticalAlign:'center', minWidth:0}}>
                <div className='node-name' style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', minWidth:0, flex: '0 1 auto', minHeight:0}}>
                    {c.name} {t('uploads','Uploads')}
                </div>

                <div style={{flexShrink:0}}>
                    <ContributionInfo contribution={c} placement={'right'}>
                        <GreyInfo/> &nbsp;
                    </ContributionInfo>

                    <Tooltip title={'Pending Uploads'} placement={'right'}>
                        <Badge count={c.pending_uploads_count} style={{marginLeft:'.5em'}}/>
                        &nbsp;
                    </Tooltip>
                </div>

                <div style={{flexShrink:0, marginLeft:'auto'}}>
                    <Tag>{n(c.user_assets_count)}</Tag>
                </div>
            </div>
        )
    }

    const uploadNodeTitle = (upload)=> {
        return (
            <>
                <CloudUploadOutlined/> {upload.name || <em>{moment(upload.created_at).calendar()}</em>}

                <Popover
                    title={t('upload-info','Upload Info')}
                    placement={'right'}
                    content={
                        <Space direction={'vertical'}>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('created','Created')}>
                                    <TimeAgo date={upload.created_at}/>
                                </Descriptions.Item>
                                {upload.done_at && (
                                    <Descriptions.Item label={t('completed','Completed')}>
                                        <TimeAgo date={upload.done_at}/>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>

                            {upload.assets_count == 0 && (
                                <Popconfirm
                                    title={t('confirm-delete-empty-upload','Delete Empty Upload?')}
                                    onConfirm={e => {
                                        e.stopPropagation()
                                        cancelUpload(upload)
                                    }}
                                >
                                    <Button
                                        style={{marginLeft:'1em'}}
                                        size={'small'}
                                        danger
                                        ghost
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <DeleteOutlined /> {t('button-cancel','Cancel')}
                                    </Button>
                                </Popconfirm>
                            )}
                        </Space>
                    }
                >
                    <GreyInfo/> &nbsp;
                </Popover>

                {!upload.done && (
                    <>
                        <Popover
                            title={<><WarningTwoTone twoToneColor={upload.all_requirements_met ? '#52c41a' : 'red'}/> Not Submitted </>}
                            content={
                                <>
                                    {upload.all_requirements_met && (
                                        <Alert message={t('message-all-requirements-met','All requirements met.')} showIcon type={'success'}/>
                                    ) || (
                                        <Alert
                                            message={<>{t('unmet-requirements','Unmet requirements')}:</>}
                                            description={
                                                <ul>
                                                    {upload.assets_count == 0 && (
                                                        <li key={'assets'}>{t('no-assets','No Assets')}</li>
                                                    )}

                                                    {!upload.tag_suggesters_met && (
                                                        <li key={'tags'}>{t('tag-suggesters','Tag Suggesters')}</li>
                                                    )}

                                                    {!upload.rights_packages_met && (
                                                        <li key={'rights'}>{t('rights-package-selection','Rights Package Selection')}</li>
                                                    )}

                                                    {!upload.creator_tags_met && (
                                                        <li key={'creators'}>{t('creator-tags','Creator Tags')}</li>
                                                    )}
                                                </ul>
                                            }
                                            type={'error'}
                                            showIcon
                                        />
                                    )}
                                </>
                            }
                        >
                            <WarningTwoTone twoToneColor={upload.all_requirements_met ? '#52c41a' : 'red'}/>
                        </Popover>
                    </>

                )}

                <div style={{float:'right', cursor: 'pointer'}}>
                    <Tag>{upload.assets_count}</Tag>
                </div>
            </>
        )
    }

    const cancelUpload = (upload)=> {
        api({
            url: `/api/uploads/${upload.id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('upload-deleted','Upload Deleted.'));
            loadData({key: upload.contribution.id})
            setRefreshes(refreshes + 1)
            if(upload.id == currentUpload?.id) navigate(getPath(`/explore/my-uploads/all`))
        });
    }

    const getTreeDataNode = node => {
        const find = (checkNode)=> {
            if(checkNode.key == node.key) return checkNode;
            if(!checkNode.children) return;

            let found;
            for(let n of checkNode.children) {
                found = find(n)
                if(found) break
            }
            return found;
        }

        return find({children: treeData})
    }

    const loadData = (node)=> {
        return new Promise(resolve => {
            const done =()=> {
                setLoadedKeys([...loadedKeys, node.key])
                setTreeData([...treeData])
                resolve()
            }

            let treeDataNode;
            switch(node.key) {
                case 'group':
                    api(`/api/contributions/group`).then(res => {
                        treeDataNode = getTreeDataNode(node)
                        if(treeDataNode) treeDataNode.children = res.data.map(c => {
                            return {key: c.id, title: nodeTitle(c), isLeaf: false, type: 'UserGroup'}
                        })
                        done()
                    })
                    break

                case 'link':
                    api(`/api/contributions/link`).then(res => {
                        treeDataNode = getTreeDataNode(node)
                        if(treeDataNode) treeDataNode.children = res.data.map(c => {
                            return {key: c.id, title: nodeTitle(c), isLeaf: false, type: 'Link'}
                        })
                        done()
                    })

                    break

                case 'lightbox':
                    api(`/api/contributions/lightbox`).then(res => {
                        treeDataNode = getTreeDataNode(node)
                        if(treeDataNode) treeDataNode.children = res.data.map(c => {
                            return {key: c.id, title: nodeTitle(c), isLeaf: false, type: 'Lightbox'}
                        })
                        done()
                    })
                    break

                default:
                    api(`/api/contributions/${node.key}/uploads?mine=true`).then(res => {
                        treeDataNode = getTreeDataNode(node)
                        if(treeDataNode) treeDataNode.children = res.data.map(c => {
                            return {key: c.guid, title: uploadNodeTitle(c), isLeaf: true, type: 'Upload'}
                        })
                        done()
                    })

                    break
            }
        })
    }

    useEffect(()=>{
        if(currentUpload) {
            let key;

            switch(currentUpload.contribution.type) {
                case 'group':
                case 'lightbox':
                    key = currentUpload.contribution.id
                    break
                case 'link':
                    key = currentUpload.contribution.type
                    break
            }

            loadData({key})
            setRefreshes(refreshes + 1)
        }

    }, [currentUpload])

    const search = ()=>{

    }

    const navigate = useNavigate();

    const getPath = useOrgPath()

    const onSelect = (keys, {node}) => {
        setSelectedKeys(keys);

        switch(node.key) {
            case 'all':
            case 'group':
            case 'lightbox':
            case 'link':
                return navigate(getPath(`/explore/my-uploads/${node.key}`))
            default:
                switch(node.type) {
                    case 'UserGroup':
                    case 'Lightbox':
                    case 'Link':
                        return navigate(getPath(`/explore/my-uploads/contributions/${node.key}`))
                    case 'Upload':
                    default:
                        return navigate(getPath(`/upload/${node.key}`))

                }
        }
    }

    return (
        <Skeleton active loading={loading}>
            {/*<Row align={'top'}>*/}
            {/*    <Col flex={'auto'}>*/}
            {/*        <AntInput*/}
            {/*            style={{ marginBottom: 8 }}*/}
            {/*            placeholder="Search..."*/}
            {/*            allowClear*/}
            {/*            onChange={search}*/}
            {/*            prefix={<SearchOutlined style={{opacity:0.5}}/>}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {empty ? (
                <em>{t('none-yet','None yet.')}</em>
            ) : (
                <div id={'my-uploads-panel'}>
                    <Tree
                        blockNode
                        checkable={false}
                        selectable
                        treeData={treeData}
                        loadData={loadData}
                        expandedKeys={expandedKeys}
                        onExpand={expand}
                        autoExpandParent={false}
                        selectedKeys={selectedKeys}
                        onSelect={onSelect}
                        loadedKeys={loadedKeys}
                    />
                </div>
            )}
        </Skeleton>
    )
}