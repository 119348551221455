import React, {useEffect, useState} from "react";
import {message, Button, Col, Menu, Popconfirm, Row, Space, Table, Tag, Tooltip, Upload} from "antd";
import TimeAgo from "../helpers/TimeAgo";
import filesize from "filesize";
import {
    CheckOutlined,
    DatabaseOutlined,
    DownloadOutlined,
    FileAddFilled,
    UndoOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {useUploadsDispatch} from "../../contexts/UploadsContext";
import UploadProgress from "../uploads/UploadProgress";
import User from "../helpers/User";
import useAssetViewer from "../helpers/useAssetViewer";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import HelpPopover from "../HelpPopover";
import moment from "moment";
import {useTranslation} from "react-i18next";

export default ({asset})=> {
    const {t} = useTranslation();
    const uploadsDispatch = useUploadsDispatch()
    const assetsDispatch = useAssetsDispatch();

    const [loading, setLoading] = useState()
    const [dataVersions, setDataVersions] = useState([])

    const loadDataVersions = ()=> {
        setLoading(true)
        api(`/api/assets/${asset.id}/asset_data_versions`).then(res => {
            setLoading(false)
            setDataVersions(res.data)
        })
    }

    useEffect(()=>{
        loadDataVersions()
    }, [asset.id, asset.new_data_version, asset.data_versions_count])

    const Thumb = ({ver}) => {
        const openViewer = useAssetViewer({asset: ver});

        const size = 90

        if(ver.new_version && asset.aasm_state != 'processed') {
            return <UploadProgress asset={asset} width={size}/>
        } else {
            return (
                <>
                    {ver.type == 'Image' && ver.thumb_url && (
                        <img
                            src={ver.thumb_url}
                            style={{display:'block', marginLeft:'auto', marginRight:'auto', cursor: 'zoom-in', maxWidth: size, maxHeight: size}}
                            onClick={openViewer}
                        />
                    ) || (
                        <AssetBrowseImage asset={ver} assetSize={size} thumbType={'thumb'} padding={'0.5em'}/>
                    )}

                    {ver.full_url && (
                        <img id={`full-image-v-${ver.id}`} src={ver.full_url} style={{display:'none'}}/>
                    )}
                </>
            )
        }
    }

    const Actions = ({ver}) => {
        const [loading, setLoading] = useState()

        const revert = ()=> {
            setLoading(true)
            api.post(`/api/assets/${asset.id}/revert?version=${ver.number}`).then(res => {
                setLoading(false)
                assetsDispatch({type:'updateAsset', asset: res.data});
                message.success(t('message-asset-version-reverted',`Asset Version Reverted to {{number}}`, {number: ver.number}))
                loadDataVersions()
            })
        }

        console.log('ver', ver.is_head, ver.aasm_state)

        return (
            <Space>
                {ver.original_url && (
                    <Tooltip title={t('tooltip-download-original','Download Original')}>
                        <a href={ver.original_url} download={ver.filename}>
                            <Button icon={<DownloadOutlined/>}/>
                        </a>
                    </Tooltip>
                )}

                {!ver.is_head && ver.aasm_state === 'processed' && (
                    <Popconfirm title={t('confirm-set-version-as-current','Set this data version as current?')} onConfirm={revert}>
                        <Button icon={<UndoOutlined/>} loading={loading}>{t('button-set-version-to-current','Set To Current')}</Button>
                    </Popconfirm>
                )}
            </Space>
        )
    }

    const columns = [
        {
            title: t('version','Version'),
            key: 'number',
            render: ver => (
                <>
                    {ver.number && (
                        ver.is_head ? <Tag icon={<CheckOutlined/>} color={'success'}>#{ver.number}</Tag> : <Tag>#{ver.number}</Tag>
                    )}
                </>
            )
        },
        {
            title: t('thumb','Thumb'),
            key: 'thumb',
            render: ver => <Thumb ver={ver}/>
        },
        {
            title: t('uploaded','Uploaded'),
            key: 'created_at',
            render: ver => (
                <>
                    <TimeAgo date={ver.created_at}/> {ver.user && <>by <User user={ver.user}/></>}
                </>
            )
        },
        {
            title: t('filename','Filename'),
            dataIndex: 'filename',
            key: 'filename'
        },
        {
            title: t('file-size','File Size'),
            dataIndex: 'file_size',
            key: 'file_size',
            render: file_size => filesize(file_size)
        },
        {
            title: t('dimensions','Dimensions'),
            key: 'dims',
            render: ver => ver.width ? `${ver.width}x${ver.height}` : ''
        },
        {
            title: t('duration','Duration'),
            key: 'duration',
            render: ver => ver.duration ? moment(ver.duration).format( 'mm:ss') : ''
        },
        {
            title: t('created_via','Created Via'),
            key: 'created_via',
            render: ver => ver.created_via?.toProperCase()
        },
        {
            key: 'actions',
            title: t('actions', 'Actions'),
            render: ver => <Actions ver={ver}/>
        }
    ]

    const onAddFile = (file)=> {
        // console.log(file)
        file.new_version = true
        file.asset_id = asset.id
        uploadsDispatch({type:'addFiles', files: [file], upload: asset.upload})
        // HACKY: this lets the top dataVersion receive UploadProgress messages:
        setDataVersions(dataVersions => [{aasm_state: 'uploading', asset_id: asset.id, file_size: file.size, filename: file.name, new_version: true}, ...dataVersions])
        return false
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <Row>
                <Col span={12}>
                    <h3><DatabaseOutlined/> {t('data-versions','Data Versions')} <HelpPopover code={'data-versions-tab'}/></h3>
                </Col>
                <Col span={12}>
                    <div style={{float:'right'}}>
                        <Upload
                            beforeUpload={onAddFile}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined/>} type='primary' ghost>
                                {t('button-upload-new-version','Upload New Version')}
                            </Button>
                        </Upload>
                    </div>
                </Col>
            </Row>
            <Table
                scroll={{x:true}}
                size={'small'}
                columns={columns}
                dataSource={dataVersions}
                loading={loading}
            />
        </Space>
    )
}