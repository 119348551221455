import React, {useState} from "react";
import {Collapse, Popover, Button} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Content from "./Content";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import useCurrentOrg from "./helpers/useCurrentOrg";
import {useTranslation} from "react-i18next";

export default ({code, text, children, generalAndAdmin, title, id}) => {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const [clicked, setClicked] = useState();

    const onOpenChange = (visible) => {
        if(!visible) setClicked(false);
    }

    const color = clicked ? 'black' : 'rgb(176, 176, 176)'

    const onClick = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        setClicked(true)
    }

    return (
        <Popover
            zIndex={10000}
            trigger={clicked ? 'click' : ['hover','click']}
            onOpenChange={onOpenChange}
            overlayStyle={{maxWidth: isMobile() ? '100vw' : '50vw'}}
            mouseEnterDelay={0.3}
            content={
                code ? (
                    <>
                        {generalAndAdmin && (
                            <Collapse
                                defaultActiveKey={['general', 'admin']}
                            >
                                <Collapse.Panel header={<strong>{t('general','General')}</strong>} key={'general'} style={{}}>
                                    <Content code={`${code}-general`}/>
                                </Collapse.Panel>

                                {currentOrg?.role_level != 'general' && (
                                    <Collapse.Panel header={<strong>{t('admin','Admin')}</strong>} key={'admin'}>
                                        <Content code={`${code}-admin`}/>
                                    </Collapse.Panel>
                                )}
                            </Collapse>
                        ) || <Content code={code}/>}
                    </>
                ) : text || children
            }
            title={
                <div style={{fontWeight:'bolder'}}>
                    <QuestionCircleOutlined style={{marginRight:'0.5em'}}/>
                    {t('help-info','Help Info')}
                </div>
            }
        >
            <Button
                style={{marginLeft: '0.5em', color:color, cursor:'help'}}
                onClick={onClick}
                id={id}
                type={'text'}
                icon={<QuestionCircleOutlined/>}
                shape={'circle'}
                size={'small'}
                aria-label={t(`help-popover-${code}`, `Help content for ${code}`)}
            >{title}</Button>
        </Popover>
    )
}
