import React, {useEffect, useState} from "react";

import {
    Table,
    Button,
    message,
    Space,
    Popconfirm,
    Popover,
    Modal,
    Card,
    Typography, Skeleton, Dropdown, Menu, Tooltip, Tag
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, FileTextOutlined, HistoryOutlined, LoadingOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import RightsForm from "./RightsForm";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import EnabledDisabled from "../helpers/EnabledDisabled";
import AssetLink from "../widgets/AssetLink";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import WebhookForm from "./WebhookForm";
import {CollectionIcon, ProjectIcon, StorageFolderIcon} from "../helpers/icons";
import OrgNavLink from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newWebhook, q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 1000});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/webhooks'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-webhook-deleted.','Webhook Deleted.'));
            handleTableChange();
        });
    }

    const Actions = ({webhook}) => {
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${webhook.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${webhook.id}`;

        const [currentWebhook, setCurrentWebhook] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`${apiPath}/${id}`).then(res => {
                setCurrentWebhook(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(webhook.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        return (
            <>
                <Space size={5}>
                    <ViewWebhookLogsButton wh={webhook}/>
                    <Button onClick={edit} size={'middle'}>
                        <EditOutlined/>
                        {t('button-edit','Edit')}

                        <WebhookForm
                            wh={webhook}
                            id={webhook.id}
                            visible={formVisible}
                            onSave={onSave}
                            onClose={onClose}
                        />
                    </Button>

                    <Popconfirm
                        title={t('confirm-delete-webhook','Delete Webhook?')}
                        onConfirm={() => destroy(webhook.id)}
                    >
                        <Button size={'middle'}>
                            <DeleteOutlined/>
                        </Button>
                    </Popconfirm>
                </Space>
            </>
        );
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            width: 200,
            render: (date, wh) => <><TimeAgo date={date}/> by <User user={wh.user}/></>
        },
        {
            title: t('enabled','Enabled').toProperCase(),
            dataIndex: 'enabled',
            width: 1,
            sorter: true,
            render: enabled => <EnabledDisabled enabled={enabled}/>,
            filters: [
                {text: t('yes','Yes'), value: 'true'},
                {text: t('no','No'), value: 'false'},
            ]
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('source','Source'),
            render: wh => {
                const path = {
                    StorageFolder: 'folders',
                    Collection: 'collections',
                    Lightbox: 'projects'
                }[wh.asset_group_type];

                const icon = {
                    StorageFolder: <StorageFolderIcon/>,
                    Collection: <CollectionIcon/>,
                    Lightbox: <ProjectIcon/>
                }[wh.asset_group_type];

                return (
                    <OrgNavLink
                        to={`/explore/${path}/${wh.asset_group_slug}`}
                        style={{color:'black'}}
                    >
                        {icon} {wh.asset_group_path_names?.join(' / ')}
                    </OrgNavLink>
                )
            }
        },
        {
            title: 'URL',
            dataIndex: 'url',
            sorter: true,
            render: url => <Typography.Text code copyable>{url}</Typography.Text>
        },
        {
            title: t('group-assets','Group Assets'),
            dataIndex: 'group_assets',
            width: 1,
            render: group_assets => <EnabledDisabled enabled={group_assets}/>,
        },
        {
            key: 'action',
            width: 100,
            render: (webhook) => <Actions webhook={webhook}/>
        }
    ];

    const [settings, setSettings] = useState({})

    const handleTableChange = (newPagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setPagination({...pagination, ...newPagination})
        setSettings({pagination, filters, sorter, q})
        setLoading(true);
        const params = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setPagination({ ...pagination, total: res.headers['total-entries'] })
            setData(res.data)
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newWebhook, q]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, pageSize:100, position: ['none', 'none']}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        {/*<Space>*/}
                        {/*    <TableDataDownloadButton apiPath={apiPath} settings={settings}/>*/}
                        {/*</Space>*/}

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}


const ViewWebhookLogsButton = ({wh})=>{
    const {t} = useTranslation();
    const [visible, setVisible] = useState()

    const onCancel = ()=> setVisible(false)

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({current: 1, pageSize: 1000});

    const [loading, setLoading] = useState()

    const apiPath = `/api/webhooks/${wh.id}/logs`;
    useEffect(()=>{
        if(!visible) return

        setLoading(true)
        api(apiPath).then(res => {
            setLoading(false)
            setData(res.data)
        })
    }, [visible])

    const Actions = ({whr})=>{
        const [payloadVisible, setPayloadVisible] = useState()
        const [payload, setPayload] = useState()
        useEffect(()=>{
            if(!payloadVisible) return;
            api(`/api/webhooks/payload?webhook_request_id=${whr.id}`).then(res => {
                setPayload(res.data)
            })
        }, [payloadVisible])

        const [responseVisible, setResponseVisible] = useState()
        const [response, setResponse] = useState()
        useEffect(()=>{
            if(!responseVisible) return;
            api(`/api/webhooks/response_data?webhook_request_id=${whr.id}`).then(res => {
                setResponse(res.data)
            })
        }, [responseVisible])

        return (
            (<Space>
                <Button onClick={()=> setPayloadVisible(true)} icon={<EyeOutlined/>}>{t('payload','Payload')}</Button>
                <Modal
                    title={t('payload','Payload')}
                    open={payloadVisible}
                    onCancel={()=> setPayloadVisible(false)}
                    footer={null}
                    width={'75vw'}
                >
                    {!payload && <LoadingOutlined/>}

                    {payload && (
                        <small>
                            <pre>{JSON.stringify(payload, null, 2)}</pre>
                        </small>
                    )}
                </Modal>
                <Button onClick={()=> setResponseVisible(true)} icon={<EyeOutlined/>}>{t('response','Response')}</Button>
                <Modal
                    title={t('response','Response')}
                    open={responseVisible}
                    onCancel={()=> setResponseVisible(false)}
                    footer={null}
                    width={'75vw'}
                >
                    {!response && <LoadingOutlined/>}

                    {response && (
                        <small>
                            <pre>{JSON.stringify(response, null, 2)}</pre>
                        </small>
                    )}
                </Modal>
            </Space>)
        );
    }

    const columns = [
        {
            title: t('sent','Sent'),
            dataIndex: 'sent_at',
            sorter: true,
            width: 200,
            render: (date) => <><TimeAgo date={date}/></>
        },
        {
            title: t('status','Status'),
            dataIndex: 'response_status',
            render: (s) => <>{s}</>
        },
        {
            title: t('assets','Assets'),
            dataIndex: 'assets_count',
        },
        {
            title: t('trigger-type','Trigger Type'),
            dataIndex: 'owner_type',
        },
        {
            title: t('retries','Retries'),
            dataIndex: 'retries',
        },
        {
            title: t('next-retry-at','Next Retry At'),
            dataIndex: 'next_retry_at',
            render: (date) => date ? <><TimeAgo date={date}/></> : <></>
        },
        {
            title: t('actions','Actions'),
            width:100,
            render: whr => <Actions whr={whr}/>
        }
    ]

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);
        const params = {
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get(apiPath, { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            // onLoaded && onLoaded()
        })
    }

    return (<>
        <Button icon={<HistoryOutlined/>} onClick={()=> setVisible(true)}>{t('logs','Logs')} &nbsp;<Tag>{wh.requests_count}</Tag></Button>
        <Modal
            title={<><HistoryOutlined/> {t('logs-for','Logs for')} {wh.name}</>}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={'65vw'}
        >
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </Modal>
    </>);
}