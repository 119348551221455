import React, {createContext} from "react";
import { useStorageReducer } from 'react-storage-hooks';

const reducer = (state, action) => {
    console.log('app reducer:', action.type);
    switch(action.type) {
        case 'login':
            return {...state, currentUser: action.user, jwt: action.jwt };

        case 'refreshToken':
            return {...state, jwt: action.jwt };

        case 'logout':
            clearSession();
            clearApp();
            clearShareLinkCode()
            return {...state, currentUser: null, jwt: null, switchedFromUser: null };

        case 'user_updated':
            return {...state, currentUser: action.user };

        case 'setLastOrganization':
            return {...state, currentUser: {...state.currentUser, last_organization: action.org } };

        case 'switchUser':
            clearSession();
            clearShareLinkCode()
            return {...state, currentUser: action.user, jwt: action.user.jwt, switchedFromUser: state.currentUser };

        case 'switchBack':
            clearSession();
            clearShareLinkCode()
            return {...state, currentUser: action.user, jwt: action.user.jwt, switchedFromUser: null };

        case 'setShareLink':
            return {...state, shareLink: action.shareLink };

        default:
            return state;
    }
};

const initialState = {
    currentUser: null,
    switchedFromUser: null,
    jwt: null,
    shareLink: null,
};

const AppContext = createContext(initialState);

function AppProvider(props) {
    const key = 'app-reducer'
    const [state, dispatch, writeError] = useStorageReducer(localStorage, key, reducer, initialState);

    window.clearApp = ()=> localStorage.removeItem(key);

    window.onstorage = () => {
        const appState = JSON.parse(window.localStorage.getItem(key));

        if(appState && (appState.currentUser?.id !== state.currentUser?.id)) location.reload()
    };

    return <AppContext.Provider value={{state, dispatch}} {...props}/>;
}

export { AppContext, AppProvider };
