import React, {useState, useEffect, useRef, useContext, useCallback} from "react";
import pluralize from 'pluralize'
import {
    Popconfirm,
    Modal,
    Button,
    message,
    Space,
    Tooltip,
    Divider,
    Progress,
    Typography,
    Popover,
    Badge,
    Dropdown,
    Menu, Alert, Collapse, Input as AntInput, Switch, Select
} from "antd";
import {Formik} from "formik";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import api from "../api";
import {Checkbox, Form, Rate, FormItem, Input, Radio} from "formik-antd";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import fileDownload from 'js-file-download';
import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import {useAssetsState} from "../../contexts/AssetsContext";
import {
    ApiOutlined,
    CheckOutlined,
    CloudOutlined, CopyOutlined,
    DownOutlined, DropboxOutlined, FolderOpenOutlined, FolderViewOutlined, LinkOutlined,
    LoadingOutlined,
    MacCommandFilled
} from "@ant-design/icons";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {ConnectBoxButton, ConnectDropboxButton} from "./UploadHeader";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../helpers/Can";
import {EmbedIcon, ShareIcon} from "../helpers/icons";
import useConsumer from "../../channels/consumer";
import TextArea from "antd/lib/input/TextArea";
import VerticalSpace from "../helpers/VerticalSpace";
import {useTranslation} from "react-i18next";
import FloatLabel from "@/components/helpers/FloatLabel";
import HelpPopover from "@/components/HelpPopover";
import {useAssetActions} from "~/contexts/AssetActionsContext";

export default ({block, small, iconOnly, tooltip, assetGroup, asset, afterCreate,})=> {
    const {t} = useTranslation();
    const {selectedAssetIds} = useSelectedAssetsState();

    const {setShareModalOpen} = useAssetActions();
    // For local Modal for asset or assetGroup
    const [open, setOpen] = useState()

    const onClick = e => {
        e.stopPropagation()
        if(asset || assetGroup) setOpen(true)
        else setShareModalOpen(true)
    }

    const ability = useContext(AbilityContext);

    if(!ability.can('create','Share')) return <></>

    if(selectedAssetIds.length > Config.maxShareAssets) {
        return (
            <>
                <Tooltip title={isBrowser() && (tooltip || <>{t('share-max-error','Maximum 250 to share at once.')}</>)} placement={'top'}>
                    <Button
                        size={block || small || iconOnly ? 'small' : 'middle'}
                        icon={<ShareIcon style={{marginLeft: iconOnly && 4}}/>}
                        type={block ? 'text' : 'primary'}
                        block={block}
                        id={'bulk-share-btn'}
                        ghost={!(block || small || iconOnly)}
                        disabled
                    >
                        {isBrowser() && !iconOnly && t('share','Share')}
                    </Button>
                </Tooltip>
            </>
        )
    }

    return (
        <>
            <Tooltip title={isBrowser() && (tooltip || <><MacCommandFilled/> Option S</>)} placement={'top'}>
                <Button
                    size={block || small || iconOnly ? 'small' : 'middle'}
                    icon={<ShareIcon style={{marginLeft: iconOnly && 4}}/>}
                    onClick={onClick}
                    type={block ? 'text' : 'primary'}
                    block={block}
                    id={'bulk-share-btn'}
                    ghost={!(block || small || iconOnly)}
                >
                    {isBrowser() && !iconOnly && t('share','Share')}
                </Button>
            </Tooltip>

            {(asset || assetGroup) && (
                <ShareModal {...{assetGroup, asset, afterCreate, open, setOpen}} />
            )}
        </>
    )
}

const ShareModal = ({assetGroup, asset, afterCreate, open, setOpen})=> {
    const {t} = useTranslation();

    const currentOrg = useCurrentOrg()
    const {selectedAssetIds} = useSelectedAssetsState();

    const ability = useAbility(AbilityContext);

    const consumer = useConsumer();
    const shareSub = useRef();

    const [share, setShare] = useState()
    const [progress, setProgress] = useState()
    const shareRef = useRef();

    // Update the ref whenever share changes
    useEffect(() => {
        shareRef.current = share;
    }, [share]);

    const receivedCallback = useCallback((data) => {
        console.log('SharesChannel received', data, shareRef.current);

        if (data.id === shareRef.current?.id) {
            setProgress(data.progress);

            if (data.aasm_state === 'processed') {
                api(`/api/shares/${data.code}/html`).then(res => {
                    setShare(prevShare => ({
                        ...prevShare,
                        html: res.data,
                        url: data.url,
                        direct_link: data.direct_link
                    }));

                    afterCreate && afterCreate();
                });
            }
        }
    }, [afterCreate]);

    useEffect(() => {
        shareSub.current = consumer.subscriptions.create({channel: "SharesChannel"}, {
            connected: () => {
                console.log('SharesChannel connected');
            },
            received: receivedCallback
        });

        return () => {
            shareSub.current?.unsubscribe();
        };
    }, [receivedCallback]);

    const {shareModalOpen: visible, setShareModalOpen: setVisible} = useAssetActions()

    return (
        (<Formik
            initialValues={{size:'original', enable_download: true, show_email: true, show_banner: true}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                if(assetGroup) {
                    values.asset_group_id = assetGroup.asset_group_id
                } else {
                    values.asset_ids = asset ? [asset.id] : selectedAssetIds;
                }

                api.post( '/api/shares', {share: values}).then(res => {

                    setShare(res.data)
                    actions.resetForm();
                    actions.setSubmitting(false);

                }).catch((err)=>{
                    console.log(err)
                    actions.setSubmitting(false);
                })
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                const radioStyle = {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                };

                let title;
                if(assetGroup) {
                    title = <>{assetGroup.name}</>
                } else if(asset) {
                    title = `1 ${t('asset', 'Asset')}`
                } else {
                    const s = selectedAssetIds.length == 1 ? '' : 's'
                    title = <>{selectedAssetIds.length} {t(`asset${s}`,`Asset${s}`)}</>;
                }

                const ref = useRef()

                useEffect(()=>{
                    if(visible || open) setTimeout(()=> ref.current?.focus(), 100)
                }, [visible || open])

                return (
                    (<Modal
                        destroyOnClose
                        zIndex={2000}
                        onCancel={()=> {
                            setOpen && setOpen()
                            setVisible();
                            setShare();
                            setProgress();
                            shareSub.current = null;
                        }}
                        open={visible || open}
                        title={<><ShareIcon/> Share {title}</>}
                        maskClosable={false}
                        footer={!share && (
                                <Space>
                                    <Button
                                        loading={isSubmitting}
                                        onClick={submitForm}
                                        icon={<ShareIcon/>}
                                    >
                                        {t('button-get-share-link','Get Share Link')}
                                    </Button>
                                </Space>
                            )
                        }
                    >
                        <div id={!asset && 'share-modal'}>
                            {share?.html ? (
                                <ShareDetails share={share}/>
                            ) : (
                                <>
                                    {share ? (
                                        <>
                                            <Progress percent={progress}/>
                                        </>
                                    ) : (
                                        <Form onSubmit={handleSubmit} layout="vertical">
                                            <FormItem name={'name'}>
                                                <Input name={'name'} placeholder={t('subject-line-optional','Subject Line (optional)')} ref={ref} autoComplete='off'/>
                                            </FormItem>

                                            <FormItem name='message' style={{marginTop:'.5em'}}>
                                                <FloatLabel label={t('message','Message')} name={'message'} value={values?.message} description={t('add-notes-or-other','Add notes or other info.')}>
                                                    <Input.TextArea rows={2} name='message'/>
                                                </FloatLabel>
                                            </FormItem>

                                            {!ability.can('download_all', 'Asset') && (
                                                <Alert
                                                    type={'warning'}
                                                    showIcon
                                                    message={t('please-note','Please Note')}
                                                    description={t('share-permissions-note',"Your share access is governed by your group permissions. You may not be able to share all files at the selected quality. Please check the download and contact an Admin if you need greater access.")}
                                                />
                                            )}

                                            <VerticalSpace>
                                                <Divider style={{margin:'.5em 0'}}/>

                                                {ability.can('enable_share_download', 'Organization') && (
                                                    <Checkbox name={'enable_download'}>{t('enable-download','Enable Download?')}</Checkbox>
                                                )}

                                                <Checkbox name={'show_email'}>{t('show-email','Show Email?')}</Checkbox>
                                                <Checkbox name={'show_banner'}>{t('show-banner','Show Banner?')}</Checkbox>

                                                <Divider style={{margin:'.5em 0'}}/>

                                                <Radio.Group name={'size'}>
                                                    <Radio value={'original'} style={radioStyle}>{t('original-file','Original File')}</Radio>
                                                    <Radio value={'full'} style={radioStyle}>{t('full-resolution','Full Resolution')} <Typography.Text type={'secondary'}><small>Max / 1080p</small></Typography.Text></Radio>
                                                    <Radio value={'medium'} style={radioStyle}>{t('medium-resolution','Medium Resolution')} <Typography.Text type={'secondary'}><small>{currentOrg?.medium_download_size}px / 720p</small></Typography.Text></Radio>
                                                    <Radio value={'small'} style={radioStyle}>{t('small-resolution','Small Resolution')} <Typography.Text type={'secondary'}><small>640px / 480p</small></Typography.Text></Radio>
                                                </Radio.Group>

                                                <Divider style={{margin:'.5em 0'}}/>

                                                <Checkbox name={'watermarked'}>{t('add-watermark','Add Watermark?')}</Checkbox>
                                            </VerticalSpace>
                                        </Form>
                                    )}
                                </>
                            )}
                        </div>
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

const ShareDetails = ({share}) => {
    const {t} = useTranslation();
    const [copied, setCopied] = useState()
    const [linkCopied, setLinkCopied] = useState()
    const [iFrameCopied, setIFrameCopied] = useState()
    const clickCopy = ()=>{
        navigator.clipboard.writeText(share.html)
        setCopied(true)
    }

    const [initialView, setInitialView] = useState('gallery')

    let url = share.url
    if(initialView === 'first-image') url += '#nanogallery/my_nanogallery/0/1'

    const iFrameHtml = `<iframe src="${url}" height="1000" width="100%"/>`

    const clickCopyIFrame = ()=>{
        navigator.clipboard.writeText(iFrameHtml)
        setIFrameCopied(true)
    }

    const clickLinkCopy = ()=>{
        navigator.clipboard.writeText(url)
        setLinkCopied(true)
    }

    useEffect(()=>{
        setCopied(false)
        setLinkCopied(false)
        setIFrameCopied(false)
    }, [share?.id])

    return (
        <VerticalSpace>
            <VerticalSpace size={'large'}>
                {share?.direct_link && (
                    <div>
                        <strong>{t('direct-link', 'Direct Link')} <HelpPopover code={'share-direct-link'}/></strong>
                        <br/>
                        <Typography.Text copyable type={'secondary'}>{share.direct_link}</Typography.Text>

                        <Divider style={{margin:'2em 0 0'}}>{t('gallery-view', 'Gallery View')}</Divider>
                    </div>
                )}
                <div>
                    <strong>{t('initial-view', 'Initial View')} <HelpPopover code={'share-initial-view'}/></strong>
                    <Select
                        onChange={setInitialView}
                        value={initialView}
                        options={[{label: t('gallery', 'Gallery'), value: 'gallery'}, {label: t('first-image', 'First Image'), value: 'first-image'}]}
                        style={{width: '100%'}}
                    />
                </div>
                <Typography.Text copyable type={'secondary'}>{url}</Typography.Text>
                <Button block type={'primary'} ghost onClick={clickLinkCopy} icon={linkCopied ? <CheckOutlined/> : <CopyOutlined/>}>{t('button-copy-Link', 'Copy Link')}</Button>
            </VerticalSpace>
            <Divider>{t('advanced', 'Advanced')}</Divider>
            <Collapse>
            <Collapse.Panel key={'embed'} header={<><EmbedIcon/> {t('embed', 'Embed')} <HelpPopover code={'embed-code'}/></>}>
                <VerticalSpace>
                    <div>
                        <strong>{t('iframe-embed-code', 'iFrame Embed Code')}</strong>
                        <TextArea style={{width: '100%', height: 100}} value={iFrameHtml}/>
                    </div>
                    <Button block onClick={clickCopyIFrame} icon={iFrameCopied ? <CheckOutlined/> : <CopyOutlined/>}>{t('button-copy-html-snippet', 'Copy HTML Snippet')}</Button>
                    <Divider/>
                    <div>
                        <strong>{t('raw-embed-code', 'Raw Embed Code')}</strong>
                        <TextArea style={{width: '100%', height: 100}} value={share.html}/>
                    </div>
                    <Button block onClick={clickCopy} icon={copied ? <CheckOutlined/> : <CopyOutlined/>}>{t('button-copy-html-snippet', 'Copy HTML Snippet')}</Button>
                </VerticalSpace>
            </Collapse.Panel>
            </Collapse>
        </VerticalSpace>
    )
}

export {ShareModal, ShareDetails}
