import {ApiOutlined, CheckOutlined} from "@ant-design/icons";
import {Alert, Button, message, notification, Popconfirm} from "antd";
import {DeleteIcon} from "../helpers/icons";
import React, {useEffect, useState} from "react";
import api from "../api";
import useCurrentOrg from "../helpers/useCurrentOrg";

export default ({storageFolder})=>{
    const currentOrg = useCurrentOrg()

    const [lightroomLoading, setLightroomLoading] = useState()

    const connectLightroom = ()=>{
        setLightroomLoading(true)

        api.post('/api/personal_access_tokens/lightroom', {asset_group_id: storageFolder?.id}).then(res => {
            setLightroomConnection(res.data)

                notification.success({
                    key: 'lightroomConnectionNotification',
                    duration: 0,
                    message: <>Connecting Lightroom...</>,
                    description: <>
                        Lightroom should open now and begin the plugin initialization. If nothing happens, please install Lightroom and the Mediagraph Plugin and try again.
                    </>
                })

            const url =
                `lightroom://${Config.lightroomProtocol}?access_token=${res.data.token}&organization_id=${currentOrg.id}&storage_folder_id=${storageFolder?.id}`+
                `&api_host=${Config.apiHost || window.location.hostname}&upload_bucket=${Config.uploadBucket}&aws_key=${Config.awsKey}&env=${Config.releaseStage}`

            const a = document.createElement("a")
            a.setAttribute('href', url)
            a.style.display = 'none';
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a);

            setLightroomLoading(false)
        })
    }

    const disconnectLightroom = ()=>{
        api.delete(`/api/personal_access_tokens/lightroom?asset_group_id=${storageFolder?.id}`).then(res => {
            message.success('Lightroom Disconnected')
            setLightroomConnection(null)
        })
    }

    const [lightroomConnection, setLightroomConnection] = useState()
    useEffect(()=>{
        api(`/api/personal_access_tokens/lightroom?asset_group_id=${storageFolder?.id}`).then(res => {
            setLightroomConnection(res.data)
        })
    }, [])

    return (
        <div style={{marginTop:'.5em'}}>
            {lightroomConnection ? (
                <Alert
                    showIcon type={'success'} message={'Lightroom is Connected.'}
                    action={
                        <Popconfirm
                            onConfirm={disconnectLightroom}
                            title={'Disconnect your Lightroom?'}
                            zIndex={10032}
                            onClick={e => e.stopPropagation()}
                        >
                            <Button size={'small'} danger ghost style={{marginLeft:'.5em'}} onClick={e => e.stopPropagation()}><DeleteIcon/></Button>
                        </Popconfirm>
                    }
                />
            ) : (
                <Popconfirm
                    onConfirm={connectLightroom}
                    title={<>Make sure the Lightroom Plugin <br/> is installed before connecting. <br/><br/> Proceed?</>}
                    zIndex={10032}
                    onClick={e => e.stopPropagation()}
                >
                    <Button loading={lightroomLoading} icon={<ApiOutlined/>} onClick={e => e.stopPropagation()}>Connect Lightroom</Button>
                </Popconfirm>
            )}
        </div>
    )
}