import React, {useEffect, useState} from "react";
import {message, Button, Col, Menu, Popconfirm, Row, Space, Table, Tag, Tooltip, Upload, List, Popover} from "antd";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import {RefreshIcon} from "../helpers/icons";
import {OrgLink} from "../helpers/OrgNavLink";
import {LineChartOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default ({asset, close})=> {
    const {t} = useTranslation();
    const [loading, setLoading] = useState()
    const [log, setLog] = useState([])

    const loadLog = ()=> {
        setLoading(true)
        api(`/api/assets/${asset.id}/log`).then(res => {
            setLoading(false)
            setLog(res.data)
        })
    }

    useEffect(()=>{
        loadLog()
    }, [asset.id])

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <div>
                <OrgLink to={`/manage/reports/downloads?search=${asset.guid}`} onClick={close}>
                    <LineChartOutlined /> {t('view-download-report','View Download Report')}
                </OrgLink>

                <div style={{float:'right'}}>
                    <Button onClick={loadLog} icon={<RefreshIcon/>} size={'small'}>{t('button-refresh','Refresh')}</Button>
                </div>
            </div>

            <List
                size="small"
                dataSource={log}
                loading={loading}
                renderItem={v => (
                    <List.Item>
                        {v.user ? (<User user={v.user}/>) : t('system', 'System')} <Popover placement='right' content={<pre>{JSON.stringify(v.object_changes, null, 2)}</pre>}>{v.description}</Popover> <TimeAgo date={v.created_at}/>
                    </List.Item>
                )}
            />
        </Space>
    )
}