import {Button, Dropdown, Menu, message} from "antd";
import {DownloadOutlined, DownOutlined} from "@ant-design/icons";
import React, {useState, useEffect} from "react";
import api from "../api";
import {useBulkJobsState} from "../../contexts/BulkJobsContext";
import {useTranslation} from "react-i18next";

export default ({apiPath, settings})=> {
    const {t} = useTranslation();
    const [loading, setLoading] = useState()
    const [guid, setGuid] = useState()

    const {dataDownloads} = useBulkJobsState()
    const data = dataDownloads[guid]

    useEffect(()=>{
        if(data?.url) {
            downloadFile(`/api/downloads/table_data?key=${data.key}`)

            setLoading(false)
            setGuid()
        }
    }, [data?.url])


    const onClick = ({key})=> {
        setLoading(true)
        const params = {
            download: key,
            q: settings.q,
            sortField: settings.sorter?.field,
            sortOrder: settings.sorter?.order,
            pagination: settings.pagination,
            ...settings.filters,
        }

        message.info(t('message-preparing-data','Preparing Data...'))
        api.get(apiPath, { params: params }).then(res => {
            setGuid(res.data.guid)
        })
    }

    return (
        <Dropdown overlay={
            <Menu onClick={onClick}>
                <Menu.Item key="csv">CSV</Menu.Item>
                {/*<Menu.Item key="xls">XLS</Menu.Item>*/}
                {/*<Menu.Item key="json">JSON</Menu.Item>*/}
            </Menu>
        }>
            <Button loading={loading}><DownloadOutlined/> {t('button-data-download','Data Download')} <DownOutlined/></Button>
        </Dropdown>
    )
}

function downloadFile(filePath){
    const link = document.createElement('a');
    link.target = '_blank'
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
}