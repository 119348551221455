import React, {useContext} from "react";
import axios from "axios";

// Obtain the fresh token each time the function is called
function getAccessToken(){
    return JSON.parse(localStorage.getItem('app-reducer'))?.jwt?.replace('"','');
}
window.isLoggedIn = ()=> !!getAccessToken()?.length

function getOrgId(){
    return JSON.parse(sessionStorage.getItem('session-reducer'))?.currentOrg?.id;
}

function getShareLinkCode(){
    return JSON.parse(localStorage.getItem('app-reducer'))?.shareLink?.code;
}

window.clearShareLinkCode = () => {
    try {
        const obj = JSON.parse(localStorage.getItem('app-reducer'))
        obj.shareLink = null
        localStorage.setItem('app-reducer', JSON.stringify(obj))
    } catch(e) {
        console.log('app-reducer key not set')
    }
}

axios.defaults.baseURL = window.Config.apiHost

// Use interceptor to inject the token to requests
axios.interceptors.request.use(request => {
    if(request.url?.match(/vault|localhost|^\//)) {
        const token = getAccessToken();
        if(token) request.headers['Authorization'] = `Bearer ${token}`;

        const orgId = getOrgId()
        if(orgId) request.headers['OrganizationId'] = orgId;

        const shareLinkCode = getShareLinkCode()
        if(shareLinkCode) request.headers['ShareLinkCode'] = shareLinkCode;
    }
    return request;
});

window.api = axios;

export default axios;

window.testInvalidToken = ()=> {
    const data = JSON.parse(localStorage.getItem('app-reducer'))
    data.jwt = 'bad jwt'
    localStorage.setItem('app-reducer', JSON.stringify(data))
}