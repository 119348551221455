import {SettingOutlined} from "@ant-design/icons";
import {Button, message, Modal, Popconfirm, Space, Tag, Tooltip} from "antd";
import React, {useContext, useEffect, useState} from "react";
import api from "../api";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {EditableTag} from "./TagSelect";
import * as Yup from "yup";
import {Formik} from "formik";
import {FormItem, Select, Radio} from "formik-antd";
import pluralize from 'pluralize'
import {GreySettings, SynonymIcon} from "../helpers/icons";
import HelpPopover from "../HelpPopover";
import {AbilityContext} from "../helpers/Can";
import {useTranslation} from "react-i18next";

export default ({tag, onUpdate})=>{
    const {t} = useTranslation();
    const [leadTag, setLeadTag] = useState(tag.lead_tag)

    useEffect(()=>{
        setLeadTag(tag.lead_tag)
    }, [tag.lead_tag?.id])

    const [modalVisible, setModalVisible] = useState()

    const [loading, setLoading] = useState()

    const [tags, setTags] = useState([])

    const options = tags.map(t => <Select.Option key={t.name}>{t.name}</Select.Option>);

    const onSearch = val => {
        setLoading(true)

        api(`/api/tags?q=${val}&synonym[]=not_synonym&list_not=blocked`).then(res => {
            setLoading(false)
            setTags(res.data)
        })
    }

    const assetsDispatch = useAssetsDispatch();

    const onClickList = (e)=>{
        e.stopPropagation()
    }

    const ability = useContext(AbilityContext);

    const mode = (tag.is_lead_tag && 'lead') || (leadTag && 'child') || 'none'

    return (<>
        {ability.can('manage','Tag') && (
            <Tooltip title={t('tooltip-manage-synonym-settings','Manage Synonym Settings')}>
                <Button size={'small'} type={'text'} icon={<GreySettings/>} onClick={()=> setModalVisible(true)} style={{}}/>
            </Tooltip>
        )}
        {leadTag && (
            <>
                <EditableTag bulk tag={leadTag} onUpdate={onUpdate}/>
            </>
        )}
        {tag.is_lead_tag && (
            <Tooltip title={tag.synonym_names?.join(', ')}>
                <Tag color={'blue'}>{tag.synonym_names?.length} {t(`synonym${tag.synonym_names?.length ? 's' : ''}`, `Synonym${tag.synonym_names?.length ? 's' : ''}`)}</Tag>
            </Tooltip>
        )}
        <Formik
            initialValues={{lead_tag_name: leadTag?.name, new_synonym_names: tag.synonym_names, mode}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                if(!values.lead_tag_name || values.mode == 'lead') values.lead_tag_name = false

                if(values.mode == 'none') {
                    values.new_synonym_names = []
                    values.lead_tag_name = false
                }

                const data = {tag: values}

                api.put(`/api/tags/${tag.id}`, data).then(res => {
                    actions.setSubmitting(false);

                    if(res.data.errors) {
                        return message.error('Error: ' + JSON.stringify(res.data.errors))
                    }

                    if(res.data.lead_tag) setLeadTag(res.data.lead_tag)

                    actions.resetForm();

                    setModalVisible(false);
                    message.success(t('message-synonym-settings-updated','Synonym Settings Updated.'))

                    onUpdate && onUpdate(res.data)
                    assetsDispatch({type:'updateTag', tag: res.data})

                }).catch((err)=>{
                    message.error(JSON.stringify(err))
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit, resetForm}) => {

                const onCancel = () => {
                    setModalVisible(false)
                    resetForm()
                }

                return (
                    (<Modal
                        open={modalVisible}
                        title={<><SynonymIcon/> {t('synonym-settings-for','Synonym Settings For')}: <Tag>{tag.name}</Tag></>}
                        zIndex={9999}
                        onCancel={onCancel}
                        onClick={onClickList}
                        footer={
                            <>
                                <Popconfirm
                                    onConfirm={submitForm}
                                    title={<>{t('confirm-update-synonym-settings','Update Synonym Settings?')}</>}
                                    zIndex={9999}
                                >
                                    <Button type={'primary'}>{t('button-update','Update')}</Button>
                                </Popconfirm>
                            </>
                        }
                    >
                        <Radio.Group name={'mode'}>
                            <Space direction={'vertical'}>
                                <Radio value={'none'}>{t('no-synonyms','No synonyms')}</Radio>
                                <Radio value={'lead'}>{t('set-as-primary-synonym','Set as primary synonym')}</Radio>
                                <Radio value={'child'}>{t('set-as-secondary-synonym','Set as secondary synonym')}</Radio>
                            </Space>
                        </Radio.Group>
                        {values.mode == 'child' && (
                            <FormItem
                                name={'lead_tag_name'}
                                extra={<em>{t('add-this-term-as-a-synonym','Add this term as a synonym to another term.')} <HelpPopover code={'select_lead_tag'}/></em>}
                                style={{marginTop:'1em'}}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    loading={loading}
                                    placeholder={t('placeholder-set-primary-tag','Set Primary Tag...')}
                                    filterOption={false}
                                    onSearch={onSearch}
                                    notFoundContent={null}
                                    name={'lead_tag_name'}
                                    autoFocus
                                    allowClear
                                    onClick={onClickList}
                                >
                                    {options}
                                </Select>
                            </FormItem>
                        )}
                        {values.mode == 'lead' && (
                            <FormItem
                                name={'new_synonym_names'}
                                extra={<em>{t('add-synonyms-to-this-term','Add synonyms to this term. These terms will be removed from the Taxonomy if present.')} <HelpPopover code={'set_synonym_names'}/></em>}
                                style={{marginTop:'1em'}}
                            >
                                <Select
                                    name={'new_synonym_names'}
                                    mode={'tags'}
                                    style={{width: '100%'}}
                                    showSearch
                                    loading={loading}
                                    placeholder={t('placeholder-add-synonyms','Add Synonyms...')}
                                    filterOption={false}
                                    onSearch={onSearch}
                                    notFoundContent={null}
                                    autoFocus
                                    onClick={onClickList}
                                >
                                    {options}
                                </Select>
                            </FormItem>
                        )}
                    </Modal>)
                );
            }}
        </Formik>
    </>);
}