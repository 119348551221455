import React, {useContext, useEffect, useRef, useState} from "react";
import {Steps} from "intro.js-react";
import {useSelectedAssetsDispatch} from "../../contexts/SelectedAssetsContext";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import useShowAsset from "../helpers/useShowAsset";
import {AbilityContext} from "../helpers/Can";
import {useViewMode} from "../explore/ViewMenu";

import _ from 'lodash'
import {useTranslation} from "react-i18next";

export default ({autoPlay})=>{
    const {t} = useTranslation();
    const ability = useContext(AbilityContext);

    const [touring, setTouring] = useState(autoPlay)

    const [tourType, setTourType] = useState(autoPlay)

    const {changeViewMode} = useViewMode()

    useEffect(()=>{
        if(location.hash == '#admin-tour') {
            startTour('admin')
            location.hash = ''

        } else if(location.hash == '#general-tour') {
            startTour('general')
            location.hash = ''

        } else if(location.hash == '#tour') {
            startTour(ability.can('manage', 'Asset') ? 'admin' : 'general')
            location.hash = ''
        }
    }, [location.hash])

    const startTour = type => {
        setTourType(type)

        changeViewMode('grid')

        // Ensure left panel is expanded:
        if(!document.getElementById('storage-folders-panel')) {
            document.getElementById('left-sidebar-btn')?.click()
            setTimeout(()=>{
                setTouring(true)
            },  500)
        } else {
            setTouring(true)
        }
    }

    const onExit = ()=>{
        setTouring(false)
    }

    const refresh = ()=>{
        stepsRef.current.introJs.refresh()
    }

    const clickSidePanel = (id, force)=>{
        if(!force && document.querySelectorAll(`#${id} .ant-collapse-content-active`).length) return

        const el = document.getElementById(id)
        el?.children[0]?.click()

        setTimeout(()=>{
            el?.scrollIntoView({block:'end'})
            refresh()
        }, 600)
    }

    const vimeo = id => {
        return (
            <div style={{height:400, width:600}}>
                <iframe
                    src={`https://player.vimeo.com/video/${id}?title=0&byline=0`}
                    width="600"
                    height="400"
                    frameBorder="0"
                    allowFullScreen='true'
                />
            </div>
        )
    }

    const selectedAssetDispatch = useSelectedAssetsDispatch();
    const {assetIds} = useLoadedAssetsState()

    const showAsset = useShowAsset()

    //------------------------------------------------------------------------------------------------------------------
    // Steps
    //------------------------------------------------------------------------------------------------------------------

    const content = {
        'intro': {
            title: t('tour-welcome','Welcome to Mediagraph Quick Start'),
            intro: (
                <ul>
                    <li>{t('tour-welcome-1','Here’s a quick tour to help you get started.')}</li>
                    <li>{t('tour-welcome-2','You can relaunch this tour anytime from the Help menu')}</li>
                    <li>{t('tour-welcome-3','This tour is made for Administrators and Content Managers')}</li>
                    <li>{t('tour-welcome-4','There is a separate tour for your general account members')}</li>
                </ul>
            )
        },
        'hints': {
            position:'bottom',
            title: t('tour-hints','Helpful hints'),
            element: '#libraries-help-popover',
            intro: (
                <>
                    <ul>
                        <li>{t('tour-hints-1','Wherever you see the help icon, you can find more info about the nearby item')}</li>
                        <li>{t('tour-hints-2','Also make note of the refresh button on all the individual panels. This is sometimes needed to force an update after a bulk action.')}</li>
                    </ul>
                    {vimeo(590028689)}
                </>
            ),
        },
        'viewing-files': {
            position:'bottom',
            title: t('tour-viewing-files','Viewing files'),
            element: '#view-all-btn',
            intro: (
                <>
                    <ul>
                        <li>{t('tour-viewing-files-1','You can view all files by hitting View all')}</li>
                        <li>{t('tour-viewing-files-2','The files you see will be based on your role and/or group membership')}</li>
                        <li>{t('tour-viewing-files-3','Administrators and Content Managers can see every file in the account')}</li>
                        <li>{t('tour-viewing-files-4','Library Managers and Members will see files based on their group membership')}</li>
                        <li>{t('tour-viewing-files-5','Clicking on folders, collections, or projects will only show the files in those containers and sub-containers ')}</li>
                        <li>{t('tour-viewing-files-6','You can filter files by metadata or file characteristic')}</li>
                        <li>{t('tour-viewing-files-7','You can always click back on View all to see all files')}</li>
                    </ul>
                    {vimeo(590050289)}
                </>
            )
        },
        'uploading': {
            position:'bottom',
            element: '#storage-folders-panel',
            title: t('tour-folders','Folders'),
            onChange: function(i) {
                clickSidePanel('storage-folders-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-folders-1','Make your first uploads directly in the Storage Folders panel')}</li>
                        <li>{t('tour-folders-2','Click the “Upload here” folder and drag items into the window')}</li>
                        <li>{t('tour-folders-3','You can upload individual files, or whole folder trees')}</li>
                        <li>{t('tour-folders-4','You can also connect a Box account')}</li>
                        <li>{t('tour-folders-5','Storage Folders are only visible to Admins and Content Managers')}</li>
                        <li>{t('tour-folders-6','There are other methods for uploading, but these are mostly for regular members')}</li>
                        <li>{t('tour-folders-7','Every file in your account lives in a folder')}</li>
                    </ul>
                    {vimeo(591159871)}
                </>
            ),
        },
        'upload-btn': {
            position:'left',
            element: '#app-header-upload-btn',
            title: t('tour-upload-button','Upload Button'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-upload-button-1','If a user has upload privileges, they will see a blue upload button in the topnav')}</li>
                        <li>{t('tour-upload-button-2','All available “Upload pipelines” will be shown here.')}</li>
                        <li>{t('tour-upload-button-3','In addition to folders, these could be group, lightbox, or general upload requests')}</li>
                        <li>{t('tour-upload-button-4','Go to Manage > Uploads to find out more about these methods')}</li>
                    </ul>
                    {vimeo(591195437)}
                </>
            ),
        },
        'libraries': {
            position:'bottom',
            element: '#collections-panel',
            title: t('tour-libraries','Libraries'),
            onChange: function(i) {
                clickSidePanel('collections-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-libraries-1','Use the Libraries Panel to make files available to your members')}</li>
                        <li>{t('tour-libraries-2','You can make collection trees to organize files')}</li>
                        <li>{t('tour-libraries-3','Add group permissions to allow viewing or download access')}</li>
                        <li>{t('tour-libraries-4','Add a collection to an existing tree and it can inherit the permissions')}</li>
                        <li>{t('tour-libraries-5','Set Table of contents view to add poster image and intro copy')}</li>
                        <li>{t('tour-libraries-6','Set as Featured content to show on front page')}</li>
                        <li>{t('tour-libraries-7','Each User Group can have their own library')}</li>
                    </ul>
                    {vimeo(591288567)}
                </>
            ),
        },
        'projects': {
            position:'bottom',
            element: '#my-projects-panel',
            title: t('tour-projects-and-lightboxes','Projects and Lightboxes'),
            onChange: function(i) {
                clickSidePanel('my-projects-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-projects-and-lightboxes-1','Every member of the account has their own personal Project space')}</li>
                        <li>{t('tour-projects-and-lightboxes-2','The person who makes the Lightbox is the “owner” and manager')}</li>
                        <li>{t('tour-projects-and-lightboxes-3','They can use Lightboxes to collect files for any reason')}</li>
                        <li>{t('tour-projects-and-lightboxes-4','They can invite others to Lightboxes to collaborate')}</li>
                        <li>
                            <ul>
                                <li>{t('tour-projects-and-lightboxes-4a','Invite a user')}</li>
                                <li>{t('tour-projects-and-lightboxes-4b','Show how comments work')}</li>
                                <li>{t('tour-projects-and-lightboxes-4c','Show markup')}</li>
                            </ul>
                        </li>
                        <li>{t('tour-projects-and-lightboxes-5','Conferring permissions')}</li>
                        <li>{t('tour-projects-and-lightboxes-6','Projects allow you to')}</li>
                        <li>{t('tour-projects-and-lightboxes-7','They can upload to Lightboxes if you give them permission')}</li>
                        <li>{t('tour-projects-and-lightboxes-8','Lightboxes can even be shared by a link')}</li>
                    </ul>
                    {vimeo(592381815)}
                </>
            ),
        },
        'workspace': {
            position:'floating',
            element: '#main',
            title: t('tour-workspace','Workspace'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-workspaces-1','Files are displayed in the Workspace')}</li>
                        <li>{t('tour-workspaces-2','The breadcrumb at the top shows you what’s selected')}</li>
                        <li>{t('tour-workspaces-3','You can see the number of files and a breakdown of file types and sizes')}</li>
                        <li>{t('tour-workspaces-4','If you would like more space to the sides, click the double arrows')}</li>
                        <li>{t('tour-workspaces-5','Mouse-over thumbnails to see information and access level')}</li>
                    </ul>
                    {vimeo(592224241)}
                </>
            ),
        },
        'view-settings': {
            position:'bottom',
            element: '#view-settings-popover',
            title: t('tour-view-options','View Options'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-view-options-1','The workspace can be set to a Grid, Table or Map view')}</li>
                        <li>{t('tour-view-options-2','The Thumbnail in grid has 5 different sizes')}</li>
                        <li>
                            {t('tour-view-options-3','Table view is good for viewing descriptions and tags, and adding metadata in bulk')}
                            <ul>
                                <li>{t('tour-view-options-3a','Select the files with the circle')}</li>
                                <li>{t('tour-view-options-3b','Select all')}</li>
                            </ul>
                        </li>
                        <li>{t('tour-view-options-4','Map view shows files from the workspace that have GPS coordinates embedded')}</li>
                        <li>{t('tour-view-options-5','You can collapse the left hand panel using the arrows')}</li>
                    </ul>
                    {vimeo(592445596)}
                </>
            ),
            onBeforeChange: i => {
                document.getElementById('view-menu-btn')?.click()

                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 10 )
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'sorting': {
            position:'bottom',
            element: '#sort-order-popover',
            title: t('tour-sorting-files','Sorting files'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-sorting-files-1','The default view for View All shows the most recently added files at the top')}</li>
                        <li>{t('tour-sorting-files-2','Any user can do an on-the-fly sort of files in the workspace')}</li>
                        <li>{t('tour-sorting-files-3','Collections default sort order is controlled by Library or Content Managers')}</li>
                        <li>{t('tour-sorting-files-4','Lightboxes default sort order is controlled by the Lightbox owner.')}</li>
                    </ul>
                    {vimeo(592497558)}
                </>
            ),
            onBeforeChange: i => {
                document.getElementById('sort-order-btn')?.click()

                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 10 )
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'file-selection': {
            position:'bottom',
            element: '#select-all-btn',
            title: t('tour-selecting-files','Selecting files'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-selecting-files-1','You can select files one at a time and the selection indicator will show a count')}</li>
                        <li>{t('tour-selecting-files-2','Click the selection indicator to toggle between select all and select none')}</li>
                        <li>{t('tour-selecting-files-3','You can also shift-select all files between a first file and a last file')}</li>
                        <li>
                            <ul>
                                <li>{t('tour-selecting-files-3a','You can also use shift-select un-contiguously')}</li>
                            </ul>
                        </li>
                        <li>{t('tour-selecting-files-4','You can drag and drop files into Folders, Collections, Lightboxes and onto tags')}</li>
                        <li>{t('tour-selecting-files-5',' Hold Option/alt to keep files selected after drag and drop, Or use the action menu, as shown in the next video')}</li>
                    </ul>
                    {vimeo(592793560)}
                </>
            ),
            onBeforeChange: i => {
                setTimeout(() => {
                    stepsRef.current.updateStepElement(i)
                }, 100)
            },
            onChange: function(i) {
                selectedAssetDispatch({type:'selectNone'})
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'action-menu': {
            position:'left',
            element: '#bulk-action-popover',
            title: t('tour-action-menu','Action menu'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-action-menu-1','Once you have at least one file selected, the download icon and action menu appear')}</li>
                        <li>{t('tour-action-menu-2','The action menu operates on all selected files')}</li>
                        <li>{t('tour-action-menu-3','Delete files, add metadata, add to collections or lightboxes here.')}</li>
                        <li>{t('tour-action-menu-4','Your ability to do these things is governed by your role in the site (e.g. only Content Managers can delete files) ')}</li>
                    </ul>
                    {vimeo(599067857)}
                </>
            ),
            onBeforeChange: function(i) {
                // Select First 2 Assets:
                selectedAssetDispatch({type:'setSelection', ids: assetIds.slice(0,2)})

                setTimeout(()=>{
                    document.getElementById('bulk-action-btn')?.click()

                    setTimeout(()=>{
                        stepsRef.current.updateStepElement(i)
                    }, 100)
                }, 10)
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 200 )
            },
        },
        'asset-modal': {
            position:'floating',
            element: '.ant-modal-content',
            title: t('tour-asset-view','Asset view'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-asset-view-1','Tap the space bar or double-click a thumbnail to launch the Asset view')}</li>
                        <li>{t('tour-asset-view-2','This shows a larger version of the file and lots of metadata')}</li>
                        <li>{t('tour-asset-view-3','Change the layout  from vertical to horizontal ')}</li>
                        <li>{t('tour-asset-view-4','Tap the right or left arrow to go to the next/previous file')}</li>
                        <li>{t('tour-asset-view-5','Click Fullscreen or tap f to go to a full image view with black background')}</li>
                        <li>{t('tour-asset-view-6','Use number keys to assign ratings, tap i key to cycle info overlay')}</li>
                        <li>{t('tour-asset-view-7','Click on asset or fullscreen to go into enlarge mode')}</li>
                        <li>{t('tour-asset-view-8','Use the upper right X to go back to previous view')}</li>
                        <li>{t('tour-asset-view-9','Tap g to go back to grid view')}</li>
                    </ul>
                    {vimeo(592930232)}
                </>
            ),
            onBeforeChange: i => {
                showAsset(assetIds[0])

                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 1000 )
            },
        },
        'tag-tree': {
            position:'bottom',
            element: '#taxonomy-panel',
            title: t('tour-tag-tree','Tag tree'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-tag-tree-1','The tag tree can be used to organize keywords, people and event tags')}</li>
                        <li>{t('tour-tag-tree-2','You can create a hierarchy to make it navigable')}</li>
                        <li>{t('tour-tag-tree-3','You can add tags to files by drag and drop')}</li>
                        <li>{t('tour-tag-tree-4','Click on the right side to see the files')}</li>
                        <li>{t('tour-tag-tree-5','Add definitions or links to your keywords and other tags')}</li>
                        <li>{t('tour-tag-tree-6','Filter the workspace for “has tag” of “does not have tag”, or “show every file with this tag).')}</li>
                        <li>{t('tour-tag-tree-7','Filter for Union or Intersection')}</li>
                    </ul>
                    {vimeo(594726590)}

                    <strong>{t('tour-tag-tree-8','Building a taxonomy')}</strong>

                    <ul>
                        <li>{t('tour-tag-tree-9','Keywords have 4 levels of visibility - the top level is “in taxonomy”')}</li>
                        <li>{t('tour-tag-tree-10','Keywords made by admins are visible by default, those which come in as embedded keywords are searchable ')}</li>
                        <li>{t('tour-tag-tree-11','You can build a tree in the panel, or create from existing keywords or import a full taxonomy from a text document ')}</li>
                        <li>{t('tour-tag-tree-12','You can add files to the tags by drag and drop')}</li>
                        <li>{t('tour-tag-tree-13','Use the has/does not have keyword filter to verify tagging accuracy and completeness')}</li>
                    </ul>
                    {vimeo(595039843)}
                </>
            ),
            onBeforeChange: i => {
                showAsset(null)
            },
            onChange: function(i) {
                clickSidePanel('taxonomy-panel')
            },
        },
        'help-menu': {
            position:'left',
            element: '#help-menu-btn',
            title: t('tour-help','Help'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-help-1','Restart this Product Tour from the Help Menu')}</li>
                    </ul>
                </>
            ),
            onBeforeChange: i => {
                document.querySelectorAll('#help-menu-btn .ant-menu-submenu-title')[0]?.click()

                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 50 )
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 100 )
            },
        },
        'filter': {
            position:'bottom',
            element: '#explore-filters-panel',
            title: t('tour-filter','Filter'),
            onChange: function(i) {
                clickSidePanel('explore-filters-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-filter-1','The filter panel serves two purposes')}</li>
                        <li>{t('tour-filter-2','Show you information about files in the workspace')}</li>
                        <li>{t('tour-filter-3','Filter down to those files with a click')}</li>
                        <li>{t('tour-filter-4','The Events panel also allows you to create an event')}</li>
                        <li>{t('tour-filter-5','The rights panel also allows you to assign rights by drag and drop')}</li>
                        <li>{t('tour-filter-6','When filters are applied you can click again to remove')}</li>
                        <li>{t('tour-filter-7','Or go to the Filter indicator at the top of the workspace to remove')}</li>
                    </ul>
                    {vimeo(595489357)}
                </>
            ),
        },
    }

    const adminSteps =
        _.compact([
            'intro',
            'hints', 'viewing-files',
            ability.can('show','StorageFolder') ? 'uploading' : null,
            'upload-btn', 'libraries','projects', 'workspace', 'view-settings', 'sorting', 'file-selection',
            'action-menu', 'asset-modal', 'tag-tree', 'filter', 'help-menu'
        ]).map(c => {return {position: 'auto', ...content[c]}})

    //------------------------------------------------------------------------------------------------------------------
    // General User Tour
    //------------------------------------------------------------------------------------------------------------------

    const generalContent = {
        'intro': {
            title: t('tour-general','Welcome to Media Graph Quick Start'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-1','Here’s a quick tour to help you get started.')}</li>
                        <li>{t('tour-general-2','You can return to this tour in the help menu')}</li>
                        <li>{t('tour-general-3','Click on the ? to learn more about a particular item')}</li>
                    </ul>

                    {vimeo(597360937)}
                </>
            )
        },
        'profile': {
            position:'left',
            element: '#user-nav-menu',
            title: t('tour-complete-profile','Complete your profile'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-complete-profile-1','Add an avatar so that people know it’s you')}</li>
                        <li>{t('tour-complete-profile-2','Choose a username for collaboration in Lightbox comments (must be unique)')}</li>
                    </ul>
                    {vimeo(597361713)}
                </>
            ),
        },
        'viewing-files': {
            position:'bottom',
            title: t('tour-general-viewing-files','Viewing files'),
            element: '#view-all-btn',
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-viewing-files-1','Click on View All to see all files you have access to')}</li>
                        <li>{t('tour-general-viewing-files-2','Use the Quick Filter to find by file type')}</li>
                    </ul>
                    {vimeo(597362373)}
                </>
            )
        },
        'view-settings': {
            position: 'bottom',
            element: '#view-settings-popover',
            title: t('tour-general-view-options','View Options'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-view-options-1','Choose between grid, table and map view')}</li>
                        <li>{t('tour-general-view-options-2','These can be activated with hotkeys as shown')}</li>
                        <li>{t('tour-general-view-options-3','In the grid view, you can choose different sizes')}</li>
                        <li>{t('tour-general-view-options-4','In table view, you can see more metadata')}</li>
                        <li>{t('tour-general-view-options-5','You can close the left hand panel to get more space')}</li>
                        <li>{t('tour-general-view-options-6','Map view will show files that have GPS coordinates')}</li>
                        <li>{t('tour-general-view-options-7','You can search google to find files')}</li>
                    </ul>
                    {vimeo(596585479)}
                </>
            ),
            onBeforeChange: i => {
                document.getElementById('view-menu-btn')?.click()

                setTimeout(() => {
                    stepsRef.current.updateStepElement(i)
                }, 10)
            },
            onChange: function (i) {
                setTimeout(() => {
                    refresh()
                }, 250)
            },
        },
        'asset': {
            position:'bottom',
            element: `#asset-${assetIds[0]}`,
            title: t('tour-asset-double-click','Double-click a file to see more'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-asset-double-click-1','Larger size')}</li>
                        <li>{t('tour-asset-double-click-2','More metadata')}</li>
                        <li>{t('tour-asset-double-click-3','Full screen view')}</li>
                        <li>{t('tour-asset-double-click-4','Use arrows to move through files')}</li>
                        <li>{t('tour-asset-double-click-5','Play video and audio')}</li>
                        <li>{t('tour-asset-double-click-6','Crop, resize or modify files for download')}</li>
                    </ul>
                    {vimeo(596863212)}
                </>
            ),
            onBeforeChange: i => {
                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 10 )
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'sorting': {
            position:'bottom',
            element: '#sort-order-popover',
            title: t('tour-general-sorting-files','Sorting files'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-sorting-files-1','You can resort files on-the-fly')}</li>
                        <li>{t('tour-general-sorting-files-2','Sort by upload date, creation date, alphabetical, size or rating')}</li>
                        <li>{t('tour-general-sorting-files-3','Use this to help you find what you are looking for')}</li>
                        <li>{t('tour-general-sorting-files-4','You can set a default sort order for your own lightboxes')}</li>
                    </ul>
                    {vimeo(597363617)}
                </>
            ),
            onBeforeChange: i => {
                document.getElementById('sort-order-btn')?.click()

                setTimeout(()=>{ stepsRef.current.updateStepElement(i) }, 10 )
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'file-selection': {
            position:'bottom',
            element: '#select-all-btn',
            title: t('tour-select-all-none','Selecting all/none'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-select-all-none-1','The selection indicator tells you how many files are in the workspace')}</li>
                        <li>{t('tour-select-all-none-2','Click to select all')}</li>
                        <li>{t('tour-select-all-none-3','Click again to deselect all')}</li>
                        <li>{t('tour-select-all-none-4','Select files by clicking, shift-clicking, or multiple shift-click')}</li>
                    </ul>
                    {vimeo(597364635)}
                </>
            ),
            onBeforeChange: i => {
                setTimeout(() => {
                    stepsRef.current.updateStepElement(i)
                }, 100)
            },
            onChange: function(i) {
                selectedAssetDispatch({type:'selectNone'})
                setTimeout(()=>{ refresh() }, 250 )
            },
        },
        'bulk-download': {
            position:'bottom',
            element: '#bulk-download-btn',
            title: t('tour-general-downloading-files','Downloading files'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-downloading-files-1','When you have a file selected, the download button appears if you have permission')}</li>
                        <li>{t('tour-general-downloading-files-2','Choose the maximum size you want to download')}</li>
                        <li>{t('tour-general-downloading-files-3','Some files may be restricted to smaller downloads, watermarks, or not allowed')}</li>
                        <li>{t('tour-general-downloading-files-4','Mouse over a file to see restrictions')}</li>
                        <li>{t('tour-general-downloading-files-5','These restrictions are set by collections and group membership')}</li>
                    </ul>
                    {vimeo(596643364)}
                </>
            ),
            onBeforeChange: function(i) {
                // Select First 2 Assets:
                selectedAssetDispatch({type:'setSelection', ids: assetIds.slice(0,2)})

                setTimeout(()=>{
                    // document.getElementById('bulk-download-btn').click()

                    setTimeout(()=>{
                        stepsRef.current.updateStepElement(i)
                    }, 100)
                }, 10)
            },
            onChange: function(i) {
                setTimeout(()=>{ refresh() }, 200 )
            },
        },
        'libraries': {
            position:'bottom',
            element: '#collections-panel',
            title: t('tour-genearl-Libraries','Libraries'),
            onChange: function(i) {
                clickSidePanel('collections-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-libraries-','Check out the libraries to see how the files are organized')}</li>
                        <li>{t('tour-general-libraries-','The Library collections must be created by the site administrators')}</li>
                        <li>{t('tour-general-libraries-','Click on a collection to see all files in the collection tree')}</li>
                        <li>{t('tour-general-libraries-','Your permission is controlled by your group membership')}</li>
                    </ul>

                    {vimeo(597365987)}
                </>
            ),
        },
        'projects': {
            position:'bottom',
            element: '#my-projects-panel',
            title: t('tour-genearl-my-projects','My Projects'),
            onChange: function(i) {
                clickSidePanel('my-projects-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-my-projects-1','You have your own personal project space')}</li>
                        <li>{t('tour-general-my-projects-2','Save files in Lightboxes for easy access')}</li>
                        <li>{t('tour-general-my-projects-3','Invite others to Lightboxes, use the comment panel to collaborate')}</li>
                        <li>{t('tour-general-my-projects-4','Share files with other people')}</li>
                        <li>{t('tour-general-my-projects-5','Add Table of contents settings for context')}</li>
                        <li>{t('tour-general-my-projects-6','Use Projects to manage multiple lightboxes for more complex workflows')}</li>
                        <li>{t('tour-general-my-projects-7','Use Organizers to reduce clutter')}</li>
                        <li>{t('tour-general-my-projects-8','Upload files (if your member account has permission)')}</li>
                    </ul>

                    {vimeo(597226888)}
                </>
            ),
        },
        'tag-tree': {
            position:'bottom',
            element: '#taxonomy-panel',
            title: t('tour-general-tag-tree','Tag tree'),
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-tag-tree-1','You can search and browse the tag tree, if your account has set it up')}</li>
                        <li>{t('tour-general-tag-tree-2','A blue tint on a tag’s count, means the workspace includes something from this group')}</li>
                        <li>{t('tour-general-tag-tree-3','A checkmark on a tag’s count means a selected item is in this group')}</li>
                        <li>{t('tour-general-tag-tree-4','Filter shown files for “has tag” of “does not have tag”, or “show every file with this tag.” ')}</li>
                    </ul>

                    {vimeo(597214670)}
                </>
            ),
            onBeforeChange: i => {
                showAsset(null)
            },
            onChange: function(i) {
                clickSidePanel('taxonomy-panel')
            },
        },
        'filter': {
            position:'right',
            element: '#explore-filters-panel',
            title: t('tour-general-filter','Use the filter panel to see other metadata'),
            onChange: function(i) {
                clickSidePanel('explore-filters-panel')
            },
            intro: (
                <>
                    <ul>
                        <li>{t('tour-general-filter-1','The panels show you information about items in the workspace')}</li>
                        <li>{t('tour-general-filter-2','Click a tag to show those items')}</li>
                        <li>{t('tour-general-filter-3','Use Rights to find files where rights have been cleared')}</li>
                    </ul>

                    {vimeo(596846315)}
                </>
            ),
        },
    }

    const generalSteps =
        _.compact([
            'intro', 'profile', 'viewing-files', 'view-settings', 'asset', 'sorting', 'file-selection', 'bulk-download',
            'libraries', 'projects', 'tag-tree', 'filter'
        ]).map(c => {return {position: 'auto', ...generalContent[c]}})
    //------------------------------------------------------------------------------------------------------------------

    const steps = tourType == 'admin' ? adminSteps : generalSteps

    const onChange = (i, el)=>{
        steps[i].onChange && steps[i].onChange(i)
    }

    const onBeforeChange = i => {
        document.getElementsByClassName('ant-modal-wrap')[0]?.click()

        steps[i].onBeforeChange && steps[i].onBeforeChange(i)
    }

    const stepsRef = useRef()
    window.steps = stepsRef.current

    const initialStep = 0

    return (
        <>
            <Steps
                onChange={onChange}
                onBeforeChange={onBeforeChange}
                enabled={touring}
                steps={steps}
                ref={stepsRef}
                initialStep={initialStep}
                onExit={onExit}
                options={{
                    showStepNumbers: true,
                    keyboardNavigation: true,
                    positionPrecedence: ['auto','right','top','bottom','left'],
                }}
            />
        </>
    )
}