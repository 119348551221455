import {Badge, Button, Collapse, Divider, message, Modal, Skeleton, Slider, Space, Tag, Tooltip, Typography} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import ExploreAssetGroups from "./ExploreAssetGroups";
import ExploreFilters from "./ExploreFilters";
import DoubleLeftOutlined from "@ant-design/icons/lib/icons/DoubleLeftOutlined";
import DoubleRightOutlined from "@ant-design/icons/lib/icons/DoubleRightOutlined";

import {
    BrowserView,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";
import VerticalSpace from "@/components/helpers/VerticalSpace";

export default ({collapsed, toggleSidebar})=>{
    const {t} = useTranslation();
    const toggleStyle = collapsed ? {position: 'fixed', left: 0, top: 0} : {position: 'absolute', right: 0, top: 0}
    const headerHeight = document.getElementById('header')?.clientHeight

    return (
        <nav aria-label={t('explore-left-label','Asset Groups and Filters Navigation')} style={{backgroundColor: collapsed ? 'rgb(250,250,250)' : 'white', height:'100%'}}>
            {!collapsed && (
                <BrowserView>
                    <div style={toggleStyle}>
                        <Tooltip
                            trigger={['hover', 'click']}
                            placement={'right'}
                            title={<>{collapsed ? 'Show' : 'Hide'} Sidebar <Typography.Text keyboard style={{backgroundColor:'grey'}}>[</Typography.Text></>}
                        >
                            <Button
                                size='small'
                                onClick={toggleSidebar}
                                id={'left-sidebar-btn'}
                                type={'text'}
                                style={{height: `calc(100vh - ${headerHeight}px)`, paddingTop:'auto', paddingBottom:'auto', paddingLeft:0, paddingRight:0}}
                                aria-label={t('toggle-left-hand-sidebar', 'Toggle Left-hand Sidebar')}
                            >
                                <Typography.Text type={'secondary'}>
                                    {collapsed ? (
                                        <>
                                            <DoubleRightOutlined />
                                        </>
                                    ) : (
                                        <DoubleLeftOutlined />
                                    )}
                                </Typography.Text>
                            </Button>
                        </Tooltip>
                    </div>
                </BrowserView>
            )}

            <Space
                direction='vertical'
                size='middle'
                style={{
                    padding: isMobile() ? 0 : '1em',
                    marginTop:0,
                    width: collapsed ? 40 : '100%',
                    paddingLeft: collapsed ? 5 : 'auto',
                    paddingTop: collapsed ? 9 : 'auto',
                }}
            >
                {collapsed && (
                    <VerticalSpace>
                        <Tooltip
                            trigger={['hover', 'click']}
                            placement={'right'}
                            title={<>{collapsed ? 'Show' : 'Hide'} Sidebar <Typography.Text keyboard style={{backgroundColor:'grey'}}>[</Typography.Text></>}
                        >
                            <Button
                                onClick={toggleSidebar}
                                id={'left-sidebar-btn'}
                                type={'text'}
                                shape={'circle'}
                                aria-label={t('expand-left-hand-sidebar', 'Expand Left-hand Sidebar')}
                                icon={<DoubleRightOutlined />}
                                className={'sidebar-btn'}
                            />
                        </Tooltip>
                    </VerticalSpace>
                )}

                <ExploreAssetGroups collapsed={collapsed}/>

                <Divider style={{margin:'.25em 0 0 6px'}}/>

                <ExploreFilters collapsed={collapsed}/>
            </Space>
        </nav>
    )
}
