// Not a real hook; does not need to be called inside a component

const key = 'afterLogin'

export default ()=> {
    const afterLogin = localStorage.getItem(key)
    return afterLogin
}

const clearAfterLogin = () => {
    localStorage.removeItem(key)
}

const setAfterLogin = path => {
    console.log('setAfterLogin:', path)
    localStorage.setItem(key, path)
}

export {setAfterLogin, clearAfterLogin}