import React, {useContext, useEffect, Suspense, useState} from "react";
import { createRoot } from "react-dom/client";

import { AppProvider, AppContext } from '../contexts/AppContext'
import {SessionContext, SessionProvider} from '../contexts/SessionContext'
import Root from '../routes/Index'
import ErrorView from "../components/ErrorView";
import {AbilityContext} from "../components/helpers/Can";
import {Ability} from "@casl/ability";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {NotificationsProvider} from "../contexts/NotificationsContext";
import {CommentsProvider} from "../contexts/CommentsContext";
import {ConfigProvider, message, notification} from "antd";
import api from "../components/api";
import axios from "axios";

import '../components/i18n';
import fr_FR from 'antd/es/locale/fr_FR'
import { fr as yupFr } from 'yup-locales';
import { setLocale as setYupLocale } from 'yup';

import _ from 'lodash';
import {LoadingOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
window._ = _;

window.n = x => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

window.formatError = err => {
    return Object.keys(err).map(k => `${k.toProperCase()} ${err[k]}.`).join(' ')
}

const getCurrentUser = ()=>{
    const {currentUser} = JSON.parse(localStorage.getItem('app-reducer') || '{}')
    return currentUser
}

import Appsignal from "@appsignal/javascript"
import { ErrorBoundary } from "@appsignal/react";

document.addEventListener("DOMContentLoaded", () => {
    const appsignal = new Appsignal({
        key: Config.appSignalKey
    })

    appsignal.addDecorator((span) => {
        const currentUser = getCurrentUser()
        const {currentOrg} = JSON.parse(sessionStorage.getItem('session-reducer') || '{}')
        return span.setTags({ organization_slug: currentOrg?.slug, organization_id: currentOrg?.id, user_id: currentUser?.id });
    });

    const root = createRoot(document.body.appendChild(document.createElement("div")));

    root.render(
        <ErrorBoundary
            instance={appsignal}
            fallback={(error) => <ErrorView error={error} />}
        >
            <AppProvider>
                <SessionProvider>
                    <NotificationsProvider>
                        <CommentsProvider>
                            <Suspense fallback={
                                // <div style={{margin:'2em'}}><LoadingOutlined/> Mediagraph is loading...</div>
                                <div style={{position:'fixed',  top:'50%', left:'50%', transform: 'translate(-50%, -50%)', textAlign: 'center'}}>
                                    <img src={ImageUrls.stackedLogo} style={{width:300, paddingBottom: 30}}/>
                                    <br/>
                                    <div style={{fontSize:20, color: '#aaa'}}>
                                        <LoadingOutlined /> Loading...
                                    </div>
                                </div>
                            }>
                                <AppWithAbilities/>
                            </Suspense>
                        </CommentsProvider>
                    </NotificationsProvider>
                </SessionProvider>
            </AppProvider>
        </ErrorBoundary>
    );
});

const AppWithAbilities = ()=> {
    const {i18n,t} = useTranslation();
    const {dispatch} = useContext(AppContext);

    const {state} = useContext(SessionContext);
    const currentUser = getCurrentUser()

    const ability = new Ability(state.abilities || {});

    window.abilities = state.abilities;

    const [loggingOut, setLoggingOut] = useState()

    // Refresh JWT on page load:
    useEffect(()=>{
        if(!currentUser) return;

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            console.log('API Error', error.response)

            if(error.response?.status === 422) {
                message.error(JSON.stringify(error.response.data))
            }

            const logout = ()=> {
                if(loggingOut) return;
                setLoggingOut(true)

                const match = window.location.pathname.match(/^\/(.+?)\//)
                const orgSlug = match && match[1]

                dispatch({type:'logout'})
                setTimeout(()=>{
                    window.location.hash = 'logged-out'
                    window.location.pathname = `/${orgSlug ? `${orgSlug}/` : ''}login`
                }, 1000)
            }

            if ([401, 403].indexOf(error.response?.status) !== -1) {
                if(error.response.status === 403 && error.response.data.error === 'stale_session') {
                    message.error(t('session-timeout-error', 'Your session has timed out due to inactivity, please sign in again'))
                    logout()
                }

                // check login status
                api('/api/whoami').then(res => {
                    console.log('whoami', res)
                }).catch(logout)
            }
            return Promise.reject(error);
        });

        api.post('/api/refresh').then(res => {
            console.log('refresh', res)
            dispatch({
                type:'refreshToken',
                jwt: res.data.token
            });
        })

    },[currentUser?.id])

    let locale;
    switch(i18n.language) {
        case 'fr':
            setYupLocale(yupFr)
            locale = fr_FR
    }

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    return (
        <ConfigProvider
            getPopupContainer={trigger =>  trigger?.className?.match(/ant-select-selector|ant-dropdown-open/) ? trigger.parentElement : document.body}
            locale={locale}
            theme={{
                cssVar: true,
                token: {
                    colorPrimary: '#1677ff',
                    fontFamily: 'Lato',
                    layoutHeaderPadding: 0,
                    bodyBackground: '#fff',
                    primaryColor: '#1890ff',
                    linkColor: '#1890ff',
                    successColor: '#52c41a',
                    warningColor: '#faad14',
                    errorColor: '#f5222d',
                    fontSizeBase: '15px',
                    headingColor: 'rgba(0, 0, 0, 0.85)',
                    textColor: 'rgba(0, 0, 0, 0.65)',
                    textColorSecondary: 'rgba(0, 0, 0, .45)',
                    disabledColor: 'rgba(0, 0, 0, .25)',
                    borderRadius: 10,
                    borderColorBase: '#d9d9d9',
                    boxShadowBase: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    zIndexMessage: 9999,

                    // colorSplit: 'rgba(5, 5, 5, 0.3)',
                    colorBorderSecondary: 'rgba(5, 5, 5, 0.1)',

                    colorPrimaryBorder: currentOrg?.header_highlight_color, // tab-index focus indicator
                },
                components: {
                    Badge: {
                        statusSize: 15,
                    }
                }
            }}
        >
            <AbilityContext.Provider value={ability}>
                <Root/>
            </AbilityContext.Provider>
        </ConfigProvider>
    )
}
