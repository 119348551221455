import React from "react";
import {Descriptions, Popover} from "antd";

import {TagOutlined} from "@ant-design/icons";

import Tag from "antd/lib/tag";
import {RightsIcon, StorageFolderIcon} from "../helpers/icons";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

export default ({contribution, placement, children})=>{
    const {t} = useTranslation();
    return (
        <Popover
            title={t('contribution-request-details','Contribution Request Details')}
            overlayStyle={{maxWidth:600}}
            placement={placement}
            content={
                <Descriptions bordered size={'small'} column={1}>
                    {contribution.user_group && (
                        <Descriptions.Item label={t('user-group','User Group')}>
                            {contribution.user_group.name}
                        </Descriptions.Item>
                    )}

                    {contribution.asset_group?.type == 'Lightbox' && (
                        <Descriptions.Item label={t('lightbox','Lightbox')}>
                            <OrgLink to={`/explore/projects/${contribution.asset_group.slug}`} onClick={e => e.stopPropagation()}>
                                {contribution.asset_group.name}
                            </OrgLink>
                        </Descriptions.Item>
                    )}

                    {contribution.asset_group?.type == 'StorageFolder' && (
                        <Descriptions.Item label={t('storage-folder','Storage Folder')}>
                            <StorageFolderIcon/> {contribution.storage_folder.path_names.join(' / ')}
                        </Descriptions.Item>
                    )}

                    {contribution.project && (
                        <Descriptions.Item label={t('project','Project')}>
                            <h4>{t('name','Name')}:</h4>
                            {contribution.project.name}
                            <br/>
                            {contribution.project.description && (
                                <>
                                    <br/>
                                    <h4>{t('description','Description')}</h4>
                                    {contribution.project.description}
                                </>
                            )}
                        </Descriptions.Item>
                    )}

                    {contribution.text && contribution.text != '' && (
                        <Descriptions.Item label={t('request','Request')}>
                            {contribution.text}
                        </Descriptions.Item>
                    )}
                    {!!contribution.tag_names.length && (
                        <Descriptions.Item label={t('auto-added-tags','Auto Added Tags')}>
                            {contribution.tag_names?.map(name => <Tag key={name}><TagOutlined/> {name}</Tag>)}
                        </Descriptions.Item>
                    )}

                    {contribution.tag_suggesters?.map(ts => (
                        <Descriptions.Item key={ts.id} label={t('tag-suggester','Tag Suggester')}>
                            <h5>{ts.name}{ts.required ? ` (${t('required', 'required')})` : ''}:</h5>
                            {ts.tag_names.map(name => <Tag key={name}><TagOutlined/> {name}</Tag>)}
                        </Descriptions.Item>
                    ))}

                    {/*{!!contribution.rights_packages.length && (*/}
                    {/*    <Descriptions.Item label={'Allowed Rights Packages'}>*/}
                    {/*        {contribution.rights_packages.map(rp => (*/}
                    {/*            <div key={rp.id}>*/}
                    {/*                <RightsIcon/> {rp.name}*/}
                    {/*                <h5>{rp.summary}</h5>*/}
                    {/*                {rp.contract_attachment && (*/}
                    {/*                    <>*/}
                    {/*                        Contract:*/}
                    {/*                        <br/>*/}
                    {/*                        <a href={rp.contract_attachment.url} download={rp.contract_attachment.filename}>*/}
                    {/*                            {rp.contract_attachment.filename}*/}
                    {/*                        </a>*/}
                    {/*                    </>*/}
                    {/*                )}*/}

                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </Descriptions.Item>*/}
                    {/*)}*/}
                </Descriptions>
            }
        >
            {children}
        </Popover>
    )

}