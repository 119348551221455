import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    AlertOutlined,
    ArrowRightOutlined, CheckCircleOutlined,
    DeleteOutlined, DownloadOutlined, LoadingOutlined, SettingOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import {MetaImportSetup} from "./MetaImports";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import useShowAsset from "../helpers/useShowAsset";
import useCurrentOrg from "../helpers/useCurrentOrg";
import BulkJobProgress from "../explore/BulkJobProgress";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/bulk_jobs'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-bulk-job-canceled.','Bulk Job Canceled.'));
            handleTableChange();
        });
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            render: date => <TimeAgo date={date}/>
        },
        // {
        //     title: 'Processed In',
        //     render: bj => <>{}</>
        // },
        {
            title: t('user','User'),
            render: (m) => {
                return <User user={m.user}/>
            }
        },
        {
            title: t('action','Action'),
            dataIndex: 'action',
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            // filters: [
            //     {text: 'Analyzed', value: 'analyzed'},
            //     {text: 'Processed', value: 'processed'},
            // ],
            // filterMultiple: true,
            render: (state, bj) => {
                let tag;
                if(state == 'processed') tag = <Tag color={'green'} icon={<CheckCircleOutlined/>}>{t('processed','Processed')}</Tag>
                else if(state == 'processing') tag = <Tag color={'blue'} icon={<LoadingOutlined/>}>{t('processing','Processing')}</Tag>
                else if(state == 'errored') tag = <Tag color={'red'} icon={<AlertOutlined/>}>{t('errored','Errored')}</Tag>
                else {
                    tag = <Tag>{t(state, state.toProperCase())}</Tag>
                }

                return <Tooltip title={<>ID: {bj.id}</>}>{tag}</Tooltip>;
            }
        },
        {
            title: t('Assets','Assets'),
            dataIndex: 'total_assets',
            sorter: true
        },
        {
            title: t('progress','Progress'),
            render: (bj) => {
                if(bj.canceled_at) return <></>

                return (
                    <>
                        {!bj.finished_processing_at ? (
                            <BulkJobProgress id={bj.guid} onFinish={handleTableChange} onError={handleTableChange} onCancel={loadTableData}/>
                        ) : (
                            <>100%</>
                        )}
                    </>
                )
            }
        },
        // {
        //     key: 'action',
        //     width: 100,
        //     render: (bj) => {
        //         return (
        //             <>
        //                 <Space size={5}>
        //                     <Can I={'edit'} a={'BulkJob'}>
        //                         <Space direction={'horizontal'}>
        //                             {(bj.aasm_state != 'processing' && bj.aasm_state != 'processed') && (
        //                                 <Popconfirm
        //                                     title={t('confirm-cancel-bulk-job','Cancel Bulk Job?')}
        //                                     onConfirm={() => destroy(bj.id)}
        //                                 >
        //                                     <Button size={'middle'}>
        //                                         <DeleteOutlined/>
        //                                     </Button>
        //                                 </Popconfirm>
        //                             )}
        //                         </Space>
        //                     </Can>
        //                 </Space>
        //             </>
        //         );
        //     }
        // }
    ];

    const params = useRef({})

    const handleTableChange = (pagination, filters, sorter) => {
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [q]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}