import React  from "react";
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import {useTranslation} from "react-i18next";

export default ({obj, state})=>{
    const {t} = useTranslation();
    state = obj?.aasm_state || state

    let color;
    let icon;

    switch(state) {
        case 'submitted':
        case 'pending':
            color = 'warning'
            icon = <ClockCircleOutlined/>;
            break

        case 'processing':
            color = 'info'
            icon = <LoadingOutlined/>;
            break

        case 'finalized':
        case 'processed':
        case 'accepted':
            color = 'green'
            icon = <CheckCircleOutlined/>;
            break
        case 'revoked':
            color = 'red'
            icon = <CloseCircleOutlined/>;
            break
    }

    return (
        <Tag color={color} icon={icon}>{t(state, state)?.toProperCase()}</Tag>
    )
}