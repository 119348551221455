import React, {useContext, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import api from '../api';

import { AppContext } from '../../contexts/AppContext'

import { Formik } from "formik"
import {
    SubmitButton,
    Input,
    Form,
    FormItem,
    Select,
} from "formik-antd"

import * as Yup from 'yup';

import {message, Button, Row, Col, Card, Timeline, Progress} from "antd"
import {BuildOutlined, PoweroffOutlined} from '@ant-design/icons';
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import ActionCable from "../ActionCable";
import useCurrentOrg from "../helpers/useCurrentOrg";
import useConsumer from "../../channels/consumer";
import useCurrentUser from "../helpers/useCurrentUser";
import Content from "../Content";

export default () => {
    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const [templates, setTemplates] = useState()
    useEffect(()=>{
        if(!currentUser.super_admin) return

        api('/api/organizations/templates').then(res => setTemplates(res.data))
    }, [])

    const {dispatch} = useContext(SessionContext);
    const navigate = useNavigate();

    const slugify = title => {
        return title
            .toLowerCase()
            .replace(/ +/g,'-')
            .replace(/[^\w]+/g,'-')
            .replace(/[;!.,'"]+/g,'')
            .replace(/-$/g,'')
    }

    const consumer = useConsumer();
    const sub = useRef();

    const [steps, setSteps] = useState([])
    const [assetProgress, setAssetProgress] = useState(0)

    useEffect(()=>{
        if(!sub.current) {
            console.log('connecting to UserChannel: ')

            sub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                connected: ()=> {
                    console.log('UserChannel connected')
                },
                disconnected: ()=> {
                    console.log('UserChannel disconnected!')
                },
                received: (data) => {
                    switch(data.type) {
                        case 'orgCreationProgress':
                            console.log(data)
                            if(data.step) {
                                setSteps(steps => [...steps, data.step])
                            } else if(data.assetProgress){
                                setAssetProgress(data.assetProgress)
                            }

                            if(data.progress >= 1) finished(data.organization)
                    }
                }
            });
        }

        return ()=> {
            consumer.subscriptions.remove(sub.current)
        }
    }, [])

    const formActions = useRef()

    const finished = org =>{
        if(!org) return

        formActions.current?.resetForm()
        formActions.current?.setSubmitting(false)

        message.success(`Welcome to ${org.title}!`)

        api.get(`/api/organizations/${org.id}/abilities`).then(res => {
            dispatch({type: 'set_org', org: org, abilities: res.data});
            navigate(`/${org.slug}/explore#tour`)
        })
    }

    if(!currentUser) return navigate('/login')

    return (
        <Card title={<><BuildOutlined/> New Organization</>} style={{width:isMobile() ? '100%' : 400, margin:'1em auto'}}>
            <Content code={'free-trial'}/>

            <Formik
                initialValues={{
                    title: "",
                    domain: "",
                }}
                onSubmit={(values, actions) => {
                    formActions.current = actions;

                    api({
                        method:'post',
                        url: '/api/organizations',
                        data: { organization: values }
                    }).then((res)=>{
                        console.log(res.data)
                    }).catch((error)=>{
                        console.error(error.response?.data)
                        message.error(`Error: ${JSON.stringify(error.response?.data) || 'Server Error'}`)
                        actions.setSubmitting(false)
                    })
                }}
                validationSchema={
                    Yup.object({
                        title: Yup.string().required('Required')
                    })
                }
            >
                {({isSubmitting, values}) => (
                    <Form layout='vertical'>
                        <FormItem label='Title' name="title" required showValidateSuccess>
                            <Input
                                autoFocus
                                required
                                autoComplete={'off'}
                                name="title"
                                placeholder="Your Organization's Title"
                                disabled={isSubmitting}
                            />
                        </FormItem>
                        <p>
                            <em>
                                {/*This will be the name of your organization on TV3.*/}
                                {/*<br/>*/}
                                Your URL will be: https://mediagraph.io/{slugify(values.title || '')}
                            </em>
                        </p>

                        {templates && (
                            <FormItem label='Starter Template' name="template_organization_id">
                                <Select name={'template_organization_id'} disabled={isSubmitting}>
                                    {templates.map(org => (
                                        <Select.Option value={org.id} key={org.id}>{org.title}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        )}

                        <FormItem name="submit" style={{marginBottom:0}}>
                            {isSubmitting ? (
                                <Button block type="primary" icon={<PoweroffOutlined />} loading>Creating...</Button>
                            ) : (
                                <SubmitButton block>Create</SubmitButton>
                            )}
                        </FormItem>

                        {!!steps.length && (
                            <Timeline pending={'Working...'} style={{margin:'2em 0'}}>
                                {steps.slice(0,steps.length).map(step => (
                                    <Timeline.Item key={step}>
                                        {step}

                                        {step.match(/assets/i) && (
                                            <div style={{marginTop:'1em'}}>
                                                <Progress percent={((assetProgress || 0) * 100).toFixed()} status={'active'}/>
                                            </div>
                                        )}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        )}
                    </Form>
                )}

            </Formik>

        </Card>
    );
}
