import React, {useContext} from "react";
import {AppContext} from "../../contexts/AppContext";
import {useSelectedAssetsDispatch} from "../../contexts/SelectedAssetsContext";
import api from "../api";
import {notification} from "antd";
import User from "./User";
import {SessionContext} from "../../contexts/SessionContext";
import {useOrgPath} from "./OrgNavLink";
import {useNavigate} from "react-router-dom-v5-compat";

const useSwitchToUser = ()=>{
    const {dispatch: appDispatch} = useContext(AppContext);
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const {state, dispatch} = useContext(SessionContext);

    const getPath = useOrgPath()

    const navigate = useNavigate();

    return user => {
        selectedAssetDispatch({type:'selectNone'})

        navigate(getPath('/'))

        setTimeout(()=>{
            api('/api/switch_user', {params: {id: user.user_id || user.id}}).then((res) => {
                appDispatch({type:'switchUser', user: res.data});

                const org = state.currentOrg;
                api.get(`/api/organizations/${org.id}/abilities`).then(res => {
                    dispatch({type: 'set_org', org: org, abilities: res.data});
                    notification.success({message: <>Switching to: <br/> <User user={user}/></>, description: 'Reloading app...'})
                    setTimeout(()=> {
                        window.location = getPath('/explore')
                    }, 500)

                })
            })
        }, 100)
    }
}

export default useSwitchToUser;