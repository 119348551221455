import React, {useEffect, useState} from "react";

import {Descriptions, Modal, Space, Tooltip, Typography} from "antd";
import {KeyboardIcon} from "../helpers/icons";
import {useTranslation} from "react-i18next";

export default ({hotKeysVisible, setHotKeysVisible})=>{
    const {t} = useTranslation();
    const item = (label, key) => {
        return <Descriptions.Item label={label}><Typography.Text keyboard>{key}</Typography.Text></Descriptions.Item>
    }

    return (<>
        <Modal
            title={<><KeyboardIcon/> Hot Keys</>}
            open={hotKeysVisible}
            onCancel={()=> setHotKeysVisible(false)}
            footer={null}
            width={'45vw'}
        >
            <Space direction={'vertical'} size={'large'} style={{width:'100%'}}>
                <Descriptions bordered size='small' column={1} title={t('site-wide','Site Wide')}>
                    {item(t('focus-site-wide-search','Focus site wide search'), '/')}
                    {item(t('close-modal-dialogues','Close modal dialogues'), 'Escape')}
                    {item(t('show-this-hotkey-panel','Show this Hotkey panel'), 'h')}
                </Descriptions>

                <Descriptions bordered size='small' column={1} title={t('explore-workspace','Explore Workspace')}>
                    {item(t('toggle-Left-sidebar','Toggle Left Sidebar'), '[')}
                    {item(t('toggle-right-sidebar','Toggle Right Sidebar'), ']')}
                    {item(t('reload-assets','Reload Assets'), 'Option-r')}
                    {item(t('set-table-view-mode','Set Table View Mode'), 't')}
                    {item(t('set-map-view-mode','Set Map View Mode'), 'm')}
                    {item(t('set-grid-view-mode','Set Grid View Mode'), 'g')}
                    {item(t('set-posts-view-mode','Set Posts View Mode'), 'p')}
                    {item(t('increase-grid-size','Increase Grid Size'), '=')}
                    {item(t('decrease-grid-size','Decrease Grid Size'), '-')}
                </Descriptions>

                <Descriptions bordered size='small' column={1} title={t('asset-selection','Asset Selection')}>
                    {item(t('select-deselect-all-assets','Select/Deselect All Assets'), 'Command-a')}
                    {item(t('prevent-deselection-on-asset-drag-prop','Prevent Deselection on Asset Drag/Drop'), 'Hold Option/Alt')}
                    {item(t('edit-selected-assets','Edit Selected Assets'), 'Command-e')}
                    {item(t('download-selected-assets','Download Selected Assets'), 'Command-Shift-s')}
                    {item(t('share-selected-assets','Share Selected Assets'), 'Command-Option-s')}
                    {item(t('delete-selected-assets','Delete Selected Assets'), 'Command-delete')}
                    {item(t('move-asset-cursor','Move Asset Cursor'), 'up/down/left/right')}
                    {item(t('move-asset-cursor-and-select-next','Move Asset Cursor and Select Next'), 'Shift up/down/left/right')}
                    {item(t('toggle-asset-cursor-selection','Toggle Asset Cursor Selection'), 's')}
                    {item(t('view-current-cursor-asset-details','View Current Cursor Asset Details'), 'Space')}
                </Descriptions>

                <Descriptions bordered size='small' column={1} title={t('asset-details-view','Asset Details View')}>
                    {item(t('view-previous-asset','View Previous Asset'), 'left')}
                    {item(t('view-next-asset','View Next Asset'), 'right')}
                    {item(t('view-full-screen','View Full Screen'), 'f')}
                    {item(t('cycle-full-screen-info-display','Cycle Full Screen Info Display'), 'i')}
                    {item(t('toggle-layout-vertical-horizontal','Toggle Layout (Vertical / Horizontal)'), 'v')}
                    {item(t('toggle-edit-browse-meta-mode','Toggle Edit/Browse Meta Mode'), 'e')}
                    {item(t('return-to-grid-view','Return to Grid View'), 'g')}
                    {item(t('rate-asset','Rate Asset'), '1-5')}
                    {item(t('un-rate-asset','Un-Rate Asset'), '0')}
                    {item(t('set-rating-1','Set Rating -1'), 'x')}
                </Descriptions>

                <Descriptions bordered size='small' column={1} title={t('workflow-approvals','Workflow Approvals')}>
                    {item(t('approve-workflow','Approve'), 'a')}
                    {item(t('pick-workflow-alt','Pick'), 'option-up')}
                    {item(t('reject-workflow-alt','Reject'), 'option-down')}
                    {item(t('set-pending-workflow-alt','Set Pending'), 'option-up / option-down')}
                </Descriptions>
            </Space>
        </Modal>
    </>);
}