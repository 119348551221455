import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    newNotifications: [],
}

const NotificationsStateContext = createContext(initialState);
const NotificationsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('NotificationsContext reducer:', action.type, action);

    switch (action.type) {
        case 'newNotification':
            return {...state, newNotifications: [action.notification, ...state.newNotifications]};

        case 'notified':
            return {...state, newNotifications: _.without(state.newNotifications, action.notification)};

        default:
            return state;
    }
}

function NotificationsProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <NotificationsStateContext.Provider value={state}>
            <NotificationsDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationsDispatchContext.Provider>
        </NotificationsStateContext.Provider>
    )
}

function useNotificationsState() {
    const context = useContext(NotificationsStateContext)
    if (context === undefined) {
        throw new Error('useNotificationsState must be used within a NotificationsProvider')
    }
    return context
}

function useNotificationsDispatch() {
    const context = useContext(NotificationsDispatchContext)
    if (context === undefined) {
        throw new Error('useNotificationsDispatch must be used within a NotificationsProvider')
    }
    return context
}

export {
    NotificationsProvider,
    useNotificationsState,
    useNotificationsDispatch
};
