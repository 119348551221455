import React, {useContext, useEffect, useRef, useState} from "react";
import setTitle from "../helpers/setTitle";
import api from "../api";
import {useParams} from "react-router-dom";
import {useAssetGroupDispatch, useAssetGroupState} from "~/contexts/AssetGroupContext";
import {Alert, Button, Card, ConfigProvider, Divider, Flex, message, notification, Popconfirm, Result, Skeleton, Space, Typography} from "antd";
import useCurrentOrg from "~/components/helpers/useCurrentOrg";
import {isMobile} from "device-detect";
import {useTranslation} from "react-i18next";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import useCurrentUser from "~/components/helpers/useCurrentUser";
import {Formik} from "formik";
import {FormItem, Input, Radio, SubmitButton} from "formik-antd";
import {RightsIcon} from "~/components/helpers/icons";
import {CheckOutlined, ClockCircleOutlined, CloseCircleOutlined, EditOutlined} from "@ant-design/icons";
import * as Yup from "yup";
import BrowseGrid from "~/components/explore/BrowseGrid";
import UploadManager from "~/components/uploads/UploadManager";
import {AssetDragLayer} from "~/components/explore/assetDragHelpers";
import DragAndDrop from "~/components/helpers/DragAndDrop";
import AssetsManager from "~/components/explore/AssetsManager";
import ActionCable from "~/components/ActionCable";
import {useNavigate} from "react-router-dom-v5-compat";
import {OrgLink, useOrgPath} from "~/components/helpers/OrgNavLink";
import {Parser} from "html-to-react";
import {markdown} from "markdown";
import User from "~/components/helpers/User";
import {Checkbox as FormikCheckbox} from "formik-antd/lib/checkbox";
import {RequiredTag, RequirementMetTag} from "~/components/widgets/EditAssetTable";
import {css, Global as GlobalStyles} from "@emotion/react";

export default ({})=>{
    const {t} = useTranslation();

    const currentUser = useCurrentUser();

    const assetGroupDispatch = useAssetGroupDispatch();
    let { uploadGuid, contributionSlug } = useParams();
    const {currentUpload} = useAssetGroupState()
    const contribution = currentUpload?.contribution

    setTitle(contribution?.name || t('quick-upload','Quick Upload'));

    const currentOrg = useCurrentOrg();
    const [loading, setLoading] = useState()
    const getPath = useOrgPath()

    const [view, setView] = useState('info')

    useEffect(() => {
       if(currentUpload?.agreed_at) setView('upload')
    }, [currentUpload?.agreed_at]);

    useEffect(()=>{
        if(!currentOrg) {
            // Old uploadGuid accessed, start a new upload:
            return navigate(getPath(`/quick-upload/${contributionSlug}`));
        }

        if(!uploadGuid) return;

        setLoading(true)
        api.get(`/api/uploads/${uploadGuid}`).then(res => {
            assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});
            setLoading(false)
        }).catch(res => {
            notification.error({message: 'Error', description: t('error-upload-not-found','Upload not found...'), duration: 0})
        });

        return ()=> {
            assetGroupDispatch({type: 'setCurrentUpload', upload: null});
        }
    }, [uploadGuid, currentOrg?.id]);

    const navigate = useNavigate();
    const getOrgPath = useOrgPath()

    const [done, setDone] = useState()
    const clickDone = ()=>{
        setDone(true)
    }

    const clickCancel = ()=>{
        message.info(t('upload-canceled','Upload Canceled'));
        navigate(getOrgPath('/'))
    }

    if (currentUpload) {
        currentUpload.name ||= '';
        currentUpload.email ||= '';
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        defaultBg: currentOrg?.header_highlight_color,
                        defaultBorderColor: currentOrg?.header_highlight_color,
                        defaultColor: currentOrg?.header_text_color,
                    },
                }
            }}
        >
            <GlobalStyles
                styles={css`
                    .ant-form-item-label { font-weight: bold; }
                `}
            />
            <Card
                style={{maxWidth:800, margin: '0 auto'}}
                styles={{header: {backgroundColor: currentOrg?.header_background_color || 'white', color: currentOrg?.header_text_color || 'black'}}}
                title={
                    <Flex justify={'space-between'} style={{width:'100%'}} align={'center'}>
                        <OrgLink to={'/'} style={{height: 35}}>
                            <img alt={`${(currentOrg)?.title} Mediagraph Account`} src={(currentOrg)?.logo_url || ImageUrls.headerLogo} style={{height: 35, width: isMobile() ? 40 : 'auto', objectFit: 'contain', maxWidth: 120}}/>
                        </OrgLink>

                        <div>{contribution?.name}</div>

                        {!done && (
                            <div>
                                {currentUpload?.agreed_at ? (
                                    <Popconfirm title={t('done-uploading', 'Done uploading?')} onConfirm={clickDone}>
                                        <Button icon={<CheckOutlined/>}>{t('done', 'Done')}</Button>
                                    </Popconfirm>
                                ) : (
                                    <Button icon={<CloseCircleOutlined/>} onClick={clickCancel}>{t('cancel-upload', 'Cancel Upload')}</Button>
                                )}
                            </div>
                        )}
                    </Flex>
                }
            >
                {done ? (
                    <Result
                        status={'success'}
                        title={t('successfully-finished-upload','Successfully finished upload, thank you!')}
                        extra={[
                            <OrgLink to={'/'} style={{height: 35}}>
                                <Button>{t('back-to-home','Back to Home')}</Button>
                            </OrgLink>
                        ]}
                    />
                ) : (
                    <VerticalSpace style={{marginBottom: '2em'}}>
                        {currentUpload?.contribution?.text?.length && (
                            <Card size={'small'} style={{marginBottom: '1em'}}>
                                {(new Parser).parse(markdown.toHTML(currentUpload.contribution.text))}
                            </Card>
                        )}

                        {(view === 'info' || !currentUpload?.agreed_at) && (
                            <Skeleton active loading={loading}>
                                <Formik
                                    initialValues={currentUpload || {}}
                                    validateOnMount
                                    onSubmit={(values, actions)=>{
                                        api.put(`/api/uploads/${currentUpload.id}`, {upload: values, agreed: true}).then(res => {
                                            assetGroupDispatch({type: 'setCurrentUpload', upload: {...currentUpload, ...res.data}});
                                            message.success(t('info-saved-start-upload','Information saved successfully! You may start uploading now.'))
                                            setView('upload')
                                            actions.setSubmitting(false)
                                        })

                                        window.scrollTo(0, 0);
                                    }}
                                    validationSchema={
                                        Yup.object({
                                            first_name: currentUser?.guest && Yup.string().required(t('required','Required')),
                                            last_name: currentUser?.guest && Yup.string().required(t('required','Required')),
                                            email: currentUser?.guest && Yup.string().required(t('required','Required')),
                                            creator: contribution?.require_creator_tag && Yup.string().required(t('required','Required')),
                                            name: Yup.string().required(t('required','Required')),
                                            tag_names: currentUpload?.tag_suggesters?.filter(ts => ts.required)?.length && Yup.array().required(t('required','Required')),
                                        })
                                    }
                                >
                                    {({values, submitForm, isValid, isSubmitting}) => {
                                        return (
                                            <>
                                                {currentUser?.guest ? (
                                                    <>
                                                        <FormItem required showValidateSuccess name='email' label={t('email-address', 'Email Address')} labelCol={{ span: 24 }}>
                                                            <Input required name='email' autoFocus type={'email'}/>
                                                        </FormItem>

                                                        <FormItem required showValidateSuccess name='first_name' label={t('first-name', 'First Name')} labelCol={{ span: 24 }}>
                                                            <Input required name='first_name'/>
                                                        </FormItem>

                                                        <FormItem required showValidateSuccess name='last_name' label={t('last-name', 'Last Name')} labelCol={{ span: 24 }}>
                                                            <Input required name='last_name'/>
                                                        </FormItem>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Space>
                                                            <strong>{t('logged-in-as','Logged in as')}:</strong>
                                                            <User user={currentUser}/>
                                                        </Space>
                                                        <Divider/>
                                                    </>
                                                )}

                                                <FormItem required={contribution?.require_creator_tag} showValidateSuccess={contribution?.require_creator_tag} name='creator' label={t('creator', 'Creator')} labelCol={{ span: 24 }}>
                                                    <VerticalSpace>
                                                        <Radio.Group name={'creator'}>
                                                            <Radio value={'me'} id={'creator-me'}>{t('i-created-these','I created these photos/videos/documents')}</Radio>
                                                            <Radio value={'other'} id={'creator-other'}>{t('created-by-someone-else', 'Created by someone else')}</Radio>
                                                        </Radio.Group>

                                                        {values.creator === 'other' && (
                                                            <Input name={'other_creator'} placeholder={t('add-a-creator-name-if-known','Add a creator name if known...')}/>
                                                        )}
                                                    </VerticalSpace>
                                                </FormItem>

                                                <FormItem required showValidateSuccess name='name' label={t('upload-name', 'Upload Name')} labelCol={{ span: 24 }}>
                                                    <Input required name='name' placeholder={t('add-an-upload-name','Enter a short name to describe the upload')}/>
                                                </FormItem>

                                                <FormItem name='note' label={t('description', 'Description')} labelCol={{ span: 24 }}>
                                                    <Input.TextArea rows={2} name={'note'}  placeholder={t('add-an-upload-description','Add additional information if desired')}/>
                                                </FormItem>

                                                {!!currentUpload?.tag_suggesters?.length && (
                                                    <FormikCheckbox.Group name='tag_names' style={{marginBottom:'1em'}}>
                                                        {currentUpload?.tag_suggesters?.map((ts,i) => (
                                                            <div key={ts.id} style={{margin: '1em 0'}}>
                                                                <strong style={{display:'inline-block', marginBottom:'.5em'}}>{ts.required && <span style={{color:'red'}}>*</span>} {ts.name}</strong>
                                                                {_.compact(ts.tag_names).map(name => (
                                                                    <div key={name} className={'tag-suggester'}>
                                                                        <FormikCheckbox key={name} value={name}>{name}</FormikCheckbox>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </FormikCheckbox.Group>
                                                )}

                                                <VerticalSpace>
                                                    <strong>{t('rights','Rights')}</strong>
                                                    {!!contribution?.rights_packages?.length && (
                                                        <Radio.Group
                                                            name={'default_rights_package_id'}
                                                            style={{width:'100%'}}
                                                        >
                                                            <VerticalSpace>
                                                                {contribution?.rights_packages?.map(rp =>
                                                                    <Alert
                                                                        key={rp.id}
                                                                        type={'info'}
                                                                        // showIcon
                                                                        // icon={<RightsIcon/>}
                                                                        // style={{padding:'0 1em'}}
                                                                        description={
                                                                            <Radio value={rp.id}>
                                                                                <strong>{rp.name}</strong>
                                                                                {!!rp.summary?.length && (new Parser).parse(markdown.toHTML(rp.summary))}
                                                                            </Radio>
                                                                        }
                                                                    />
                                                                )}
                                                            </VerticalSpace>
                                                        </Radio.Group>
                                                    )}

                                                    <Flex gap={8}>
                                                        <SubmitButton onClick={submitForm} type={'primary'} block icon={<CheckOutlined/>} loading={isSubmitting} disabled={!isValid}>
                                                            {currentUpload?.agreed_at ? (
                                                                <>{t('save-info','Save Info')}</>
                                                            ) : (
                                                                <>{t('agree-and-upload','Agree and Upload')}</>
                                                            )}
                                                        </SubmitButton>
                                                        {currentUpload?.agreed_at && (
                                                            <Button type={'primary'} ghost icon={<CloseCircleOutlined/>} onClick={()=> setView('upload')}>{t('cancel-edit','Cancel Edit')}</Button>
                                                        )}
                                                    </Flex>
                                                </VerticalSpace>
                                            </>
                                        )
                                    }}
                                </Formik>
                            </Skeleton>
                        )}

                        {currentUpload?.agreed_at && (
                            <VerticalSpace>
                                <Space style={{margin:'1em 0'}} size={'large'}>
                                <span>
                                     <strong>{t('upload-name','Upload Name')}:</strong> {currentUpload?.name}
                                </span>
                                    <Button size={'small'} type={'primary'} ghost icon={<EditOutlined />} onClick={()=> setView('info')}>{t('edit-info','Edit Info')}</Button>
                                </Space>

                                <DragAndDrop>
                                    <UploadManager quickUpload/>
                                    <AssetDragLayer/>
                                    <AssetsManager/>
                                    {currentOrg && <ActionCable/>}
                                    <BrowseGrid quickUpload/>
                                </DragAndDrop>
                            </VerticalSpace>
                        )}
                    </VerticalSpace>
                )}
            </Card>
        </ConfigProvider>
    )
}