import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import ContributionsTable from "./ContributionsTable";
import Content from "../Content";
import ContributionsForm from "./ContributionsForm";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('contributors','Contributors'));

    const [drawerVisible, setDrawerVisible] = useState();

    const onClick = ()=> {
        setDrawerVisible(true)
        window.location.hash = 'new'
    }

    const onClose = ()=> {
        history.replaceState(null, null, ' ');
        setDrawerVisible(false)
    }

    const [newContribution, setNewContribution] = useState();

    const onSave = (values)=> {
        history.replaceState(null, null, ' ');
        onClose()
        setNewContribution(values);
    }

    if(window.location.hash == '#new' && !drawerVisible) {
        setDrawerVisible(true);
    }

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('search','Search...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'Contributor'}>
                    <Button type='primary' onClick={onClick}>
                        <PlusOutlined/>
                        {t('add-new','Add New')}
                    </Button>

                    <ContributionsForm
                        onClose={onClose}
                        onSave={onSave}
                        visible={drawerVisible}
                    />
                </Can>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}
            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}
            >
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-contributions'}/>
                </TabPane>
                <TabPane tab={t("browse-contribution-requests","Browse Contribution Requests")} key="browse">
                    <ContributionsTable newContribution={newContribution} q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
            </Tabs>
        </>
    );
}
