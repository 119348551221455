import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom-v5-compat";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions, Switch, Radio
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {AbilityContext, Can} from "../helpers/Can";
import ApartmentOutlined from "@ant-design/icons/lib/icons/ApartmentOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import TagSynonymManager from "../widgets/TagSynonymManager";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import {EditableTag, MergeTagButton} from "@/components/widgets/TagSelect";
import {useAbility} from "@casl/react";

export default ({newTag, q, onLoaded, refresh}) => {
    const {t} = useTranslation();
    const dispatch = useAssetsDispatch();

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/tags'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-tag-deleted.','Tag Deleted.'));
            handleTableChange();
        });
    }

    const [bulkDeleting, setBulkDeleting] = useState()

    const destroySelected = ()=>{
        const key = 'tag-bulk-delete'
        message.loading({content: t('message-tag-deleted','Tag Deleted.'), key});

        setBulkDeleting(true)
        setSelectedKeys([])

        api({
            url: `/api/tags/bulk_destroy`,
            params: {ids: selectedKeys},
            method: 'delete'
        }).then(res => {
            message.destroy(key)
            message.success(t('message-tags-deleted','Tag Deleted.'));
            handleTableChange();
            setBulkDeleting(false)
        });
    }

    const NameCell = ({name, tag})=>{
        const [value, setValue] = useState(tag.name || '');

        const onChange = (newValue)=>{
            setValue(newValue);
            api.put(`/api/tags/${tag.id}`, {tag:{name: newValue}}).then(res => {
                if(res.data.errors) {
                    setValue(tag.name);
                    return message.error(`${t('error','Error')}: ` + JSON.stringify(res.data.errors))
                }
                message.success(t('message-name-updated','Name updated.'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        return (
            <Typography.Paragraph
                // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                editable={tag.editable && {onChange}}
            >
                {value}
            </Typography.Paragraph>
        )
    }

    const DescriptionCell = ({name, tag})=>{
        const [value, setValue] = useState(tag.description || '');

        const onChange = (newValue)=>{
            setValue(newValue);
            api.put(`/api/tags/${tag.id}`, {tag:{description: newValue}}).then(res => {
                message.success(t('message-description-updated','Description updated.'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        return (
            <Typography.Paragraph
                // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                editable={tag.editable && {onChange}}
            >
                {value}
            </Typography.Paragraph>
        )
    }

    const TypeCell = ({value, tag}) => {
        const [subType, setSubType] = useState(tag.sub_type);

        const onChange = value => {
            setSubType(value);
            api.put(`/api/tags/${tag.id}`, {tag: {sub_type: value}}).then(res => {
                message.success(t('message-tag-updated','Tag updated'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        useEffect(()=>{
            setSubType(tag.sub_type)
        }, [tag.sub_type])

        return (
            <Can I={'manage'} a={'Tag'} passThrough>
                {allowed => {
                    return allowed ? (
                        <Radio.Group
                            options={[{label:t('keyword','Keyword'), value:'keyword'}, {label:t('person','Person'), value:'person'}, {label:t('event','Event'), value:'event'},{label:t('product','Product'), value:'product'}]}
                            onChange={e => { onChange(e.target.value) }}
                            value={subType}
                            optionType="button"
                            size="small"
                        />
                    ) : subType.toProperCase()
                }}
            </Can>
        )
    }

    const ListCell = ({value, tag}) => {
        const [list, setList] = useState(tag.list);

        const onChange = value => {
            setList(value);
            api.put(`/api/tags/${tag.id}`, {tag: {list: value}}).then(res => {
                message.success(t('message-tag-Updated','Tag updated'))
                dispatch({type:'updateTag', tag: {...tag, ...res.data}})
            })
        }

        useEffect(()=>{
            setList(tag.list)
        }, [tag.list])

        return (
            <Can I={'manage'} a={'Tag'} passThrough>
                {allowed => {
                    return allowed ? (
                        <Select onChange={onChange} style={{width:150}} value={list} getPopupContainer={() => document.getElementById('taxonomy-table')}>
                            {['visible', 'searchable', 'blocked'].map(level =>
                                <Select.Option key={level} value={level}>
                                    {getIcon(level)}&nbsp;&nbsp;{t(level,level)}
                                </Select.Option>
                            )}
                        </Select>
                    ) : list
                }}
            </Can>
        )
    }

    const TaxonomiesCell = ({names, tag})=>{
        const [removing, setRemoving] = useState()
        const removeFromTaxonomy = ()=> {
            setRemoving(true)
            api.post(`/api/tags/${tag.id}/remove_taxonomies`).then(res => {
                setRemoving(false)
                message.success(t('message-tag-removed-from-taxonomies','Tag removed from Taxonomies.'))
                loadTableData()
            })
        }

        return (
            <>
                {names.join(', ')}
                {!!names.length && (
                    <div style={{float:'right'}}>
                        <Popconfirm onConfirm={removeFromTaxonomy} title={t('confirm-remove-from-taxonomies?','Remove From Taxonomies?')}>
                            <Tooltip title={t('tooltip-remove-from-taxonomies','Remove From Taxonomies...')} placement={'left'}>
                                <Button icon={<DeleteOutlined/>} loading={removing} size={'small'}/>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                )}
            </>
        )
    }


    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
            render: (name, tag) => <NameCell name={name} tag={tag}/>
        },
        {
            title: t('description','Description'),
            dataIndex: 'description',
            sorter: true,
            render: (name, tag) => <DescriptionCell name={name} tag={tag}/>
        },
        {
            title: 'Type',
            sorter: true,
            dataIndex: 'sub_type',
            filters: [
                {text: t('keyword','Keyword'), value: 'keyword'},
                {text: t('person','Person'), value: 'person'},
                {text: t('event','Event'), value: 'event'},
                {text: t('product','Product'), value: 'product'},
            ],
            render: (value, tag) => <TypeCell value={value} tag={tag}/>
        },
        {
            title: t('list','List'),
            sorter: true,
            dataIndex: 'list',
            filters: [
                {text: t('blocked','Blocked'), value: 'blocked'},
                {text: t('searchable','Searchable'), value: 'searchable'},
                {text: t('visible','Visible'), value: 'visible'},
            ],
            render: (value, tag) => <ListCell value={value} tag={tag}/>
        },
        {
            title: t('synonyms','Synonyms'),
            dataIndex: 'synonym',
            filterMultiple: false,
            filters: [
                {text: t('primary','Primary'), value: 'lead'},
                {text: t('is-synonym','Is Synonym'), value: 'synonym'},
                {text: t('not-set','Not Set'), value: 'none'},
            ],
            render: (_s, tag) => {
                return (
                    <>
                        <TagSynonymManager tag={tag} onUpdate={loadTableData}/>
                    </>
                )
            },
        },
        {
            title: t('taxonomies','Taxonomies'),
            dataIndex: 'taxonomy_names',
            filters: [
                {text: t('in-taxonomy','In Taxonomy'), value: true},
                {text: t('not-in-taxonomy','Not In Taxonomy'), value: false},
            ],
            render: (names, tag) => <TaxonomiesCell names={names} tag={tag}/>,
        },
        {
            title: t('taggings','Taggings'),
            dataIndex: 'taggings_count',
            sorter: true,
            render: (count, tag) => {
                return (
                    <OrgLink to={`/explore?tags[]=${tag.name}`}>{count}</OrgLink>
                )
            }
        },
        {
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (tag) => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'Tag'}>
                                <Space direction={'horizontal'}>
                                    <EditableTag tag={tag} editable bulk disableRemove disableTypeSelect afterMerge={handleTableChange}>
                                        <Tooltip title={t('details','Details')}>
                                            <Button icon={<EyeOutlined/>} size={'small'}/>
                                        </Tooltip>
                                    </EditableTag>

                                    <MergeTagButton tag={tag} iconOnly afterMerge={handleTableChange}/>

                                    <Popconfirm
                                        title={t('confirm-delete-tag','Delete Tag?')}
                                        onConfirm={() => destroy(tag.id)}
                                    >
                                        <Button size={'small'}>
                                            <DeleteOutlined/>
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        if(!params.current?.pagination) return

        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newTag, refresh]);

    useEffect(()=>{
        handleTableChange({...settings.pagination, current:1})
    }, [q])

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        console.log(item);
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    const onListChange = (e)=>{
        const newValue = e.key;
        api.post(`/api/tags/bulk`, {list: newValue, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(t('message-lists-updated','List(s) updated.'))
        })
    }

    const addTaxonomy = ()=>{
        api.post(`/api/tags/bulk`, {add_taxonomy: true, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(`${selectedKeys.length} ${t('message-added-to-taxonomy','Added to Taxonomy.')}`)
        })
    }

    const removeTaxonomy = ()=>{
        api.post(`/api/tags/bulk`, {remove_taxonomy: true, tag_ids: selectedKeys}).then(res => {
            loadTableData();
            message.success(`${selectedKeys.length} ${t('message-removed-from-taxonomy','Removed From Taxonomy.')}`)
        })
    }

    const getIcon = (value)=> {
        return {
            visible: <EyeOutlined/>,
            searchable: <SearchOutlined/>,
            blocked: <CloseCircleOutlined/>
        }[value];
    }

    const ability = useAbility(AbilityContext);
    const canEditTags = ability.can('edit', 'Tag')

    return (
        <>
            <Table
                id={'taxonomy-table'}
                bordered
                scroll={{x: true}}
                size={'small'}
                rowSelection={canEditTags && {onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, showSizeChanger: true}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>

                            {selectedKeys.length > 0 &&
                                <Space>
                                    <Dropdown overlay={
                                        <Menu onClick={onListChange}>
                                                {['visible', 'searchable', 'blocked'].map(level => {
                                                    return <Menu.Item key={level} value={level}>{getIcon(level)} {t(level,level)}</Menu.Item>
                                                })}
                                        </Menu>
                                    }>
                                        <Button><EditOutlined/>  {t('bulk-update','Bulk Update')} {selectedKeys.length} {t('list','List')}... <DownOutlined/></Button>
                                    </Dropdown>

                                    <Popconfirm
                                        title={t('confirm-bulk-add-to-taxonomy','Bulk add to Taxonomy?')}
                                        onConfirm={addTaxonomy}
                                    >
                                        <Button ghost type={'primary'} icon={<UnorderedListOutlined />}>
                                            {t('bulk-add-to-taxonomy','Bulk Add {{count}} To Taxonomy', {count: selectedKeys.length})}
                                        </Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={t('confirm-bulk-remove-from-taxonomy','Bulk remove from Taxonomy?')}
                                        onConfirm={removeTaxonomy}
                                    >
                                        <Button ghost danger icon={<CloseCircleOutlined />}>
                                            {t('remove-from-taxonomy','Remove {{count}} From Taxonomy', {count: selectedKeys.length})}
                                        </Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title={t('confirm-delete-selected','Delete Selected?')}
                                        onConfirm={destroySelected}
                                    >
                                        <Button ghost danger icon={<DeleteOutlined />} loading={bulkDeleting}>
                                            {t('delete','Delete')} {selectedKeys.length}
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            }
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
