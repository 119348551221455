import {CollectionIcon, ProjectIcon, StorageFolderIcon} from "../helpers/icons";
import OrgNavLink from "../helpers/OrgNavLink";
import React from "react";

export default ({assetGroup, searchString})=>{
    const path = {
        StorageFolder: 'folders',
        Collection: 'collections',
        Lightbox: 'projects'
    }[assetGroup.type];

    const icon = {
        StorageFolder: <StorageFolderIcon/>,
        Collection: <CollectionIcon/>,
        Lightbox: <ProjectIcon/>
    }[assetGroup.type];

    return (
        <OrgNavLink
            to={`/explore/${path}/${assetGroup.slug}${searchString ? `#${searchString}` : ''}`}
            style={{color:'black'}}
            className={'asset-group-link'}
        >
            {icon} {assetGroup.path_names?.join(' / ')}
        </OrgNavLink>
    )
}