import React, {useEffect, useState} from "react";
import {Alert, Button, message, Modal, Space} from "antd";
import {ClockCircleOutlined, EditOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import fileDownload from 'js-file-download';
import api from "../api";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";

// react-pintura
import { PinturaEditorModal } from '@pqina/react-pintura';

// pintura
import {
    // editor
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    // plugin_crop_defaults,

    plugin_finetune,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,

    plugin_filter,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,

    plugin_annotate,
    // plugin_annotate_defaults,
    plugin_annotate_locale_en_gb,

    plugin_decorate,
    // plugin_decorate_defaults,
    plugin_decorate_locale_en_gb,
    // component_shape_editor_locale_en_gb,

    plugin_resize,
    // plugin_resize_defaults,
    plugin_resize_locale_en_gb,

    markup_editor_defaults,
    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    plugin_frame,
    plugin_frame_locale_en_gb,
    plugin_frame_defaults,

    plugin_redact,
    plugin_redact_locale_en_gb,
    createDefaultImageScrambler,
} from '@pqina/pintura';

import locale_fr from '../../plugins/pintura-locales/fr_FR/core/fr_FR'
import plugin_crop_fr from '../../plugins/pintura-locales/fr_FR/crop/fr_FR'
import plugin_finetune_fr from '../../plugins/pintura-locales/fr_FR/finetune/fr_FR'
import plugin_filter_fr from '../../plugins/pintura-locales/fr_FR/filter/fr_FR'
import plugin_annotate_fr from '../../plugins/pintura-locales/fr_FR/annotate/fr_FR'
import plugin_decorate_fr from '../../plugins/pintura-locales/fr_FR/decorate/fr_FR'
import plugin_resize_fr from '../../plugins/pintura-locales/fr_FR/resize/fr_FR'
import plugin_frame_fr from '../../plugins/pintura-locales/fr_FR/frame/fr_FR'
import plugin_redact_fr from '../../plugins/pintura-locales/fr_FR/redact/fr_FR'

import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useUploadsDispatch} from "../../contexts/UploadsContext";
import {useNavigate} from "react-router-dom-v5-compat";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import useCurrentUser from "@/components/helpers/useCurrentUser";

setPlugins(plugin_crop, plugin_resize, plugin_decorate, plugin_finetune, plugin_filter, plugin_redact, plugin_frame);

const imageWriter = createDefaultImageWriter({
    copyImageHead: true,
    mimeType: 'image/jpeg',
});

// TODO: locale:
// https://pqina.nl/pintura/docs/v8/faq/change-language/#loading-different-language-and-locale-files

const editorDefaults = {
    imageReader: createDefaultImageReader(),
    imageWriter,
    shapePreprocessor: createDefaultShapePreprocessor(),
    // ...plugin_crop_defaults,
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    ...plugin_frame_defaults,
    // ...plugin_annotate_defaults,
    // ...plugin_decorate_defaults,
    // ...plugin_resize_defaults,
    imageScrambler: createDefaultImageScrambler(),
    locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_decorate_locale_en_gb,
        ...plugin_resize_locale_en_gb,
        ...markup_editor_locale_en_gb,
        ...plugin_frame_locale_en_gb,
        ...plugin_redact_locale_en_gb,
        labelButtonExport: 'Done',
        decorateLabel: 'Annotate',
        decorateIcon: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M17.086 2.914a2.828 2.828 0 1 1 4 4l-14.5 14.5-5.5 1.5 1.5-5.5 14.5-14.5z"/></g>'
    },
    cropEnableInfoIndicator: true,
};

export default ({asset})=> {
    const {t, i18n} = useTranslation();

    // TODO: use org crop presets:
    editorDefaults.cropSelectPresetOptions = [
        [
            t('crop','Crop'),
            [
                [undefined, t('custom','Custom')],
                [1, t('square','Square')],
                [1.5, t('landscape','Landscape')],
                [0.7, t('portrait','Portrait')],
            ],
        ],
        [
            t('size','Size'),
            [
                [[1200, 600], `${t('banner-image','Banner Image')} (1200x600)`],
                [[800, 400], `${t('timeline-photo','Timeline Photo')} (800x400)`],
                [[180, 180], `${t('profile-picture','Profile Picture')} (180x180)`],
            ],
        ],
    ];

    if(i18n.language === 'fr') {
        editorDefaults.locale = {
            ...editorDefaults.locale,
            ...locale_fr,
            ...plugin_crop_fr,
            ...plugin_finetune_fr,
            ...plugin_filter_fr,
            ...plugin_annotate_fr,
            ...plugin_decorate_fr,
            ...plugin_resize_fr,
            ...plugin_frame_fr,
            ...plugin_redact_fr,
            labelButtonExport: t('done','Done'),
            decorateLabel: t('annotate','Annotate'),
        }
    }

    const [src, setSrc] = useState()
    const [loading, setLoading] = useState()
    const [modalVisible, setModalVisible] = useState()

    const uploadsDispatch = useUploadsDispatch()

    const {currentAssetGroup} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch()
    const inLightbox = currentAssetGroup?.type === 'Lightbox' && currentAssetGroup?.contribution_id

    const useOriginal = ['jpg', 'jpeg', 'png', 'gif'].indexOf(asset.ext) !== -1

    const clickEdit = ()=> {
        setLoading(true)
        if(asset.original_downloadable) {
            const mb = (asset.file_size / (1024*1024)).toFixed(2)
            const size = useOriginal && mb < 100 ? 'original' : 'full'

            api.post('/api/downloads',{download: {size, via: 'editor', asset_ids: [asset.id]}}).then(res => {
                if(res.data.token) {
                    setSrc(`/api/downloads/${res.data.token}?attachment=false`)
                } else {
                    message.error('There an error preparing the image for editing.')
                }
            }).catch((err)=>{
                setLoading(false)
                console.log(err)
            })
        } else {
            setSrc(asset.full_url || asset.permalink_url || asset.small_url)
        }
    }

    const [fileData, setFileData] = useState()

    const onProcess = ({dest})=> {
        if(asset.editable || inLightbox) {
            setFileData(dest)
            setModalVisible(true)
        } else {
            proceedDownload(dest)
        }
    }

    const onError = err =>{
        console.log(err)
        setLoading(false)
    }

    const download = ()=>{
        proceedDownload(fileData)
    }

    const editedFilename = `${asset.filename.toBasename()}-edited.jpg`

    const proceedDownload = (data)=> {
        fileDownload(data, editedFilename)
    }

    const saveVersion = ()=>{
        fileData.new_version = true
        fileData.asset_id = asset.id
        fileData.filename = editedFilename
        uploadsDispatch({type:'addFiles', files: [fileData], upload: asset.upload})
        setModalVisible(false)
    }

    const navigate = useNavigate();

    const getPath = useOrgPath()

    const [creatingNew, setCreatingNew] = useState()
    const createNew = ()=>{
        setCreatingNew(true)
        const id = currentAssetGroup.contribution_id

        api.post(`/api/contributions/${id}/uploads`, {
            asset_group_folder_id: currentAssetGroup?.folder?.id
        }).then(res => {
            const upload = res.data;
            assetGroupDispatch({type: 'setCurrentUpload', upload});

            navigate(getPath(`/upload/${upload.contribution.slug}/${upload.guid}?${upload.contribution.asset_group?.type == 'StorageFolder' ? '' : 'submitted=false'}`));

            setTimeout(()=>{
                fileData.duplicated_asset_id = asset.id
                fileData.filename = editedFilename
                uploadsDispatch({type:'addFiles', files: [fileData], upload})
                setModalVisible(false)
                setCreatingNew(false)
            }, 500)
        })
    }

    const [cropPresets, setCropPresets] = useState()
    const org = useCurrentOrg()
    const currentUser = useCurrentUser()

    useEffect(()=>{
        if(org && currentUser) {
            api('/api/crop_presets?enabled=true').then(res => {
                setCropPresets(res.data)
            })
        }
    }, [org?.id])

    if(!cropPresets) return <></>

    editorDefaults.cropSelectPresetOptions[1][1] = cropPresets.map(cp => [[cp.width, cp.height], `${cp.name} (${cp.width}x${cp.height})`])

    return (<>
        <Button icon={<EditOutlined/>} onClick={clickEdit} loading={loading}>{isBrowser() && t('button-open-editor','Open Editor')}</Button>
        {src && (
            <PinturaEditorModal
                {...editorDefaults}
                src={src}
                onLoad={(res) => setLoading(false)}
                onHide={() => setSrc(false)}
                onProcess={onProcess}
                onError={onError}
            />
        )}
        {modalVisible && (
            <Modal
                zIndex={2000}
                width={isMobile() ? '100%' : 650}
                onCancel={()=> setModalVisible(false)}
                open={modalVisible}
                title={<><CloudDownloadOutlined/> {t('saving-edited-image-options','Saving Edited Image Options')}</>}
                footer={null}
            >
                <Space direction={'horizontal'} style={{textAlign:'center', width:'100%'}}>
                    <Button onClick={download} icon={<DownloadOutlined/>} size={'large'}>{t('button-download','Download')}</Button>

                    {asset.manageable && (
                        <Button onClick={saveVersion} icon={<ClockCircleOutlined/>} size={'large'}>{t('button-save-as-current-version','Save As Current Version')}</Button>
                    )}
                </Space>
                
                {inLightbox && (
                    <p style={{margin:'1em 0'}}>
                        <Button onClick={createNew} icon={<PlusOutlined/>} size={'large'} loading={creatingNew}>{t('button-create-new-asset-in-lightbox','Create New Asset in Lightbox')}</Button>
                    </p>
                )}

                {!useOriginal && (
                    <Alert
                        style={{marginTop:'1em'}}
                        type={'warning'}
                        showIcon
                        message={
                            t('edit-image-version-warning','The version created in the editor is a JPEG file, and your original file was a different format. The original file will be saved as a version, and can be found in the Versions tab of the asset view.')
                        }/>
                )}
            </Modal>

        )}
    </>);
}