import {Formik} from "formik";
import api from "../api";
import {Alert, Button, Card, message, Modal} from "antd";
import * as Yup from "yup";
import {Form, FormItem, Input, SubmitButton, Checkbox} from "formik-antd";
import {LoadingOutlined, LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate} from "react-router-dom-v5-compat";
import {SessionContext} from "../../contexts/SessionContext";
import useAfterLogin from "../helpers/useAfterLogin";
import Content from "../Content";
import {useTranslation} from "react-i18next";

export default ({invite, trialSignup})=> {
    const {t, i18n} = useTranslation();
    let lastEmailCheck = null;
    console.log('invite', invite)
    const {dispatch} = useContext(AppContext);
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const navigate = useNavigate()

    const [showTos, setShowTos] = useState()

    const showTosModal = e =>{
        e.preventDefault()
        e.stopPropagation()
        setShowTos(true)
    }

    const [confirmAccount, setConfirmAccount] = useState()

    const [redirecting, setRedirecting ] = useState()

    if(confirmAccount) {
        return (
            <Alert showIcon type={'success'} message={t('please-check-email-for-confirmation-link',`Please check "{{email}}" for an email confirmation link.`, {email: confirmAccount})}/>
        )
    }

    if(redirecting) {
        return (
            <>
                <LoadingOutlined/> {t('taking-you-to-the-organization', 'Taking you to the Organization...')}
            </>
        )
    }

    return (
        (<Formik
            initialValues={{
                email: invite?.email || trialSignup?.email || "",
                first_name: invite?.first_name || (trialSignup?.name && trialSignup.name.split(/ /)[0]) || "",
                last_name: invite?.last_name || (trialSignup?.name && trialSignup.name.split(/ /)[1]) || "",
                password: "",
                password_confirmation: "",
            }}
            onSubmit={(values, actions) => {
                values.invite_token = invite?.token
                values.email_verification_token = trialSignup?.email_verification_token
                values.lang = i18n.language
                console.warn(JSON.stringify(values))
                api({
                    method:'post',
                    url: '/api/sign_up',
                    data: { user: values }
                }).then((res)=>{
                    const newUser = res.data

                    if(res.headers.authorization) {
                        dispatch({
                            type: 'login',
                            user: newUser,
                            jwt: res.headers.authorization.replace('Bearer ','')
                        });

                        actions.resetForm()

                        if(!invite && !trialSignup) {
                            return setConfirmAccount(values.email)
                        }

                        const org = invite?.organization || trialSignup?.organization

                        // TODO: if confirmation needed, show note
                        // Wait for jwt state:
                        setRedirecting(true)

                        setTimeout(()=> {
                            if(org) {
                                api(`/api/organizations/${org.id}/abilities`).then(res => {
                                    sessionDispatch({type: 'set_org', org: org, abilities: res.data});
                                    message.success(t('message-welcome-to-org',`Welcome to {{title}}!`, {title: org.title}));

                                    const path = invite?.collection ? `explore/collections/${invite.collection.slug}` : 'explore'
                                    navigate(useAfterLogin() || `/${org.slug}/${path}#tour`)
                                });
                            } else {
                                navigate('/organizations')
                            }
                        }, 2000)
                    } else {
                        setConfirmAccount(values.email)
                    }
                    console.log(res)
                    actions.setSubmitting(false)
                }).catch((res)=>{
                    message.info(JSON.stringify(res))
                    console.warn(res)
                    actions.setSubmitting(false)
                })
            }}
            validationSchema={
                Yup.object({
                    email: Yup.string()
                        .email().required('Required')
                        .test('checkUniqueEmail',t('email-already-exists','Email already exists'), function(value){
                            if(value === lastEmailCheck) return true;
                            return new Promise((resolve, reject) => {
                                api.post('/api/check_email', {email: value}).then((res)=>{
                                    if(res.data.ok) lastEmailCheck = value;
                                    resolve(res.data.ok)
                                }).catch(() => resolve(false));
                            })
                        }),

                    password: Yup.string().min(8).required('Required'),
                    password_confirmation: Yup.string()
                        .required('Required')
                        .oneOf([Yup.ref('password')], t('password-does-not-match','Password does not match')),

                    default_username: Yup.string().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9_-]+$/, t('username-validation-message','Default Username must be text and numbers only (no special characters or spaces), e.g. "JohnnyQPublic"')).required(t('required','Required')),
                    terms: Yup.bool().required().oneOf([true], t('you-must-accept-terms-of-service','You must accept terms of service.')),
                })
            }
        >
            {({isSubmitting}) => (
                <Form id={'signup-form'}>
                    <FormItem name="email" required showValidateSuccess>
                        <Input
                            autoFocus required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            type='email'
                            autoComplete='email' name="email" placeholder={t('placeholder-email',"Email")}
                            aria-label={'email'}
                        />
                    </FormItem>

                    <FormItem name="default_username" required showValidateSuccess>
                        <Input
                            required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            name="default_username" placeholder={t('placeholder-username',"Username (text and numbers only)")}
                            aria-label={'default_username'}
                        />
                    </FormItem>

                    <FormItem name="first_name" required showValidateSuccess>
                        <Input
                            required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            name="first_name" placeholder={t('placeholder-first-name',"First Name")}
                            aria-label={'first_name'}
                        />
                    </FormItem>

                    <FormItem name="last_name" required showValidateSuccess>
                        <Input
                            required
                            prefix={<UserOutlined style={{opacity:0.5}} />}
                            name="last_name" placeholder={t('placeholder-last-name',"Last Name")}
                            aria-label={'first_name'}
                        />
                    </FormItem>

                    <FormItem name="password" required showValidateSuccess>
                        <Input.Password
                            prefix={<LockOutlined style={{opacity:0.5}}/>}
                            name="password"
                            placeholder={t('placeholder-password',"Password")}
                            aria-label={'password'}
                        />
                    </FormItem>

                    <FormItem name="password_confirmation" required showValidateSuccess>
                        <Input.Password
                            prefix={<LockOutlined style={{opacity:0.5}}/>}
                            name="password_confirmation"
                            placeholder={t('placeholder-password-confirmation',"Password Confirmation")}
                            aria-label={'password_confirmation'}
                        />
                    </FormItem>

                    <FormItem name="terms" required showValidateSuccess>
                        <Checkbox name={'terms'} aria-label={'terms'} id={'terms'}>
                            {t('accept','Accept')} <a href={'https://www.mediagraph.io/terms-of-service'} target={'_blank'}>{t('terms-of-service','Terms of Service')}</a>
                            {/*<a onClick={showTosModal}>Terms of Service</a>?*/}
                        </Checkbox>
                    </FormItem>

                    <Modal
                        open={showTos}
                        title={t('terms-of-service','Terms of Service')}
                        onCancel={()=> setShowTos(false)}
                        footer={null}
                    >
                        <Content code={'tos'}/>
                    </Modal>

                    <FormItem name="submit" style={{marginBottom:0}}>
                        {isSubmitting ? (
                            <Button block type="primary" icon={<PoweroffOutlined />} loading>{t('submitting','Submitting')}...</Button>
                        ) : (
                            <SubmitButton block>{trialSignup ? t('button-submit','Submit') : t('button-join','Join')}</SubmitButton>
                        )}
                    </FormItem>
                </Form>
            )}
        </Formik>)
    );
}