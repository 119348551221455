import {Tooltip} from "antd";
import WarningTwoTone from "@ant-design/icons/lib/icons/WarningTwoTone";
import React from "react";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../helpers/Can";
import {useTranslation} from "react-i18next";

function AssetRequirementsInfo(props) {
    const {t} = useTranslation();
    const ability = useAbility(AbilityContext);
    if(!ability.can('view_details', 'Asset')) return <></>

    return <>
        {!props.asset.submitted && (props.asset.all_requirements_met && (
                <Tooltip title={<>{t('requirements-met','Requirements Met')}<br/>{t('asset-not-submitted','Asset Not Submitted')}</>}>
                    <WarningTwoTone twoToneColor={"#52c41a"}/>
                </Tooltip>
            ) || (
                <Tooltip
                    title={
                        <>
                            {!props.asset.tag_suggesters_met && (
                                <div>{t('tagging-requirements-not-met','Tagging requirements not met')}</div>
                            )}

                            {!props.asset.rights_package_met && (
                                <div>{t('rights-requirements-not-met','Rights requirements not met')}</div>
                            )}

                            {!props.asset.creator_tag_met && (
                                <div>{t('creator-tags-requirements-not-met','Creator tags requirements not met')}</div>
                            )}
                        </>
                    }
                >
                    <WarningTwoTone twoToneColor={"red"}/>
                </Tooltip>
            )
        )}
    </>;
}

export default AssetRequirementsInfo