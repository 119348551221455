import React, {useEffect, useState} from "react";
import setTitle from "../helpers/setTitle";

import {Popover, message, Popconfirm, Skeleton, Space, Table, Tag, Tooltip} from "antd";
import TimeAgo from "../helpers/TimeAgo";
import {Link} from "react-router-dom";
import {EyeOutlined, ClockCircleOutlined, DeleteOutlined, AlertOutlined} from "@ant-design/icons";
import Button from "antd/lib/button";
import api from "../api";
import HelpPopover from "../HelpPopover";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import {LightboxIcon, StorageFolderIcon, UserGroupIcon} from "../helpers/icons";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

export default ()=> {
    const {t} = useTranslation();

    setTitle(t('uploads','Uploads'));

    const [loading, setLoading] = useState(true);
    const [uploads, setUploads] = useState();

    const loadUploads = ()=>{
        api.get(`/api/uploads?per_page=1000`).then(res => {
            setLoading(false);
            setUploads(res.data);
        });
    }

    useEffect(()=>{
        loadUploads()
    }, []);

    const destroy = id => {
        api({
            url: `/api/uploads/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-upload-deleted','Upload Deleted.'));
            loadUploads();
        });
    }

    const columns = [
        {
            title: t('created','Created'),
            render: upload => <TimeAgo date={upload.created_at}/>,
            shouldCellUpdate: () => false,
        },
        // {
        //     title: t('status','Status'),
        //     width:1,
        //     render: upload => {
        //         return upload.done && (
        //             <Tag color={'success'} icon={<CheckCircleOutlined/>}>Done</Tag>
        //         ) || (
        //             <Tag color={'warning'} icon={<AlertOutlined/>}>Pending</Tag>
        //         )
        //     }
        // },
        {
            title: t('name','Name'),
            render: upload => (
                <Tooltip title={`ID: ${upload.id}`}>{upload.name}</Tooltip>
            )
        },
        {
            title: t('contribution','Contribution'),
            render: upload => {
                const name = upload.contribution?.name || <em>Removed</em>
                return (
                    <>
                        {upload.contribution.user_group && <Tooltip title={t('user-group','User Group')}><UserGroupIcon/> {name}</Tooltip>}
                        {upload.contribution.asset_group?.type == 'Lightbox' && <Tooltip title={t('lightbox','Lightbox')}><LightboxIcon/> {name}</Tooltip>}
                        {upload.contribution.asset_group?.type == 'StorageFolder' && <Tooltip title={t('storage-folder','Storage Folder')}><StorageFolderIcon/> {name}</Tooltip>}
                    </>
                )
            }
        },
        {
            title: t('assets','Assets'),
            render: upload => upload.assets_count,
        },
        {
            title: '',
            width: 100,
            render: upload => (
                <Space>
                    <OrgLink to={`/upload/${upload.contribution?.slug}/${upload.guid}`}>
                        <Button icon={<EyeOutlined/>}>{t('button-view','View')}</Button>
                    </OrgLink>

                    {!upload.assets_count && (
                        <Popconfirm
                            title={t('confirm-delete-empty-upload','Delete Empty Upload?')}
                            onConfirm={() => destroy(upload.id)}
                        >
                            <Button size={'middle'}>
                                <DeleteOutlined/>
                            </Button>
                        </Popconfirm>
                    )}
                </Space>

            )
        }
    ]

    return (
        <div style={{margin:'2em'}}>
            <h1><ClockCircleOutlined/> {t('my-past-uploads','My Past Uploads')} <HelpPopover code={'past-uploads-header'}/></h1>
            <Skeleton active loading={loading}>
                <Table
                    scroll={{x:true}}
                    bordered
                    size={'small'}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={uploads}
                />
            </Skeleton>
        </div>
    );
}
