import {Alert, Popover, Progress, Timeline} from "antd";
import React, {useMemo} from "react";
import {useUploadsState} from "../../contexts/UploadsContext";
import TimelineItem from "antd/lib/timeline/TimelineItem";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";

export default ({asset, type, width, style={}})=>{
    // console.log('upload progress render:', asset)
    const {aasm_state} = asset;
    const id = asset.asset_id || asset.id;

    const {uploadingAssets} = useUploadsState();
    let {percent, stats} = _.find(uploadingAssets, {id}) || {};
    if(!percent) percent = 0

    //{
    //     speed: 70343222.003493043, // avgSpeedBytesPerSecond,
    //     readableSpeed: "703 Kb",
    //     loaded: 7034333, // Bytes loaded since the last call. it's for part upload.
    //     totalUploaded: 10024457, // Total bytes uploaded
    //     remainingSize: 20000000,
    //     secondsLeft: 10, //when -1, it's unknown.
    //     fileSize: 30024457
    // }

    const step = _.compact([asset.thumb_url, asset.meta_extracted, asset.grid_url, asset.permalink_url, asset.final_storage]).length
    const pending = ['Generating Thumbnail', 'Extracting MetaData', 'Generating Preview', 'Generating Large', 'Final Storage'][step]
    const stepPercent = asset.type == 'Video' ? 0.5 : step / 5

    return (
        <div style={{backdropFilter: 'blur(1px)', ...style}}>
            {aasm_state == 'pending' && (
                <>
                    <Progress
                        width={width}
                        percent={0}
                        status={null}
                        strokeColor={'grey'}
                        format={()=>(
                            <small>
                                <ClockCircleOutlined/>
                                <br/>
                                Pending
                                <br/>
                                Upload
                            </small>
                        )}
                        type={type || 'circle'}
                    />
                </>
            )}

            {aasm_state == 'uploading' && (
                <>
                    <Progress
                        width={width}
                        percent={(percent * 100).toFixed(1)}
                        status={percent == 1 ? null : 'active'}
                        format={()=>(
                            <>
                                <small>
                                    <CloudUploadOutlined/>
                                </small>
                                <br/>
                                {(percent * 100).toFixed()}%
                                <br/>
                                <small>{stats?.readableSpeed && `${stats?.readableSpeed}\s`}</small>
                            </>
                        )}
                        type={type || 'circle'}
                    />
                </>
            )}

            {(aasm_state == 'processing' || aasm_state == 'uploaded') && (
                <>
                    <Popover
                        title={'Processing'}
                        content={
                            <Timeline pending={pending + '...'}>
                                {asset.thumb_url && <TimelineItem color={'green'}>Thumbnail</TimelineItem>}
                                {asset.meta_extracted && <TimelineItem color={'green'}>Metadata</TimelineItem>}
                                {asset.grid_url && <TimelineItem color={'green'}>Preview</TimelineItem>}
                                {asset.permalink_url && <TimelineItem color={'green'}>Large</TimelineItem>}
                                {asset.final_storage && <TimelineItem color={'green'}>Final Storage</TimelineItem>}
                            </Timeline>
                        }
                    >
                        <Progress
                            width={width}
                            status={stepPercent == 1 ? null : 'active'}
                            percent={(stepPercent * 100).toFixed(1)}
                            strokeColor={Colors.green}
                            type={type || 'circle'}
                            format={()=>(
                                <div style={{color: Colors.green}}>
                                    <small>
                                        <SyncOutlined spin/> Processing
                                    </small>
                                    <br/>
                                    {(stepPercent * 100).toFixed()}%
                                </div>
                            )}
                        />
                    </Popover>
                </>
            )}

            {aasm_state == 'processed' && (
                <small>
                    Processed in {asset.time_to_process?.toFixed(2)}s
                </small>

            )}

            {aasm_state == 'processing_error' && (
                <Popover title='Processing Error' content={asset.processing_error} overlayStyle={{maxWidth:400}}>
                    <Alert type={'error'} showIcon message={<>Processing Error</>}/>
                </Popover>
            )}
        </div>
    )
}