import React, {useEffect, useState} from "react";

import moment from 'moment-timezone';
import 'moment/locale/fr'

import {Tooltip} from "antd";
import {useTimezone} from "./useCurrentUser";
import {useTranslation} from "react-i18next";

const TimeAgo = ({date}) => {
    const {i18n} = useTranslation();

    const [timer, setTimer] = useState();

    const timezone = useTimezone()

    moment.locale(i18n.language)

    useEffect(()=>{
        const interval = setInterval(() => setTimer(Date.now()), 1000)
        return ()=> clearInterval(interval);
    },[])

    if(!date) return <></>

    return (
        <Tooltip refresh={timer} title={moment.tz(date, timezone).format('LLLL z')}>
            <span style={{borderBottom: '1px dotted #807f80', cursor:'help'}} className={'time-ago'}>{moment(date).fromNow()}</span>
        </Tooltip>
    )
}

export default TimeAgo;
