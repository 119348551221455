import React, {useEffect} from 'react';

import { withRouter } from "react-router-dom";

import { Layout, Menu, Breadcrumb, Input } from 'antd';
const { Content } = Layout;

import AppHeader from './AppHeader';
import LegalFooter from "./widgets/LegalFooter";
import useCurrentOrg from "./helpers/useCurrentOrg";

const HomeLayout = withRouter(props => {
    const currentOrg = useCurrentOrg()

    useEffect(()=>{
        document.body.style.backgroundColor = currentOrg?.header_background_color
    }, [currentOrg?.id])

    return (
        <Layout style={{backgroundColor: currentOrg?.header_background_color}}>
            <AppHeader/>
            <Layout>
                <Content
                    style={{
                        padding:0,
                        margin: 0,
                        minHeight: 280,
                        backgroundColor: currentOrg?.header_background_color,
                    }}
                >
                    {props.children}

                    <LegalFooter noDivider orgColors/>
                </Content>
            </Layout>
        </Layout>
    );
});

export default HomeLayout;
