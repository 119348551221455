import React, {useState} from "react";
import {Button, Drawer, Menu, Tabs, Input, Space} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = Input;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('user-groups','User Groups'));

    const [drawerVisible, setDrawerVisible] = useState();

    const onClick = ()=> {
        setDrawerVisible(true)
        window.location.hash = 'new'
    }

    const onClose = ()=> {
        history.replaceState(null, null, ' ');
        setDrawerVisible(false)
    }

    const [newGroup, setNewGroup] = useState();

    const onSave = (group)=> {
        history.replaceState(null, null, ' ');
        onClose()
        console.log('onSave', group);
        setNewGroup(group);
    }

    if(window.location.hash == '#new' && !drawerVisible) {
        setDrawerVisible(true);
    }

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-user-groups','Search Groups...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'UserGroup'}>
                    <Button type='primary' onClick={onClick}>
                        <PlusOutlined/>
                        {t('button-add-new-user-group','Add New User Group')}
                    </Button>
                </Can>
            </Space>

            <GroupForm
                onClose={onClose}
                onSave={onSave}
                visible={drawerVisible}
            />
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}
            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t('about',"About")} key="about">
                    <Content code={'about-groups'}/>
                </TabPane>
                <TabPane tab={t('browse-user-groups',"Browse User Groups")} key="browse">
                    <GroupsTable newGroup={newGroup} q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
            </Tabs>
        </>
    );
}