import React, {useContext, useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select} from "formik-antd";
import * as Yup from "yup";

import {
    Badge,
    Collapse,
    Divider,
    Drawer,
    List,
    message,
    Modal,
    Space,
    Tag,
    Tooltip,
    Typography
} from 'antd';
const { Panel } = Collapse;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";

import HelpPopover from "../HelpPopover";

import {
    Row,
    Col,
    Button,
    Card,
} from 'antd';

import {
    NotificationOutlined,
    SaveOutlined,
    CloudUploadOutlined,
    HddOutlined,
    EyeOutlined,
    UserAddOutlined,
    FolderOpenOutlined,
    TeamOutlined,
    FileProtectOutlined,
    DeploymentUnitOutlined, DeleteOutlined, CheckOutlined, SettingOutlined, BulbOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import UserChooser from './UserChooser';
import api from "../api";

import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import AssetGroupChooser from "./AssetGroupChooser";
import FloatLabel from "../helpers/FloatLabel";
import PermissionForm from "./PermissionForm";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {CollectionIcon, LibraryIcon, PermissionsIcon, RightsIcon, StorageFolderIcon} from "../helpers/icons";
import {Can, AbilityContext} from "../helpers/Can";
import ContributionDetailsForm from "./ContributionDetailsForm";
import {SessionContext} from "../../contexts/SessionContext";
import OrgNavLink, {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

export default ({id, onSave, visible, onClose}) => {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const navigate = useNavigate();

    if(!id) {
        const { id } = useParams();
    }

    const [userGroup, setUserGroup] = useState({home_collection_children_inherit: true});

    const ability = useContext(AbilityContext);

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`/api/user_groups/${path}`).then((res) => {
            setUserGroup(res.data);
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    let modal = null;
    const onCloseDirty =(dirty)=> {
        // FIXME: calling onClose directly has no effect
        if(modal) return;

        modal = Modal.confirm({
            title: t('cancel-edit','Cancel edit?'),
            icon: <ExclamationCircleOutlined />,
            content: dirty ? t('you-have-unsaved-changes','You have unsaved changes.') : '',
            onOk: onClose,
            onCancel: () => { modal = null },
            cancelText: t('no','No'),
            okText: t('yes','Yes')
        })
    }

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    const getPath = useOrgPath()

    return (
        (<Formik
            initialValues={userGroup}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                if(values.enable_contribution && !values.enable_storage_folder) {
                    message.error(t('error-must-enable-group-storage-folder-for-contributions','You must enable a Group Storage Folder to enable Contributions.'))
                    actions.setSubmitting(false)
                    return
                }

                api({
                    method: id ? 'put' : 'post',
                    url: id ? `/api/user_groups/${id}` : `/api/user_groups`,
                    data: { user_group: values }
                }).then((res)=>{
                    console.log('id',id);
                    if(!id) navigate(getPath(`/manage/groups`))
                    actions.setSubmitting(false)
                    message.success(id ? t('entity-updated', '{{name}} has been updated!', {name: values.name}) : t('entity-created', '{{name}} has been created!', {name: values.name}))
                    setUserGroup(res.data);

                    actions.resetForm({});

                    onSave && onSave(res.data);

                }).catch((error)=>{
                    console.error(error.data)
                    message.error(`Error: ${JSON.stringify(error.data)}`)
                    actions.setSubmitting(false)
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(t('required','Required')),
                })
            }
        >
            {({setFieldValue, values, submitForm, dirty}) => {

                const [quickSetupType, setQuickSetupType] = useState()
                const onClickSetup = type =>{
                    if(!values.name.length) {
                        autoFocusInput.current?.focus()
                        return message.warning(t('warning-please-add-name-first','Please add a name first.'))
                    }

                    setQuickSetupType(type)

                    setFieldValue('permissions_attributes.0.enabled', true) // view only small WM
                    setFieldValue('permissions_attributes.1.enabled', false)
                    setFieldValue('permissions_attributes.2.enabled', true) // view only full size
                    setFieldValue('permissions_attributes.3.enabled', false)
                    setFieldValue('permissions_attributes.4.enabled', false)
                    setFieldValue('permissions_attributes.5.enabled', true) // view / DL original

                    setFieldValue('enable_home_collection', true)
                    setFieldValue('home_collection_permission_ids', ['index-2'])

                    if(type == 'basic') {
                        setFieldValue('enable_library_managers', false)
                        setFieldValue('enable_taggers', false)
                        setFieldValue('enable_content_managers', false)
                        setFieldValue('enable_storage_folder', false)
                        setFieldValue('enable_sandbox_collection', false)
                        setFieldValue('enable_contribution', false)

                    } else {
                        setFieldValue('enable_library_managers', true)
                        setFieldValue('enable_taggers', true)
                        setFieldValue('enable_content_managers', true)
                        setFieldValue('enable_storage_folder', true)
                        setFieldValue('enable_sandbox_collection', true)

                        setFieldValue('home_collection_admin_role_level', 'library')

                        if(type == 'full-uploads') {
                            setFieldValue('enable_contribution', true)
                            setFieldValue('auto_approve', true)
                            setFieldValue('contribution_attributes.tag_names', [values.name])
                        } else {
                            setFieldValue('enable_contribution', false)
                        }
                    }
                }

                const [selectedCollection, setSelectedCollection] = useState();

                const selectCollection = (node)=> {
                    setFieldValue(
                        'collection_permission_ids',
                        collectionPermissionIds[node.id] || node.permission_ids
                    );

                    console.log('selectCollection', node)

                    const aga = _.filter(node.asset_group_admins, {'user_group_id': values.id})[0];

                    // for editing in form element:

                    setFieldValue(
                        'collection_admin_role_level',
                        collectionAdmins[node.id]?.role_level || aga?.role_level || 'none'
                    );

                    // TODO: initialize as true
                    setFieldValue(
                        'collection_children_inherit',
                        collectionAdmins[node.id]?.children_inherit || aga?.children_inherit
                    );

                    setSelectedCollection(node);
                }

                const [collectionPermissionIds, setCollectionPermissionIds] = useState({});

                // {<collection-id>: [<perm-id-1>,<perm-id-2>, ...], ...}
                const setSelectCollectionPermissionIds = (ids) => {
                    // TODO: update node's collection_permission_ids value locally when changing
                    selectedCollection.permission_ids = ids;

                    collectionPermissionIds[selectedCollection.id] = ids;
                    setCollectionPermissionIds({...collectionPermissionIds});
                    setFieldValue('new_collection_permission_ids', collectionPermissionIds);
                }

                const [collectionAdmins, setCollectionAdmins] = useState({});

                const setSelectedCollectionAdminRoleLevel = (e) => {
                    setSelectedCollectionAdmin('role_level', e.target.value);
                }

                const setSelectedCollectionAdminChildrenInherit = (e) => {
                    setSelectedCollectionAdmin('children_inherit', e.target.checked);
                }

                const setSelectedCollectionAdmin = (field, value) => {
                    let data = collectionAdmins[selectedCollection.id] ||
                        _.filter(selectedCollection.asset_group_admins, {user_group_id: userGroup.id})[0] || {};

                    data[field] = value;
                    collectionAdmins[selectedCollection.id] = {...data};

                    setCollectionAdmins({...collectionAdmins});
                    console.log('collection_admins', collectionAdmins)
                    setFieldValue('new_collection_admins', collectionAdmins);
                }

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = '';

                const toggleContributions = e =>{
                    if(e.target.checked && !values.enable_storage_folder) {
                        setFieldValue('enable_storage_folder', true)
                    }
                }

                const onSandboxChange = e => {
                    if(e.target.checked && !values.enable_sandbox_collection) {
                        setFieldValue('enable_sandbox_collection', true)
                    }
                }

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <DeploymentUnitOutlined/> {id ? t('editing','Editing') : t('add-new','Add New')} {t('user-group','User Group')}

                                    <HelpPopover code='user-group-form-help'/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        {/*<Button>*/}
                                        {/*    <EyeOutlined/>*/}
                                        {/*    Preview Library Access*/}
                                        {/*</Button>*/}

                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <SaveOutlined/>
                                            {id ? t('update','Update') : t('save','Save')} {t('user-group','User Group')}
                                        </SubmitButton>

                                        {/*<SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>*/}
                                        {/*    <SaveOutlined/>*/}
                                        {/*    {id ? 'Update' : 'Save'} Group*/}
                                        {/*    Group and Notify*/}
                                        {/*    <NotificationOutlined/>*/}
                                        {/*</SubmitButton>*/}

                                    </Space>
                                </div>
                            }
                            width={width}
                            bodyStyle={{padding: isMobile() ? '.5em' : '1em'}}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            // destroyOnClose={true}
                            // maskClosable={false}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical' id={'group-form'}>
                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-user-group-name','e.g. Marketing')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off' id={'group-name-input'}/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('placeholder--user-group-description','Describe the purpose of the Group. Optional.')}>
                                        <Input.TextArea rows={2} name='description'/>
                                    </FloatLabel>
                                </FormItem>

                                {!id && (
                                    <div style={{margin:'1em 0'}}>
                                        <Divider><BulbOutlined/> {t('quick-setup','Quick Setup')}</Divider>
                                        <Space direction={'vertical'}>
                                            <div>
                                                <Button onClick={()=> onClickSetup('basic')} icon={quickSetupType == 'basic' && <CheckOutlined/>}>{t('basic-settings','Basic settings')}</Button> <HelpPopover code={'group-form-setup-basic'}/>
                                            </div>
                                            <div>
                                                <Button onClick={()=> onClickSetup('full')} icon={quickSetupType == 'full' && <CheckOutlined/>}>{t('full-feature-settings','Full-feature settings')}&nbsp;<em>{t('without','without')}</em>&nbsp;{t('uploads','Uploads')}</Button> <HelpPopover code={'group-form-setup-full-no-upload'}/>
                                            </div>
                                            <div>
                                                <Button onClick={()=> onClickSetup('full-uploads')} icon={quickSetupType == 'full-uploads' && <CheckOutlined/>}>{t('full-feature-settings','Full-feature settings')}&nbsp;<em>{t('with','with')}</em>&nbsp;{t('uploads','Uploads')}</Button> <HelpPopover code={'group-form-setup-full-with-upload'}/>
                                            </div>
                                        </Space>
                                        <Divider><SettingOutlined/> {t('all-settings','All Settings')}</Divider>
                                    </div>

                                )}

                                <Collapse defaultActiveKey={defaultActiveKey}>
                                    {!userGroup || !userGroup.auto_group && (
                                        <Panel
                                            header={
                                                <>
                                                    <TeamOutlined style={{marginRight:'1em'}}/>{id ? t('edit','Edit') : t('add','Add')} {t('members','Members')}
                                                    <Badge count={values.membership_ids?.length} style={{margin:'0 .5em', backgroundColor:'#bbb'}}/>
                                                    <HelpPopover code='user-group-form-add-members'/>
                                                </>
                                            }
                                            key="members"
                                        >
                                            {!userGroup.id && (
                                                <FormItem name={'create_test_member'}>
                                                    <Checkbox name='create_test_member'>{t('create-test-member','Create Tester Member')}</Checkbox>
                                                </FormItem>
                                            )}

                                            <Collapse defaultActiveKey={['existing']} bordered={true} accordion>
                                                <Panel header={t('select-from-existing','Select from Existing')} key='existing'>
                                                    <UserChooser edit={!!id}/>
                                                </Panel>

                                                <Panel header={t('invite-new','Invite New')} key='invite'>
                                                    {!!values.invites_attributes?.length && (
                                                        <FieldArray
                                                            name={'invites_attributes'}
                                                            render={arrayHelpers => (
                                                                <List
                                                                    bordered
                                                                    size={'small'}
                                                                    header={<strong>{t('pending-invites','Pending Invites')}</strong>}
                                                                    style={{marginBottom:'1em'}}
                                                                >
                                                                    {values.invites_attributes.map((invite,i) => (
                                                                        <List.Item
                                                                            key={i}
                                                                            extra={
                                                                                <div style={{float: 'right'}}>
                                                                                    {invite.id ? (
                                                                                        <>
                                                                                            <Input
                                                                                                name={`invites_attributes[${i}].id`}
                                                                                                style={{display: 'none'}}
                                                                                            />
                                                                                            <Checkbox
                                                                                                name={`invites_attributes[${i}]._destroy`}>
                                                                                                <Typography.Text type="danger">
                                                                                                    <DeleteOutlined/>
                                                                                                </Typography.Text>
                                                                                            </Checkbox>
                                                                                        </>
                                                                                    ) : (
                                                                                        <Button type={'text'} onClick={() => arrayHelpers.remove(i)}>
                                                                                            <Typography.Text type="danger">
                                                                                                <DeleteOutlined/>
                                                                                            </Typography.Text>
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            {invite.email}
                                                                        </List.Item>
                                                                    ))}
                                                                </List>
                                                            )}
                                                        />
                                                    )}

                                                    <FormItem name='invite_emails' label={t('invite-by-email-address','Invite by Email Address')} extra={t('invite-by-email-address-note','Type in email addresses separated by a semicolon.')}>
                                                        <Input.TextArea name='invite_emails' rows={2} />
                                                    </FormItem>

                                                    <FormItem name='invite_note' label={t('invite-note','Add extra text for the email they will receive.')}>
                                                        <Input.TextArea name='invite_note' rows={2} />
                                                    </FormItem>

                                                    {/*<FormItem name='invite_permission_level' label={'Add Permission Level'}>*/}
                                                    {/*    <Radio.Group name='invite_permission_level'>*/}
                                                    {/*        <Radio value={'add'}>Add to Group</Radio>*/}
                                                    {/*        <Radio value={'tagger'}>Tagger</Radio>*/}
                                                    {/*        <Radio value={'library'}>Library Manager</Radio>*/}
                                                    {/*        <Radio value={'content'}>Content Manager</Radio>*/}
                                                    {/*    </Radio.Group>*/}
                                                    {/*</FormItem>*/}
                                                </Panel>

                                                <Panel header={<>{t('add-by-domain','Add by Domain')} <HelpPopover code='user-group-form-add-by-domain'/></>} key='domain'>
                                                    <FormItem name='invite_domain' label={t('domain-name','Domain Name')} extra={t('domain-name-note','Separated by comma or semicolon.')}>
                                                        <Input name='invite_domain' placeholder={t('placeholder-invite-domain','e.g. mediagraph.io; example.com')}/>
                                                    </FormItem>
                                                </Panel>

                                                {currentOrg?.enable_sso && (
                                                    <Panel header={<>{t('add-by-sso-group','Add by SSO Group')} <HelpPopover code='user-group-form-add-by-sso-group'/></>} key='sso'>
                                                        <FormItem name='sso_name' label={t('sso-name','SSO Name')}>
                                                            <Select mode={'tags'} name={'sso_name'}/>
                                                        </FormItem>
                                                    </Panel>
                                                )}

                                                {/*<Panel header={<>Open Group <HelpPopover code='user-group-form-open-group'/></>} key='open'>*/}
                                                {/*    <em>Coming Soon...</em>*/}
                                                {/*    <Checkbox name='is_open'>Make this an Open Group</Checkbox>*/}
                                                {/*</Panel>*/}
                                            </Collapse>
                                        </Panel>
                                    )}

                                    <Panel
                                        key="permissions"
                                        header={
                                            <>
                                                <PermissionsIcon style={{marginRight:'1em'}}/> {t('enable-member-permissions','Enable Member Permissions')}
                                                <Badge count={values.permissions_attributes?.filter(p => p.enabled)?.length} style={{margin:'0 .5em', backgroundColor:'#bbb'}}/>
                                                <HelpPopover code='user-group-form-portal-permissions'/>
                                            </>
                                        }
                                    >
                                        <PermissionForm values={values}/>
                                    </Panel>

                                    <Panel
                                        key="admins"
                                        header={
                                            <>
                                                <UserAddOutlined style={{marginRight:'1em'}}/>{t('enable-group-managers','Enable Group Managers')}
                                                <EnabledDisabled enabled={values.enable_content_mangers || values.enable_library_managers || values.enable_taggers}/>
                                                <Badge
                                                    count={(values.content_manager_ids?.length || 0) + (values.library_manager_ids?.length || 0) + (values.tagger_ids?.length || 0)}
                                                    style={{margin:'0 .5em', backgroundColor:'#bbb'}}
                                                />
                                                <HelpPopover code='user-group-form-add-admins'/>
                                            </>
                                        }
                                    >
                                        <Collapse defaultActiveKey={[]} accordion>
                                            <Panel
                                                style={{display: ability.can('enable_content_manager', 'UserGroup') ? 'auto' : 'none'}}
                                                key={'content'}
                                                disabled={!currentOrg?.plan_has_group_content_managers}
                                                header={
                                                    <Tooltip
                                                        title={
                                                            !currentOrg?.plan_has_group_content_managers &&
                                                                <em>{t('group-content-mamagers-note','Group Content Managers are available for')} <OrgNavLink to={'/manage/billing'}>{t('workgroup-and-enterprise-plan-levels','Workgroup and Enterprise plan levels')}</OrgNavLink>.</em>
                                                        }
                                                    >
                                                        {values.name || t('group','Group')} - {t('enable-content-manager','Enable Content Manager')}
                                                        <EnabledDisabled enabled={values.enable_content_managers}/>
                                                        <Badge count={values.content_manager_ids?.length} style={{margin:'0 .5em', backgroundColor:'#bbb'}}/>
                                                    </Tooltip>
                                                }
                                            >
                                                <>
                                                    <Checkbox name='enable_content_managers' style={{marginBottom:'1em'}}>
                                                        {t('enable','Enable')} {userGroup?.name || t('group','Group')} {t('content-manager','Content Manager')}
                                                        <HelpPopover code='user-group-form-content-managers'/>
                                                    </Checkbox>

                                                    {!userGroup.id && values.enable_content_managers && (
                                                        <FormItem name={'create_test_content_manager'}>
                                                            <Checkbox name='create_test_content_manager'>{t('create-tester-content-manager','Create Tester Content Manager')}</Checkbox>
                                                        </FormItem>
                                                    )}

                                                    {values.enable_content_managers && <UserChooser fieldName='content_manager_ids' edit={!!id}/>}
                                                </>
                                            </Panel>

                                            <Panel
                                                key={'library'}
                                                header={
                                                    <>
                                                        {values.name || t('group','Group')} - {t('enable-library-manager','Enable Library Manager')}
                                                        <EnabledDisabled enabled={values.enable_library_managers}/>
                                                        <Badge count={values.library_manager_ids?.length} style={{margin:'0 .5em', backgroundColor:'#bbb'}}/>
                                                    </>
                                                }
                                            >
                                                <Checkbox name='enable_library_managers' style={{marginBottom:'1em'}}>
                                                    {t('enable','Enable')} {userGroup?.name || t('group','Group')} {t('library-manager','Library Manager')}
                                                    <HelpPopover code='user-group-form-library-managers'/>
                                                </Checkbox>

                                                {!userGroup.id && values.enable_library_managers && (
                                                    <FormItem name={'create_test_library_manager'}>
                                                        <Checkbox name='create_test_library_manager'>{t('create-tester-library-manager','Create Tester Library Manager')}</Checkbox>
                                                    </FormItem>
                                                )}

                                                {values.enable_library_managers && <UserChooser fieldName='library_manager_ids' edit={!!id}/>}

                                            </Panel>
                                            <Panel
                                                key={'tag'}
                                                header={
                                                    <>
                                                        {values.name || t('group','Group')} - {t('enable-tagger','Enable Tagger')}
                                                        <EnabledDisabled enabled={values.enable_taggers}/>
                                                        <Badge count={values.tagger_ids?.length} style={{margin:'0 .5em', backgroundColor:'#bbb'}}/>
                                                    </>
                                                }
                                            >
                                                <Checkbox name='enable_taggers' style={{marginBottom:'1em'}}>
                                                    {t('enable','Enable')} {userGroup?.name || t('group','Group')} {t('taggers','Taggers')}
                                                    <HelpPopover code='user-group-form-taggers'/>
                                                </Checkbox>

                                                {!userGroup.id && values.enable_taggers && (
                                                    <FormItem name={'create_test_tagger'}>
                                                        <Checkbox name='create_test_tagger'>{t('create-tester-tagger','Create Tester Tagger')}</Checkbox>
                                                    </FormItem>
                                                )}

                                                {values.enable_taggers && <UserChooser fieldName='tagger_ids' edit={!!id}/>}

                                            </Panel>
                                        </Collapse>
                                    </Panel>

                                    <Panel
                                        header={<><LibraryIcon style={{marginRight:'1em'}}/>{t('set-group-library','Set Group Library')} <EnabledDisabled enabled={values.enable_home_collection}/> <HelpPopover code='user-group-form-home-collection'/></>}
                                        key="collections"
                                        id={'user-group-library-panel'}
                                    >
                                        <Checkbox name={'enable_home_collection'}>{t('set-group-library-for-this-group','Set a Group Library for this Group')}</Checkbox>

                                        {values.enable_home_collection && !values.collection_id && (
                                            <h5 style={{marginTop:'1em'}}>
                                                <CollectionIcon/> {`${t('collections','Collections')} / ${values.name || `(${t('name','Name')})`} ${t('library','Library')} / ${t('home','Home')}`}
                                            </h5>
                                        )}

                                        {values.enable_home_collection &&
                                            <Row gutter={16} style={{marginTop:'1em'}}>
                                                {values.id &&
                                                    <Col span={12}>
                                                        <Card size='small' title={t('select-a-home-collection','Select a Home Collection')} bodyStyle={{height: '100%', overflow: 'scroll'}}>
                                                            {userGroup && (
                                                                <AssetGroupChooser
                                                                    type={'collections'}
                                                                    fieldName={'collection_id'}
                                                                    current={userGroup.collection}
                                                                    organizerSelection
                                                                />
                                                            )}
                                                        </Card>
                                                    </Col>
                                                }

                                                <Col span={12}>
                                                    <Card size='small'>
                                                        <h4><strong>{t('apply-member-permissions','Apply Member Permissions')}:</strong></h4>

                                                        <Checkbox.Group
                                                            name={'home_collection_permission_ids'}
                                                        >
                                                            <Space direction={'vertical'} size={1}>
                                                                {values.permissions_attributes?.filter(p => p.enabled)?.map((perm, i)=> (
                                                                    <Checkbox key={i} value={perm.id || `index-${i}`}>{values.name} - {perm.name}</Checkbox>
                                                                ))}
                                                            </Space>

                                                        </Checkbox.Group>

                                                        <Divider/>

                                                        <h4><strong>{t('apply-group-manager-permissions','Apply Group Manager Permissions')}:</strong></h4>

                                                        <Radio.Group
                                                            rootClassName={'vertical-checkbox-group'}
                                                            name={'home_collection_admin_role_level'}
                                                        >
                                                            <Radio value={'none'}>{t('none','None')}.</Radio>
                                                            <Radio value={'tagger'} disabled={!values.enable_taggers}>{values.name} - {t('tagging-only','Tagging Only')}</Radio>
                                                            <Radio value={'library'} disabled={!(values.enable_library_managers || values.enable_content_managers)}>{values.name} - {t('manage-library','Manage Library')}</Radio>
                                                        </Radio.Group>
                                                        <Divider/>
                                                        <Checkbox name='home_collection_children_inherit'>
                                                            {t('child-collections-inherit-all-parent-permissions','Child Collections inherit all parent permissions')}
                                                        </Checkbox>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        }
                                        {/*<Panel header={<>Existing Collection Access <HelpPopover code='user-group-form-existing-collections'/></>} key='existing'>*/}
                                        {/*    <Row gutter={16}>*/}
                                        {/*        <Col span={12}>*/}
                                        {/*            <Card size='small' title={'Select Collection'}>*/}
                                        {/*                {userGroup && (*/}
                                        {/*                    <AssetGroupChooser*/}
                                        {/*                        organizerSelection*/}
                                        {/*                        type={'collections'}*/}
                                        {/*                        setSelectedGroup={selectCollection}*/}
                                        {/*                        fieldName={'selected_collection_id'}*/}
                                        {/*                    />*/}
                                        {/*                )}*/}
                                        {/*            </Card>*/}
                                        {/*        </Col>*/}

                                        {/*        {selectedCollection &&*/}
                                        {/*            <Col span={12}>*/}
                                        {/*                <Card size={'small'} title={`Collection: ${selectedCollection.name}`}>*/}
                                        {/*                    <h4><strong>Apply Member Permissions:</strong></h4>*/}
                                        {/*                    <Ceckbox.Group*/}
                                        {/*                        className={'vertical-checkbox-group'}*/}
                                        {/*                        name={`collection_permission_ids`}*/}
                                        {/*                        options={values.permissions_attributes && _.compact(values.permissions_attributes.map((perm,i) => {return perm.enabled ? {label: `${values.name} - ${perm.name}`, value: perm.id || `index-${i}`} : null}))}*/}
                                        {/*                        onChange={setSelectCollectionPermissionIds}*/}
                                        {/*                    />*/}
                                        {/*                    <Divider/>*/}

                                        {/*                    <h4><strong>Apply Group Manager Permissions:</strong></h4>*/}

                                        {/*                    <Radio.Group*/}
                                        {/*                        className={'vertical-checkbox-group'}*/}
                                        {/*                        name={'collection_admin_role_level'}*/}
                                        {/*                        onChange={setSelectedCollectionAdminRoleLevel}*/}
                                        {/*                    >*/}
                                        {/*                        <Radio value={'none'}>None.</Radio>*/}
                                        {/*                        <Radio value={'tagger'} disabled={!values.enable_taggers}>{values.name} - Tagging Only</Radio>*/}
                                        {/*                        <Radio value={'library'} disabled={!(values.enable_library_managers || values.enable_content_managers)}>{values.name} - Manage Library</Radio>*/}
                                        {/*                    </Radio.Group>*/}
                                        {/*                    <Divider/>*/}
                                        {/*                    <Checkbox*/}
                                        {/*                        name='collection_children_inherit'*/}
                                        {/*                        onChange={setSelectedCollectionAdminChildrenInherit}*/}
                                        {/*                    >*/}
                                        {/*                        Child Collections inherit all parent permissions*/}
                                        {/*                    </Checkbox>*/}
                                        {/*                </Card>*/}
                                        {/*            </Col>*/}
                                        {/*        }*/}
                                        {/*    </Row>*/}
                                        {/*</Panel>*/}
                                    </Panel>
                                    <Panel
                                        id={'user-group-storage-folder-panel'}
                                        style={{display: ability.can('create', 'StorageFolder') ? 'auto' : 'none'}}
                                        header={
                                            <>
                                                <StorageFolderIcon style={{marginRight:'1em'}}/>
                                                {id ? t('edit','Edit') : t('add','Add')} {t('storage-folders','Storage Folders')}
                                                <EnabledDisabled enabled={values.enable_storage_folder}/>
                                                <HelpPopover code='user-group-form-storage-folder' />
                                            </>
                                        }
                                        key="folders">
                                        <Collapse defaultActiveKey={[]}>
                                            <Panel id='user-group-create-storage-folder-panel' key={'create'} header={
                                                <>
                                                    {t('create-group-storage-folder','Create Group Storage Folder')}
                                                    <EnabledDisabled enabled={values.enable_storage_folder}/>
                                                    <HelpPopover code='user-group-form-create-storage-folder'/>
                                                </>
                                            }>
                                                <Checkbox name={'enable_storage_folder'}>{t('enable-dedicated-storage-folder-for-this-group','Enable dedicated Storage Folder for this Group')}</Checkbox>
                                                {values.enable_storage_folder && !values.storage_folder_id &&
                                                <h5 style={{marginTop:'1em'}}>
                                                    <StorageFolderIcon/> {` ${t('storage-folders','Storage Folders')} / ${values.name || t('add-name','Add Name...')} ${t('storage','Storage')} / ${t('group-uploads', 'Group Uploads')}`}
                                                </h5>
                                                }

                                                {values.enable_storage_folder && values.storage_folder_id && (
                                                    <Row style={{marginBottom:'1em'}} gutter={16}>
                                                        <Col span={12}>
                                                            <Card title={t('choose-a-folder','Choose a Folder')} style={{marginTop:'1em'}} size={'small'}>
                                                                {userGroup && (
                                                                    <AssetGroupChooser
                                                                        type={'storage_folders'}
                                                                        fieldName={'storage_folder_id'}
                                                                        current={userGroup.storage_folder}
                                                                    />
                                                                )}
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Panel>

                                            <Panel
                                                key={'sandbox'}
                                                header={
                                                    <>
                                                        {t('create-a-group-sandbox','Create a Group Sandbox')}
                                                        <EnabledDisabled enabled={values.enable_sandbox_collection}/>
                                                        <HelpPopover code='user-group-form-sandbox-collection'/>
                                                    </>
                                                }
                                            >
                                                <Checkbox name={'enable_sandbox_collection'}>{t('enable-group-sandbox','Enable group Sandbox')}</Checkbox>

                                                {values.enable_sandbox_collection && !values.sandbox_collection_id && (
                                                    <h5 style={{marginTop:'1em'}}>
                                                        <CollectionIcon/> {` ${t('collections','Collections')} / ${t('sandboxes','Sandboxes')} / ${values.name || `(${t('name','Name')})`} ${t('sandbox','Sandbox')}`}
                                                    </h5>
                                                )}

                                                <>
                                                    <br/>
                                                    <br/>
                                                    <Row gutter={16}>
                                                        {values.enable_sandbox_collection && values.sandbox_collection_id && (
                                                            <Col span={12}>
                                                                <Card size='small' title={t('select-sandbox-collection','Select a Sandbox Collection')}
                                                                      bodyStyle={{height: 200, overflow: 'scroll'}}>
                                                                    {userGroup && (
                                                                        <AssetGroupChooser
                                                                            type={'collections'}
                                                                            fieldName={'sandbox_collection_id'}
                                                                            current={userGroup?.sandbox_collection}
                                                                            reset={userGroup}
                                                                            sandbox
                                                                        />
                                                                    )}
                                                                </Card>
                                                            </Col>
                                                        )}
                                                        {values.enable_sandbox_collection && (
                                                            <Col span={12}>
                                                                <h4><strong>{t('apply-group-manager-permissions','Apply Group Manager Permissions')}:</strong></h4>

                                                                <Radio.Group
                                                                    rootClassName={'vertical-checkbox-group'}
                                                                    name={'sandbox_collection_admin_role_level'}
                                                                >
                                                                    <Radio value={'none'}>None.</Radio>
                                                                    <Radio value={'tagger'} disabled={!values.enable_taggers}>{values.name} - {t('tagging-only','Tagging Only')}</Radio>
                                                                    <Radio value={'library'} disabled={!(values.enable_library_managers || values.enable_content_managers)}>{values.name} - {t('manage-library','Manage Library')}</Radio>
                                                                </Radio.Group>
                                                                <Divider/>
                                                                <Checkbox name='sandbox_collection_children_inherit' disabled>
                                                                    {t('child-collections-inherit-all-parent-permissions','Child Collections inherit all parent permissions')}
                                                                </Checkbox>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </>
                                            </Panel>
                                        </Collapse>
                                    </Panel>

                                    <Panel
                                        id={'group-form-contributors-panel'}
                                        style={{display: ability.can('create', 'StorageFolder') ? 'auto' : 'none'}}
                                        header={<><CloudUploadOutlined style={{marginRight:'1em'}}/>{t('add-contributors','Add Contributors')} <EnabledDisabled enabled={values.enable_contribution}/></>}
                                        key="contributors"
                                    >
                                        <Checkbox name={'enable_contribution'} style={{marginBottom:'1em'}} onChange={toggleContributions}>
                                            {t('enable-contributions-for-all-group-members','Enable Contributions for all Group Members')}
                                        </Checkbox>

                                        <ContributionDetailsForm
                                            disabled={!values?.enable_contribution}
                                            fieldName={'contribution_attributes'}
                                            includeWorkflowPanel
                                            onSandboxChange={onSandboxChange}
                                        />

                                    </Panel>
                                </Collapse>

                                {/*For Enter btn submit:*/}
                                <SubmitButton style={{display:'none'}}/>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

