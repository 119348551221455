import React from "react";

import {useCurrentAssetsDispatch, useCurrentAssetsState} from "../../contexts/CurrentAssetContext";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";

const useSetCurrentAsset = ()=>{
    const currentAssetDispatch = useCurrentAssetsDispatch()

    const setCursorAsset = useSetCursorAsset()

    return (asset)=> {
        if(asset?.id) setCursorAsset(asset)
        currentAssetDispatch({type:'setCurrentAsset', asset});
    }
}

const useSetCursorAsset = ()=>{
    const currentAssetDispatch = useCurrentAssetsDispatch()

    return (asset)=> {
        currentAssetDispatch({type:'setCursorAsset', asset});

        // Ensure Asset is in view:
        setTimeout(()=>{
            const el = document.getElementById(`asset-cover-${asset?.id}`)
            if(el){
                const rect = el.getBoundingClientRect()

                if (rect.bottom > window.innerHeight + el.height) {
                    el.scrollIntoView(true);

                } else if (rect.top < 0) {
                    el.scrollIntoView(true);
                }
            }
        }, 100)
    }
}

export default useSetCurrentAsset

const useCurrentAssetCursor = () => {
    const {assetIds} = useLoadedAssetsState()
    const {currentAssetId, cursorAssetId} = useCurrentAssetsState()

    const setCurrentAsset = useSetCurrentAsset()
    const setCursorAsset = useSetCursorAsset()

    const {selectedAssetIds} = useSelectedAssetsState()
    const selectedAssetDispatch = useSelectedAssetsDispatch()

    const {coords} = useAggsState()
    const coordIds = _.map(coords, 'id')

    const ids = coordIds.length ? coordIds : assetIds

    let i, id;
    return (direction, select=false) => {
        window.lastCursorChange = new Date()

        // console.log('currentAssetCursor:', direction, cursorAssetId, currentAssetId)
        switch(direction) {
            case 'prev':
                i = ids.indexOf(cursorAssetId)
                id = i === 0 ? cursorAssetId : ids[i - 1]
                if(id) setCursorAsset({id})

                if(currentAssetId) setCurrentAsset({id})
                if(select) selectedAssetDispatch({type: 'Asset', id})
                return

            case 'next':
                i = ids.indexOf(cursorAssetId)
                id = ids[i + 1]

                if(id) setCursorAsset({id})
                if(currentAssetId) setCurrentAsset({id})
                if(select) selectedAssetDispatch({type: 'selectAsset', id})
                return

            case 'up':
                i = ids.indexOf(cursorAssetId)
                id = ids[i - window.assetsPerRow] || ids[0]

                if(id) {
                    if(select) selectedAssetDispatch({type: 'selectAsset', id})
                    setCursorAsset({id})
                }
                return

            case 'down':
                i = ids.indexOf(cursorAssetId)
                id = ids[i + window.assetsPerRow]

                if(id) {
                    if(select) selectedAssetDispatch({type: 'selectAsset', id})
                    setCursorAsset({id})
                }
                return

            case 'open':
                return !currentAssetId && setCurrentAsset({id: cursorAssetId})

            case 'toggle':
                return selectedAssetDispatch({type: 'toggleAsset', id: cursorAssetId})

            case 'shift-toggle':
                const selectIds = assetIds.slice(ids.indexOf(_.last(selectedAssetIds)), ids.indexOf(cursorAssetId) + 1)
                return selectedAssetDispatch({type: 'toggleAssets', ids: selectIds})

        }

    }
}

export {useCurrentAssetCursor, useSetCursorAsset};