import React from "react";
import {Tag, Tooltip} from "antd";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default ({enabled, onOff, labels}) => {
    const {t} = useTranslation();
    const style = {marginLeft:'0.5em'};

    return enabled ?
        <Tooltip title={'Enabled'}>
            {onOff ? <Tag color={'green'} style={style}>{labels?.on || t('on','On')}</Tag> : <CheckCircleTwoTone twoToneColor="#52c41a" style={style}/> }
        </Tooltip>
        :
        <Tooltip title={'Disabled'}>
            {onOff ? <Tag style={style}>{labels?.off || t('off','Off')}</Tag> : <CloseCircleTwoTone twoToneColor="red" style={style}/>}
        </Tooltip>
}