import React from "react";
import { Link } from "react-router-dom";

import { Tabs } from 'antd';
import setTitle from "../helpers/setTitle";
import Content from "../Content";
const { TabPane } = Tabs;

export default () => {
    setTitle('Collaborate');

    return (
        <div style={{marginTop:'1em'}}>
            <Content code={'collaborate-dashboard'}/>
        </div>
    )
}
