import React, {useContext, useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import * as Yup from "yup";

import {
    Badge, Button, Col,
    Collapse,
    Divider,
    Drawer,
    message,
    Modal,
    Row,
    Space,
    Tag,
    Tooltip,
    Upload
} from 'antd';
const { Panel } = Collapse;

import HelpPopover from "../HelpPopover";

import {
    SaveOutlined,
    FolderFilled,
    ArrowDownOutlined,
    DeploymentUnitOutlined,
    PlusOutlined,
    ClockCircleOutlined,
    FileProtectOutlined,
    TagOutlined,
    UserOutlined,
    UploadOutlined, FileOutlined, LinkOutlined, ApiOutlined, CheckOutlined, SendOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import api from "../api";

import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import AssetGroupChooser from "./AssetGroupChooser";
import FloatLabel from "../helpers/FloatLabel";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import UserSelect from "../widgets/UserSelect";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import FormItemLabel from "antd/lib/form/FormItemLabel";
import {SessionContext} from "../../contexts/SessionContext";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {FileIcon} from "react-file-icon";
import AssetLink from "../widgets/AssetLink";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {LibraryIcon, ProjectIcon, StorageFolderIcon} from "../helpers/icons";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";

export default ({id, onSave, visible, onClose, wh}) => {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const navigate = useNavigate();

    if(!id) {
        const { id } = useParams();
    }

    const [selectedGroup, setSelectedGroup] = useState()

    const [webhook, setWebhook] = useState(
        {name:'', url: '', asset_group_type: wh?.asset_group_type, asset_group_id: wh?.asset_group_id}
    );
    const apiPath = '/api/webhooks';

    // Only load when Drawer opened:
    useEffect(() => {
        if(!visible) return;

        const path = id ? `${id}/edit` : 'new';

        api.get(`${apiPath}/${path}`).then((res) => {
            setWebhook(res.data);
            setSelectedGroup(res.data.asset_group)
        });
    }, [id, visible]);

    const autoFocusInput = useRef(null);

    const onCloseDirty = useOnCloseDirty(onClose);

    const [width, setWidth] = useState(isMobile() ? '100%' : '70%');

    const onDrag = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        const els = document.getElementsByClassName('ant-drawer-content-wrapper');
        [].forEach.call(els, (el) => {
            el.style.width = `${w}%`;
        });
    }

    const onDragStop = (e) => {
        const w = (1 - (e.screenX / window.innerWidth)) * 100;
        console.log('onDragStop', e)
        setWidth(`${w}%`);
    }

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    // Avoid null validation error:
    if(webhook && !webhook.name) webhook.name = ''
    if(webhook && !webhook.url) webhook.url = ''

    const getPath = useOrgPath()

    return (
        (<Formik
            initialValues={webhook}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                api({
                    method: id ? 'put' : 'post',
                    url: id ? `${apiPath}/${id}` : apiPath,
                    data: {webhook: values}
                }).then((res)=>{
                    console.log('id',id);
                    if(!id) navigate(getPath(`/manage/integrations`))
                    actions.setSubmitting(false)
                    message.success(`${values.name} has been ${id ? 'updated' : 'created'}!`)

                    setWebhook(res.data);

                    actions.resetForm({});

                    onSave && onSave(res.data);

                }).catch((error)=>{
                    if(error) {
                        console.error(error.response?.data)
                        message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                        actions.setSubmitting(false)
                    }
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(true),
                    url: Yup.string().required(t('required','Required')),
                    asset_group_id: Yup.number().required(t('required','Required')),
                })
            }
        >
            {({setFieldValue, values, submitForm, dirty}) => {

                // Dev helper to auto open the pertinent panel:
                const defaultActiveKey = ''; // agreements

                const [testingUrl, setTestingUrl] = useState()
                const testUrl = ()=>{
                    setTestingUrl(true)

                    api.post('/api/webhooks/test', {url: values.url}).then(res => {
                        setTestingUrl(false)
                        if(res.data.error) {
                            message.error(res.data.error)
                        } else {
                            message.success(res.data.message)
                        }
                    })
                }

                return (
                    (<div>
                        <Drawer
                            title={
                                <>
                                    <ApiOutlined/> {id ? t('editing','Editing') : t('add-new','Add New')} {t('webhook','Webhook')}

                                    <HelpPopover code='webhook-form-header'/>
                                </>
                            }
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    <Space direction={'horizontal'}>
                                        <SubmitButton onClick={submitForm} type={'primary'} htmlType={'submit'}>
                                            <SaveOutlined/>
                                            {id ? t('update','Update') : t('save','Save')} {t('webhook','Webhook')}
                                        </SubmitButton>
                                    </Space>
                                </div>
                            }
                            width={width}
                            placement={'right'}
                            open={visible}
                            onClose={() => onCloseDirty(dirty)}
                            afterOpenChange={afterOpenChange}
                        >
                            <Form layout='vertical'>
                                <FormItem name='enabled'>
                                    <Switch name='enabled' checkedChildren={t('enabled','Enabled')} unCheckedChildren={t('disabled','Disabled')}/>
                                </FormItem>

                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-webhook-name', 'e.g. Uploads to Zapier')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name={'note'} style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('note','Note')} name={'note'} value={values.note || ''} description={t('placeholder-webhook-note','Note about the use for this Webhook. Optional.')}>
                                        <Input.TextArea rows={2} name='note'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem required name='url'>
                                    <FloatLabel label={'URL'} name={'url'} value={values.url} description={'e.g. https://example.com'}>
                                        <Input
                                            size={'large'}
                                            required
                                            name='url'
                                            autoComplete='off'
                                            addonAfter={
                                                <Button type='text' onClick={testUrl} loading={testingUrl} icon={<SendOutlined />}>{t('button-send-test','Send Test')}</Button>
                                            }
                                        />
                                    </FloatLabel>
                                </FormItem>

                                <Divider>{t('settings','Settings')}</Divider>

                                <FormItem name='group_assets'>
                                    <Switch name='group_assets' checkedChildren={t('yes','Yes')} unCheckedChildren={t('no','No')}/>
                                    &nbsp; &nbsp;
                                    {t('group-assets-in-payload','Group Assets in payload? Each will be sent separately when disabled.')}
                                </FormItem>

                                <FormItem name='include_download_url'>
                                    <Switch name='include_download_url' checkedChildren={t('yes','Yes')} unCheckedChildren={t('no','No')}/>
                                    &nbsp; &nbsp;
                                    {t('include-full-resolution-url-in-payload','Include Full Resolution URL in Payload?')}
                                </FormItem>

                                <Divider>{t('source','Source')}</Divider>

                                <Collapse defaultActiveKey={[webhook.asset_group_type]}>
                                    <Collapse.Panel header={<><StorageFolderIcon/> {t('storage-folders','Storage Folders')}</>} key={'StorageFolder'}>
                                        <AssetGroupChooser
                                            type={'storage_folders'}
                                            fieldName={`asset_group_id`}
                                            setSelectedGroup={setSelectedGroup}
                                            current={selectedGroup}
                                        />
                                    </Collapse.Panel>

                                    <Collapse.Panel header={<><LibraryIcon/> {t('libraries','Libraries')}</>} key={'Collection'}>
                                        <AssetGroupChooser
                                            type={'collections'}
                                            sandbox={'all'}
                                            fieldName={`asset_group_id`}
                                            setSelectedGroup={setSelectedGroup}
                                            current={selectedGroup}
                                        />
                                    </Collapse.Panel>

                                    <Collapse.Panel header={<><ProjectIcon/> {t('projects','Projects')}</>} key={'Lightbox'}>
                                        <AssetGroupChooser
                                            type={'lightboxes'}
                                            fieldName={`asset_group_id`}
                                            setSelectedGroup={setSelectedGroup}
                                            current={selectedGroup}
                                        />
                                    </Collapse.Panel>
                                </Collapse>

                                <SubmitButton style={{display:'none'}}/>
                            </Form>
                            <Draggable axis="x" disabled={!visible} onDrag={onDrag} onStop={onDragStop} defaultPosition={{x:0}}>
                                <div style={
                                    {
                                        top:0, position: 'fixed', height: '100vh', width:'3px', cursor:'col-resize',
                                        zIndex: 9999, right: width, display: (visible ? '' : 'none')
                                    }
                                }/>
                            </Draggable>
                        </Drawer>
                    </div>)
                );
            }}
        </Formik>)
    );
};

