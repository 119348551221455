import React, {useState} from "react";
import Content from "../Content";
import setTitle from "../helpers/setTitle";
import {Button, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
const {TabPane} = Tabs;

export default () => {
    const {t} = useTranslation();
    setTitle(t('status','Status'));

    const [tab, setTab] = useState('new');

    return (
        <Tabs
            activeKey={tab}
            onTabClick={(key)=> setTab(key)}
        >
            <TabPane tab={t('what-is-new',"What's New")} key="new">
                <Content code={'site-what-is-new'}/>
            </TabPane>
            {/*<TabPane tab="Status" key="status">*/}
            {/*    <Content code={'site-status'}/>*/}
            {/*</TabPane>*/}
            {/*<TabPane tab="Known Issues" key="issues">*/}
            {/*    <Content code={'site-known-issues'}/>*/}
            {/*</TabPane>*/}
            {/*<TabPane tab="Roadmap" key="roadmap">*/}
            {/*    <Content code={'site-roadmap'}/>*/}
            {/*</TabPane>*/}
        </Tabs>
    )
}
