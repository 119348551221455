import React, {useEffect, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput, Badge} from "antd";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import api from "../api";
import MembershipsTable from "./MembershipsTable";
import InviteForm from "./InviteForm";
import InvitesTable from "./InvitesTable";
import Content from "../Content";
import MembershipRequestsTable from "./MembershipRequestsTable";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";
import {useOrgPath} from "@/components/helpers/OrgNavLink";
import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";

export default () => {
    const {t} = useTranslation();
    setTitle(t('memberships','Memberships'));

    const [drawerVisible, setDrawerVisible] = useState();

    const onClick = ()=> {
        setDrawerVisible(true)
        window.location.hash = 'new'
    }

    const onClose = ()=> {
        window.history.replaceState(null, null, ' ');
        setDrawerVisible(false)
    }

    const [newInvite, setNewInvite] = useState();

    const onSave = (values)=> {
        window.history.replaceState(null, null, ' ');
        onClose()
        setNewInvite(values);
    }

    if(window.location.hash == '#new' && !drawerVisible) {
        setDrawerVisible(true);
    }

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [reloads, setReloads] = useState(0)
    const [pendingRequestCount, setPendingRequestCount] = useState()

    useEffect(()=>{
        api(`/api/membership_requests/pending_count`).then(res => setPendingRequestCount(res.data))
    }, [reloads])

    const getPath = useOrgPath()
    const { tab: routeTab } = useParams();

    const [tab, setTab] = useState(routeTab || 'browse');

    useEffect(()=>{
        if(routeTab?.length) setTab(routeTab)
    }, [routeTab])

    const navigate = useNavigate();

    const clickTab = key => {
        navigate(getPath(`/manage/members${key != 'browse' ? `/${key}` : ''}`))
        setTab(key)
    }

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placholder-search','Search...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'Invite'}>
                    <Button type='primary' onClick={onClick}>
                        <PlusOutlined/>
                        {t('button-new-invite','New Invite')}
                    </Button>

                    <InviteForm
                        onClose={onClose}
                        onSave={onSave}
                        visible={drawerVisible}
                    />
                </Can>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'1em 0'}}>{Extra}</div>}
            <Tabs
                activeKey={tab}
                onTabClick={clickTab}
                tabBarExtraContent={isBrowser() && Extra}
            >
                <TabPane tab={t('about',"About")} key="about">
                    <Content code={'about-memberships'}/>
                </TabPane>
                <TabPane tab={t('members',"Members")} key="browse">
                    <MembershipsTable q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
                <TabPane tab={t('invites',"Invites")} key="invites">
                    <InvitesTable newInvite={newInvite} q={searchValue} onLoaded={onLoaded} reloads={reloads}/>
                </TabPane>
                <TabPane tab={<>{t('requests','Requests')} <Badge count={pendingRequestCount}/></>} key="requests">
                    <MembershipRequestsTable q={searchValue} onLoaded={onLoaded} onUpdate={()=> setReloads(reloads + 1)}/>
                </TabPane>

            </Tabs>
        </>
    );
}
