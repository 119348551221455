import React, {useContext, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    AlertOutlined,
    ArrowRightOutlined, CheckCircleOutlined,
    DeleteOutlined, DownloadOutlined, LoadingOutlined, SettingOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import useCurrentUser from "@/components/helpers/useCurrentUser";
import useConsumer from "@/channels/consumer";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {OrgLink} from "@/components/helpers/OrgNavLink";

export default ({q, onLoaded}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/ingestions'

    const cancel = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}/cancel`,
            method: 'put'
        }).then(res => {
            message.success(t('message-ingestion-canceled.','Ingestion Canceled.'));
            handleTableChange();
        });
    }

    const currentUser = useCurrentUser();
    const org = useCurrentOrg();
    const userSub = useRef();
    const consumer = useConsumer();

    const [assetCounts, setAssetCounts] = useState({})
    const [states, setStates] = useState({})

    useEffect(()=> {
        if(!currentUser) return;

        if(!userSub.current) {
            userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                received: (data) => {
                    const {ingestion_id} = data;
                    switch (data.type) {
                        case 'ingestionAssetAdded':
                            if(assetCounts[ingestion_id]) {
                                assetCounts[ingestion_id] += 1;
                                setAssetCounts({...assetCounts});
                            }
                            return

                        case 'ingestionDone':
                            if(states[ingestion_id]) {
                                states[ingestion_id] = 'processed';
                                setStates({...states});
                            }
                            return
                        case 'ingestionCanceled':
                            if(states[ingestion_id]) {
                                states[ingestion_id] = 'canceled';
                                setStates({...states});
                            }
                            return
                    }
                }
            })
        }
    }, [currentUser?.id, org?.id])

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            width: 200,
            render: date => <TimeAgo date={date}/>
        },
        {
            title: t('user','User'),
            render: (m) => {
                return <User user={m.user}/>
            }
        },
        {
            title: t('type','Type'),
            dataIndex: 'type',
            render: type => <>{type.replace('Ingestion','')}</>
        },
        {
            title: t('upload-session','Upload Session'),
            render: (i)=> (
                <OrgLink to={`/upload/${i.upload.guid}`}>
                    {i.upload.name || i.upload.contribution?.name || <TimeAgo date={i.upload.created_at}/>}
                </OrgLink>
            )
        },
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            // filters: [
            //     {text: 'Analyzed', value: 'analyzed'},
            //     {text: 'Processed', value: 'processed'},
            // ],
            // filterMultiple: true,
            render: (tableState, i) => {
                const state = states[i.id] || tableState
                let tag;
                if(state === 'processed') tag = <Tag color={'green'} icon={<CheckCircleOutlined/>}>{t('processed','Processed')}</Tag>
                else if(state === 'processing') tag = <Tag color={'blue'} icon={<LoadingOutlined/>}>{t('processing','Processing')}</Tag>
                else if(state === 'errored') tag = <Tag color={'red'} icon={<AlertOutlined/>}>{t('errored','Errored')}</Tag>
                else {
                    tag = <Tag>{t(state, state).toProperCase()}</Tag>
                }

                return <Tooltip title={<>ID: {i.id}</>}>{tag}</Tooltip>;
            }
        },
        {
            title: t('Assets','Assets'),
            dataIndex: 'assets_count',
            sorter: true,
            render: (assetsCount, ingestion)=> {
                return (
                    <>{assetCounts[ingestion.id] || assetsCount}</>
                )
            }
        },
        {
            key: 'action',
            width: 100,
            render: i => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'Ingestion'}>
                                <Space direction={'horizontal'}>
                                    {(i.aasm_state === 'pending' || i.aasm_state === 'processing') && (
                                        <Popconfirm
                                            title={t('confirm-cancel-ingestion','Cancel Ingestion?')}
                                            onConfirm={() => cancel(i.id)}
                                        >
                                            <Button danger size={'small'} icon={<CloseCircleOutlined/>}>
                                                {t('cancel', 'Cancel')}
                                            </Button>
                                        </Popconfirm>
                                    )}
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        }
    ];

    const params = useRef({})

    const handleTableChange = (pagination, filters, sorter) => {
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()

            res.data.map(i => assetCounts[i] = i.assets_count)
            setAssetCounts({...assetCounts})
        })
    }

    useEffect(handleTableChange, [q]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}