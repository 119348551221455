import React, {useState, useEffect} from "react";

import {
    isMobile
} from "device-detect";

import {useAbility} from "@casl/react";
import {AbilityContext, Can} from "../helpers/Can";
import {Button, Col, Divider, message, Modal, Popconfirm, Row, Tooltip, Typography} from "antd";
import {Formik} from "formik";
import api from "../api";
import {Checkbox, Form, FormItem, Input, Rate, Select} from "formik-antd";
import pluralize from "pluralize";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import BulkJobProgress from "../explore/BulkJobProgress";
import {useBulkJobsDispatch} from "../../contexts/BulkJobsContext";
import {useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import AssetGroupChooser from "../manage/AssetGroupChooser";
import {CollectionIcon, LightboxIcon} from "../helpers/icons";
import {useAssetActions} from "~/contexts/AssetActionsContext";

export default ({type})=> {
    const ability = useAbility(AbilityContext);

    const klass = {
        'collections': 'Collection',
        'lightboxes': 'Lightbox'
    }[type]

    const Icon = {
        'collections': <CollectionIcon/>,
        'lightboxes': <LightboxIcon/>
    }[type]


    const actions = useAssetActions()
    const visible = actions[type === 'lightboxes' ? 'addToLightboxModalOpen' : 'addToCollectionModalOpen']
    const setVisible = actions[type === 'lightboxes' ? 'setAddToLightboxModalOpen' : 'setAddToCollectionModalOpen']

    const onClick = e => {
        e.stopPropagation()
        setVisible(true)
    }

    const [bulkJobId, setBulkJobId] = useState()
    const bulkJobDispatch = useBulkJobsDispatch()

    const {selectedAssetIds} = useSelectedAssetsState();

    if(!ability.can('update_some', klass)) return <></>;

    return (<>
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                values.asset_ids = selectedAssetIds;

                if(!values.add_asset_group_id) return message.error('Please make a selection.')

                api.post(`/api/bulk_jobs`, {bulk_job: values}).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    setBulkJobId(res.data.guid)
                    bulkJobDispatch({type:'add', bulkJob: res.data})

                }).catch((err)=>{
                    console.log(err)
                })
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting}) => {

                const onFinish = (bj)=> {
                    setVisible(false)
                    message.success(`Done!`)
                    setBulkJobId(null)
                }

                return (
                    (<Modal
                        zIndex={1031}
                        width={isMobile() ? '100%' : 500}
                        onCancel={()=> setVisible(false)}
                        open={visible}
                        title={
                            <>
                                Adding {selectedAssetIds.length} {pluralize('Asset', selectedAssetIds.length)} to a {klass}
                            </>
                        }
                        footer={!bulkJobId && (
                            <Popconfirm title={'Are you sure?'} onConfirm={submitForm} zIndex={1032} getPopupContainer={e => e.parentElement}>
                                <Button icon={<SaveOutlined/>} loading={isSubmitting}>Submit</Button>
                            </Popconfirm>
                        )}
                    >
                        {bulkJobId && (
                            <BulkJobProgress id={bulkJobId} onFinish={onFinish}/>
                        ) || (
                            <Form onSubmit={handleSubmit} layout="vertical">
                                <AssetGroupChooser
                                    selectableIf={c => c.can_update_assets && c.sub_type !== 'project'}
                                    type={type}
                                    fieldName={'add_asset_group_id'}
                                />
                            </Form>
                        )}
                    </Modal>)
                );
            }}
        </Formik>
    </>);
}