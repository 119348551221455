import React, {useContext, useEffect, useRef, useState} from "react";
import api from "./api";
import {AbilityContext, Can} from "./helpers/Can";
import {Button, Modal, Space, Input, Tooltip, notification, message} from "antd";
import {EditOutlined, LoadingOutlined} from "@ant-design/icons";

import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";

import {Parser} from 'html-to-react';
import {useTranslation} from "react-i18next";
import useCurrentUser from "@/components/helpers/useCurrentUser";

export default ({code}) => {
    const {i18n} = useTranslation();
    const [fetched, setFetched] = useState(false);
    const [content, setContent] = useState();
    const [editing, setEditing] = useState();

    useEffect(() => {
        if(fetched) return;

        api(`/api/content/${code}?lang=${i18n.language}`).then(res => {
            setFetched(true);
            setContent(res.data);
        })
    }, [fetched])

    const currValue = useRef()

    const handleChange = (newValue) => {
        currValue.current = newValue;
    }

    const save = (e) => {
        e.preventDefault();
        e.stopPropagation();
        api.put(`/api/contents/${code}`, {lang: i18n.language, content: {text: currValue.current}}).then(res => {
            setEditing(false);
            setContent(res.data);
            message.success('Content saved.')
        })
    }

    const cancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setEditing(false)
    }

    const onClick = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        setEditing(true)
    }

    const modules = {
        imageResize: {parchment: Quill.import('parchment')},
        toolbar: [
            [{ size: [] }],
            [{ align: [] }, 'direction' ],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ color: [] }, { background: [] }],
            [{ script: 'super' }, { script: 'sub' }],
            ['blockquote', 'code-block' ],
            [{ list: 'ordered' }, { list: 'bullet'}, { indent: '-1' }, { indent: '+1' }],
            [ 'link', 'image', 'video' ],
            [ 'clean' ]        ],
    }

    const ability = useContext(AbilityContext);
    const currentUser = useCurrentUser()
    const canEdit = ability.can('manage','Content') || currentUser?.super_admin

    return (
        <>
            {!editing && (
                <div className={'_info-content'}>
                    {!fetched && <LoadingOutlined/>}

                    {(new Parser).parse(content)}

                    {canEdit && (
                        <Tooltip title={'Edit Content'}>
                            <Button
                                type={'link'}
                                size='small'
                                onClick={onClick}
                                style={{marginTop:'1em'}}
                            >
                                <EditOutlined/>
                            </Button>
                        </Tooltip>
                    )}
                </div>
            )}

            {editing && (
                <>
                    <ReactQuill
                        onChange={handleChange}
                        defaultValue={content}
                        theme="snow"
                        modules={modules}
                        preserveWhitespace
                    />

                    <Space direction={'horizontal'} style={{marginTop:'1em'}}>
                        <Button
                            icon={<SaveOutlined/>}
                            ghost type={'primary'}
                            onClick={save}
                        >
                            Save
                        </Button>

                        <Button onClick={cancel}>Cancel</Button>
                    </Space>
                </>
            )}

        </>
    )
}
