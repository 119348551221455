import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {useParams} from 'react-router-dom';
import api from "../api";
import {Breadcrumb, Button, Col, message, Popconfirm, Popover, Row, Space, Table, Typography} from "antd";
import {OrgLink} from "../helpers/OrgNavLink";
import {Can} from "../helpers/Can";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import User from "../helpers/User";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import Search from "antd/lib/input/Search";
import queryString from "query-string";
import useShowAsset from "../helpers/useShowAsset";
import EnabledDisabled from "../helpers/EnabledDisabled";
import TimeAgo from "../helpers/TimeAgo";
import DateRangePicker from "../widgets/DateRangePicker";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ()=> {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/downloads'

    const showAsset = useShowAsset()

    const clickAsset = (asset)=>{
        showAsset(asset?.id)
    }

    const columns = [
        {
            title: t('date','Date'),
            dataIndex: 'created_at',
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: t('thumb','Thumb'),
            render: (download) => {
                return download.asset && (
                    <a onClick={()=> clickAsset(download.asset)}>
                        <img src={download.asset.thumb_url} style={{width:30}}/>
                    </a>
                )
            }
        },
        {
            title: t('filename','Filename'),
            render: (download) => {
                return download.asset && <a onClick={()=> clickAsset(download.asset)}>{download.asset.filename}</a>
            }
        },
        {
            title: 'GUID',
            render: (download) => {
                return download.asset && <Typography.Text code copyable>{download.asset.guid.slice(0,8)}</Typography.Text>
            }
        },
        {
            title: t('size','Size'),
            dataIndex: 'size',
            sorter: true,
            filters: [
                {text: t('small','Small'), value: 'small'},
                {text: t('medium','Medium'), value: 'permalink'},
                {text: t('full','Full'), value: 'full'},
                {text: t('original','Original'), value: 'original'},
            ],
            render: (value, download) => {
                return value == 'permalink' ? t('medium' ,'Medium') : (download.crop_preset ? download.crop_preset.name : value?.toProperCase())
            }
        },
        {
            title: t('watermark','Watermark'),
            dataIndex: 'watermarked',
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (value) => {
                return value ? t('yes','Yes') : t('no','No')
            }
        },
        {
            title: t('user','User'),
            render: (download) => {
                return <User user={download.user}/>
            }
        },
        {
            title: t('share-link','Share Link'),
            dataIndex: 'share_link',
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (sl, download) => {
                return <Popover content={sl?.note}>{sl ? <>{t('created-by','Created by')} <User user={sl.user}/></> : t('no','No')}</Popover>
            }
        },
        {
            title: t('via','Via'),
            dataIndex: 'via',
            filters: [
                {text: t('web','Web'), value: 'web'},
                {text: t('editor','Editor'), value: 'editor'},
                {text: 'API', value: 'api'},
            ],
            render: (via) => {
                return t(via,via)?.toProperCase()
            }
        },
    ];

    let location = useLocation()
    const navigate = useNavigate()

    const {search: searchQuery, share_link_id} = queryString.parse(location.search)

    const params = useRef({})
    const [q, setQ] = useState(searchQuery)
    const [dates, setDates] = useState()

    useEffect(()=>{
        if(searchQuery != q) setQ(searchQuery)
    }, [searchQuery])

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination, filters, sorter) => {
        setSettings({q, dates, pagination, filters, sorter, share_link_id})
        params.current = {
            q,
            dates,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            share_link_id,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(handleTableChange, [q, dates, share_link_id]);

    const onSearch = _.debounce((value) => {
        setQ(value)
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <Row gutter={8}>
                <Col lg={4} xs={24}>
                    <DateRangePicker onChange={dates => setDates(dates) } />
                </Col>
                <Col lg={8} xs={24}>
                    <Search
                        placeholder={t('placeholder-search-download-report','Search By Asset GUID, Filename, User...')}
                        onSearch={onSearch}
                        onChange={onChange}
                        style={{width:'100%'}}
                        size={'medium'}
                        allowClear
                        loading={loading}
                        value={q}
                    />
                </Col>
            </Row>

            <Table
                scroll={{x: true}}
                bordered
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </Space>
    );
}