import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from "react";
import {Link, NavLink, useLocation, useParams, withRouter} from "react-router-dom";

import {
    Input,
    Menu,
    Layout,
    Avatar,
    Badge,
    Divider,
    message,
    Tooltip,
    Space,
    AutoComplete,
    Modal,
    Tag,
    Button, Progress, Popover, List, Row, Col, Select, Typography, ConfigProvider
} from "antd";
const { SubMenu } = Menu;
const { Header } = Layout;
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";

import _ from 'lodash';

import { AppContext } from '../contexts/AppContext'
import api from "./api";

import { useNavigate } from "react-router-dom-v5-compat";
import UploadButton from "./uploads/UploadButton";
import {SessionContext} from "../contexts/SessionContext";
import {
    FolderOutlined,
    TagOutlined,
    UserSwitchOutlined,
    UserOutlined,
    BuildOutlined,
    LogoutOutlined,
    UserAddOutlined,
    LinkOutlined,
    CheckOutlined,
    LoadingOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FileImageOutlined,
    VideoCameraOutlined,
    FileOutlined,
    AudioOutlined,
    FontSizeOutlined,
    ClockCircleOutlined,
    ExclamationOutlined,
    EyeOutlined,
    SettingOutlined,
    ThunderboltOutlined,
    CloudServerOutlined,
    AlertOutlined,
    WarningOutlined, WifiOutlined, VerticalAlignBottomOutlined
} from "@ant-design/icons";
import AssetModal from "./widgets/AssetModal";
import AssetGroupDrawer from "./widgets/AssetGroupDrawer";
import SearchModalButton from "./widgets/SearchModalButton";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import {useAssetsDispatch} from "../contexts/AssetsContext";
import {useFilters} from "./helpers/useFilters";
import Form from "antd/lib/form";
import queryString from "query-string";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import {AbilityContext} from "./helpers/Can";
import UploadManager from "./uploads/UploadManager";
import UploadDrawer from "./widgets/UploadDrawer";
import ActionCable from "./ActionCable";
import NotificationsManager from "./widgets/NotificationsManager";
import AssetsManager from "./explore/AssetsManager";
import {useLoadedAssetsDispatch} from "../contexts/LoadedAssetsContext";
import OrgNavLink, {OrgLink, useOrgPath} from "./helpers/OrgNavLink";
import useOrgSlug from "./helpers/useOrgSlug";
import useOrgSwitcher from "./helpers/useOrgSwitcher";
import SiteSearch from "./widgets/SiteSearch";
import {ManageMenu} from "./manage/ManageLayout";
import HotKeysModal from "./widgets/HotKeysModal";
import {GlobalHotKeys, HotKeys, IgnoreKeys} from "react-hotkeys";
import useCurrentOrg, {checkReindexing, useAfterOrgLoad} from "./helpers/useCurrentOrg";
import TimeAgo from "./helpers/TimeAgo";
import ProductTour from "./widgets/ProductTour";
import {AccessibilityIcon, ExploreIcon, KeyboardIcon, ShareIcon, WorkflowIcon} from "./helpers/icons";
import {useUploadsState} from "../contexts/UploadsContext";
import BulkJobProgress from "./explore/BulkJobProgress";
import {useBulkJobsState} from "../contexts/BulkJobsContext";
import { useTranslation, Trans } from 'react-i18next';


import {css, Global as GlobalStyles} from '@emotion/react';

// selectedKeys from Router pathname:
// https://github.com/ant-design/ant-design/issues/6576#issuecomment-398355506

const AppHeader = withRouter(props => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { location } = props;

    const ability = useContext(AbilityContext);

    const {state, dispatch} = useContext(AppContext);
    const {currentUser} = state;

    const {state: sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const {org: orgSlug} = useParams()
    const org = useOrgSlug()

    const orgSlugOrDomain = window.customDomainOrganizationSlug || orgSlug

    useEffect(()=> {
        if(orgSlugOrDomain && currentOrg && orgSlugOrDomain != currentOrg?.slug && ['login', 'organizations', 'trial-sign-up'].indexOf(orgSlugOrDomain) === -1) {
            console.log('different org:', orgSlugOrDomain)
            api.get(`/api/organizations/find?slug=${orgSlugOrDomain}&login=true`).then(res => {
                // TODO: if not member, send to login
                sessionDispatch({type: 'set_org', org: res.data.organization || res.data, abilities: res.data.abilities});
                checkReindexing(res.data.organization)
            }).catch(()=> {
                message.success(t('message-please-sign-in','Please sign in.'));
                navigate(getOrgPath('/login'))
            })
        }
    }, [orgSlugOrDomain])

    const logout = () => {
        api({
            method:'delete',
            url: '/api/logout',
        }).then((res)=>{
            message.info('Logged out.')
            sessionDispatch({type:'logout'});
            dispatch({type:'logout'});

            navigate(getOrgPath('/login'));
        }).catch((res)=>{
            message.info(JSON.stringify(res))
            console.log(res)
        })
    }

    const switchBack = ()=>{
        api('/api/switch_back', {params:{switch_token: currentUser.switch_token}}).then((res) => {
            dispatch({type:'switchBack', user: res.data});
            const org = currentOrg;
            org && api.get(`/api/organizations/${org.id}/abilities`).then(res => {
                sessionDispatch({type: 'set_org', org: org, abilities: res.data});
                message.success('Switched Back, reloading...')
                window.location = window.location
            })
        })
    }

    const logo = (
        <Button
            type={'text'}
            size={'small'}
            onClick={()=> navigate(getOrgPath('/'))}
            aria-label={t('navigate-home','Navigate Home')}
            tabIndex={0}
            id={'logo-btn'}
        >
            <img alt={`${(currentOrg || org)?.title} Mediagraph Account`} src={(currentOrg || org)?.logo_url || ImageUrls.headerLogo} style={{height:35, width: isMobile() ? 40 : 'auto', objectFit: 'contain', maxWidth: 120}}/>
        </Button>
    )

    const switchOrgs = useOrgSwitcher()

    const [hotKeysVisible, setHotKeysVisible] = useState()

    const ref = useRef();
    const keyMap = { H: 'h' }

    const handlers = {
        H: (e) => {
            e.preventDefault()
            setHotKeysVisible(!hotKeysVisible)
        }
    }

    const clickSuperAdmin = ()=> {
        window.location = `/admin/login?jwt=${state.jwt}`;
    }

    const [openNotificationManager, setOpenNotificationManager] = useState()

    const onUserMenuClick = item => {
        console.log('onUserMenuClick', item)
        if(item.key === 'notifications') setOpenNotificationManager(true)
        else if(item.key === 'trial-sign-up') navigate('/trial-sign-up')
        else if(item.key === 'login') navigate(currentOrg ? getOrgPath('/login') : '/login')
    }

    const getOrgPath = useOrgPath()

    const [sharesOpen, setSharesOpen] = useState()

    const loadAssetFromGuid = useLoadAssetFromGuid()
    const afterOrgLoad = useAfterOrgLoad()

    // Check loaded window hash:
    useEffect(()=>{
        const guid = (location.hash?.match(/^#\/assets\/(.+?)$/) || [])[1]

        if(!guid) return;

        if(currentOrg) {
            loadAssetFromGuid(guid)
        } else {
            afterOrgLoad(()=> loadAssetFromGuid(guid))
        }
    }, [location.hash]);

    const skipToContent = ()=> {
        document.querySelector('[role=main]')?.focus();
    }

    const [openAccessibilityVideoModal, setOpenAccessibilityVideoModal] = useState()

    return (
        (<GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref}>
            <GlobalStyles
                styles={css`
                    nav {
                        .ant-menu-submenu-active, .ant-menu-submenu-selected, .ant-menu-item-active, .ant-menu-item-selected  {
                            background-color: ${currentOrg?.header_highlight_color || 'white'};
                            text-decoration-color: ${currentOrg?.header_text_color};
                            color: ${currentOrg?.header_text_color};

                            svg {
                                color: ${currentOrg?.header_text_color};
                            }
                        }
                    }
                `}
            />

            <SitewideAnnouncements/>
            <ConfigProvider
                theme={{
                    token: {
                        linkFocusDecoration: 'underline',
                        colorSplit: currentOrg?.header_text_color,
                    },
                    components: {
                        Menu: {
                            activeBarHeight: 0,
                            activeBarBorderWidth: 0,
                            horizontalItemHoverBg: currentOrg?.header_highlight_color || Colors.blue,
                            horizontalItemSelectedBg: currentOrg?.header_highlight_color,
                            itemColor: currentOrg?.header_text_color || 'white',
                            itemSelectedColor: currentOrg?.header_text_color || 'white',
                            itemSelectedBg: currentOrg?.header_highlight_color,
                            itemHoverColor: currentOrg?.header_text_color,
                            itemHoverBg: currentOrg?.header_highlight_color || Colors.blue,
                            itemActiveBg: currentOrg?.header_highlight_color || Colors.blue,
                            groupTitleColor: currentOrg?.header_text_color,
                            popupBg: currentOrg?.header_background_color,
                            itemBg: currentOrg?.header_background_color,
                        },
                    }
                }}
            >
                <nav role={'navigation'} aria-label={t("main-navigation", 'Main Navigation')}>
                    <Button id={'skip-to-content-link'} type={'primary'} icon={<I><VerticalAlignBottomOutlined /></I>} onClick={skipToContent} onKeyPress={e => e.keyCode === 13 && skipToContent()}>{t('skip-to-content', 'Skip to Content')}</Button>

                    <Header className="header" id={'header'}
                            style={{backgroundColor: currentOrg?.header_background_color || '000'}}>
                        <Row justify="space-between" wrap={false} align={'middle'}>
                            <Col flex={'none'}>
                                <div className="logo" onKeyDown={e => { if (e.keyCode === 13) navigate(getOrgPath('/')) }}>
                                    {currentUser && currentOrg?.federation && (
                                        <Popover
                                            overlayStyle={{minWidth: 400}}
                                            placement={'bottomLeft'}
                                            title={<><BuildOutlined/> {currentOrg.federation.title} {t('organizations', 'Organizations')}</>}
                                            trigger={isBrowser() ? 'hover' : 'click'}
                                            content={() => {
                                                return (
                                                    <>
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={currentOrg.federation.organizations}
                                                            renderItem={org => {
                                                                const logo = org.logo_url && <img src={org.logo_url} style={{ height: 25, marginRight: '1em' }}/> ||
                                                                    <BuildOutlined/>

                                                                const [loading, setLoading] = useState()
                                                                const click = () => {
                                                                    setLoading(true)
                                                                    switchOrgs(org, () => setLoading(false))
                                                                }

                                                                const current = org.slug == currentOrg.slug

                                                                return (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            title={
                                                                                <Tooltip
                                                                                    title={isBrowser() && (current ? 'Current Organization' : 'Switch To Organization')}
                                                                                    placement={'right'}>
                                                                                    <Button
                                                                                        disabled={current}
                                                                                        icon={logo}
                                                                                        onClick={click}
                                                                                        type={'text'}
                                                                                    >
                                                                                        {org.title}
                                                                                        {current && <CheckOutlined/>}

                                                                                        {loading && <LoadingOutlined/>}
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                    </List.Item>
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }}
                                        >
                                            {logo}
                                        </Popover>

                                    ) || (
                                        <Tooltip
                                            title={(currentOrg || org) && <span style={{color: 'black'}}>{(currentOrg || org).title}</span>}
                                            placement='bottomLeft'
                                            color={'white'}
                                            trigger={['hover', 'focus']}
                                        >
                                            {logo}
                                        </Tooltip>
                                    )}
                                </div>
                            </Col>

                            {currentOrg && isBrowser() && (
                                <Col flex={'auto'}>
                                    <TopSectionNav/>
                                </Col>
                            )}

                            {currentOrg && isBrowser() && (
                                <Col
                                    style={{
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        textAlign: 'center',
                                        padding: '0 4em'
                                    }}
                                    flex={11}
                                    xs={0}
                                    md={24}
                                    id={'site-search-wrapper'}
                                >
                                    <SiteSearch/>
                                </Col>
                            )}

                            {currentOrg && isMobile() && (
                                <Col>
                                    <Space size={'small'} style={{width: '100%'}}>
                                        <BrandedButton icon={<ExploreIcon/>} type={'primary'} shape={'circle'} tabIndex={0} onClick={()=> navigate(getOrgPath('/explore'))}/>

                                        <BrandedButton icon={<SearchOutlined/>} type={'primary'} shape={'circle'} tabIndex={0} onClick={()=> navigate(location.pathname.match(/\/explore/) ? '#search' : `/${!customDomainOrganizationSlug ? currentOrg.slug : ''}/explore#search`)}/>

                                        {ability.can('view_manage_menu', 'Organization') && <ManageMenu/>}
                                    </Space>
                                </Col>
                            )}

                            {!currentOrg && (
                                // Spacer:
                                (<Col flex={11} xs={0} sm={24}/>)
                            )}

                            <Col
                                flex={0}
                                style={{display: 'flex', justifyContent: 'flex-end'}}
                            >
                                {currentOrg && (currentUser || currentOrg?.has_public_uploads) && (
                                    <Space size={'middle'} style={{marginRight: '1em'}}>
                                        {isBrowser() && <SubscriptionBadge/>}
                                        <NetworkIndicator/>
                                        <BulkJobIndicator/>
                                        <UploadManager/>
                                        <UploadButton/>
                                    </Space>
                                )}

                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Menu: {
                                                horizontalItemHoverBg: 'transparent',
                                                horizontalItemSelectedBg: 'transparent',
                                            },
                                        }
                                    }}
                                >
                                    <Menu
                                        getPopupContainer={e => e.node}
                                        aria-label={t('user-navigation-menu', 'User Account Navigation Menu')}
                                        mode="horizontal"
                                        style={{lineHeight: isMobile() ? 'auto' : '64px', paddingRight: isMobile() ? 0 : '1em', borderBottom: 0}}
                                        id={'app-header-user-menu'}
                                        disabledOverflow={true}
                                        onClick={onUserMenuClick}
                                        items={_.flatten(_.compact([
                                            currentUser && !currentUser.guest ? {
                                                id: 'user-nav-menu',
                                                key: 'user-nav-menu',
                                                label: (
                                                    <Badge count={0}>
                                                        <Avatar
                                                            src={currentUser.avatar_url}>{currentUser.initials}</Avatar>
                                                    </Badge>
                                                ),
                                                onClick: (e) => {
                                                    switch (e.key) {
                                                        case 'logout':
                                                            logout()
                                                    }
                                                },
                                                children: _.compact([
                                                    {
                                                        type: 'group',
                                                        key: 'user',
                                                        label: (
                                                            <Tooltip
                                                                title={<>{currentUser.email}<br/>{currentUser.username && `@${currentUser.username}`}</>}
                                                                placement={'left'}>
                                                                <small>
                                                                    <UserOutlined/> {currentUser.name} {currentOrg &&
                                                                    <Tag style={{marginLeft: '.5em'}}
                                                                         color={'blue'}>{t(currentOrg.role_level_name, currentOrg.role_level_name) || t('inactive-membership', 'Inactive Membership')}</Tag>}
                                                                </small>
                                                            </Tooltip>
                                                        ),
                                                        children: _.flatten(_.compact([
                                                            state.switchedFromUser ? {
                                                                key: 'switchBack',
                                                                icon: <UserSwitchOutlined/>,
                                                                label: <a
                                                                    onClick={switchBack}>{t('nav-switch-back-to', 'Switch back to')} {state.switchedFromUser.email}</a>
                                                            } : null,

                                                            !state.switchedFromUser ? {
                                                                key: 'organizations',
                                                                icon: <BuildOutlined/>,
                                                                label: <NavLink
                                                                    to='/organizations'>{t('nav-organizations', 'Organizations')}</NavLink>
                                                            } : null,

                                                            currentOrg ? {
                                                                key: 'my-shares',
                                                                label: t('my-shares', 'My Shares'),
                                                                icon: <ShareIcon/>,
                                                                onClick: () => setSharesOpen(true)
                                                            } : null,

                                                            currentOrg ? {
                                                                key: 'settings',
                                                                icon: <EditOutlined/>,
                                                                label: <OrgNavLink
                                                                    to='/settings'> {t('nav-profile-settings', 'Profile Settings')} </OrgNavLink>
                                                            } : null,

                                                            !state.switchedFromUser ? {
                                                                key: 'account',
                                                                icon: <UserOutlined/>,
                                                                label: <NavLink
                                                                    to='/account'> {t('nav-account', 'Account')} </NavLink>
                                                            } : null,

                                                            !state.switchedFromUser && currentUser?.super_admin ? [
                                                                {type: 'divider'},
                                                                {
                                                                    key: 'admin',
                                                                    icon: <ThunderboltOutlined/>,
                                                                    label: (
                                                                        <a onClick={clickSuperAdmin}
                                                                           style={{color: 'orange'}}
                                                                           id={'super-admin-btn'}> Super Admin </a>
                                                                    ),
                                                                },
                                                                {type: 'divider'},
                                                                {
                                                                    key: 'errors',
                                                                    icon: <WarningOutlined/>,
                                                                    label: <OrgNavLink
                                                                        to={'/explore?aasm_state=processing_error'}
                                                                        style={{color: 'orange'}}> Errored
                                                                        Assets </OrgNavLink>
                                                                },
                                                                {
                                                                    key: 'pending',
                                                                    label: (
                                                                        <OrgNavLink to={'/explore?aasm_state=pending'}
                                                                                    style={{color: 'orange'}}>
                                                                            <ClockCircleOutlined/> Pending Assets
                                                                        </OrgNavLink>
                                                                    )
                                                                },
                                                            ] : null,
                                                            {type: 'divider'},
                                                            {
                                                                key: 'logout',
                                                                id: 'logout-btn',
                                                                icon: <LogoutOutlined/>,
                                                                label: t('nav-logout', 'Log Out')
                                                            }
                                                        ]))
                                                    },

                                                ])
                                            } : null,

                                            currentUser && currentOrg && !currentUser.guest ? (
                                                {
                                                    key: 'notifications',
                                                    label: <NotificationsManager open={openNotificationManager}
                                                                                 setOpen={setOpenNotificationManager}/>
                                                }
                                            ) : null,

                                            (!currentUser || currentUser.guest) ? [
                                                {
                                                    key: 'login',
                                                    icon: <LoginOutlined/>,
                                                    label: t('nav-login', 'Login')
                                                },
                                                !isMobile() && {
                                                    key: 'trial-sign-up',
                                                    icon: <UserAddOutlined/>,
                                                    label: t('nav-trial-sign-up', 'Trial Sign Up'),
                                                },
                                            ] : null,

                                            currentOrg?.enable_membership_requests && (!currentUser || currentUser.guest) ? {
                                                key: 'request-access',
                                                icon: <UserAddOutlined/>,
                                                label: <OrgNavLink
                                                    to='/request-access'><BrandedText>{t('request-access', 'Request Access')}</BrandedText></OrgNavLink>
                                            } : null,

                                            isBrowser() ? [
                                                {
                                                    key: 'help',
                                                    id: 'help-nav-menu',
                                                    label: <BrandedText>{t('nav-help', 'Help')}</BrandedText>,
                                                    children: _.compact([
                                                        currentOrg?.enable_help_menu_link ? {
                                                            key: 'help-menu-link',
                                                            label: <a href={currentOrg.help_menu_link_url} target={'_blank'}>{currentOrg.help_menu_link_text}</a>
                                                        } : null,

                                                        currentOrg?.enable_help_menu_link && {type: 'divider'},

                                                        ability.can('manage', 'Organization') ? {
                                                            key: 'tour',
                                                            onClick: () => console.log('click'),
                                                            label: <OrgNavLink
                                                                to='/explore#admin-tour'>{t('admin-product-tour', 'Admin Product Tour')}</OrgNavLink>
                                                        } : null,

                                                        currentOrg ? {
                                                            key: 'general-tour',
                                                            onClick: () => console.log('click'),
                                                            label: <OrgNavLink
                                                                to='/explore#general-tour'>{t('general-product-tour', 'General Product Tour')}</OrgNavLink>
                                                        } : null,

                                                        {type: 'divider'},
                                                        {
                                                            key: 'kb',
                                                            label: <a href={Config.helpUrl} target={'_blank'}>{t('nav-knowledge-base', 'Knowledge Base')}</a>
                                                        },
                                                        // {
                                                        //     key: 'accessibility',
                                                        //     label: <>{t('accessibility-tutorial', 'Accessibility Tutorial')}</>,
                                                        //     onClick: ()=> setOpenAccessibilityVideoModal(true),
                                                        // },
                                                        {
                                                            key: 'accessibility-kb',
                                                            label: <a href={Config.accessibilityUrl} target={'_blank'}>{t('accessibility-info', 'Accessibility Info')}</a>
                                                        },
                                                        {
                                                            key: 'status',
                                                            label: <a href={'https://mediagraph.statuspage.io'}
                                                                      target={'_blank'}>{t('nav-system-status', 'System Status')}</a>
                                                        },

                                                        typeof zE != 'undefined' ? {
                                                            key: 'chat',
                                                            label: <a
                                                                onClick={() => openZendesk()}>{t('live-chat', 'Live Chat')}</a>
                                                        } : null,
                                                        
                                                        {
                                                            key: 'trouble',
                                                            label: <TroubleShootLink/>
                                                        },

                                                        {type: 'divider'},
                                                        {
                                                            key: 'hotkeys',
                                                            onClick: () => setHotKeysVisible(true),
                                                            icon: <KeyboardIcon/>,
                                                            label: t('nav-hot-keys', 'Hot Keys')
                                                        },

                                                    ])

                                                }

                                            ] : null,

                                            LanguageSelect({})
                                        ]))}
                                    />
                                </ConfigProvider>
                            </Col>
                        </Row>

                    </Header>
                </nav>
            </ConfigProvider>

            <Modal
                open={openAccessibilityVideoModal}
                onCancel={() => setOpenAccessibilityVideoModal(false)}
                width={'75%'}
                title={<><AccessibilityIcon/> {t('accessibility-tutorial', 'Accessibility Tutorial')}</>}
                footer={null}
            >
                <iframe src={`https://player.vimeo.com/video/${Config.accessibilityVimeoId}`} width="100%" height="600"
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen/>
            </Modal>

            {currentUser && (
                <Modal
                    open={sharesOpen}
                    onCancel={() => setSharesOpen(false)}
                    width={'75%'}
                    title={<><ShareIcon/> {t('my-shares', 'My Shares')}</>}
                    footer={null}
                    destroyOnClose
                >
                    <SharesTable user={currentUser}/>
                </Modal>
            )}

            <HotKeysModal hotKeysVisible={hotKeysVisible} setHotKeysVisible={setHotKeysVisible}/>

            {isBrowser() && <ProductTour/>}

            <AssetGroupDrawer/>

            <DownloadModal/>
            <AccessRequestModal/>
            <ShareModal/>

            <AssetActions/>

            <AssetModal/>

            {currentOrg && <ActionCable/>}
        </GlobalHotKeys>)
    );
});

export default AppHeader;

const TopSectionNav = ({}) => {
    const {t} = useTranslation();
    const [counts, setCounts] = useState()

    const ability = useContext(AbilityContext);
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()

    const onOpenChange = (keys) =>{
        if(!keys || keys.indexOf('view-all') === -1) return

        api(`/api/assets/counts`).then(res => {
            setCounts(res.data.type.buckets)
        })
    }

    const {filters} = useFilters()

    const count = key => {
        const enabled = {
            'images': 'Image',
            'videos': 'Video',
            'audio': 'Audio',
            'documents': 'Document',
            'fonts': 'Font',
        }[filters.type] == key

        if(!counts) return <Tag><LoadingOutlined/></Tag>

        const i = key === 'All' ? _.sum(counts.map(c => c.doc_count)) : _.find(counts, {key})?.doc_count || 0

        return <Tag color={enabled && 'blue'} style={{marginLeft:'.5em'}}>{n(i)}</Tag>
    }


    const getOrgPath = useOrgPath()

    const navigate = useNavigate()

    const clickViewAll = () => {
        return navigate(getOrgPath('/explore'));
    }

    const onClick = item => {
        if(item.key === 'view-all') clickViewAll()
    }

    let defaultSelectedKeys;
    const location = useLocation()
    if(location.pathname.match(/\/explore/)) {
        defaultSelectedKeys = 'view-all'
    } else if(location.pathname.match(/\/manage/)) {
        defaultSelectedKeys = 'manage'
    } else if(location.pathname.match(/\/collaborate/)) {
        defaultSelectedKeys = 'workflow'
    }

    return (
        <Menu
            aria-label={t('sitewide-navigation-menu', 'Sitewide Navigation Menu')}
            mode="horizontal"
            style={{lineHeight: isMobile() ? 'auto' : '64px', borderBottom: 0}}
            overflowedIndicator={<BrandedText><MenuUnfoldOutlined/></BrandedText>}
            onOpenChange={onOpenChange}
            expandIcon={<DownOutlined/>}
            onClick={onClick}
            selectedKeys={[defaultSelectedKeys]}
            items={_.compact([
                {
                    key: 'view-all',
                    label: t('nav-view-all', 'View All'),
                    icon: <I><EyeOutlined/></I>,
                    onTitleClick: clickViewAll,
                    children: [
                        {
                            key: 'all',
                            icon: <I><EyeOutlined/></I>,
                            label: <OrgNavLink to="/explore">{t('all','All')} {count('All')}</OrgNavLink>
                        },
                        {
                            key: 'images',
                            icon: <I><FileImageOutlined/></I>,
                            label: <OrgNavLink to="/explore?type=images" >{t('images','Images')} {count('Image')}</OrgNavLink>
                        },
                        {
                            key: 'videos',
                            icon: <I><VideoCameraOutlined/></I>,
                            label: <OrgNavLink to="/explore?type=videos" >{t('videos','Videos')} {count('Video')}</OrgNavLink>
                        },
                        {
                            key: 'audio',
                            icon: <I><AudioOutlined/></I>,
                            label: <OrgNavLink to="/explore?type=audio">{t('audio','Audio')} {count('Audio')}</OrgNavLink>
                        },
                        {
                            key: 'documents',
                            icon: <I><FileOutlined/></I>,
                            label: <OrgNavLink to="/explore?type=documents" >{t('docs','Docs')} {count('Document')}</OrgNavLink>
                        },
                        {
                            key: 'font',
                            icon: <I><FontSizeOutlined/></I>,
                            label: <OrgNavLink to="/explore?type=fonts" >{t('fonts','Fonts')} {count('Font')}</OrgNavLink>
                        },
                    ]
                },

                !currentUser && currentOrg?.public_collections?.length > 0 ? {
                    key: 'public',
                    label: <OrgNavLink to={'/public'}><BrandedText>{t('nav-public-library','Public Library')}</BrandedText></OrgNavLink>,
                } : null,

                ability.can('show', 'Workflow') ? {
                    key: 'workflow',
                    icon: <I><WorkflowIcon/></I>,
                    label: <OrgNavLink to='/collaborate/workflows'><BrandedText>{t('nav-workflow','Workflow')}</BrandedText></OrgNavLink>,
                } : null,

                ability.can('view_manage_menu', 'Organization') ? {
                    key: 'manage',
                    icon: <I><SettingOutlined/></I>,
                    label: (
                        <>
                            {isBrowser() && <OrgNavLink to='/manage/status'><BrandedText>{t('nav-manage','Manage')}</BrandedText></OrgNavLink>}
                            {isMobile() && <ManageMenu/>}
                        </>
                    )
                } : null
            ])}
        />
    )
}

const SubscriptionBadge = ({})=>{
    const org = useCurrentOrg()

    const ability = useContext(AbilityContext);
    
    const {t} = useTranslation();

    if(org.free || org.custom_pricing || !ability.can('manage', 'Organization')) return <></>

    return (
        <>
            {org.stripe_status == 'trialing' && (
                <OrgNavLink to={'/manage/billing'}>
                <Tooltip title={t('tooltip-manage-billing','Manage Billing')}>
                        <Tag color={'orange'} icon={<ClockCircleOutlined/>}>{t('trial-ending','Trial ending')} <TimeAgo date={org.trial_ends_at}/>...</Tag>
                    </Tooltip>
                </OrgNavLink>
            )}

            {org.stripe_status == 'past_due' && (
                <OrgNavLink to={'/manage/billing'}>
                    <Tooltip title={t('tooltip-manage-billing','Manage Billing')}>
                        <Tag color={'red'} icon={<ExclamationOutlined/>}>{t('payment-past-due','Payment Past Due')}</Tag>
                    </Tooltip>
                </OrgNavLink>
            )}
        </>
    )
}

const NetworkIndicator = ({})=> {
    const {disconnected} = useUploadsState();
    const {t} = useTranslation();

    if(!disconnected) return <></>;

    return (
        <Popover
            title={<><WifiOutlined/> {t('network-disconnected-title','Network Disconnected')}</>}
            content={<><LoadingOutlined/> {t('network-disconnected-message','Please wait until your browser reconnects...')}</>}
        >
            <Tag color={'red'} id='network-outage-indicator'><WifiOutlined/></Tag>
        </Popover>
    )
}

const BulkJobIndicator = ({})=>{
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    const {bulkJobs} = useBulkJobsState()

    const [processingBulkJobs, setProcessingBulkJobs] = useState([])
    useEffect(()=>{
        setTimeout(()=>{
            if(currentOrg && currentUser) {
                api.get('/api/bulk_jobs/processing').then(res => {
                    setProcessingBulkJobs(res.data)
                })
            }
        }, 100)
    }, [currentOrg?.id, currentUser?.id])

    useEffect(()=>{
        setProcessingBulkJobs(Object.values(bulkJobs))
    }, [Object.keys(bulkJobs).length])

    const activeJobs = processingBulkJobs.filter(bj => bj.progress < 100)

    let totalPercent = 0;
    let jobsCount = 0;
    Object.values(bulkJobs).map(bj => {
        if(bj.progress < 100) {
            totalPercent += bj.progress
            jobsCount += 1
        }
    })
    totalPercent = totalPercent / jobsCount;

    if(!activeJobs.length || !totalPercent) return <></>

    return (
        <Popover
            header={<strong>Bulk Jobs</strong>}
            overlayStyle={{width: 300}}
            content={
                <List
                    size={'small'}
                    dataSource={activeJobs}
                    renderItem={bj => (
                        <List.Item>
                            {bj.action}
                            <br/>
                            <BulkJobProgress id={bj.guid}/>
                        </List.Item>
                    )}
                />
            }
        >
            <Progress
                type="circle"
                status={'active'}
                percent={totalPercent}
                width={40}
                strokeColor={'grey'}
                format={p => <span style={{color:'white'}}>{p.toFixed()}%</span>}
            />
        </Popover>
    )
}

const BrandedText = ({children}) => {
    const currentOrg = useCurrentOrg()

    return (
        <span style={{color: currentOrg?.header_text_color || 'fff'}}>{children}</span>
    )
}

export {BrandedText}

import useCurrentUser from "./helpers/useCurrentUser";
import BrandedButton from "./widgets/BrandedButton";
import SharesTable from "@/components/manage/SharesTable";
import {Parser} from "html-to-react";
import {markdown} from "markdown";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {AssetActions} from "~/components/explore/BulkEditButton";
import {DownloadModal} from "~/components/explore/DownloadButton";
import {ShareModal} from "~/components/explore/ShareButton";
import {AccessRequestModal} from "@/components/explore/AccessRequestButton";
import I from "@/components/widgets/i";
import {AssetNavKeys} from "@/components/explore/BrowseGrid";
import {useLoadAssetFromGuid} from "@/components/widgets/AssetLink";
import {TroubleShoot} from "@/routes/Index";

const LanguageSelect = ({selectTag=false})=>{
    const { t, i18n } = useTranslation();

    const currentUser = useCurrentUser()
    const {dispatch} = useContext(AppContext)

    useEffect(()=>{
        if(currentUser && currentUser?.lang !== i18n.language) i18n.changeLanguage(currentUser.lang)
    }, [currentUser?.id])

    const updateLanguage = lang => i18n.changeLanguage(lang)

    const [changing, setChanging] = useState()

    const change = lang => {
        return ()=>{
            if(currentUser) {
                setChanging(true)
                api.put(`/api/sign_up`, {user: {lang}}).then(res =>{
                    dispatch({type:'user_updated', user: res.data})
                    setChanging(false)

                    updateLanguage(lang).then(()=>{
                        message.success(t('language-updated','Language updated!'))
                    })
                })
            } else {
                updateLanguage(lang)
            }
        }
    }

    let current;
    switch(i18n.language) {
        case 'fr':
        case 'fr-FR':
            current = 'fr'
            break
        default:
            current = 'en'
    }

    const icons = {
        en: '🇺🇸',
        fr: '🇫🇷'
    }

    const select = val => change(val)()

    if(selectTag) {
        return (
            <Select value={current} onChange={select}>
                <Select.Option value={'en'}>{icons.en} English</Select.Option>
                <Select.Option value={'fr'}>{icons.fr} Français</Select.Option>
            </Select>
        )
    } else {
        return {
            key: 'lang',
            label: <div aria-label={t('language-selection-menu', 'Language Selection Menu')}>{changing ? <LoadingOutlined/> : icons[current]}</div>,
            children: [
                {
                    key: 'en',
                    onClick: change('en'),
                    icon: icons.en,
                    label: <>English {current === 'en' && <CheckOutlined/>}</>
                },
                {
                    key: 'fr',
                    onClick: change('fr'),
                    icon: icons.fr,
                    label: <>French {current === 'fr' && <CheckOutlined/>}</>
                },
            ]

        }
    }

}

export {LanguageSelect}

const SitewideAnnouncements = ({})=>{
    const {t} = useTranslation();
    const currentUser = useCurrentUser()

    const key = 'sitewide_announcements'

    const [sas, setSas] = useState()

    useEffect(() => {
        api(`/api/sitewide_announcements`).then(res => {
            const value = JSON.parse(localStorage.getItem(key) || '[]');

            setSas(res.data.filter(sa => value.indexOf(sa.id) === -1))

            if(res.data.length > 0) setVisible(true)
        })
    }, []);

    const [visible, setVisible] = useState()

    const accept = sa => {
        setVisible(false)

        if(!currentUser) {
            const value = JSON.parse(localStorage.getItem(key) || '[]');
            value.push(sa.id)
            localStorage.setItem(key, JSON.stringify(value))

        } else {
            api.post(`/api/sitewide_announcements/${sa.id}/accept`).then(res => {
                console.log(res.data)
            })
        }
    }

    const ref = useRef()

    useEffect(()=>{
        if(visible) setTimeout(()=> ref.current?.focus(), 100)
    }, [visible])

    return (<>
        {sas?.map(sa => (
            <Modal
                open={visible}
                title={<><AlertOutlined/> {sa.title}</>}
                footer={
                    <Button block type={'primary'} onClick={() => accept(sa)} ref={ref}>
                        <CheckOutlined/> {t('accept', 'Accept')}
                    </Button>
                }
                keyboard={false}
                closeIcon={false}
                closable={false}
                // width={'50%'}
            >
                <>
                    <div style={{ whiteSpace: 'pre-wrap',}}>{(new Parser).parse(markdown.toHTML(sa.text))}</div>
                </>
            </Modal>
        ))}
    </>);
}

const TroubleShootLink = ()=>{
    const {t} = useTranslation();
    const [visible, setVisible] = useState()
    const [troubleshoot, setTroubleshoot] = useState()

    if(troubleshoot) return <TroubleShoot/>
    
    return (
        <>
            <a tabIndex={0} href={'javascript://'} onClick={() => { setVisible(true) }}>{t('trouble-shoot', 'Troubleshoot')}</a>
            <Modal
                open={visible}
                onOk={setTroubleshoot}
                onCancel={()=>setVisible()}
                title={t('troubleshoot-header', 'Reset Mediagraph')}
            >
                {t('troubleshoot-prompt', 'If you\'re having a problem or seeing unusual behavior, click Confirm below to refresh Mediagraph site data. Only Mediagraph data, like site-specific cache and cookies, will be reset. You will be prompted to log back into your account afterwards.')}

            </Modal>
        </>
    )
}