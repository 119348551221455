import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {InfoWindow, Marker, GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import {useFilters} from "../helpers/useFilters";
import {useLocation} from "react-router-dom";
import {useAggsState} from "../../contexts/AggsContext";
import {useLoadAssetFromGuid} from "../widgets/AssetLink";
import {useCurrentAssetsState} from "../../contexts/CurrentAssetContext";
import {LoadingOutlined} from "@ant-design/icons";
import {useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useTranslation} from "react-i18next";

const libraries = ['places'];

export default ({})=> {
    const {t} = useTranslation();
    const {coords} = useAggsState()

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.googleApiKey,
        libraries
    })

    const [map, setMap] = useState(null)

    const {zoomAsset} = useCurrentAssetsState()

    useEffect(()=>{
        if(!zoomAsset || !map) return
        console.log('zoomAsset', zoomAsset)

        // When coming from another view mode, let the BoundCalculator run first:
        setTimeout(()=>{
            map.setZoom(17);
            map.panTo(new window.google.maps.LatLng(zoomAsset.latitude, zoomAsset.longitude));
        }, 1000)
    }, [map, zoomAsset?.id])

    const onLoad = useCallback(function callback(map) {
        setMap(map)

        const input = document.getElementById("pac-input");
        input.style.display = 'auto'
        const searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', function() {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach(function(place) {
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const toolbarHeight = document.getElementById('browse-grid-header')?.clientHeight + 32

    const location = useLocation()

    const {setFiltersKeepSelection, filters} = useFilters()

    useEffect(()=>{
        if(!map) return;

        if(filters.bounds) {
            const ba = filters.bounds.split(',').map(c => parseFloat(c))

            const ne = new google.maps.LatLng({lat: ba[0], lng: ba[1]})
            const sw = new google.maps.LatLng({lat: ba[2], lng: ba[3]})

            const bounds = new google.maps.LatLngBounds()
            bounds.extend(ne)
            bounds.extend(sw)

            console.log('AssetMap useEffect', bounds)
            map.fitBounds(bounds)
            if(ba[4]) map.setZoom(ba[4])
        }
    }, [!map])

    const mapLoaded = useRef()
    const mapMoved = useRef()
    const boundsSet = useRef()

    const loadMore = () => {
        if(!map) return
        if(!mapLoaded.current) return mapLoaded.current = true

        if(filters.bounds) boundsSet.current = true
        else if(boundsSet.current && !filters.bounds) return boundsSet.current = false

        console.log('loadMore')

        mapMoved.current = true

        const bounds = map.getBounds()
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const zoom = map.getZoom()

        setFiltersKeepSelection(true, {bounds: [ne.lat(), ne.lng(), sw.lat(), sw.lng(), zoom].join(',')})
    }

    const searchRef = useRef()

    return useMemo(()=> (
        <AutoSizer>
            {({height, width}) => (
                <div style={{width, height: height - toolbarHeight}}>
                    {isLoaded ? (
                        <>
                            {!mapMoved.current && !filters.bounds && <BoundCalculator map={map}/>}

                            <input
                                id="pac-input"
                                className="controls"
                                type="text"
                                placeholder={t('placeholder-search-map',"Search Map...")}
                                ref={searchRef}
                                style={{bottom:-100, right:-100, position: 'fixed'}}
                            />

                            <GoogleMap
                                mapContainerStyle={{width, height: height - toolbarHeight}}
                                // zoom={1}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                onIdle={loadMore}
                                onZoom={loadMore}
                            >
                                {coords?.map(c => <AssetMarker coord={c} key={c.id} map={map}/>)}
                            </GoogleMap>
                        </>
                    ) : (
                        <><LoadingOutlined/> Loading Map...</>
                    )}
                </div>
            )}
        </AutoSizer>
    ), [location.pathname, location.search, isLoaded, coords]);
}

const AssetMarker = ({coord, map})=> {
    const loadAssetFromGuid = useLoadAssetFromGuid()

    const {id, latitude, longitude} = coord

    const {cursorAssetId} = useCurrentAssetsState()
    const isCursor = cursorAssetId == id

    const {selectedAssetIds} = useSelectedAssetsState()
    const selected = selectedAssetIds.indexOf(id) != -1

    const onClick = ()=>{
        loadAssetFromGuid(id)
    }

    let url
    if(isCursor) url = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
    else {
        url = `https://maps.google.com/mapfiles/ms/icons/${selected ? 'blue' : 'red'}-dot.png`
    }

    const [hoverMarker, setHoverMarker] = useState()
    const [asset, setAsset] = useState()

    const onMouseOver = ()=>{
        setHoverMarker(true)
        if(asset) return

        api(`/api/assets/${id}/thumb`).then(res => {
            setAsset(res.data)
        })
    }

    const onClose = ()=>{
        setHoverMarker(null)
    }

    const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) }

    return (
        <>
            <Marker
                key={id}
                position={position}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseOut={onClose}
                icon={{
                    url,
                    // origin: new google.maps.Point(0, 0),
                    // size: new google.maps.Size(20, 32),
                }}
            />

            {hoverMarker && (
                <InfoWindow
                    marker={id}
                    visible
                    onClose={onClose}
                    position={position}
                    options={{
                        pixelOffset: new window.google.maps.Size(0, -30)
                    }}
                >
                    <div>
                        {!asset && <LoadingOutlined style={{margin:'2em'}}/>}
                        {asset && (
                            <>
                                <img src={asset.thumb_url}/>
                            </>
                        )}
                    </div>
                </InfoWindow>
            )}
        </>
    )
}

const BoundCalculator = ({map})=> {
    const {coords} = useAggsState()

    useMemo(()=>{
        const bounds = new window.google.maps.LatLngBounds()

        coords?.forEach(c => {
            const {latitude: lat, longitude: lng} = c
            if(lat && lng) bounds.extend(new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng)))
        })

        if(!coords?.length) {
            map?.setCenter(new window.google.maps.LatLng(41.850033, -87.6500523))
            map?.setZoom(3)
        } else {
            map?.fitBounds(bounds)
        }
    }, [coords])

    return <></>
}