// 	* ExploreHome: `LeftSideBar`, will use Aggs/Selected/Filters/AssetGroup context
//      * useMemo on sections w/ agg key(s) as deps
// 	* dispatch only from `AssetsList`
// 	* consolidated with `SelectedAssetsAggsContext`? -- both re-render NodeTitles only? YES
// 		* useMemo in NodeTitles w/ aggs as deps

import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    aggs: {},
    total: null,
    allAssetCounts: {},
    coords: []
}

const AggsStateContext = createContext(initialState);
const AggsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('AggsContext reducer:', action.type, action);

    switch (action.type) {
        case 'aggsLoaded':
            return {...state,
                aggs: action.aggs,
                total: action.total,
                allAssetCounts: action.allAssetCounts,
                coords: action.coords,
                params: action.params
            };

        case 'incrementTotal':
            return {...state, total: state.total + 1}

        case 'setTotal':
            return {...state, total: action.total}

        case 'remove':
            return {...state, total: state.total - action.count}

        default:
            return state;
    }
}

function AggsProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <AggsStateContext.Provider value={state}>
            <AggsDispatchContext.Provider value={dispatch}>
                {children}
            </AggsDispatchContext.Provider>
        </AggsStateContext.Provider>
    )
}

function useAggsState() {
    const context = useContext(AggsStateContext)
    if (context === undefined) {
        throw new Error('useAggsState must be used within a AggsProvider')
    }
    return context
}

function useAggsDispatch() {
    const context = useContext(AggsDispatchContext)
    if (context === undefined) {
        throw new Error('useAggsDispatch must be used within a AggsProvider')
    }
    return context
}

export {
    AggsProvider,
    useAggsState,
    useAggsDispatch
};
