import React, {useEffect, useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import setTitle from "../helpers/setTitle";
import AccessRequestsTable from "./AccessRequestsTable";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import {useOrgPath} from "../helpers/OrgNavLink";
import CustomMetaFields from "./CustomMetaFields";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation();
    setTitle(t('access-requests','Access Requests'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const getPath = useOrgPath()
    const { tab: routeTab } = useParams();

    const [tab, setTab] = useState(routeTab || 'browse');

    useEffect(()=>{
        if(routeTab?.length) setTab(routeTab)
    }, [routeTab])

    const navigate = useNavigate();

    const clickTab = key => {
        navigate(getPath(`/manage/access-requests${key != 'browse' ? `/${key}` : ''}`))
        setTab(key)
    }

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search','Search...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}
            <Tabs
                activeKey={tab}
                onTabClick={clickTab}
                tabBarExtraContent={isBrowser() && Extra}
            >
                <TabPane tab={t("browse-access-requests","Browse Access Requests")} key="browse">
                    <AccessRequestsTable q={searchValue} onLoaded={onLoaded}/>
                </TabPane>

                <TabPane tab={<>{t('custom-fields','Custom Fields')}</>} key="custom-fields">
                    <CustomMetaFields ownerType={'AccessRequest'} typeTitle={t('access-request','Access Request')}/>
                </TabPane>
            </Tabs>
        </>
    );
}
