import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    Table,
    Tag,
    Timeline,
    Typography,
    message,
    Card,
    Tooltip,
    Checkbox,
    Space,
    Popover,
    Badge,
    Row,
    Col,
    Descriptions,
    Radio,
    Divider, Drawer, Collapse, Button
} from "antd";

import filesize from 'filesize'

import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import {DragPreviewImage, useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import TagSelect from "./TagSelect";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import AutoSizer from "react-virtualized-auto-sizer";
import UploadProgress from "../uploads/UploadProgress";
import {useFilters} from "../helpers/useFilters";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";
import AssetRequirementsInfo from "./AssetRequirementsInfo";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAssetsPageDispatch, useAssetsPageState} from "../../contexts/AssetsPageContext";
import {
    CheckCircleFilled,
    CheckCircleTwoTone, CheckOutlined,
    ExclamationCircleOutlined,
    WarningTwoTone
} from "@ant-design/icons";
import RightsPackage from "./RightsPackage";
import {useAbility} from "@casl/react";
import {AbilityContext, Can} from "../helpers/Can";
import {CreatorTag} from "../explore/CreatorTagList";
import Asset from "../explore/Asset";
import api from "../api";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import DeleteAssetButton from "./DeleteAssetButton";
import {useAssetLoader} from "../explore/AssetsManager";
import useSetCurrentAsset from "../helpers/useSetCurrentAsset";
import {useViewMode} from "../explore/ViewMenu";
import {useTranslation} from "react-i18next";

export default ({})=> {
    const {t} = useTranslation();
    const ability = useAbility(AbilityContext);

    const {assetIds, assetsLoading, moreAssetsLoading} = useLoadedAssetsState()
    const total = assetIds.length

    const assetsPageDispatch = useAssetsPageDispatch()

    const {page} = useAssetsPageState()
    const tableAssetIds = _.compact(assetIds.slice((page - 1) * 25, (page - 1) * 25 + 25))

    const {currentUpload: upload} = useAssetGroupState()

    const columnDefs = [
        {
            title: t('asset','Asset'),
            key: 'created_at',
            sorter: true,
            width: '100%',
            render: ({id}) => <RowRender id={id}/>
        }
    ];

    const {selectedAssetIds: selectedRowKeys} = useSelectedAssetsState()
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const onChange = (newSelectedKeys, assets)=> {
        selectedAssetsDispatch({type:'selectNone'});
        assets.map(({id}) => selectedAssetsDispatch({type:'selectAsset', id}))
    }

    const {setFilters} = useFilters();
    const {assetLoader} = useAssetLoader()
    const [loadingPage, setLoadingPage] = useState()

    const handleTableChange = (pagination, filter, sorter)=>{
        if(sorter.columnKey) setFilters(false, {sort: sorter.columnKey, order: sorter.order })

        setLoadingPage(true)
        assetLoader({
            page: pagination.current,
            cb: () => {
                assetsPageDispatch({type: 'setPage', page: pagination.current})
                setLoadingPage(false)
            }
        })
    }

    const dataSource = tableAssetIds.map(id => { return {id} })

    const {changeViewMode} = useViewMode()

    return (
        <AutoSizer>
            {({height, width}) => (
                <div style={{width:width, height:'100%'}}>
                    <Table
                        key={assetsLoading ? `loading-table` : `loaded-table`}
                        rowSelection={{onChange, selectedRowKeys, columnWidth: 15}}
                        loading={loadingPage || assetsLoading || moreAssetsLoading}
                        bordered
                        size={'small'}
                        rowKey={'id'}
                        columns={columnDefs}
                        dataSource={dataSource}
                        pagination={{defaultPageSize: 25, total: total, current: page, showSizeChanger: false }}
                        onChange={handleTableChange}
                        scroll={{y: height, scrollToFirstRowOnChange: true, x: true}}
                    />

                    {isMobile() && (
                        <Row>
                            <Col span={24} style={{padding:'.5em'}}>
                                <Button block type={'primary'} icon={<CheckOutlined/>} onClick={()=> changeViewMode('grid')}>Done</Button>
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </AutoSizer>
    );
}

const RowRender = ({id}) => {
    const {t} = useTranslation();
    const loadedAssetDispatch = useLoadedAssetsDispatch()

    const {assets} = useAssetsState()
    const asset = assets[id]

    const dispatch = useAssetsDispatch()
    const editAssetDispatch = useEditAssetsDispatch()

    const [attrs, setAttrs] = useState({...asset} || '');

    useEffect(() => {
        setAttrs({...asset})
    }, [asset.updated_at])

    const onChange = (field, display)=>{
        return (value)=> {
            const data = {}
            data[field] = value
            attrs[field] = value
            setAttrs({...attrs})
            api.put(`/api/assets/${asset.id}`, {asset:data}).then(res => {
                dispatch({type:'updateAsset', asset: res.data})
                editAssetDispatch({type:'increment'})
                message.success(`${display || field} ${t('updated','updated')}`)
            })
        }
    }

    const hasTag = name => {
        return !!_.find(asset.tags, {name})
    }

    const toggleTag = name => {
        const field = hasTag(name) ? 'remove_tag_names' : 'add_tag_names'
        onChange(field, 'Tags')([name])
    }

    const {currentUpload} = useAssetGroupState();

    const [{ opacity }, drag, preview] = useDrag({
        item: { asset, type: 'asset' },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        })
    });

    let color, icon;
    switch(asset.aasm_state) {
        case 'pending':
            color = 'default'
            icon = <ClockCircleOutlined/>
            break
        case 'uploading':
        case 'processing':
            color = 'processing'
            icon = <SyncOutlined spin/>
            break
        case 'processed':
            color = 'success'
            icon = <CheckCircleOutlined/>
    }

    const statusTag = <Tag color={color} icon={icon}>{t(asset.aasm_state,asset.aasm_state)}</Tag>

    const creatorUpdated = ()=> {
        // Trigger requirements check:
        editAssetDispatch({type:'increment'})
    }

    const setCurrentAsset = useSetCurrentAsset()

    return (
        <>
            <Row gutter={[8,8]}>
                <Col xs={24} lg={6} style={{height:'100%'}}>
                    <Space direction={'vertical'} style={{width:'100%'}}>
                        {asset.aasm_state == 'processed' ? (
                            <AssetRequirementsInfo asset={asset}/>
                        ) : (
                            <UploadProgress asset={asset} width={'75%'} type={'line'}/>
                        )}

                        <div ref={drag}>
                            <DragPreviewImage src={getEmptyImage().src} connect={preview}/>

                            <Popover
                                title={'Details'}
                                trigger={'hover'}
                                content={
                                    <Space direction={'vertical'}>
                                        <Descriptions bordered size={'small'} column={1}>
                                            <Descriptions.Item label={t('filename','Filename')}>
                                                <Typography.Text code><small>{asset.filename}</small></Typography.Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={t('file-size','File Size')}>
                                                {filesize(asset.file_size)}
                                            </Descriptions.Item>
                                            {asset.captured_at && (
                                                <Descriptions.Item label={t('captured','Captured')}>
                                                    {asset.captured_at}
                                                </Descriptions.Item>
                                            )}
                                            <Descriptions.Item label={t('uploaded','Uploaded')}>
                                                {asset.created_at}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Space>
                                }
                            >
                                <a onClick={()=> setCurrentAsset(asset)} id={`asset-show-link-${asset.id}`} style={{position:'relative', display:'block'}}>
                                    <AssetBrowseImage asset={asset} assetSize={'100%'} thumbType={'permalink'} padding={'0.5em'} fileIconSize={'50%'}/>
                                    {!asset.permalink_url && <>{asset.filename}</>}
                                </a>
                            </Popover>
                        </div>
                    </Space>
                </Col>

                <Col xs={24} lg={6}>
                    <Card size={'small'} title={t('description','Description')}>
                        <Typography.Paragraph
                            editable={asset.editable && {onChange: onChange('description')}}
                        >
                            {attrs.description}
                        </Typography.Paragraph>
                    </Card>

                    <Card
                        size={'small'}
                        title={
                            <>
                                {t('creator','Creator')}&nbsp;
                                {currentUpload.contribution.require_creator_tag && (asset.submitted || asset.creator_tag_met ?
                                    <RequirementMetTag/> :
                                    <RequiredIcon tooltip={t('creator-tag-required','Creator Tag Required')}/>
                                )}
                            </>
                        }
                        style={{marginTop:'.5em'}}
                    >
                        <CreatorTag cTag={asset.creator_tag} asset={asset} editable={asset.editable} width={'100%'} afterUpdate={creatorUpdated}/>
                    </Card>
                </Col>

                <Col xs={24} lg={6}>
                    <Card size={'small'} title={
                        <>
                            {t('keywords','Keywords')} &nbsp;
                            {currentUpload.tag_suggesters?.find(ts => ts.required) && (asset.submitted || asset.tag_suggesters_met ?
                                <RequirementMetTag/> :
                                <RequiredIcon tooltip={t('keyword-selection-required','Keyword Selection Required')}/>
                            )}
                        </>
                    }>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <TagSelect asset={asset}/>

                            <Collapse>
                                {currentUpload.tag_suggesters?.map(ts => (
                                    <Collapse.Panel
                                        key={ts.id}
                                        header={
                                            <>
                                                <Tooltip title={isBrowser() && !ts.required && t('tooltip-choose-any-that-apply','Choose any that apply.')}>{ts.name}</Tooltip>
                                                &nbsp;
                                                {ts.required && (asset.unmet_tag_suggester_ids?.indexOf(ts.id) != -1 ? <RequiredIcon tooltip={t('tooltip-must-choose-at-least-one-keyword-from-list','Must choose at least one keyword from this list.')}/> : <RequirementMetTag/>)}
                                            </>
                                        }>
                                        {_.compact(ts.tag_names).map(name => (
                                            <div key={`${ts.id}-${name}`}>
                                                <Checkbox
                                                    checked={hasTag(name)}
                                                    onChange={()=>toggleTag(name)}
                                                >
                                                    {name}
                                                </Checkbox>
                                            </div>
                                        ))}
                                    </Collapse.Panel>
                                ))}

                            </Collapse>
                        </Space>
                    </Card>
                </Col>

                <Col xs={24} lg={6}>
                    <Card
                        size={'small'}
                        title={
                            <>
                                {t('permissions-granted','Permissions Granted')} &nbsp;
                                {currentUpload.contribution.require_rights_package && (asset.submitted || asset.rights_package_met ?
                                    <RequirementMetTag/> :
                                    <RequiredIcon tooltip={t('tooltip-selection-required','Selection Required')}/>
                                )}
                            </>
                        }>
                        <Radio.Group
                            onChange={e => onChange('rights_package_id', t('message-permission-granted','Permission Granted'))(e.target.value)}
                            style={{width:'100%'}}
                            value={asset.rights_package_id}
                        >
                            {currentUpload.contribution.rights_packages.map(rp => (
                                <Radio key={rp.id} value={rp.id} style={{display:'block'}}>
                                    <RightsPackage rightsPackage={rp} placement={'left'} style={{display:'inline', marginBottom:'.5em'}}/>
                                </Radio>
                            ))}

                            <Radio key={'none'} value={null}>
                                <em>None</em>
                            </Radio>
                        </Radio.Group>

                    </Card>

                    {!asset.submitted && <div style={{position:'absolute', bottom:0, right:'.5em'}}><DeleteAssetButton asset={asset} enabled/></div>}
                </Col>
            </Row>
        </>
    )
}

const RequiredTag = ({})=> {
    const {t} = useTranslation();
    return <Tag icon={<ExclamationCircleOutlined/>} color={'red'}>{t('required','Required')}</Tag>
}

const RequiredIcon = ({tooltip, color='red'})=> {
    return (
        <Tooltip title={tooltip}>
            <WarningTwoTone twoToneColor={color}/>
        </Tooltip>
    )
}

const RequirementMetTag = ()=>{
    return <CheckCircleTwoTone twoToneColor={'#52c41a'}/>
}

export {RequiredTag, RequirementMetTag, RequiredIcon}