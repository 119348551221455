import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import WorkflowsTable from "./WorkflowsTable";
import WorkflowForm from "./WorkflowForm";
import Content from "../Content";
import {useTranslation} from "react-i18next";
const { TabPane } = Tabs;
const { Search } = AntInput;

export default () => {
    const {t} = useTranslation();
    setTitle(t('workflows','Workflows'));

    const [drawerVisible, setDrawerVisible] = useState();

    const onClick = ()=> {
        setDrawerVisible(true)
        window.location.hash = 'new'
    }

    const onClose = ()=> {
        history.replaceState(null, null, ' ');
        setDrawerVisible(false)
    }

    const [newWorkflow, setNewWorkflow] = useState();

    const onSave = (workflow)=> {
        history.replaceState(null, null, ' ');
        onClose()
        setNewWorkflow(workflow);
    }

    if(window.location.hash == '#new' && !drawerVisible) {
        setDrawerVisible(true);
    }

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    return (
        <main role={'main'} tabIndex={0}>
            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={
                    <>
                        <Space>
                            <Search
                                placeholder={t('placeholder-search','Search...')}
                                onSearch={onSearch}
                                style={{width:'100%'}}
                                size={'medium'}
                                allowClear
                                loading={searchLoading}
                                onChange={onChange}
                            />
                            <Can I={'create'} a={'Workflow'}>
                                <Button type='primary' onClick={onClick}>
                                    <PlusOutlined/>
                                    {t('button-add-new-workflow','Add New Workflow')}
                                </Button>

                                <WorkflowForm
                                    onClose={onClose}
                                    onSave={onSave}
                                    visible={drawerVisible}
                                />
                            </Can>
                        </Space>
                    </>
                }>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-workflows'}/>
                </TabPane>
                <TabPane tab={t("browse-workflows","Browse Workflows")} key="browse">
                    <WorkflowsTable newWorkflow={newWorkflow} q={searchValue} onLoaded={onLoaded}/>
                </TabPane>
            </Tabs>
        </main>
    );
}
