import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    selectedAssetAggs: {},
}

const SelectedAggsStateContext = createContext(initialState);
const SelectedAggsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    console.log('SelectedAggsContext reducer:', action.type, action);

    switch (action.type) {
        case 'selectedAssetsAggsLoaded':
            return {...state, selectedAssetAggs: action.aggs};

        case 'clearSelectedAssetsAggs':
            return {...state, selectedAssetAggs: {}};

        default:
            return state;
    }
}

function SelectedAggsProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <SelectedAggsStateContext.Provider value={state}>
            <SelectedAggsDispatchContext.Provider value={dispatch}>
                {children}
            </SelectedAggsDispatchContext.Provider>
        </SelectedAggsStateContext.Provider>
    )
}

function useSelectedAggsState() {
    const context = useContext(SelectedAggsStateContext)
    if (context === undefined) {
        throw new Error('useSelectedAggsState must be used within a SelectedAggsProvider')
    }
    return context
}

function useSelectedAggsDispatch() {
    const context = useContext(SelectedAggsDispatchContext)
    if (context === undefined) {
        throw new Error('useSelectedAggsDispatch must be used within a SelectedAggsProvider')
    }
    return context
}

export {
    SelectedAggsProvider,
    useSelectedAggsState,
    useSelectedAggsDispatch
};
