import moment from "moment";
import {DatePicker} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

export default ({value, onChange, showTime, getLabel=label => label})=> {
    const {t} = useTranslation();

    const ranges = {}
    ranges[`${getLabel(t('today','Today'))}`] = [moment().startOf('day'), moment().endOf('day')]
    ranges[`${getLabel(t('last-2-weeks','Last 2 Weeks'))}`] = [moment().subtract(2, 'weeks'), moment().endOf('day')]
    ranges[`${getLabel(t('last-30-days','Last 30 Days'))}`] = [moment().subtract(30, 'days'), moment().endOf('day')]
    ranges[`${getLabel(t('this-year','This Year'))}`] = [moment().startOf('year'), moment().endOf('day')]
    ranges[`${getLabel(t('last-year','Last Year'))}`] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

    return (
        <DatePicker.RangePicker
            value={value}
            ranges={ranges}
            onChange={onChange}
            showTime={showTime}
            locale={getLocale()}
        />
    )
}

import fr from 'antd/es/date-picker/locale/fr_FR';

const getLocale = ()=> {
    const {i18n} = useTranslation();

    let locale;
    switch(i18n.language) {
        case 'fr':
            locale = fr
    }

    return locale;
}

export {getLocale}