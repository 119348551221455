import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom-v5-compat";
import _ from 'lodash';

import {
    Table,
    Button,
    message,
    Tooltip,
    Tag,
    Avatar,
    Space,
    Popconfirm,
    Popover,
    Drawer,
    Modal,
    Card,
    List, Divider,
    Typography, Skeleton, Dropdown, Menu, Select
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";
import {AppContext} from "../../contexts/AppContext";

import {
    DeleteOutlined, DeploymentUnitOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import GroupForm from "./GroupForm";
import WorkflowForm from "./WorkflowForm";
import {CollectionIcon, ProjectIcon, StorageFolderIcon} from "../helpers/icons";
import OrgNavLink from "../helpers/OrgNavLink";
import AssetGroupLink from "../widgets/AssetGroupLink";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";

export default ({newWorkflow, q, onLoaded}) => {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/workflows'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-workflow-deleted.','Workflow Deleted.'));
            handleTableChange();
        });
    }

    const Actions = ({workflow}) => {
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${workflow.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${workflow.id}`;

        const [currentWorkflow, setCurrentWorkflow] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`${apiPath}/${id}`).then(res => {
                setCurrentWorkflow(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(workflow.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        return (<>
            <Space size={5}>
                <Button onClick={() => view(workflow.id)} size={'middle'}>
                    <EyeOutlined/>
                    {t('button-view','View')}
                </Button>

                <Modal
                    width={800}
                    title={<><DeploymentUnitOutlined/> {workflow.name}</>}
                    footer={(
                        <Can I={'edit'} a={'Workflow'}>
                            <Button onClick={edit}>
                                <EditOutlined/>
                                {t('button-edit','Edit')}
                            </Button>
                        </Can>
                    )}
                    open={modalVisible}
                    onCancel={modalClose}
                >
                    {currentWorkflow ? (
                        <>
                            <p>
                                <Text type={'secondary'}>
                                    <em>
                                        {t('created','Created')} <TimeAgo date={currentWorkflow.created_at}/> {t('by','by')}
                                        &nbsp; <User user={currentWorkflow.user}/>
                                    </em>
                                </Text>
                            </p>

                            {currentWorkflow.description != '' && currentWorkflow.description &&
                                <Card style={{marginBottom:'1em', whiteSpace:'pre-wrap'}}>
                                    <Paragraph ellipsis={{rows: 3, expandable: true}}>
                                        {currentWorkflow.description}
                                    </Paragraph>
                                </Card>
                            }

                            <List
                                header={<strong>{t('steps','Steps')}</strong>}
                                size={'small'}
                                dataSource={currentWorkflow.steps}
                                bordered
                                renderItem={step => (
                                    <List.Item>
                                        {step.name}
                                        <br/>
                                        {step.asset_group?.name} ={'>'} {step.destination?.name}
                                    </List.Item>
                                )}
                            />
                        </>
                    ) : (
                        <Skeleton active loading/>
                    )}
                </Modal>

                {workflow.editable && (
                    <Space direction={'horizontal'}>
                        <Button onClick={edit} size={'middle'}>
                            <EditOutlined/>
                            {t('button-edit','Edit')}

                            <WorkflowForm
                                id={workflow.id}
                                visible={formVisible}
                                onSave={onSave}
                                onClose={onClose}
                            />
                        </Button>

                        <Popconfirm
                            title={t('confirm-archive-workflow','Archive Workflow?')}
                            onConfirm={() => destroy(workflow.id)}
                        >
                            <Button size={'middle'}>
                                <DeleteOutlined/>
                            </Button>
                        </Popconfirm>

                    </Space>
                )}
            </Space>
        </>);
    }

    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('steps','Steps'),
            render: (workflow) => {
                return (
                    <>{workflow.steps.length}</>
                )
            }
        },
        {
            title: t('start','Start'),
            render: (workflow) => {
                const step = _.first(workflow.steps);

                if(!step) return <em>None.</em>

                const {asset_group: assetGroup} = step;

                if(!assetGroup) return <em>Removed.</em>;

                return <AssetGroupLink assetGroup={assetGroup} searchString={`step-${step.id}`}/>
            }
        },
        {
            title: t('finish','Finish'),
            render: (workflow) => {
                const step = _.last(workflow.steps);
                const {asset_group: assetGroup} = step || {};

                if(!assetGroup) return <em>Removed.</em>;

                return <AssetGroupLink assetGroup={assetGroup} searchString={`step-${step.id}`}/>
            }
        },
        {
            key: 'action',
            width: 100,
            render: workflow => <Actions workflow={workflow}/>
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [newWorkflow]);

    useEffect(()=>{
        handleTableChange({...settings.pagination, current:1})
    }, [q])

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    const handleDownloadClick = (item)=>{
        console.log(item);
        message.success(`Downloading ${item.key.toUpperCase()}`)
    }

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
