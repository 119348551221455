import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    Drawer,
    Menu,
    Tabs,
    Space,
    message,
    Modal,
    Input as AntInput,
    Upload,
    Descriptions,
    Divider,
    Select as AntSelect, Popconfirm, Typography, Progress, Tooltip, Alert, Checkbox, Flex
} from "antd";
import GroupsTable from "./GroupsTable";
import {CheckOutlined, CloudDownloadOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UploadOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input, Select} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import TagSuggestersTable from "./TagSuggestersTable";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import MetaImportsTable from "./MetaImportsTable";
import useConsumer from "../../channels/consumer";
import {CustomMetaIcon} from "../helpers/icons";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import fileDownload from "js-file-download";
import useCurrentUser from "@/components/helpers/useCurrentUser";
import {SessionContext} from "@/contexts/SessionContext";
import useCurrentOrg from "@/components/helpers/useCurrentOrg";

export default () => {
    const {t} = useTranslation();
    setTitle(t('meta-imports','Meta Imports'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const [newMetaImport, setNewMetaImport] = useState();
    const onCreate = (newMi) => {
        setNewMetaImport(newMi)
    }

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-meta-imports', 'Search Meta Imports...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'MetaImport'}>
                    <NewMetaImportButton onCreate={onCreate}/>
                </Can>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}

            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-meta-imports'}/>
                </TabPane>
                <TabPane tab={t("browse-meta-imports","Browse Meta Imports")} key="browse">
                    <MetaImportsTable q={searchValue} onLoaded={onLoaded} newMetaImport={newMetaImport}/>
                </TabPane>
            </Tabs>
        </>
    );
}

const NewMetaImportButton = ({onCreate}) => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState()
    const onClick = ()=> {
        setMetaImport()
        setModalVisible(true)
    }

    const onCloseDirty = useOnCloseDirty(()=> setModalVisible(false))

    const autoFocusInput = useRef(null);

    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState()

    const onAddFile = (file)=> {
        setUploading(true)

        // if (file.type !== 'text/plain') {
        //     message.error(`Error: ${file.name} is not a valid import file (${file.type})`);
        //     setUploading(false)
        //     return false
        // }

        const data = new FormData()
        data.append('meta_import[file]', file)

        api.post(`/api/meta_imports`, data).then(res => {
            console.log(res)
            setUploading(false)
            setFileList([])
            setMetaImport(res.data)
            onCreate && onCreate(res.data)
        }).catch(err => {
            setUploading(false)
            message.error(`Error: ${err}`)
        })

        return false
    }

    const [metaImport, setMetaImport] = useState()

    return (<>
        <Button type='primary' onClick={onClick}>
            <PlusOutlined/>
            {t('button-new-import','New Import')}
        </Button>
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                const data = {tag_suggester: values}

                api.post(`/api/meta_imports`, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    setMetaImport(res.data)

                    message.success(t('message-meta-import-created','Meta Import created.'))

                    onCreate && onCreate(res.data);
                }).catch((err)=>{
                    actions.setSubmitting(false);
                    message.error(JSON.stringify(err.response.data))
                })
            }}
        >
            {({values, submitForm, handleSubmit, dirty}) => {
                return (
                    (<Modal
                        title={<>New Meta Import <HelpPopover code={'new-meta-import'}/></>}
                        open={modalVisible}
                        destroyOnClose
                        onCancel={() => onCloseDirty(dirty)}
                        centered
                        footer={null}
                    >
                        {metaImport ? <MetaImportSetup metaImport={metaImport} onDone={()=> setModalVisible(false)}/> : (
                            <form onSubmit={handleSubmit} id={'meta-import-form'}>
                                <Upload
                                    beforeUpload={onAddFile}
                                    fileList={fileList}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />} loading={uploading} block>{uploading ? t('uploading','Uploading...') : t('select-file','Select File...')}</Button>
                                </Upload>
                                <p style={{margin:'1em 0 0'}}><em>{t('meta-import-supported-filetypes','Supported file types: .csv, .tsv, .xls, .xlsx')}</em></p>
                            </form>
                        )}
                    </Modal>)
                );
            }}
        </Formik>
    </>);
}

const MetaImportSetup = ({metaImport, onDone, onCreate})=>{
    const {t} = useTranslation();
    const [attrs, setAttrs] = useState(metaImport)

    const consumer = useConsumer();
    const userSub = useRef();

    const [fieldsLoading, setFieldsLoading] = useState(true)
    const [customMetaFields, setCustomMetaFields] = useState([])

    useEffect(()=> {
        setFieldsLoading(true)
        api(`/api/custom_meta_fields`).then(res => {
            setFieldsLoading(false)
            setCustomMetaFields(res.data)
        })

        api(`/api/meta_imports/${metaImport.id}`).then(res => setAttrs(res.data))

        if(!userSub.current) {
            userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                received: (data) => {
                    console.log('UserChannel received', data)

                    switch(data.type) {
                        case 'metaImport':
                            if(data.attrs.id == metaImport.id) setAttrs({...attrs, ...data.attrs})
                            return

                        case 'metaImportProgress':
                            if(data.id == metaImport.id) {
                                if(data.done) {
                                    message.success(t('message-processing-Done','Processing Done!'))
                                    onDone && onDone()
                                }
                                setProgress(data.progress)
                            }
                            return
                    }
                }
            });
        }

        return ()=> {
            userSub.current.unsubscribe()
        }
    }, [metaImport?.id])

    const onChange = (column, value)=>{
        column.mapping = value
        api.put(`/api/meta_imports/${metaImport.id}/mapping`, {column}).then(res => {
            message.success(t('message-meta-import-updated!','Meta Import Updated!'))
            setAttrs(res.data);
        })
    }

    const update = (field)=> {
        return (value)=> {
            const meta_import = {}
            meta_import[field] = value
            api.put(`/api/meta_imports/${metaImport.id}`, {meta_import}).then(res => {
                message.success(t('message-meta-import-updated!','Meta Import Updated!'))
                setAttrs(res.data);
            })
        }
    }

    const [loading, setLoading] = useState()
    const [progress, setProgress] = useState()
    const [processing, setProcessing] = useState()
    const process = ()=> {
        setLoading(true)
        api.post(`/api/meta_imports/${metaImport.id}/start_process`).then(res => {
            setLoading(false)
            setProcessing(true)
        })
    }

    const [downloading, setDownloading] = useState()
    const downloadUnmatchedValues = ()=>{
        setDownloading(true)
        api(`/api/meta_imports/${metaImport.id}/unmatched_values`).then(res => {
            fileDownload(res.data, `${attrs.filename}.unmatched-values.csv`);
            setDownloading(false)
        })
    }

    if(processing) {
        return (
            <>
                <LoadingOutlined/> {t('processing-progress','Processing progress')}:
                <Progress percent={progress || 0} status={'active'}/>
            </>
        )
    }

    const standardFields = Object.keys(MetaFields).filter(f => !f.match(/ao_/))
    const artworkFields = Object.keys(MetaFields).filter(f => f.match(/ao_/))

    if(attrs.aasm_state === 'errored') {
        return <Alert type={'error'} showIcon message={t('parse-error','Parse Error')} description={`${t('parse-error-description','Unable to parse file, please try another.')} (${attrs.parse_error?.message})`}/>
    }

    return (
        <>
            {(attrs.aasm_state === 'analyzing' || attrs.aasm_state === 'pending') && (
                <Alert style={{marginBottom:'2em'}} type={'info'} showIcon message={<><em><LoadingOutlined/> {t('analyzing', 'Analyzing')}...</em></>}></Alert>
            )}

            <VerticalSpace>
                <div>
                    {t('csv-column-for-matching', 'CSV column for matching')}:
                    <br/>
                    <AntSelect name={'match_column'} value={attrs.match_column} onChange={update('match_column')} style={{width: '100%'}} placeholder={t('placeholder-choose-column', 'Choose Column...')} required>
                        {attrs.columns.map(col => (
                            <AntSelect.Option value={col.name} key={col.name}>{col.name}</AntSelect.Option>
                        ))}
                    </AntSelect>
                </div>

                <div>
                    {t('attribute-for-matching', 'Asset attribute to match on')}:
                    <br/>
                    <AntSelect name={'match_attribute'} value={attrs.match_attribute} onChange={update('match_attribute')} style={{width: '100%'}} placeholder={t('placeholder-choose-column', 'Choose Column...')} required>
                        <AntSelect.Option value={'filename'}>{t('filename','Filename')}</AntSelect.Option>
                        <AntSelect.Option value={'guid'}>{t('guid-cap','GUID')}</AntSelect.Option>
                        <AntSelect.Option value={'created_via_id'}>{t('externally-imported-id','Externally Imported ID')}</AntSelect.Option>
                    </AntSelect>
                </div>

                <Checkbox name={'partial_match'} onChange={e => update('partial_match')(e.target.checked)} checked={attrs.partial_match}>{t('enable-partial-matches','Enable Partial Matches?')}</Checkbox>

                <Divider/>

                <Descriptions bordered size='small' column={1}>
                    <Descriptions.Item label={t('filename', 'Filename')}>
                        {attrs.filename}
                        <a style={{marginLeft: '1em'}} href={metaImport.download_url} download={metaImport.filename} target={'_blank'}><DownloadOutlined/></a>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('rows', 'Rows')}>{attrs.rows}</Descriptions.Item>
                    <Descriptions.Item label={t('matches', 'Matches')}>
                        <Flex justify={'space-between'}>
                            <span>{attrs.matches}</span>

                            {attrs.unmatched_values_size > 0 && (
                                <Button icon={<CloudDownloadOutlined/>} loading={downloading} size={'small'} onClick={downloadUnmatchedValues}>{t('download-unmatched','Download Unmatched Values')}</Button>
                            )}
                        </Flex>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('duplicates','Duplicates')}>{attrs.duplicates}</Descriptions.Item>
                    <Descriptions.Item label={t('note','Note')}>
                        <Typography.Text editable={{onChange: update('note')}}>{attrs.note}</Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            </VerticalSpace>

            <Divider/>

            <Descriptions bordered size='small' column={1} title={'Headers'} className={'meta-import-headers'}>
                {attrs.columns.map(c => {

                    return (
                        <Descriptions.Item label={c.name} key={c.name} style={{width:'50%'}}>
                            <AntSelect
                                name={'mapping'}
                                value={c.mapping}
                                onChange={val => onChange(c, val)}
                                style={{width:'100%'}}
                                placeholder={t('placeholder-choose-mapping','Choose mapping...')}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => typeof option.children == 'string' && option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                <AntSelect.OptGroup label={t('standard-fields','Standard Fields')}>
                                    {standardFields.map(f => (
                                        <AntSelect.Option value={f} key={f}>{MetaFields[f].name}</AntSelect.Option>
                                    ))}
                                </AntSelect.OptGroup>

                                <AntSelect.OptGroup label={t('artwork-fields','Artwork Fields')}>
                                    {artworkFields.map(f => (
                                        <AntSelect.Option value={f} key={f}>
                                            <Tooltip placement={'right'} title={t(metaFields[f].desc,MetaFields[f].desc)}>
                                                {t(metaFields[f].name,MetaFields[f].name)}
                                            </Tooltip>
                                        </AntSelect.Option>
                                    ))}
                                </AntSelect.OptGroup>
                                <AntSelect.OptGroup label={t('custom-meta-fields','Custom Meta Fields')}>
                                    {customMetaFields.map(cmf => (
                                        <AntSelect.Option value={`custom_meta_field_${cmf.id}`} key={cmf.id}>
                                            <Tooltip placement={'right'} title={cmf.description}>
                                                {cmf.name}
                                            </Tooltip>
                                        </AntSelect.Option>
                                    ))}
                                    <AntSelect.Option value={'create'} key={'create'}>
                                        <Tooltip placement={'right'} title={t('tooltip-meta-import-create-new-custom-meta-field','A new free-form Custom Meta Field will be created and populated from this column.')}>
                                            <CustomMetaIcon/> {t('create-new','Create New')}...
                                        </Tooltip>
                                    </AntSelect.Option>
                                </AntSelect.OptGroup>
                            </AntSelect>
                        </Descriptions.Item>
                    )
                })}
            </Descriptions>

            <Popconfirm title={t('confirm-process-import','Process import?')} onConfirm={process}>
                <Button icon={<CheckOutlined/>} type={'primary'} block style={{marginTop:'2em'}} loading={loading}>{t('button-process-import','Process Import')}</Button>
            </Popconfirm>
        </>
    )
}

export {MetaImportSetup}