import React, {useEffect, useRef, useState} from 'react';
import useCurrentOrg from "../helpers/useCurrentOrg";
import {Button, ColorPicker, Divider, Flex, List, message, Modal, Popconfirm, Space, Table, Tag, Typography} from "antd";
import {FieldArray, Formik, useFormikContext} from "formik";
import api from "../api";
import * as Yup from "yup";
import {
    CheckOutlined,
    EditOutlined,
    PlusOutlined,
    RollbackOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {Form, FormItem, Input, Radio, Checkbox, Select, Switch} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import {DeleteIcon} from "../helpers/icons";
import HelpPopover from "../HelpPopover";
import setTitle from "../helpers/setTitle";
import {useTranslation} from "react-i18next";

export default ({ownerType='Asset', typeTitle='Meta'})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    setTitle(t(`custom-${ownerType.toLowerCase()}-fields`,`Custom ${typeTitle} Fields`));

    const [loading, setLoading] = useState()
    const [customMetaFields, setCustomMetaFields] = useState([])

    useEffect(()=>{
        setLoading(true)

        api(`/api/custom_meta_fields?owner_type=${ownerType}`).then(res => {
            setLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [currentOrg?.id])

    const [newModalVisible, setNewModalVisible] = useState()
    const clickNew = ()=>{
        setNewModalVisible(true)
        setTimeout(()=>{
            autoFocusInput.current.focus()
        }, 100)
    }

    const autoFocusInput = useRef(null);

    const updated = cmf => {
        const i = customMetaFields.map(cmf => cmf.id).indexOf(cmf.id)
        customMetaFields.splice(i, 1, cmf)
        setCustomMetaFields([...customMetaFields])
    }

    const destroy = cmf => {
        api.delete(`/api/custom_meta_fields/${cmf.id}`).then(res => {
            message.success(t(`message-custom-${ownerType.toLowerCase()}-field-removed`,`Custom ${typeTitle} Field removed.`))

            const i = customMetaFields.map(cmf => cmf.id).indexOf(cmf.id)
            customMetaFields.splice(i, 1)
            setCustomMetaFields([...customMetaFields])
        })
    }

    const columns = [
        {title: t('name','Name'), dataIndex: 'name'},
        {title: t('description','Description'), dataIndex: 'description'},
        {
            title: t('value','Value'),
            render: (cmf)=> (
                <>{cmf.multi ? t('multi-value','Multi-Value') : t('single-value','Single-Value')}</>
            )
        },
        {
            title: t('type','Type'),
            render: (cmf)=> (
                <>{t(cmf.value_type, cmf.value_type.toProperCase())}</>
            )
        },
        {
            title: t('controlled-vocab','Controlled Vocab'),
            render: (cmf)=> (
                <>{cmf.free ? t('free-text','Free Text') : <Tag>{cmf.custom_meta_values_attributes.length}</Tag>}</>
            )
        },
        {
            title: t('action-menu','Action Menu'),
            render: (cmf)=> (
                <>{cmf.enable_action_menu && <CheckOutlined/>}</>
            )
        },
    ]

    // if(ownerType == 'AccessRequest') {
    //     columns.push(
    //         {
    //             title: 'Required',
    //             width: 100,
    //             render: (cmf)=> (
    //                 <>{cmf.required ? 'Yes' : 'No'}</>
    //             )
    //         }
    //     )
    // }

    columns.push(
        {
            title: t('actions','Actions'),
            width: 100,
            render: (cmf)=> (
                <Space style={{textAlign:'right', marginLeft:'auto'}}>
                    <CustomMetaEditButton cmf={cmf} updated={updated} ownerType={ownerType} typeTitle={typeTitle}/>
                    <Popconfirm title={t('confirm-delete-custom-meta-field','Delete Custom Meta Field?')} onConfirm={()=> destroy(cmf)}>
                        <Button><DeleteIcon/></Button>
                    </Popconfirm>
                </Space>
            )
        }
    )


    return (
        <div style={{paddingTop:'1em'}}>
            <p>
                <Button onClick={clickNew} type={'primary'} icon={<PlusOutlined/>}>{t(`button-add-custom-${ownerType.toLowerCase()}-field`,`Add Custom ${typeTitle} Field`)}</Button>

                <CustomMetaModal
                    modalVisible={newModalVisible}
                    setModalVisible={setNewModalVisible}
                    onSuccess={cmf => setCustomMetaFields([...customMetaFields, cmf])}
                    autoFocusInput={autoFocusInput}
                    ownerType={ownerType}
                    typeTitle={typeTitle}
                />
            </p>

            <Table
                dataSource={customMetaFields}
                columns={columns}
                loading={loading}
                bordered
                rowKey={record => record.id}
                scroll={{x: true}}
                size={'small'}
            />
        </div>
    )
}

const CustomMetaEditButton = ({cmf, updated, ownerType, typeTitle})=>{
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState()

    return (
        <>
            <Button icon={<EditOutlined/>} onClick={()=> setModalVisible(true)}>{t('button-edit','Edit')}</Button>

            <CustomMetaModal cmf={cmf} modalVisible={modalVisible} setModalVisible={setModalVisible} onSuccess={updated} ownerType={ownerType} typeTitle={typeTitle}/>
        </>
    )
}

const CustomMetaModal = ({cmf = {free: false, multi: false, value_type: 'text'}, modalVisible, setModalVisible, onSuccess, autoFocusInput, ownerType, typeTitle})=>{
    const {t} = useTranslation();

    return (
        (<Formik
            initialValues={cmf}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                const method = cmf.id ? api.put : api.post;
                values.owner_type = ownerType

                method(`/api/custom_meta_fields/${cmf.id || ''}`, {custom_meta_field: values}).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    if(cmf.id) {
                        message.success(t(`custom-${ownerType.toLowerCase()}-field-updated`,`Custom ${typeTitle} Field "{{name}}" updated.`, {name: values.name}))
                    } else {
                        message.success(t(`custom-${ownerType.toLowerCase()}-field-created`,`Custom ${typeTitle} Field "{{name}}" created.`, {name: values.name}))
                    }

                    onSuccess(res.data)
                    setModalVisible(false);
                }).catch((err)=>{
                    actions.setSubmitting(false);
                    message.error(`Error: ${JSON.stringify(err.data)}`)
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit, dirty, setFieldValue, isSubmitting, resetForm}) => {
                return (
                    (<Modal
                        open={modalVisible}
                        onCancel={()=> setModalVisible(false)}
                        title={<>{cmf.id ? t('edit','Edit') : t('new','New')} Custom {typeTitle} Field</>}
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                    <SaveOutlined/>
                                    {cmf.id ? t('update','Update') : t('create','Create')}
                                </Button>
                                <Popconfirm
                                    title={t('confirm-cancel-edit-and-reset','Cancel Edit and Reset?')}
                                    onConfirm={()=>{
                                        resetForm()
                                        setModalVisible(false);
                                    }}
                                >
                                    <Button>{t('button-cancel','Cancel')}</Button>
                                </Popconfirm>
                            </Space>
                        }
                    >
                        <Form>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name','Name')} name={'name'} value={values?.name} >
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='description'>
                                <FloatLabel label={t('description-optional','Description (optional)')} name={'description'} value={values?.description} >
                                    <Input.TextArea size={'large'} name='description' autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            {/*{ownerType == 'AccessRequest' && (*/}
                            {/*    <Switch*/}
                            {/*        name={'required'}*/}
                            {/*        checkedChildren={<><ExclamationCircleOutlined/> Required</>}*/}
                            {/*        unCheckedChildren={<>Not Required</>}*/}
                            {/*        style={{marginBottom:'1em'}}*/}
                            {/*    />*/}
                            {/*)}*/}

                            <FormItem name='multi'>
                                <Radio.Group name={'multi'}>
                                    <Radio value={false} style={{display:'block'}}>{t('single-value','Single-Value')}</Radio>
                                    <Radio value={true}>{t('multi-value','Multi-Value')?.toProperCase()}</Radio>
                                </Radio.Group>
                            </FormItem>


                            <FormItem name='free'>
                                <Checkbox name={'free'}>{t('free-text-input','Free text input?')} <HelpPopover code={'custom-meta-field-free'}/></Checkbox>
                            </FormItem>

                            {values.free && (
                                <FormItem name='value_type'>
                                    <label>{t('value-type','Value Type')}: <HelpPopover code={'custom-meta-field-value-type'}/></label>
                                    <br/>
                                    <Radio.Group name={'value_type'} disabled={cmf.id}>
                                        <Radio value={'text'} style={{display:'block'}}>{t('text','Text')}</Radio>
                                        <Radio value={'date'} style={{display:'block'}}>{t('date','Date')}</Radio>
                                        <Radio value={'datetime'} style={{display:'block'}}>{t('datetime','Date and Time')}</Radio>
                                        {/*<Radio value={'number'} style={{display:'block'}}>{t('number','Number')}</Radio>*/}
                                        {/*<Radio value={'price'} style={{display:'block'}}>{t('price','Price')}</Radio>*/}
                                    </Radio.Group>
                                </FormItem>
                            )}

                            {!values.free && (
                                <>
                                    <Divider>{t('controlled-vocabulary','Controlled Vocabulary')} <HelpPopover code={'custom-meta-values'}/></Divider>

                                    {!values.multi && (
                                        <FormItem name='enable_action_menu'>
                                            <Checkbox name={'enable_action_menu'}>{t('enable-action-menu','Show in Action Menu?')} <HelpPopover code={'custom-meta-enable-action-menu'}/></Checkbox>
                                        </FormItem>
                                    )}

                                    <FieldArray
                                        name='custom_meta_values_attributes'
                                        render={arrayHelpers => {
                                            const addNewValue = () => {
                                                arrayHelpers.push({text: values.new_value});
                                                setFieldValue('new_value', null)
                                            }

                                            return (
                                                <>
                                                    <div style={{display:'flex'}}>
                                                        <Form.Item name={'new_value'} style={{flex:'auto', marginRight:'.5em'}}>
                                                            <Input name={'new_value'} placeholder={t('placeholder-enter-new-value...','Enter New Value...')} onKeyDown={e => { if(e.keyCode == 13) addNewValue() }} autoComplete={'off'}/>
                                                        </Form.Item>

                                                        <Button onClick={addNewValue} style={{flex:'none'}}><PlusOutlined/> {t('add','Add')}</Button>
                                                    </div>

                                                    <List
                                                        dataSource={values.custom_meta_values_attributes}
                                                        itemLayout={'horizontal'}
                                                        locale={{emptyText:t('no-values-yet','No values yet.')}}
                                                        size={'small'}
                                                        renderItem={cmv => <CustomMetaValue cmv={cmv}/>}
                                                    />
                                                </>
                                            );
                                        }}
                                    />
                                </>
                            )}
                        </Form>
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

const CustomMetaValue = ({cmv})=>{
    const {t} = useTranslation();
    const { values, setFieldValue } = useFormikContext();

    if(!values.custom_meta_values_attributes) return <></>

    const i = values.custom_meta_values_attributes?.indexOf(cmv)
    const toDestroy = cmv._destroy

    return (
        <List.Item
            style={{display: cmv._destroy ? 'red' : 'auto'}}
            actions={[
                <Button
                    onClick={() => {
                        if (cmv.id) {
                            setFieldValue(`custom_meta_values_attributes[${i}]._destroy`, !toDestroy)
                        } else {
                            arrayHelpers.remove(i)
                        }
                    }}
                >
                    {toDestroy ? <RollbackOutlined/> : <DeleteIcon/>}
                </Button>
            ]}
        >
            <Flex align={'center'} gap={6} justify={'flex-start'}>
                <ColorPicker
                    disabledAlpha
                    style={{marginLeft:'auto'}}
                    value={values.custom_meta_values_attributes[i]?.color_label}
                    allowClear
                    onChange={(c, hex) => {
                        setFieldValue(`custom_meta_values_attributes[${i}].color_label`, hex);
                    }}
                />

                <Typography.Text
                    disabled={toDestroy}
                    editable={!toDestroy && {
                        onChange: val => {
                            setFieldValue(`custom_meta_values_attributes[${i}].text`, val)
                        }
                    }}
                >
                    {toDestroy && t('removing','Removing') + ': '}
                    {cmv.text}
                </Typography.Text>
            </Flex>
        </List.Item>
    );
}