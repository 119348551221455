import React, {useRef, useState} from "react";
import {Button, Drawer, Menu, Tabs, Space, message, Modal, Input as AntInput} from "antd";
import GroupsTable from "./GroupsTable";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import GroupForm from "./GroupForm";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import TaxonomyTable from "./TaxonomyTable";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input, Select} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import TagSuggestersTable from "./TagSuggestersTable";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Content from "../Content";
const { TabPane } = Tabs;
const { Search } = AntInput;

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useTranslation} from "react-i18next";
import useOnCloseDirty from "../helpers/useOnCloseDirty";

export default () => {
    const {t} = useTranslation();
    setTitle(t('tag-suggesters','Tag Suggesters'));

    const [searchValue, setSearchValue] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
        setTab('browse');
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const [searchLoading, setSearchLoading] = useState()
    const onLoaded = ()=> {
        setSearchLoading(false);
    }

    const [tab, setTab] = useState('browse');

    const [newTagSuggester, setNewTagSuggester] = useState();
    const onCreate = (newTs) => {
        setNewTagSuggester(newTs)
    }

    const Extra = (
        <>
            <Space>
                <Search
                    placeholder={t('placeholder-search-tag-suggesters','Search Tag Suggesters...')}
                    onSearch={onSearch}
                    style={{width:'100%'}}
                    size={'medium'}
                    allowClear
                    loading={searchLoading}
                    onChange={onChange}
                />
                <Can I={'create'} a={'TagSuggester'}>
                    <NewTagSuggesterButton onCreate={onCreate}/>
                </Can>
            </Space>
        </>
    )

    return (
        <>
            {isMobile() && <div style={{margin:'.5em 0'}}>{Extra}</div>}

            <Tabs
                activeKey={tab}
                onTabClick={(key)=> setTab(key)}
                tabBarExtraContent={isBrowser() && Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-tag-suggesters'}/>
                </TabPane>
                <TabPane tab={t("browse-tag-suggesters","Browse Tag Suggesters")} key="browse">
                    <TagSuggestersTable q={searchValue} onLoaded={onLoaded} newTagSuggester={newTagSuggester}/>
                </TabPane>
            </Tabs>
        </>
    );
}

const NewTagSuggesterButton = ({onCreate}) => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState()
    const onClick = ()=> {
        setModalVisible(true)
    }

    const onCloseDirty = useOnCloseDirty(()=> setModalVisible(false))

    const autoFocusInput = useRef(null);

    return (<>
        <Button type='primary' onClick={onClick}>
            <PlusOutlined/>
            {t('button-add-new','Add New')}
        </Button>
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                const data = {tag_suggester: values}

                api.post(`/api/tag_suggesters/`, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    setModalVisible(false);
                    message.success(t('message-tag-suggester-created','Tag Suggester created.'))

                    onCreate && onCreate(res.data);
                }).catch((err)=>{
                    message.error(JSON.stringify(err.response.data))
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit, dirty}) => {
                const [tagNames, setTagNames] = useState([])
                return (
                    (<Modal
                        title={t('new-tag-suggester','New Tag Suggester')}
                        open={modalVisible}
                        destroyOnClose
                        onCancel={() => onCloseDirty(dirty)}
                        // onCancel={() => setModalVisible(false)}
                        centered
                        confirmLoading
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm}>
                                    <SaveOutlined/>
                                    {t('button-create','Create')}
                                </Button>
                                <Button onClick={() => setModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                            </Space>
                        }
                    >
                        <form onSubmit={handleSubmit}>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name','Name')} name={'name'} value={values?.name}
                                            description={t('placeholder-tag-suggester-name','e.g. Marketing')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>
                            <FormItem name='tag_names' style={{marginTop:'.5em'}}>
                                <Select
                                    name={'tag_names'}
                                    // value={tagNames}
                                    // onChange={setTagNames}
                                    mode='tags'
                                    style={{width:'100%'}}
                                    placeholder={t('placeholder-add-tags','Add Tags...')}
                                />
                            </FormItem>
                        </form>
                    </Modal>)
                );
            }}
        </Formik>
    </>);
}