import {
    Breadcrumb,
    Button,
    Col,
    message,
    Row,
    Space,
    Tooltip,
    Menu, Typography, Modal, notification, Select, DatePicker, Collapse, Popconfirm, Tag
} from "antd";
import {Link, useParams} from "react-router-dom";
import FileImageOutlined from "@ant-design/icons/lib/icons/FileImageOutlined";
import AppliedFilters from "./AppliedFilters";
import {
    CloudUploadOutlined,
    LoadingOutlined,
    LaptopOutlined,
    LinkOutlined,
    FormOutlined,
    EditOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    TagOutlined,
    UserOutlined, CalendarOutlined, ShoppingCartOutlined
} from "@ant-design/icons";
import {
    DeleteIcon,
    LightboxIcon,
    RefreshIcon,
    RightsIcon,
    StorageFolderIcon,
    UserGroupIcon,
    WorkflowIcon
} from "../helpers/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {SessionContext} from "../../contexts/SessionContext";
import api from "../api";
import AppstoreAddOutlined from "@ant-design/icons/lib/icons/AppstoreAddOutlined";
import ProjectOutlined from "@ant-design/icons/lib/icons/ProjectOutlined";
import FolderOutlined from "@ant-design/icons/lib/icons/FolderOutlined";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useSelectedAggsDispatch} from "../../contexts/SelectedAggsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";

import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import UploadDrawer from "../widgets/UploadDrawer";
import ContributionInfo from "../widgets/ContributionInfo";
import {AppContext} from "../../contexts/AppContext";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import ViewMenu from "./ViewMenu";
import SortMenu from "./SortMenu";
import SelectAssetsButton from "./SelectAssetsButton";
import {OrgLink, useOrgPath} from "../helpers/OrgNavLink";
import {AssetGroupInfoPopover} from "../manage/AssetGroupChooser";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import UploadHeader from "./UploadHeader";
import {GlobalHotKeys} from "react-hotkeys";
import useCurrentUser from "../helpers/useCurrentUser";
import {MembershipRequestForm} from "../registration/MembershipRequest";
import HelpPopover from "../HelpPopover";
import useRefreshAssets from "../helpers/useRefreshAssets";
import {useFilters} from "../helpers/useFilters";
import {AbilityContext} from "../helpers/Can";
import AccessRequestHeader from "./AccessRequestHeader";
import useGuestLogin from "../helpers/useGuestLogin";
import {useTranslation} from "react-i18next";
import ShareButton from "@/components/explore/ShareButton";
import {setAfterLogin} from "~/components/helpers/useAfterLogin";

export default ({quickUpload})=>{
    const {t} = useTranslation();
    const {dispatch: appDispatch} = useContext(AppContext);
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const {selectedAssetIds, reloads: selectedAssetReloads} = useSelectedAssetsState();
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const selectedAggsDispatch = useSelectedAggsDispatch()

    let location = useLocation()
    const navigate = useNavigate();

    const {filters} = useFilters()

    useEffect(()=> {
        console.log('getting selected Asset aggs');
        if(!selectedAssetIds.length)
            return selectedAggsDispatch({type: 'clearSelectedAssetsAggs'});

        (async () => {
            const res = await api.post('/api/assets/selected', {ids: selectedAssetIds});

            selectedAggsDispatch({type: 'selectedAssetsAggsLoaded', aggs: res.data });
        })();
    }, [selectedAssetIds, selectedAssetReloads]);


    // ---------------------------------------
    // Current Asset Group:
    // ---------------------------------------

    const currentUser = useCurrentUser()
    const {currentAssetGroup, currentWorkflowStep, currentUpload, viewAllType, currentContribution, myCurrentContribution, currentTag} = useAssetGroupState()
    const assetGroupDispatch = useAssetGroupDispatch();

    const currentAssetGroupTypeName = {
        'Collection': t('collections','Collections'),
        'Lightbox': t('projects','Projects'),
        'StorageFolder': t('storage-folders','Storage Folders'),
    }[currentAssetGroup?.type];

    const currentAssetGroupPathName = {
        'Collection': 'collections',
        'Lightbox': 'projects',
        'StorageFolder': 'folders',
    }[currentAssetGroup?.type];

    const currentAssetGroupIcon = {
        'Collection': <AppstoreAddOutlined/>,
        'NoCollection': <AppstoreAddOutlined/>,
        'Lightbox': <ProjectOutlined/>,
        'StorageFolder': <FolderOutlined/>,
        'Trash': <DeleteIcon/>,
    }[currentAssetGroup?.type || viewAllType];

    const { type, slug, shareCode, accessRequestId, guestCode, tagId } = useParams();

    let folderMatch, folder;

    try {
        folderMatch = location.pathname.match(new RegExp(`${slug || shareCode}\/(.*?)$`))
        folder = folderMatch && folderMatch[1]
    } catch { }

    const [loadingCurrentAssetGroup, setLoadingCurrentAssetGroup] = useState()
    const [assetGroupNotFound, setAssetGroupNotFound] = useState()

    useEffect(()=>{
        if(assetGroupNotFound) setAssetGroupNotFound(false)
    }, [slug])

    const [shareLinkModalVisible, setShareLinkModalVisible] = useState()
    const [shareLink, setShareLink] = useState()

    const [accessRequest, setAccessRequest] = useState()

    const loadAccessRequest = ()=>{
        setLoadingCurrentAssetGroup(true)
        assetGroupDispatch({type:'loadingCurrentAssetGroup'})

        api(`/api/access_requests/find?id=${accessRequestId}`).then(res => {
            setLoadingCurrentAssetGroup(false)

            if(res.data.error) {
                navigate('/')
                return message.error(res.data.error);
            }

            assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: res.data.asset_group});
            setAccessRequest(res.data.access_request)

        }).catch(err => {
            console.log(err)
        })
    }

    const guestLogin = useGuestLogin()

    useEffect(()=> {
        if(!currentOrg) return

        if(accessRequestId) {
            if(currentUser) loadAccessRequest()
            else if(guestCode) {
                guestLogin(()=> loadAccessRequest())
            }

            return;
        }

        setAccessRequest(null)

        if(tagId) {
            console.log('tagId', tagId)
            api(`/api/taxonomy_tags/${tagId}`).then(res => {
                if(res.data.error) {
                    navigate('/')
                    return message.error(res.data.error);
                }

                assetGroupDispatch({type: 'setCurrentTag', tag: res.data});

            }).catch(err => {
                console.log(err)
            })

            return ()=> {
                assetGroupDispatch({type: 'setCurrentTag', tag: null});
            }
        } else if(!tagId && currentTag) {
            console.log('setting current tag null')
            assetGroupDispatch({type: 'setCurrentTag', tag: null});
        }

        if(shareCode) {
            api(`/api/find_share_link?code=${shareCode}&folder=${folder || ''}`).then(res => {
                if(res.data.error) {
                    navigate('/')
                    return message.error(res.data.error);
                }

                appDispatch({type:'setShareLink', shareLink: res.data.share_link})

                assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: res.data.asset_group});
                setShareLink(res.data.share_link)

            }).catch(err => {
                console.log(err)
            })
            return;
        }

        const path = {
            'collections': 'collections',
            'projects': 'lightboxes',
            'folders': 'storage_folders',
            'trash': 'trash'
        }[type];

        if(!path && !currentUpload?.id) {
            assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: null});
            return
        }

        if(slug && ['view-all', 'view-none', 'trash'].indexOf(slug) === -1){
            setLoadingCurrentAssetGroup(true)
            assetGroupDispatch({type:'loadingCurrentAssetGroup'})

            api(`/api/${path}/find?slug=${slug}&folder=${folder}`).then(res => {
                setLoadingCurrentAssetGroup(false)
                assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: res.data});

                const stepId = location.hash?.match(/#step-(.+?)$/);
                if(stepId) {
                    getWorkflowStep(stepId[1])
                    location.hash = ''
                }
            }).catch(e => {
                setLoadingCurrentAssetGroup(false)
                setAssetGroupNotFound(true)
                assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: {id: 'not-found'}});
                console.log('Find Error:', e)

                if(!currentUser) {
                    setAfterLogin(location.pathname)
                    message.warning(t('please-login-to-view','Please login to access this resource.'))
                    navigate(getPath('/login'))
                }
            });
        } else {
            let assetGroupType = {
                'collections': 'Collection',
                'lightboxes': 'Lightbox',
                'storage_folders': 'StorageFolders',
                'trash': 'Trash'
            }[path];

            if(slug == 'view-none') {
                assetGroupType = `No${assetGroupType}`
            }

            assetGroupDispatch({type:'viewAllType', assetGroupType})
        }
    }, [type, slug, folder, shareCode, currentOrg?.slug, accessRequestId, tagId]);

    // ---------------------------------------
    // Upload Views:
    // ---------------------------------------

    let { uploadGuid, uploadType, contributionId, myContributionId } = useParams();

    // By Type:
    // ---------

    useEffect(()=>{
        if(!uploadType) return;

        setLoadingCurrentAssetGroup(true)
        assetGroupDispatch({type:'setUploadType', uploadType})

    }, [uploadType])

    // By Contribution:
    // ----------------
    useEffect(()=>{
        if(!myContributionId) return;

        setLoadingCurrentAssetGroup(true)

        api.get(`/api/contributions/${myContributionId}`).then(res => {
            assetGroupDispatch({type: 'setMyCurrentContribution', contribution: res.data});
            setLoadingCurrentAssetGroup(false)
        });
        return ()=> {
            assetGroupDispatch({type: 'setMyCurrentContribution', upload: null});
        }

    }, [myContributionId])

    useEffect(()=>{
        if(!contributionId) return;

        setLoadingCurrentAssetGroup(true)

        api.get(`/api/contributions/${contributionId}`).then(res => {
            assetGroupDispatch({type: 'setCurrentContribution', contribution: res.data});
            setLoadingCurrentAssetGroup(false)
        });
        return ()=> {
            assetGroupDispatch({type: 'setCurrentContribution', upload: null});
        }

    }, [contributionId])

    // By Upload Session:
    // ------------------

    useEffect(()=>{
        if(!uploadGuid || !currentOrg) return;
        console.log('BrowseHeader getting Upload')

        selectedAssetDispatch({type:'selectNone'}) // Prevent search from firing before state change
        setLoadingCurrentAssetGroup(true)

        api.get(`/api/uploads/${uploadGuid}`).then(res => {
            assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});
            setLoadingCurrentAssetGroup(false)
        }).catch(res => {
            notification.error({message: 'Error', description: t('error-upload-not-found','Upload not found...'), duration: 0})
            setLoadingCurrentAssetGroup(false)
        });

        return ()=> {
            assetGroupDispatch({type: 'setCurrentUpload', upload: null});
        }
    }, [uploadGuid, currentOrg?.id]);

    const [uploadName, setUploadName] = useState()
    useEffect(()=>{
        if(currentUpload) setUploadName(currentUpload?.name)
    }, [currentUpload?.name])

    const getPath = useOrgPath()

    const clickNewUpload = (e)=> {
        const id = currentAssetGroup?.contribution_id || currentContribution?.id || myCurrentContribution?.id || currentUpload?.contribution_id

        api.post(`/api/contributions/${id}/uploads`, {
            asset_group_folder_id: currentAssetGroup?.folder?.id
        }).then(res => {
            message.success(t('message-starting-new-upload','Starting new Upload!'))
            const upload = res.data;

            // NOTE: setting this causes a flicker:
            // assetGroupDispatch({type: 'setCurrentUpload', upload});

            navigate(getPath(`/upload/${upload.contribution.slug}/${upload.guid}?${upload.contribution.asset_group?.type == 'StorageFolder' ? '' : 'submitted=false'}`));
        })
        return false;
    }

    const clickUploadExisting = ()=> {
        api.post(`/api/uploads/${currentUpload.id}/reopen`).then(res => {
            message.success(t('message-reopening-upload','Reopening upload!'))
            const upload = res.data;
            assetGroupDispatch({type: 'setCurrentUpload', upload});
            navigate(getPath(`/upload/${upload.contribution.slug}/${upload.guid}?submitted=false`));
        })
        return false;
    }

    const [assetGroupName, setAssetGroupName] = useState(currentAssetGroup?.name)
    useEffect(()=> {
        setAssetGroupName(currentAssetGroup?.name)
    }, [currentAssetGroup?.name])

    const refreshAssets = useRefreshAssets();

    const ref = useRef();
    const keyMap = { OPTION_R: 'alt+r' }

    const handlers = {
        OPTION_R: (e) => {
            e.preventDefault()
            refreshAssets()
        }
    }

    const ability = useContext(AbilityContext);

    const viewAllTypeTitle = viewAllType == 'NoCollection' ? 'No Collection' : viewAllType

    if(quickUpload || (isMobile() && currentUpload)) return <div id={'browse-grid-header'}><UploadHeader quickUpload={quickUpload}/></div>

    return (
        (<GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref}>
            <Modal
                open={shareLinkModalVisible}
                onCancel={()=> setShareLinkModalVisible(false) }
                cancelText={'No Thanks'}
                okButtonProps={{style:{display:'none'}}}
            >
                <h2>Welcome to the Lightbox!</h2>

                <p>You can return to this Lightbox by clicking the original share link.</p>

                <p>
                    If you would like an easier and more permanent access to the Lightbox, please use the form below to request becoming a Lightbox member.
                </p>

                <MembershipRequestForm shareLink={shareLink} onSave={()=> setShareLinkModalVisible(false)} />
            </Modal>
            <div id={'browse-grid-header'} style={{position:'sticky', top: 0, background: 'white', padding:'1em 0 1em 1em', zIndex: 1}}>
                <UploadDrawer/>
                <Row wrap={false}>
                    <Col style={{marginBottom: loadingCurrentAssetGroup ? 0 : 'auto'}} flex={'auto'}>
                        <Row wrap={false}>
                            <Col flex={'auto'} style={{minWidth:0, flex: '0 1 auto'}}>
                                {viewAllType && (
                                    <Breadcrumb aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                        <Breadcrumb.Item>
                                            {currentAssetGroupIcon}
                                            <span>{viewAllTypeTitle}</span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>{filters.type?.toProperCase() || 'All'}</Breadcrumb.Item>
                                    </Breadcrumb>
                                )}

                                {accessRequest && (
                                    <>
                                        <Breadcrumb style={{textOverflow:'hidden', whiteSpace:'nowrap', overflow:'hidden'}} aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                            <Breadcrumb.Item>
                                                <FormOutlined/> {ability.can('manage', 'AccessRequest') ? <OrgLink to={'/manage/access-requests'} tabIndex={0}>{t('access-requests','Access Requests')}</OrgLink> : <>{t('access-requests','Access Requests')}</>}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item>
                                                {accessRequest.name}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </>
                                )}

                                {currentTag && (
                                    <>
                                        <Breadcrumb style={{textOverflow:'hidden', whiteSpace:'nowrap', overflow:'hidden'}} aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                            <Breadcrumb.Item>
                                                {currentTag.sub_type === 'person' && <><UserOutlined style={{marginRight:'.5em'}}/> {t('people','People')}</>}
                                                {currentTag.sub_type === 'event' && <><CalendarOutlined style={{marginRight:'.5em'}}/> {t('events','Events')}</>}
                                                {currentTag.sub_type === 'product' && <><ShoppingCartOutlined style={{marginRight:'.5em'}}/> {t('products','Products')}</>}
                                                {(currentTag.sub_type === 'keyword' || currentTag.organizer) && <><TagOutlined/> {t('keywords','Keywords')}</>}
                                            </Breadcrumb.Item>
                                            {currentTag.path_names?.map((name, i) => {
                                                const last = i == currentTag.path_names.length - 1
                                                return (
                                                    <Breadcrumb.Item key={name}>
                                                        {name}
                                                    </Breadcrumb.Item>
                                                );
                                            })}
                                        </Breadcrumb>
                                    </>
                                )}

                                {currentAssetGroup && !accessRequest && !currentTag && (
                                    <>
                                        <Breadcrumb style={{textOverflow:'hidden', whiteSpace:'nowrap', overflow:'hidden'}} aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                            <Breadcrumb.Item>
                                                {currentAssetGroupIcon}
                                                {!isMobile() && <span>{currentAssetGroupTypeName}</span>}
                                            </Breadcrumb.Item>

                                            {!currentAssetGroup.folder && currentAssetGroup.path_names?.map((name,i) => {
                                                const last = i == currentAssetGroup.path_names.length - 1
                                                // Don't show path on mobile or LB shares:
                                                if(!last && (isMobile() || (!currentUser && currentAssetGroup.type == 'Lightbox'))) return

                                                const slugs = (currentAssetGroup.lightbox_path_slugs || currentAssetGroup.path_slugs)[i]
                                                const path = shareCode ?
                                                    `/share-links/${shareCode}` :
                                                    `/explore/${currentAssetGroupPathName}/${slugs}`

                                                return (
                                                    <Breadcrumb.Item key={i}>
                                                        <Space>
                                                            <Button onClick={()=> navigate(getPath(path))} tabIndex={0} type={'text'} size={'small'}>
                                                                {last ? <Typography.Text underline>{assetGroupName}</Typography.Text> : name}
                                                            </Button>

                                                            {last && <AssetGroupInfoPopover c={currentAssetGroup} setName={setAssetGroupName} viewOnly/>}
                                                        </Space>
                                                    </Breadcrumb.Item>
                                                );
                                            })}

                                            {currentAssetGroup.folder?.path_names?.map((name,i) => {
                                                const currSlug = currentAssetGroup.folder.path_slugs[i]
                                                const path = shareCode ?
                                                    `/share-links/${shareCode}` :
                                                    `/explore/${currentAssetGroupPathName}/${currentAssetGroup.slug}/${currSlug}`

                                                return (
                                                    <Breadcrumb.Item key={i}>
                                                        <Space>
                                                            <Button onClick={()=> navigate(getPath(path))} tabIndex={0} type={'text'} size={'small'}>
                                                                {name}
                                                            </Button>

                                                            {i === currentAssetGroup.folder.path_names.length - 1 &&
                                                                <AssetGroupInfoPopover c={currentAssetGroup.folder || currentAssetGroup} viewOnly/>}
                                                        </Space>
                                                    </Breadcrumb.Item>
                                                );
                                            })}
                                        </Breadcrumb>
                                    </>
                                )}

                                {currentUpload?.contribution && (
                                    <Breadcrumb aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                        <Breadcrumb.Item>
                                            <CloudUploadOutlined/>
                                            {!isMobile() && <span>{t('uploads','Uploads')}</span>}
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item key={'contribution'}>
                                            <ContributionInfo contribution={currentUpload.contribution}>
                                                {currentUpload.contribution.user_group && <Tooltip title={'User Group'}><UserGroupIcon/> {currentUpload.contribution.name} <InfoCircleOutlined/> </Tooltip>}
                                                {currentUpload.contribution.asset_group?.type == 'Lightbox' && (
                                                    <OrgLink to={`/explore/projects/${currentUpload.contribution.asset_group.slug}`}>
                                                        {currentUpload.asset_group_folder && (
                                                            <>
                                                                <LightboxIcon/> {currentUpload.asset_group_folder.path_names.join(' / ')}  <InfoCircleOutlined/>
                                                            </>
                                                        ) || (
                                                            <>
                                                                <Tooltip title={'Lightbox'}>
                                                                    <LightboxIcon/> {currentUpload.contribution.name} <InfoCircleOutlined/>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </OrgLink>
                                                )}
                                                {currentUpload.contribution.asset_group?.type == 'StorageFolder' && <Tooltip title={'Storage Folder'}><StorageFolderIcon/> {currentUpload.contribution.name} <InfoCircleOutlined/> </Tooltip>}

                                                {currentUpload.standalone && <Tooltip title={'Link'}><LinkOutlined/> {currentUpload.contribution.name} <InfoCircleOutlined/> </Tooltip>}
                                            </ContributionInfo>
                                        </Breadcrumb.Item>

                                        {uploadName?.length && (
                                            <Breadcrumb.Item key={currentUpload.id}>{uploadName}</Breadcrumb.Item>
                                        )}
                                    </Breadcrumb>
                                )}

                                {(!(currentAssetGroup || currentUpload || viewAllType || uploadType || currentContribution || myCurrentContribution || currentTag) || assetGroupNotFound) && (
                                    <Breadcrumb aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                        {loadingCurrentAssetGroup ? (
                                            <Breadcrumb.Item>
                                                <LoadingOutlined/>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <>
                                                {assetGroupNotFound ? (
                                                    <em>{t('not-found','Not Found')} <HelpPopover code={'asset-group-not-found'}/> </em>
                                                ) : (
                                                    <>
                                                        <Breadcrumb.Item>
                                                            <FileImageOutlined/>
                                                            <span>{t('assets','Assets')}</span>
                                                        </Breadcrumb.Item>
                                                        <Breadcrumb.Item>{filters.type?.toProperCase() || t('all','All')}</Breadcrumb.Item>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Breadcrumb>
                                )}

                                {uploadType && (
                                    <Breadcrumb aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                        <Breadcrumb.Item>
                                            <CloudUploadOutlined/>
                                            <span>{t('my-uploads','My Uploads')}</span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item style={{textTransform:'capitalize'}}>{uploadType}</Breadcrumb.Item>
                                    </Breadcrumb>
                                )}

                                {(currentContribution || myCurrentContribution) && (
                                    <>
                                        <Breadcrumb  aria-label={t('asset-browse-breadcrumbs', 'Asset Browse Breadcrumbs')}>
                                            <Breadcrumb.Item>
                                                <CloudUploadOutlined/>
                                                {currentContribution ? (
                                                    <span>{t('contributions','Contributions').toProperCase()}</span>
                                                ) : (
                                                    <span>{t('my-uploads', 'My Uploads')}</span>
                                                )}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item style={{textTransform:'capitalize'}}>
                                                {(myCurrentContribution || currentContribution).name}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </>
                                )}

                            </Col>

                            <Col flex={'none'} style={{padding:'0 .5em'}}>
                                <Space size={'small'}>
                                    {currentUpload?.done && (
                                        <Tooltip title={t('tooltip-reopen-upload',`Reopen Upload to add/edit Assets.`)}>
                                            <Button block shape='circle' icon={<CloudUploadOutlined/>} onClick={clickUploadExisting} size={'small'} ghost type={'primary'} id={'browse-header-upload-btn'} aria-label={t('upload','Upload')}/>
                                        </Tooltip>
                                    )}

                                    {currentContribution?.enabled && (
                                        <Tooltip title={isBrowser() && t('tooltip-start-new-upload','Start New Upload')}>
                                            <Button icon={<CloudUploadOutlined/>} onClick={clickNewUpload} size={'small'} ghost type={'primary'}>
                                                Upload
                                            </Button>
                                        </Tooltip>
                                    )}

                                    {currentUser && currentAssetGroup?.enable_contribution && currentAssetGroup.sub_type != 'project' && (
                                        <Tooltip title={`${t('upload-into','Upload Into')} ${currentAssetGroup.name}`}>
                                            <Button block icon={<CloudUploadOutlined/>} onClick={clickNewUpload} size={'small'} ghost type={'primary'} id={'browse-header-upload-btn'} shape={'circle'} aria-label={t('upload','Upload')}/>
                                        </Tooltip>
                                    )}

                                    {/*{currentUser && currentAssetGroup?.type === 'Lightbox' && currentAssetGroup?.sub_type !== 'project' && (*/}
                                    {/*    <ShareButton assetGroup={currentAssetGroup} iconOnly tooltip={t('share-lightbox', 'Share Lightbox')}/>*/}
                                    {/*)}*/}

                                    <AppliedFilters/>
                                </Space>
                            </Col>
                        </Row>
                    </Col>

                    {!assetGroupNotFound && (
                        <Col flex={'none'} style={{textAlign:'right', marginRight:'1em', marginLeft:'auto', height:24}}>
                            <Space size={2}>
                                <SelectAssetsButton/>

                                <Tooltip title={isBrowser() && <>{t('tooltip-refresh-assets','Refresh Assets')}: Option-R</>}>
                                    <Button size='small' type={'text'} onClick={refreshAssets} aria-label={t('refresh-assets','Refresh Assets')}>
                                        <RefreshIcon/>
                                    </Button>
                                </Tooltip>

                                <SortMenu/>
                                <ViewMenu/>
                            </Space>
                        </Col>
                    )}
                </Row>
                <UploadHeader/>

                {accessRequest && <AccessRequestHeader accessRequest={accessRequest}/>}
            </div>
        </GlobalHotKeys>)
    );
}