import React, {useEffect} from "react";

import {useNavigate} from "react-router-dom-v5-compat";
import {useParams} from "react-router-dom";
import useOrgSlug from "../helpers/useOrgSlug";
import useCurrentUser from "../helpers/useCurrentUser";
import api from "../api";
import {message} from "antd";
import useGuestLogin from "../helpers/useGuestLogin";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

const UploadLink = ({quick})=>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentUser = useCurrentUser()
    const org = useOrgSlug()
    const {slug: contributionSlug} = useParams()

    const guestLogin = useGuestLogin()

    const getPath = useOrgPath()

    useEffect(()=>{
       if(!org) return

        api(`/api/contributions/find?slug=${contributionSlug}`).then(res => {
            console.log(res.data)

            const startUpload = ()=> {
                api.post(`/api/contributions/${res.data.id}/uploads`, {upload: {quick}}).then(res => {
                    message.success(t('starting-new-upload','Starting new Upload!'))

                    const upload = res.data;
                    const pathPrefix = quick ? 'quick-upload' : 'upload'
                    navigate(getPath(`/${pathPrefix}/${upload.contribution.slug}/${upload.guid}`));
                }).catch(()=>{
                    message.error(t('error-starting-new-upload','Error Starting new Upload!'))
                })
            }

            if(currentUser) {
                startUpload()

            } else if(res.data.is_public){
                guestLogin(()=> startUpload())

            } else {
                message.info('Please Login to Upload.')
                sessionStorage.setItem('afterLogin', window.location.pathname)
                navigate(getPath(`/login`));
            }
        })
    }, [org?.id])

    return <></>
}

const QuickUploadLink = ()=> <UploadLink quick/>;

export {UploadLink, QuickUploadLink}