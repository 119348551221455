import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";

import {Sigma, RandomizeNodePositions, RelativeSize, SigmaEnableWebGL, ForceAtlas2} from 'react-sigma';
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useAggsState} from "../../contexts/AggsContext";
import LoadingIcon from "antd/es/button/LoadingIcon";
import ForceLink from "react-sigma/lib/ForceLink";
import {useFilters} from "../helpers/useFilters";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAssetLoader} from "./AssetsManager";
import {Skeleton} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

export default ({})=> {
    const currentOrg = useCurrentOrg()
    const {params} = useAggsState()
    const {assetLoader} = useAssetLoader()

    const [loading, setLoading] = useState()
    const [graph, setGraph] = useState()

    useEffect(()=>{
        if(!params) {
            assetLoader();
        } else {
            params.graph = true
            setLoading(true)
            setGraph()
            api('/api/assets/search', {params}).then(({data}) => {
                setLoading(false)
                console.log(data)

                // Vertices:
                // {field: 'visible_tag_names', term: 'asdf', weight: 0.23094246559677628, depth: 0}
                // Edges::
                // {source: 8, target: 10, weight: 0.0013725479774862712, doc_count: 4}

                // TODO: set color for each node/edge type:
                setGraph({
                    nodes: data.vertices.map((v,id) => {
                        const color = checkApplied(v.term) ? Colors.blue : '#222'

                        return {id, label: v.term, size: v.weight * 100, color}}
                    ),
                    edges: data.connections.map((c,id) => {

                        return {
                            id,
                            type: 'arrow',
                            source: c.source,
                            target: c.target,
                            label: c.doc_count,
                            size: c.weight * 100,
                            color: "#888"
                        }
                    }),
                })
            })
        }

    }, [currentOrg?.id, params])

    const {filters, setFilters} = useFilters();

    const checkApplied = name => {
        const tagFilters = filters.tags
        return tagFilters && tagFilters.indexOf(name) != -1
    }

    const onClickNode = e => {
        const name = e.data.node.label
        setFilters(checkApplied(name), {tags: name})
    }

    if(!graph || loading) return <div style={{margin:'2em'}}><LoadingOutlined/> Loading Graph...</div>

    const toolbarHeight = document.getElementById('browse-grid-header')?.clientHeight + 32

    return (
        <AutoSizer>
            {({height, width}) => (
                <div style={{width, height}}>
                    <Sigma
                        graph={graph}
                        style={{ width, height, paddingTop: toolbarHeight}}
                        settings={{
                            drawEdges: true,
                            clone: false,
                            drawLabels: true,
                            labelThreshold: 1,
                            renderEdgeLabels: true,
                        }}
                        onOverNode={e => console.log("Mouse over node: " + e.data.node.label)}
                        onClickNode={onClickNode}
                    >
                        <RandomizeNodePositions>
                            <ForceLink
                                background
                                easing="cubicInOut"
                                iterationsPerRender={1}
                                linLogMode
                                // timeout={1000}
                                worker
                            />
                            <RelativeSize initialSize={15} />
                        </RandomizeNodePositions>
                    </Sigma>
                </div>
            )}
        </AutoSizer>
    )
}