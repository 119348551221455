import React, {createContext, useContext, useReducer} from "react";

const initialState = {
    uploadingAssets: [],
    fileAdds: 0,
    disconnected: false,
    uploads: {} // array of Files by Upload ID
}

const UploadsStateContext = createContext(initialState);
const UploadsDispatchContext = createContext(initialState);

const reducer = (state, action) => {
    // console.log('UploadsContext reducer:', action.type, action);

    let key
    switch (action.type) {
        case 'addFiles':
            key = action.upload?.guid || 'new'
            state.uploads[key] = action.files;
            window.FileQueue[key] = [(window.FileQueue[key] || []), ...action.files]

            return {...state, uploads: {...state.uploads}, fileAdds: state.fileAdds + 1};

        case 'filesProcessed':
            const filenames = _.map(action.files,'name')
            state.uploads[action.guid] = state.uploads[action.guid].filter(file => filenames.indexOf(file.name) == -1);
            return {...state, uploads: {...state.uploads}};

        case 'addUploadingAsset':
            return {...state, uploadingAssets: state.uploadingAssets.concat(action.asset) };

        case 'addUploadingAssets':
            // Why is this filter needed?
            const uploadingAssets = _.uniqBy([..._.filter(action.assets, {aasm_state: 'pending'})], a => a.id)
            return {...state, uploadingAssets};

        case 'uploadProgress':
            const asset = _.find(state.uploadingAssets, {id: action.asset.id})
            if(!asset) return state;

            const i = _.indexOf(state.uploadingAssets, asset)

            asset.percent = action.percent
            asset.stats = action.stats

            state.uploadingAssets.splice(i, 1, {...asset});

            return {...state, uploadingAssets: [...state.uploadingAssets]};

        case 'triageNewFiles':
            key = action.upload?.guid
            state.uploads[key] = state.uploads.new;
            window.FileQueue[key] = [(window.FileQueue[key] || []), ...state.uploads.new]
            state.uploads.new = null;

            return {...state, uploads: {...state.uploads}, fileAdds: state.fileAdds + 1};

        case 'cancelNewFiles':
            window.FileQueue[null] = []
            state.uploads[null] = []
            state.uploads.new = []

            return {...state, uploads: {...state.uploads}};

        case 'disconnected':
            return {...state, disconnected: true}

        case 'reconnected':
            return {...state, disconnected: false}

        default:
            return state
    }
}

const UploadsProvider = ({children})=> {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <UploadsStateContext.Provider value={state}>
            <UploadsDispatchContext.Provider value={dispatch}>
                {children}
            </UploadsDispatchContext.Provider>
        </UploadsStateContext.Provider>
    )
}

function useUploadsState() {
    const context = useContext(UploadsStateContext)
    if (context === undefined) {
        throw new Error('useUploadsState must be used within a Provider')
    }
    return context
}

function useUploadsDispatch() {
    const context = useContext(UploadsDispatchContext)
    if (context === undefined) {
        throw new Error('useUploadsDispatch must be used within a Provider')
    }
    return context
}

export {
    UploadsProvider,
    useUploadsState,
    useUploadsDispatch
};
