import React from 'react';

import { NavLink, withRouter } from "react-router-dom";

import {Layout, Menu, Breadcrumb, Input, Tooltip} from 'antd';
const { Content, Sider } = Layout;

import AppHeader from '../AppHeader';
import {InboxOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {useStorageState} from "react-storage-hooks";
import OrgNavLink from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

const ManageLayout = withRouter(props => {
    const {t} = useTranslation();
    const { location } = props;
    const [{collapsed}, setState] = useStorageState(sessionStorage, 'collaborate-sider-collapse', {});
    return (
        <Layout>
            <AppHeader/>
            <Layout>
                <Sider width={200} style={{background: '#fff'}} trigger={null} collapsible collapsed={collapsed}>
                    <Menu
                        mode="inline"
                        selectedKeys={location.pathname}
                        style={{height: '100%', borderRight: 0}}
                    >
                        <Menu.Item key='/collaborate/workflows'>
                            <OrgNavLink to='/collaborate/workflows'>
                                <InboxOutlined/>
                                <span>{t('workflows','Workflows')}</span>
                            </OrgNavLink>
                        </Menu.Item>
                        {/*<Menu.Item key="1">Projects</Menu.Item>*/}
                        {/*<Menu.Item key="2">Lightboxes</Menu.Item>*/}
                        <Menu.Item key={'hide'}>
                            <Tooltip title={!collapsed && 'Hide Menu'} placement={'right'}>
                                <a onClick={() => setState({collapsed: !collapsed})}>
                                    {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                    {collapsed && <span>Show Menu</span>}
                                </a>
                            </Tooltip>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Content
                        style={{
                            background: '#fff',
                            padding: '0 1em 1em 1em',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            margin: 0,
                            minHeight: '100vh',
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
});

export default ManageLayout;
