import React from "react";
import Text from "antd/lib/typography/Text";
import {useTranslation} from "react-i18next";

export default ({pagination}) => {
    const {t} = useTranslation();

    return (
        <>
            {pagination.total &&
                <Text type={'secondary'} style={{marginRight:'.5em', marginLeft: 'auto'}}>
                    <em>
                        {pagination.total} {pagination.total != 1 ? t('total-results','Total Results') : t('total-result','Total Result')}
                    </em>
                </Text>
            }
        </>
    )
}