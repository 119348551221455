import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import queryString from "query-string";
import {useSelectedAssetsDispatch} from "../../contexts/SelectedAssetsContext";
import qs from "qs";

const keys = ['tags', 'face_tags', 'auto_tags', 'hide_tags', 'hide_auto_tags', 'hide_face_tags', 'nsfw_detected', 'nsfw_cleared', 'auto_tagged', 'moderation_names', 'user_ids', 'exts', 'rights', 'taxonomy', 'hide_taxonomy', 'rights', 'rating', 'file_size', 'file_size_range', 'workflow_state', 'sql', 'q',
    'sort', 'order', 'creator_tag_id', 'unsaved_creator', 'no_creator', 'contracts', 'releases', 'expired', 'rights_code', 'has_people', 'has_gps', 'has_alt_text', 'created_at', 'captured_at', 'updated_at',
    'contract_asset_id', 'release_ids', 'submitted', 'bounds', 'aspect', 'proximity_word_1', 'proximity_word_2', 'proximity_max_gaps', 'proximity_field', 'type', 'unsubmitted', 'optimization_request', 'checkout', 'vote', 'aasm_state', 'custom_meta', 'block_level',
    'collection_ids', 'storage_folder_ids', 'lightbox_ids', 'selection', 'download_levels', 'watermark', 'frame_status', 'missing_captured_at', 'unidentified_faces', 'faces_indexed', 'faces_not_searched'];

const singleKeys = ['sql', 'q', 'sort', 'order', 'rating', 'contracts', 'releases', 'expired', 'has_people', 'has_gps', 'has_alt_text', 'file_size_range', 'auto_tagged', 'faces_indexed', 'nsfw_detected', 'nsfw_cleared',
    'captured_at', 'created_at', 'updated_at', 'submitted', 'bounds', 'aspect', 'proximity_word_1', 'proximity_word_2', 'proximity_max_gaps', 'proximity_field', 'type', 'unsubmitted', 'optimization_request', 'checkout',
    'no_creator', 'vote', 'aasm_state', 'custom_meta', 'block_level', 'collection_ids', 'storage_folder_ids', 'lightbox_ids', 'selection', 'watermark', 'frame_status', 'missing_captured_at', 'unidentified_faces', 'faces_not_searched'];

function useFilters() {
    const navigate = useNavigate();
    let location = useLocation();

    const init = {}
    keys.map(key => {
        init[key] = singleKeys.indexOf(key) != -1 ? null : []
    })

    if(!init.rating) init.rating = []
    if(!init.captured_at) init.captured_at = []
    if(!init.created_at) init.created_at = []
    if(!init.updated_at) init.updated_at = []
    if(!init.workflow_state) init.workflow_state = []
    if(!init.download_levels) init.download_levels = []

    const parseFilters = ()=> {
        const filters = {...init, ...qs.parse(window.location.search.replace(/^\?/,''), {arrayFormat: 'bracket'})}
        window.filters = filters
        return filters
    }

    const doSetFilters = (applied, attrs)=>{
        const filters = parseFilters()

        console.log('doSetFilters', applied, attrs, filters)

        // Check single keys for existing filter applied:
        let found
        for(let key of singleKeys) {
            if(attrs[key]) {
                filters[key] = attrs[key]
                found = true
            }
        }

        // Not found in single keys, when `applied` true toggle each attr:
        if(!found) {
            _.keys(attrs).map(key => {
                filters[key] = applied ? _.without(filters[key], attrs[key]) : [...(filters[key] || []), attrs[key]]
            })
        }

        // Deep compact:
        const reducedFilters = Object.entries(filters).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
        console.log('reducedFilters',reducedFilters)

        // Update search string to retain state:
        // if(location.hash != '#search') location.hash = null

        // Ensure path isn't changing:
        location.pathname = window.location.pathname
        location.search = qs.stringify(reducedFilters, { arrayFormat: 'brackets' });//queryString.stringify(reducedFilters, {arrayFormat: 'bracket'})

        navigate(location)
    }

    const setFilters = (applied, attrs)=>{
        doSetFilters(applied, attrs)
    }

    const setFiltersKeepSelection = (applied, attrs)=>{
        doSetFilters(applied, attrs)
    }

    const clearFilters = onlyKey =>{
        const search = qs.parse(location.search.replace(/^\?/,''), {arrayFormat: 'bracket'})
        console.log('clearFilters', onlyKey, search)

        // Ensure onlyKey isn't an event, in case `onClick={clearFilters}`:
        if(typeof onlyKey == 'string') delete search[onlyKey]
        else keys.map(key => delete search[key])

        location.search = qs.stringify(search, {arrayFormat: 'bracket'});
        location.hash = null
        navigate(location)
    }

    const replaceFilters = (newFilters, path)=>{
        if(path) location.pathname = path;
        location.search = qs.stringify(newFilters, {arrayFormat: 'bracket'})
        location.hash = null
        navigate(location)
    }

    const removeFilter = key => {
        clearFilters(key)
    }

    const filters = parseFilters()

    let filterCount = _.filter([
        Object.keys(filters.custom_meta || {}).length == 0,
        filters.rights.length == 0,
        filters.contract_asset_id.length == 0,
        filters.release_ids.length == 0,
        filters.user_ids.length == 0,
        filters.exts.length == 0,
        filters.rating.length == 0,
        filters.captured_at.length == 0,
        filters.created_at.length == 0,
        filters.updated_at.length == 0,
        filters.workflow_state.length == 0,
        !(filters.watermark && filters.watermark != ''),
        !(filters.block_level && filters.block_level != ''),
        !(filters.vote && filters.vote != ''),
        !(filters.aasm_state && filters.aasm_state != ''),
        !(filters.no_creator && filters.no_creator != ''),
        !(filters.unsaved_creator && filters.unsaved_creator != ''),
        !(filters.creator_tag_id && filters.creator_tag_id != ''),
        !(filters.file_size_range && filters.file_size_range != ''),
        !(filters.sql && filters.sql != ''),
        !(filters.q && filters.q != ''),
        !(filters.contracts && filters.contracts != ''),
        !(filters.releases && filters.releases != ''),
        !(filters.expired && filters.expired != ''),
        !(filters.has_people && filters.has_people != ''),
        !(filters.unsubmitted && filters.unsubmitted != ''),
        !(filters.has_gps && filters.has_gps != ''),
        !(filters.has_alt_text && filters.has_alt_text != ''),
        !(filters.auto_tagged && filters.auto_tagged != ''),
        !(filters.nsfw_detected && filters.nsfw_detected != ''),
        !(filters.nsfw_cleared && filters.nsfw_cleared != ''),
        !(filters.submitted && filters.submitted != ''),
        !(filters.bounds && filters.bounds != ''),
        !(filters.aspect && filters.aspect != ''),
        !(filters.proximity_word_1 && filters.proximity_word_1 != ''),
        !(filters.optimization_request && filters.optimization_request != ''),
        !(filters.checkout && filters.checkout != ''),
        !(filters.storage_folder_ids && filters.storage_folder_ids != ''),
        !(filters.collection_ids && filters.collection_ids != ''),
        !(filters.lightbox_ids && filters.lightbox_ids != ''),
        !(filters.download_levels && filters.download_levels != ''),
        !(filters.frame_status && filters.frame_status != ''),
        !(filters.missing_captured_at && filters.missing_captured_at != ''),
        !(filters.unidentified_faces && filters.unidentified_faces != ''),
        !(filters.faces_not_searched && filters.faces_not_searched != ''),
    ], bool => !bool).length

    filterCount += filters.tags.length +
        filters.auto_tags.length +
        filters.face_tags.length +
        filters.hide_tags.length +
        filters.hide_auto_tags.length +
        filters.hide_face_tags.length +
        filters.moderation_names.length +
        filters.taxonomy.length +
        filters.hide_taxonomy.length +
        filters.rights_code.length

    return {location, filters, setFilters, clearFilters, replaceFilters, setFiltersKeepSelection, removeFilter, filterCount};
}


export {useFilters}