// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"
import {useContext} from "react";
import {AppContext} from "../contexts/AppContext";
import {SessionContext} from "../contexts/SessionContext";

export default () => {
    const {state} = useContext(AppContext);
    const {state: sessionState} = useContext(SessionContext);

    const ws = document.querySelector('meta[name=action-cable-url]').content
    return createConsumer(ws + `?token=${state.jwt}&organization_id=${sessionState.currentOrg?.id || ''}`);
}
