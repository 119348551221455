import React from "react";
import Content from "../Content";
import setTitle from "../helpers/setTitle";

export default () => {
    setTitle('Manage');

    return (
        <div style={{marginTop:'1em'}}>
            <Content code={'manage-dashboard'}/>
        </div>
    )
}
