import React, {createElement, useEffect, useRef, useState} from "react";
import {DragPreviewImage, useDrag, useDrop} from "react-dnd";
import {
    Tooltip,
    Card,
    Checkbox,
    Popover,
    Rate,
    Typography,
    Divider,
    message,
    Button, Flex, Space, Badge
} from "antd";

const {Text} = Typography;

import {getEmptyImage} from "react-dnd-html5-backend";
import {
    AlertOutlined, ArrowLeftOutlined, ArrowRightOutlined,
    BulbOutlined,
    CheckCircleTwoTone,
    CheckOutlined, ClockCircleOutlined,
    ClockCircleTwoTone,
    CloseCircleOutlined,
    CloseOutlined, CloudDownloadOutlined, CloudServerOutlined, DeleteOutlined, EditOutlined,
    EyeFilled, EyeInvisibleOutlined, FileAddOutlined, FileProtectOutlined,
    FileTextOutlined, FlagOutlined,
    LoadingOutlined, LockOutlined, MinusOutlined,
    PlayCircleFilled,
    PlayCircleOutlined,
    ReconciliationOutlined, RightCircleTwoTone,
    RotateLeftOutlined,
    RotateRightOutlined, SettingOutlined, StarFilled, StarOutlined, SwapOutlined,
    SyncOutlined,
    TagOutlined, UserOutlined
} from "@ant-design/icons";

import filesize from 'filesize'
import AssetGroupWorkflows, {useApprovalState} from "../widgets/AssetGroupWorkflows";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";

import UploadProgress from "../uploads/UploadProgress";

import AssetRequirementsInfo from "../widgets/AssetRequirementsInfo";
import AssetBrowseImage from "./AssetBrowseImage";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import TimeAgo from "../helpers/TimeAgo";
import {EditableTag} from "../widgets/TagSelect";
import {useFilters} from "../helpers/useFilters";
import {isMobile, isBrowser} from "device-detect";
import DeleteAssetButton from "../widgets/DeleteAssetButton";
import {RequirementMetTag} from "../widgets/EditAssetTable";
import useSetCurrentAsset, {useSetCursorAsset} from "../helpers/useSetCurrentAsset";
import {useCurrentAssetsState} from "../../contexts/CurrentAssetContext";

import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment)

import api from "../api";
import {useSortOrder} from "./SortMenu";
import {
    BlockDownload,
    BlockView, CollectionIcon,
    DeleteIcon,
    FrameIcon,
    FrameStatusIcon, LightboxIcon,
} from "../helpers/icons";
import {BulkEditAssetMenu} from "./BulkEditButton";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useViewMode} from "./ViewMenu";
import {useTranslation} from "react-i18next";
import User from "@/components/helpers/User";
import IsolatedDiv from "@/components/helpers/IsolatedDiv";
import VerticalSpace from "@/components/helpers/VerticalSpace";
import AutoLinker from "@/components/helpers/AutoLinker";
import {EditableTextArea} from "@/components/widgets/AssetModal";

// TODO: move assetSize to Context

export default ({assetSize, assetMargin, asset}) => {
    const {t} = useTranslation();

    const {viewMode, gridColor} = useViewMode()

    const currentOrg = useCurrentOrg()

    // will always trigger render:
    // TODO: should only re-render if selection has toggled:
    const {selectedAssetIds} = useSelectedAssetsState()
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const loadedAssetsDispatch = useLoadedAssetsDispatch()

    const {currentWorkflowStep, currentAssetGroup, currentUpload} = useAssetGroupState()

    const setCurrentAsset = useSetCurrentAsset()

    const { showFilenames, showIcons, showRating, showHeadline } = useViewMode()

    // console.log('rendering Asset:', asset?.id);
    const type = 'asset';

    const [{ opacity }, drag, preview] = useDrag({
        item: { asset, type },
        canDrag: ()=> !asset.trashed_at,
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
        begin: ()=> {
            selectedAssetDispatch({type: 'draggingOn'})

            if(selectedAssetIds.indexOf(asset.id) == -1)
                selectedAssetDispatch({type: 'toggleAsset', id: asset.id})
        },
        end: (item, monitor)=> {
            const altKey = monitor.getDropResult()?.dropEffect == 'copy'

            if(!altKey) {
                selectedAssetDispatch({type: 'selectNone'})
            }

            selectedAssetDispatch({type: 'draggingOff'})
        }
    });

    const {assetIds} = useLoadedAssetsState()
    const {cursorAssetId, currentAssetId} = useCurrentAssetsState()
    const setCursorAsset = useSetCursorAsset()

    const selected = selectedAssetIds?.indexOf(asset.id) != -1
    const currentCursor = cursorAssetId == asset.id

    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(e.shiftKey) {
            const from = assetIds.indexOf(_.last(selectedAssetIds))
            const to = assetIds.indexOf(asset.id) + 1
            const ids = from < to ? assetIds.slice(from, to) : assetIds.slice(to - 1, from)
            console.log(from,to, ids)
            selectedAssetDispatch({type: 'toggleAssets', ids: ids})
        } else if(!e.ctrlKey) {
            selectedAssetDispatch({type: 'toggleAsset', id: asset.id})
            if(!selected) setCursorAsset(asset)
        }
    };

    const onDoubleClick = (e) => {
        e.stopPropagation();
        setCurrentAsset(asset)
    };

    //--------------------
    // Asset Drop Handling
    //--------------------
    const sortOrder = useSortOrder()

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'asset',
        drop: ({asset: dropAsset}) => {
            const asset_ids = _.uniq([dropAsset.id, ...selectedAssetIds])
            loadedAssetsDispatch({type:'reorderAssets', beforeAssetId: asset.id, movingAssetIds: asset_ids})

            const key = 'reordering-loading'
            message.loading({content: t('message-reordering','Reordering...'), key})
            api.post(`/api/asset_groups/${currentAssetGroup.asset_group_id}/reorder_assets`, {asset_ids, before_asset_id: asset.id}).then(res => {
                message.destroy(key)
                message.success(t('message-assets-reordered','Assets Reordered!'))
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: ()=>  currentAssetGroup?.sortable && sortOrder == 'custom'
    });

    const isActive = canDrop && isOver;

    if(!asset) return <>{t('loading','Loading')}...</>

    const isPosts = viewMode === 'posts';

    const style = {
        float: isPosts ? 'none' : 'left',
        margin: isPosts ? '0 auto' : `0 ${assetMargin}px 0 0`,
        paddingBottom: isPosts ? '2em' : 0,
        width: assetSize,
        height: isPosts ? 'auto' : assetSize,
        backgroundColor: gridColor,
        opacity,
    }

    const [hovering, setHovering] = useState();
    const mouseEnter = e => {
        setHovering(true)
    }

    const mouseLeave = e => {
        setHovering(false)
    }

    const {approval_state} = useApprovalState(currentWorkflowStep, asset)

    if(!asset.id) {
        return (
            <Card
                bordered={false}
                style={{...style, boxShadow: 'none'}}
                cover={
                    <div ref={drop} style={{height: assetSize, width: assetSize}}>
                        {isActive && <div className={'asset-reorder-indicator'}/>}
                    </div>
                }
            />
        )
    }

    const thumbType = assetSize > 300 ? 'permalink' : (assetSize < 100 ? 'thumb' : 'grid')

    // Allow tab nav to `setCursorAsset`:
    const onFocus = ()=> {
        if(asset?.id !== cursorAssetId) {
            setCursorAsset(asset)
        }
    }

    const onKeyPress = e => {
        if(e.key === 'Enter') onClick(e)
        return true
    }

    const ref = useRef()
    useEffect(() => {
        if(cursorAssetId === asset?.id && !currentAssetId) {
            ref.current.focus({preventScroll: true})
        }
    }, [cursorAssetId, currentAssetId]);

    const UploadProgressWrapper = (
        <>
            {(asset.aasm_state == 'uploading' || asset.aasm_state == 'uploaded' || asset.aasm_state == 'processing' || asset.aasm_state == 'pending' || asset.aasm_state == 'processing_error') && (
                <div style={{position: 'absolute', top: 5, left: 5}}>
                    <UploadProgress asset={asset} width={assetSize}/>
                </div>
            )}
        </>
    )

    const ActionMenu = (
        <IsolatedDiv className={'asset-actions'}>
            <AssetContextMenu asset={asset} trigger={'click'} id={`asset-context-menu-${asset.id}`}>
                <Button size='small' type={'text'} shape={'circle'} icon={<SettingOutlined/>} aria-label={t('actions-menu', 'Action Menu')}/>
            </AssetContextMenu>
        </IsolatedDiv>
    )

    const SelectionIndicator = (
        <div style={{position: 'absolute', top: '.5em', right: '.5em'}}>
            {selected && (
                <Checkbox checked={selected} onClick={onClick} className={'asset-checked'} tabIndex={-1}/>
            )}
        </div>
    )

    const VideoIndicator = (
        <>
            {asset.type == 'Video' && !asset.trashed_at && !hovering && asset.duration && (
                <div style={{width: assetSize, height: assetSize, display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: 0, left: 0, flexDirection: 'column'}}>
                    <div style={{fontSize: 25, zoom: assetSize / 300, backgroundColor: 'rgba(0, 0, 0, 0.3)', color: 'white', padding: 3, borderRadius: 10, textShadow: '0 1px 1px rgba(0, 0, 0, 0.4)', marginLeft: 'auto', marginRight: 'auto'}}>
                        <PlayCircleFilled/> {asset.duration && moment.duration(asset.duration, 'milliseconds').format(asset.duration >= 60 * 60 * 1000 ? 'h:mm:ss' : 'mm:ss', {trim: false})}
                    </div>
                </div>
            )}
        </>
    )

    const TrashIndicator = (
        <>
            {asset.trashed_at && (
                <div style={{position: 'absolute', top: 'calc(50% - 25px)', left: 'calc(50% - 25px)', zoom: 3}}>
                    <DeleteIcon style={{fontSize: '50px', opacity: 0.20, color: '#f00'}}/>
                </div>
            )}
        </>
    )

    const dropShadow = {filter: 'drop-shadow( 3px 3px 3px rgba(0, 0, 0, .7))'}

    const rate = rating => {
        setRating(rating)
        api.put(`/api/assets/${asset.id}`, {asset: {rating}}).then(res => {
            message.success(t('message-rating-updated', 'Rating Updated!'))
        })
    }

    const [rating, setRating] = useState(asset.rating)

    useEffect(() => {
        setRating(asset.rating)
    }, [asset.rating]);

    const Rating = (
        <>
            {showRating && (
                <Rate
                    value={rating}
                    onChange={rate}
                    disabled={!asset.editable}
                    className={'asset-grid-rating'}
                    style={{flex:'none'}}
                    character={({index, value}) => {
                        return value >= index + 1 ? <StarFilled style={dropShadow}/> : <StarOutlined style={dropShadow}/>
                    }}
                />
            )}
        </>
    )

    const [description, setDescription] = useState(asset.description)
    const updateDescription = value => {
        setDescription(value)
        api.put(`/api/assets/${asset.id}/update_description`, {asset: { description: value}}).then(res => {
            message.success(t('message-description-updated','Description Updated!'))
        })
    }

    const [headline, setHeadline] = useState(asset.headline)
    const updateHeadline = value => {
        setHeadline(value)
        api.put(`/api/assets/${asset.id}`, {asset: { headline: value}}).then(res => {
            message.success(t('message-headline-updated','Headline Updated!'))
        })
    }

    return (
        <AssetContextMenu asset={asset} trigger={'contextMenu'}>
            <Card
                id={`asset-${asset.id}`}
                className={`asset state-${asset.aasm_state}`}
                hoverable
                cover={
                    <div
                        ref={ref}
                        className={
                            `${approval_state === 'rejected' ? 'rejected-asset' : ''} asset-cover ${currentCursor && 'asset-current-cursor'} ${selected && 'asset-selected'}`
                        }
                        tabIndex={0}
                            onKeyPress={onKeyPress}
                            id={`asset-cover-${asset.id}`}
                            onFocus={onFocus}
                            aria-label={asset.alt_text || asset.description}
                        >
                            <div ref={drop}>
                                {isActive && <div className={'asset-reorder-indicator'}/>}

                                <div ref={drag} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                    <DragPreviewImage src={getEmptyImage().src} connect={preview}/>

                                    <div id={`asset-context-menu-${asset.id}`}/>

                                    {viewMode === 'posts' && (
                                        <>
                                            <AssetBrowseImage asset={asset} hovering={hovering} thumbType={thumbType} padding={0} assetSize={Config.defaultPostWidth}/>

                                            {UploadProgressWrapper}
                                            {SelectionIndicator}
                                            {TrashIndicator}
                                        </>
                                    )}

                                    {viewMode === 'grid' && (
                                        <>
                                            <AssetBrowseImage asset={asset} assetSize={assetSize} thumbType={thumbType} padding={isMobile() ? '1em' : '2.5em'} hovering={hovering}/>

                                            {UploadProgressWrapper}
                                            {ActionMenu}
                                            {SelectionIndicator}
                                            {VideoIndicator}
                                            {TrashIndicator}

                                            {showIcons && (
                                                <>
                                                    <div style={{position: 'absolute', top: '.5em', left: '.5em'}}>
                                                        {asset.max_block_level == 1 && (
                                                            <Tooltip title={t('tooltip-download-blocked', 'Download Blocked')}>
                                                                <BlockDownload style={{filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))', color: Colors.red}}/>
                                                            </Tooltip>
                                                        )}

                                                        {asset.max_block_level == 2 && (
                                                            <Tooltip title={t('tooltip-view-and-download-blocked', 'View and Download Blocked')}>
                                                                <BlockView style={{filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))', color: Colors.red}}/>
                                                                &nbsp; {/* Unclear why this is needed to trigger Tooltip */}
                                                            </Tooltip>
                                                        )}

                                                        {asset.checked_out_at && (
                                                            <Tooltip title={t('tooltip-checked-out', 'Currently Checked Out')}>
                                                                <LockOutlined style={{filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))', color: Colors.red}}/>
                                                                &nbsp; {/* Unclear why this is needed to trigger Tooltip */}
                                                            </Tooltip>
                                                        )}

                                                        {currentOrg?.frame_account && !!asset.frame_status?.length && asset.frame_status != 'none' && (
                                                            <Tooltip title={t(`tooltip-frame-status-${asset.frame_status}`, `Frame.io status: ${asset.frame_status.toProperCase().replace('_', ' ')}`)}>
                                                                <FrameStatusIcon status={asset.frame_status}/>
                                                                {/*<FrameIcon style={{padding: 4, width: 24, borderRadius:'50%', backgroundColor: Colors.frameStatuses[asset.frame_status]}}/>*/}
                                                                &nbsp; {/* Unclear why this is needed to trigger Tooltip */}
                                                            </Tooltip>
                                                        )}
                                                    </div>

                                                    <div style={{position: 'absolute', bottom: '.5em', right: '.5em'}}>
                                                        <div style={{display: 'inline'}}>
                                                            {currentWorkflowStep && (
                                                                <div style={{marginRight: '.5em', display: 'inline-block'}} onClick={e => e.stopPropagation()}>
                                                                    <AssetGroupWorkflows
                                                                        iconOnly
                                                                        step={currentWorkflowStep}
                                                                        asset={asset}
                                                                        assetGroup={currentAssetGroup}
                                                                    />
                                                                </div>
                                                            )}

                                                            {asset.expired && (
                                                                <Tooltip title={<>{t('tooltip-rights-expired', 'Rights Expired')} <TimeAgo date={asset.expires_at}/></>}>
                                                                    <ClockCircleTwoTone twoToneColor={'red'}/>
                                                                </Tooltip>
                                                            )}

                                                            {asset.contract && (
                                                                <Tooltip title={t('tooltip-this-asset-is-a-contract', 'This Asset is a Contract')}>
                                                                    <FileTextOutlined/>
                                                                </Tooltip>
                                                            )}

                                                            {asset.release && (
                                                                <Tooltip title={t('tooltip-this-asset-is-a-release', 'This Asset is a Release')}>
                                                                    <FileTextOutlined/>
                                                                </Tooltip>
                                                            )}

                                                            {currentUpload && (
                                                                <AssetRequirementsInfo asset={asset}/>
                                                            )}

                                                            <Popover
                                                                content={<AssetInfoPopover asset={asset}/>}
                                                                trigger={['hover', 'click']}
                                                                getPopupContainer={e => e.parentElement}
                                                                zIndex={9999}
                                                            >
                                                                <Button
                                                                    onKeyPress={e => e.stopPropagation()}
                                                                    onClick={e => e.stopPropagation()}
                                                                    size={'small'}
                                                                    shape={'circle'}
                                                                    type={'text'}
                                                                    icon={<EyeFilled/>}
                                                                    tabIndex={0}
                                                                    style={{marginRight: '.5em', filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))'}}
                                                                    className={'asset-modal-btn'}
                                                                    aria-label={t('view-details', 'View Details')}
                                                                />
                                                            </Popover>
                                                        </div>
                                                    </div>

                                                    <div style={{position: 'absolute', bottom: 0, left: '.5em'}}>
                                                        {currentUpload && (!asset.submitted ?
                                                                (<DeleteAssetButton asset={asset} iconOnly enabled/>) :
                                                                (<Tooltip title={<>Submitted <TimeAgo date={asset.submitted_at}/></>}><RequirementMetTag/>&nbsp;</Tooltip>)
                                                        )}
                                                    </div>
                                                </>
                                            )}

                                            <VerticalSpace size={2}>
                                                {showHeadline &&
                                                    <Flex>
                                                        <DynamicTextColor className={'asset-grid-filename'} style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', minWidth: 0, flex: '0 1 auto'}}>
                                                            {asset.headline}
                                                        </DynamicTextColor>
                                                    </Flex>
                                                }

                                                {Rating}

                                                {showFilenames && (
                                                    <Typography.Text
                                                        className={'asset-grid-filename'}
                                                        type={'secondary'}
                                                        style={{fontSize: 'smaller'}}
                                                    >
                                                        <Flex gap={2} justify={'space-between'}>
                                                            <DynamicTextColor style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', minWidth: 0, flex: '0 1 auto'}} title={asset.filename} muted={1.1}>
                                                                {asset.filename}
                                                            </DynamicTextColor>

                                                            {asset.file_size && (
                                                                <DynamicTextColor style={{flex: 'none', fontWeight: 'bolder'}} muted={0.95}>{filesize(asset.file_size, {round: true})}</DynamicTextColor>
                                                            )}
                                                        </Flex>
                                                    </Typography.Text>
                                                )}
                                            </VerticalSpace>
                                        </>
                                    )}

                                </div>
                            </div>
                    </div>
                }
                style={style}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
            >
                {viewMode === 'posts' && (
                    <VerticalSpace size={4}>
                        {showHeadline &&
                            <IsolatedDiv className={'asset-post-text'}>
                                <DynamicTextColor
                                    Component={Typography.Title}
                                    style={{margin:0}}
                                    componentProps={{
                                        level: 5,
                                        italic: true,
                                        editable: asset.editable && {onChange: updateHeadline, tooltip: t('edit-headline','Edit Headline')},
                                    }}
                                >
                                    {headline}
                                </DynamicTextColor>
                            </IsolatedDiv>
                        }

                        <Flex align={'center'} gap={6}>
                            <User user={asset.user} iconOnly size={'small'}/>

                            <IsolatedDiv className={'asset-post-description'} style={{margin:'.5em 0'}}>
                                <EditableTextArea
                                    tooltip={t('edit-description','Edit Description')}
                                    id={asset.id}
                                    value={description}
                                    displayValue={<AutoLinker text={description}/>}
                                    onChange={updateDescription}
                                    editable={asset.editable || asset.description_editable}
                                    style={{marginBottom:0}}
                                    Component={DynamicTextColor}
                                />
                            </IsolatedDiv>

                            <Space style={{marginLeft: 'auto', fontSize:12, flex: 'none'}}>
                                <Flex gap={4}>
                                    <DynamicTextColor muted={1}>
                                        <TimeAgo date={asset.created_at}/>
                                    </DynamicTextColor>
                                </Flex>
                            </Space>
                        </Flex>

                        <IsolatedDiv className={'asset-post-text'}>
                            <Flex align={'center'} gap={6} justify={'space-between'}>
                                {Rating}

                                {showFilenames && (
                                    <Flex gap={8}>
                                        <DynamicTextColor
                                            Component={Typography.Text}
                                            componentProps={{type:'secondary', copyable: true}}
                                            style={{fontSize:'smaller'}}
                                            muted={0.95}
                                        >
                                            {asset.filename}
                                        </DynamicTextColor>
                                        {asset.file_size && (
                                            <DynamicTextColor
                                                Component={Typography.Text}
                                                componentProps={{type:'secondary'}}
                                                style={{flex:'none', fontWeight:'bolder', fontSize:'smaller'}}
                                                muted={1}
                                            >
                                                {filesize(asset.file_size, {round: true})}
                                            </DynamicTextColor>
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                        </IsolatedDiv>

                        <Divider style={{margin:'.5em 0'}} className={'asset-post-text'}/>
                    </VerticalSpace>
                )}
            </Card>
        </AssetContextMenu>
    );
};


const AssetTags = ({asset}) => {
    const {filters, setFilters} = useFilters()

    return (
        <>
            {_.filter(asset.tags, {list: 'visible'}).map((tag, i) => {
                const name = tag.name.toLowerCase()
                const applied = filters.tags && filters.tags.indexOf(name) != -1
                const hidden = filters.hide_tags && filters.hide_tags.indexOf(name) != -1

                const onClick = () => {
                    setFilters(applied, {tags: name})
                }

                return (
                    <EditableTag filter key={i} editable={asset.editable} {...{tag, applied, hidden, onClick}}/>
                );
            })}
        </>
    )
}

const AssetInfoPopover = ({asset}) => {
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(true)
    const [maxRes, setMaxRes] = useState(true)

    const {currentAssetGroup} = useAssetGroupState()

    const [addedByLoading, setAddedByLoading] = useState(true)
    const [addedBy, setAddedBy] = useState()

    useEffect(() => {
        api(`/api/assets/${asset.id}/max_res`).then(res => {
            setMaxRes(res.data)
            setLoading(false)
        })
    }, [asset.id])

    useEffect(() => {
        if (!currentAssetGroup) return;

        api(`/api/assets/${asset.id}/added_by?asset_group_id=${currentAssetGroup?.asset_group_id}`).then(res => {
            setAddedBy(res.data)
            setAddedByLoading(false)
        }).catch(()=>{
            setAddedBy(false)
            setAddedByLoading(false)
        })
    }, [asset.id, currentAssetGroup?.asset_group_id])

    moment.locale(i18n.language)

    const setCurrentAsset = useSetCurrentAsset()

    return (
        <IsolatedDiv style={{width: 200}}>
            <div>
                <small>ID: <Button className='view-asset-btn' size='small' type={'link'} onClick={() => setCurrentAsset(asset)}>{asset.guid.slice(0, 8)}</Button></small>
                <Typography.Text copyable={{tooltips: t('tooltip-copy-file-id', 'Copy File ID'), text: asset.guid}}/>
                <Divider style={{margin: '.5em 0'}}/>
                {asset.description}
                <div>
                    <Typography.Text type={'secondary'}>
                        <small>
                            <Flex align="center" gap=".5em">
                                <span>{asset.filename}</span>
                                <Typography.Text copyable={{tooltips: t('tooltip-copy-filename', 'Copy Filename'), text: asset.filename}}/>
                            </Flex>
                            <br/>
                            {!!asset.file_size && filesize(asset.file_size)}
                        </small>
                    </Typography.Text>
                </div>
                <div>
                    <Typography.Text type={'secondary'}>
                        <small>{t('created', 'Created')} <TimeAgo date={asset.created_at}/> </small>
                    </Typography.Text>
                </div>
                {asset.creator_tag && (
                    <p>
                        <small>{t('creator', 'Creator')}: <Text underline>{asset.creator_tag?.name}</Text></small>
                    </p>
                )}
                {asset.captured_at && (
                    <p>
                        <small>{t('captured', 'Captured')}: {moment(asset.captured_at).format('llll')}</small>
                    </p>
                )}
                {!!asset.rating && <Rate defaultValue={asset.rating} disabled style={{color: '#000', fontSize: 12}}/>}
            </div>
            <div style={{marginTop: '.5em'}}>
                <AssetTags asset={asset}/>
            </div>
            <Divider style={{margin: '.5em 0'}}/>
            <div style={{marginTop: '.5em'}}>
                <small><em>{t('max-res', 'Max Res')}: {loading && <LoadingOutlined/>} {maxRes?.name} {maxRes?.watermark && <>({t('watermarked', 'Watermarked')})</>}</em></small>
            </div>
            {currentAssetGroup && addedBy !== false && (
                <>
                    <Divider style={{margin: '.5em 0'}}/>
                    <small><em>{t('added-by', 'Added By')}:&nbsp;{addedByLoading && <LoadingOutlined/>} <User user={addedBy}/></em></small>
                </>
            )}
        </IsolatedDiv>
    )
}

const AssetContextMenu = ({asset, children, id, trigger = 'contextMenu', enabled=true}) => {
    if(!enabled) return children

    return (
        <BulkEditAssetMenu trigger={trigger} asset={asset} id={id}>{children}</BulkEditAssetMenu>
    )
}

export {AssetContextMenu}

const DynamicTextColor = ({ children, style, title, muted = 0 , Component='div', componentProps={}}) => {
    const {gridColor: backgroundColor} = useViewMode()

    // Function to calculate contrast color
    const getContrastColor = (bgColor) => {
        const color = (bgColor.charAt(0) === '#') ? parseInt(bgColor.substring(1), 16) : parseInt(bgColor, 16);
        const r = (color >> 16) & 255;
        const g = (color >> 8) & 255;
        const b = color & 255;
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance < 140 ? 'white' : 'black';
    };

    // Function to mute color towards gray
    const muteColor = (color, amount) => {
        // Convert hex color to RGB
        const colorValue = (color === 'black') ? [0, 0, 0] : [255, 255, 255];
        const gray = 128; // Middle gray
        const newColor = colorValue.map(component => component + (gray - component) * amount);
        return `rgb(${newColor.join(',')})`;
    };

    // Calculate the contrast color and mute it if necessary
    const baseTextColor = getContrastColor(backgroundColor);
    const textColor = muteColor(baseTextColor, muted);

    return (
        <Component style={{ backgroundColor, color: textColor, ...style }} title={title} {...componentProps}>
            {children}
        </Component>
    );
};