import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export default (onClose)=> {
    const {t} = useTranslation();

    let modal = null;

    return (dirty)=> {
        if(modal) return;

        modal = Modal.confirm({
            title: t('cancel-edit','Cancel edit?'),
            icon: <ExclamationCircleOutlined />,
            content: dirty ? t('you have unsaved changes.','You have unsaved changes.') : '',
            onOk: onClose,
            onCancel: () => { modal = null },
            cancelText: t('no','No'),
            okText: t('yes','Yes')
        })
    }
}