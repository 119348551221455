import React, {useRef, useState, useEffect} from "react";
import DragAndDrop from "../helpers/DragAndDrop";
import SplitPane from "react-split-pane";
import BrowseGrid from "./BrowseGrid";
import {AssetDragLayer} from "./assetDragHelpers";
import ExploreLeftSideBar from "./ExploreLeftSideBar";
import {SelectedAssetsProvider} from "../../contexts/SelectedAssetsContext";
import {SelectedAggsProvider} from "../../contexts/SelectedAggsContext";
import ExploreRightSideBar from "./ExploreRightSideBar";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";

import {Button, Drawer, Layout, message, Typography} from 'antd'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {CompassOutlined, MenuOutlined} from "@ant-design/icons";
import SiteSearch from "../widgets/SiteSearch";
import {LightboxIcon} from "../helpers/icons";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import {useFilters} from "../helpers/useFilters";
import {GlobalHotKeys} from "react-hotkeys";
import useCurrentUser from "../helpers/useCurrentUser";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import DoubleRightOutlined from "@ant-design/icons/lib/icons/DoubleRightOutlined";
import {useTranslation} from "react-i18next";

export default ()=> {
    const {currentAssetGroup, currentUpload} = useAssetGroupState()
    const currentUser = useCurrentUser()

    const sizeKey = `ExploreLeftSplit-${isMobile() ? 'mobile' : 'desktop'}`
    const leftSize = useRef(
        parseInt(sessionStorage.getItem(sizeKey), 10) ||
        (isMobile() ? window.innerWidth : 375)
    );

    const leftChange = size => {
        sessionStorage.setItem(sizeKey, size.toString())
        leftSize.current = size
    }

    const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useState(sessionStorage.getItem('ExploreLeftCollapsed') === 'true' || !currentUser || currentUpload);

    const toggleLeftSidebar = ()=> {
        const value = !leftSidebarCollapsed
        sessionStorage.setItem('ExploreLeftCollapsed', value)
        setLeftSidebarCollapsed(value)
    }

    const headerHeight = (document.getElementById('header')?.clientHeight || 64)

    const [rightDrawerVisible, setRightDrawerVisible] = useState()

    const showRight =
        (currentAssetGroup?.commentable ||
            !!currentAssetGroup?.workflow_steps?.filter(s => !s.auto_approval )?.length ||
            !!currentAssetGroup?.workflow_steps?.filter(s => s.send_to_lightroom && s.lightroom_user_id === currentUser?.id )?.length
        ) && currentUser

    const ref = useRef();
    const keyMap = { BRACKET_LEFT: '[' }

    const handlers = {
        BRACKET_LEFT: (e) => {
            e.preventDefault()
            toggleLeftSidebar()
        }
    }

    return (
        (<GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref} allowChanges>
            <DragAndDrop>
                <AssetDragLayer/>
                <div style={{position:'relative', width:'100%', overflow: 'auto', height:`calc(100vh - ${headerHeight}px)`}}>
                    <BrowserView>
                        <SplitPane
                            split='vertical'
                            pane1Style={{overflow: leftSidebarCollapsed ? 'visible' : 'auto'}}
                            size={leftSidebarCollapsed ? 40 : leftSize.current}
                            onChange={leftChange}
                            onDragStarted={()=> { if(leftSidebarCollapsed) toggleLeftSidebar() }}
                        >
                            <ExploreLeftSideBar collapsed={leftSidebarCollapsed} toggleSidebar={toggleLeftSidebar}/>
                            <CenterPane showRight={showRight}/>
                        </SplitPane>
                    </BrowserView>

                    <MobileView>
                        <MobileExploreDrawer/>

                        {showRight && (
                            <>
                                <Drawer
                                    placement={'right'}
                                    onClose={() => setRightDrawerVisible(false)}
                                    open={rightDrawerVisible}
                                    width={'100vw'}
                                    bodyStyle={{padding: 0, margin: '.5em .5em 0 .5em'}}
                                    id={'explore-right-drawer'}
                                    title={<><LightboxIcon/> {currentAssetGroup.name}</>}
                                >
                                    <ExploreRightSideBar/>
                                </Drawer>

                                <Button
                                    style={{position:'fixed', top: '10%', right:-7}}
                                    onClick={()=>setRightDrawerVisible(true)}
                                    size={'small'}
                                    type={'primary'}
                                >
                                    <SettingOutlined/>
                                </Button>
                            </>
                        )}
                    </MobileView>
                </div>
            </DragAndDrop>
        </GlobalHotKeys>)
    );
}

const MobileExploreDrawer = ({})=>{
    const {t} = useTranslation();
    const [drawerVisible, setDrawerVisible] = useState()

    const {currentAssetGroup, currentUpload, viewAllType} = useAssetGroupState()
    const {filters} = useFilters()

    useEffect(()=>{
        setDrawerVisible(false)
    }, [currentUpload?.id, currentAssetGroup?.id, viewAllType, filters?.q])

    const inputRef = useRef()
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(()=> {
        if (location.hash == '#search') {
            setDrawerVisible(true)
            setTimeout(()=>{
                inputRef.current?.focus()
                location.hash = null
                navigate(location)
            }, 200)
        }
    }, [location.hash])

    return (<>
        <Drawer
            placement={'left'}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            width={'100vw'}
            bodyStyle={{padding: 0, margin: '.5em .5em 0 .5em'}}
            id={'explore-left-drawer'}
            title={<><CompassOutlined/> {t('explore-navigation', 'Explore Navigation')}</>}
        >
            <div style={{marginBottom: '1em'}}>
                <SiteSearch inputRef={inputRef}/>
            </div>

            <ExploreLeftSideBar/>
        </Drawer>
        {!currentUpload && (
            <Button
                type={'text'}
                style={{position: 'fixed', top: 50, left: 0, zIndex: 1000, width: 14, height: 53, padding: 1, overflow: 'hidden', borderRight: '1px solid #eee', backgroundColor: '#eee'}}
                onClick={() => setDrawerVisible(true)}
                tabIndex={0}
                id={'mobile-sidebar-toggle'}
                aria-label={t('toggle-left-hand-sidebar', 'Toggle Left-hand Sidebar')}
            >
                <Typography.Text type={'secondary'}>
                    <DoubleRightOutlined style={{marginTop: 18}}/>
                </Typography.Text>
            </Button>
        )}
        <div style={{position: 'absolute', height: '100%', width: '100%'}}>
            <BrowseGrid/>
        </div>
    </>);
}


const CenterPane = ({showRight}) => {
    const rightSize = useRef(parseInt(sessionStorage.getItem('ExploreRightSplit'), 10) || 350);
    const rightChange = size => {
        sessionStorage.setItem('ExploreRightSplit', size.toString())
        rightSize.current = size
    }

    const [rightSidebarCollapsed, setRightSidebarCollapsed] = useState(sessionStorage.getItem('ExploreRightCollapsed') == 'true')

    const toggleRightSidebar = ()=> {
        const value = !rightSidebarCollapsed
        sessionStorage.setItem('ExploreRightCollapsed', value)
        setRightSidebarCollapsed(value)
    }

    const ref = useRef();
    const keyMap = { BRACKET_RIGHT: ']'}

    const handlers = {
        BRACKET_RIGHT: (e) => {
            e.preventDefault()
            toggleRightSidebar()
        },
    }

    if(!showRight) {
        return <BrowseGrid/>
    }

    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref}>
            <SplitPane
                split='vertical'
                size={rightSidebarCollapsed ? 15 : rightSize.current}
                onChange={rightChange}
                primary={'second'}
            >
                <BrowseGrid/>
                <ExploreRightSideBar collapsed={rightSidebarCollapsed} toggleSidebar={toggleRightSidebar}/>
            </SplitPane>
        </GlobalHotKeys>
    )
}