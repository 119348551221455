import React, {useContext, useEffect, useRef, useState} from "react";

import {
    Table,
    Button,
    message,
    Space,
    Popconfirm,
    Typography, Skeleton, Dropdown, Menu, Select, Descriptions, Breadcrumb
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {Link, useNavigate} from "react-router-dom-v5-compat";
import {OrgLink} from "../helpers/OrgNavLink";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";
import TimeAgo from "../helpers/TimeAgo";

export default ({q, onLoaded}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/collections'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-collection-deleted','Collection Deleted.'));
            handleTableChange();
        });
    }

    const clickManage = (e,c)=>{
        e.preventDefault()
        e.stopPropagation();
        location.hash = `/collections/${c.id}/edit`
        navigate(location)
        return false;
    }

    const columns = [
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            width: 200,
            sorter: true,
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('path','Path'),
            render: collection => {
                return (
                    <Breadcrumb>
                        {collection.path_names?.map((name,i) => (
                            <Breadcrumb.Item key={i}>
                                <OrgLink to={`/explore/collections/${collection.path_slugs[i]}`}>{name}</OrgLink>
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                )
            }
        },
        {
            key: 'action',
            width: 100,
            render: (collection) => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'Collection'}>
                                <Space direction={'horizontal'}>
                                    <Button onClick={(e) => clickManage(e,collection)} size={'middle'}>
                                        <EditOutlined/>
                                        {t('button-edit','Edit')}
                                    </Button>

                                    <Popconfirm
                                        title={t('confirm-archive-collection','Archive Collection?')}
                                        onConfirm={() => destroy(collection.id)}
                                    >
                                        <Button size={'middle'}>
                                            <DeleteOutlined/>
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(handleTableChange, [q]);

    const [selectedKeys, setSelectedKeys] = useState([]);

    const onChange = (newSelectedKeys, selectedRows)=> {
        setSelectedKeys(newSelectedKeys);
    }

    return (
        <>
            <Table
                scroll={{x: true}}
                bordered
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={{...pagination, showSizeChanger: true}}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
