import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom-v5-compat";

import api from '../api';

import { AppContext } from '../../contexts/AppContext'
import {Space, message, Button, Row, Col, Card, Alert, Divider} from "antd"

import setTitle from "../helpers/setTitle";
import {useParams} from "react-router-dom";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import User from "../helpers/User";
import SignUpForm from "./SignUpForm";
import LoginForm from "../session/LoginForm";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {SessionContext} from "../../contexts/SessionContext";
import useOrgSlug from "../helpers/useOrgSlug";
import {ContactsOutlined} from "@ant-design/icons";
import OrgBackground from "../session/OrgBackground";
import OrgLogo from "../widgets/OrgLogo";
import {isMobile} from "device-detect";
import useAfterLogin, {setAfterLogin} from "../helpers/useAfterLogin";
import {useOrgPath} from "../helpers/OrgNavLink";

export default () => {
    setTitle('Mediagraph Invite');

    const org = useOrgSlug()

    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const {dispatch} = useContext(SessionContext);

    const navigate = useNavigate();

    const {token} = useParams()

    const [loading, setLoading] = useState()
    const [invite, setInvite] = useState()

    useEffect(()=>{
        setLoading(true)

        api(`/api/invites/find?token=${token}`).then(res => {
            setInvite(res.data)
            setLoading(false)
        }).catch(res => {
            message.error(JSON.stringify(res))
            console.log(res)
        })
    }, [token])

    const getPath = useOrgPath()

    const [accepting, setAccepting] = useState()
    const accept = ()=> {
        setAccepting(true)
        api.post(`/api/invites/accept?token=${token}`).then(res => {
            setAccepting(false)

            const org = invite?.organization
            api.get(`/api/organizations/${org.id}/abilities?login=true`).then(res => {
                dispatch({type: 'set_org', org: org, abilities: res.data});
                message.success(`Welcome to ${org.title}!`);

                const afterLogin = useAfterLogin()

                const path = invite.collection ? `explore/collections/${invite.collection.slug}#tour` : 'explore#tour'
                navigate(afterLogin || getPath(`/${path}`))
            });
        })
    }

    if(org && invite?.asset_group_invite) {
        setAfterLogin(getPath(`/explore/projects/${invite.asset_group_invite.asset_group.slug}`))
    }

    return (
        <div style={{paddingTop:'2em'}}>
            <OrgBackground/>
            <Card title={<><ContactsOutlined/> Mediagraph Invite</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                <OrgLogo org={org}/>

                {loading && (
                    <>
                        <LoadingOutlined/> Invite Loading...
                    </>
                )}

                {invite?.still_valid && (
                    <>
                        {invite.auto_approved ? (
                            <>
                                You have requested to join:
                            </>
                        ) : (
                            <>
                                <User user={invite.user}/> has invited you to the organization:
                            </>
                        )}
                        <h2 style={{textAlign:'center'}}>
                            {invite.organization.title}
                        </h2>

                        {invite.asset_group_invite && (
                            <p style={{textAlign:'center'}}>
                                And to collaborate on the Lightbox: <br/>
                                <h3 style={{marginTop:'1em'}}>"{invite.asset_group_invite.asset_group?.name}"</h3>
                            </p>
                        )}

                        {invite.note && (
                            <Card style={{margin:'1em 0'}} size={'small'} title={<small>Note:</small>}>{invite.note}</Card>
                        )}

                        <Divider style={{margin:'1em 0'}}/>

                        {currentUser && (
                            <Space direction={'vertical'} style={{width:'100%'}}>
                                <div>Logged in as {currentUser.email}</div>
                                <Button block type={'primary'} onClick={accept} loading={accepting}>
                                    <CheckOutlined/> Accept Invite
                                </Button>
                            </Space>
                        ) || (
                            <Space direction={'vertical'} style={{width:'100%'}}>
                                {invite.existing_user_id && (
                                    <>
                                        <h3>Login to Existing Account:</h3>
                                        <Alert
                                            showIcon
                                            message={'This email address already has a Mediagraph identity, use the same password to log in here.'}
                                        />
                                        <LoginForm invite={invite}/>
                                        <br/>
                                        <Link to={'/forgot-password'}>Forgot Password</Link>
                                    </>
                                ) || (
                                    <>
                                        <h3>Sign Up: (All fields required)</h3>
                                        <SignUpForm invite={invite}/>
                                    </>
                                )}
                            </Space>
                        )}
                    </>
                )}

                {invite && !invite.still_valid && (
                    <>Sorry, this invite is no longer valid.</>
                )}

            </Card>
        </div>
    );
}
