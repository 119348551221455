import {
    Button,
    Descriptions,
    Divider, Dropdown,
    Menu,
    message,
    Modal,
    Popconfirm,
    Popover,
    Space, Switch, Tag,
    Tooltip,
    Typography
} from "antd";
import {
    CheckOutlined,
    DeleteOutlined,
    FileImageOutlined,
    FileOutlined,
    SearchOutlined,
    StopOutlined,
    VideoCameraOutlined,
    AudioOutlined,
    SettingOutlined,
    DownOutlined,
    MacCommandFilled,
    MacCommandOutlined,
    CloseCircleOutlined,
    FontSizeOutlined,
    DownloadOutlined,
    UndoOutlined,
    EyeInvisibleOutlined,
    FilterOutlined,
    RetweetOutlined, FileTextOutlined
} from "@ant-design/icons";
import React, {useContext, useRef, useState} from "react";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useAggsState} from "../../contexts/AggsContext";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import DownloadButton from "./DownloadButton";
import filesize from 'filesize'
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import {GlobalHotKeys} from "react-hotkeys";

import {
    isBrowser,
} from "device-detect";
import useSelectAll from "../helpers/useSelectAll";
import useCurrentUser from "../helpers/useCurrentUser";
import {useFilters} from "../helpers/useFilters";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {AppContext} from "../../contexts/AppContext";
import {AbilityContext} from "../helpers/Can";
import {useTranslation} from "react-i18next";
import ShareButton from "@/components/explore/ShareButton";
import {BulkEditAssetMenu} from "~/components/explore/BulkEditButton";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import IsolatedDiv from "@/components/helpers/IsolatedDiv";

export default ({})=> {
    const {t} = useTranslation();
    const {selectedAssetIds, reloads: selectedAssetReloads} = useSelectedAssetsState();

    const {assetsLoading} = useLoadedAssetsState();

    const {total} = useAggsState()
    const currentUser = useCurrentUser()
    const {filters, removeFilter} = useFilters()
    // ---------------------------------------

    const {selectingAll, selectAll, selectNone, toggleSelect} = useSelectAll()

    const clickSelectNone = ()=>{
        selectNone()
        setTimeout(()=> removeFilter('selection'), 100)
    }

    const ref = useRef();
    const keyMap = {
        CMD_A: 'command+a',
    }

    const handlers = {
        CMD_A: (e) => {
            toggleSelect()
            e.preventDefault()
        },
    }

    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} innerRef={ref} allowChanges>
            <Space direction={'horizontal'}>
                {!!selectedAssetIds?.length || filters.selection ? (
                    <>
                        {currentUser && <BulkActionsButton/>}
                        <DownloadButton/>
                        <ShareButton small/>
                        <Dropdown.Button
                            size={'small'}
                            onClick={clickSelectNone}
                            loading={assetsLoading}
                            icon={<CheckOutlined />}
                            type={'primary'}
                            ghost
                            menu={{items:[]}}
                            buttonsRender={([left, right])=> [React.cloneElement(left, {id: 'select-all-btn'}), (
                                <AssetStoragePopover>
                                    <div aria-label={t('selected-assets-menu', 'Selected Assets Menu')}>
                                        {React.cloneElement(right, {icon: '...'})}
                                    </div>
                                </AssetStoragePopover>
                            )]}
                        >
                            <Tooltip title={<>{t('tooltip-select-none','Select None')}: <MacCommandFilled/> A</>} trigger={['hover','focus']}>
                                <CheckOutlined/>
                                {n(selectedAssetIds.length)} / {n(total) || '...'} {(isBrowser() && <>&nbsp;Asset{selectedAssetIds.length == 1 ? '' : 's'} {t('selected','Selected')}</>) || <FileOutlined/>}
                                {filters.selection && <FilterOutlined style={{color: Colors.red}}/>}
                            </Tooltip>
                        </Dropdown.Button>
                    </>
                ) : (
                    <Dropdown.Button
                        size={'small'}
                        onClick={selectAll}
                        loading={assetsLoading || selectingAll}
                        menu={{items:[]}}
                        buttonsRender={([left, right])=> [React.cloneElement(left, {id: 'select-all-btn'}), (
                            <AssetStoragePopover>
                                <div aria-label={t('selected-assets-menu', 'Selected Assets Menu')}>
                                    {right}
                                </div>
                            </AssetStoragePopover>
                            )]}
                    >
                        <Tooltip title={<>{t('tooltip-select-all','Select All')}: <MacCommandFilled/> A</>} trigger={['hover','focus']}>
                            {n(total)} {(isBrowser() && <>&nbsp;{t(`asset${total != 1 ? 's' : ''}`,`Asset${total != 1 ? 's' : ''}`)}</>) || <FileOutlined/>}
                        </Tooltip>
                    </Dropdown.Button>
                )}
            </Space>
        </GlobalHotKeys>
    )
}

const AssetStoragePopover = ({children}) => {
    const {t} = useTranslation();
    const {selectedAssetIds, previouslySelectedAssetIds} = useSelectedAssetsState();
    const selectedAssetsDispatch = useSelectedAssetsDispatch();

    const currentOrg = useCurrentOrg()
    const {state} = useContext(AppContext);
    const ability = useContext(AbilityContext);

    const {aggs: searchAggs, total: totalAssets} = useAggsState()
    const {selectedAssetAggs} = useSelectedAggsState()
    const {setFilters, removeFilter} = useFilters()

    const clickType = type => {
        setFilters(true, {type})
    }

    const downloadCSV = ()=> {
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", `/api/download_meta?organization_id=${currentOrg?.id}&jwt=${state.jwt}`);
        form.setAttribute("target", "_blank");

        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", "asset_ids");
        hiddenField.setAttribute("value", selectedAssetIds);
        form.appendChild(hiddenField);
        document.body.appendChild(form);

        form.submit();
    }

    const clickUndoDeselect = ()=>{
        selectedAssetsDispatch({type:'undoSelectNone'})
    }

    const {getAllIds} = useSelectAll()

    const [selectingInverse, setSelectingInverse] = useState()
    const clickSelectInverse = ()=>{
        setSelectingInverse(true)
        getAllIds(ids => {
            setSelectingInverse(false)
            selectedAssetsDispatch({type:'setSelection', ids: ids.filter(id => !selectedAssetIds.includes(id))})
        })
    }

    const toggleSelectionFilter = (selection, value) => {
        const applied = filters.selection === selection

        if(!value && applied) {
            removeFilter('selection')
        } else {
            setFilters(false, {selection})
        }
    }

    const onShowSelectedAssetsChange = value => {
        toggleSelectionFilter('selected', value)
    }

    const onShowUnselectedAssetsChange = value => {
        toggleSelectionFilter('unselected', value)
    }

    if(!searchAggs?.type) return children

    const descriptions = (aggs, title) => {
        let count = 0
        let total = 0

        return (
            <Descriptions bordered size='small' column={1} title={title}>
                {aggs.type.buckets.map(bucket => {
                    const icon = {
                        Image: <FileImageOutlined/>,
                        Video: <VideoCameraOutlined/>,
                        Document: <FileOutlined/>,
                        Audio: <AudioOutlined/>,
                        Font: <FontSizeOutlined/>,
                        Text: <FileTextOutlined/>,
                    }[bucket.key] || <></>

                    const type = {
                        Image: 'images',
                        Video: 'videos',
                        Document: 'documents',
                        Audio: 'audio',
                        Font: 'fonts',
                        Text: 'text'
                    }[bucket.key]

                    if(!type) {
                        console.log('type null', bucket)
                        return <div key={'null'}></div>;
                    }

                    count += bucket.doc_count
                    total += bucket.file_size.value

                    return (
                        <Descriptions.Item
                            key={bucket.key}
                            label={<>{icon} <a onClick={()=> clickType(type)} className='asset-type-filter'>{t(type, bucket.key + 's')}</a></>}
                        >
                            {n(bucket.doc_count)} <small><Typography.Text type={'secondary'}>({filesize(bucket.file_size.value)})</Typography.Text></small>
                        </Descriptions.Item>
                    )
                })}
                <Descriptions.Item key={'total'} label={<><Typography.Text strong>{t('total','Total')}</Typography.Text></>}>
                    {n(count)} <small> <Typography.Text type={'secondary'}> ({filesize(total)}) </Typography.Text> </small>
                </Descriptions.Item>
            </Descriptions>
        )
    }

    return (
        <Popover
            placement={'bottomRight'}
            autoAdjustOverflow={false}
            overlayStyle={{minWidth:300}}
            trigger={['hover','click']}
            getPopupContainer={e => e.parentElement}
            content={
                <VerticalSpace>
                    {(!!selectedAssetIds.length || filters.selection) && (
                        <div style={{margin:'1em 0'}}>
                            <div style={{display:'flex',margin:'.5em 0'}}>
                                <Switch checked={filters.selection === 'selected'} checkedChildren={<CheckOutlined/>} onChange={onShowSelectedAssetsChange} aria-label={t('view-selected','View Selected')}/>
                                <span>&nbsp;{t('view-selected','View Selected')}</span>
                                <Tag style={{marginLeft:'auto'}}>{selectedAssetIds.length}</Tag>
                            </div>
                            <div style={{display:'flex',margin:'.5em 0'}}>
                                <Switch checked={filters.selection === 'unselected'} checkedChildren={<EyeInvisibleOutlined/>} onChange={onShowUnselectedAssetsChange} aria-label={t('view-unselected','View Unselected')}/>
                                <span>&nbsp;{t('view-unselected','View Unselected')}</span>
                                <Tag style={{marginLeft:'auto'}}>{Math.max(totalAssets - selectedAssetIds.length,0)}</Tag>
                            </div>
                        </div>
                    )}

                    {!!selectedAssetIds.length && (
                        <Button block ghost type={'primary'} onClick={clickSelectInverse} icon={<RetweetOutlined />} loading={selectingInverse}>
                            {t('button-select-inverse','Select Inverse')} &nbsp;<Tag>{Math.max(totalAssets - selectedAssetIds.length, 0)}</Tag>
                        </Button>
                    )}

                    {!!previouslySelectedAssetIds?.length && (
                        <Button block ghost danger onClick={clickUndoDeselect} icon={<UndoOutlined />}>
                            {t('button-undo-deselect','Undo Deselect')} &nbsp;<Tag>{previouslySelectedAssetIds.length}</Tag>
                        </Button>
                    )}

                    {(!!previouslySelectedAssetIds?.length || !!selectedAssetIds.length) && <Divider style={{margin:'1em 0'}}/>}

                    {!!selectedAssetIds.length && selectedAssetIds.length != totalAssets && (
                        <>
                            {selectedAssetAggs?.type && (
                                <>
                                    {descriptions(selectedAssetAggs, <><CheckOutlined/> {t('selected-assets','Selected Assets')}</>)}
                                    <Divider style={{margin:'.5em 0'}}/>
                                </>
                            )}
                        </>
                    )}

                    {descriptions(searchAggs, <><SearchOutlined/> {t('search-results','Search Results')}</>)}

                    {ability.can('download_meta', 'Asset') && !!selectedAssetIds.length && (
                        <Button
                            onClick={downloadCSV}
                            type={'primary'}
                            icon={<DownloadOutlined/>}
                            block
                        >
                            {t('button-download-csv','Download CSV')}
                        </Button>
                    )}
                </VerticalSpace>
            }
        >
            {children}
        </Popover>
    )
}

const BulkActionsButton = ({})=> {
    return (
        <IsolatedDiv>
            <BulkEditAssetMenu trigger={['click', 'hover']} id={'bulk-action-popover'}>
                <Button icon={<SettingOutlined/>} size={'small'} type={'text'} id={'bulk-action-btn'}/>
            </BulkEditAssetMenu>
        </IsolatedDiv>
    )
}