import React, {useState} from "react";
import {Alert, Button, Space, Tooltip} from "antd";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import TimeAgo from "react-timeago/lib";
import User from "../helpers/User";
import {AlertOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined} from "@ant-design/icons";
import {DeleteIcon} from "../helpers/icons";
import {useTranslation} from "react-i18next";

export default ({asset})=> {
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();

    const [requesting, setRequesting] = useState()

    const request = ()=>{
        setRequesting(true)
        api.post(`/api/assets/${asset.id}/request_optimization`).then(res => {
            setRequesting(false)
            assetsDispatch({type:'updateAsset', asset: res.data});
        })
    }

    const [removing, setRemoving] = useState()

    const remove = ()=>{
        setRemoving(true)
        api.post(`/api/assets/${asset.id}/remove_optimization_request`).then(res => {
            setRemoving(false)
            assetsDispatch({type:'updateAsset', asset: res.data});
        })
    }

    const [completing, setCompleting] = useState()

    const complete = ()=>{
        setCompleting(true)
        api.post(`/api/assets/${asset.id}/complete_optimization`).then(res => {
            setCompleting(false)
            assetsDispatch({type:'updateAsset', asset: res.data});
        })
    }

    return (
        <Space direction={'vertical'}>
            {asset.optimization_requested_at && (
                <Alert
                    showIcon type={'info'}
                    message={
                        <>
                            {t('requested','Requested')} <TimeAgo date={asset.optimization_requested_at}/> {t('by','by')} <User user={asset.optimization_requested_by}/>
                        </>
                    }
                    action={
                        <Tooltip title={t('tootlip-remove-optimization-request','Remove optimization request?')}>
                            <Button size={'small'} loading={removing} onClick={remove} icon={<CloseOutlined/>} style={{marginLeft:'1em'}}/>
                        </Tooltip>
                    }
                />

            )}

            {asset.optimization_completed_at && (
                <Alert showIcon type={'success'} message={
                    <>
                        {t('completed','Completed')} <TimeAgo date={asset.optimization_completed_at}/> {t('by','by')} <User
                        user={asset.optimization_completed_by}/>
                    </>
                }/>
            )}

            {asset.optimization_completed_at || !asset.optimization_requested_at ? (
                <Button loading={requesting} onClick={request} icon={<AlertOutlined/>}>{}{t(`button-${asset.optimization_completed_at ? 're-' : ''}request-optimization`,`${asset.optimization_completed_at ? 'Re-' : ''}Request Optimization`)}</Button>
            ) : (
                <Button loading={completing} onClick={complete} icon={<CheckOutlined/>}>{t('button-complete-optimization','Complete Optimization')}</Button>
            )}
        </Space>
    )
}