export default {
    redactLabel: 'Rédiger',
    redactIcon: `<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em">
        <path d="M 4 5 l 1 -1"/>
        <path d="M 4 10 l 6 -6"/>
        <path d="M 4 15 l 11 -11"/>
        <path d="M 4 20 l 16 -16"/>
        <path d="M 9 20 l 11 -11"/>
        <path d="M 14 20 l 6 -6"/>
        <path d="M 19 20 l 1 -1"/>
    </g>`,
};
