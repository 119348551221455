import React, {useContext} from "react";
import {SessionContext} from "../../contexts/SessionContext";
import {useDragLayer} from "react-dnd";
import {Badge} from "antd";
import {useAssetsState} from "../../contexts/AssetsContext";
import {useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";

//
// Drag Layer
// ------------------------------------------------------------

export function AssetDragLayer() {
    const {assets} = useAssetsState();
    const {selectedAssetIds} = useSelectedAssetsState();

    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
    };

    const getItemStyles = clientOffset => {
        if (!clientOffset) {
            return {
                display: 'none',
            };
        }
        const { x, y } = clientOffset;
        return {
            transform: `translate(${x}px, ${y}px)`,
            filter: 'drop-shadow(0 2px 12px rgba(0,0,0,0.45))',
        };
    };

    const {
        itemType,
        isDragging,
        item,
        initialOffset,
        currentOffset,
        clientOffset,
    } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        clientOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) return '';

    const cards = (selectedAssetIds || []).slice(0,3).map(id => assets[id])

    if(!_.compact(cards).length) return '';

    let previewCards = _.compact([...cards]);
    if(_.compact(cards).map(a => a.id).indexOf(item.asset?.id) == -1) {
        previewCards = [item.asset, ...previewCards];
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(clientOffset)}><CardsDragPreview cards={previewCards} count={selectedAssetIds.length}/></div>
        </div>
    );
};

const CardsDragPreview = ({ cards, count }) => {
    return (
        <Badge count={count} style={{zIndex: 100, left: '2em', width: 30 + ((count.toString().length - 1) * 10)}} overflowCount={1000000}>
            {_.compact(cards).map((card, i) => (
                <div
                    key={card.id}
                    className="card card-dragged"
                    style={{
                        zIndex: cards.length - i,
                        transform: `rotateZ(${-i * 10}deg)`,
                        position: 'absolute',
                        transformOrigin: 'bottom left',
                        WebkitBackfaceVisibility: 'hidden'
                    }}
                >
                    <img src={card.thumb_url} width="80" draggable="false" />
                </div>
            ))}
        </Badge>
    );
};
