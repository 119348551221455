import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import api from "../api";
import {Breadcrumb, Button, Col, message, Popconfirm, Popover, Row, Space, Table, Typography, DatePicker} from "antd";
import {OrgLink} from "../helpers/OrgNavLink";
import {Can} from "../helpers/Can";
import {CommentOutlined, DeleteOutlined, EditOutlined, FileTextOutlined} from "@ant-design/icons";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import User from "../helpers/User";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import Search from "antd/lib/input/Search";
import queryString from "query-string";
import useShowAsset from "../helpers/useShowAsset";
import EnabledDisabled from "../helpers/EnabledDisabled";
import TimeAgo from "../helpers/TimeAgo";
import moment from 'moment'
import DateRangePicker, {getLocale} from "../widgets/DateRangePicker";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ()=> {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/share_links'

    const columns = [
        {
            title: t('enabled','Enabled'),
            dataIndex: 'enabled',
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (enabled) => (
                <EnabledDisabled enabled={enabled}/>
            )
        },
        {
            title: t('created','Created'),
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => {
                return <TimeAgo date={created_at}/>
            }
        },
        {
            title: t('lightbox','Lightbox'),
            render: sl => (
                <>{sl.asset_group?.name}</>
            )
        },
        {
            title: t('note','Note'),
            dataIndex: 'note',
            render: note => {
                return !!note?.length ? <Popover content={note}><CommentOutlined/></Popover> : <></>
            }
        },
        {
            title: t('user','User'),
            render: sl => {
                return <User user={sl.user}/>
            }
        },
        {
            title: t('image-and-video-permission','Image/Video Permission'),
            dataIndex: 'image_and_video_permission',
            sorter: true,
            filters: [
                {text: t('small','Small'), value: 'small'},
                {text: t('medium','Medium'), value: 'medium'},
                {text: t('full','Full'), value: 'full'},
                {text: t('original','Original'), value: 'original'},
            ],
            render: (value) => {
                return t(value, value?.toProperCase())
            }
        },
        {
            title: t('other-permission','Other Permission'),
            dataIndex: 'other_permission',
            sorter: true,
            filters: [
                {text: t('none','None'), value: 'none'},
                {text: t('original','Original'), value: 'original'},
            ],
            render: (value) => {
                return t(value, value?.toProperCase())
            }
        },
        {
            title: t('watermark','Watermark'),
            dataIndex: 'watermark_all',
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (value) => {
                return value ? t('yes','Yes') : t('no','No')
            }
        },
        {
            title: t('assets','Assets'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'assets_count'
        },
        {
            title: t('asset-views','Asset Views'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'asset_views_count'
        },
        {
            title: t('downloads','Downloads'),
            sorter: true,
            defaultSortOrder: 'descend',
            dataIndex: 'downloads_count',
            render: (count, shareLink) => (
                <OrgLink to={`/manage/reports/downloads?share_link_id=${shareLink.id}`}>{count}</OrgLink>
            )
        },
        {
            title: t('expires','Expires'),
            dataIndex: 'expires',
            filters: [
                {text: t('yes','Yes'), value: true},
                {text: t('no','No'), value: false},
            ],
            render: (enabled) => (
                <EnabledDisabled enabled={enabled}/>
            )
        },
        {
            title: t('expiration','Expiration'),
            dataIndex: 'expires_at',
            render: d => d && <TimeAgo date={d}/>
        },
    ];

    let location = useLocation()
    const navigate = useNavigate()

    const {search: searchQuery} = queryString.parse(location.search)

    const params = useRef({})
    const [q, setQ] = useState(searchQuery)
    const [dates, setDates] = useState()

    useEffect(()=>{
        if(searchQuery != q) setQ(searchQuery)
    }, [searchQuery])

    const [settings, setSettings] = useState({})

    const handleTableChange = (pagination, filters, sorter) => {
        setSettings({q, dates, pagination, filters, sorter})
        params.current = {
            q,
            dates,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    useEffect(handleTableChange, [q, dates]);

    const onSearch = _.debounce((value) => {
        setQ(value)
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <Row gutter={8}>
                <Col lg={4} xs={24}>
                    <DateRangePicker onChange={dates => setDates(dates) } />
                </Col>
                <Col lg={8} xs={24}>
                    <Search
                        placeholder={t('placeholder-share-link-report-search','Search By Lightbox name, User...')}
                        onSearch={onSearch}
                        onChange={onChange}
                        style={{width:'100%'}}
                        size={'medium'}
                        allowClear
                        loading={loading}
                        value={q}
                    />
                </Col>
            </Row>

            <Table
                scroll={{x: true}}
                bordered
                size={'small'}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </Space>
    );
}